/*******************************************************************************************
//  * File Name                : SelectCity
//  * File Directory           : src/components/Home/SelectCity
//  * Module                   : SelectCity
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Home 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   SelectCity        -   component for setting up which city
 *
 * #How to call:
 *    <SelectCity/>
 *
 * Note: Still working on the ff:
 * Ability to save the city_id on localstorage
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import CookieConsent, {
  Cookies,
  getCookieConsentValue,
} from "react-cookie-consent";
import Home_api from "../APIs/Home";
import Select from "react-select";
import { storeFrontLogs } from "../APIs/Logs";
import { v4 as uuid } from "uuid"; //module for generating id

const style = {
  errorStyle: {
    top: "18%",
  },
  selectForm: {
    width: "100%",
  },
  iconPin: {
    verticalAlign: "text-top",
    width: "12px",
  },
};

const SelectCity = ({ branch, setBranch }) => {
  const [branchSelect, setBranchSelect] = useState(
    localStorage.getItem("branchSelected") || "false"
  );
  let getSelected = branch !== null ? branch : "Please Select Store";
  if (getSelected?.branch_id === undefined) {
    getSelected = "Please Select Store";
  }

  const [firstLoad, setFirstLoad] = useState(true); // for mapping city
  const [city, setCity] = useState([]); // for mapping city
  const [shop, setShop] = useState(getSelected.city_name || "Frozenlink - Paranaque"); // saving shop /city
  const [branch2, setBranch2] = useState(getSelected.branch_id || "1"); // saving branch_id
  const [cityID, setCityId] = useState(getSelected.city_id || "11"); // saving city_id
  const [visible, setVisible] = useState(true); // show modal
  const [error, setError] = useState(""); // showing errors
  const [opening, setOpening] = useState(getSelected.city_id || "08:00"); // saving opening of branch
  const [closing, setClosing] = useState(getSelected.branch_opening || "16:00"); // saving closing of branch
  const [success, setSuccess] = useState(false); // showing success
  // const [toggleModal, setToggleModal] = useState(`modal ${visible ? 'showModal' : ''}`); // // class for show modal
  const toggleModal = `modal ${visible ? "showModal" : ""}`; // class for show modal
  const errorClass = `${error ? "error" : ""}`; // class for error span
  let body = document.body; // for setting body height (modal)
  // const cityChecker = localStorage.getItem('SelectedCity');
  const [cityCheck, setCityCheck] = useState(cityID) || ""; // saving branch_id
  const isMobile = localStorage.getItem("isMobile") === "true";
  // alert(shop)
  const isLogged = sessionStorage.getItem("Login") || ""; // login checker
  let gen_id = localStorage.getItem("gen_id"); // generated id
  let user;
  let userLogin;
  const uniqueID = uuid();

  useEffect(() => {
    console.log(branch, branchSelect)
    setShop(branch !== null ? branch?.city_name : ""); // saving shop /city
    setBranch2(branch !== null ? branch?.branch_id : ""); // saving branch_id
    setCityId(branch !== null ? branch?.city_id : ""); // saving city_id
    //  console.log(localStorage.getItem('accessKey'))
    if (branch !== null && branchSelect === "false") {
    } else if (branch !== null) {
      setBranchSelect(true);
      localStorage.setItem("branchSelected", true);
      setVisible(true);
      //  alert("TEST3")
      // toggleCityModal()
    } else if (branch === null && branchSelect === "true") {
      // alert("TEST1")
      // function uuidv4() {
      //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
      //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      //         return v.toString(16);
      //     });
      // }
      let link = uuid();
      const accessStorage = async () => {
        await Home_api.post("/accessStorage", { accessToken: link });
        localStorage.setItem("accessKey", link);
        window.location.reload();
      };
      if (firstLoad && localStorage.getItem("accessKey") === null) {
        setFirstLoad(false);
        setBranchSelect(false);
        localStorage.setItem("branchSelected", false);
        setVisible(!visible);
        body.classList.add("body_class");
        setVisible(!visible);
        accessStorage();
      }
    } else {
      body.classList.remove("body_class");
    }
    if (branch) {
      if (branch.default) {
        setShop(branch.default ? branch?.city_name : ""); // saving shop /city
        setBranch2(branch.default ? branch?.branch_id : ""); // saving branch_id
        setCityId(branch.default ? branch?.city_id : ""); // saving city_id
        // toggleCityModal()
      }
    }
    // if(branch!==null&&!branchSelect) !branchSelect ? toggleCityModal() : setVisible(visible);body.classList.remove("body_class");
  }, [branch]);
  //fetching city from database
  useEffect(() => {
    const fetchCity = async () => {
      const response = await Home_api.get("/fetchBranch");
      const cityResult = response.data || [];
      let newCity = []
      if (cityResult.length > 0) {
        newCity = cityResult.map((data) => {
          return data
        })
      }

      console.log(newCity)
      // console.log(window.location)
      // console.log(window.location.pathname)
      if (window?.location?.pathname !== "/checkout-page") {
        setCity(newCity);
      }
    };
    fetchCity();
    {
      branchSelect === "false" ? toggleCityModal() : setVisible(visible);
    }
  }, []);

  const onClickOutside = (e) => {
    e.stopPropagation();
  };
  //storing city id and name on localstorage
  const selectCity = async (e) => {
    console.log("SELECT CITY")
    let city_id = cityID || 11,
      branch_id = branch2 || 2,
      city_name = shop || "Frozenlink - Paranaque",
      branch_opening = opening,
      branch_closing = closing;
    // branch_id
    let city_info = city.filter((data) => { return data.branch_id === branch_id })
    let branch_city, branch_zip, branch_brgy, branch_address, admin_name, contact_number
    if (city_info.length > 0) {
      branch_city = city_info[0].branch_city
      branch_zip = city_info[0].branch_zip
      branch_brgy = city_info[0].branch_brgy
      branch_address = city_info[0].branch_address
      admin_name = `${city_info[0].admin_fname} ${city_info[0].admin_lname}`
      contact_number = city_info[0].contact_number
    }
    const city_details = {
      city_id: city_id || 11,
      city_name: city_name || "Frozenlink - Paranaque",
      branch_id: branch_id || 2,
      branch_opening: branch_opening || "08:00",
      branch_closing: branch_closing || "16:00",
      shop_name: shop || "",
      branch_city: branch_city,
      branch_zip: branch_zip,
      branch_brgy: branch_brgy,
      branch_address: branch_address,
      admin_name: admin_name,
      contact_number: contact_number
    };
    console.log("city_details", city_details)
    // alert(city_id)
    setCityCheck(cityID);
    // setBranch(city_details)
    //onsubmit validating
    if (city_id !== "" || city_details.city_id !== "") {
      // if(cityCheck != city_details.city_id){
      //     localStorage.removeItem("cart");
      //     sessionStorage.removeItem("total");
      //     // window.location.reload()
      // }

      let accessToken = localStorage.getItem("accessKey");
      let result = await Home_api.post(`/accessStorage/changeCity`, {
        accessToken: accessToken,
        city_details: city_details,
      });
      localStorage.setItem("branchSelected", true);
      localStorage.setItem("SelectedCity", JSON.stringify(city_details || {}));
      setShop(city_name || city_details.city_name);
      setVisible(!visible);
      body.classList.remove("body_class");
      setError("");
      setSuccess(true);
      setBranch(city_details);

      if (gen_id === null) {
        gen_id = localStorage.setItem("gen_id", uniqueID);
        gen_id = localStorage.getItem("gen_id");
        user = gen_id;
      } else if (gen_id) {
        user = gen_id;
      } else if (isLogged) {
        userLogin = JSON.parse(sessionStorage.getItem("Login"));
        gen_id = localStorage.getItem("gen_id");
        user = userLogin.customer_name + ` (${gen_id})`;
      }
      const payload = {
        user: user,
        changes: `Selected ${city_details.city_name}`,
        type: "select_city",
        date: `NOW()`,
      };
      storeFrontLogs(payload);
      setVisible(false);
      /** Google Analytics */
      // if(getCookieConsentValue('zaganaCookie')==='true'){
      window.gtag("event", "select_content", {
        content_type: "city",
        item_id: city_name,
      });
      // }
      /** Google Analytics */
    } else {
      setError("Please select store");
      setSuccess(false);
    }
    let result = verifyItems(branch_id);
  };
  //verify item availble on cart
  function verifyItems(branch_id) {
    let cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const fetchItems = async () => {
      let accessKey = localStorage.getItem("accessKey");
      const response = await Home_api.post(`/accountChecker`, {
        branch_id: branch_id,
        accessToken: accessKey,
      });
      let items = response.data.result || "";
      if (items === "Success") window.location.reload();
      //  let cartLength=cartItems.length,itemsLength=items.length,cartResult=[]
      //  for(let i=0;i<cartLength;i++){
      //      for(let x=0;x<itemsLength;x++){
      //          if(items[x].item_sku===cartItems[i].item_sku){
      //              cartResult.push({
      //                  item_id: items[x].item_id,
      //                  cart_qty: cartItems[i].cart_qty,
      //                  cart_price: items[x].item_salePrice,
      //                  item_price:items[x].item_price,
      //                  item_sku: cartItems[i].item_sku,
      //                  item_var: cartItems[i].item_variant,
      //                  item_name: cartItems[i].item_name,
      //                  item_image: cartItems[i].item_image
      //              })
      //          }
      //      }
      //  }
      //  localStorage.setItem('cart', JSON.stringify(cartResult))
    };
    fetchItems();
  }

  //onchange selected
  function logChange(val) {
    let city_id = val.value;
    // alert(city_id)
    if (city_id !== "") {
      setError("");
      setSuccess(true);
    } else {
      setError("Please select store");
      setSuccess(false);
    }
    setBranch2(val.label2);
    setCityId(val.value);
    setShop(val.label);
    setOpening(val.opening);
    setClosing(val.closing);
  }
  //onchange selected mobile
  function logChange1() {
    const city_id = document.getElementById("citySelected").value;
    const el = document.querySelector("#citySelected #opt_" + city_id);
    // alert(city_id + city_branch)
    setBranch2(el.dataset.id);
    setCityId(city_id);
    setShop(el.dataset.name);
    setOpening(el.dataset.opening);
    setClosing(el.dataset.closing);
  }
  //toggle modal and body scroll
  const toggleCityModal = () => {
    setBranch2("2");
    setCityId("11");
    setShop("Frozenlink - Paranaque");
    setOpening("08:00");
    setClosing("16:00");
    setVisible(!visible);
    body.classList.add("body_class");
  };

  const cityGroup = [
    {
      cityName: "Metro Manila",
      index: "1",
    },
    {
      cityName: "Rizal",
      index: "2",
    },
    {
      cityName: "Cavite",
      index: "3",
    },
  ];
  //map from database
  const renderedBranch = city.map((option, index) => {
    return {
      value: option.branch_id,
      label: option.branch_name,
      label2: option.branch_id,
      opening: option.Opening,
      closing: option.Closing,
    };
  });
  const renderedCity = city.map((option, index) => {
    return {
      value: option.branch_id,
      label: option.branch_name,
      label2: option.branch_id,
      opening: option.Opening,
      closing: option.Closing,
    };
  });
  //map from database for mobile
  const renderCityGrp = cityGroup.map((item) => {
    const renderedCityMob = city.map((optionMob, index) => {
      let select = "";
      if (shop == optionMob.branch_name) {
        select = "selected";
      }
      // if (!(optionMob.city_name).includes("Cavite") && !(optionMob.city_name).includes("Rizal") && item.cityName == "Metro Manila") {

      return (
        <option
          key={index}
          value={optionMob.branch_id}
          data-id={optionMob.branch_id}
          data-name={optionMob.branch_name}
          data-opening={optionMob.Opening}
          data-closing={optionMob.Closing}
          id={`opt_${optionMob.branch_id}`}
          selected={select}
        >
          {optionMob.branch_name}
        </option>
      );
      // }
      // else if ((optionMob.city_name).includes(item.cityName)) {

      //     return (
      //         <option key={index} value={optionMob.city_id} data-id={optionMob.city_branch} data-name={optionMob.city_name} data-opening={optionMob.Opening} data-closing={optionMob.Closing} id={`opt_${optionMob.city_id}`} selected={select} >{optionMob.city_name}</option>
      //     )
      // }
    });

    return (
      <optgroup key={item.index} label={item.branch_name}>
        {renderedCityMob}
      </optgroup>
    );
  });
  return (
    <React.Fragment>
      <button
        className="btn pt-0 pb-0"
        id="cityField"
        onClick={toggleCityModal}
      >
        {isMobile ? (
          <div>
            <span>&nbsp; Deliver to &nbsp;</span>
            <br />
          </div>
        ) : (
          <div style={{ display: "inline" }}>
            <span style={{ color: "#24b14b" }}>Deliver to </span>
          </div>
        )}
        {isMobile ? (
          <div className="m-loc" style={{ display: "inline" }}>
            &nbsp;
            <svg
              style={style.iconPin}
              xmlns="http://www.w3.org/2000/svg"
              fill="#24b14b"
              viewBox="0 0 95.03 142.88"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_8" data-name="Layer 8">
                  <path d="M95,47.51a47.52,47.52,0,0,0-95,0C0,56.9,4.13,65,7.43,73l27.32,61.55a14,14,0,0,0,25.58,0l27.86-62.5h0C91.37,64,95,56.5,95,47.51ZM47.51,74.2a22,22,0,1,1,22-22A22,22,0,0,1,47.51,74.2Z" />
                  <circle
                    className="cls-1"
                    cx="47.51"
                    fill="#f4cc07"
                    cy="52.24"
                    r="21.96"
                  />
                </g>
              </g>
            </svg>
            &nbsp;
          </div>
        ) : (
          <div style={{ display: "inline" }}>
            &nbsp;
            <svg
              style={style.iconPin}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 105.03 152.88"
              fill="none"
              stroke="orange"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="10px"
              width="8"
            >
              <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_8" data-name="Layer 8">
                  <path
                    className="cls-1"
                    d="M100,52.51a47.52,47.52,0,0,0-95,0C5,61.9,9.13,70,12.43,78l27.32,61.55a14,14,0,0,0,25.58,0l27.86-62.5h0C96.37,69,100,61.5,100,52.51Z"
                  />
                  <circle className="cls-1" cx="52.51" cy="57.24" r="21.96" />
                </g>
              </g>
            </svg>
            &nbsp;
          </div>
        )}

        <b>{shop || "Frozenlink - Paranaque"} </b>

        {isMobile ? (
          <svg
            style={{ stroke: "#222", width: "24px" }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="16"
            fill="currentColor"
            className="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        ) : (
          ""
        )}
      </button>

      {isMobile ? (
        <div id="CityModal">
          <div className={`${toggleModal}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">Select Store</div>
                <div className="modal-body">
                  <div className="form-group" style={style.selectForm}>
                    <Select
                      className="form-field-name"
                      placeholder={shop}
                      defaultValue={{
                        label: shop || "Frozenlink - Paranaque",
                        value: branch2 || 11,
                      }}
                      options={renderedCity}
                      onChange={logChange}
                    >
                      {renderedCity}
                    </Select>
                  </div>
                  <span>
                    <i style={{ fontSize: "15px" }}>Heads up!</i> <br />
                    <i style={{ fontSize: "12px" }}>
                      Changing location may result in removal of some items that
                      are out of stock in that area.
                    </i>
                  </span>
                  <br />
                  <button
                    id="setID"
                    className="btn btn-primary"
                    onClick={selectCity}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div id="CityModal">
          <div className={`${toggleModal}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">Select Store</div>
                <div className="modal-body">
                  <div className="form-group" style={style.selectForm}>
                    <Select
                      className="form-field-name"
                      placeholder={shop}
                      defaultValue={{
                        label: shop || "Frozenlink - Paranaque",
                        value: branch2 || 11,
                      }}
                      options={renderedCity}
                      onChange={logChange}
                    >
                      {renderedCity}
                    </Select>
                  </div>
                  <span>
                    <i style={{ fontSize: "15px" }}>Heads up!</i> <br />
                    <i style={{ fontSize: "12px" }}>
                      Changing location may result in removal of some items that
                      are out of stock in that area.
                    </i>
                  </span>
                  <br />
                  <button
                    id="setID"
                    className="btn btn-primary"
                    onClick={selectCity}
                  >
                    Select
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SelectCity;
