/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : CheckoutCardV2
 * File Directory           : src/components/Shared/CheckoutCardV2
 * Module                   : Items
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/Search.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <CheckoutCardV2/>
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import LoadingModal from "../Modal/LoadingModal";
import { useParams } from "react-router-dom";
import Home from "../APIs/Home";
import ItemCardv2 from "./ItemCardv2";
import ItemViewModal from "./ItemViewModal";
import { set } from "react-ga";
import { LazyLoadImage } from "react-lazy-load-image-component"
import CartButtons from "./CartButtons";
import CartRemoveButton from "./CartRemoveButton";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

const style = {
    fontSize12: {
        fontSize: "12px"
    },
    fontSize10: {
        fontSize: "10px"
    },
    lineHeight12: {
        lineHeight: "12px"
    },
    imgColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'zoom-in',
        position: 'relative'
    }
}
const CheckoutCardV2 = ({
    item,
    branch,
    options,
    CartRedux
}) => {
    let accessKey = localStorage.getItem('accessKey')
    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload

    let saleTag = Math.round(((item.item_price - item.cart_price) / item.item_price) * 100); // for sale tag percent, for home page and modal
    const dispatch = useDispatch()

    // useEffect(() => {
    //     if (!isFirstRender)
    //         try {
    //             Home.post('/accessStorage/changeCart', {
    //                 itemOnCart: CartRedux,
    //                 accessToken: accessKey
    //             })
    //         } catch (err) {
    //             console.log(err.message)
    //         }
    //     else
    //         setIsFirstRender(false)
    // }, [CartRedux])

    
    return (
        <React.Fragment>
            <div className={`card item-card border-bottom ${item.item_status === `enabled` && item.item_status !== "item-remove"}`}>
                <div className={`ribbon-wrapper ${item.cart_price < item.item_price ? '' : 'd-none'}`}>
                    <div className="ribbon">
                        {Math.round(saleTag)}%
                    </div>
                </div>
                <div className="row p-0 m-0">
                    <div style={style.imgColumn} className="col-3">
                        <LazyLoadImage
                            alt={item.item_name}
                            effect="blur"
                            width="100%"
                            wrapperClassName="item-list-image img-fluid w-100"
                            src={item.item_image}
                        />
                    </div>
                    <div style={style.lineHeight12} className="col-6 p-4 pt-3 pb-3">
                        <h5
                            className="item-name"
                        >
                            {item.item_name}
                        </h5>
                        <label
                            style={style.fontSize10}
                            className="w-100"
                        >
                            {item.item_var}
                        </label>
                        {item.cart_price < item.item_price ?
                            <React.Fragment>
                                <span
                                    style={style.fontSize12}
                                    className="text-danger"
                                >
                                    ₱{item.cart_price.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}
                                </span>
                                <label
                                    style={style.fontSize10}
                                    className="w-100"
                                >
                                    <del>
                                        ₱{item.item_price.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}
                                    </del>
                                </label>
                            </React.Fragment>
                            :
                            <label
                                className="w-100"
                                style={style.fontSize12}
                            >
                                ₱{item.item_price.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })}
                            </label>
                        }
                    </div>
                    <div style={style.imgColumn} className="col-3">
                        {
                            item.item_status === "enabled" ?
                                <CartButtons item={item} />
                                :
                                <CartRemoveButton item={item} />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        CartRedux: state.cart.CartList,
    };
};

export default connect(mapStateToProps)(CheckoutCardV2);