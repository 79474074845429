
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : FAQs
 * File Directory           : src/components/Misc/FAQs
 * Module                   : Frequently Asked Queations
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To view the Frequently Asked Questions with Zagana
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   FAQs        -   component for FAQs
 *
 * #How to call:
 *    <FAQs />
 *
 *******************************************************************************************/


import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Header from "../Shared/Header";
import MetaTags from 'react-meta-tags';
import DesktopHeader from '../Shared/DesktopHeader';
import { connect, useDispatch } from "react-redux";
import LoginModal from '../Shared/LoginModal';
import { storeFrontLogs } from "../APIs/Logs";

const styles = {
    header: {
        backgroundColor: 'white',
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    card: {
        backgroundColor: '#F5F5F8',
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed',
        'zIndex': '2'
    },
    greenText: {
        color: "rgb(36, 177, 75)",
        cursor: "pointer",
    },
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'left'
    }
}
function FAQs({ showModal }) {
    document.title = "Zagana | FAQs"
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || FAQs`,
            type: 'view_faqs',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }, [])
    const details = [
        {
            questionTitle: "Where do you deliver?",
            answer: "We can deliver in Metro Manila, Rizal, Cavite, and very soon in Pampanga."
        },
        {
            questionTitle: "What kind of items do you sell?",
            answer: "Our item category includes farm-fresh fruits and vegetables; frozen meat, poultry, and seafood; rice, dairy & eggs, frozen foods, desserts, herbs & spices, and beverages."
        },
        {
            questionTitle: "What are your modes of payment?",
            answer: <>The payment options you can choose from are: <br />
                1. Cash on delivery <br />
                2. Debit/Credit card <br />
                3. GCash <br />
                4. Grabpay <br />
            </>
        },
        {
            questionTitle: "How long will it take to receive my order?",
            answer: "We can deliver in as fast as 30 minutes or in the same day that you order."
        },
        {
            questionTitle: "I have a concern with my order. Where can I reach you?",
            answer: (<> For concerns and other suggestions,
                you may reach us on our official Facebook Page and Instagram account - Zagana Farm-To-Kitchen (@zagana.com.ph).
                Our customer success representative will assist you as soon as they can.
                You may also send an email to <strong style={styles.greenText}>hello@zagana.com </strong>
                or contact us at <a href="tel:09176294376"> <strong style={styles.greenText}>09176294376</strong></a>. </>)
        },
        {
            questionTitle: "Where else can I buy?",
            answer: "Aside from our website - www.zagana.com, you can also place your orders through Grab, foodpanda, PickARoo, Shopee, Lazada, Metromart, and Zappy."
        }
    ]
    const [hidden, setHidden] = useState([true, true, true, true, true, true])
    const [refresh, setRefresh] = useState(true)
    const isMobile = localStorage.getItem("isMobile") === "true"
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal



    function clickCard(category) {
        let tempHidden = hidden;
        tempHidden[category] = !hidden[category]
        setHidden(tempHidden)
        setRefresh(!refresh)
    }

    return (
        <React.Fragment>
            <MetaTags>
                <title>Zagana | FAQs</title>
                <meta name="description" content="Frequently asked questions about Zagana" />
                <meta property="og:title" content="Zagana | FAQs" />
                <meta property="og:image" content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" />
                <meta property="og:url" content="https://www.zagana.com/ContentPage/FAQs" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Zagana Inc." />
                <meta name="keywords" content="farm-fresh, fresh, produce, local, frozen, farmers, quality, introduction, online grocery, online palengke, faqs, asked, questions, frequently, frequently asked questions" />
                <meta name="author" content="Zagana Inc." />
            </MetaTags>
            {isMobile ?
                <div style={{ position: 'fixed', zIndex: '2' }} className="p-0" >
                    <Header title="FAQs" />
                </div>
                :
                <div style={{ position: 'fixed', zIndex: '2' }} className="p-0" >
                    <DesktopHeader
                        visibleLogin={visibleLogin}
                        setVisibleLogin={setVisibleLogin} />
                </div>}
            <div className="container p-3 mt-5">
                <div className="row" style={{ 'paddingBottom': '20%' }}>
                    <div className="col-xs-1 col-lg-2" />
                    <div className="col-xs-10 col-lg-8" >
                        {isMobile ? null : <h1>FAQs</h1>}
                        {details.map((key, i) => {
                            return (
                                <Card key={key.questionTitle} onClick={() => clickCard(i)}>
                                    <Card.Header as="h6" style={styles.header}>{key.questionTitle}</Card.Header>
                                    <Card.Body hidden={hidden[i]} style={styles.card}>
                                        <Card.Text>
                                            {key['answer']}
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            )
                        })}
                    </div>
                    <div className="col-xs-1 col-lg-2" />
                </div>
            </div>


            <LoginModal
                visible={visibleLogin}
                modalToggle={modalShow}
            />
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal
    }
}

export default connect(mapStateToProps)(FAQs);