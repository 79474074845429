export const specialProduct = [
    {
        sku: "ZGN00001815",
        rate: 5,
        hookline: "Try the world's best soup with the best ingredients!",
        bullets: [
            {
                image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1662710892/special%20product/pork%20sinigang/Pork_Sinigang_GIF_Square_gyus76.gif",
                title: "Benifits",
                items: [
                    'Frozen fresh meat to preserve quality',
                    'Fresh, healthy, and quality Pork Sinigang ingredients',
                    'Same day delivery',
                    'Flat rate shipping fee',
                    'Flexible payment options with COD'
                ]
            },
            {
                image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1662710886/special%20product/pork%20sinigang/Pork_Sinigang-1_e6jqxd.jpg",
                title: "Specs & Packaging",
                items: [
                    'Vacuum sealed to guarantee safety',
                    '1kg - 50% lean meat, 30% fat, 20% bones',
                    'Total of 3 Individually packed Pork Sinigang Cut 1kg',
                    'Pre-cut into cubes for Sinigang dish',
                    'From pigue or kasim part of the pig'
                ]
            }
        ],
        relatedIngredientsTitle: "Sinigang",
        relatedIngredients: [ // sku list
        "ZGN00001698","ZGN00001339-2","ZGN00000818-2","ZGN00001734","ZGN00000800-2","ZGN00000429","ZGN00004091-2","ZGN00000817-2"
        ],
        reviewsImgs:[
            {
                desktop: "https://res.cloudinary.com/dw5axbz64/image/upload/v1663070379/icons/banner-desktop-3_ITEMS_tkiscv.png",
                mobile: [
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1662980849/icons/banner-01_wrcy41.jpg',
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1662980849/icons/banner-02_ggtgya.jpg',
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1663069719/icons/3rd_banner_mobile_1_rr3eb4.png'
                ]
            }
        ]
    },
    {
        sku: "ZGN00001826",
        rate: 5,
        hookline: "Bring nostalgia with every sip of a classic chicken soup - Chicken Tinola!",
        bullets: [
            {
                image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1664245741/special%20product/tinolang%20manok/Tinolang_Manok_GIF_Square_xzaai2.gif",
                title: "Benefits",
                items: [
                    'Frozen fresh Chicken Drumstick and Thigh to preserve quality',
                    'Fresh, healthy, and quality Tinolang Manok ingredients',
                    'Same day delivery',
                    'Flat rate shipping fee',
                    'Flexible payment options with COD'
                ]
            },
            {
                image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1664245739/special%20product/tinolang%20manok/Tinolang_Manok_1_ncf18z.jpg",
                title: "Specs & Package inclusions",
                items: [
                    'Vacuum sealed to guarantee safety',
                    '1kg per package - Approximately 3 pcs drumstick and 3 pcs thigh',
                    'Total of 3 individually packed Chicken Drumstick and Thigh 1kg',
                    'Parts are bone-in and with skin',
                    'NMIS approved'
                ]
            }
        ],
        relatedIngredientsTitle: "Tinola",
        relatedIngredients: [ // sku list
            'ZGN00000888','ZGN00001701','ZGN00000817-2','ZGN00001699','ZGN00000717','ZGN00000807'
        ],
        reviewsImgs:[
            {
                desktop: "https://res.cloudinary.com/dw5axbz64/image/upload/v1664245919/special%20product/tinolang%20manok/Review-Banner-PCDesktop_qlelc4.jpg",
                mobile: [
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1664245946/special%20product/tinolang%20manok/Review-1-Mobile_kchqss.png',
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1664245932/special%20product/tinolang%20manok/Review-2-Mobile_grzh5t.png',
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1664245921/special%20product/tinolang%20manok/Review-3-Mobile_adf1nn.png'
                ]
            }
        ]
    },
    {
        sku: "ZGN00001827",
        rate: 5,
        hookline: "Let every sip and bite bring you to the best Bulalo places without leaving your house.",
        bullets: [
            {
                image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1665562756/special%20product/Bulalo/Bulalo_GIF_2_uvvf3l.gif",
                title: "Benefits",
                items: [
                    'Frozen fresh Beef Shanks to preserve quality',
                    'Fresh, healthy, and quality Bulalo ingredients',
                    'Same day delivery',
                    'Flat rate shipping fee',
                    'Flexible payment options with COD'
                ]
            },
            {
                image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1665558582/special%20product/Bulalo/Beef_Nilaga_2_gr5wkg.jpg",
                title: "Specs & Package inclusions",
                items: [
                    'Vacuum sealed to guarantee safety',
                    '500g per package - 1/2 to 1 inch thick per slice and 1-2 slices per pack ',
                    'Total of 3 individually packed Beef Shanks 500g',
                    'Bone marrow surrounded by low-fat meat',
                    'NMIS approved'
                ]
            }
        ],
        relatedIngredientsTitle: "Bulalo",
        relatedIngredients: [ // sku list
            'ZGN00000515','ZGN00001360-2','ZGN00000020-1','ZGN00000708-2','ZGN00000429','ZGN00000814-2'
        ],
        reviewsImgs:[
            {
                desktop: "https://res.cloudinary.com/dw5axbz64/image/upload/v1665559732/special%20product/Bulalo/reviews/deskrev_mytqnm.jpg",
                mobile: [
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1665559731/special%20product/Bulalo/reviews/1rev_tpdzdg.jpg',
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1665559731/special%20product/Bulalo/reviews/2rev_n46okm.jpg',
                    'https://res.cloudinary.com/dw5axbz64/image/upload/v1665559732/special%20product/Bulalo/reviews/3rev_o3xfbe.jpg'
                ]
            }
        ]
    }
]