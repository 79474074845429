//  * File Name                : KitchenTips
//  * File Directory           : src/components/Misc/RecipePage/KitchenTips
//  * Module                   : KitchenTips
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/App.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <KitchenTips/>
 *
 *******************************************************************************************/


// import react library
import React from "react";
// import use params 
import { useParams } from "react-router-dom";
// import header component
import RecipeHeader from "./Components/Header";
// import Kitchen tips list
import { KitchenTipsList } from "./recipeList";
const KitchenTips = () => {
    // set parameters as params
    const params = useParams()
    // filter KitchenTips list selected Kitchen tips video only
    const filterTutorial = KitchenTipsList.filter(el => el.name === params.tips)
    // filter list for other kithen tips 
    const filterList = KitchenTipsList.filter(el => el.name !== params.tips)
    // if no match found on params redirect to main page
    if (filterTutorial.length === 0) {
        // redirect to main page if no match found
        window.location.href = "/ContentPage/recipe"
    }

    // render other video list 
    const renderOtherList = filterList.map(tips => {
        // onClick side Videos
        const onOtherVidClick = () => {
            // redirect to page on click with video on params
            window.location.href = `/ContentPage/recipe/KitchenTips/${tips.name}`
        }
        return (
            <li className="other-kt-li">
                <div className="container p-0">
                    <div className="row other-kt" onClick={onOtherVidClick}>
                        <div className="col-sm other-kt-vid">
                            <video width="100%" height="100%" >
                                <source src={tips.video} type="video/mp4"/>
                            </video>
                        </div>
                        <div className="col-sm other-kt-desc">
                            <div className="row">
                                <span>{tips.name}</span>
                            </div>
                            <div className="row">
                                <p>{tips.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    })
    // render list length
    let renderOtherListLength = renderOtherList.length
    return(
      <div className="kitchen-tips">
          <div className="row">
            <RecipeHeader />
          </div>
          <div className="row mt-50">
              <div className="container kitchen-tips-video-wrapper">
                <div className="row">
                    <div className="col-sm-8 kitchen-tips-video">
                        <div className="collection kitchen-tips-video-margin">
                            <div className="row kt-video">
                                <video width="100%" height="100%" controls >
                                    <source src={filterTutorial[0].video} type="video/mp4"/>
                                </video>
                            </div>
                            <div className="row kt-desc">
                                <h3 className="mt-20">
                                    {filterTutorial[0].name }
                                </h3>
                                <p>
                                    {filterTutorial[0].description}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 kitchen-tips-recommend">
                        <div className="collection kitchen-tips-recommend-vids">
                            <div className="container">
                                <div className="row">
                                    <h4 className="mt-10">Zagana Kitchen Tips:</h4>
                                </div>
                                <div className="row other-kt-height">
                                    <ul>
                                        {renderOtherListLength < 1 ? <p className="centeredText">No Result</p> : renderOtherList}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
          </div>
      </div>
    )
}

export default KitchenTips