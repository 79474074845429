const INTIAL_STATE = {
    Secret_discount_code: "Enter referral",
    Secret_discount_amount: 0,
    Secret_discount: false
}

const SecretDiscountReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_SECRET_DISCOUNT':
            return {
                ...state,
                Secret_discount_code: action.code,
                Secret_discount_amount: action.amount,
                Secret_discount: action.discount
            };
        default:
            return state;
    }

};

export default SecretDiscountReducer;