
import React, { useState, useEffect } from "react";
import CartCardv2 from './CartCardv2';
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Home from "../APIs/Home";
import Home_api from '../APIs/Home';
import NoProducts from "./NoProducts";
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga4'

const style = {
  incrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    float: "right",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  decrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#white",
    color: "#24b14b",
    fontWeight: "bold",
    float: "right",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  incrementButtonColorDesktop: {
    border: "none",
    borderRadius: "25px",
    background: "transparent",
    color: "#24b14b",
    fontWeight: "bold",
    float: "left",
    fontSize: "14px",
    padding: "0",
    width: "20px",
    height: "20px"
  },
  decrementButtonColorDesktop: {
    border: "none",
    borderRadius: "25px",
    background: "transparent",
    color: "#24b14b",
    fontWeight: "bold",
    float: "left",
    fontSize: "14px",
    padding: "0",
    width: "20px",
    height: "20px"
  },
  textField: {
    textAlign: "center",
    float: "right",
    background: "transparent",
    margin: "3px"
  },
  textFieldDesktop: {
    textAlign: "center",
    float: "left",
    background: "transparent",
    margin: "3px"
  },
  imgColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: 'zoom-in',
    position: 'relative'
  },
  textItemTitle: {
    fontSize: "1rem",
    cursor: 'zoom-in'
  },
  textItemTitleDesktop: {
    fontSize: "1rem",
    height: "45px",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box"
  },
  textItemVariant: {
    fontSize: "0.7rem"
  },
  textItemPrice: {
    fontSize: "0.8rem"
  },
  textItemPriceDesktop: {
    fontSize: "0.8rem",
    float: 'right'
  },
  btnDiv: {
    float: 'right',
    width: '125px',
    paddingRight: '1rem'
  },
  btnCheckout: {
    cursor: 'pointer',
    background: 'rgb(244, 204, 14)',
    textDecoration: 'none',
    display: 'block',
    textAlign: 'center'
  },
  footer: {
    display: "inline-block",
    height: "100%",
    color: 'black'
  }
};
const Cart = ({ cartModalShow, total, visibleCart, setVisibleCart, cart, CartRedux, pathname, setVisibleLogin, setTotal }) => {
  let accessKey = localStorage.getItem('accessKey')
  const isLogged = sessionStorage.getItem("Login") || '';
  const isMobile = localStorage.getItem("isMobile") === "true";
  const history = useHistory()
  const dispatch = useDispatch()
  const [location, setLocation] = useState(pathname)
  const [totalCount, setTotalCount] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload
  const [lastCart, setLastCart] = useState([]) //useeffect should not be triggered onload
  useEffect(() => {
    if (!isFirstRender) {
      setLastCart(CartRedux)
      try {
        Home.post('/accessStorage/changeCart', {
          itemOnCart: CartRedux,
          accessToken: accessKey
        })
      } catch (err) {
        console.log(err.message)
      }
    }
    else
      setIsFirstRender(false)
    //computing total cart price and qty
    let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
    let itemCount = 0
    CartRedux.forEach((cart) => { itemCount += cart.item_status === "enabled" ? cart.cart_qty : 0 }) // returning qty per item
    totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
    // itemCount = itemCount.reduce((a, b) => a + b, 0) // total of all items
    // setTotal({ itemTotal: totalPrice, itemCount: itemCount })
    setTotalCount(itemCount)
    setTotalPrice(totalPrice)
  }, [CartRedux])
  function objectsAreSame(x, y) {
    var objectsAreSame = true;
    for (var propertyName in x) {
      if (x[propertyName] !== y[propertyName]) {
        objectsAreSame = false;
        break;
      }
    }
    return objectsAreSame;
  }
  let body = document.body; // for setting body height (modal)

  const GACart = CartRedux.map((item, index) => {
    return {
      item_id: item.item_sku,
      item_name: item.item_name,
      currency: "PHP",
      index: index,
      item_brand: "Zagana",
      item_category: item.category,
      price: item.item_price,
      quantity: item.cart_qty
    }
  })

  //trigger open login modal
  const openLoginModal = () => {

    if (isLogged === '') {
      setVisibleLogin("false");
      body.classList.add("body_class");
    }
    window.gtag("event", "begin_checkout", {
      currency: "PHP",
      value: totalPrice,
      items: GACart
    });
  }

  const onCLickProceed = () => {
    window.gtag("event", "begin_checkout", {
      currency: "PHP",
      value: totalPrice,
      items: GACart
    });
    if (pathname === '/checkout-page' || pathname === '/sub-list') {
      setVisibleCart(!visibleCart)
      if (isMobile) {
        history.push('/checkout-page')
      }
    } else {
      if (isMobile) {
        dispatch({
          type: "SET_LOCATION",
          location: '/checkout-page'
        })
        history.push('/checkout-page')
      } else {
        dispatch({
          type: "SET_LOCATION",
          location: window.location.pathname
        })
        history.push('/checkout-details')
      }
    }
  }

  useEffect(() => {
    //dbStorage
    // function uuidv4() {
    //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
    //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    //         return v.toString(16);
    //     });
    // }
    let link = uuidv4();
    const accessStorage = async (status) => {
      await Home_api.post('/accessStorage', { accessToken: link })
      localStorage.setItem('accessKey', link)
      if (status === 'tokenNotFound') {
        window.location.reload()
      }
    }
    const getCart = async () => {
      let response = await Home_api.post('/cartChecker', { accessToken: accessKey })
      // console.log(response.data)
      if (response.data.result === "AccessTokenNotFound") {
        // ("PASOK5")
        localStorage.setItem('branchSelected', false)
        accessStorage('tokenNotFound')
      }
      else {
        // ("PASOK8")
        let itemsOnCart = response.data.cart || []
        dispatch({
          type: "SET_CART",
          selected: itemsOnCart,
        });
      }


    }
    if (cartModalShow === "modal cart ")
      getCart()
  }, [cartModalShow])
  //change item image
  const renderedItems = CartRedux.slice(0).reverse().map((item, index) => {
    let photo = item.item_image
    photo = photo === null ? "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" : photo
    return (<CartCardv2 key={index} item={item} />)
  })

  return (
    <div id="cartModal">
      <div className={cartModalShow} id={`${isMobile ? 'cartShowed' : 'cartshow'}`} tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document" style={{ height: "100%", maxWidth: "100%" }}>
          <div className="modal-content" style={{ height: "100%" }}>
            {
              CartRedux.length > 0 ?
                <React.Fragment>
                  <div className="modal-header p-2 pb-1 d-block">
                    <button className=" p-2 btn btn-transparent" style={{ float: "right", fontSize: "18px", padding: "0" }} onClick={() => { setVisibleCart(!visibleCart) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                      </svg>
                    </button>
                    <h5 className="p-2">{totalCount} Item/s</h5>
                  </div>
                  <div className="modal-body p-0 ">
                    {renderedItems}
                  </div>
                  {isMobile ?
                    <div>
                      <a className="modal-footer p-2" style={style.btnCheckout} onClick={onCLickProceed}>
                        <div className="  row">
                          <div className="col-5 p-0" style={style.footer}>Total: ₱{totalPrice.toLocaleString(2)}
                          </div>
                          <div className="col-7 p-0" style={style.footer}> Proceed To Checkout
                          </div>
                        </div>
                      </a>
                    </div>
                    :
                    <a className="modal-footer p-2" style={style.btnCheckout} href={`${isLogged !== '' ? '/checkout-details' : '#checkout'}`} onClick={openLoginModal}>
                      <div className="  row">
                        <div className="col-6 p-0" style={style.footer}>Total: ₱{totalPrice.toLocaleString(2)}
                        </div>
                        <div className="col-6 p-0" style={style.footer}> Place order
                        </div>
                      </div>
                    </a>
                  }
                </React.Fragment> :
                <div id="mobCartEmpt" style={{ textAlign: "end", padding: "10px" }}>
                  <div className="modal-header p-0 d-block">
                    <button className="p-2 btn btn-transparent" onClick={() => { setVisibleCart(!visibleCart) }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                      </svg>
                    </button>
                    {isMobile ?
                      ""
                      :
                      <h5 className="p-2">{totalCount} Item/s</h5>
                    }
                  </div>
                  <div className="text-center modal-body p-2">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                      viewBox="0 0 231.523 231.523" style={{ enableBackground: "new 0 0 231.523 231.523", width: "35%" }} >
                      <g>
                        <path d="M107.415,145.798c0.399,3.858,3.656,6.73,7.451,6.73c0.258,0,0.518-0.013,0.78-0.04c4.12-0.426,7.115-4.111,6.689-8.231
                         l-3.459-33.468c-0.426-4.12-4.113-7.111-8.231-6.689c-4.12,0.426-7.115,4.111-6.689,8.231L107.415,145.798z"/>
                        <path d="M154.351,152.488c0.262,0.027,0.522,0.04,0.78,0.04c3.796,0,7.052-2.872,7.451-6.73l3.458-33.468
                         c0.426-4.121-2.569-7.806-6.689-8.231c-4.123-0.421-7.806,2.57-8.232,6.689l-3.458,33.468
                         C147.235,148.377,150.23,152.062,154.351,152.488z"/>
                        <path d="M96.278,185.088c-12.801,0-23.215,10.414-23.215,23.215c0,12.804,10.414,23.221,23.215,23.221
                         c12.801,0,23.216-10.417,23.216-23.221C119.494,195.502,109.079,185.088,96.278,185.088z M96.278,216.523
                         c-4.53,0-8.215-3.688-8.215-8.221c0-4.53,3.685-8.215,8.215-8.215c4.53,0,8.216,3.685,8.216,8.215
                         C104.494,212.835,100.808,216.523,96.278,216.523z"/>
                        <path d="M173.719,185.088c-12.801,0-23.216,10.414-23.216,23.215c0,12.804,10.414,23.221,23.216,23.221
                         c12.802,0,23.218-10.417,23.218-23.221C196.937,195.502,186.521,185.088,173.719,185.088z M173.719,216.523
                         c-4.53,0-8.216-3.688-8.216-8.221c0-4.53,3.686-8.215,8.216-8.215c4.531,0,8.218,3.685,8.218,8.215
                         C181.937,212.835,178.251,216.523,173.719,216.523z"/>
                        <path d="M218.58,79.08c-1.42-1.837-3.611-2.913-5.933-2.913H63.152l-6.278-24.141c-0.86-3.305-3.844-5.612-7.259-5.612H18.876
                         c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h24.94l6.227,23.946c0.031,0.134,0.066,0.267,0.104,0.398l23.157,89.046
                         c0.86,3.305,3.844,5.612,7.259,5.612h108.874c3.415,0,6.399-2.307,7.259-5.612l23.21-89.25C220.49,83.309,220,80.918,218.58,79.08z
                          M183.638,165.418H86.362l-19.309-74.25h135.895L183.638,165.418z"/>
                        <path d="M105.556,52.851c1.464,1.463,3.383,2.195,5.302,2.195c1.92,0,3.84-0.733,5.305-2.198c2.928-2.93,2.927-7.679-0.003-10.607
                         L92.573,18.665c-2.93-2.928-7.678-2.927-10.607,0.002c-2.928,2.93-2.927,7.679,0.002,10.607L105.556,52.851z"/>
                        <path d="M159.174,55.045c1.92,0,3.841-0.733,5.306-2.199l23.552-23.573c2.928-2.93,2.925-7.679-0.005-10.606
                         c-2.93-2.928-7.679-2.925-10.606,0.005l-23.552,23.573c-2.928,2.93-2.925,7.679,0.005,10.607
                         C155.338,54.314,157.256,55.045,159.174,55.045z"/>
                        <path d="M135.006,48.311c0.001,0,0.001,0,0.002,0c4.141,0,7.499-3.357,7.5-7.498l0.008-33.311c0.001-4.142-3.356-7.501-7.498-7.502
                         c-0.001,0-0.001,0-0.001,0c-4.142,0-7.5,3.357-7.501,7.498l-0.008,33.311C127.507,44.951,130.864,48.31,135.006,48.311z"/>
                      </g>
                    </svg>
                    <NoProducts />
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>)
}

const mapStateToProps = (state) => {
  return {
    CartRedux: state.cart.CartList,
    pathname: state.Location.location
  }
}

export default connect(mapStateToProps)(Cart);