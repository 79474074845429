/*******************************************************************************************
 *
 * Copyright (C) 2022 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : AffiliateMarketing
 * File Directory           : src/components/Affiliate/
 * Module                   : Affiliate
 * Author                   : Ava Alimurong
 * Version                  : 1
 * Purpose                  : View Affiliate  
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   AffiliateMarketing        -   component for viewing Affiliate Marketers Store Credits
 *
 * #How to call:
 *    /AffiliateMarketing
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";

//redux
import { reduxForm, Field } from "redux-form";
import { connect, useDispatch } from "react-redux";


//router
import { Link, useHistory } from "react-router-dom";

// API
import Home from "../APIs/Home";
import FbLogin from "../APIs/LoginAPI/FbLogin";
import Backend from "../APIs/Backend"
//assets
import loginLogo from "../../assets/logo/zagana-logo.png";

//bootstrap
import { Table } from "react-bootstrap";
import _ from "lodash"


const style = {
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'center'
    },
    formGroup: {
        marginBottom: "6%",
        position: "relative",
    },
    errorStyle: {
        position: "absolute",
        color: "red",
    },
    formInput: {
        width: "100%",
    },
    backBtn: {
        color: "#6c757d",
        textDecoration: "none",
    },
    oneMore: {
        color: '#f87f00',
        backgroundColor: '#f87f0047',
        borderColor: '#f5c2c7'
    },
    login: {
        borderRadius: '20px'
    },
    padding5: {
        padding: '5%'

    }
}

//to show error
const renderError = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span className="error" style={style.errorStyle}>
                    {error}
                </span>
                <span className="invalid " style={style.errorStyle}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-x-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    } else if (!error) {
        return (
            <div>
                <span className="valid">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-check-circle"
                        viewBox="0 0 16 16"
                    >
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </span>
            </div>
        );
    }
};

//to show input via redux
const renderInput = ({
    input,
    type,
    meta,
    value_name,
    class_name,
    value_check,
}) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""} ${!meta.error ? "valid" : ""
        }`;
    let label_radio = value_name;
    if (value_name === "Radio") {
        return <label> {value_name}</label>;
    }
    return (
        <div className={className}>
            <input
                {...input}
                type={type}
                value={value_name}
                autoComplete="off"
                className={class_name}
                checked={value_check}
                style={style.formInput}
            />
            {label_radio}
            <div className="form-control-feedback">{renderError(meta)}</div>
        </div>
    );
};

//to validate field values
const validate = (values) => {
    const errors = {};
    var emailCheck =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (!values.customerEmail) {
        errors.customerEmail = "You must enter your Email";
    }
    if (values.customerEmail) {
        if (emailCheck.test(values.customerEmail) === false) {
            errors.customerEmail = "Invalid Email Format";
        }
    }
    if (!values.customerPassword) {
        errors.customerPassword = "You must enter your Password";
    }

    return errors;
};


const AffiliateMarketing = ({ valid, affiliate }) => {
    document.title = "Zagana | Affiliate Marketing"
    const dispatch = useDispatch()
    const history = useHistory();
    const [data, setData] = useState([])
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [confirmContactModalStatus, setConfirmContactModalStatus] = useState()
    const [invalid, setInvalid] = useState("");
    const isMobile = localStorage.getItem("isMobile");
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker - true if naka login, false not yet
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let userId = getUser.customer_id
    let body = document.body; // for setting body height (modal)
    let showModal = false;
    let user;
    let userLogin;
    // if (getUser) {
    //     userLogin = JSON.parse(sessionStorage.getItem('Login'))
    //     gen_id = localStorage.getItem('gen_id')
    //     user = userLogin.customer_name + ` (${gen_id})`
    //   } else if (gen_id) {
    //     user = gen_id
    //   }

        // loading data
        useEffect(() => {
            if (isLogged) {
                const fetchAffiliate = async () => {
                    const response = await Backend.post(`/apiV2/affiliateInfo/${userId}`)
                    let userStoreCred = response.data.userStoreCred
                    let referralID = response.data.referralID
                    let used = response.data.used
                    setData({
                        userStoreCred: userStoreCred,
                        referralID: referralID,
                        used: used
                        
                    })
                }
                fetchAffiliate().catch(console.error)
            }
        }, [isLogged, userId])

    let loginModalShow = `modal login ${isLogged ? 'showModal' : ''}`; // class for show modal

    const handleSubmit = (e) => {
        e.preventDefault();

        // const payload = {
        //     user: user,
        //     changes: `Zagana || Login`,
        //     type: 'click_login',
        //     date: `NOW()`
        // }
        //        storeFrontLogs(payload)

        body.classList.remove("body_class");
        body.classList.remove("body_class_");
        const values = affiliate.values;
        console.log(values, affiliate, e)
        const details = {
            customerEmail: values.customerEmail,
            customerPassword: values.customerPassword
        };
        const Login = async () => {
            try {
                const response = await Home.post(`/authenticate/login`, details);
                if (response.data.message === "Login Success") {
                    let login = response.data.loginDetails[0]
                    const loginDetails = JSON.stringify(response.data.loginDetails[0]);
                    let accessToken = localStorage.getItem('accessKey')
                    let result = Home.post(`/accessStorage/Login`, {
                        accessToken: accessToken,
                        loginDetails: loginDetails
                    })
                    sessionStorage.setItem("Login", loginDetails);
                    sessionStorage.setItem('FBLogin', false)
                    console.log("I AM HERE")
                    // } 
                }
                setInvalid(response.data.error);
            } catch (err) {
                console.log(err);
            }
        };
        Login();
    };


    //toggle modal and body scroll
    // const closeLoginModal = () => {
    //     setVisibleLogin("true");
    //     body.classList.remove("body_class");
    //     body.classList.remove("body_class_");
    //     localStorage.removeItem('notifyItem')
    // }

    return (
        <React.Fragment>
            {/** Log in pop out if the user is not yet logged in */}
            {!isLogged ?
                <div>
                    <div id="LoginModal">
                        <div className={loginModalShow} tabIndex="-1" role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body p-0 pt-2 mb-3">
                                        {/* <button className="btn text-left" style={style.closeBtn} onClick={closeLoginModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                            </svg>
                                        </button> */}
                                        <img src={loginLogo} alt="zagana.com" width="250px" className="mx-auto mt-3 mb-3 d-block" />
                                        {invalid ? (
                                            <div className="alert alert-danger p-2 m-2">{invalid}</div>
                                        ) : (
                                            ""
                                        )}

                                        {!showModal ?
                                            <form onSubmit={handleSubmit} className="p-3 mt-2">
                                                <div
                                                    className="form-group row"
                                                    id="Email_field"
                                                    style={style.formGroup}
                                                >
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-xs-12 col-sm-5 col-lg-4 col-form-label"
                                                    >
                                                        Email
                                                    </label>
                                                    <div className="col-xs-12 col-sm-7 col-lg-8">
                                                        <Field
                                                            type="email"
                                                            name="customerEmail"
                                                            component={renderInput}
                                                            class_name="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div
                                                    className="form-group row"
                                                    id="Password_field"
                                                    style={style.formGroup}
                                                >
                                                    <label
                                                        htmlFor="staticEmail"
                                                        className="col-xs-12 col-sm-5 col-lg-4 col-form-label"
                                                    >
                                                        Password
                                                    </label>
                                                    <div className="col-xs-12 col-sm-7 col-lg-8">
                                                        <Field
                                                            type="password"
                                                            name="customerPassword"
                                                            component={renderInput}
                                                            class_name="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="row">

                                                    <div className="col my-auto d-flex align-items-center justify-content-center ">
                                                        <button
                                                            type="submit"
                                                            className={`btn  ${!valid ? "disabled" : "btn-primary"} w-100`}
                                                            disabled={!valid}
                                                            style={style.login}
                                                        >
                                                            Login
                                                        </button>
                                                    </div>
                                                    <div className="mt-4 text-center">
                                                        <small>or login with:</small>
                                                        <FbLogin />
                                                    </div>
                                                </div>
                                                {isMobile === "true" ?
                                                    <div className="col my-auto text-center mt-2">
                                                        <Link to="/register" style={style.backBtn}>
                                                            Sign Up
                                                        </Link>
                                                    </div>
                                                    : ""
                                                }
                                            </form> : "bye"
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {/**if logged in already, no pop out -- direct to see the table */}

            <div className={style.padding5}>
                Total Store Credit: {data['userStoreCred']}<br />
                Referral Code: {data['referralID']} <br />
                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Order ID#</th>
                            <th>Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.get(data, ['used'], []).map((key, i) => {
                            return (
                                <tr key={i}>
                                    <td>{key["orderNumber"]}</td>
                                    <td>{key["orderCredit"]}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    console.log(state.form.AffiliateMarketing)
    return {
        showModal: state.ModalLogin.showModal,
        affiliate: state.form.AffiliateMarketing
    }
}

export default connect(mapStateToProps)(
    reduxForm({
        form: "AffiliateMarketing",
        validate,
    })(AffiliateMarketing)
);