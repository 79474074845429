const INTIAL_STATE = {
    credit   : 0,
    isApplied : false
};

const StoreCredit= (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'STORE_CREDIT':
            return {
                ...state,
                credit : action.credit,
                isApplied : action.isApplied
            };
        default:
            return state;
    }

};

export default StoreCredit;
