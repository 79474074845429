/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : CartCardV2
 * File Directory           : src/components/Shared/CartCardV2
 * Module                   : Items
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/Search.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <CartCardV2/>
 *******************************************************************************************/
 import React, { useEffect, useState } from "react";
 import LoadingModal from "../Modal/LoadingModal";
 import { useParams } from "react-router-dom";
 import Home from "../APIs/Home";
 import ItemCardv2 from "./ItemCardv2";
 import ItemViewModal from "./ItemViewModal";
 import { set } from "react-ga";
 import { LazyLoadImage } from "react-lazy-load-image-component"
 import CartButtons from "./CartButtons";
 import CartRemoveButton from "./CartRemoveButton";
 import { connect } from "react-redux";
 import { useDispatch } from "react-redux";

 const style = {
     fontSize12: {
         fontSize: "12px"
     },
     fontSize10: {
         fontSize: "10px"
     },
     lineHeight12: {
         lineHeight: "12px"
     },
     imgColumn: {
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
         cursor: 'zoom-in',
         position: 'relative'
     }
 }
 const CartCardv2 =({ item, CartRedux}) => {
         let saleTagCO = Math.round(((item?.item_price - item?.cart_price) / item?.item_price) * 100); // for sale tag percent, for checkout page
         let saleTag = Math.round(((item?.item_price - item?.cart_price) / item?.item_price) * 100); // for sale tag percent, for home page and modal
         return (
             <React.Fragment key={item.item_id}>

                 <div className={`card border-bottom ${item?.item_status !== "enabled" && "item-remove"}`}>
                     <div className={`ribbon-wrapper ${item?.cart_price < item?.item_price ? '' : 'd-none'}`}>
                         {
                             window.location.pathname === '/checkout-details' ?
                                 <div className="ribbon">
                                     {Math.round(saleTagCO)}%
                                 </div>
                                 :
                                 <div className="ribbon">
                                     {Math.round(saleTag)}%
                                 </div>

                         }
                     </div>
                     <div className="row p-0 m-0">
                         <div style={style.imgColumn} className="col-4">
                             <LazyLoadImage
                                 alt={item?.item_name}
                                 effect="blur"
                                 width="100%"
                                 wrapperClassName={`item-list-image img-fluid w-100 ${(window.location.pathname).includes("productPage")&&"cursor-disable"}`}
                                 src={item?.item_image}
                             />
                         </div>
                         <div style={style.lineHeight12} className=" col-8 p-4 pt-2 pb-2">
                             <div className="row p-0">
                                 <div className="col-12">
                                     <h5
                                         className="item-name"
                                     >
                                         {item?.item_name}
                                     </h5>
                                     <label
                                         style={style.fontSize10}
                                         className="w-100"
                                     >
                                         {item?.item_var}
                                     </label>
                                     {item?.cart_price < item?.item_price ?
                                         <React.Fragment>
                                             <b>
                                                 <span
                                                     style={style.fontSize12}
                                                     className="text-danger"
                                                 >
                                                     ₱{item?.cart_price.toLocaleString(undefined, {
                                                         minimumFractionDigits: 2,
                                                         maximumFractionDigits: 2
                                                     })}
                                                 </span>
                                             </b>
                                             <label
                                                 style={style.fontSize10}
                                                 className="w-100 text-muted"
                                             >
                                                 <del>
                                                     ₱{item?.item_price.toLocaleString(undefined, {
                                                         minimumFractionDigits: 2,
                                                         maximumFractionDigits: 2
                                                     })}
                                                 </del>
                                             </label>
                                         </React.Fragment>
                                         :
                                         <b>
                                             <span
                                                 className="w-100"
                                                 style={style.fontSize12}
                                             >
                                                 ₱{item?.item_price.toLocaleString(undefined, {
                                                     minimumFractionDigits: 2,
                                                     maximumFractionDigits: 2
                                                 })}
                                             </span>
                                         </b>
                                     }
                                 </div>
                                 <div className="col-lg-12 col-sm-12 text-end">
                                     {
                                         item?.item_status === "enabled" ?
                                             <CartButtons item={item} />
                                             :
                                             <CartRemoveButton item={item} />
                                     }
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
             </React.Fragment>
         )
     }


 const mapStateToProps = (state) => {
     return {
         CartRedux: state.cart.CartList,
     };
 };

 export default connect(mapStateToProps)(CartCardv2);
