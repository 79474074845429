const OrderIDReducer = (state = {}, action) => {
    switch (action.type) {
        case 'ORDER_ID':
            return {
                ...state, 
                order_id: action.id
            };
        default:
            return state;
    }

};

export default OrderIDReducer;