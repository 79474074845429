/*******************************************************************************************
//  * File Name                : LoadingModal
//  * File Directory           : src/components/Home/LoadingModal
//  * Module                   : LoadingModal
//  * Author                   : Jake
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : ItemList
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   LoadingModal        -   component for pop up item details
 *
 * #How to call:
 *    <LoadingModal/>
 *
 * Note:
 *******************************************************************************************/
 import React, { useState} from "react";
 import siteLoader from "../../assets/logo/Zagana_Site_Preloader.gif";
 
 const style = {
   showModal: {
     'display': 'block',
     'background': 'rgba(0, 0, 0, 0.5)',
     'transition': 'all 0.8s ease',
     'top': '0px',
   }
}
 const LoadingModal = ({ modalStatus}) => {
 
  const isMobile = localStorage.getItem("isMobile") || "true"
   
   return (
     <React.Fragment>
 
       <div id="itemModal">
         <div className={`modal ${modalStatus ? 'd-flex' : ''} align-items-center justify-content-center`}
           style={modalStatus ? style.showModal : {}}
           tabIndex="-1"
           role="dialog">
           <img className={`mx-auto my-auto ${modalStatus ? 'd-block' : 'd-none'}`} src={siteLoader} alt="About Us" height={`${isMobile==='true'?'150':'250'}`} width={`${isMobile==='true'?'150':'250'}`} />
         </div>
       </div>
     </React.Fragment>
   );
 };
 
 export default LoadingModal;