import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import { storeFrontLogs } from "../APIs/Logs";
import Header from '../Shared/Header';
import ItemList from "../Shared/ItemList";
import { searchToDatabase } from '../Shared/SaveToDatabase';

const style = {
    btnClear: {
        position: 'absolute',
        right: '10px'
    }
}
let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const Search = () => {
    document.title = "Zagana | Search";
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [viewing, setViewing] = useState('grid')
    const [cartVDetails, setCartVDetails] = useState(defaultItem);
    const [cartVChange, setCartVChange] = useState(0);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [term, setTerm] = useState('');// search for desktop
    const [selectedCity, setSelectedCity] = useState({});// cart for desktop
    const [cart, setCart] = useState([]);// cart for desktop
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [branch, setBranch] = useState(null);
    const selectedBranch = 3;
    const [inCartQty, setinCartQty] = useState(0);
    const [cartProduct, setCartProduct] = useState([]); // show modal cart
    const [MinusToCartVisible, setMinusToCartVisible] = useState(inCartQty > 0 ? true : false);
    const [visibleCart, setVisibleCart] = useState(true); // show modal cart
    let source = sessionStorage.getItem('source')||''
    let accessKey = localStorage.getItem('accessKey')
    let body = document.body; // for setting body height (modal)
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal 
    const show = false;
    // select city branch setter
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        let city = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 0 }
        setBranch(city)
    }, [])
    const clearSearch = () => {
        setTerm('')
    }

    const onBlurSearch = (e) => {
        const search = e.target.value
        const payload = {
            user: user,
            changes: `Zagana || HomePage`,
            type: `search_${search}`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }

    const onChangeSearch = (e) => {
        const search = e.target.value
        const payload = {
            user: user,
            changes: `Zagana || HomePage`,
            type: `search_${search}`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
        setTerm(e.target.value)
        searchToDatabase(search)
        ReactPixel.track('Search', { search_string: e.target.value })
        if (source === "Tiktok") {
            window.ttq.track('Search', { query: e.target.value })
        }

    }

    const debouncedChangeHandler = useCallback(
        debounce(onChangeSearch, 250)
        , []);

    return (
        <>
            <Header title="Search" />
            {/* <CartDetails total={total} /> */}
            {/* Search input textfield*/}
            <div className="input-group mb-2 pb-4 pt-2 mr-sm-2 bg-light">
                <div className="input-group ">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                        </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Search product by name" value={term} onChange={debouncedChangeHandler} />

                </div>
                {term !== '' ? <button className="btn btn-primary-outline" id="btn_clear" style={style.btnClear} onClick={clearSearch}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                    </svg>
                </button> : ''}

            </div>
            {/* Search Result */}
            <div className="m-0 pb-5" id="srBody" >
                {/* {<ItemList search={term} show={show} total={total} setTotal={setTotal} branch={branch} />} */}
                <div id="saleProds">
                    <ItemList search={term} total={total} selectedCity={selectedCity} setTotal={setTotal} branch={branch} onCartLoaded={onCartLoaded} cart={cart} setCart={setCart} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing} setViewing={setViewing} />
                </div>
            </div>
        </>
    );
};

export default Search;

