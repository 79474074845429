const INTIAL_STATE = {
    show: false
};


const CheckoutModalReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SHOW_MODAL':
            return {
                ...state, 
                show: action.show
            };
        default:
            return state;
    }

};

export default CheckoutModalReducer;