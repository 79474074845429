import React from "react";
import { Link } from "react-router-dom";
import { storeFrontLogs } from "../APIs/Logs";

const style = {
    border: {
        borderRadius: '10px'
    },
    link: {
        textDecoration: 'none',
        color: '#222'
    },
    selected: {
        textDecoration: 'none',
        color: '#24b14b'
    },
    fixed: {
        position: 'fixed',
        width: '22%'
    }
}


const SideNav = () => {
    const selected = window.location.pathname
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    }else if(gen_id){
        user = gen_id
    }

    const onClickProfile = () => {
        const payload = {
            user: user,
            changes: `Zagana || customer profile`,
            type: 'view_customer_profile',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }
    const onClickOrders = () => {
        const payload = {
            user: user,
            changes: `Zagana || order history`,
            type: 'view_my_orders',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }
    const onClickAddress = () => {
        const payload = {
            user: user,
            changes: `Zagana || customer address `,
            type: 'view_my_address',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }

    return (
        <React.Fragment>
            <div className="row" style={style.fixed}>
                <div className="col p-3">
                    <div className="border bg-white p-3" style={style.border}>
                        <div className="row d-flex text-center">
                            <Link
                                to="/my-account"
                                style={selected === "/my-account" ? style.selected : style.link}
                                onClick={onClickProfile}
                            >
                                My Account
                            </Link>
                        </div>
                        <div className="row d-flex text-center">
                            <Link
                                to="/user-profile"
                                style={selected === "/user-profile" ? style.selected : style.link}
                                onClick={onClickOrders}
                            >
                                My Orders
                            </Link>
                        </div>
                        <div className="row d-flex text-center">
                            <Link
                                to="/my-directions"
                                style={selected === "/my-directions" ? style.selected : style.link}
                                onClick={onClickAddress}
                            >
                                My Address
                            </Link>
                        </div>
                        
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default SideNav;