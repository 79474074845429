const INTIAL_STATE = {
    AddAddress: false
};



const AmountReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_ADDRESS':
            return {
                ...state, 
                AddAddress: action.add
            };
        default:
            return state;
    }

};

export default AmountReducer;