//  * File Name                : SuggestedCollections
//  * File Directory           : src/components/Misc/RecipePage/Components/SuggestedCollections
//  * Module                   : SuggestedCollections
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/SubCollection.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <SuggestedCollections/>
 *
 *******************************************************************************************/


// import react libary
import React from 'react'
// import category list
import { categoryList } from "../recipeList";
// import propTypes
import PropTypes from "prop-types"; 


// export suggested collection component
const SuggestedCollections = ({exception}) => {
    // isMobile
    const isMobile = localStorage.getItem("isMobile") === "true"
    // filter category not equal to "exception"
    const filterCategory = categoryList.filter(el => el.categoryName !== exception)
    // render suggested collection/category
    const renderSuggestedCollection = filterCategory.map(suggested => {
        // declare category variable
        let suggestedCategory = suggested.categoryName
        // declare suggested description
        let suggestedDescription = suggested.categoryDescription
        // declare suggested image
        let suggestedImage = suggested.categoryImage
        // on suggested click function
        const onSuggestedClick = () => {
            // set suggested to string as collection
            const collection = JSON.stringify(suggested)
            // set local storage collection value
            localStorage.setItem("collection", collection)
            // set local storage category value
            localStorage.setItem("category", suggestedCategory)
            // refresh page with new parameters
            window.location.href = `/ContentPage/recipe/collection/ ${suggestedCategory}`
        }
        if(isMobile){
            return(
                <div key={suggested.categoryName} onClick={onSuggestedClick} className={`${isMobile ? "" : "collection-content"} suggested-flex-content`}>
                    <div className="collection-overlay-wrapper">
                        <div className="collection-overlay"></div>
                        <img className="collection-image suggested-collection-image" src={suggestedImage} alt={suggestedCategory} />
                            <div className="collection-sliding-desc">
                                <div className="row">
                                    <p className="collection-text">{suggestedCategory}</p>
                                </div>
                            </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div key={suggested.categoryName} className={`${isMobile ? "" : "collection-content"} suggested-flex-content`}>
                  <div className="collection-overlay-wrapper">
                    <div className="collection-overlay"></div>
                    <img className="collection-image suggested-collection-image" src={suggestedImage} alt={suggestedCategory} />
                        <div className="collection-sliding-desc">
                            <div className="row">
                                <p className="collection-text">{suggestedCategory}</p>
                            </div>
                            <div className="row centeredItem collection-desc">
                                <p className="centeredText">{suggestedDescription}</p>
                            </div>
                            <div className="row">
                                <button className="centeredItem btn btn-warning collection-text-btn" onClick={onSuggestedClick}>View</button>
                            </div>
                        </div>
                  </div>
                </div>
            )
        }
    })
    return (
    <div className='d-flex flex-row suggested-flex '>
        {renderSuggestedCollection}
    </div>
  )
}


// propType
SuggestedCollections.propTypes = {
    exception: PropTypes.string
};
SuggestedCollections.defaultProps = {
    exception: ""
};

export default SuggestedCollections