/*******************************************************************************************
* File Name                : VerifyOTP
* File Directory           : src/components/Login/VerifyOTP
* Module                   : Login
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Login.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   VerifyOTP        -   component for Registration for new customers
 *
 * #How to call:
 *    <VerifyOTP/>
 *
 * Note: Still working on the ff:
 * - When success should be auto login (Login is still ongoing)
 * 
 *******************************************************************************************/
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Home from '../APIs/Home';
import { textAlign } from '@mui/system';
import Countdown from "react-countdown";

const style = {
    textField: {
        textAlign: "center",
    },
    successMessage: {
        color: '#24b14b',
        textAlign: 'center'
    },
    errorMessage: {
        color: 'red',
        textAlign: 'center'
    },
    resendDisabled: {
        backgroundColor: 'unset',
        border: 'unset',
    }
}
const VerifyOTP = () => {
    const history = useHistory()
    const [otp, setOtp] = useState({ value: '', otp1: '', otp2: '', otp3: '', otp4: '', otp5: '', otp6: '', disable: true });
    const user = JSON.parse(sessionStorage.getItem('Login')) || {};
    const isMobile = localStorage.getItem("isMobile") || "true"
    // this.handleChange = this.handleChange.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    const [countdown, setCoutdown] = useState(true)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [errorPhone, setErrorPhone] = useState('')
    const [phone, setPhone] = useState('')
    const [toggleField, setToggleField] = useState(false)
    const inputfocus = (value, elmnt) => {
        let newOtp = otp
        newOtp[value] = parseInt(elmnt.target.value || 0)
        setOtp(newOtp)
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].value = ''
                elmnt.target.form.elements[next].focus()
            }
        }
        else {
            const next = elmnt.target.tabIndex;
            if (elmnt.target.value !== '') {
                if (next < 6) {
                    elmnt.target.form.elements[next].focus()
                }
            }
        }

    }
    const renderer = (e) => {
        if (e.completed) {
            // Render a countdown
            setCoutdown(false)
            return (
                ''
            )
        } else {
            return (
                <span>
                    {e.seconds}
                </span>
            );
        }

    };
    const reSendOTP = () => {
        const fetchCart = async () => {
            const response = await Home.post('/resendOTP', {
                user_id: user.customer_id
            })
        }
        fetchCart()
        window.location.reload()
    }
    const btnVerify = () => {
        if (otp.otp1 !== '', otp.otp2 !== '', otp.otp3 !== '', otp.otp4 !== '', otp.otp5 !== '', otp.otp6 !== '') {
            let otpCode = otp.otp1.toString() + otp.otp2.toString() + otp.otp3.toString() + otp.otp4.toString() + otp.otp5.toString() + otp.otp6.toString()
            const fetchCart = async () => {
                const response = await Home.post('/OTPverification', {
                    user_id: user.customer_id,
                    OTP: otpCode
                })
                if (response.data.message === 'Verification Success') {
                    user.userVerified = true;
                    setSuccessMessage('Verification Success')
                    sessionStorage.setItem('Login', JSON.stringify(user))
                    setTimeout(() => {
                        if (window.location.hash === "#checkout") {
                            history.push("/checkout-details");
                        } else {
                            history.push("/");
                        }
                    }, 750);
                } else {
                    setErrorMessage('We received the wrong OTP code. Please try again.')
                }
            }
            fetchCart()
        } else {
            setErrorMessage('You must enter a 6-digit security code')
        }
    }

    const toggleEdit = () => {
        setToggleField(true)
    }
    const blurPhone = () => {
        let phoneNumber = phone;
        if (phoneNumber) {
            if (phoneNumber.length > 10 || phoneNumber.length < 10) {
                setErrorPhone('Format should be: (+63) 9xxxxxxxxx');
            }
            else {
                setErrorPhone(' ');
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        // const values = register.values
        const details = {
            user_id: user.customer_id,
            mobileNo: phone
        }
        console.log(details)
        Home.post(`/updateMobile`, details)
            .then(response => {
                if (response.data.message === 'Success') {
                    console.log(response.data)
                    let loginDetails = JSON.parse(sessionStorage.getItem("Login"))
                    loginDetails.customer_phone = phone
                    sessionStorage.setItem('Login', JSON.stringify(loginDetails))
                    window.location.href = "/verifyOTP"
                    // history.push('/verifyOTP')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const signOut = () => {
        sessionStorage.removeItem('Login');
        localStorage.removeItem('cart');
        sessionStorage.removeItem('deliveryDate');
        sessionStorage.removeItem('deliveryTime');
        sessionStorage.removeItem('Address');
        history.push('/')
    };
    return <div className="p-4 row justify-content-center" style={!isMobile ? { height: "auto" } : {}}>
        <div className="row col-md-6 col-lg-4">
            <h4 className="m-0">Mobile phone verification</h4>
            <span className="mobile-text">OTP sent to your registered mobile number<b className="text-danger"> +63{user.customer_phone}</b> <a href="#" id="edit" onClick={toggleEdit}> Edit</a></span>
            <div className="row">
                {toggleField ?
                    <div>
                        <div className="input-group mt-2">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="basic-addon1">(+63)</span>
                            </div>
                            <input type="number" className="form-control" placeholder="9xxxxxxxxx" aria-label="phone" aria-describedby="basic-addon1" value={phone}
                                onChange={(e) =>
                                    setPhone(e.target.value)
                                }
                                onBlur={blurPhone} />

                            <input type="button" className="btn btn-success" aria-label="phone" aria-describedby="basic-addon1" id="update" onClick={handleSubmit} value="Update" />
                        </div>
                        <span className="error">{errorPhone}</span>
                    </div>
                    : ''}
            </div>
            <div className="d-flex flex-row mt-1">
                <form>
                    <div className="otpContainer flex-row mt-5">
                        <p style={style.errorMessage}>
                            {errorMessage}
                        </p>
                        <p style={style.successMessage}>
                            {successMessage}
                        </p>
                        <input
                            name="otp1"
                            type="text"
                            className="otpInput col-2"
                            autoComplete="off"
                            style={style.textField}
                            tabIndex="1" maxLength="1" onKeyUp={(e) => inputfocus("otp1", e)}
                        />
                        <input
                            name="otp2"
                            type="text"
                            autoComplete="off"
                            style={style.textField}
                            className="otpInput col-2"
                            tabIndex="2" maxLength="1" onKeyUp={(e) => inputfocus("otp2", e)}
                        />
                        <input
                            name="otp3"
                            type="text"
                            autoComplete="off"
                            style={style.textField}
                            className="otpInput col-2"
                            tabIndex="3" maxLength="1" onKeyUp={(e) => inputfocus("otp3", e)}
                        />
                        <input
                            name="otp4"
                            type="text"
                            autoComplete="off"
                            style={style.textField}
                            className="otpInput col-2"
                            tabIndex="4" maxLength="1" onKeyUp={(e) => inputfocus("otp4", e)}
                        />
                        <input
                            name="otp5"
                            type="text"
                            autoComplete="off"
                            style={style.textField}
                            className="otpInput col-2"
                            tabIndex="5" maxLength="1" onKeyUp={(e) => inputfocus("otp5", e)}
                        />
                        <input
                            name="otp6"
                            type="text"
                            autoComplete="off"
                            style={style.textField}
                            className="otpInput col-2"
                            tabIndex="6" maxLength="1" onKeyUp={(e) => inputfocus("otp6", e)}
                        />
                    </div>
                    <button className="btn btn-success w-100" type="button" onClick={btnVerify}>
                        Verify
                    </button>
                </form>
            </div>
            <div className="text-center mt-5 mb-5 pt-3">
                <span className="d-block mobile-text">Don't receive the code?</span>
                {countdown ? <Countdown date={Date.now() + 60000} renderer={renderer} /> : null}<button className="btn font-weight-bold text-danger" style={countdown ? style.resendDisabled : null} disabled={countdown} onClick={reSendOTP}>Resend</button>
                <br />
                <br />
                <span onClick={signOut}>Logout</span>
            </div>
        </div>
    </div>
}

export default VerifyOTP;
