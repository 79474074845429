/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : CheckoutDetails
 * File Directory           : src/components/APIs/Paymongo
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : render 3DS authentication  
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   NextAction        -   component for rendering 3DS authentication  
 *
 * #How to call:
 *    /paymongo/debit-credit/next-action
 *
 *******************************************************************************************/
import React from "react";
import Paymongo from 'paymongo';
import { useHistory } from "react-router-dom";

const style = {
    height: {
        height: '98vh',
        width: '100%'
    }
}

const NextAction = () => {
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const nextAction = sessionStorage.getItem('paymongo') || '{}'
    const parsedNextAction = JSON.parse(nextAction)
    const history = useHistory()


    //detect customer response in 3DS
    window.addEventListener(
        'message',
        ev => {
            if (ev.data === '3DS-authentication-complete') {
                //get payment status
                const getPaymongoStatus = async () => {
                    const result = await paymongo.paymentIntents.retrieve(parsedNextAction.id);
                    const status = result.data.attributes.status
                    if (status === 'succeeded') {
                        //You already received your customer's payment. You can show a success message from this condition.
                        history.push('/success')
                    } else if (status === 'processing') {
                        //You need to requery the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
                        setTimeout(async () => {
                            const result = await paymongo.paymentIntents.retrieve(parsedNextAction.id);
                            const status = result.data.attributes.status
                            if (status === 'succeeded') {
                                history.push('/success')
                            } else if (status === 'processing') {
                                //You need to requery the PaymentIntent after a second or two. This is a transitory status and should resolve to `succeeded` or `awaiting_payment_method` quickly.
                                setTimeout(async () => {
                                    const result = await paymongo.paymentIntents.retrieve(parsedNextAction.id);
                                    const status = result.data.attributes.status
                                    if (status === 'succeeded') {
                                        history.push('/success')
                                    }
                                }, 2000);
                            } else if (status === 'awaiting_payment_method') {
                                // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
                                history.push('/user-profile') 
                            }

                        }, 2000);
                    } else if (status === 'awaiting_payment_method') {
                        // The PaymentIntent encountered a processing error. You can refer to paymentIntent.attributes.last_payment_error to check the error and render the appropriate error message.
                        history.push('/user-profile') 
                    }

                }
                getPaymongoStatus()
            }
        },
        false
    );


    return (
        <React.Fragment>
            <iframe title="paymongo" style={style.height} src={parsedNextAction.redirect}/>
        </React.Fragment>
    )

}



export default NextAction;