import React, { useState, useEffect } from "react"
import AddressView from "../Shared/Address/AddressView";
import SideNav from "./SideNav";
import Header from "../Shared/Header";
import ZaganaLogo from '../Shared/ZaganaLogo';
import { Link } from "react-router-dom";
import SelectCity from "../Home/SelectCity";
import Home from "../APIs/Home";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

const styles = {
    headerBG: {
        'backgroundColor': '#facc02'
    },
    minHeight: {
        minHeight: '75vh'
    },
    left: {
        float: "left"
    }
}

const MyAddress = () => {
    const isMobile = localStorage.getItem("isMobile") === "true"
    const [branch, setBranch] = useState(null);
    const history = useHistory()
    useEffect(() => {
        // function uuidv4() {
        //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //         return v.toString(16);
        //     });
        // }
        let link = uuidv4();
        let accessKey = localStorage.getItem('accessKey')
        const accessStorage = async () => {
            await Home.post('/accessStorage', { accessToken: link })
            localStorage.setItem('accessKey', link)
        }
        if (!accessKey) {
            accessStorage()
        } else {
            const getCity = async () => {
                let response = await Home.post('/accountChecker', { accessToken: accessKey })
                if (response.data.result === "AccessTokenNotFound")
                    accessStorage()
                else {
                    setBranch(response.data.selectedCity || [])
                }
            }
            getCity()
        }
        history.push('my-directions')
    }, [])

    return (
        <React.Fragment>
            {isMobile ?
                <Header title="My Address" />
                :
                <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                    <div className="row">
                        <div className="col-2 d-none d-sm-block text-center">
                            <Link to="/">
                                <ZaganaLogo />
                            </Link>
                        </div>
                    </div>
                </div>
            }
            <div className={isMobile ? null : "row"} style={styles.minHeight}>
                {!isMobile ?
                    <div className="col-3 sideNav_OrderAdd">
                        <SideNav />
                    </div>
                    :
                    null
                }
                <div className={isMobile ? "col-12" : "col-9"}>
                    {!isMobile ?
                        <div className="row p-2">
                            <div clasName="col">
                                <h4>My Address</h4>
                                <SelectCity branch={branch} setBranch={setBranch} />
                            </div>
                        </div>
                        :
                        <div className="text-center">
                            <SelectCity branch={branch} setBranch={setBranch} />
                        </div>
                    }
                    <div className={isMobile ? "row" : "row p-5"}>
                        <AddressView myAddress={true} />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default MyAddress;