import React, { useState, useEffect } from "react";
import ItemCard from '../Shared/ItemCard';
import Home from '../APIs/Home';
import { storeFrontLogs } from "../APIs/Logs";
import { v4 as uuid } from 'uuid';
const style = {
  incrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    float: "right",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  decrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#white",
    color: "#24b14b",
    fontWeight: "bold",
    float: "right",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  incrementButtonColorDesktop: {
    border: "none",
    borderRadius: "25px",
    background: "transparent",
    color: "#24b14b",
    fontWeight: "bold",
    float: "left",
    fontSize: "14px",
    padding: "0",
    width: "20px",
    height: "20px"
  },
  decrementButtonColorDesktop: {
    border: "none",
    borderRadius: "25px",
    background: "transparent",
    color: "#24b14b",
    fontWeight: "bold",
    float: "left",
    fontSize: "14px",
    padding: "0",
    width: "20px",
    height: "20px"
  },
  textField: {
    textAlign: "center",
    float: "right",
    background: "transparent",
    margin: "3px"
  },
  textFieldDesktop: {
    textAlign: "center",
    float: "left",
    background: "transparent",
    margin: "3px"
  },
  imgColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: 'relative'
  },
  textItemTitle: {
    fontSize: "1rem"
  },
  textItemTitleDesktop: {
    fontSize: "1rem",
    height: "45px",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box"
  },
  textItemVariant: {
    fontSize: "0.7rem"
  },
  textItemPrice: {
    fontSize: "0.8rem"
  },
  textItemPriceDesktop: {
    fontSize: "0.8rem",
    float: 'right'
  },
  btnDiv: {
    float: 'right',
    width: '125px',
    paddingRight: '1rem'
  },
  outofstockCart: {
    background:"rgba(0, 0, 0, 0.1)"
  }
};
const CartCard = ({item,setInCart,cartModalShow,total,cart,setCart,setTotal,cartVChange,setCartVChange,cartVDetails,setCartVDetails,setVisibleLogin,setConfirmContactModalStatus,setItemNotify,itemNotify}) => {
  let accessKey = localStorage.getItem('accessKey')
  const isMobile = localStorage.getItem("isMobile") === "true"
  let photo = item.item_image
  const [visible,setVisible] = useState(item.cart_qty>0?true:false)
  
  const isLogged = sessionStorage.getItem("Login") || ''; // login checker
  const uniqueID = uuid();
  let gen_id = localStorage.getItem('gen_id')
  let user;
  let userLogin =JSON.parse(sessionStorage.getItem('Login'));
  // if(item.cart_qty>0){
  //   setVisible(true)
  // }
  const [actionChecker, setActionChecker] = useState(null);
  const [inCartQty, setInCartQty] = useState(item.cart_qty)
  photo = photo === null ? "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" : photo

  //useTo Veify Item Qty and save it to state
  useEffect(()=>{
      setInCartQty(item.cart_qty)
      if(cart){
        var cartIndex = cart.findIndex(
          (cartItem) => item.item_id === cartItem.item_id
        );
        if(cartIndex>=0)
        if(cart[cartIndex].item_id==item.item_id){
          if(cart[cartIndex].cart_qty>0)setVisible(true)
          setInCartQty(cart[cartIndex].cart_qty)
        }
      }
  },[item.cart_qty,cart])
  useEffect(()=>{
    // console.log(item.cart_qty)
    // if(item.cart_qty>0)
    //   setVisible(true)
    // TotalComputation()
      // if(item.cart_qty===0)
      //   setVisible(false)
      

  },[])
    
  //onChange/onBlur run cart quantity function
  const inputQtyChange = (action, count, e) => {
    //check action type
    if (action === "blur") {
      if(window.location.pathname === '/premium'&&!item.category.includes("premium")){
        cartMovement(count)
        
      }
      if(window.location.pathname === '/checkout-page')
      cartMovement(count)
      //on blur remove if 0 else just update qty
      if (count === "" || count <= 0 || count === "0") {
        setCartVChange(0)
        setCartVDetails(item)
      }
      else {
        //set max to 50
        if (count > 50)
          count = 50
        count = parseInt(count)
        setCartVChange(count)
        setCartVDetails(item)
      }
    } else {
      if(window.location.pathname === '/premium'&&!item.category.includes("premium")){
        cartMovement(count)
        
      }
      if(window.location.pathname === '/checkout-page')
        cartMovement(count)
      //set max to 50
      if (parseInt(count) > 50)
        count = 50
      setCartVChange(count)
      setCartVDetails(item)
    }
  }
  const cartMovement = (qty) => {
    var checker = qty
    //set Maximum of 50 only
    if (checker > 50)
      checker = 50
    //Check if already in Cart
    var cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    if(cartIndex<0){
      cartIndex = cart.findIndex(
        (cartItem) => item.item_sku === cartItem.item_sku
      );
    }
    // console.log(cartIndex)
    // if (checker === 0) {

    //   setVisible(false)
    // }
    // console.log(item.item_name)
    // console.log(cartIndex)
    
    if (cartIndex >= 0 && checker === 0) {
      //remove item on cart
      cart.splice(cartIndex, 1);
      // console.log("splice")
    } else if (cartIndex >= 0) {
      //update item qty
      cart[cartIndex].cart_qty = checker;
      // console.log("update")
    }
    else if (cartIndex < 0 && qty != 0) {
      //add item to cart
      // setVisible(true)
      // console.log("insert")
      cart.push({
        item_id: item.item_id,
        cart_qty: parseInt(checker),
        cart_price: item.item_salePrice,
        item_price: item.item_price,
        item_sku: item.item_sku,
        item_status: item.item_status,
        item_var: item.item_variant,
        item_name: item.item_name,
        item_image: item.item_image,
        category: item.category
      });
    }
    // //update items
    // setInCartQty(qty)
    // console.log(cart)
    let itemAdded = JSON.stringify(cart);
    setCart(cart)
    
    TotalComputation()
    fetchItems(cart)
    localStorage.setItem("cart", itemAdded);
  }
  const TotalComputation = () => {
    let itemsOnCart = cart || []
    let itemTotal = 0 //default total
    let itemCount = 0 //default cart count
    //loop all items
    itemsOnCart.forEach(items => {
      let cartQty = items.cart_qty
      //check if there are old errors (string on cartQty)
      if (cartQty === '' || cartQty === '0')
        cartQty = 0
      //add to total and cart count
      if (items.item_status==="enabled") {
        itemTotal += cartQty * parseFloat(items.cart_price)
        itemCount += parseInt(cartQty)
      }
    });
    //set to sessionStorage
    // console.log(itemCount+" "+parseFloat(itemTotal))
    setTotal({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) })
    sessionStorage.setItem("total", JSON.stringify({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) }));
  }
  const fetchItems = async (items) => {
    await Home.post('/accessStorage/changeCart', {
      itemOnCart: items,
      accessToken: accessKey
    })
  }
  const setLogs = (changes,movement,cartItem)=>{
      if(gen_id===null){
          gen_id = localStorage.setItem('gen_id',uniqueID)
          gen_id = uniqueID
          user = gen_id
      }else if (gen_id){
          gen_id = localStorage.getItem('gen_id')
          user = gen_id
      }else if(isLogged){
          userLogin = JSON.parse(sessionStorage.getItem('Login'))
          gen_id = localStorage.getItem('gen_id')
          user = userLogin.customer_name + ` (${gen_id})` 
      }
      
      const payload = { 
          user: user, 
          changes: changes, 
          type: movement, 
          date: `NOW()`,
          sku: cartItem.item_sku
      }
      storeFrontLogs(payload)
  }
  
   //on click Notify me/update me
   const btnNotifyMe = async () => {
    if(isLogged){

      if(!userLogin.preferredNotification){
        setConfirmContactModalStatus(true)
        setItemNotify(item)
      }else{
        await Home.post('/notifyItem/add', {
          userId: userLogin.customer_id,
          item_sku: item.item_sku,
          item_branch: item.item_branch,
          customerEmail: userLogin.customer_email,
          phoneNumber:userLogin.customer_phone,
          mode:null
        })
        setItemNotify({
          item_id:item.item_id,
          item_sku:item.item_sku,
          item_name:item.item_name,
          notify_id:"success"
        })
      }
      // if(userEmail!==null&&userEmail!==""){
      //   console.log(item.item_sku)
      //   await Home.post('/notifyItem/add', {
      //     userId: userId,
      //     item_sku: item.item_sku,
      //     item_branch: item.item_branch
      //   })
      // }else{
      //   alert("kindly input Email")
      // }
    }else{
      // setVisibleLogin("false");
      // let element = document.getElementById("modalLoginID");
      // element.classList.remove("showModal");
      // body.classList.add("body_class_");
      // dispatch(forgotModal(false))
      
    }
    
  }
    return(
        <div  className={`border-bottom ${item.item_status==="enabled"?"bg-transparent":""} p-1`}
        style={item.item_status==="enabled"?{}:style.outofstockCart} 
        id={item.item_name}>
            <div className="card-body p-1  pb-1">
                <div className="row">
                    <div className='col'  style={style.imgColumn}>
                        <img
                            size="100%"
                            className='img-fluid'
                            src={photo}
                            width="70px"
                            alt={item.item_name}
                        />
                    </div>
                    <div className={`col-9 p-0  itemDetails`}>
                        <h5 className={`mb-1 item_titleCart`} style={style.textItemTitleDesktop}>{item.item_name}</h5>
                        <p className="mb-2 text-secondary" style={style.textItemVariant}>{item.item_variant||item.item_var}</p>
                        <strong style={style.textItemPrice} ><span style={item.cart_price<item.item_price?{textDecoration: "line-through",fontWeight:'normal'}:{}}>₱{(item.item_price||0)}</span> {item.cart_price<item.item_price? <span style={{color:"red"}}> ₱{item.cart_price}</span>:''} </strong>
                        {item.item_status==="enabled"?
                          <div style={style.btnDiv}>
                            <button
                                id={`btnAddCart${item.item_id}`}
                                className="btn"
                                style={style.incrementButtonColor}
                                onClick={() => {
                                  // if(window.location.pathname === '/checkout-page')
                                if(!window.location.pathname.includes("productPage"))    
                                  cartMovement(inCartQty + 1)
                                  setCartVChange(inCartQty + 1);setCartVDetails(item);
                                  
                                  setLogs(`Item Variant: ${item.item_var}, Item Cost: ${item.item_price}`,"add_to_cart",item)                              
                                  // console.log(item.category.includes("premium"))
                                  if(item.category!==undefined){
                                    if((window.location.pathname === '/premium'&&(item.category!==undefined&&!item.category.includes("premium"))) || (window.location.pathname !== '/premium'&&item.category.includes("premium"))){
                                      cartMovement(inCartQty + 1)
                                      
                                    }
                                  }
                                    // alert("YEHEY")
                                    // setCart()
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
                                </svg >
                            </button >
                            <input
                                id={`inputCartQty${item.item_id}`}
                                className={`form-control w-25 float-right border-0 border-bottom ${visible ? "" : "invisible"
                                }`}
                                type="number"
                                value={inCartQty}
                                style={style.textField}
                                onChange={(e) => inputQtyChange("change", e.target.value)}
                                onBlur={(e) => inputQtyChange("blur", e.target.value, e)}
                            />
                            <button
                                id={`btnMinusCart${item.item_id}`}
                                className={`btn ${visible ? "" : "invisible"}`}
                                style={style.decrementButtonColor}
                                onClick={() => {
                                  setCartVChange(inCartQty - 1);setCartVDetails(item);
                                  setLogs(`Item Variant: ${item.item_var}, Item Cost: ${item.item_price}`,"remove_from_cart",item)              
                                  if(!window.location.pathname.includes("productPage"))                    
                                  cartMovement(inCartQty - 1)
                                  // console.log(item)
                                  if(item.category!==undefined){
                                    if((window.location.pathname === '/premium'&&!item.category.includes("premium")) || (window.location.pathname !== '/premium'&&item.category.includes("premium"))){
                                      cartMovement(inCartQty - 1)
                                      
                                    }
                                  }
                                    // alert("YEHEY")
                                    // setCart()
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                                </svg>
                            </button>
                        </div>
                        :
                        <div style={style.btnDiv} role="group" aria-label="Basic mixed styles example">
                          <div class="btn-group-vertical w-100 my-auto">
                            <button
                              id={`btnMinusCart${item.item_id}`}
                              className={`btn btn-sm btn-danger oosItem`}
                              onClick={() => {
                                setCartVChange(inCartQty - 1);setCartVDetails(item);
                                setLogs(`Item Variant: ${item.item_var}, Item Cost: ${item.item_price}`,"remove_from_cart",item)               
                                if(!window.location.pathname.includes("productPage"))               
                                  cartMovement(inCartQty - 1)
                                // console.log(item)
                                
                                if(item.category!==undefined){
                                  if((window.location.pathname === '/premium'&&!item.category.includes("premium")) || (window.location.pathname !== '/premium'&&item.category.includes("premium"))){
                                    cartMovement(inCartQty - 1)
                                    
                                  }
                                }
                                  // alert("YEHEY")
                                  // setCart()
                              }}
                            >
                              Remove
                            </button>
                            {/* <button
                              id={`btnMinusCart${item.item_id}`}
                              className={`btn btn-sm btn-warning oosItem`}
                              onClick={btnNotifyMe}
                            >
                              Notify
                            </button> */}
                          </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>)
}


export default CartCard