//  * File Name                : Kitchen
//  * File Directory           : src/components/Misc/RecipePage/Components/Kitchen
//  * Module                   : KitchentipsComponent
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In					        : src/Recipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <KitchentipsComponent/>
 *
 *******************************************************************************************/


// import react library
import React, { useEffect, useState } from "react";
// import kitchen tips list
import { KitchenTipsList } from "../recipeList";


const KitchentipsComponent = () => {  
  // set max item to display 
  const [maxItem, setMaxItem] = useState(5)
  // declare maxed state
  const [maxed, setMaxed] = useState(false)
  // set number of items to display upon click More/Less
  const [numberOfItems, setNumberOfItems] = useState()
  // set index of how many times you click
  const [index, setIndex] = useState(0)
  // set visible data to display
  const [visibleData , setVisibleData] = useState([])
  // visible data length
  const visibleDataLength = visibleData.length
  // declare action state for more/less function
  const [action,setAction] = useState("")
  // kitchen tips length
  const kitchenTipsLength = KitchenTipsList.length
  // isMobile 
  const isMobile = localStorage.getItem("isMobile") === "true"

  
  // useEffect for Show More/Less function
  useEffect(() => {
    // for mobile show no limit
    if (isMobile) {
      setMaxItem(kitchenTipsLength)
    }else{
      setMaxItem(5)
    }
    setNumberOfItems(maxItem * ( index + 1 )) ;
    // data container to update visible data state
    const newArray = []; 
    let i= 0
    for( i ;i< kitchenTipsLength ; i++ ){
      if(i < numberOfItems) 
        // add data and set it to visible data
        newArray.push(KitchenTipsList[i])
    }
    // setting visible datas
    setVisibleData(newArray);
    if (numberOfItems >= kitchenTipsLength) {
      // set max if number of items is greater or equal to main list
      setMaxed(true)
    }else{
      setMaxed(false)
    }
  }, [index, numberOfItems, kitchenTipsLength])

  
  // use effect for show more/less
  useEffect(()=>{
    if(kitchenTipsLength > 0){
        if(maxed){
            if(kitchenTipsLength <= maxItem){
                setAction("")
            }else{
                setAction("Less")
            }
        }else{
          setAction("More")
        }
    }
  },[maxed,kitchenTipsLength,maxItem])

    // onClick function for Show More/Less
  const onClickLoad = () => {
      if (numberOfItems > kitchenTipsLength) {
        // set to default value 0
        setNumberOfItems(0)
        setIndex (0)
      }else{
        // add 1 on index
        setIndex ( index + 1 )
      }
  }
  
  // render kitchen tips video
  const renderKitchenTipsVideo = visibleData.map((col) => {
    // declare name
    let tipsName = col.name
    // declare video
    let tipsVideo = col.video
    // declare post image
    let tipsPoster = col.poster
    // declare description
    let tipsDescription = col.description
    const onVideoClick = () => {
      // redirect on Kitchen tips page
      window.location.href = `/ContentPage/recipe/KitchenTips/${tipsName}`
    }
    if(isMobile){
      return(
        <div key={tipsName} className="collection-mobile c-pointer centeredItem collection-kt-wrapper" onClick={onVideoClick}>
          <div className="centeredItem ">
            <div className="collection-overlay-wrapper">
              <div className="collection-overlay"></div>
                <video className="centeredItem" width="80%" poster={tipsPoster} height="100%" >
                    <source src={tipsVideo} type="video/mp4"/>
                </video>
              <div className="collection-sliding-desc">
                  <div className="row">
                      <p className="collection-text">{tipsName}</p>
                  </div>
                  <div className="row centeredItem collection-desc">
                      <p className="centeredText">{tipsDescription}</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div key={tipsName} className="collection-content c-pointer centeredItem collection-kt-wrapper">
          <div key={tipsName} className="collection-content centeredItem ">
            <div className="collection-overlay-wrapper">
              <div className="collection-overlay"></div>
                <video className="centeredItem" width="80%" poster={tipsPoster} height="100%" >
                    <source src={tipsVideo} type="video/mp4"/>
                </video>
              <div className="collection-sliding-desc">
                  <div className="row">
                      <p className="collection-text">{tipsName}</p>
                  </div>
                  <div className="row centeredItem collection-desc">
                      <p className="centeredText">{tipsDescription}</p>
                  </div>
                  <div className="row">
                      <button className="centeredItem btn btn-warning collection-text-btn" onClick={onVideoClick}>Watch</button>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })
  return(
    <div className="container">
        <div className="row kitchen-component-margin marginLR">
          <div className="collection collection-m">
              <h2>Zagana Kitchen Tips</h2>
              
              {kitchenTipsLength < 1 ? <p className="centeredText ">No Available Kitchen Tips</p> : null}
              <div className={`${isMobile ? "collection-mobile-flex" : "collection-image-grid"} collection-kt`}>
                {kitchenTipsLength > 0 ? renderKitchenTipsVideo : null}
              </div>
              {
                  isMobile ?
                  "" :
                  <div className="mt-20 centeredItem show-more-btn">
                    <p className="show-more" onClick={onClickLoad}> {action} </p>
                  </div>
              }
              {visibleDataLength < 1 ? <p>No Kitchen Tips available</p> : null}
          </div>
        </div>
    </div>
  )
}

export default KitchentipsComponent