const INTIAL_STATE = {
    paymentDD   : ''
};

const XenditValidateOTP = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'XenditValidateOTP':
            return {
                ...state,
                paymentDD   : action.paymentDD
            };
        default:
            return state;
    }

};

export default XenditValidateOTP;
