import React, { useEffect, useState } from "react";
import Home from '../APIs/Home';
import LazyLoad from "react-lazyload";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { forgotModal } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";
import { connect, useDispatch } from "react-redux";
import { v4 as uuid } from 'uuid';
import _ from 'lodash'
import ReactGA from "react-ga4"

const NotifyMeButtons = ({
    CartRedux,
    notifyItem,
    item
}) => {
    
    const dispatch = useDispatch()
    const [notified, setNotified]=useState(item.notify_id?true:false)
    let userLogin = JSON.parse(sessionStorage.getItem('Login')) || '';
    useEffect(()=>{
      if (notifyItem.item_sku === item.item_sku) {
        setNotified(notifyItem.notified?true:false)
      }
    },[notifyItem])
  //on click Notify me/update me
  const btnNotifyMe = async () => {

    if (userLogin) {
      if (!userLogin.preferredNotification) {
        dispatch({
          type: "SET_NOTIFY",
          value:  {
            item_sku: item.item_sku,
            item_branch: item.item_branch,
            notified: false
          },
          login: false
        });
        dispatch({
          type: "SET_CONFIRM_CONTACT",
          value: true
        });
      } else {
        ReactGA.event({
          category: "Click Button",
          action: "Notify Me",
          label: item.item_sku
        })
        await Home.post('/notifyItem/add', {
          userId: userLogin.customer_id,
          item_sku: item.item_sku,
          item_branch: item.item_branch,
          customerEmail: userLogin.customer_email,
          phoneNumber: userLogin.customer_phone,
          mode: null
        })
        dispatch({
          type: "SET_NOTIFY",
          value:  {
            item_sku: item.item_sku,
            item_branch: item.item_branch,
            notified: true
          },
          login: false
        });
      }
    }else{
      ReactGA.event({
        category: "Click Button",
        action: "Notify Me",
        label: item.item_sku
      })
      localStorage.setItem('notifyItem',JSON.stringify({
        item_sku: item.item_sku,
        item_branch: item.item_branch
      }))
      dispatch({
        type: "SET_NOTIFY",
        value:  {
          item_sku: item.item_sku,
          item_branch: item.item_branch,
          notified: false
        },
        login: true
      });
      // setVisibleLogin("false");
      // let element = document.getElementById("modalLoginID");
      // element.classList.remove("showModal");
      // body.classList.add("body_class_");
      // dispatch(forgotModal(false))

    }

  }
    return(
    <div className="product-buttons">
        {
            !notified ?
                <button
                    id={`btnAddCart${item.item_id}`}
                    onClick={btnNotifyMe}
                    className="product-button-oval notify-button"
                    >
                    Notify Me
                </button >
            :
                <button
                    id={`btnAddCart${item.item_id}`}
                    className="product-button-oval toBeNotify-button"
                    >
                    Updating Soon
                </button >
                    
        }
    </div>
    )
};

const mapStateToProps = (state) => {
    
    return {
      notifyItem:state.notify.NotifyItem,
    };
};

export default connect(mapStateToProps)(NotifyMeButtons);