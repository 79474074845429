/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : LandBankForm
 * File Directory           : src/components/APIs/LandBankForm
 * Module                   : API
 * Author                   : Joseph Rivera
 * Version                  : 1
 * Purpose                  : payment method for xendit
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   LandBankForm        -   component for Direct Debit input details
 *
 * #How to call:
 *    /xendit/LandBankForm
 *
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import Home from "../Home";
import axios from 'axios';
const style = {
    error: {
        color: 'red'
    },
    valid: {
        color: '#24b14b'
    },
    width: {
        width: '100%'
    },
    img: {
        width: '250px',
        margin: '0 auto',
        display: 'block'
    }
}

//render component for redux-form
const renderInput = ({
    input,
    type,
    meta,
    value_name,
    class_name,
    placeholder,
    label,
    pattern,
    style
}) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''} ${!meta.error ? 'valid' : ''}`;
    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    {label === undefined ?
                        ''
                        :

                        <label
                            htmlFor={label}
                            className="col-form-label"
                        >
                            {label} &nbsp;
                            <div className="d-inline-block"> {errorIcon(meta)}</div>
                        </label>

                    }

                </div>
            </div>

            <div className={className}>
                <input
                    {...input}

                    type={type}
                    pattern={pattern}
                    autoComplete="off"
                    className={class_name}
                    placeholder={placeholder}
                    style={style}
                />
                {label === undefined ? <div className="d-inline-block"> {errorIcon(meta)}</div> : ''}
                <div className="form-control-feedback">{errorMsg(meta)}</div>
            </div>

        </React.Fragment>
    );
};

//rendering error message
const errorMsg = ({ error, touched }) => {
    if (touched && error) {
        return (
            <span className="error" >{error}</span>
        )
    }
}

//rendering error/valid icon
const errorIcon = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span style={style.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
    else if (!error) {
        return (
            <div>
                <span style={style.valid}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </span>
            </div>
        );
    }
}

//declaring input errors
const validate = values => {
    const errors = {};
    if (values.contactNumber) {
        if (values.contactNumber.length > 10 || values.contactNumber.length < 10) {
            errors.contactNumber = 'Format should be: (+63) 9xxxxxxxxx';
        }
    }
    return errors;
};

//main component
const LandBankForm = ({ valid, details, total, order_id, customer }) => {
    const isMobile = localStorage.getItem("isMobile") === "true"; // is mobile
    const [errorDetails, setErrorDetails] = useState(false) // error details
    const [errorMsg, setErrorMsg] = useState('') // error msg
    const [generatedToken, setGeneratedToken] = useState('') // error msg
    const history = useHistory()
    const dispatch = useDispatch()
    const orderTotal=JSON.parse(sessionStorage.getItem("orderTotal"))
    const orderDetails = JSON.parse(sessionStorage.getItem('order'))
    const identity = "6d5a74c940694d668aaaae6b402b4ee71cca906bc6ce48c39002fae9d72ae384-IDTK45E291-112701"


    useEffect(() => {
        let data = JSON.stringify({
            "clientId": process.env.REACT_APP_LANDBANK_ID,
            "secret": process.env.REACT_APP_LANDBANK_SECRET
        });

        let config = {
            method: 'post',
            url: 'https://landbankpay.bank-genie.co:7004/api/Ecommerce/Token',
            headers: {
                'Identity': '6d5a74c940694d668aaaae6b402b4ee71cca906bc6ce48c39002fae9d72ae384-IDTK45E291-112701',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let token = response.data.body.token;

                dispatch({
                    type: "accessToken",
                    token: token
                })
                setGeneratedToken(token);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const value = details.values;
        const orderNo = order_id.order_id;
        const amount = orderTotal;
        var axios = require('axios');
        var data = JSON.stringify({
            "clientId": process.env.REACT_APP_LANDBANK_ID,
            "secret": process.env.REACT_APP_LANDBANK_SECRET,
            "customerMobileNumber": `${value.contactNumber}`,
            "amount": amount,
            "requestOrderNumber1": orderNo
        });

        var config = {
            method: 'post',
            url: 'https://landbankpay.bank-genie.co:7004/api/PaymentGateway/Order',
            headers: {
                'Identity': '6d5a74c940694d668aaaae6b402b4ee71cca906bc6ce48c39002fae9d72ae384-IDTK45E291-112701',
                'Authorization': 'Bearer ' + generatedToken,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log((response.data.body));
                const generatedOrderID = response.data.body.orderId;
                const url  = response.data.body.url;
                if(response.data.body.code === "00"){
                    dispatch({
                        type: "orderID",
                        generatedOrderID: generatedOrderID
                    })
                    const body = {
                        payID: generatedOrderID,
                        checkID: orderDetails.check_id,
                        paymentMethod: "LandBank"
                    }
                    Home.post(`/paymongo/payment/payID`, body)
                    window.location.href = url;
                }
                else{
                    alert(response.data.body.remarks)
                }
            })
            .catch(function (error) {
                console.log(error);
            });



        // console.log(payload);

    }
    return (
        <React.Fragment>
            <div className="container ">
                <div style={{ "minHeight": "400px" }} className={`justify-content-center m-auto ${isMobile === true ? 'w-100' : 'w-25'}`}
                >
                    <div className="card mt-5">
                        <div className="row">
                            <div className="col p-2">

                                <img src="https://www.landbank.com/images/logo1.png" style={style.img} />
                            </div>
                        </div>
                        {errorDetails ?
                            <div className="alert alert-danger p-2" role="alert">
                                <span>{errorMsg}</span>
                            </div>
                            :
                            ''
                        }
                        <form onSubmit={handleSubmit} className="p-2">
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Contact Number"
                                        type="number"
                                        name="contactNumber"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row pt-4">
                                <div className="col">
                                    <button type="submit" className={`btn w-100  ${!valid ? 'disabled' : 'btn-primary'} `} disabled={!valid} id="btnRegister">Submit</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        details: state.form.Xendit, // state from redux state
        total: state.total, // redux state from order id
        order_id: state.OrderID, // redux state from order id
        customer: state.CustInfo, // redux state from customer info
        initialValues: {
            customerEmail: state.CustInfo.Email || " ", // setting initials value for email
            contactNumber: state.CustInfo.Phone || " "// setting initials value for phone
        },
    }
}
export default connect(mapStateToProps)
    (reduxForm({
        form: 'Xendit',
        validate
    })(LandBankForm));
