import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import AddAddress from "./AddAddress";
import Plus from '../../../assets/site_icons/Plus.svg';

const style = {
    border: {
        border: 'unset',
        paddingBottom: 'unset'

    },
    body: {
        padding: '3.5rem',
        paddingTop: 'unset',
        paddingBottom: '2rem'
    }
}

const AddressModal = () => {
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <React.Fragment>
            <div className="row" onClick={handleShow} >
                <img src={Plus} alt="add_logo" />
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={style.border} closeButton>
                </Modal.Header>
                <Modal.Body style={style.body}>
                    <AddAddress onHide={setShow} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );

}

export default AddressModal;