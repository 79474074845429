/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : CheckoutDetails
 * File Directory           : src/components/Checkout/
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : view checkoit details 
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CheckoutDetails        -   component for viewing checkout details
 *
 * #How to call:
 *    /checkout-details
 *
 *******************************************************************************************/
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useState } from "react";
import ReactPixel from 'react-facebook-pixel';
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

import Paymongo from 'paymongo';
import Backend from "../APIs/Backend";
import Home from "../APIs/Home";
import { quoteShipment, bookShipment } from "../APIs/Shipmates";

import OrderDetails from "../Checkout/OrderDetails";
import AddressView from "../Shared/Address/AddressView";
import Header from "../Shared/Header";
import StoreCredit from "./StoreCredit";
import Voucher from "./Voucher";
// import DeliveryFields from "../Shared/DeliveryFields";
import axios from 'axios';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { getOrderID, initialCustInfo } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";
import ErrorModal from "../Modal/ErrorModal";
import ModalPromo from "../Modal/ModalPromo";
import DeliveryFields from "../Shared/DeliveryTime";
import { SaveToDatabase } from "../Shared/SaveToDatabase";
import { SaveToSession } from "../Shared/SaveToSession";
import ZaganaLogo from '../Shared/ZaganaLogo';
import Payments from "./Payments";
import CourierJson from '../Shared/courier';
import moment from 'moment';
import grabLogo from "../../assets/logo/grab.png";
const style = {
    colsLeft: {
        textAlign: 'left',
        fontSize: '.9rem',
    },
    colsRight: {
        textAlign: 'right',
        fontSize: '.9rem',
    },
    colsCenter: {
        textAlign: 'center',
        fontSize: '.9rem',
    },
    textRight: {
        textAlign: 'right'
    },
    links: {
        textDecoration: 'none',
        fontSize: '1rem',
        color: "#24b14b",
    },
    fontSize: {
        fontSize: '1.2rem'
    },
    marginBottom: {
        marginBottom: '5.5rem',
    },
    bgColor: {
        backgroundColor: '#fff'
    },
    time: {
        textDecoration: 'none',
        color: '#24b14b',
        textAlign: 'left'
    },
    icon: {
        color: '#24b14b',
        textAlign: 'right'
    },
    colorPrimary: {
        color: '#24b14b'
    },
    box: {
        boxShadow: 'none',
        borderColor: 'none',
        backgroundColor: '#fff',
    },
    link: {
        textDecoration: 'unset',
    },
    inputIcon: {
        borderRadius: '5px 0px 0px 5px',
        height: '100%'
    },
    height: {
        height: '8.2rem'
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed',
        'zIndex': '2'
    },
    buttonFixed: {
        backgroundColor: '#facc02',
        borderColor: '#facc02',
        borderRadius: '20px',
        width: '25%'
    },
    buttonFixedFont: {
        fontSize: '1rem'
    },
    terms: {
        textDecoration: 'none',
        color: '#24b14b',
        cursor: 'pointer'
    }
}

const CheckoutDetails = ({ discount, secretDiscount, paymentSelected, timeError, CartRedux }) => {
    document.title = "Zagana | Checkout"
    let source = sessionStorage.getItem('source') || ''
    let date = localStorage.getItem('date') || ''
    let delivery = ""
    let deliveryShow = sessionStorage.getItem('deliveryShow') || ''
    const isMobile = localStorage.getItem("isMobile") === "true";
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const NumStops = sessionStorage.getItem('NumStops') || 'Single'
    const [shippingLoading, setShippingLoading] = useState(false)
    const [address, setAddress] = useState({})
    const [shippingDate, setShippingDate] = useState("")
    const [branch, setBranch] = useState(null);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [shippingFee, setShippingFee] = useState(0)
    const dispatch = useDispatch()
    const history = useHistory()
    const [orderTotal, setOrderTotal] = useState(0)
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    // const [oldTotal, setOldTotal] = useState(false)
    const [cart, setCart] = useState(CartRedux);// cart for desktop
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [modalPromoStatus, setModalPromoStatus] = useState(false)
    const [modalMultipleStatus, setModalMultipleStatus] = useState(false)
    const [enableCheckoutButton, setEnableCheckoutButton] = useState(true)
    const [renderShippingMethod, setRenderShippingMethod] = useState([])
    const [selectedShippingMethod, setSelectedShippingMethod] = useState({})
    const [shipmentData, setShipmentData] = useState()
    const merchant_code = process.env.REACT_APP_BILLEASE_MERCHANT_CODE // Billease Merchant Code
    const shop_code = process.env.REACT_APP_BILLEASE_SHOP_CODE // Billease Shop Code
    const token = process.env.REACT_APP_BILLEASE_TOKEN // Billease Bearer Token
    const billeaseUrl = process.env.REACT_APP_BILLEASE_URL
    const [hasCredit, setHasCredit] = useState(false)//credit store
    const [credit, setCredit] = useState(0)
    let accessKey = localStorage.getItem('accessKey')
    const [stop, setStops] = useState(NumStops || 'Single')
    const stops = sessionStorage.getItem('stops') || 0
    const specialIns = sessionStorage.getItem('notes') || ''
    const [disabledCOD, setDisabledCOD] = useState(false)
    let freedelCode;
    const [categDel, setCategDel] = useState([]);
    let disableDel;
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }
    if (isMobile) {
        if (window.location.pathname === '/checkout-details') {
            history.push('/checkout-page')
        }
        const time = sessionStorage.getItem('deliveryTime') || null
        const date = sessionStorage.getItem('deliveryDate') || null
        if (time !== null && date !== null) {
            delivery = date + "  " + deliveryShow
        }
    }
    const [freeDelivery, setFreeDelivery] = useState([])
    const [refresh, setRefresh] = useState(true)
    if (!getUser) {
        window.location.replace("/login#checkout")
    }

    useEffect(() => {
        SaveToSession({
            page: 'Checkout Page',
            path: window.location.search
        })
        if (source === 'FB') {
            ReactPixel.pageView();
            SaveToDatabase({
                action: {
                    type: 'pageView',
                    value: {}
                }
            })
        }
        if (!accessKey) {
        } else {
            const getAccount = async () => {
                let response = await Home.post('/accountChecker', { accessToken: accessKey })
                setBranch(response.data.selectedCity || [])
            }
            getAccount()
            const getCart = async () => {
                let response = await Home.post('/cartChecker', { accessToken: accessKey })
                let cart = response.data.cart || []
                setCart(response.data.cart || [])
                dispatch({
                    type: "SET_CART",
                    selected: response.data.cart,
                });

                // console.log(response.data.selectedCity)
                setOnCartLoaded(true)
                let checker;
                const found = [];
                cart.forEach((cart) => {
                    checker = cart.category;
                    // found.concat(checker)
                    if (checker.includes("2d")) {
                        found.push("2d")
                    }
                    else if (checker.includes("1d")) {
                        found.push("1d")
                    }
                });
                setCategDel(found)
            }
            getCart()

        }
    }, [])

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || Checkout`,
            type: `view_checkout_page`,
            date: `NOW()`
        }
        storeFrontLogs(payload)

        const getCustomerInfo = async () => {
            try {
                const response = await Home.post(`/customerInfo`, { user_id: getUser.customer_id })
                dispatch(initialCustInfo(response.data[0]))
            } catch (err) {
                console.log(err)
            }
        }
        getCustomerInfo()

    }, [])



    useEffect(() => {
        setCart(CartRedux)
    }, [CartRedux])

    useEffect(() => {
        //saving total amount to redux
        dispatch({
            type: "TOTAL_AMOUNT",
            total: total.itemTotal
        })
        // if(total.itemTotal>=1000){   
        //     setOrderTotal(total.itemTotal + shippingFee - 250 || 0)
        //     freeDelivery.discount=true
        //     freeDelivery.discount_amount=250
        //     freeDelivery.discount_code='BIGSALESURPRISE'
        //     setModalPromoStatus(true)
        // }else{
        //     setOrderTotal(total.itemTotal + shippingFee || 0)
        //     freeDelivery.discount=false
        //     freeDelivery.discount_amount=0
        //     freeDelivery.discount_code=''
        // }
        //if cx is discounted
        const today = new Date();
        function formatDate(today) {
            let d = new Date(today),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            //            console.log([year, month, day].join('-')>="2022-01-01")
            return [year, month, day].join('-');
        }
        console.log("TRIGGER")
        let voucherFreedel = true
        sessionStorage.setItem("disabledCOD", "false")
        setDisabledCOD(false)
        if (cart.some(item => !["Zagana 1000 Gift Voucher", "Zagana 2000 Gift Voucher", "Zagana 300 Gift Voucher", "Zagana 500 Gift Voucher", "Zagana 800 Gift Voucher"].includes(item.item_name))) {
            voucherFreedel = false
            console.log("TRIGGER1")
        }
        if (discount.discount_code.toLowerCase() === "freedel") {
            let totalPayment = total.itemTotal + (shippingFee - 99 || 0) - credit
            setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            freeDelivery.discount = false
            freeDelivery.discount_amount = 0
            voucherFreedel = false
            setRefresh(!refresh)
            sessionStorage.setItem("disabledCOD", "false")
            setDisabledCOD(false)
            console.log("TRIGGER2")
        }
        else if (voucherFreedel === true && discount.discount_code.toLowerCase() !== "freedel") {
            let totalPayment = total.itemTotal + (shippingFee - 99 || 0) - credit
            setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            freeDelivery.discount = true
            freeDelivery.discount_amount = 99
            freeDelivery.discount_code = "GIFTVOUCHER"
            //For gift voucher payment method - cannot use COD
            if (!hasCredit || orderTotal > 0) {
                sessionStorage.setItem("disabledCOD", "true")
                setDisabledCOD(true)
            }
            console.log("TRIGGER3")
        }
        else if (total.itemTotal < 100 || formatDate(today) >= "2022-01-01") {
            freeDelivery.discount = false
            freeDelivery.discount_amount = 0
            freeDelivery.discount_code = 'Select code'
        } else {
            let totalPayment = total.itemTotal + (shippingFee - 99 || 0) - credit
            setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            freeDelivery.discount = true
            freeDelivery.discount_amount = 99
            freeDelivery.discount_code = 'MAZAGANA'
            // setModalPromoStatus(true)
            console.log("TRIGGER4")
        }
        if (discount.discount || secretDiscount.Secret_discount || hasCredit) {
            let totalPayment
            if (freeDelivery.discount) {
                totalPayment = total.itemTotal + shippingFee - (discount.discount_amount || 0) - (secretDiscount.Secret_discount_amount || 0) - (freeDelivery.discount_amount || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            } else {
                totalPayment = total.itemTotal + shippingFee - (discount.discount_amount || 0) - (secretDiscount.Secret_discount_amount || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            }
            console.log("TRIGGER5")
        }
        // else if (total.itemTotal >= 1000) {
        //     setOrderTotal(total.itemTotal + shippingFee - 99 || 0)
        //     freeDelivery.discount = true
        //     freeDelivery.discount_amount = 79
        //     freeDelivery.discount_code = 'FREEDEL'
        //     setModalPromoStatus(true)
        // } 
        else {
            let totalPayment
            if (freeDelivery.discount_amount) {
                totalPayment = total.itemTotal + shippingFee - (freeDelivery.discount_amount || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            }
            else {
                totalPayment = total.itemTotal + (shippingFee || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            }
            console.log("TRIGGER6")
            // setOrderTotal(total.itemTotal + shippingFee || 0)
        }


        // if (total.itemTotal >= 1000 && discount.discount_code.toLowerCase() !== "freedel" && discount.discount_code.toLowerCase() !== "walkin") {
        //     //alert(total.itemTotal)
        //     let ftotal = total.itemTotal + shippingFee;
        //     let finalTotal;
        //     freedelCode = ''
        //     freeDelivery.discount = true
        //     freeDelivery.discount_amount = 99
        //     freeDelivery.discount_code = 'FREEDEL1000'
        //     if (discount.discount) {
        //         finalTotal = ftotal - discount.discount_amount - 99;
        //         freedelCode = (`FREEDEL1000` + `(99)`)
        //     }
        //     else {
        //         finalTotal = ftotal - 99;
        //     }
        //     let totalPayment = (finalTotal || 0) - credit
        //     setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
        // }
        let checker;
        const found = [];
        cart.forEach((cart) => {
            checker = cart.category;
            // console.log(checker)
            // found.push(checker)
            if (checker.includes("2d")) {
                found.push("2d")
            }
            else if (checker.includes("1d")) {
                found.push("1d")
            }
        });
        setCategDel(found)
    }, [total, orderTotal, discount.discount, discount.discount_amount, dispatch, shippingFee, stops, stop, specialIns, secretDiscount, discount, disableDel, hasCredit, credit, disabledCOD])

    useEffect(() => {
        let city = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 1 }
        setBranch(city)

        //fetch shipping fee amount
        const getShippingFee = async () => {
            try {
                const response = await Home.post(`/get-shippingfee`)
                if (stops > 1) {
                    // setShippingFee((response.data.sfee_price * stops) || 0)
                } else {
                    // setShippingFee(response.data.sfee_price || 0)
                }
            } catch (err) {
                console.log(err)
            }
        }
        getShippingFee()
        sessionStorage.setItem('payment', paymentSelected.paymentSelected)
    }, [paymentSelected.paymentSelected, stops])

    //checkout validation error message
    useEffect(() => {
        const time = sessionStorage.getItem('deliveryTime') || null
        const date = sessionStorage.getItem('deliveryDate') || null
        if (time !== null && date !== null) {
            delivery = date + " " + time
        }
        const specialIns = sessionStorage.getItem('notes') || '';
        if ((selectedShippingMethod === null || selectedShippingMethod === {} || Object.keys(selectedShippingMethod).length === 0)) {
            setErrorMsg('Please select shipping method')
        } else if (sessionStorage.getItem('Address') === '{}' || sessionStorage.getItem('Address') === null) {
            setErrorMsg('Please select address')
        } else if (delivery === '') {
            setErrorMsg('Please select delivery time')
        } else if (!paymentSelected.payment || paymentSelected.paymentSelected === 'Select payment') {
            setErrorMsg('Please select payment method')
        } else if (total.itemTotal === 0) {
            setErrorMsg('No remaining products')
        } else if (timeError.error) {
            setErrorMsg(timeError.errorMsg)
        } else if (stops < 2 && NumStops === "Multiple") {
            setErrorMsg(`Uh-oh! 
            Please make sure that the number of drop off addresses are two or more
            `)
        } else if (stops > total.itemCount && NumStops === "Multiple") {
            setErrorMsg(`Uh-oh!
             Please make sure that the number of drop off addresses are according to or less than the items in your cart. Thank you!
             `)
        } else if (specialIns === '' && NumStops === "Multiple") {
            setErrorMsg('You forgot to add Special Instructions. Please tap "Guide" for the format that you can follow. Thanks!')
        }

    }, [error, delivery, paymentSelected.paymentSelected, total.itemTotal, stop, stops, specialIns])

    useEffect(() => {
        getShippingQuote()
    }, [address, paymentSelected.paymentSelected, shippingDate])
    const getShippingQuote = async () => {
        setShippingFee(0)
        setSelectedShippingMethod({})
        setShippingLoading(true)
        let store = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 1 }
        let date = shippingDate ?
            shippingDate
            :
            moment().isBetween(moment().format("YYYY-MM-DD 08:00"), moment().format("YYYY-MM-DD 17:00")) ? moment().format("YYYY-MM-DD") : moment().add("days", 1).format("YYYY-MM-DD")
        let rateType = date === moment().format("YYYY-MM-DD") ? "ondemand" : "ondemand"
        console.log(address)
        let data = {
            from_address: {
                contact_name: store?.admin_name,
                contact_number: store?.contact_number,
                contact_email: null,
                address1: `${store.branch_address}`,
                address2: store?.branch_brgy,
                city: store?.branch_city,
                province: "Metro Manila",
                country: "Philippines",
                zip: store?.branch_zip,
                landmark: "",
                remarks: "",
                latitude: "",
                longitude: ""
            },
            to_address: {
                contact_name: address?.add_fn || address?.receiver_name,
                contact_number: address?.add_contact || address?.receiver_contactNo,
                contact_email: null,
                address1: `${address?.address_apartment} ${address?.address_street}`,
                address2: address?.brgy_name,
                city: address?.city_name,
                province: "Metro Manila",
                country: "Philippines",
                zip: address?.address_zip,
                landmark: address?.add_landmark ?? "",
                remarks: "",
                latitude: "",
                longitude: ""
            },
            items: {},
            amount_total: orderTotal,
            amount_cod: paymentSelected.paymentSelected === "COD" ? orderTotal : 0
        };
        setShipmentData(data)
        let result = await quoteShipment(data)
        let renderShippingMethod = result.data.rates[rateType].filter((data) => { return data.courier_service_name.match(/20kg/gi) || data.courier_service_name.match(/pouch/gi) })
        setRenderShippingMethod(renderShippingMethod)
        setShippingLoading(false)

    }
    const handleShippingClick = async (data) => {
        setShippingFee(data.fees.shipping_fee + data.fees.shipmates_fee + (data.fees?.cod_fee ?? 0) + (data.fees?.valuation_fee ?? 0))
        setSelectedShippingMethod(data)
    }
    const onClickCheckout = async () => {
        // setEnableCheckoutButton(false)
        console.log(Object.keys(selectedShippingMethod))
        if (source === 'FB') {
            ReactPixel.track('InitiateCheckout');
            SaveToDatabase({
                action: {
                    type: 'InitiateCheckout',
                    value: {}
                }
            })
        }
        if (source === 'Tiktok') {
            ReactPixel.track('InitiateCheckout');
            SaveToDatabase({
                action: {
                    type: 'InitiateCheckout',
                    value: {}
                }
            })
        }

        const time = sessionStorage.getItem('deliveryTime') || null
        const date = sessionStorage.getItem('deliveryDate') || null
        let uniqueCateg = [...new Set(categDel)];
        // console.log(categDel)
        // console.log(uniqueCateg)
        let i;
        let orderType = [];
        for (i = 0; i < uniqueCateg.length; i++) {
            // console.log(uniqueCateg[i]);
            if (uniqueCateg[i] === "1d") {
                orderType.push("1day")
            }
            else if (uniqueCateg[i] === "2d") {
                orderType.push("2days")
            }
        }
        // console.log(orderType);
        if (time !== null && date !== null) {
            delivery = date + " " + time
        }
        const specialIns = sessionStorage.getItem('notes') || ''
        if (total.itemCount === 0 || cart === null || cart === undefined || cart === "[]" || cart.length === 0) {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 1200)
        } else if (sessionStorage.getItem('Address') === '{}' || sessionStorage.getItem('Address') === null) {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 1200)
        } else if (delivery === '') {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 1200)
        } else if (paymentSelected.paymentSelected === 'Select payment') {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 1200)
        } else if (timeError.error) {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        } else if (!paymentSelected.payment) {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        } else if (total.itemCount < stops && NumStops === "Multiple") {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 5500)
        } else if (stops < 2 && NumStops === "Multiple") {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 3000)
        } else if (specialIns === '' && NumStops === "Multiple") {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        } else if (selectedShippingMethod === null || selectedShippingMethod === {} || Object.keys(selectedShippingMethod).length === 0) {
            setError(true)
            setEnableCheckoutButton(true)
            setTimeout(() => {
                setError(false)
            }, 2000)
        } else {
            sessionStorage.setItem('orderTotal', orderTotal)
            let discountAmount = 0
            let discountName = ''
            // if (discount.discount_code !== 'Select code' || secretDiscount.Secret_discount_code !== 'Select code') {
            //     discountAmount = parseFloat(discount.discount_amount) + parseFloat(secretDiscount.Secret_discount_amount)
            //     discountName = discount.discount_code + "(" + discount.discount_amount + ")" + secretDiscount.Secret_discount_code + "(" + secretDiscount.Secret_discount_amount + ")"
            // }            
            if (discount.discount_code !== 'Select code') {
                discountAmount = parseFloat(discount.discount_amount)
                discountName = discount.discount_code + "(" + discount.discount_amount + ")"
            }
            if (freeDelivery.discount) {
                discountAmount += freeDelivery.discount_amount
                if (discount.discount_code !== 'Select code') {
                    discountName += ' '
                }
                discountName += (freeDelivery.discount_code + `(${freeDelivery.discount_amount})`)
            }
            if (hasCredit) {
                discountName += `StoreCredit(${credit.toFixed(2)})`
            }
            const custInfo = sessionStorage.getItem('Login')
            const parsedCustInfo = JSON.parse(custInfo)
            const custAddress = sessionStorage.getItem('Address')
            const parsedCustAddress = JSON.parse(custAddress)
            // const itemCart = localStorage.getItem('cart')
            // const parsedItemCart = JSON.parse(itemCart)
            const selectedCity = localStorage.getItem('SelectedCity')
            const parsedSelectedCity = JSON.parse(selectedCity)
            const paymentMethod = sessionStorage.getItem('payment')
            const customerAddress = [parsedCustAddress]
            // const cart = parsedItemCart
            const branchId = parsedSelectedCity.branch_id
            const payment = paymentMethod
            const totalAmount = parseFloat(orderTotal).toFixed(2)
            const sfee = shippingFee
            const deliveryDate = delivery
            const origin = window.location.origin
            let type = ''

            const customerInfo = [{
                customer_id: parsedCustInfo.customer_id,
                customer_name: parsedCustInfo.customer_name,
                customer_email: parsedCustInfo.customer_email,
                customer_contactNo: parsedCustInfo.customer_phone
            }]
            const body = {
                customerAddress,
                cart,
                branchId,
                payment,
                totalAmount,
                discountAmount,
                discountName,
                sfee,
                credit,
                orderType,
                customerInfo,
                deliveryDate,
                specialIns: stops + "stops" + "/" + specialIns,
                accessKey,
                selectedShippingMethod,
                origin
            }
            if (payment === 'GCash') {
                type = 'gcash'
            } else {
                type = 'grab_pay'
            }
            //data for paymongo payment (Gcash/Grabpay)
            const doubleTotalAmount = totalAmount * 100
            const data = {
                data: {
                    attributes: {
                        type: type,
                        amount: parseInt(doubleTotalAmount),
                        currency: 'PHP',
                        redirect: {
                            success: window.location.origin + '/success',
                            failed: window.location.origin + '/failed'
                        }
                    }
                }
            }
            try {
                // insert checkout data to database
                const response = await Home.post(`/checkout`, body)
                console.log(response)
                // if successfully inserted to database
                if (response.data.status === "OK") {
                    let pixelItems = []
                    let pixelContent = []
                    const GAItems = CartRedux.map((c, index) => {
                        pixelItems.push(c.item_sku)
                        pixelContent.push({
                            id: c.item_sku,
                            quantity: c.cart_qty
                        })
                        return {
                            item_id: c.item_sku,
                            item_name: c.item_name,
                            currency: "PHP",
                            index: index,
                            item_category: c.category,
                            price: c.cart_price,
                            quantity: c.cart_qty
                        }
                    })
                    localStorage.setItem('fbPixelItems', JSON.stringify(pixelItems))
                    localStorage.setItem('fbPixelContent', JSON.stringify(pixelContent))

                    ReactGA.event({
                        'category': 'Payment Checkout Details',
                        'action': 'Place Order',
                        'label': response.data.job_order
                    });

                    if (source === "Tiktok") {
                        SaveToDatabase({
                            action: {
                                type: 'PlaceAnOrder',
                                value: {
                                    content_type: 'Purchase',
                                    content_name: response.data.job_order,
                                    currency: "PHP",
                                    value: totalAmount, // your total transaction value goes here
                                    content_ids: [response.data.job_order],
                                }
                            }
                        })
                        window.ttq.track('PlaceAnOrder', {
                            content_type: "product",
                            quantity: GAItems.map(p => p.quantity).reduce((a, b) => a + b, 0),
                            description: discountName + discountAmount + hasCredit ? "STORECREDIT" + credit : "",
                            content_id: response.data.job_order,
                            currency: "PHP",
                            value: totalAmount
                        })
                    }
                    window.gtag("event", "purchase", {
                        transaction_id: response.data.job_order,
                        value: totalAmount,
                        shipping: totalAmount < 500 ? sfee : 0,
                        currency: "PHP",
                        coupon: discountName + discountAmount + hasCredit ? "STORECREDIT" + credit : "",
                        items: GAItems
                    });

                    window.gtag("event", "add_payment_info", {
                        currency: "PHP",
                        value: totalAmount,
                        payment_type: payment,
                        items: GAItems
                    });

                    dispatch(getOrderID(response.data.job_order))
                    let shipData = shipmentData
                    shipData.courier_service = selectedShippingMethod.courier_service
                    //saved data to session storage
                    sessionStorage.setItem('order', JSON.stringify(response.data))
                    sessionStorage.setItem('selectedPaymentMethod', JSON.stringify(shipData))
                    if (payment === 'Debit/Credit') { // Debit/Credit Payment method
                        setEnableCheckoutButton(true) //show error message
                        history.push('/paymongo/debit-credit')
                    } else if (payment === 'COD') { // Cash on delivery Payment method
                        let result = await bookShipment(shipData)
                        const response_shipment = await Home.post(`/save_courier_info`, { order_id: response.data.check_id, courier: result.data })
                        setEnableCheckoutButton(true) //show error message
                        history.push('/thank-you')
                    } else if (payment === 'Atome') {
                        if (response.status === 200) {
                            let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                            let url = response.data.atomeData.redirectUrl
                            const details = {
                                paymentId: response.data.job_order,
                                checkID: orderDetails.check_id,
                                paymentMethod: "Atome"
                            }
                            const savePaymentID = async () => {
                                try {
                                    const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                    if (res.status === 200) {
                                        setEnableCheckoutButton(true) //show error message
                                        window.location.href = url
                                    }
                                } catch (err) {
                                    console.log(err)
                                }
                            }
                            savePaymentID()
                        }

                    } else if (payment === 'LandBank') { // Landbank Payment method
                        setEnableCheckoutButton(true) //show error message
                        history.push('/landbank-form')
                    } else if (payment === 'UB') { // UB Payment method
                        setEnableCheckoutButton(true) //show error message
                        history.push('/xendit/direct-debit')
                    } else if (payment === 'BPI') { // BPI Payment method
                        setEnableCheckoutButton(true) //show error message
                        history.push('/xendit/direct-debit')
                    } else if (payment === 'GCash') { // Gcash Payment method
                        const result = await paymongo.sources.create(data);
                        const details = {
                            id: result.data.id,
                            redirect: result.data.attributes.redirect.checkout_url
                        }
                        let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                        const body = {
                            payID: result.data.id,
                            totalAmount: totalAmount,
                            checkID: orderDetails.check_id,
                            paymentMethod: payment,
                            accessKey: accessKey
                        }
                        const response = await Home.post(`/paymongo/payment/payID`, body)
                        const stringifyData = JSON.stringify(details)
                        sessionStorage.setItem('paymongo', stringifyData)
                        // history.push({pathname : `${details.redirect}`})
                        setEnableCheckoutButton(true) //show error message
                        window.location.href = details.redirect

                    } else if (payment === 'Grabpay') { // Grabpay Payment method
                        const result = await paymongo.sources.create(data);
                        const details = {
                            id: result.data.id,
                            redirect: result.data.attributes.redirect.checkout_url
                        }
                        let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                        const body = {
                            payID: result.data.id,
                            totalAmount: totalAmount,
                            checkID: orderDetails.check_id,
                            paymentMethod: payment,
                            accessKey: accessKey
                        }
                        const response = await Home.post(`/paymongo/payment/payID`, body)
                        const stringifyData = JSON.stringify(details)
                        sessionStorage.setItem('paymongo', stringifyData)
                        // history.push('/paymongo/source')
                        setEnableCheckoutButton(true) //show error message
                        window.location.href = details.redirect
                    } else if (payment === 'Paymaya') { // Paymaya Payment method
                        let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                        let auth = process.env.REACT_APP_PAYMAYA
                        const options = {
                            method: 'POST',
                            url: 'https://pg.paymaya.com/payby/v2/paymaya/payments',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: auth
                            },
                            data: {
                                totalAmount: { value: totalAmount, currency: 'PHP' },
                                redirectUrl: {
                                    success: window.location.origin + '/thank-you',
                                    failure: window.location.origin + '/user-profile',
                                    cancel: window.location.origin + '/user-profile'
                                },
                                metadata: {
                                    pf: {
                                        smi: '101065221',
                                        smn: 'Zagana Inc.',
                                        mci: 'Quezon City',
                                        mpc: '608',
                                        mco: 'PHL'
                                    }
                                },
                                requestReferenceNumber: orderDetails.job_order
                            }
                        };

                        axios.request(options).then(function (response) {
                            const url = response.data.redirectUrl
                            if (response.status === 200) {
                                const details = {
                                    paymentId: response.data.paymentId,
                                    checkID: orderDetails.check_id,
                                    paymentMethod: payment
                                }
                                const savePaymentID = async () => {
                                    try {
                                        const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                        if (res.status === 200) {
                                            setEnableCheckoutButton(true) //show error message
                                            window.location.href = url
                                        }
                                    } catch (err) {
                                        console.log(err)
                                    }
                                }
                                savePaymentID()
                            }
                        }).catch(function (error) {
                            console.error(error);
                        });

                    } else if (payment === "Billease") { // Billease Payment method
                        let orderDetails = JSON.parse(sessionStorage.getItem('order')) // order details 
                        let voucherAmount = discount.discount_amount // voucher amount
                        let voucherCode = discount.discount_code // voucher code
                        let customerName = getUser.customer_name // customer name
                        let customerEmail = getUser.customer_email // customer email
                        let customerPhone = getUser.customer_phone // customer phone number
                        let orderID = response.data.job_order // order ID
                        let freeDel = freeDelivery.discount_code // free del code

                        let filteredcart = cart.filter((item) => {
                            return item.item_status === "enabled" ? item : null
                        })
                        const renderCart = filteredcart.map(item => { // render cart items
                            // item varialbles
                            let itemSku = item.item_sku
                            let itemName = item.item_name
                            let itemPrice = item.cart_price
                            let itemQty = item.cart_qty

                            // Cart item details
                            const item_details = {
                                code: itemSku,
                                item: itemName,
                                price: parseFloat(itemPrice),
                                quantity: itemQty,
                                currency: "PHP",
                                seller_code: "ZGN"
                            }
                            return item_details
                        })

                        const deliveryFee = { // add Delivery fee in item (defautlt)
                            code: "Shipping Fee",
                            item: "Delivery Fee",
                            price: 99,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }
                        const customerFreeDel = { // add free delivery deduction in item
                            code: freeDel,
                            item: `Free delivery: ${freeDel}`,
                            price: -99,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }
                        const customerDiscount = { // add Discount/Voucher deduction in item
                            code: voucherCode,
                            item: `Voucher: ${voucherCode}`,
                            price: -voucherAmount,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }
                        const customerStoreCred = { // add StoreCredit in deduction item
                            code: 'zgnstorecredit',
                            item: `Store Credit: ${credit}`,
                            price: -credit,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }

                        // push delivery fee to item
                        renderCart.push(deliveryFee)

                        if (total.itemTotal > 500) { // push delivery fee to item if subtotal greater or equal to 500
                            renderCart.push(customerFreeDel)
                        }
                        if (discount.discount_amount) { // push voucher to item if voucher true
                            renderCart.push(customerDiscount)
                        }
                        if (hasCredit) { // push store credit to item if voucher true
                            renderCart.push(customerStoreCred)
                        }

                        // Billease Payment
                        const body = { // Payloads
                            "merchant_code": merchant_code, // merchant code
                            "shop_code": shop_code, // shop code
                            "amount": parseFloat(totalAmount), // total amount === items total amount
                            "currency": "PHP",
                            "checkout_type": "standard",
                            "seller_code": "ZGN",
                            "items": renderCart, // items list
                            "sellers": [ // seller details
                                {
                                    "code": "ZGN",
                                    "seller_name": "Zagana inc.",
                                    "url": "http://zagana.com"
                                }
                            ],
                            "customer": { // customer details
                                "full_name": customerName,
                                "email": customerEmail,
                                "phone": customerPhone,
                                "internal_user_id": customerEmail,
                            },
                            "callbackapi_url": `https://api.zagana.com/api/billease/trx/webhook`,
                            // "callbackapi_url": `${process.env.REACT_APP_ZAGANA_URL}api/billease/trx/webhook`,
                            "url_redirect": window.location.origin + "/thank-you",
                            "failed_url_redirect": window.location.origin + "/checkout-details",
                            "order_id": orderID
                        }
                        const options = { // axios options
                            "method": 'POST',
                            "url": billeaseUrl + '/be-transactions-api/trx/checkout',
                            "headers": {
                                "Content-Type": 'application/json',
                                "Authorization": `bearer ${token}`
                            },
                            "data": body
                        };
                        // if (totalAmount > 150 && parseFloat(total.itemTotal) > 150 ) { // minimum of 150php to make transaction condition
                        axios.request(options).then(function (response) { // connection to Billease
                            const url = response.data.redirect_url // url 
                            if (response.status === 200) {
                                // variables
                                let paymentID = response.data.trxid
                                let checkID = orderDetails.check_id
                                const details = { // payment details
                                    paymentId: paymentID,
                                    checkID: checkID,
                                    paymentMethod: payment
                                }
                                const savePaymentID = async () => { // save payment ID 
                                    try {
                                        const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                        if (res.status === 200) { // redirect to billease payment
                                            setEnableCheckoutButton(true) //show error message
                                            window.location.href = url
                                        }
                                    } catch (err) {
                                        console.log(err)  // log error
                                    }
                                }
                                savePaymentID() // run save payment
                            }
                        }).catch(function (error) {
                            setErrorMsg(error.message) // error message
                            setError(true)
                            setEnableCheckoutButton(true) //show error message
                            setTimeout(() => {
                                setError(false) // hide error message
                            }, 1200)
                        });
                        // } else{ // minimum value error 
                        //     setErrorMsg(`Sorry, A minimum value of 150php in Subtotal and Total Payment to proceed Transaction`) // error message
                        //     setError(true)
                        // setEnableCheckoutButton(true) //show error message
                        //     setTimeout(() => {
                        //         setError(false) // hide error message
                        //     }, 2000)
                        // }
                    }
                }
            } catch (err) {
                if (err.detail === "The value for amount cannot be less than 10000." && err.code === "parameter_below_minimum") {
                    setErrorMsg('The value for amount cannot be less than 100.')
                    setError(true)
                    setEnableCheckoutButton(true)
                    setTimeout(() => {
                        setError(false)
                    }, 2000)
                }
            }
        }
    }

    const onBlurNotes = (e) => {
        //saving special instruction to sessionStorage
        sessionStorage.setItem('notes', e.target.value || '')
    }

    const showModalGuide = (e) => {
        setModalMultipleStatus(true)
    }
    // const multipleStops = () => {

    //     if (isMobile === false) {
    //         let element = document.getElementById("COD");
    //         element.classList.add("disabledCOD");
    //     }
    //     if (paymentSelected.paymentSelected === 'COD') {
    //         dispatch({
    //             type: "SELECTED_PAYMENT",
    //             selected: 'Select payment',
    //             status: true
    //         })
    //     }
    //     setStops('Multiple')
    //     sessionStorage.setItem('stops', 0)
    //     sessionStorage.setItem('NumStops', "Multiple" || "")
    //     showModalGuide()
    // };

    // const singleStops = () => {

    //     if (isMobile === false) {
    //         let element = document.getElementById("COD");
    //         element.classList.remove("disabledCOD");
    //     }
    //     setStops('Single')
    //     sessionStorage.setItem('stops', 0)
    //     sessionStorage.setItem('NumStops', "Single" || "")
    //     history.push('/checkout-details')
    // };
    const onChangeStops = (e) => {
        sessionStorage.setItem('stops', e.target.value || 0)
        const stops = sessionStorage.getItem('stops') || 0
        //fetch shipping fee amount
        const getShippingFee = async () => {
            try {
                const response = await Home.post(`/get-shippingfee`)
                if (stops > 1) {
                    setShippingFee((response.data.sfee_price * stops) || 0)
                } else {
                    setShippingFee(response.data.sfee_price || 0)
                }
            } catch (err) {
                console.log(err)
            }
        }
        getShippingFee()
    }


    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                Fruit baskets are made to order and may not be delivered on your chosen schedule. Our Customer Success Rep will reach out to you for any delivery concerns.
            </Popover.Body>
        </Popover>
    );

    return (
        <React.Fragment>
            <ModalPromo modalPromoStatus={modalMultipleStatus} discount={discount} imageUrl={""} multiStop={true} setModalPromoStatus={setModalMultipleStatus} />
            {/* <ModalPromo modalPromoStatus={modalPromoStatus} discount={discount} imageUrl={"https://res.cloudinary.com/dw5axbz64/image/upload/v1638980520/upload/Lark20211209-002135_y9oqct.jpg"} setModalPromoStatus={setModalPromoStatus} /> */}
            <div className="container">
                <div className="row">
                    {isMobile ?
                        <Header />
                        : <div className="row justify-content-md-center m-0 pc_nav" style={style.headerBG} >
                            <div className="row">

                                <div className="col-2 d-none d-sm-block text-center">
                                    <a href="/">
                                        <ZaganaLogo />
                                    </a>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className="row border-top mt-5">
                    <div className="col-lg-7 col-md-6 pt-4 bg-white">
                        <div className="row">
                            <div className="col">
                                <h5 className="pt-2">Shipping Address</h5>
                            </div>
                        </div>
                        <div className="row">
                            <AddressView setCheckoutAddress={setAddress} />
                        </div>
                        <div className="row mt-3">
                            <div className="row mb-3">
                                <div className="col">
                                    <h5>Delivery Date
                                        <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                                            <button className="btn btn-link" style={{ color: '#24b14b', fontSize: '12px', fontStyle: 'normal' }}>Disclaimer</button>
                                        </OverlayTrigger></h5>
                                </div>
                                <div className="col">
                                </div>
                            </div>
                            <div className="row ">
                                <DeliveryFields disableDel={categDel} setShippingDate={setShippingDate} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="row ">
                                <h5>Payment Method</h5>
                            </div>
                            <div className="row ">
                                <Payments orderTotal={orderTotal} disabledCOD={disabledCOD} />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="row ">
                                <h5>Shipping Method</h5>
                            </div>
                            <div className="row ">
                                {
                                    shippingLoading ?
                                        <div style={{ textAlign: "center" }}>
                                            <Spinner animation="border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        </div>
                                        :
                                        renderShippingMethod.map((data) =>
                                            <div className="col-sm-3 p-1">
                                                <div className="container mt-2">
                                                    <div className="card card-payment m-1 " onClick={() => handleShippingClick(data)}
                                                        style={data.courier_service === selectedShippingMethod.courier_service ? {
                                                            outlineColor: "rgb(36, 177, 75)",
                                                            outlineStyle: "solid",
                                                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"
                                                        } : {}}
                                                    >
                                                        <div className="card-body">
                                                            <div className="row text-center">
                                                                <div className="row-6">
                                                                    <div style={{ minHeight: '49px' }}>
                                                                        <img src={data.courier === "Grab" ? grabLogo : CourierJson[data.courier].img} width="50%" alt="paymaya" style={style.paymayaimg} />
                                                                    </div>
                                                                    <p style={{ minHeight: '50px', maxHeight: '50px' }}>{data.courier_service_name}</p>
                                                                    Php {((data.fees.shipping_fee + data.fees.shipmates_fee + (data.fees?.cod_fee ?? 0) + (data.fees?.valuation_fee ?? 0)) || 0).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)
                                }
                            </div>
                        </div>

                        {/* <div className="row mt-3">
                                    <div className="row">

                                        {stop === "Single" ?
                                            <div className="col-6 d-flex justify-content-center">
                                                <input type="button" className="btn-md btn-block w-100 p-3 btn btn-primary btn-md btn-block" value="Single Address" onClick={singleStops} />
                                            </div>
                                            :
                                            <div className="col-6 d-flex justify-content-center">
                                                <input type="button" className="btn-md btn-block w-100 p-3 btn btn-outline-success btn-md btn-block" value="Single Address" onClick={singleStops} />
                                            </div>
                                        }

                                        {stop === "Multiple" ?
                                            <div className="col-6 d-flex justify-content-center">
                                                <input type="button" className="btn-md btn-block w-100 p-3 btn btn-primary btn-md btn-block" value="Multiple Addresses" onClick={multipleStops} />
                                            </div>
                                            :
                                            <div className="col-6 d-flex justify-content-center">
                                                <input type="button" className="btn-md btn-block w-100 p-3 btn btn-outline-success btn-md btn-block" value="Multiple Addresses" onClick={multipleStops} />
                                            </div>
                                        }
                                    </div>
                                    {stop === "Multiple" ?
                                        <div id="StopsOpt">
                                            <div className="row mt-2">
                                                <h5>No. of stops</h5>
                                            </div>
                                            <div className="row p-5 pt-0 pb-0">
                                                <input type="number" className="form-control" step="1" name="numStops" defaultValue={stops} onKeyUp={onChangeStops} />
                                            </div>
                                        </div>
                                        :
                                        ""
                                    }
                                </div> */}
                        <div className="row mt-3">
                            <div className="row mt-2">
                                <h5>Special Instructions
                                    {/* <span><button className="btn btn-link" style={{ color: '#24b14b', fontSize: '12px', fontStyle: 'normal' }} onClick={showModalGuide}>Guide</button></span> */}
                                </h5>
                            </div>
                            <div className="row p-5 pt-2">
                                <textarea
                                    className="form-control w-100"
                                    style={style.height}
                                    onBlur={onBlurNotes}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 p-4 pt-4 pb-5 bg-white">
                        <div className="row overflow-auto pb-0">
                            <OrderDetails total={total} setTotal={setTotal} branch={branch} onCartLoaded={onCartLoaded} cart={cart} setCart={setCart} />
                        </div>
                        <div className="row pt-4 pb-1">
                            <div className="col p-1 pt-0 m-2 mt-0 mb-0">
                                <div className="row p-1 pt-4 rounded-top bg-light">
                                    <h5>Voucher/ Referral</h5>
                                </div>
                                <div className="row pt-0 rounded-bottom bg-light">
                                    <Voucher totalOrder={orderTotal} shippingFee={shippingFee} />
                                </div>
                                {/* <div className="row pt-0 rounded-bottom bg-light">
                                            <Referral />
                                        </div> */}
                            </div>
                        </div>
                        <div className="row pt-4 pb-1">
                            <div className="col p-1 pt-0 m-2 mt-0 mb-0">
                                <div className="row p-1 pt-4 rounded-top bg-light">
                                    <h5>Store Credit</h5>
                                </div>
                                <div className="row pt-0 rounded-bottom bg-light">
                                    <StoreCredit totalOrder={orderTotal} subTotal={total.itemCount} setHasCredit={setHasCredit} setCredit={setCredit} disabledCOD={disabledCOD} />
                                </div>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col p-3 pt-4 pb-4 bg-light rounded">
                                <div className="row p-1">
                                    <div
                                        className="col-7  my-auto "
                                    >
                                        <span>Subtotal ({total.itemCount} item/s):</span>
                                    </div>
                                    <div
                                        className="col-5  my-auto"
                                        style={style.textRight}
                                    >
                                        <span >
                                            {total.itemTotal.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                        </span>
                                    </div>
                                </div>
                                <div className="row p-1 ">
                                    <div
                                        className="col-7  my-auto "

                                    >
                                        {stops > 1 ?
                                            < span > Shipping fee ({stops} stops):</span>
                                            :
                                            < span > Shipping fee:</span>
                                        }
                                    </div>
                                    <div
                                        className="col-5  my-auto"
                                        style={style.textRight}
                                    >
                                        <span>
                                            {shippingFee.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}
                                        </span>
                                    </div>
                                </div>
                                {discount.discount ?
                                    <div className="row p-1">
                                        <div
                                            className="col-7  my-auto"
                                        >
                                            <span >Voucher Code ({discount.discount_code}) </span>
                                        </div>
                                        <div
                                            className="col-5  my-auto"
                                            style={style.textRight}
                                        >
                                            <span>-{Number(discount.discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {secretDiscount.Secret_discount ?
                                    <div className="row p-1">
                                        <div
                                            className="col-7  my-auto"
                                        >
                                            <span >Referral  Code ({secretDiscount.Secret_discount_code}) </span>
                                        </div>
                                        <div
                                            className="col-5  my-auto"
                                            style={style.textRight}
                                        >
                                            <span>-{Number(secretDiscount.Secret_discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {freeDelivery.discount ?
                                    <div className="row p-1">
                                        <div
                                            className="col-7  my-auto"
                                        >
                                            <span >Free Shipping ({freeDelivery.discount_code}) </span>
                                        </div>
                                        <div
                                            className="col-5  my-auto"
                                            style={style.textRight}
                                        >
                                            <span>-{Number(freeDelivery.discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                        </div>
                                    </div>
                                    : null
                                    // <div className="row p-1">
                                    //     <div
                                    //         className="col-7  my-auto"
                                    //     >
                                    //         <span style={{fontStyle:"italic",color:"rgb(255, 196, 89)", fontSize:'10px'}} className="font-italic">You can get free delivery for purchases Greater than or equal to 1000 </span>
                                    //     </div>
                                    // </div>
                                }
                                {hasCredit ?
                                    <div className="row p-1">
                                        <div
                                            className="col-7  my-auto"
                                        >
                                            <span >Store Credit ({credit}) </span>
                                        </div>
                                        <div
                                            className="col-5  my-auto"
                                            style={style.textRight}
                                        >
                                            <span>-{Number(credit).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</span>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                <div className="row p-1">
                                    <div
                                        className="col-7  my-auto"
                                    >
                                        <b >Total Payment:</b>
                                    </div>
                                    <div
                                        className="col-5  my-auto"
                                        style={style.textRight}
                                    >
                                        <b>{orderTotal.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</b>
                                    </div>
                                </div>
                                <div className="row p-1">
                                    <div className="col  my-auto">
                                        <button id="place_order" className="btn w-100 btn-primary" onClick={enableCheckoutButton ? onClickCheckout : null} disabled={!enableCheckoutButton}>
                                            {
                                                enableCheckoutButton ? 'Place Order'
                                                    :
                                                    <CircularProgress
                                                        size={16} style={{ color: 'white' }} />
                                            }
                                        </button>
                                    </div>
                                </div>
                                <div className="row p-1">
                                    <div
                                        className="col my-auto text-center"
                                        style={{ fontSize: '.87rem' }}
                                    >
                                        <span> By confirming your order, you agree to the <Link to='/ContentPage/terms' style={style.terms}> Terms &amp; Conditions</Link>.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row fixed-bottom mb-4 ">
                    <div className="col my-auto d-flex justify-content-center">
                        <button
                            id="place_order"
                            className="btn"
                            onClick={enableCheckoutButton ? onClickCheckout : null}
                            disabled={!enableCheckoutButton}
                            style={style.buttonFixed}
                        >
                            {
                                enableCheckoutButton ?
                                    <div className="row">
                                        <div
                                            className="col-sm-6 my-auto"
                                            style={style.buttonFixedFont}
                                        >
                                            Total: {orderTotal.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}

                                        </div>
                                        <div className="col-sm-6 my-auto d-flex justify-content-center">
                                            <span> Place Order</span>
                                        </div>
                                    </div>
                                    :
                                    <CircularProgress
                                        size={16} style={{ color: 'white' }} />
                            }

                        </button>
                    </div>
                </div>
                <ErrorModal btn={error} body={errorMsg} />
            </div>
        </React.Fragment >
    )

}
const mapStateToProps = (state) => {
    return {
        discount: state.discount,
        paymentSelected: state.payment,
        timeError: state.time,
        CartRedux: state.cart.CartList,
        secretDiscount: state.secretDiscount
    }
}
export default connect(mapStateToProps)(CheckoutDetails);