import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import EditAddress from "./EditAddress";
import edit from "../../../assets/site_icons/pencil-square.svg"
import axios from "axios";
import { useDispatch } from "react-redux";
import { initialAddressInfo } from "../../../actions";
import Home from "../../APIs/Home";

const style = {
    border: {
        border: 'unset',
        paddingBottom: 'unset',
        paddingLeft : '3rem'
    },
    body: {
        padding: '3.5rem',
        paddingTop: 'unset',
        paddingBottom: '2rem'
    }
}

const EditAddressModal = ({id}) => {

    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = async () => {
        let add_id = {
            add_id: id,
        }
        try {
            const response = await Home.post(`/fetchAddress`, add_id)
            dispatch(initialAddressInfo(response.data[0]))
        } catch (err) {
            console.log(err)
        }
        setShow(true)
    };

    return (
        <React.Fragment>
            <div className="row" onClick={handleShow} >
                <img src={edit} alt="edit_logo" />
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="text-center" style={style.border} closeButton>
                    <h4 className="text-center w-100">Edit Address</h4>
                </Modal.Header>
                <Modal.Body style={style.body}>
                    <EditAddress id={id} onHide={setShow} />
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );

}

export default EditAddressModal;