/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : Address
 * File Directory           : src/components/Shared/Address
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : change and add address 
 * Used In					        : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Address        -   component for showing Address list
 *
 * #How to call:
 *    /address/change
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import AddressModal from "./AddressModal";
import EditAddressModal from "./EditAddressModal";
import Header from "../Header";
import './Address.css';
import Home from "../../APIs/Home";
import edit from "../../../assets/site_icons/pencil-square.svg"
import { initialAddressInfo } from "../../../actions"


const style = {
    add: {
        textDecoration: 'unset',
        fontSize: '1rem',
        width: '100%',
        backgroundColor: '#24b14b',
        borderColor: '#24b14b'
    },
    check: {
        height: '1rem',
        width: '1rem',
        position: 'absolute',
        top: '.4rem',
        right: '.3rem',
        display: 'flex',
        color: '#24b14b'

    },
    display: {
        display: 'none'
    },
    align: {
        textAlign: 'left',
        padding: '.4rem',
    },
    alignDesktop: {
        textAlign: 'left',
        padding: '.3rem',
        height: '7.2rem'
    },
    selected: {
        outlineColor: "#24b14b",
        outlineStyle: "solid"
    },
    inline: {
        overflowX: 'scroll',
        height: '10rem',
        display: 'inline',
        whiteSpace: 'nowrap'

    },
    col: {
        maxWidth: '15rem',
        whiteSpace: 'pre-line',
        height: '7rem'
    },
    fontSize: {
        fontSize: '.9rem'
    },
    edit: {
        position: 'absolute',
        bottom: '0.2rem',
        right: '0.3rem'
    }
}

const AddressView = ({ AddAddress, myAddress, setCheckoutAddress }) => {
    const history = useHistory()
    const isMobile = localStorage.getItem("isMobile") === "true";
    const [address, setAddress] = useState([])
    const [selected, setSelected] = useState()
    const customerID = sessionStorage.getItem('Login') || '{}'
    const parseCustomerID = JSON.parse(customerID) || '{}'
    const selectedCity = JSON.parse(localStorage.getItem('SelectedCity'))
    const data = { user_id: parseCustomerID.customer_id, city_id: selectedCity.city_id }
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const dispatch = useDispatch()
    if (!getUser) {
        window.location.replace("/login#checkout")
    }

    //get customer address from data base
    useEffect(() => {
        const GetAddress = async () => {
            try {
                const response = await Home.post(`/address`, data)
                if (response.data.results.length > 0) {
                    const selectedAddress = response.data.results.filter((data) => {
                        return data.add_status === 'Enable'
                    })
                    const values = {
                        address_id: selectedAddress[0].add_id,
                        address_street: selectedAddress[0].add_apartment + " " + selectedAddress[0].add_street,
                        brgy_name: selectedAddress[0].brgy_name,
                        city_name: selectedAddress[0].city_name,
                        zip_code: selectedAddress[0].address_zip,
                        address_landmark: '',
                        address_apartment: selectedAddress[0].add_apartment,
                        receiver_name: selectedAddress[0].add_fn,
                        receiver_contactNo: selectedAddress[0].add_contact
                    }

                    sessionStorage.setItem('Address', JSON.stringify(values))
                    setCheckoutAddress(selectedAddress[0])
                    setSelected(selectedAddress[0].add_id)
                    setAddress(response.data.results)
                } else {
                    sessionStorage.setItem('Address', '{}')
                }
            } catch (err) {
                console.log(err)
            }
        }
        GetAddress()
    }, [AddAddress])

    //update address status when selected and save to session storage for desktop
    const onClickAddress = async (e) => {
        let data = {
            address_id: e.currentTarget.dataset.id,
            address_apartment: e.currentTarget.dataset.apartment,
            address_landmark: '',
            address_street: e.currentTarget.dataset.street,
            brgy_name: e.currentTarget.dataset.brgy,
            city_name: e.currentTarget.dataset.city,
            receiver_contactNo: e.currentTarget.dataset.contact,
            receiver_name: e.currentTarget.dataset.name,
            zip_code: e.currentTarget.dataset.zip,
        }
        let values = {
            id: e.currentTarget.id,
            address: e.currentTarget.innerText,
        }
        setSelected(e.currentTarget.id)
        setCheckoutAddress(data)
        sessionStorage.setItem('Address', JSON.stringify(data))
        const customerID = sessionStorage.getItem('Login') || '{}'
        const parseCustomerID = JSON.parse(customerID) || '{}'
        const user_id = { user_id: parseCustomerID.customer_id, add_id: values.id, city_id: selectedCity.city_id }
        const selectAddress = async () => {
            try {
                await Home.post(`/selectAddress`, user_id)
            } catch (err) {
                console.log(err)
            }
        }
        selectAddress()
        if (isMobile) {
            history.push('/checkout-page')
        }
    }
    const onClickEdit = async (e) => {
        let add_id = {
            add_id: e.currentTarget.dataset.id,
        }
        try {
            const response = await Home.post(`/fetchAddress`, add_id)
            dispatch(initialAddressInfo(response.data[0]))
        } catch (err) {
            console.log(err)
        }
        if (isMobile) {
            history.push('/address/edit')
        }
    }

    const renderedAddress = address.map((result, index) => {
        return (

            <div
                key={index}
                className="btn col w-100"
                style={isMobile ? null : style.col}
            >
                <div
                    className="card rounded"
                    style={result.add_id === parseInt(selected) ? style.selected : null}
                >
                    <div
                        className="card-body"
                        id={result.add_id}
                        style={isMobile ? style.align : style.alignDesktop}
                        onClick={onClickAddress}
                        data-id={result.add_id}
                        data-apartment={result.add_apartment}
                        data-street={result.add_street}
                        data-brgy={result.brgy_name}
                        data-city={result.city_name}
                        data-contact={result.add_contact}
                        data-name={result.add_fn}
                        data-zip={result.address_zip}
                    >
                        <span>
                            <div className="row" >
                                <div className="col" style={isMobile ? null : style.fontSize} >
                                    <b>{result.add_fn}</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col addressView"
                                    name="address"
                                    style={isMobile ? null : style.fontSize}
                                >
                                    {result.add_apartment} {result.add_street}
                                    , {result.brgy_name}
                                    , {result.city_name}
                                    , {result.address_zip}
                                </div>
                            </div>
                            <div className="row" >
                                <div className="col" style={isMobile ? null : style.fontSize}>
                                    <span>+(63){result.add_contact}</span>

                                </div>

                            </div>
                        </span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            className="bi bi-check-circle"
                            viewBox="0 0 16 16"
                            style={result.add_id === parseInt(selected) ? style.check : style.display}
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                        </svg>

                    </div>
                    <div className="col" style={style.edit}>
                        {isMobile ?
                            <img src={edit} data-id={result.add_id} alt="edit_logo" onClick={onClickEdit} />
                            :
                            <EditAddressModal id={result.add_id} />
                        }
                    </div>

                </div>
            </div>
        )
    })

    return (

        <React.Fragment>
            {isMobile ?
                <div className="p-0">
                    {/* {!myAddress ?
                        <Header title="Address Book" />
                        :
                        null
                    } */}
                    <div className="container">
                        <div className="row p-0">
                            <div className="col p-0 ">
                                {renderedAddress}
                            </div>
                        </div>
                        <div className="hideonModal row mb-5 pb-3 mt-2">
                            <div className="col">
                                <Link
                                    to="/address/add"
                                    className="btn btn-success"
                                    style={style.add}
                                >
                                    <span>ADD</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="container">
                        <div className="row p-0 ">
                            <div className="col-12 p-3 address" style={style.inline}>
                                <div className="btn col h-100">
                                    <div className="card-body h-100 d-flex my-auto p-0">
                                        <AddressModal />
                                    </div>
                                </div>
                                {renderedAddress}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>

    )
}
const mapStateToProps = (state) => {
    return {
        AddAddress: state.address.AddAddress,
    }
}
export default connect(mapStateToProps)(AddressView);