
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : PrivacyPolicy
 * File Directory           : src/components/Misc/CookiePolicy
 * Module                   : Show All Privacy Policy from Zagana
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : To view the Privacy Policy 
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   PrivacyPolicy        -   component for Cookie Policy Page
 *
 * #How to call:
 *    <CookiePolicy />
 *
 *******************************************************************************************/

import React from "react";
import Header from "../Shared/Header";
import DesktopHeader from '../Shared/DesktopHeader';

const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    a: {
        wordWrap: "break-word",
        wordBreak: "break-all",
        whiteSspace: "normal",
        display: "block",
    },
    marginTop :{
        marginTop : '5vw'
    }
}

const CookiePolicy = () => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    return (
        <React.Fragment>
            {isMobile ?
                null
                :
                <div style={{ position: 'fixed' }} className="p-0" >
                    <DesktopHeader />
                </div>
            }
            <div className="container pt-2" style={style.marginTop}>
                <div className="p-3" style={style.center}>
                    <div className="col-12 text-center">
                        <h3>Cookie Policy</h3>
                    </div>
                    <div className="col-12">
                        Cookies are used on our website to recognize you from other visitors. This enables us to offer you with a positive browsing experience while also allowing us to enhance our website.
                        If you agree, we will store a small file of characters and numbers on your browser or on your computer's hard disk as a cookie. Cookies are small data files that are saved on your computer's hard disk by websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.
                    </div>
                    <div className="col-12 mt-2">
                        We use the following cookies:
                        <ul>
                            <li>
                                <b>Strictly necessary cookies.</b> These are cookies that are essential for our website to function properly. Cookies, for example, allow our website to remember information you enter into forms during a session or to remember your preferences.
                            </li>
                            <li>
                                <b>Analytical or performance cookies.</b> These let us recognize and count the number of visitors as well as see how they navigate our website while on it. This allows us to enhance the way our website functions, such as ensuring that people can simply locate what they're looking for.
                            </li>
                            <li>
                                <b>Marketing and advertising cookies.</b> These cookies, which Zagana Inc. or its media agencies have put on this site, track your visit to this website, the pages you have visited, and the internet links you have followed. Zagana Inc., uses this information to tailor their online or social media advertisements or offers on third-party websites, or their own websites, to those that are most likely to be of interest to you, as well as to evaluate the effectiveness of their online marketing and advertising campaigns.
                            </li>
                        </ul>
                    </div>
                    <div className="col-12">
                        You can find more information about the individual cookies we use and the purposes for which we use them in the table below:
                        <div className=" table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Cookie Title <br /> Cookie Name</th>
                                        <th scope="col" style={{ minWidth: '250px' }}>Purpose</th>
                                        <th scope="col">Expiry</th>
                                        <th scope="col">More Information</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            Immediately after closing the tab
                                        </td>
                                        <td>
                                            Category: Strictly Necessary
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Cookie Preference <br /> <span style={{ color: 'red' }}>CookieControl</span>
                                        </td>

                                        <td>
                                            Used to distinguish user’s option about cookies on zagana.com.
                                            This cookie enables us to indicate the previously selected preference of the user.
                                        </td>

                                        <td>
                                            90 days
                                        </td>
                                        <td>
                                            Category: Strictly Necessary
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Universal Analytics (Google)
                                            <br />
                                            <span style={{ color: 'red' }}> _ga</span>
                                            <br />
                                            <span style={{ color: 'red' }}>_gid</span>
                                        </td>

                                        <td>
                                            These cookies are used to collect information about how visitors use our website.
                                            This cookie enables us to:
                                            <ol type="1">
                                                <li>Use the information to compile reports and to help us improve the website.</li>
                                                <li>The cookies collect information in a way that does not directly identify anyone, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.</li>
                                            </ol>
                                        </td>
                                        <td>
                                            2 years (_ga),
                                            24 hours (_gid)
                                        </td>
                                        <td>
                                            Category: Performance or Analytical
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Facebook Ads
                                            <br />
                                            <span style={{ color: 'red' }}>_fbp</span>
                                        </td>

                                        <td>
                                            We use cookies to count the number of times that an ad is shown and to calculate the cost of those ads.
                                            We also use cookies to measure how often people do things, such as make a purchase following an ad impression
                                        </td>
                                        <td>
                                            90 days
                                        </td>
                                        <td>
                                            Category: Marketing and Advertising
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12">
                                Please be aware that the third parties listed below may also utilize cookies over which we have no control. External services such as online traffic analysis services are examples of designated third parties. These third-party cookies will likely be analytical or performance cookies:
                            </div>
                            <div className="col-12 mt-4">
                                Our website analytics provider, Google Analytics, provides the above performance or analytical cookies.
                                Google's cookie policy can be found here:<a href="https://policies.google.com/technologies/types?hl=en-US" style={isMobile ? style.a : { width: "auto" }}> https://policies.google.com/technologies/types?hl=en-US</a>,
                                and you can learn more about the purposes of their cookies at <a style={isMobile ? style.a : { width: "auto" }} href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a>.
                            </div>
                            <div className="col-12 mt-4">
                                We use a simple implementation of the Google Analytics tool which has the effect of reducing our cookie use.
                            </div>
                            <div className="col-12 mt-4">
                                Google, via the Google Analytics tool described above, may change their cookie use and may also use other cookies over which we have no control. Google may use cookies across different websites to infer your interests.
                                You can see how Google categories your preferences here:
                                <br />
                                <a style={isMobile ? style.a : { width: "auto" }} href="https://adssettings.google.com/authenticated?hl=en_US">https://adssettings.google.com/authenticated?hl=en_US</a>.
                            </div>
                            <div className="col-12 mt-4">
                                You can manage your cookies with Google here: <br /> <a href="https://policies.google.com/technologies/managing" style={isMobile ? style.a : { width: "auto" }}>https://policies.google.com/technologies/managing</a>.
                            </div>
                            <div className="col-12 mt-4">
                                We reserve the right to make any changes and corrections to this notice. Please refer to this page from time to time to review these and new additional information.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )


}

export default CookiePolicy;