const INTIAL_STATE = {
    token: '',
    generatedOrderID: ''
};

const LandBank = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'accessToken':
            return {
                ...state, 
                token : action.token
            };
        case 'orderID':
            return {
                ...state, 
                generatedOrderID : action.generatedOrderID
            };
        default:
            return state;
    }

};

export default LandBank;
