
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ContactUs
 * File Directory           : src/components/Misc/ContactUs
 * Module                   : Show All Contact Us from Zagana
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To view the Contact Us 
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ContactUs        -   component for Contact Us Page
 *
 * #How to call:
 *    <ContactUs />
 *
 *******************************************************************************************/

 import React, { useEffect } from "react";
import Header from '../Shared/Header';
import { storeFrontLogs } from '../APIs/Logs';

const styles = {
    greenText: {
        color: "rgb(36, 177, 75)",
        cursor: "pointer",
    }
}
function ContactUs() {
    const isMobile = localStorage.getItem("isMobile") || "true"
    document.title = "Zagana | Terms & Condition"
    let gen_id = localStorage.getItem('gen_id')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || Contact Us`,
            type: 'view_contact_us',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }, [])

    return (
        <React.Fragment>
            {isMobile === "true" ?
                <Header title="Contact Us" />
                : ""}
            <div className="container">
                <div className="row p-4 pt-3 pb-2">
                    <p>
                        Contact us at <a href="tel:09176294376"> <strong style={styles.greenText}>09176294376</strong> </a><br />
                        or at <strong style={styles.greenText} onClick={() => {
                            window.open(
                                "mailto:hello@zagana.com?subject=Query on my orders"
                            );
                        }}>hello@zagana.com</strong>
                    </p>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactUs