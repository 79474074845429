/*******************************************************************************************
//  * File Name                : UseOutsideClick
//  * File Directory           : src/components/Home/UsOutsideClick
//  * Module                   : UseOutsideClick
//  * Author                   : Jake
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : ItemList
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   UseOutsideClick        -   function for determining outside clicks
 *
 * #How to call:
 *    <UseOutsideClick/>
 *
 * Note:
 *******************************************************************************************/
import { useEffect } from "react";

const UseOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export default UseOutsideClick;