/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : NoProducts
 * File Directory           : src/components/Shared/ItemListv2
 * Module                   : Items
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/NoProducts.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for no products ramaining on the cart
 *
 * #How to call:
 *    <NoProducts/>
 *******************************************************************************************/

import React from "react"

const style = {
    colorGreen: {
        color: '#24b14b'
    }
}

const NoProducts = () => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const isHome = window.location.pathname === "/"

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center mb-5 no_prods">
                <p className="text-center">
                    There are no more items in your shopping cart.
                    {isMobile || !isHome?
                        <span>To add to your basket, please click <a href="/" className="btn btn-transparent p-0" style={style.colorGreen}>here</a>.</span>
                        :
                        null
                    }

                </p>
            </div>
        </React.Fragment>
    )
}

export default NoProducts; 