import React from 'react';
import ReactDOM from 'react-dom';

import thunk from "redux-thunk"
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist'
import { createStore, applyMiddleware, compose } from 'redux';

import App from './App';
import reducers from './reducers';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
)
const persistor = persistStore(store)
ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </BrowserRouter>
    </Provider>,
    document.querySelector('#root'))
