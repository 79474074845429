
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ThankYou
 * File Directory           : src/components/Checkout/ThankYou
 * Module                   : Checkout Page
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To know that the order is complete
 * Used In				    : 
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ThankYou        -   component for Thank you Page 
 *
 * #How to call:
 *    <ThankYou />
 *
 *******************************************************************************************/

import React, { useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Header from "../Shared/Header"
import ZaganaLogo from '../Shared/ZaganaLogo';
import OrderSuccess from '../../assets/site_icons/OrderSuccess.svg'
import { connect } from 'react-redux';
import Backend from '../APIs/Backend';
import Home from '../APIs/Home';
import { storeFrontLogs } from '../APIs/Logs';
import StoreCredit from './StoreCredit';
import ReactGA from 'react-ga4'
import { SaveToSession } from '../Shared/SaveToSession';
import { SaveToDatabase } from "../Shared/SaveToDatabase";
import { quoteShipment, bookShipment } from "../APIs/Shipmates";

const styles = {
    container: {
        height: "100vh",
        padding: "3rem"
    },
    headerBG: {
        'backgroundColor': '#facc02'
    }
}
function ThankYou({ id, paymongo, landbankId, token, credit }) {
    let source = sessionStorage.getItem('source') || ''
    let date = localStorage.getItem('date') || ''
    if (source === 'FB') {
        SaveToDatabase({
            action: {
                type: 'pageView',
                value: {}
            }
        })
        ReactPixel.pageView();
    }
    document.title = "Zagana | Thank You"
    const custInfo = sessionStorage.getItem('Login')
    const pixelItems = localStorage.getItem('fbPixelItems')
    const parsedPixelItems = JSON.parse(pixelItems)
    const pixelContent = localStorage.getItem('fbPixelContent')
    const parsedPixelContent = JSON.parse(pixelContent)
    const totalAmount = sessionStorage.getItem("orderTotal") || 0
    const isMobile = localStorage.getItem("isMobile") === "true"
    const payment = sessionStorage.getItem('payment') === 'COD'
    const paymaya = sessionStorage.getItem('payment') === 'Paymaya'
    const landBank = sessionStorage.getItem('payment') === 'LandBank'
    const Atome = sessionStorage.getItem('payment') === 'Atome'
    const billease = sessionStorage.getItem('payment') === 'Billease'
    const shipData = JSON.parse(sessionStorage.getItem('selectedPaymentMethod'))
    const order_info = JSON.parse(sessionStorage.getItem('order'))
    const accessKey = localStorage.getItem('accessKey')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    if (payment || paymaya || landBank || Atome || billease) {

        localStorage.removeItem('fbPixelItems')
        localStorage.removeItem('fbPixelContent')
        localStorage.removeItem('cart')
        sessionStorage.removeItem('total')
        // sessionStorage.removeItem('payment')
        sessionStorage.removeItem('deliveryTime')
        sessionStorage.removeItem('deliveryDate')
        sessionStorage.removeItem('orderTotal')
        sessionStorage.removeItem('paymongo')
        sessionStorage.removeItem('notes')
        sessionStorage.removeItem('stops')
        sessionStorage.removeItem('NumStops')
        localStorage.removeItem('skip')
    }
    const onClickHome = () => {
        window.location = "www.zagana.com"
    }

    console.log(credit)
    // if(landBank){
    //     console.log(landbankId)

    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", `Bearer ${token}`);
    //     myHeaders.append("Content-Type", "application/json");

    //     var raw = JSON.stringify({
    //         "clientId": "aba6b914030a4c30a4c9a522dc0f686a",
    //         "secret": "3c75706433b947fab70d016c2f417e1e349XGAX4KU",
    //         "orderId": landbankId
    //     });

    //     var requestOptions = {
    //     method: 'POST',

    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    //     };

    //     fetch("http://bglbppay.eastus.cloudapp.azure.com:7314/api/PaymentGateway/OrderStatus", requestOptions)
    //     .then(response => response.text())
    //     .then(function (response) { 
    //         console.log(response)
    //     })
    // }

    useEffect(() => {
        SaveToSession({
            page: 'Thank You Page',
            path: window.location.search
        })
        const payload = {
            user: user,
            changes: `Zagana || Thank you page`,
            type: `view_thank_you_page`,
            date: `NOW()`
        }
        console.log(parsedPixelItems)
        ReactGA.event({
            'category': 'Checkout',
            'action': 'Order Completed',
        });
        if (source === 'FB') {
            parsedPixelItems.push(id)
            let renderedItems = parsedPixelItems.slice(0).reverse().map((item, index) => {
                return item
            })
            SaveToDatabase({
                action: {
                    type: 'Purchase',
                    value: {
                        currency: "PHP",
                        content_ids: renderedItems,
                        content_type: 'product',
                        content: parsedPixelContent,
                        num_items: parsedPixelContent.length,
                        value: parseFloat(totalAmount), // your total transaction value goes here
                    }
                }
            })
            ReactPixel.track('Purchase', {
                currency: "PHP",
                content_ids: renderedItems,
                content_type: 'product',
                content: parsedPixelContent,
                num_items: parsedPixelContent.length,
                value: parseFloat(totalAmount), // your total transaction value goes here
            });
        } else if (source === 'Tiktok') {
            parsedPixelItems.push(id)
            let renderedItems = parsedPixelItems.slice(0).reverse().map((item, index) => {
                return item
            })
            SaveToDatabase({
                action: {
                    type: 'Purchase',
                    value: {
                        currency: "PHP",
                        content_ids: renderedItems,
                        content_type: 'product',
                        content: parsedPixelContent,
                        num_items: parsedPixelContent.length,
                        value: parseFloat(totalAmount), // your total transaction value goes here
                    }
                }
            })
            window.ttq.track('CompletePayment', {
                content_type: "product",
                description: "Complete Transaction",
                content_id: id,
                currency: "PHP",
                value: parseFloat(totalAmount)
            })

        }
        console.log({
            currency: "PHP",
            content_type: 'product',
            content_ids: parsedPixelItems,
            content: parsedPixelContent,
            num_items: parsedPixelContent.length,
            value: parseFloat(totalAmount), // your total transaction value goes here
        })
        storeFrontLogs(payload)

        if (paymaya || landBank || Atome || billease) {
            const removeCart = async () => {
                try {
                    await Backend.post(`/Paymayav2/removecart`, { accessKey })
                } catch (err) {
                    console.log(err)
                }
            }
            removeCart()
            const book = async () => {
                try {
                    let result = await bookShipment(shipData)
                    const response_shipment = await Home.post(`/save_courier_info`, { order_id: order_info.check_id, courier: result.data })
                } catch (err) {
                    console.log(err)
                }
            }
            book()
        }
        const deductCredit = async () => {
            const details = { id: getUser.customer_id, credit: credit.credit }
            try {
                await Home.post(`/storeCredit/deduct`, details)

            } catch (err) {
                console.log(err)
            }
        }
        if (credit.isApplied) {
            deductCredit()
        }
    }, [])

    return (
        <React.Fragment>
            {!paymongo ?
                isMobile ?
                    <Header title="Thank You" />
                    : <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                        <div className="row">

                            <div className="col-2 d-none d-sm-block text-center">
                                <a href="/">
                                    <ZaganaLogo />
                                </a>
                            </div>
                        </div>
                    </div>

                :
                null
            }
            <div className="container mt-5" id="thankYou_con" style={styles.container}>
                <div className="row align-items-center" >
                    <center>
                        <img src={OrderSuccess} alt="Thank you Icon" width="100%" />
                        <br />
                        <p><strong>Yay! It's Done</strong> </p>
                        <p>Transaction Successful</p>
                        <p>Your Order is <b># {id}</b></p>
                        <p>We'll process your goods immediately.</p>
                    </center>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return ({
        id: state.OrderID.order_id,
        token: state.LandBank.token,
        landbankId: state.LandBank.generatedOrderID,
        credit: state.StoreCredit
    })
}

export default connect(mapStateToProps)(ThankYou);