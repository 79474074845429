//  * File Name                : DeliveryTime
//  * File Directory           : src/components/Shared/DeliveryTime
//  * Module                   : DeliveryTime
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/App.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DeliveryTime        -   component for setting delivery schedule
 *
 * #How to call:
 *    <DeliveryTime/>
 *
 * Note: Still working on the ff:
 * Date: set only from today to tom and next day
 * Upon clicking on confirm or delivery now the data is saved on localstorage
 * 

 *******************************************************************************************/
import React, { useState, useRef, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Shared/Shared.scss';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Header'
import { useDispatch } from "react-redux";
const style = {
    formLabel: {
        display: 'inline-block',
        width: '25%'
    },
    formInput: {
        display: 'inline-block',
        width: '75%'
    },
    formDate: {
        textAlign: 'center',
    }

}



const DeliveryTime = ({ disableDel, setShippingDate }) => {
    const history = useHistory();
    const today = new Date();
    let tomorrow = new Date();
    let nextday = new Date();
    tomorrow.setDate(today.getDate() + 1);
    nextday.setDate(today.getDate() + 2);
    const [visible, setVisible] = useState(false);
    const ref = useRef();
    const dateStore = sessionStorage.getItem('deliveryDate') || '';
    const timeStore = sessionStorage.getItem('deliveryShow') || 'Please select delivery time';
    const timeNowStore = sessionStorage.getItem('deliveryTime') || 'Please select delivery time';
    const [startDate, setStartDate] = useState(dateStore);
    const [timeStyle, setTimeStyle] = useState(timeStore);
    const [timeNow, setTimeNow] = useState(timeNowStore);
    const isMobile = localStorage.getItem("isMobile");
    let getBranch = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_opening: '08:00', branch_closing: '16:00' };
    getBranch.branch_opening = getBranch.branch_opening !== undefined ? getBranch.branch_opening : '08:00'
    getBranch.branch_closing = getBranch.branch_closing !== undefined ? getBranch.branch_closing : '16:00'
    let opening = (getBranch.branch_opening !== undefined ? getBranch.branch_opening : '08:00').split(':'), closing = (getBranch.branch_closing !== undefined ? getBranch.branch_closing : '16:00').split(':')
    opening = parseInt(opening[0])
    closing = parseInt(closing[0])
    const dispatch = useDispatch()
    // getting start and end time depending on branch opening and closing
    const [optionTime, setOptionTime] = useState([]);
    //checker if user is logged in
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    if (!getUser) {
        window.location.replace("/login#checkout")
    }
    let d = new Date(today);
    useEffect(() => {
        let i, start, last;
        const startTime = [];
        let schedules = new Object();
        if (opening % 2 === 0) {
            for (i = opening; i <= closing; i++) {
                last = closing.length;
                // console.log(last)
                if (i % 2 === 0) {
                    start = i;
                }
                // start = i;
                // console.log(start)
                startTime.push({
                    totalHrs: closing - opening,
                    startvalue: start,
                    endvalue: closing,
                    start: start,
                    end: start + 2
                })
            }
        }
        else {
            for (i = opening; i <= closing; i++) {
                last = closing.length;
                // console.log(last)
                if (i % 2 !== 0) {
                    start = i;
                }
                // start = i;
                // console.log(start)
                startTime.push({
                    totalHrs: closing - opening,
                    startvalue: start,
                    endvalue: closing,
                    start: start,
                    end: start + 2
                })
            }

        }
        schedules = Array.from(new Set(startTime.map(JSON.stringify))).map(JSON.parse);
        setOptionTime(schedules)
        // console.log(schedules)
        // tomCard


        // alert(d.getHours()>=closing)
        // if(d.getHours() >= closing){
        //         document.getElementById("tomCard").click();   
        // }
        if (disableDel.length > 0) {

            if (disableDel.includes("2d")) {
                document.getElementById("nextCard").click();
                document.getElementById("todayCard").classList.add("disabledCOD");
                document.getElementById("tomCard").classList.add("disabledCOD");
            }
            else if ((disableDel.includes("1d") && !disableDel.includes("2d")) || (d.getHours() >= closing && !disableDel.includes("2d"))) {
                document.getElementById("tomCard").click();
                document.getElementById("todayCard").classList.add("disabledCOD");
            }
        }
        else if (disableDel.length < 0) {
            document.getElementById("todayCard").click();
            document.getElementById("tomCard").classList.remove("disabledCOD");
            document.getElementById("nextCard").classList.remove("disabledCOD");
        }

    }, [" ", disableDel]);



    //getting format today date

    function formatDate(today) {
        let d = new Date(today),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    // split time + 12 hour format no additional +1
    function delNowTime(today) {
        let d = new Date(today);
        let H = d.getHours() + 1, m = d.getMinutes(), l = "";
        let time = "am"

        if (H == 12) {
            time = "pm"
            H = H;
        }
        if (H > 12) {
            time = "pm"
            H = H - 12;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (m < 10) {
            m = '0' + m;
        }

        return H + ':' + m + ' ' + l + time;
    }
    const timeFormatter = (time) => {

        let newTime;
        if (parseInt(time) > 12) {
            newTime = time - 12 + 'pm'
        }
        else if (parseInt(time) === 12) {
            newTime = time + 'pm'
        }
        else {
            newTime = time + 'am'
        }
        return newTime
    }
    const timeDB = (time) => {
        time = time.split(':')
        let newTime = (time[0] < 12 ? time[0] : (parseInt(time[0]) - 12 < 10 ? '0' + (parseInt(time[0]) - 12).toString() : parseInt(time[0]) - 12)) + ':' + time[1]
        newTime += (parseInt(time[0]) >= 12 ? ' PM' : ' AM')
        return newTime
    }
    // submitting del schedule (book)now
    const expressDel = () => {
        sessionStorage.setItem('deliveryTime', delNowTime(today));
        sessionStorage.setItem('deliveryDate', formatDate(today));
        setShippingDate(formatDate(today))
        setTimeStyle("Please select delivery time")
        setTimeNow(delNowTime(today));
        setTimeStyle(delNowTime(today))
        sessionStorage.setItem('deliveryShow', delNowTime(today));

        if (isMobile === "true") {
            history.push('/checkout-page');
        }
    };

    // renders today time
    const renderTimeToday = optionTime.map((time, index) => {
        return (
            <>

                {
                    (today.getHours() < time.startvalue) ?
                        <div
                            key={index}
                            className={`shadow-sm p-3 mb-2 bg-white rounded timeCard 
                            ${timeStyle === timeFormatter(time.end) ? 'activeDate' : ''} `}
                            id={`id_${time.start} `}
                            onClick={
                                date => {

                                    sessionStorage.setItem('deliveryTime', (time.start + ":00")
                                        + "-" + (time.end + ":00"));
                                    setTimeStyle(timeFormatter(time.end));
                                    setTimeNow(timeFormatter(time.end));
                                    sessionStorage.setItem('stationary', (time.start + ":00")
                                        + "-" + (time.end + ":00"));
                                    sessionStorage.setItem('deliveryShow', timeFormatter(time.start) + "-" + timeFormatter(time.end));
                                    if (isMobile === "true") {
                                        history.push('/checkout-page');
                                    }
                                }
                            } >
                            <small>
                                {timeFormatter(time.start) + "-" + timeFormatter(time.end)}
                            </small>
                        </div>
                        :
                        ""
                }
            </>
        );
    });

    //renders next day time
    const renderTimeNext = optionTime.map((time, index) => {
        return (
            <>
                {
                    closing >= time.end ?

                        <div
                            key={index}
                            className={`shadow-sm p-3 mb-2 bg-white rounded timeCard 
                            ${timeStyle === timeFormatter(time.end) ? 'activeDate' : ''} 
                            ${(startDate === formatDate(today)) && (today.getHours() >= time.end) ? 'd-none' : ''}
                            `}
                            id={`id_${time.start} `}
                            onClick={
                                date => {

                                    sessionStorage.setItem('deliveryTime', (time.start + ":00")
                                        + "-" + (time.end + ":00"));
                                    setTimeStyle(timeFormatter(time.end));
                                    setTimeNow(timeFormatter(time.end));
                                    sessionStorage.setItem('stationary', (time.start + ":00")
                                        + "-" + (time.end + ":00"));
                                    sessionStorage.setItem('deliveryShow', timeFormatter(time.start) + "-" + timeFormatter(time.end));
                                    if (isMobile === "true") {
                                        history.push('/checkout-page');
                                    }
                                }
                            } >
                            <small>
                                {timeFormatter(time.start) + "-" + timeFormatter(time.end)}
                            </small>
                        </div>
                        :
                        <div
                            key={index}
                            className={`shadow-sm p-3 mb-2 bg-white rounded timeCard ${timeStyle === timeFormatter(time.endvalue) ? 'activeDate' : ''}
                            ${time.totalHrs % 2 === 0 ? 'd-none' : ''} `}
                            id={`id_${time.start} `}
                            onClick={
                                date => {

                                    sessionStorage.setItem('deliveryTime', (time.startvalue + ":00")
                                        + "-" + (time.endvalue + ":00"));
                                    setTimeStyle(timeFormatter(time.endvalue));
                                    setTimeNow(timeFormatter(time.endvalue));
                                    sessionStorage.setItem('stationary', (time.startvalue + ":00")
                                        + "-" + (time.endvalue + ":00"));
                                    sessionStorage.setItem('deliveryShow', timeFormatter(time.startvalue) + "-" + timeFormatter(time.endvalue));
                                    if (isMobile === "true") {
                                        history.push('/checkout-page');
                                    }
                                }
                            } >
                            <small>
                                {timeFormatter(time.startvalue) + "-" + timeFormatter(time.endvalue)}

                            </small>
                        </div>
                }
            </>
        );
    });


    return (

        <React.Fragment>
            {isMobile === "true" ?
                <>
                    {/* <Header title="Delivery Schedule" /> */}

                    <form className="mt-3 mb-3" >
                        <div className="mt-3 mb-3">
                            <div className="form-group row mb-2 p-2 pt-0 pb-0" id="DeliveryDate_field ">
                                <div id="todayCard" className={`card col dateCard 
                                ${startDate === formatDate(today) && startDate !== "2023-01-01" && closing > today.getHours() ? 'activeDate' : ''} ${closing > today.getHours() ? '' : 'disabledCOD'} 
                                ${formatDate(today) === "2023-01-01" ? 'disabledCOD' : ''} 
                                ${formatDate(today) === "2022-12-25" ? 'disabledCOD' : ''}
                                `}
                                    onClick={
                                        date => {
                                            setStartDate(formatDate(today))
                                            sessionStorage.setItem('deliveryDate', formatDate(today));
                                            setShippingDate(formatDate(today))
                                            sessionStorage.setItem('deliveryTime', "");
                                            sessionStorage.setItem('deliveryShow', "");
                                            setTimeStyle("Please select delivery time");
                                        }
                                    }>
                                    {today.toLocaleString('default', { month: 'short' })}. {today.getDate()}
                                    <br />
                                    {today.toLocaleDateString('default', { weekday: 'short' })}
                                </div>

                                <div id="tomCard" className={`card col dateCard ${startDate === formatDate(tomorrow) ? 'activeDate' : ''} 
                                ${formatDate(tomorrow) === "2023-01-01" ? 'disabledCOD' : ''}
                                ${formatDate(tomorrow) === "2022-12-25" ? 'disabledCOD' : ''}
                                `}
                                    onClick={
                                        date => {
                                            setStartDate(formatDate(tomorrow))
                                            sessionStorage.setItem('deliveryDate', formatDate(tomorrow)); sessionStorage.setItem('deliveryTime', "");
                                            setShippingDate(formatDate(tomorrow))
                                            sessionStorage.setItem('deliveryShow', "");
                                            setTimeStyle("Please select delivery time");
                                        }}

                                >
                                    {tomorrow.toLocaleString('default', { month: 'short' })}. {tomorrow.getDate()}
                                    <br />
                                    {tomorrow.toLocaleDateString('default', { weekday: 'short' })}
                                </div>
                                <div id="nextCard" className={`card col dateCard 
                                ${startDate === formatDate(nextday) ? 'activeDate' : ''} 
                                ${formatDate(nextday) === "2023-01-01" ? 'disabledCOD' : ''} 
                                ${formatDate(nextday) === "2022-12-25" ? 'disabledCOD' : ''}
                                `}
                                    onClick={
                                        date => {
                                            setStartDate(formatDate(nextday))
                                            sessionStorage.setItem('deliveryDate', formatDate(nextday)); sessionStorage.setItem('deliveryTime', "");
                                            setShippingDate(formatDate(nextday))
                                            sessionStorage.setItem('deliveryShow', "");
                                            setTimeStyle("Please select delivery time");

                                        }} >
                                    {nextday.toLocaleString('default', { month: 'short' })}. {nextday.getDate()}
                                    <br />
                                    {nextday.toLocaleDateString('default', { weekday: 'short' })}
                                </div>
                            </div>

                            <div className="form-group row" id="DeliveryTime_field">
                                <div className="col-xs-12 col-sm-12 col-lg-12">
                                    {startDate === formatDate(today) && closing >= today.getHours() && opening <= today.getHours() ?
                                        <>
                                            <br />

                                            <b className={`${closing <= parseInt(today.getHours()) + 1 ? "d-none" : ""}`}>Express Delivery</b>
                                            <div className={`shadow-sm p-3 mb-2 d-block bg-white rounded timeCard ${timeStyle === delNowTime(today) ? 'activeDate' : ''} ${formatDate(nextday) === "2022-01-01" ? 'disabledCOD' : ''} ${closing <= parseInt(today.getHours()) + 1 ? "d-none" : ""}`} onClick={expressDel} >
                                                <b><small>
                                                    {delNowTime(today)}
                                                </small></b>
                                            </div>
                                        </>
                                        : ""}
                                    <br />
                                    {dateStore !== "" ?
                                        startDate === formatDate(today) ?
                                            <div>
                                                <b>Scheduled </b>
                                                <i><small>(Pick a delivery slot)</small></i>
                                                <br />
                                                {renderTimeNext}
                                            </div>
                                            :
                                            <div>
                                                <b>Scheduled </b>
                                                <i><small>(Pick a delivery slot)</small></i>
                                                <br />
                                                {renderTimeNext}
                                            </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </>
                :
                <>
                    {/* desktop */}
                    <form id="timeSched" >
                        <div className="">
                            <div className="form-group row mb-2 p-2 pt-0 pb-0" id="DeliveryDate_field ">
                                <div id="todayCard" className={`card col dateCard m-1 ${startDate === formatDate(today) && startDate !== "2023-01-01" && closing > today.getHours() ? 'activeDate' : ''} ${closing <= today.getHours() ? 'disabledCOD' : ''}  
                                ${formatDate(today) === "2023-01-01" ? 'disabledCOD' : ''}
                                ${formatDate(today) === "2022-12-25" ? 'disabledCOD' : ''}
                                `}
                                    onClick={
                                        date => {
                                            setStartDate(formatDate(today))
                                            sessionStorage.setItem('deliveryDate', formatDate(today));
                                            sessionStorage.setItem('deliveryTime', "");
                                            sessionStorage.setItem('deliveryShow', "");
                                            setShippingDate(formatDate(today))
                                            setTimeNow(timeFormatter(""));
                                            setTimeStyle("Please select delivery time");
                                        }
                                    }>
                                    {today.toLocaleString('default', { month: 'short' })}. {today.getDate()}
                                    <br />
                                    {today.toLocaleDateString('default', { weekday: 'short' })}
                                </div>

                                <div id="tomCard" className={`card col dateCard m-1 ${startDate === formatDate(tomorrow) ? 'activeDate' : ''} 
                                ${formatDate(tomorrow) === "2023-01-01" ? 'disabledCOD' : ''} 
                                ${formatDate(tomorrow) === "2022-12-25" ? 'disabledCOD' : ''}
                                `}
                                    onClick={
                                        date => {
                                            setStartDate(formatDate(tomorrow))
                                            sessionStorage.setItem('deliveryDate', formatDate(tomorrow)); sessionStorage.setItem('deliveryTime', "");
                                            sessionStorage.setItem('deliveryShow', "");
                                            setShippingDate(formatDate(tomorrow))
                                            setTimeNow(timeFormatter(""));

                                            setTimeStyle("Please select delivery time");
                                        }}

                                >
                                    {tomorrow.toLocaleString('default', { month: 'short' })}. {tomorrow.getDate()}
                                    <br />
                                    {tomorrow.toLocaleDateString('default', { weekday: 'short' })}
                                </div>
                                <div id="nextCard" className={`card col dateCard m-1 ${startDate === formatDate(nextday) ? 'activeDate' : ''} 
                                ${formatDate(nextday) === "2023-01-01" ? 'disabledCOD' : ''} 
                                ${formatDate(nextday) === "2022-12-25" ? 'disabledCOD' : ''}
                                `}
                                    onClick={
                                        date => {
                                            setStartDate(formatDate(nextday))
                                            sessionStorage.setItem('deliveryDate', formatDate(nextday)); sessionStorage.setItem('deliveryTime', "");
                                            sessionStorage.setItem('deliveryShow', "");
                                            setShippingDate(formatDate(nextday))
                                            setTimeNow(timeFormatter(""));

                                            setTimeStyle("Please select delivery time");

                                        }} >
                                    {nextday.toLocaleString('default', { month: 'short' })}. {nextday.getDate()}
                                    <br />
                                    {nextday.toLocaleDateString('default', { weekday: 'short' })}
                                </div>
                            </div>

                            <div className="form-group row" id="DeliveryTime_field">
                                <div className="col-xs-12 col-sm-12 col-lg-12">

                                    {startDate === formatDate(today) && opening <= today.getHours() ?
                                        <>
                                            <br />
                                            <b className={`${closing <= parseInt(today.getHours()) + 1 ? "d-none" : ""}`}>Express Delivery</b>
                                            <div
                                                className={`shadow-sm p-3 d-block mb-2 bg-white rounded timeCard ${timeNow === delNowTime(today) ? 'activeDate' : ''} ${formatDate(nextday) === "2022-01-01" ? 'disabledCOD' : ''} ${closing <= parseInt(today.getHours()) + 1 ? "d-none" : ""}`}
                                                onClick={expressDel} >
                                                <b><small>
                                                    {delNowTime(today)}
                                                </small></b>
                                            </div>
                                        </>
                                        : ""}

                                    <br />
                                    {dateStore !== "" ?
                                        startDate === formatDate(today) ?

                                            <div>
                                                <b>Scheduled </b>
                                                <i><small>(Pick a delivery slot)</small></i>
                                                <br />
                                                {renderTimeNext}
                                            </div>
                                            :
                                            <div>

                                                <b>Scheduled </b>
                                                <i><small>(Pick a delivery slot)</small></i>
                                                <br />
                                                {renderTimeNext}
                                            </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </>
            }
        </React.Fragment>
    );
}


export default (DeliveryTime);



