// export videos

export const zgnVideos = [
    {
        name: "Beefy Fried Rice",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654588160/recipe/Beefy_Fried_Rice_gcdzfs.mp4",
    },
    {
        name: "Pork Chow Fan",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654588126/recipe/Pork_Chow_Fan_plkaof.mp4",
    },
    {
        name: "Vegetable Fried Rice",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654588136/recipe/Vegetable_Fried_Rice_xtloya.mp4",
    },
    {
        name: "Green Smoothie",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654588070/recipe/2_Pineapple_Smoothie_recipe_axxayq.mp4",
    },
    {
        name: "Fruit and Veggie",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654588070/recipe/2_Pineapple_Smoothie_recipe_axxayq.mp4",
    },
    {
        name: "Melon Smoothie",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654588066/recipe/3_Melon_Smoothie_pkwpzx.mp4",
    },
    {
        name: "Milky Banana",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654587988/recipe/2_Banana_Smoothie_Recipes_wcgpiu.mp4",
    },
    {
        name: "Oh My Banana",
        url: "http://res.cloudinary.com/dw5axbz64/video/upload/v1654587988/recipe/2_Banana_Smoothie_Recipes_wcgpiu.mp4",
    }
]