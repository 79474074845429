/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : Address
 * File Directory           : src/components/Shared/Address
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : resuable
 * Used In					        : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Address        -   component for address
 *
 * #How to call:
 *   </Address>
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import Home from "../../APIs/Home";

const style = {
    colorPrimary: {
        color: '#24b14b'
    },
    box: {
        boxShadow: 'none',
        borderColor: 'none',
        backgroundColor: '#fff',
    },
    link: {
        textDecoration: 'unset',
    },
    inputIcon: {
        borderRadius: '5px 0px 0px 5px',
        height: '100%'
    }
}

const Address = () => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const [address, setAddress] = useState('')
    const [addressLength, setAddressLength] = useState(0)
    const [selected, setSelected] = useState({})
    const selectedCity = JSON.parse(localStorage.getItem('SelectedCity'))
    if (!getUser) {
        window.location.replace("/login#checkout")
    }


    useEffect(() => {
        const source = axios.CancelToken.source()
        const customerID = sessionStorage.getItem('Login') || '{}'
        const parseCustomerID = JSON.parse(customerID) || '{}'
        const details = { user_id: parseCustomerID.customer_id, city_id : selectedCity.city_id }
        let mounted = true
        
        const GetAddress = async () => {
            try {
                
                const response = await Home.post(`/address`, details)
                if (mounted) {
                    setAddressLength(response.data.results.length)
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                } else {
                    console.log(err)
                }

            }
        }
        GetAddress()

        return function cleanup() {
            source.cancel()
            mounted = false
        }

    }, [])

    useEffect(() => {
        const source = axios.CancelToken.source()
        const customerID = sessionStorage.getItem('Login') || '{}'
        const parseCustomerID = JSON.parse(customerID) || '{}'
        const details = { user_id: parseCustomerID.customer_id, city_id: selectedCity.city_id}
        let mounted = true
        const fetchAddress = async () => {
            try {
                const response = await Home.post(`/getAddress`, details)
                const address = {
                    address: `${response.data.result.add_apartment} ${response.data.result.add_street}, ${response.data.result.brgy_name}, ${response.data.result.city_name}, ${response.data.result.address_zip}`
                }
                const values = {
                    address_id: response.data.result.add_id,
                    address_street: response.data.result.add_apartment + " " + response.data.result.add_street,
                    brgy_name: response.data.result.brgy_name,
                    city_name: response.data.result.city_name,
                    zip_code: response.data.result.address_zip,
                    address_landmark: '',
                    address_apartment: response.data.result.add_apartment,
                    receiver_name: response.data.result.add_fn,
                    receiver_contactNo: response.data.result.add_contact
                }
                if (mounted) {
                    setSelected(values)
                    setAddress(address.address)
                }
            } catch (err) {
                if (axios.isCancel(err)) {
                } else {
                    console.log(err)
                }
            }
        }
        fetchAddress()
        sessionStorage.setItem('Address', JSON.stringify(selected))

        return function cleanup() {
            source.cancel()
            mounted = false
        }
    }, [address])



    return (
        <React.Fragment>
            {isMobile ?
                <div className="input-group mb-3 mt-2 address_page">
                    <Link
                        to={addressLength > 0 ? '/address/change' : '/address/add'}
                        className="input-group"
                        style={style.link}
                    >
                        <div className="input-group-prepend">
                            <span className="input-group-text" style={style.inputIcon}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                    fill="currentColor"
                                    className="bi bi-geo-alt-fill"
                                    viewBox="0 0 16 16"
                                    style={style.colorPrimary}
                                >
                                    <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                                </svg>
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Please select address"
                            value={address}
                            readOnly={true}
                            style={style.box}
                        />

                    </Link>
                </div>
                :
                null
            }
        </React.Fragment>
    );
};


export default Address;