import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import ZaganaLogo from '../../Shared/ZaganaLogo';
import Header from "../../Shared/Header";
import Xendit from "../Xendit";
import { useHistory } from "react-router-dom";
import BPI from "../../../assets/site_icons/bpi-icon.svg"

const styles = {
    container: {
        height: "100vh",
        padding: "3rem"
    },
    headerBG: {
        'backgroundColor': '#facc02'
    },
    img: {
        width: '250px',
        margin: '0 auto',
        display: 'block'
    }
}

const OTPXendit = ({ createPayment_id, customer_id, paymentSelected, order_id, paymentDD }) => {
    const isMobile = localStorage.getItem("isMobile") === "true"
    const dispatch = useDispatch();
    let total = JSON.parse(sessionStorage.getItem('orderTotal'))
    const history = useHistory()
    let loc = window.location.origin;

    const otpSubmit = () => {
        const otp = document.getElementById('otpCode').value;

        document.getElementById("btnRegister").disabled = true;
        document.getElementById("btnRegister").innerText = 'Loading ...';
        Xendit.post(`/validatePayment`, { otp: otp, paymentDD: paymentDD })
            .then(response => {
                if (response.data.status === "COMPLETED") {
                    window.location.href = loc + "/thank-you-xendit"
                }
                else if (response.data.status === 400) {
                    alert(response.data.message)
                    document.getElementById("btnRegister").disabled = false;
                    document.getElementById("btnRegister").innerText = 'Enter OTP';
                }
                else if (response.data.status === 409) {
                    alert(response.data.message)
                    window.location.href = loc + "/checkout-details"
                }
                console.log(response)
            })
            .catch(error => {
                console.log(error)
            })
    }
    return (
        <React.Fragment>
            <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                <div className="row">
                    <div className="col-2 d-none d-sm-block text-center">
                        <a href="/">
                            <ZaganaLogo />
                        </a>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row mb-5'>
                    <div className={`card mt-5 mb-5 p-0 ${isMobile ? 'w-100 mb-5 mt-5' : 'w-25 '}`} style={{ "margin": "0 auto", "display": "block", "minHeight": "250px" }}>
                        <div className="card-body">
                            <img src={BPI} style={styles.img} />
                            <br />
                            <h5 className="card-text mt-2">Order Number: {order_id.order_id} </h5>
                            <input type="text" id="otpCode" className='form form-control' placeholder="OTP" />
                            <i>Please enter OTP</i>
                            <button className='btn btn-success mt-5'
                                onClick={otpSubmit}
                                id="btnRegister"
                            >
                                Enter OTP
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = (state) => {
    return ({
        createPayment_id: state.XenditCreatePayment,
        paymentSelected: state.payment,
        customer_id: state.XenditData,
        order_id: state.OrderID,
        paymentDD: state.XenditValidateOTP,

    })
}
export default connect(mapStateToProps)(OTPXendit);