/*******************************************************************************************
* File Name                : Carousel
* File Directory           : src/components/Shared/Carousel
* Module                   : Carousel
* Author                   : Ava
* Version                  : 1
* Purpose                  : Reusable
* Used In				   : src/components/Shared/Carousel.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   Carousel        -   component for banners that is displayed in the application
 *
 * #How to call:
 *    <Carousel/>
 *
 *******************************************************************************************/

 import React from "react";
 import Carousel from 'react-bootstrap/Carousel';
 import { trackWindowScroll } from "react-lazy-load-image-component";
 
 const style = {
     pointer : {
         cursor : "pointer"
     } 
 }
 export default function App({ imgSlide }) {
     
 
     const bannerSlide = imgSlide.map((banner, index) => {
         const onClickBanner = () => {
             const path = new URL(banner.link)
             if (path !== '') {
                 window.location.pathname = path.pathname
             }
         }
         return (
             <Carousel.Item onClick={onClickBanner} style={style.pointer} key={index} interval={3000}>
                 <img
                     className="d-block w-100"
                     src={banner.banner_path}
                     alt={banner.banner_name}
                 />
             </Carousel.Item>
 
         )
     })
 
 
     return (
         <React.Fragment>
             <div className="w-100 m-0 p-0 mb-0 mt-5rem m-csf">
                 {/* {imgSlide} */}
                 <Carousel>
                     {bannerSlide}
                 </Carousel>
             </div>
         </React.Fragment>
     );
 }