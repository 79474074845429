import moment from "moment";

export const SaveToSession = (params) => {
    const { page, path } = params
    const dateNow = moment().format('YYYY-MM-DD')
    const dateFromSession = sessionStorage.getItem('date')
    if (dateFromSession !== dateNow) {
        if (path.includes('fbclid')) {
            sessionStorage.setItem('source', 'FB')
            sessionStorage.setItem('date', dateNow)
            sessionStorage.setItem('current_page', page)
        } else if (path.includes('BillEase')) {
            sessionStorage.setItem('source', 'BillEase')
            sessionStorage.setItem('date', dateNow)
            sessionStorage.setItem('current_page', page)
        } else if (path.includes('tiktok')) {
            sessionStorage.setItem('source', 'Tiktok')
            sessionStorage.setItem('date', dateNow)
            sessionStorage.setItem('current_page', page)
        }
    } else {
        sessionStorage.setItem('current_page', page)
    }

};
