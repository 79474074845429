
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import CookieSettings from "../Shared/CookieSettings";
import { connect } from "react-redux";
import { storeFrontLogs } from "../APIs/Logs";

const style = {
    close: {
        backgroundColor: 'unset',
        border: '2px solid #0d6efd',
        borderRadius: '15px',
        marginLeft: '1rem',
        padding: '5px 10px',
        color: 'black',
        bottom: '3rem',
        position: 'absolute',
    },
    border: {
        border: 'unset'
    }
}
const CookieModal = (showModal) => {
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        if (gen_id) {
            user = gen_id
        } else if (isLogged) {
            userLogin = JSON.parse(sessionStorage.getItem('Login'))
            gen_id = localStorage.getItem('gen_id')
            user = userLogin.customer_name + ` (${gen_id})`
        }
        const payload = {
            user: user,
            changes: `View cookie settings`,
            type: 'storefront',
            date: `NOW()`
        }
        storeFrontLogs(payload)
        setShow(true)
    };
    useEffect(() => {
        if (!showModal.showModal) {
            setShow(false)
        }
    }, [showModal])
    return (
        <>
            {(window.location.pathname).includes("my-account") ?
                <div className="w-100" style={{"textAlign" : "right"}}>
                    <Button variant="default"  onClick={handleShow}>
                        Change Cookie Settings
                    </Button>
                </div>
                :
                <Button variant="primary" onClick={handleShow}>
                    No, show cookie settings
                </Button>
            }
            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header style={style.border} closeButton />
                <Modal.Body>
                    <CookieSettings />
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                        style={style.close}
                    >
                        Close
                    </Button>
                </Modal.Body>
                <Modal.Footer style={style.border} />
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal
    }
}
export default connect(mapStateToProps)(CookieModal);