import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';

const style = {
    border: {
        border: 'unset',
        paddingBottom: 'unset'

    },
    body: {
        padding: '3.5rem',
        paddingTop: 'unset',
        paddingBottom: '2rem'
    }
}

const renderInput = ({ input, type, meta, value_name, class_name, value_check, maxLength, id, pattern }) => {

    const className = `field ${meta.error && meta.touched ? 'error' : ''} ${!meta.error ? 'valid' : ''}`;
    return (
        <div className={className}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">(+63)</span>
                </div>
                <input {...input}
                    type={type}
                    value={value_name}
                    id={id}
                    pattern={pattern}
                    autoComplete="off"
                    className={class_name}
                    checked={value_check}
                    maxLength={maxLength}
                />
            </div>
            {/* <div className="form-control-feedback">{renderError(meta)}</div> */}
        </div>
    );
};

const ModalOTP = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (e) =>{
        e.preventDefault()
        handleClose(false)
    }

    return (
        <React.Fragment>
            <div className="row" onClick={handleShow} >
                <button>weew</button>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={style.border} closeButton>
                </Modal.Header>
                <Modal.Body style={style.body}>
                    <form onSubmit={handleSubmit} className="p-2" id="regForm">
                        <div className="form-group row" id="Phone_field">
                            <div className="col-12 p-2 my-auto text-center">
                                <h5>
                                    Phone Registration
                                </h5>
                            </div>
                            <div className="col-12">
                                <Field
                                    type="number"
                                    name="Phone"
                                    maxLength="15"
                                    component={renderInput}
                                    class_name="form-control"
                                    id="phone_fieldd"
                                    pattern="[0-9]"
                                />
                            </div>
                            <div className="col d-flex pt-3 align-items-center justify-content-center">
                                <button type="submit" className="btn btn-primary w-50" id="btnRegister">Submit</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    );

}

export default reduxForm({
    form: 'phone_registration'
})(ModalOTP);