//  * File Name                : Featured
//  * File Directory           : src/components/Misc/RecipePage/Components/Featured.js
//  * Module                   : Featured
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/Recipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <Featured/>
 *
 *******************************************************************************************/

// import React library
import React from "react";
// import images
import { zgnImages } from "../RecipeImages";
// import propTypes
import PropTypes from "prop-types";

// export featured component
const Featured = ({recipeFeatured}) => {
    // is Mobile
    const isMobile = localStorage.getItem("isMobile") === "true"
    // current location
    const currentLocation = window.location.href
    // filter images where image recipe name equals to featured recipe name 

    
    const featuredBanner = zgnImages.filter(el => el.recipeName === recipeFeatured[0].recipeName)
    // declare image variable
    let featuredBannerImage = isMobile ? featuredBanner[0].image[0] : featuredBanner[0].banner[0]
    // declare name variable
    let featuredName = recipeFeatured[0].recipeName
    // declare description variable
    let featuredDescription = recipeFeatured[0].recipeDescription
    return(
        <div className="featured-banner p-0">
            <div className="row featured-description">
                <div className="col-sm p-0">
                    <div className="row">
                        <h2 className="featured-header">Featured Recipe:</h2>
                    </div>
                    <div className="row">
                        <h1 className="featured-title">{featuredName}</h1>
                    </div>
                    <div className="row">
                        <p className="featured-desc">{featuredDescription}</p>
                    </div>
                    <div className="row">
                        <a href={`/ContentPage/recipe/${featuredName}`} className="centeredText view-recipe">
                            View Recipe
                        </a>
                    </div>
                    <div className="row carousel-icons">
                        <div className="row share-on">
                            <p>
                                Share on:
                            </p> 
                        </div>
                        <div className="row icons">
                            <div className="col-sm leftText icon-flex">
                                <div class="fb-share-button" data-href={currentLocation} data-layout="button" data-size="large">
                                    <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${currentLocation}src=sdkpreparse`} class="fb-xfbml-parse-ignore">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            <img
                className="d-block w-100"
                src={featuredBannerImage}
                alt={featuredName}
            />
            <div className="featured-overlay"></div>
        </div>
    )
}

// propType
Featured.propTypes = {
    recipeFeatured: PropTypes.array
};
Featured.defaultProps = {
    recipeFeatured: []
};

export default Featured