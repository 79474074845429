//  * File Name                : Recipe
//  * File Directory           : src/components/Misc/RecipePage/Recipe
//  * Module                   : Recipe
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/App.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <Recipe/>
 *
 *******************************************************************************************/



// import react library
import React from "react";
// import list of recipe
import { recipeList } from "./recipeList";
// import css
import "./RecipePage.css"
// import Featured Component
import Featured from "./Components/Featured";
// import Collection Component
import Collection from "./Components/Collection";
// import Header
import RecipeHeader from "./Components/Header";
// Import Floating recipe Component
import FLoatingRecipe from "./Components/FloatingRecipe";
// import Kitchen Tips Components
import KitchenTipsComponent from "./Components/Kitchen";


// export Main Recipe Page
const Recipe = () => {
    // filter recipe list if featured is true
    const featuredRecipe = recipeList.filter( el => el.featured === true)
    return (
        <div>
            <RecipeHeader />
            <div className="container p-0">
                <div className="row">
                    <Featured recipeFeatured={featuredRecipe} />
                </div> 
                <div className="row">
                    <Collection />
                </div>
                <div className="row">
                    <FLoatingRecipe />
                </div>
                <div className="row">
                    <KitchenTipsComponent />
                </div>
            </div>
        </div>
        
    )
}

export default Recipe