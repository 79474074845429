/*******************************************************************************************
* File Name                : Sidebar
* File Directory           : src/components/Shared/Sidebar
* Module                   : Sidebar
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Sidebar.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   SearchFloat        -   component for user to search and redirect to productpage
 *
 * #How to call:
 *    <SearchFloat/>
 *
 * Note: Still working on the ff:
 * - Ability to show user details
 * - about , faqs and blog links is here 
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import ItemList from "../Shared/ItemList";
import Home_api from '../APIs/Home';
import { v4 as uuidv4 } from 'uuid';

let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const SearchFloat = () => {
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [viewing, setViewing] = useState('grid')
    const [cartVDetails, setCartVDetails] = useState(defaultItem);
    const [cartVChange, setCartVChange] = useState(0);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [term, setTerm] = useState('');// search for desktop
    const [selectedCity, setSelectedCity] = useState({});// cart for desktop
    const [cart, setCart] = useState([]);// cart for desktop
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [branch, setBranch] = useState(null);
    const selectedBranch = 3;
    const [inCartQty, setinCartQty] = useState(0);
    const [cartProduct, setCartProduct] = useState([]); // show modal cart
    const [MinusToCartVisible, setMinusToCartVisible] = useState(inCartQty > 0 ? true : false);
    const [visibleCart, setVisibleCart] = useState(true); // show modal cart
    let accessKey = localStorage.getItem('accessKey')
    let body = document.body; // for setting body height (modal)
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal 
    useEffect(() => {
        
        //dbStorage
        // function uuidv4() {
        //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //         return v.toString(16);
        //     });
        // }
        let link = uuidv4();
        const accessStorage = async (status) => {
            await Home_api.post('/accessStorage', { accessToken: link })
            localStorage.setItem('accessKey', link)
            if (status === 'tokenNotFound') {
                window.location.reload()
            }
        }
        if (!accessKey) {

            if (localStorage.getItem('branchSelected') === true) {
                accessStorage('tokenNotFound')
            } else {
                setBranch([{ city_id: 11, branch_id: 3, city_name: 'Quezon City (QC)', branch_closing: '17:00', branch_opening: '9:00' }])
                accessStorage()
            }
        } 


    }, [])
    return (
        <React.Fragment>
            <div>Hello</div>
            <div id="searchC">
                <ItemList options={[{ category: "all" }]} search="apple" total={total} selectedCity={selectedCity} setTotal={setTotal} branch={branch} onCartLoaded={onCartLoaded} cart={cart} setCart={setCart} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing} setViewing={setViewing} />
            </div>
        </React.Fragment>
    )
}


export default SearchFloat;