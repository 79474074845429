const AddressInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INITIAL_ADDRESS':
            return {
                ...state,
                fullName: action.fullName,
                houseNumber: action.houseNumber,
                street: action.street,
                postalCode: action.postalCode,
                brgy_id: action.brgy_id,
                brgy_name: action.brgy_name,
                city_id: action.citybrgy_id,
                city_name: action.city_name,
                Phone: action.Phone,
                add_id: action.add_id

            };
        default:
            return state;
    }

};

export default AddressInfoReducer;