import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import ProductCarousel from './ProductCarousel';
import CartButtons from './CartButtons';
import NotifyMeButtons from './NotifyMeButtons';
const style = {
    modalHeader: {
        borderBottom: "unset"
    },
    fontSize12: {
        fontSize: "16px"
    },
    fontSize10: {
        fontSize: "14px"
    },
}

const MyVerticallyCenteredModal = (props) => {
    const { show, data, onHide } = props
    let saleTag = Math.round(((data.item_price - data.item_salePrice) / data.item_price) * 100); // for sale tag percent, for home page and modal
    let defaultImg = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
    if (data.image_list) {
        data.image_list = data.image_list[0] === null || data.image_list[0] === "" ? [defaultImg] : data.image_list
    }
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <Modal.Header style={style.modalHeader} closeButton />
            <Modal.Body>
                <div className='row'>
                    <div className={`ribbon-wrapper ${data.item_salePrice < data.item_price ? '' : 'd-none'}`}>
                        <div className="ribbon">
                            {Math.round(saleTag)}%
                        </div>
                    </div>
                    {(data.category)?.includes("new") ?
                        <div className="ribbon ribbonNew">
                            &nbsp;&nbsp;new
                        </div>
                        :
                        null
                    }
                    {(data.category)?.includes("seasonal") ?
                        <div className="ribbon ribbonSeasonal">
                            &nbsp;Seasonal
                        </div>
                        :
                        null
                    }
                    <div className='col-12 sub-listing-modal'>
                        <ProductCarousel images={data.image_list} />
                    </div>
                    <div className='col-12 p-0'>
                        <h5 className='p-3 pt-3 pb-2'>{data.item_name}</h5>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='p-3 pt-0 pb-0'>
                                    {data.item_salePrice < data.item_price ?
                                        <React.Fragment>
                                            <b>
                                                <span
                                                    style={style.fontSize12}
                                                    className="text-danger"
                                                >
                                                    ₱{data.item_salePrice?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </span>
                                            </b>
                                            <label
                                                style={style.fontSize10}
                                                className="w-100 text-muted"
                                            >
                                                <del>
                                                    ₱{data.item_price?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </del>
                                            </label>
                                        </React.Fragment>
                                        :
                                        <b>
                                            <span
                                                className="w-100"
                                                style={style.fontSize12}
                                            >
                                                ₱{data.item_price?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </span>
                                        </b>
                                    }
                                </div>
                            </div>
                            <div className='col-6 text-center my-auto'>
                                {
                                    data.item_status === "enabled" ?
                                        <CartButtons item={data} />
                                        :
                                        <NotifyMeButtons item={data} />
                                }
                            </div>
                        </div>
                        <hr />
                        <pre className='p-3 pt-0 pb-0 item-description'>
                            {data.item_description}
                        </pre>
                        <hr />
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );
}

const SubListingModal = ({ show, setShow, data }) => {

    return (
        <>
            <MyVerticallyCenteredModal
                show={show}
                onHide={() => setShow(false)}
                data={data}
            />
        </>
    );
}

export default SubListingModal;