
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : productSpecial
 * File Directory           : src/components/Products/productSpecial
 * Module                   : product page component
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To view the Frequently Asked Questions with Zagana
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   productSpecial        -   component for productSpecial
 *
 * #How to call:
 *    <productSpecial />
 *
 *******************************************************************************************/


 import React, { useState, useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SpecificItemList from "./SpecificItemList";
 
 const productSpecial = ({ product, term, branch, total, selectedCity, prodData }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";

    const renderProdBullets = prodData.map(blts => {
        const bullets1 = blts.bullets[0]
        const bullets2 = blts.bullets[1]
        return (
            <>
            <div className="col-lg-3 col-sm-12 p-0 m-0 prodBenefits">
                <div className="w-100 r5-mv">
                    <img className="br-5 auto-mh w-100" src={bullets1.image} alt="benefits img" />   
                </div>
            </div>
            <div className="col-lg-3 col-sm-12  p-0 m-0">
                <h1 className="pv-h1 mt2r listT"><b>{bullets1.title}:</b></h1>
                <ul className="lh-30">
                    {bullets1.items.map(item => <li>{item}</li>)}
                </ul>
            </div>
            
            <div className="col-lg-3 col-sm-12  p-0 m-0 prodSpecs">
                        <div className="w-100 r5-mv">
                            <img className="br-5 auto-mh w-100" src={bullets2.image} alt="spec & packaging img" />   
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 p-0 m-0">
                        <h1 className="pv-h1 mt2r listT"><b>{bullets2.title}:</b></h1>
                        <ul className="lh-30">
                            {bullets2.items.map(item => <li>{item}</li>)}
                        </ul>
                    </div>
            </>
        )
    })

     return (
         <React.Fragment>
            <div className="productSpecial">  
                <div className="row mtb-1rem prodBullets">
                    {renderProdBullets}
                </div>

                <hr/>

                <div className="prodBranches r5-mv">
                    <h1> <img className="prodViewIcon" src="https://res.cloudinary.com/dw5axbz64/image/upload/v1662958621/icons/Location_Marker_t4odxe.svg" alt="location" /> <b>Serviceable areas in Metro Manila:</b> </h1>
                    <p>Las Pinas City, Makati City, Mandaluyong City, Manila City, Marikina City, Muntinlupa City, Paranaque City, Pasay City, Pasig City, Quezon City, San Juan City, and Taguig City</p>
                    <h1> <img className="prodViewIcon" src="https://res.cloudinary.com/dw5axbz64/image/upload/v1662958621/icons/Shopping_Cart_ycogfw.svg" alt="cart" /> <b>Orders can be placed and are accepted 24/7</b></h1>
                    <h1> <img className="prodViewIcon" src="https://res.cloudinary.com/dw5axbz64/image/upload/v1662958621/icons/Time_oj6yl9.svg" alt="time" /> <b>Delivery cut off is until 5PM on a daily basis</b></h1>
                </div>

                <hr/>

                <div className="productRelated mt2r">
                    <h1 className="hdrh1 mt2r">{prodData[0].relatedIngredientsTitle} Ingredients</h1>
                    <div id="relatedProdIngr" className="relatedProdIngr">
                        <SpecificItemList
                            options={[{
                                category_tag: "relatedingredients",
                                category_name: "Related Ingredients"
                            }]}
                            specificList={prodData[0].relatedIngredients
                                || []} // sample for local
                            // specificList={["ZGN00001698","ZGN00001339-2","ZGN00000818-2","ZGN00001734","ZGN00000800-2","ZGN00000429","ZGN00004091-2","ZGN00000817-2"] || []} // original sinigang list
                            search={term}
                            total={total}
                            selectedCity={selectedCity}
                            branch={branch}

                        />
                    </div>
                </div>

                <div className="mt2r">
                    <p>Do you have any other questions or comments? Please send a message to <b><a href="https://www.messenger.com/t/114753366567227">@zagana.com.ph</a> on Facebook Messenger. </b></p>
                </div>

                <div className="prodReviews">
                    <div>
                        <h1 className="hdrh1">Reviews!</h1>
                    </div> 
                    <>
                        {
                            prodData.map(data => {
                                const imgs = data.reviewsImgs[0]
                                return(
                                    <div className="w-100 r5-mv">
                                        {   isMobile ? 
                                            imgs.mobile.map(img => <img className="auto-mh br-5 w-100 mb-2" src={img} alt="product reviews" /> )
                                        :
                                            <img className="auto-mh br-5 w-100" src={imgs.desktop} alt="product reviews" />
                                        } 
                                    </div>
                                )
                            })
                        }
                    </>
                </div>
            </div>
        </React.Fragment>
     )
 }
 
 export default productSpecial;