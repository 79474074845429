/*******************************************************************************************
* File Name                : Navbar
* File Directory           : src/components/Shared/Navbar
* Module                   : Navbar
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Navbar.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   Navbar        -   component for user navigation
 *
 * #How to call:
 *    <Navbar/>
 *
 * Note: Still working on the ff:
 * - active css 
 *******************************************************************************************/

import React, { useEffect, useState } from "react";
import { Navbar, Container, Nav } from 'react-bootstrap';
import homeIcon from "../../assets/site_icons/Home.svg";
import homeIconActive from "../../assets/site_icons/Home-Green.svg";
import cartIcon from "../../assets/site_icons/cartIcon.svg";
import cartIconActive from "../../assets/site_icons/cartIcon-Green.svg";
import messengerIcon from "../../assets/site_icons/Messenger.svg";
import SideBar from "./SideBar";
import { connect, useDispatch } from "react-redux";

const style = {
    BottomFixed: {
        position: 'fixed',
        bottom: '0',
        width: '100%',
        zIndex: '2',
        left: '0',
        background: '#ffffff',
        padding: "0px",
        height: "53px"
    },
    Container_Nav: {
        margin: '0 auto',
        display: 'block',
        height: "100%"

    },
    btnIcon: {
        margin: "0 auto",
        display: "block"
    },
    fbMsgFixed: {
        bottom: '10%',
        right: '30px',
        zIndex: '1',
        position: 'fixed'
    },
    centerImage: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%"
    }
}

const NavBar = ({ pathname, CartRedux }) => {
    const isMobile = localStorage.getItem("isMobile");
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const dispatch = useDispatch()
    const [refresh, setRefresh] = useState(true)
    const [location, setLocation] = useState(pathname)
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });

    // if (getUser.userVerified == "") {
    //     if (location != "/verifyOTP") {
    //         window.location.href = "/verifyOTP";
    //     }
    // } 
    useEffect(() => {
        let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
        let itemCount = 0
        CartRedux.forEach((cart) => { itemCount += cart.item_status === "enabled" ? cart.cart_qty : 0 }) // returning qty per item
        totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
        // itemCount = itemCount.reduce((a, b) => a + b, 0) // total of all items
        setTotal({ itemTotal: totalPrice, itemCount: itemCount })
        sessionStorage.setItem('total', JSON.stringify({ itemTotal: totalPrice, itemCount: itemCount }))
    }, [CartRedux])

    useEffect(() => {
        setLocation(pathname)
    }, [pathname, refresh])

    const triggerCart = () => {
        dispatch({
            type: 'SET_LOCATION',
            location: window.location.pathname
        })

        setRefresh(!refresh)
        document.getElementById("cartModalMobile").click();
    }
    return (
        <React.Fragment>
            {isMobile === "true" ? <Navbar style={style.BottomFixed} variant="light">
                <Container style={style.Container_Nav}>
                    <Nav className="me-auto h-100">
                        <div className="container p-0 h-100">
                            <div className="row p-0 h-100">
                                <div className="col p-0 h100">
                                    <Nav.Link className="h-100 p-0" style={style.centerImage} href="/">
                                        {location === "/" ?
                                            <img src={homeIconActive} width="16" style={style.btnIcon} />
                                            :
                                            <img src={homeIcon} width="16" style={style.btnIcon} />
                                        }
                                    </Nav.Link>
                                </div>
                                {/* <div className="col">
                                    <Nav.Link href="/search">
                                        {location ==="/search" ?
                                         <img src={searchIconActive} width="16" style={style.btnIcon} />
                                         
                                         :
                                         <img src={searchIcon} width="16" style={style.btnIcon} />
                                        }
                                    </Nav.Link>
                                </div> */}
                                {(location !== "/" && location !== '/success' && location !== '/thank-you') ?
                                    <div className="col p-0 h-100 userInfo position-relative">
                                        {total.itemCount > 0 ?
                                            <div className="cart-count" onClick={triggerCart}>
                                                {total.itemCount}
                                            </div>
                                            :
                                            null
                                        }
                                        {location === "/checkout-page"||location === "/checkout-details" || location.includes("productPage") || location.includes("home") || location.includes("sub-list") ?
                                            <Nav.Link onClick={triggerCart} className="h-100 p-0" style={style.centerImage}>
                                                <></>
                                                <img src={cartIcon} width="16" style={style.btnIcon} />
                                            </Nav.Link>
                                            :
                                            <Nav.Link href="/checkout-page" className="h-100 p-0" style={style.centerImage}>
                                                <img src={cartIcon} width="16" style={style.btnIcon} />
                                            </Nav.Link>
                                        }

                                    </div>
                                    :
                                    null
                                }
                                <div className="col p-0 userInfo">
                                    <Nav.Link className="h-100 p-0" style={style.centerImage}>
                                        <SideBar />
                                    </Nav.Link>
                                </div>
                                <div className="col p-0">
                                    <Nav.Link className="h-100 p-0" style={style.centerImage} href="https://www.messenger.com/t/114753366567227">
                                        <img src={messengerIcon} width="16" style={style.btnIcon} />
                                    </Nav.Link>
                                </div>
                            </div>
                        </div>

                    </Nav>
                </Container>
            </Navbar>
                : <div style={style.fbMsgFixed}>
                    <a href="https://www.messenger.com/t/114753366567227" style={style.btnIcon} >

                        <img width="32" />
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131.8 133.2"
                            fill='#4078ff' stroke='#fff' strokeLinecap='round' strokeLinejoin='round' strokeWidth='5px' width="42">
                            <g id="Layer_2" data-name="Layer 2"><g id="Layer_21" data-name="Layer 21"><path className="cls-1" d="M65.9,5A60.91,60.91,0,0,0,5,65.9C5,81.58,9.75,95,19.48,105.8h0l-1.07,17.54a4.57,4.57,0,0,0,6.33,4.49l15.63-6.58h0A59.8,59.8,0,0,0,65.9,126.8,60.9,60.9,0,0,0,65.9,5Z" /><path d="M31,80.52,51.39,50.57A5.73,5.73,0,0,1,59.27,49l15.4,10.2a5.38,5.38,0,0,0,5.82.07L99,47.7a1.32,1.32,0,0,1,1.87,1.73l-17,32.4a4.64,4.64,0,0,1-6.39,1.88L56.52,71.88a4.4,4.4,0,0,0-4.25,0L32.71,82.39A1.3,1.3,0,0,1,31,80.52Z" /></g></g>
                        </svg>
                    </a>
                </div>
            }
        </React.Fragment >
    )

};

const mapStateToProps = (state) => {
    return {
        pathname: state.Location.location,
        CartRedux: state.cart.CartList,
    }
}

export default connect(mapStateToProps)(NavBar);