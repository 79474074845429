//  * File Name                : Header
//  * File Directory           : src/components/Misc/RecipePage/Header.s
//  * Module                   : RecipeHeader
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In					        : src/Recipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <MiscHeader/>
 *
 *******************************************************************************************/


 import React, { useEffect, useState } from "react";

 // Headers
 import Header from "../Shared/Header";
 import DesktopHeader from "../Shared/DesktopHeader";
 
 // import meta tags library
 import { MetaTags } from "react-meta-tags";
 
 
 const MiscHeader = () => {
     const recipe_home = window.location.pathname
     const isMobile = localStorage.getItem("isMobile") === "true"
     const [visibleLogin, setVisibleLogin] = useState('true'); // show
     const [home_recipe, setHomeRecipe] = useState(false)
     // home button for recipe page
     useEffect(() => {
         if (recipe_home === "/ContentPage/recipe/" || recipe_home === "/ContentPage/recipe") {
             setHomeRecipe(true)
         }
     }, [home_recipe, recipe_home])
 
     return (
        <div className="row misc-h">
        {isMobile
            ?   <div
                    style={{position: 'fixed', zIndex: '2'}}
                    className="p-0 hdr m-h">
                    <Header title="Blogs"/>
                </div>
            :   <div
                    style={{position: 'fixed'}}
                    className="p-0 hdr d-h">
                    <DesktopHeader visibleLogin={visibleLogin} setVisibleLogin={setVisibleLogin}/>
                </div>
        }
        </div>
     )
 }
 
 export default MiscHeader