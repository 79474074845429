// export images
export const zgnImages = [
    {
        recipeName: "Pork Chow Fan",
        categoryName:"Fried Rice",
        subCategoryName:"Fried Rice",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587893/recipe/Pork_Chow_Fan_1_1920x800_z6ojup.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587875/recipe/Pork_Chow_Fan_2_1920x800_hg2qex.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587881/recipe/Pork_Chow_Fan_3_1920x800_k7tvf2.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654588174/recipe/Pork_Chow_Fan_1_square_onjhvm.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654588174/recipe/Pork_Chow_Fan_2_square_dgdu8m.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587883/recipe/Pork_Chow_Fan_3_square_rnkdyc.jpg"
        ]
    },
    {
        recipeName: "Vegetable Fried Rice",
        categoryName:"Fried Rice",
        subCategoryName:"Fried Rice",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587885/recipe/Vegetable_fried_rice_1_1920x800_gyimiv.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587884/recipe/Vegetable_fried_rice_2_1920x800_dn8lgc.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587887/recipe/Vegetable_fried_rice_3_1920x800_cdx5w0.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587885/recipe/Vegetable_fried_rice_1_square_gvfuw0.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587886/recipe/Vegetable_fried_rice_2_square_o35qog.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587887/recipe/Vegetable_fried_rice_3square_z0zfyv.jpg"

        ]
    },
    {
        recipeName: "Beefy Fried Rice",
        categoryName:"Fried Rice",
        subCategoryName:"Fried Rice",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587675/recipe/Beef_Fried_Rice_1_1920x800_jwmvr9.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587659/recipe/Beef_Fried_Rice_2_1920x800_l9a3r0.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587590/recipe/Beef_Fried_Rice_3_1920x800_n0axoi.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587618/recipe/Beefy_Fried_rice_square_1_j2etqp.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587637/recipe/Beefy_Fried_rice_square_3_gjlzll.jpg"
        ]
    },
    {
        recipeName: "Oh My Banana",
        categoryName:"Smoothie",
        subCategoryName:"Banana",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587654/recipe/Oh_My_Banana_1_1920x800_tktfi8.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587641/recipe/Oh_My_Banana_2_1920x800_om6ozw.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587686/recipe/Oh_My_Banana_3_1920x800_ypcjuy.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587672/recipe/Oh_My_Banana_1_square_uf18l8.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587706/recipe/Oh_My_Banana_2_square_vysllm.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587685/recipe/Oh_My_Banana_3_square_kvsj67.jpg"
        ]
    },
    {
        recipeName: "Fruit and Veggie",
        categoryName:"Smoothie",
        subCategoryName:"Pineapple",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587704/recipe/Fruit_and_Veggie_1_1920x800_bknq7i.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587698/recipe/Fruit_and_Veggie_2_1920x800_jtycba.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587892/recipe/Fruit_and_Veggie_3_1920x800_s02jdf.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587700/recipe/Fruit_and_Veggie_1_Square_fscclg.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587691/recipe/Fruit_and_Veggie_2_Square_uof2u0.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587792/recipe/Fruit_and_Veggie_3_Square_vvehqg.jpg"
        ]
    },
    {
        recipeName: "Green Smoothie",
        categoryName:"Smoothie",
        subCategoryName:"Pineapple",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587777/recipe/Green_Smoothie_1_1920x800_rmiatr.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587875/recipe/Green_Smoothie_2_1920x800_q5y5qi.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587890/recipe/Green_Smoothie_3_1920x800_h7mogs.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587892/recipe/Green_Smoothie_1_square_spufm5.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587888/recipe/Green_Smoothie_2_square_kb5gl8.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587890/recipe/Green_Smoothie_3_square_tkzbra.jpg"
        ]
    },
    {
        recipeName: "Milky Banana",
        categoryName:"Smoothie",
        subCategoryName:"Banana",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587887/recipe/Milky_Banana_1_1920x800_kfspgm.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587888/recipe/Milky_Banana_2_1920x800_sbxaum.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587889/recipe/Milky_Banana_3_1920x800_tfvird.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587888/recipe/Milky_Banana_1_Square_rynu7x.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587889/recipe/Milky_Banana_2_Square_i8gdtu.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587890/recipe/Milky_Banana_3_Square_qs70du.jpg"
        ]
    },
    {
        recipeName: "Melon Smoothie",
        categoryName:"Smoothie",
        subCategoryName:"Melon",
        banner:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587891/recipe/3_Melon_Smoothie_1_1920x800_pgdpgh.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587891/recipe/3_Melon_Smoothie_2_1920x800_b1qp00.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587892/recipe/3_Melon_Smoothie_3_1920x800_ynqasu.jpg"
        ],
        image:[
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587891/recipe/3_Melon_Smoothie_1_Square_womrtx.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587890/recipe/3_Melon_Smoothie_2_Square_iipvh8.jpg",
            "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587892/recipe/3_Melon_Smoothie_3_Square_lidjhz.jpg"
        ]
    },
    // {
    //     recipeName: "Sweet and Sour bangus",
    //     categoryName:"Dish",
    //     subCategoryName:"Fishy",
    //     banner:[
    //         ""
    //     ],
    //     image:[
    //         ""
    //     ]
    // }

]
