const INTIAL_STATE = {
    NotifyList: {},
    NotifyItem: {},
    VisibleLogin: false,
    ConfirmContact: false
}

const NotifyReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_NOTIFY':
            return {
                ...state,
                NotifyItem: action.value,
                VisibleLogin: action.login
            };
        case 'SET_CONFIRM_CONTACT':
            return {
                ...state,
                ConfirmContact: action.value
            };
        default:
            return state;
    }

};

export default NotifyReducer;