//  * File Name                : SelectedRecipeSlideShow
//  * File Directory           : src/components/Misc/RecipePage/Components/SelectedRecipeSlideShow
//  * Module                   : SelectedRecipeSlideShow
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/SelectedRecipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <SelectedRecipeSlideShow/>
 *
 *******************************************************************************************/



// import react library
import React from "react";
// import css
import "../RecipePage.css"   
// import Carousel component
import { Carousel } from "react-bootstrap";
// import React printing
import { useReactToPrint } from 'react-to-print';
// import propTypes
import PropTypes from "prop-types";

// export selected recipe slideshow
const SelectedRecipeSlideShow = ({ componentRef, recipe }) => {
    // isMobile 
    const isMobile = localStorage.getItem("isMobile") === "true"
    // current location
    const currentLocation = window.location.href
    // print function
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    // declare recipe image variable
    let recipeImage = isMobile ? recipe.recipeImages.image : recipe.recipeImages.banner

    
        // render recipe Images
        const renderImage = recipeImage.map( el => {
            // declare name variable
            let recipeName = recipe.recipeName
            // declare description variable
            let recipeDescription = recipe.recipeDescription
            // declare category variable
            // let recipe_category = recipe.categoryName
            return (
                <Carousel.Item interval={4000}>
                    <img
                        className="d-block w-100"
                        src={el}
                        alt={recipeName}
                    />
                    <div className="carousel-overlay d-block"></div>
                    <Carousel.Caption>
                        <div className="row carousel-description">
                            <div className="row carousel-desc-content leftText">
                                <h1 className="carousel-title">{recipeName}</h1>
                            </div>
                            <div className="row">
                                <p className="carousel-desc">{recipeDescription}</p>
                            </div>
                            <div className="row leftText">
                                {/* <div className="row">
                                    <p className="featured-cooking-duration zgn-text-color leftText">20 mins - 30 mins</p>
                                </div> */}
                                <div className="row">
                                    <p onClick={handlePrint} className="print c-pointer zgn-text-color">Print Recipe <i className="fa fa-print"></i></p>
                                </div>
                            </div>
                            <div className="row carousel-icons">
                                <div className="row share-on">
                                    <p>
                                        Share on:
                                    </p> 
                                </div>
                                <div className="row">
                                    <div className="col-sm carousel-icons-icons leftText">
                                        <div class="fb-share-button" data-href={currentLocation} data-layout="button" data-size="large">
                                            <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${currentLocation}src=sdkpreparse`} class="fb-xfbml-parse-ignore">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-facebook" viewBox="0 0 16 16">
                                                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
                                            </svg></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            )
        })
        
    return (
        <Carousel fade >
            {renderImage}
        </Carousel>
    )
}


// propType
SelectedRecipeSlideShow.propTypes = {
    componentRef: PropTypes.func,
    recipe: PropTypes.array
};
SelectedRecipeSlideShow.defaultProps = {
    componentRef: null,
    recipe: []
};

export default SelectedRecipeSlideShow