//  * File Name                : Header
//  * File Directory           : src/components/Misc/RecipePage/Header.s
//  * Module                   : RecipeHeader
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In					        : src/Recipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <RecipeHeader/>
 *
 *******************************************************************************************/


import React, { useEffect, useState } from "react";

// Headers
import Header from "../../../Shared/Header";
import DesktopHeader from "../../../Shared/DesktopHeader";

// import meta tags library
import { MetaTags } from "react-meta-tags";


const RecipeHeader = () => {
    const recipe_home = window.location.pathname
    const isMobile = localStorage.getItem("isMobile") === "true"
    const [visibleLogin, setVisibleLogin] = useState('true'); // show
    const [home_recipe, setHomeRecipe] = useState(false)
    // home button for recipe page
    useEffect(() => {
        if (recipe_home === "/ContentPage/recipe/" || recipe_home === "/ContentPage/recipe") {
            setHomeRecipe(true)
        }
    }, [home_recipe, recipe_home])

    return (
        <div className="row">
                    <MetaTags>
                        <title>Zagana | Introducing Zagana</title>
                        <meta name="description" content="The Online Grocery Every Filipino Needs" />
                        <meta property="og:title" content="Zagana | Introducing Zagana" />
                        <meta property="og:image" content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" />
                        <meta property="og:url" content="https://www.zagana.com/ContentPage/blogs/introducing-zagana" />
                        <meta property="og:type" content="website" />
                        <meta property="og:site_name" content="Zagana Inc." />
                        <meta name="keywords" content="farm-fresh, fresh, produce, local, frozen, farmers, quality, introduction, online grocery, online palengke, blogs" />
                        <meta name="author" content="Zagana Inc." />
                    </MetaTags>
                {isMobile ?
                    <div style={{ position: 'fixed', zIndex: '5' }} className="p-0" >
                        <Header title="Recipe" />
                    </div>
                    :
                    <div style={{ position: 'fixed', zIndex: '5' }} className="p-0" >
                        <DesktopHeader
                            visibleLogin={visibleLogin}
                            setVisibleLogin={setVisibleLogin} />
                            <div className="nav-bar">
                                {
                                    home_recipe 
                                        ?
                                            "" 
                                        : 
                                            <ul>
                                                <li><a href="/ContentPage/recipe/">Back to home Recipe Page</a></li>
                                            </ul>
                                }
                            </div>
                    </div>
                }
            </div>
    )
}

export default RecipeHeader