/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : Address
 * File Directory           : src/components/Shared/Address
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : post add address 
 * Used In					        : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Address        -   component for adding Address
 *
 * #How to call:
 *    /address/add
 *
 *******************************************************************************************/

import Select from 'react-select'
import React, { useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import './Address.css'
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import Header from "../Header"
import Home from '../../APIs/Home';

const style = {
    formGroup: {
        marginBottom: '0px',
        position: 'relative'
    },
    inputIcon: {
        borderRadius: '5px 0px 0px 5px',
        height: '100%'
    },
    paddingBottom: {
        paddingBottom: '4rem'
    },
    error: {
        color: '#ff1e02'
    },
    valid: {
        color: '#24b14b'
    }
}


const renderSelect = (props) => {
    const { input, options, meta, label, initialBrgy, initialCity } = props;
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    if (!getUser) {
        window.location.replace("/login#checkout")
    }
    return (
        <React.Fragment>
            <label
                htmlFor="staticEmail"
                className="col"
            >
                {label} <div className="d-inline-block">{renderIcon(meta)}</div>
            </label>
            <div className={className}>
                <Select
                    class_name="form-control"
                    name="City"
                    onChange={value => input.onChange(value.value)}
                    options={options}
                    defaultValue={initialBrgy}
                />
            </div>
            <div className="form-control-feedback">{renderMsg(meta)}</div>
        </React.Fragment>
    )
}
const renderBrgy = (props) => {

    const { input, value_name, class_name, type, label, meta } = props;
    return (
        <div>
            <label
                htmlFor="staticEmail"
                className="col"
            >
                {label} <div className="d-inline-block">{renderIcon(meta)}</div>
            </label>
            <input
                {...input}
                value={value_name}
                autoComplete="off"
                className={class_name}
                type={type}

            />
        </div>
    )
}

const renderInput = ({ input, type, meta, class_name, value_check, maxLength, id, pattern, label }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
        <div className={className}>
            <label
                htmlFor="staticEmail"
                className="col"
            >
                {label}  <div className="d-inline-block">{renderIcon(meta)}</div>
            </label>
            {id !== "phone_fieldd" ? <input {...input} type={type} id={id} pattern={pattern} autoComplete="off" className={class_name} checked={value_check} maxLength={maxLength} />
                :
                <div className="input-group">
                    <div className="input-group-prepend" style={style.inputIcon}>
                        <span className="input-group-text" id="basic-addon1">(+63)</span>
                    </div>
                    <input {...input} type={type} id={id} pattern={pattern} autoComplete="off" className={class_name} checked={value_check} maxLength={maxLength} />

                </div>
            }

            <div className="form-control-feedback">{renderMsg(meta)}</div>
        </div>
    );
};

const renderMsg = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span className="error" >{error}</span>
            </div>
        )
    }
}

const renderIcon = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span style={style.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
}
const validate = values => {
    const errors = {};
    if (!values.fullName) {
        errors.fullName = 'Please enter your full name'
    }
    if (!values.houseNumber) {
        errors.houseNumber = 'Please enter your house/lot/apt #';
    }
    if (!values.street) {
        errors.street = 'Please enter your street';
    }
    if (!values.Brgy) {
        errors.Brgy = 'Please select your barangay';
    }
    if (!values.Phone) {
        errors.Phone = 'Please enter your Phone Number';
    }
    if (values.Phone) {
        if (values.Phone.length > 10 || values.Phone.length < 10) {
            errors.Phone = 'Format should be: (+63) 9xxxxxxxxx';
        }
    }
    if (!values.postalCode) {
        errors.postalCode = 'You must enter your postalCode';
    }
    return errors;
};
const EditAddress = ({ valid, EditAddress, onHide, initialBrgy, initialCity, id, add_id }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const dispatch = useDispatch()
    const selectedCity = localStorage.getItem('SelectedCity') || {}
    const parsedSelectedCity = EditAddress?.values?.City || null;
    const history = useHistory()
    const [city, setCity] = useState([])
    const [cityName, setCityName] = useState('')
    const [brgy, setBrgy] = useState([]);
    const handleSubmit = (e) => {
        e.preventDefault()
        const values = EditAddress.values
        const Login = sessionStorage.getItem('Login')
        const parseLogin = JSON.parse(Login)
        let details = {}
        if (isMobile) {
            details = {
                user_id: parseLogin.customer_id,
                add_fn: values.fullName,
                add_apartment: values.houseNumber,
                add_street: values.street,
                brgy_id: values.Brgy,
                city_id: values.city_id,
                address_zip: values.postalCode,
                add_contact: values.Phone,
                add_id: add_id
            }
        } else {
            details = {
                user_id: parseLogin.customer_id,
                add_fn: values.fullName,
                add_apartment: values.houseNumber,
                add_street: values.street,
                brgy_id: values.Brgy,
                city_id: parsedSelectedCity.city_id,
                address_zip: values.postalCode,
                add_contact: values.Phone,
                add_id: id
            }
        }
        const update = async () => {
            try {
                const response = await Home.post(`/address/update`, details)
                if (response.status === 200) {
                    if (isMobile) {
                        history.push('/address/change')
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }
        update()
        dispatch({
            type: 'ADD_ADDRESS',
            add: true
        })
        setTimeout(() => {
            dispatch({
                type: 'ADD_ADDRESS',
                add: false
            })
        }, 500)
        if (!isMobile) {
            onHide(false)
        }
    }
    //fetching city from database
    useEffect(() => {
        fetchCity();
    }, []);
    const fetchCity = async () => {
        // alert("add address");
        const response = await Home.get("/city");
        const cityResult = response.data || [];
        // console.log(cityResult);
        setCity(cityResult);
    };
    useEffect(() => {
        const details = { city: parsedSelectedCity };
        const fetchBrgy = async () => {
            try {
                const response = await Home.post(`/brgy`, details);
                if (response.data !== 404) {
                    setBrgy(response.data || []);
                    console.log(response.data)
                } else {
                    setBrgy([]);
                    //   setCityName("");
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchBrgy();
    }, [parsedSelectedCity])

    const renderedBrgy = brgy.map((data) => {
        return {
            value: data.brgy_id,
            label: data.brgy_name
        }
    })
    const renderedCity = city.map((data) => {
        // console.log(data);
        return {
            value: data.city_id,
            label: data.city_name,
        };
    });
    return (


        <React.Fragment>
            {isMobile ?
                <Header title="Edit Address" />
                :
                null
            }
            <div className="row-12" style={isMobile ? style.paddingBottom : null} >
                <form onSubmit={handleSubmit}>
                    <div className="form-group row" style={style.formGroup} id="fullname_field">
                        <div className="col-12">
                            <Field
                                label="Full Name"
                                type="text"
                                name="fullName"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="houseNumber_field">
                        <div className="col-12">
                            <Field
                                label="Lot/ house/ Apt #"
                                type="text"
                                name="houseNumber"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="street_field">
                        <div className="col-12">
                            <Field
                                label="Street"
                                type="text"
                                name="street"
                                component={renderInput}
                                class_name="form-control"

                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="brgy_field">
                        <div className="col-12">
                            <Field
                                label="Barangay"
                                type="text"
                                name="Brgy"
                                component={renderSelect}
                                class_name="form-control"
                                options={renderedBrgy}
                                initialBrgy={initialBrgy}
                                id="brgy"

                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="city_field">
                        <div className="col-12">
                            <Field
                                label="City"
                                type="text"
                                name="City"
                                component={renderSelect}
                                class_name="form-control City"
                                value_name={cityName}
                                initialBrgy={initialCity}
                                options={renderedCity}
                            />
                        </div>
                    </div>
                    <div className="form-group row mt-1" style={style.formGroup} id="postalCode_field">
                        <div className="col-12">
                            <Field
                                label="Postal Code"
                                type="text"
                                name="postalCode"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="phone_field">
                        <div className="col-12">
                            <Field
                                label="Phone"
                                type="number"
                                name="Phone"
                                maxLength="11"
                                component={renderInput}
                                class_name="form-control"
                                id="phone_fieldd"
                                pattern="[0-9]"
                            />
                        </div>
                    </div>
                    <br />
                    <div className="col-12 mt-3">
                        <p style={{ fontSize: 12 }}>Our Customer Success Representative will only call you 3 times. Please ensure that the contact number you provided is active and reachable before your scheduled delivery time. Thank you!</p>
                        <div
                            className="col-12">
                            <button
                                type="submit"
                                disabled={!valid}
                                className="btn btn-success w-100">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment >
    )
}
const mapStateToProps = state => {
    return {
        EditAddress: state.form.EditAddress,
        initialBrgy: {
            label: state.AddressInfo.brgy_name,
            value: state.AddressInfo.brgy_id
        },
        initialCity: {
            label: state.AddressInfo.city_name,
            value: state.AddressInfo.city_id
        },
        initialValues: {
            fullName: state.AddressInfo.fullName,
            houseNumber: state.AddressInfo.houseNumber,
            street: state.AddressInfo.street,
            postalCode: state.AddressInfo.postalCode,
            Phone: state.AddressInfo.Phone,
            Brgy: state.AddressInfo.brgy_id,
        },
        add_id: state.AddressInfo.add_id,
    }
};

export default connect(mapStateToProps)
    (reduxForm({
        form: 'EditAddress',
        validate
    })(EditAddress));

