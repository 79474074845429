// import images
import {zgnImages} from "./RecipeImages";
// import videos
import { zgnVideos } from "./RecipeVideos";

// recipe list 
export const recipeList = [
    {

        featured: true,
        recipeName: "Beefy Fried Rice",
        categoryName:"Fried Rice",
        subCategoryName:"Beefy", 
        recipeImages: zgnImages[2],
        recipeVideo: zgnVideos[0].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 1/2 kilo Zagana Beef Cubes cut into thin strips',
                '- 1/2 cup butter divided',
                '- 1/4 cup vegetable oil',
                '- 1/2 tsp. salt',
                '- 1 tsp. white pepper',
                '- 1 medium Zagana Onion sliced',
                '- 2 tbsp. minced Zagana Garlic',
                '- 8 cups Zagana Local Whole Grains (cooked) ',
                '- 1 beef cube',
                '- 1/2 tsp. ground Zagana Ginger',
                '- 1/2 cup diced Zagana Celery',
                '- 1 1/2 cups Zagana Broccoli florets',
                '- 1/2 cup Zagana Carrots cut into thin strips'
            ],
            procedures: [
                'In a large pan, melt 2 tablespoons of butter over low heat. Add meat and cook. Sprinkle salt, add onion and garlic until meat turns golden brown.',
                'Remove meat from pan. Set aside.',
                'Pour vegetable oil and fry minced garlic until golden brown and set aside. (for garnish for later)',
                'In same pan, melt butter and add the ginger, broccoli, carrots, celery and beef cube. Stir-cook until vegetables are tender.',
                'Add cooked rice. Sprinkle salt and white pepper.',
                'Add the cooked beef. Toss until all ingredients are well distributed.',
                'Serve on a plate, garnish with fried garlic and enjoy!'
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Pork Chow Fan",
        categoryName:"Fried Rice",
        subCategoryName:"Fried Rice",
        recipeImages: zgnImages[0],
        recipeVideo: zgnVideos[1].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Pork Chow Fan recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 6 ounces Zagana ground pork ',
                '- 8 cups Zagana Local Whole Grains (cooked, cooled and dry)',
                '- ½ cup Zagana Carrot, chopped',
                '- ¾ cup green peas',
                '- 1 piece Zagana Onion, minced',
                '- 2 pieces Zagana  Free range eggs beaten',
                '- ½ cup Zagana Onion Spring. chopped',
                '- 2 tablespoons oyster sauce',
                '- 1 tablespoon soy sauce',
                '- 1 teaspoon Sesame oil',
                '- Salt and ground white pepper to taste',
                '- 5 tablespoons cooking oil',
            ],
            procedures: [
                'Heat butter in a large pan. Add beaten eggs. Fry until eggs are fully cooked.  And set aside.',
                'On the same pan melt butter and sauté onion until it softens.',
                'Add ground pork. Cook while stirring until pork turns medium brown.',
                'Pour soy sauce, Sesame oil, and oyster sauce into the pan. Stir.',
                'Add carrots, green peas and leftover rice. Stir-fry for 2 minutes.', 
                'Add onion spring. Sprinkle salt and white pepper.',
                'Add the chopped fried egg and mix until all ingredients are well distributed.',
                'Serve on a plate, garnish with onion spring and enjoy!'
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Vegetable Fried Rice",
        categoryName:"Fried Rice",
        subCategoryName:"Fried Rice",
        recipeImages: zgnImages[1],
        recipeVideo: zgnVideos[1].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Vegetable Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 4 cups Zagana Healthy Brown Rice (cooked, cooled, dry)',
                '- 2 cups chopped Zagana Broccoli',
                '- 1/2 cup Zagana Red bell pepper diced',
                '- 1/2 cup Zagana Green bell pepper',
                '- 1/4 cup minced Zagana Carrots',
                '- 2 tablespoons vegetable oil',
                '- 1 teaspoon salt',
            ],
            procedures: [
                'Combine salt and rice. Gently mix using your hands.',
                'Heat a wide frying pan with vegetable oil.',
                'Stir fry the carrots, peppers, and broccoli. Add sesame oil.',
                'Add the rice and then cook for 5 to 6 minutes while continuously mixing. This will prevent the rice from sticking on the pan.',
                'Serve on a plate and enjoy!',
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Oh My Banana",
        categoryName:"Smoothie",
        subCategoryName:"Banana",
        recipeImages: zgnImages[3],
        recipeVideo: zgnVideos[7].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 1 small orange (peeled and sliced)',
                '- 1 cup cubed ripe mango',
                '- 1 banana sliced',
                '- ice cubes (optional)',
            ],
            procedures: [
                'Cut orange into wedges.',
                'Slice mango into small pieces.',
                'Slice banana into small pieces.',
                'Put all ingredients in a blender. Blend until smooth.',
                'Pout into a glass and enjoy!',
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Milky Banana",
        categoryName:"Smoothie",
        subCategoryName:"Banana",
        recipeImages: zgnImages[6],
        recipeVideo: zgnVideos[6].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 1 small banana ripe',
                '- 1/2 ripe papaya',
                '- 1 cup of milk',
                '- 1 cup of ice',
            ],
            procedures: [
                'Peel and slice papaya into small pieces (remove seeds).',
                'Slice banana into small pieces.',
                'Put all ingredients in a blender. Blend until smooth.',
                'Pout into a glass and enjoy!',
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Fruit and Veggie",
        categoryName:"Smoothie",
        subCategoryName:"Pinapple",
        recipeImages: zgnImages[4],
        recipeVideo: zgnVideos[4].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 1 cup chopped carrot',
                '- 1/2 cup pineapple',
            ],
            procedures: [
                'Peel and chop carrot into small pieces.',
                'Chop pineapple into small pieces',
                'Put all ingredients in a blender. Blend until smooth.',
                'Pout into a glass and enjoy!',
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Green Smoothie",
        categoryName:"Smoothie",
        subCategoryName:"Pinapple",
        recipeImages: zgnImages[5],
        recipeVideo: zgnVideos[3].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 1/2 cup chopped fresh pineapple',
                '- 2 cups fresh spinach or kale',
                '- 1/2 banana frozen',
                '- 1 cup chopped mango',
            ],
            procedures: [
                'Peel and slice pineapple into small pieces',
                'Slice mango into small pieces.',
                'Remove spinach stalk and chop in small pieces.',
                'Slice banana into small pieces.',
                'Put all ingredients in a blender. Blend until smooth.',
                'Pout into a glass and enjoy!',
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    },
    {
        featured: false,
        recipeName: "Melon Smoothie",
        categoryName:"Smoothie",
        subCategoryName:"Melon",
        recipeImages: zgnImages[7],
        recipeVideo: zgnVideos[5].url,
        recipeRatings: 1,
        recipeDescription: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        recipeDetails: {
            ingredients: [
                '- 2 cups watermelon',
                '- 1 cup honeydew melon',
                '- 1 cup sweet melon',
                '- 1/2 cup lime juice',
                '- 1/2 cup ice cubes',
            ],
            procedures: [
                'Slice watermelon, removes seeds and chop into small pieces.',
                'Slice honey, scoop out seeds and chop into small pieces.',
                'Slice swee melon, scoop out seeds and chop into small pieces',
                'Slice lime and squeeze',
                'Put all ingredients in a blender. Blend until smooth.',
                'Pout into a glass and enjoy!',
            ],
            prices:[
                'Beef Cubes (500g) - ₱199',
                'Onion Red (250g) - ₱21',
                'Garlic (250g) - ₱35',
                'Zagana Local Long Grains 5kg - ₱294',
                'Ginger (100g) - ₱18',
                'Celery (500g) - ₱49',
                'Broccoli (500g) - ₱168',
                'Carrots Regular (500g) - ₱84',
                'Total: ₱898'
            ]
        }
    }
]

// category list
export const categoryList = [
    // {
    //     categoryName: "Fried Rice",
    //     categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
    //     categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    // },
    // {
    //     categoryName: "Fried Rice",
    //     categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
    //     categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    // },
    // {
    //     categoryName: "Fried Rice",
    //     categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
    //     categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    // },
    // {
    //     categoryName: "Fried Rice",
    //     categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
    //     categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    // },
    // {
    //     categoryName: "Fried Rice",
    //     categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
    //     categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    // },
    // {
    //     categoryName: "Fried Rice",
    //     categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
    //     categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    // },
    {
        categoryName: "Fried Rice",
        categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
        categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587660/recipe/Beefy_Fried_rice_square_2_uwwcci.jpg"
    },
    {
        categoryName: "Smoothie",
        categoryDescription: "To eat is a necessity, but to eat intelligently is an art. We all eat, an it would be a sad waste of opportunity to eat badly",
        categoryImage: "http://res.cloudinary.com/dw5axbz64/image/upload/v1654587672/recipe/Oh_My_Banana_1_square_uf18l8.jpg"
    }
]

// kitchen tips list
export const KitchenTipsList = [
    {
        name:"Beefy Fried Rice",
        description: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
        video: zgnVideos[0].url,
        poster: zgnImages[2].image[3]
    },
    // {
    //     name:"Pork Chow Fan",
    //     description: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
    //     video: zgnVideos[1].url,
    //     poster: zgnImages[0].image[3]
    // },
    // {
    //     name:"Melon Smoothie",
    //     description: "This easy homemade Beef Fried Rice recipe includes tender strips of beef, vegetables, crisp rice, and a rich, full-flavored sauce. Serve as a side or enjoy this stir fry all on its own as a complete meal.",
    //     video: zgnVideos[5].url,
    //     poster: zgnImages[7].image[3]
    // },
]