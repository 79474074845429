/*******************************************************************************************
//  * File Name                : ItemModal
//  * File Directory           : src/components/Home/ItemModal
//  * Module                   : ItemModal
//  * Author                   : Jake
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : ItemList
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemModal        -   component for pop up item details
 *
 * #How to call:
 *    <ItemModal/>
 *
 * Note:
 *******************************************************************************************/
import React, { useState, useRef, useEffect } from "react";
import UseOutsideClick from "./UseOutsideClick";
import ReactPixel from 'react-facebook-pixel';
import _ from 'lodash'
import { forgotModal } from "../../actions";
import { useDispatch } from "react-redux";
import { storeFrontLogs } from "../APIs/Logs";
import { v4 as uuid } from 'uuid';
import { useTimer } from 'react-timer-hook';
import { borderTop } from "@mui/system";
import { SaveToDatabase } from "./SaveToDatabase";
const style = {
  addButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "0",
    width: "170px",
    height: "39.5px",
    float: "right"
  },
  itemModal: {
    'transition': 'all 0.8s ease',
  },
  showModal: {
    'display': 'block',
    'background': 'rgba(0, 0, 0, 0.5)',
    'transition': 'all 0.8s ease',
    'top': '0px',
  }, modalDialog: {
    'margin': '1.5rem'
  },
  modalContent: {
    'height': 'auto'
  },
  textField: {
    'textAlign': "center",
    'float': "right",
    'background': "transparent",
    'margin': "3px"
  },
  textItemTitle: {
    fontSize: "1.1rem"
  },
  textItemVariant: {
    fontSize: "0.8rem"
  },
  textItemPrice: {
    fontSize: "0.9rem"
  },
  textDescription: {
    width: "100%",
    border: 'none',
    background: 'transparent',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
    lineHeight: '1.5'
  },
  incrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    float: "right",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  decrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#white",
    color: "#24b14b",
    fontWeight: "bold",
    float: "right",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  }, closeButton: {
    float: 'right',
    top: "7px",
    position: "relative",
    right: "5px"
  },
  headerItemTitle: {
    color: "#222",
    textDecoration: "none",
    fontSize: "20px"
  },
  timeBorder: {
    border: "solid 1px #ffca00",
    padding: "1px",
    background: "#ffca00",
    color: "#fff",
    borderRadius: "5px",
    fontWeight: "400",
    color: "#222",
    fontSize: "15px"
  }, borderBlack: {
    border: "3px solid #222",
    borderBottom: "0"
  }, borderwithBottom: {
    border: "3px solid #222"
  }, borderBlack2px: {
    border: "2px solid #facc02",
    borderRadius: "0",
    borderTop: "0px"
  }

}
let defaultItem = {
  item_id: 0,
  item_name: "Product Name",
  item_variant: "500g",
  item_price: 300,
  category: "all",
  item_status: "enabled",
  item_description: "Description here - 500g",
  item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const ItemModal = ({ modalStatus, itemDetailModal, setModalStatus, setItemDetailModal, setBranch, cartChange, setCartChange, setVisibleLogin }) => {
  const item = itemDetailModal === null ? defaultItem : itemDetailModal
  const [actionChecker, setActionChecker] = useState(null);
  const [visible, setVisible] = useState(cartChange > 0 ? true : false);
  const ref = useRef();
  const [delayStatus, setDelayStatus] = useState(false); // show modal and delay on closing it.
  const isMobile = localStorage.getItem("isMobile") || "true"
  let body = document.body; // for setting body height (modal)

  const dispatch = useDispatch()
  const isLogged = sessionStorage.getItem("Login") || ''; // login checker
  const uniqueID = uuid();
  let source = sessionStorage.getItem('source')||''
  let gen_id = localStorage.getItem('gen_id')
  let user;
  let userLogin;
  const time = (item.category).toLowerCase().includes("flash") ? new Date(item.flash_end_date) : new Date()
  let expiryTimestamp = time.setSeconds(time.getSeconds())
  let autoStart = (item.category).toLowerCase().includes("flash")
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({
    expiryTimestamp, autoStart, onExpire: () =>
      window.location.reload()
  });
  useEffect(() => {
    if (modalStatus === true) {
      restart((new Date(item.flash_end_date)).setSeconds((new Date(item.flash_end_date)).getSeconds()))
    } else {
      pause()
    }
  }, [modalStatus])
  //for clicking outside close modal
  UseOutsideClick(ref, () => {
    if (delayStatus) {
      setModalStatus(false)
      setItemDetailModal(defaultItem)
      setCartChange(0)
      if (!window.location.href.includes('checkout-details'))
        window.history.pushState({}, document.title, "/");   // window.location.hash=item.item_name.replace(/ /g,'');
      else
        window.history.pushState({}, document.title, "/checkout-details");
    }
  });
  //timing out of opening and closing of modal
  setTimeout(() => {
    setDelayStatus(modalStatus)
  }, 100);

  //onChange/onBlur run cart quantity function
  const inputQtyChange = (action, count, e) => {
    //check action type
    if (action === "blur") {
      setActionChecker(null)
      //on blur remove if 0 else just update qty
      if (count === "" || count <= 0 || count === "0") {
        setCartChange(0)
      }
      else {
        //set max to 50
        if ((item.category).toLowerCase().includes("flash")) {
          if (parseInt(count) > 2)
            count = 2
        }
        else if (parseInt(count) > 50)
          count = 50
        count = parseInt(count)
        setCartChange(count)
      }
    } else {
      setActionChecker('onChange')
      //set max to 50
      if ((item.category).toLowerCase().includes("flash")) {
        if (parseInt(count) > 2)
          count = 2
      }
      else if (parseInt(count) > 50)
        count = 50
      setCartChange(count)
    }
  }

  const setLogs = (changes, movement, cartItem) => {
    if (gen_id === null) {
      gen_id = localStorage.setItem('gen_id', uniqueID)
      gen_id = uniqueID
      user = gen_id
    } else if (gen_id) {
      gen_id = localStorage.getItem('gen_id')
      user = gen_id
    } else if (isLogged) {
      userLogin = JSON.parse(sessionStorage.getItem('Login'))
      gen_id = localStorage.getItem('gen_id')
      user = userLogin.customer_name + ` (${gen_id})`
    }

    const payload = {
      user: user,
      changes: changes,
      type: movement,
      date: `NOW()`,
      sku: cartItem.item_sku
    }
    storeFrontLogs(payload)
  }
  //onCartChange check if cart QTy = 0 input field and minus btn should be hidden else vice versa
  useEffect(() => {
    if (actionChecker !== 'onChange') {
      if (cartChange > 0) {
        setVisible(true)
      } else {
        setVisible(false)
      }
    }
  }, [cartChange])

  //trigger close modal
  const closeModal = () => {
    setModalStatus(false)
    setItemDetailModal(defaultItem)
    setCartChange(0)
    if (!window.location.href.includes('checkout-details')) {
      window.history.pushState({}, document.title, window.location.pathname);   //removeItem Name on URL
    }
    else {
      window.history.pushState({}, document.title, "/checkout-details");
    }
  }

  //on click Notify me/update me
  const btnNotifyMe = async () => {
    if (isLogged) {

      let user = JSON.parse(sessionStorage.getItem('Login'))
      let userEmail = user.customer_email
      if (userEmail !== null && userEmail !== "") {

      } else {
        alert("kindly input Email")
      }
    } else {
      closeModal()
      setVisibleLogin("false");
      let element = document.getElementById("modalLoginID");
      body.classList.add("body_class_");

      dispatch(forgotModal(false))
    }
    // await Home.post('/accessStorage/changeCart', {
    //   itemOnCart: items,
    //   accessToken: accessKey
    // })
  }

  return (
    <React.Fragment>

      <div id="itemModal">
        <div className='modal'
          style={modalStatus ? style.showModal : {}}
          tabIndex="-1"
          role="dialog">
          <div className="modal-dialog" style={style.modalDialog} role="document" ref={ref}>
            <div id="modal-content" className="modal-content" style={style.modalContent}>

              {
                (((item.item_price - item.item_salePrice) / item.item_price) * 100).toFixed(2) <= 0 ? null :
                  <div className="ribbon-wrapper">
                    <div className="ribbon">
                      {Math.round(((item.item_price - item.item_salePrice) / item.item_price) * 100)}%
                    </div>
                  </div>
              }


              {
                ((item.category || "all").toLowerCase()).includes("new") ?
                  <div className="ribbon-wrapper" >
                    {
                      window.location.pathname === '/checkout-details' ?
                        <div className="ribbon ribbonNew">
                          &nbsp;&nbsp;&nbsp;new
                        </div>
                        :
                        <div className="ribbon ribbonNew">
                          &nbsp;&nbsp;&nbsp;new
                        </div>

                    }
                  </div>
                  : null
              }
              {
                ((item.category || "all").toLowerCase()).includes("seasonal") ?
                  <div className="ribbon-wrapper" >
                    {
                      window.location.pathname === '/checkout-details' ?
                        <div className="ribbon ribbonSeasonal">
                          &nbsp;Seasonal
                        </div>
                        :
                        <div className="ribbon ribbonSeasonal">
                          &nbsp;Seasonal
                        </div>

                    }
                  </div>
                  : null
              }

              <div className="modal-body">
                <div className="float-right">
                  <button className='btn btn-transparent'
                    style={style.closeButton}
                    onClick={closeModal}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#222" className="bi bi-x-lg" viewBox="0 0 16 16">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                      <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                    </svg>
                  </button>
                </div>
                <div 
                className={`p-4 h-25 ${(item.category).toLowerCase().includes("flash") ? "borderBlack" : "borderwithBottom"}`}>
                  <img src={`${item.item_image ? item.item_image : 'https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png'}`}
                   className={`rounded mx-auto d-block ${isMobile ? 'w-75' : 'w-100'}`} alt="..." />
                </div>
                <>
                  {
                    (item.category).toLowerCase().includes("flash") &&
                    <>
                      <div className="alert alert-secondary p-2" style={style.borderBlack2px} >
                        <div className="row">
                          <div className="col-6" style={{ "fontWeight": "600" }}>
                            <span className="text-dark">Flash Sale! </span>
                            <br />
                            <strong style={!isMobile && window.location.pathname !== '/' ? style.textItemPriceDesktop : style.textItemPrice} ><span style={item.item_salePrice < item.item_price ? { textDecoration: "line-through", fontWeight: 'normal' } : {}}>₱{item.item_price}</span> {item.item_salePrice < item.item_price ? <span style={{ color: "red" }}> ₱{item.item_salePrice}</span> : ''} </strong>

                          </div>
                          <div className="col-6 my-auto flashTimer">
                            <span className="float-end" style={style.timeBorder}>Ends in: &nbsp;{("0" + hours).slice(-2)} : {("0" + minutes).slice(-2)} : {("0" + seconds).slice(-2)}&nbsp;</span>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </>
                <div className='p-3'>
                  <h2 className="mb-1" style={style.headerItemTitle}>{item.item_name}</h2>
                  <p className="mb-2 text-secondary" style={style.textItemVariant}>{item.item_variant}</p>
                  <pre className="mb-2 text-secondary" style={style.textDescription}>{_.get(item, ['item_description'], '').length >= 300 ? `${item.item_description.substring(0, 300)}...` : item.item_description} </pre>
                  <a href={`/productPage/${item.item_sku}`} >See more</a>
                  <br />
                  {
                    (item.category).toLowerCase().includes("flash") ? 
                    ""
                    : 
                    <strong style={!isMobile && window.location.pathname !== '/' ? style.textItemPriceDesktop : style.textItemPrice} ><span style={item.item_salePrice < item.item_price ? { textDecoration: "line-through", fontWeight: 'normal' } : {}}>₱{item.item_price}</span> {item.item_salePrice < item.item_price ? <span style={{ color: "red" }}> ₱{item.item_salePrice}</span> : ''} </strong>
                  }
                  <div style={item.item_status && (item.item_status).toLowerCase() === "disabled" && isMobile ? { float: "right" } : {}}>
                  {item.item_status && (item.item_status).toLowerCase() === "enabled" ?
                    <div>
                      <button
                        id={`btnAddCart${item.item_id}`}
                        className={`btn btnAdd ${visible ? "" : "d-none"}`}
                        style={style.incrementButtonColor}
                        onClick={() => {
                          setCartChange(cartChange + 1)
                          setLogs(`Item Variant: ${item.item_variant}, Item Cost: ${item.item_price}`, "add_to_cart", item)

                          ReactPixel.track('AddToCart',
                            {
                              content_ids: [item.item_sku],
                              content_name: item.item_name,
                              currency: 'PHP',
                              value: item.item_salePrice,
                            });
                            if (source === "Tiktok") {
                              
                              SaveToDatabase({
                                action: {
                                    type: 'AddToCart',
                                    value: {
                                        content_ids: [item.item_sku],
                                        content_type: 'product',
                                        content_name: item.item_name,
                                        currency: "PHP",
                                        value: parseFloat(item.item_price), // your total transaction value goes here
                                    }
                                },
                                sku: item.item_sku
                            })
                            window.ttq.track('AddToCart',
                            {
                              content_id: item.item_sku,
                              content_type: "product",
                              content_name: item.item_name,
                              currency: 'PHP',
                              value: item.item_salePrice,
                            })
                            }
                        }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 95.84" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px"><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="47.92" y1="12.5" x2="47.92" y2="83.34" /><line className="cls-1" x1="83.34" y1="48.96" x2="12.5" y2="48.96" /></g></g></svg>

                      </button >
                      <input
                        id={`inputCartQty${item.item_id}`}
                        className={`form-control w-25 float-right border-0 border-bottom ${visible ? "" : "d-none"
                          }`}
                        type="number"
                        value={cartChange}
                        style={style.textField}
                        onBlur={(e) => inputQtyChange("blur", e.target.value, e)}
                        onChange={(e) => inputQtyChange("change", e.target.value, e)}
                      />
                      <button
                        id={`btnMinusCart${item.item_id}`}
                        className={`btn ${visible ? "" : "d-none"}`}
                        style={style.decrementButtonColor}
                        onClick={() => {
                          setCartChange(cartChange - 1)
                          setLogs(`Item Variant: ${item.item_variant}, Item Cost: ${item.item_price}`, "remove_from_cart", item)
                        }
                        }
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash" viewBox="0 0 16 16">
                          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                        </svg>
                      </button>
                      <button
                        id={`btnAddCart${item.item_id}`}
                        className={`btn btnAdd ${visible ? "d-none" : ""}`}
                        style={style.addButtonColor}
                        onClick={() => {
                          setCartChange(cartChange + 1)
                          setLogs(`Item Variant: ${item.item_variant}, Item Cost: ${item.item_price}`, "add_to_cart", item)

                          ReactPixel.track('AddToCart',
                            {
                              content_ids: [item.item_sku],
                              content_name: item.item_name,
                              currency: 'PHP',
                              value: item.item_salePrice,
                            });
                        }}
                      >
                        ADD
                      </button >
                    </div> : ""
                    // <button
                    //   style={{ background: "#facc09", borderRadius: "25px" }}
                    //   className={`btn m-3 mt-3 mb-1`}
                    //   onClick={btnNotifyMe}
                    // >
                    //   Notify Me
                    // </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment >
  );
};

export default ItemModal;