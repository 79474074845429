export const sublisting = [
    {
        subList: "Tip-top Chicken Cuts",
        hookline: `It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged`,
        hooklineDesc: `Sometimes in my tears I drown
        But I never let it get me down
        So when negativity surrounds
        I know some day it'll all turn around because`,
        gif: "https://res.cloudinary.com/dw5axbz64/image/upload/v1662710892/special%20product/pork%20sinigang/Pork_Sinigang_GIF_Square_gyus76.gif",
        banners: {
            desktop: "https://res.cloudinary.com/dw5axbz64/image/upload/v1663070379/icons/banner-desktop-3_ITEMS_tkiscv.png",
            mobile: [
                'https://res.cloudinary.com/dw5axbz64/image/upload/v1662980849/icons/banner-01_wrcy41.jpg',
                'https://res.cloudinary.com/dw5axbz64/image/upload/v1662980849/icons/banner-02_ggtgya.jpg',
                'https://res.cloudinary.com/dw5axbz64/image/upload/v1663069719/icons/3rd_banner_mobile_1_rr3eb4.png'
            ]
        }
    },
    {
        subList: "Sub Categ",
        hookline: `One day, one day, one day, oh
        One day, one day, one day, oh`,
        hooklineDesc: `All my life I've been waitin' for
        I've been prayin' for
        For the people to say
        That we don't wanna fight no more
        There'll be no more war
        And our children will play`,
        gif: "https://res.cloudinary.com/dw5axbz64/image/upload/v1662710892/special%20product/pork%20sinigang/Pork_Sinigang_GIF_Square_gyus76.gif",
        banners: {
            desktop: "https://res.cloudinary.com/dw5axbz64/image/upload/v1663070379/icons/banner-desktop-3_ITEMS_tkiscv.png",
            mobile: [
                'https://res.cloudinary.com/dw5axbz64/image/upload/v1662980849/icons/banner-01_wrcy41.jpg',
                'https://res.cloudinary.com/dw5axbz64/image/upload/v1662980849/icons/banner-02_ggtgya.jpg',
                'https://res.cloudinary.com/dw5axbz64/image/upload/v1663069719/icons/3rd_banner_mobile_1_rr3eb4.png'
            ]
        }
    },
    {
        subList: "",
        gif: "",
        hookline: "",
        hooklineDesc: "",
        banners: {
            desktop: "",
            mobile: [
                '',
                '',
                ''
            ]
        }
    }
]