//  * File Name                : Collection
//  * File Directory           : src/components/Misc/RecipePage/Components/Collection
//  * Module                   : Collection
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In					        : src/Recipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <Collection/>
 *
 *******************************************************************************************/

// import react library
import React, { useEffect, useState } from "react";
// import categoryList
import { categoryList } from "../recipeList";

// collection component displayed on recipe.js Main Page
const Collection = () => {
  // isMobile
  const isMobile = localStorage.getItem("isMobile") === "true"
  // set max item to display 
  const [maxItem, setMaxItem] = useState(5)
  // declare maxed state to toggle if to display is more or less
  const [maxed, setMaxed] = useState(false)
  // set number of items to display upon click More/Less
  const [numberOfItems, setNumberOfItems] = useState()
  // set index of how many times you click
  const [index, setIndex] = useState(0)
  // set visible data to display
  const [visibleData , setVisibleData] = useState([])
  // visible data length
  const visibleDataLength = visibleData.length
  // declare action state for more/less function
  const [action,setAction] = useState("")
  // declare category length
  const categoryLength = categoryList.length

  
  // useEffect for Show More/Less function
  useEffect(() => {
    // for mobile show no limit
    if (isMobile) {
      setMaxItem(categoryLength)
    }else{
      setMaxItem(5)
    }
    setNumberOfItems(maxItem * ( index + 1 )) ;
    // data container to update visible data state
    const newArray = []; 
    let i = 0
    for( i ; i < categoryLength ; i++ ){
      if(i < numberOfItems) 
          // add data and set it to visible data
          newArray.push(categoryList[i])
    }
    // setting visible datas
    setVisibleData(newArray);
    if (numberOfItems >= categoryLength) {
      // set max if number of items is greater or equal to main list
      setMaxed(true)
    }else{
      setMaxed(false)
    }
  }, [index, numberOfItems, categoryLength])


  // use effect for show more/less
  useEffect(()=>{
    if(categoryLength > 0){
        if(maxed){
            if(categoryLength <= maxItem){
                setAction("")
            }else{
                setAction("Less")
            }
        }else{
          setAction("More")
        }
    }
  },[maxed,categoryLength ,maxItem])


  // onClick function for Show More/Less
  const onClickLoad = () => {
    if (numberOfItems > categoryLength) {
      // set to default value 0
      setNumberOfItems(0)
      setIndex (0)
    }else{
      // add 1 on index
      setIndex ( index + 1 )
    }
  }


  // Render all Categories and called it Collection
  const renderCollection = visibleData.map((col) => {
    // declare name variable
    let collection_category = col.categoryName
    // declare description variable
    let collection_description = col.categoryDescription
    // declare image variable
    let collection_image = col.categoryImage

    const onCollectionClick = () => {
      // set collection data to string
      const collection = JSON.stringify(col)
      // set collection in Local Storage 
      localStorage.setItem("collection", collection)
      // set category name in Local Storage
      localStorage.setItem("category", collection_category)
      // redirect to collections page
      window.location.href = `/ContentPage/recipe/collection/ ${collection_category}`
    }
    
    if(isMobile){
      return (
        <div key={col.categoryName}  onClick={onCollectionClick} className={`collection-mobile centeredItem`}>
          <div className="collection-overlay-wrapper br-20">
            <div className="collection-overlay br-20"></div>
            <img className="suggested-collection-image br-20" src={collection_image} alt={collection_category} />
            <div className="collection-sliding-desc">
              <div className="row">
                  <p className="collection-text">{collection_category}</p>
              </div>
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div key={col.categoryName} className="collection-content centeredItem">
          <div className="collection-overlay-wrapper br-20">
            <div className="collection-overlay br-20"></div>
            <img className="collection-image br-20" src={collection_image} alt={collection_category} />
            <div className="collection-sliding-desc">
              <div className="row">
                  <p className="collection-text">{collection_category}</p>
              </div>
              <div className="row centeredItem collection-desc">
                  <p className="centeredText">{collection_description}</p>
              </div>
              <div className="row">
                  <button className="centeredItem btn btn-sm btn-warning collection-text-btn" onClick={onCollectionClick}>View</button>
              </div>
            </div>
          </div>
        </div>
      )
    }
  })



  return(
    <div className="container">
        <div className="row collection-margin marginLR">
          <div className="collection collection-m">
              <h2>Collection</h2>
              <div className={`${isMobile ? "collection-mobile-flex" : "collection-image-grid"}`}>
                {renderCollection}
              </div>
              {
                isMobile ?
                "" :
                <div className="mt-20 centeredItem show-more-btn">
                  <p className="show-more" onClick={onClickLoad}> {action} </p>
                </div>
              }
              {visibleDataLength >= 1 ? null : <p className="centeredText">No Available Collection</p>}
          </div>
        </div>
    </div>
  )
}

export default Collection