/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ProductCarousel
 * File Directory           : src/components/Shared/ItemCardv2
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/ProductCarousel.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for Product Image
 *
 * #How to call:
 *    <ProductCarousel/>
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
//react slick carousel  //reference https://www.freakyjolly.com/react-slick-carousel-with-custom-navigation-and-lazy-loaded-images-in-slider/
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'pointer',
    },
    width: {
        width: "90%",
    }
}

const ProductCarousel = ({ images }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);

    useEffect(() => {
        setNav1(slider1);
        setNav2(slider2);
    });


    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        lazyLoad: 'ondemand',
        asNavFor: '.slider-nav',
        adaptiveHeight: true
    };

    const settingsThumbs = {
        slidesToShow: isMobile ? images?.length > 3 ? 3 : images?.length : images?.length > 4 ? 4 : images?.length,
        autoplay: true,
        autoplaySpeed: 3500,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        centerMode: false,
        swipeToSlide: true,
        focusOnSelect: true,
        mobileFirst: true,
        variableWidth: true,
        lazyLoad: 'ondemand',
    };

    return (
        <div className="row slider-wrapper">
            <div className="col-12 pt-3 pb-2 main-image w-100 HoverDiv">
                <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={slider => (setSlider1(slider))}
                >
                    {images?.map((slide, index) =>
                        <div className="slick-slide-main " key={index}>
                            <img className="slick-slide-image-main img img-fluid h-75" alt={`image_${index}`} src={slide} />
                        </div>
                    )}
                </Slider>
            </div>
            <div className={`col-12 w-100 pb-3 pt-1 text-center ${images?.length > 1 ? '' : "d-none"} col-slick-slider`} style={style.center}>
                <div className="thumbnail-slider-wrap" style={style.width} >
                    <Slider
                        {...settingsThumbs}
                        asNavFor={nav1}
                        ref={slider => (setSlider2(slider))}>
                        {images?.map((slide, index) =>
                            <div className="slick-slide border border-secondary thumbnail" key={index}>
                                <img className="slick-slide-image img img-fluid" id={slide} alt={`image_${index}`} src={slide} />
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
}





export default ProductCarousel;