/*******************************************************************************************
* File Name                : UserProfile
* File Directory           : src/components/Customer/userProfile
* Module                   : Customer and order history
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Customer and order history.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   UserProfile        -   component for Registration for new customers
 *
 * #How to call:
 *    <UserProfile/>
 *
 * Note: Still working on the ff:
 * - When success should be auto Customer and order history (Customer and order history is still ongoing)
 * 
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import Home_api from '../APIs/Home';
import Header from "../Shared/Header";
import ProductHistory from "./productHistory";
import PaymentModal from "../Modal/PaymentModal";
import ReorderModal from "../Modal/ReorderModal";
import { useHistory } from 'react-router-dom';
import DesktopHeader from '../Shared/DesktopHeader';
import { useDispatch } from "react-redux";
import SideNav from "./SideNav";
import { Accordion, Button, Modal } from 'react-bootstrap'
import FeedBack from '../Shared/FeedBack';
import LoadingModal from '../Modal/LoadingModal'
import ReactGA from "react-ga4"
import { trackShipment } from "../APIs/Shipmates";

const styles = {
    header: {
        backgroundColor: 'white',
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    card: {
        backgroundColor: '#F5F5F8',
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    headerBG: {
        'backgroundColor': '#facc02'
    },
    userDetails: {
        width: '80%',
        display: 'inline-table'
    },
    txtCenter: {
        'textAlign': 'center',
    },
    textRight: {
        textAlign: 'right'
    }
}
const UserProfile = () => {

    document.title = "Zagana | Customer Profile"
    const isMobile = localStorage.getItem("isMobile") === "true"
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const [orders, setOrders] = useState([]); // for mapping myorders
    const [orderId, setOrderId] = useState();
    const history = useHistory()
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    let body = document.body; // for setting body height (modal)
    const [reorderModalStatus, setReorderModalStatus] = useState(false)
    const [reorderDetail, setReorderDetail] = useState([])
    const [outOfStockItems, setOutOfStockItems] = useState([])
    const [loading, setLoading] = useState(true)
    let accessKey = localStorage.getItem('accessKey')
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();

    //toggle modal and body scroll
    const closeLoginModal = () => {
        setVisibleLogin("true");
        body.classList.remove("body_class");
        body.classList.remove("body_class_");
    }
    // console.log(orders);

    if (getUser === "") {
        history.push("/");
    }


    //reorderAPIcall
    const reorderFunction = async (id) => {
        // console.log(id)
        const response = await Home_api.post('/reorder', { customer_id: getUser.customer_id })
        // const customer = response.data.order || []
        // setOrders(customer)
    }
    //fetching my orders from database
    useEffect(() => {
        const myOrders = async () => {
            const response = await Home_api.post('/myorders', { customer_id: getUser.customer_id })
            const customer = response.data.order || []
            setOrders(customer)
            setLoading(false)
        }
        myOrders()
    }, [])

    const trackDelivery = async (tracking_number) => {
        let result = await trackShipment(tracking_number)
        if (result.data) {
            setModalContent(
                <div>
                    <div className="row">
                        <div className="col-12">
                            <p>Tracking Number: {result.data?.tracking_number}</p>
                        </div>
                        <div className="col-12">
                            <p>Status: {result.data?.courier_status}</p>
                        </div>
                        {
                            result.data?.tracking_url &&
                            <div>
                                <a href={result.data?.tracking_url} target="_blank" rel="noreferrer">Click here to track your order</a>
                            </div>
                        }
                        {/* <div className="col-12">
                            <p>Driver Info: </p>
                        </div> */}
                    </div>
                </div>
            )

        }
        setShowModal(!showModal);
    }
    const handleModal = () => {
        setShowModal(!showModal);
    };
    //map from database + orders cards
    const renderedOrders = orders.map((option, index) => {
        console.log(option)
        let rateOrder = option.Rate_Order;
        let rider_info = {}
        if (typeof option.rider_details === 'object')
            rider_info = option.rider_details
        return (
            <div className="col-sm-12 col-md-6 col-lg-6">
                <Accordion>
                    <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                            <div className="col" style={{ fontSize: '14px' }} >
                                <b>Order:</b> &nbsp; {option.order_number}
                            </div>
                            {option.order_status === 'For Payment' ?
                                <div className="col-5 col-md-3 col-lg-4 p-1">
                                    <PaymentModal
                                        id={option.order_number}
                                        payment={option.order_paymentMethod}
                                        orderTotal={option.order_totalAmount}
                                        orderID={option.order_id}
                                        orderNumber={option.order_number}
                                        orderItem={option.order_cartItems}
                                        orderDiscountName={option.order_discountName}
                                    />
                                </div>
                                :
                                <div className="col">
                                    <b>Status:</b> &nbsp; {option.order_status}
                                </div>
                            }
                        </Accordion.Header>
                        <Accordion.Body>
                            <p className="p-0 m-0"><b>Date: </b><i>{option.order_createdDate}</i></p>
                            <p className="p-0 m-0"><b>Courier: </b><i>{option.shipping_carrier}</i></p>
                            <p className="p-0 m-0"><b>Courier-tracking: </b>
                                {
                                    rider_info?.borzo_delivery_tracking_url ? <a target="_blank" href={rider_info?.tracking_number}>Track Delivery</a> :
                                        <button className="btn btn-primary" onClick={() => { trackDelivery(rider_info?.tracking_number) }} >Track Delivery</button>
                                }
                            </p>
                            <div>
                                <div className="row">
                                    <div className="col">
                                        <b>Products</b>
                                    </div>
                                    <div className="col" style={styles.txtCenter} >
                                        <b>Quantity</b>
                                    </div>
                                    <div className="col" style={styles.txtCenter} >
                                        <b>Price</b>
                                    </div>
                                </div>
                                <div className="row">
                                    <ProductHistory order_id={option.order_id} order_status={option.order_status} Rate_date={option.Rate_date} />
                                    <div className="col">

                                        {option.order_status === 'For Payment' ? "" :
                                            <div className="">
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={(async () => {
                                                        ReactGA.event({
                                                            category: "Re-order",
                                                            action: 'Re-order',
                                                            label: option.order_id
                                                        });
                                                        // let items = await Home_api.post('/fetchOutOfStock', { 
                                                        //     access_key: accessKey,
                                                        //     order_cartItems:option.order_cartItems,
                                                        //     branch_id:option.order_branchId
                                                        // })
                                                        let response = await Home_api.post('/reorder', {
                                                            access_key: accessKey,
                                                            order_id: option.order_id
                                                        })
                                                        if (response?.data?.result === "Success")
                                                            history.push("/checkout-details");
                                                        // history.push("/#cart");
                                                        // if(items.data.products)
                                                        // {

                                                        //     // setReorderModalStatus(true)
                                                        //     // setReorderDetail(option)
                                                        //     setOutOfStockItems(items.data.products)
                                                        // }
                                                        // reorderFunction(option)
                                                    })}
                                                > Re-Order
                                                </button>
                                            </div>}
                                    </div>
                                    <div style={styles.textRight}>
                                        <b>Discount: </b> {option.order_discountAmount}
                                        <br />
                                        <b>Shipping: </b> {option.order_shippingFee}
                                        <br />
                                        <b>Total: </b> {option.order_totalAmount}
                                    </div>
                                </div>
                                {option.order_status === "Order fulfilled" && option.Rate_Order !== null ?
                                    <div className="row">
                                        <b>Your Feedback:</b>
                                        <p style={{ "width": "10%" }}>Rate:
                                        </p>
                                        <div className="ratings">
                                            <label htmlFor="star5"
                                                className={`${option.Rate_Order >= 5 ? 'bgYellow' : ''}`}
                                            ></label>
                                            <label htmlFor="star4"
                                                className={`${option.Rate_Order >= 4 ? 'bgYellow' : ''}`}></label>
                                            <label htmlFor="star3"
                                                className={`${option.Rate_Order >= 3 ? 'bgYellow' : ''}`}></label>
                                            <label htmlFor="star2"
                                                className={`${option.Rate_Order >= 2 ? 'bgYellow' : ''}`}></label>
                                            <label htmlFor="star1"
                                                className={`${option.Rate_Order >= 1 ? 'bgYellow' : ''}`}></label>
                                        </div>
                                        {option.Feed_Back !== "" ? <p>Comments <br />{option.Feed_Back}</p> : null}
                                    </div>
                                    :

                                    ""
                                }
                                {option.order_status === "Order fulfilled" && option.Rate_Order === null ?

                                    <FeedBack order_id={option.order_id} forForm="show" />
                                    :
                                    ""
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div >
            // <div className="col-sm-12 col-md-12 col-lg-6 " key={index}>
            //     <div className="card mb-2" key={index}>
            //         <div className="card-body">
            //             <h5>Order Details</h5>
            //             <hr />
            //             <div className="row">
            //                 <div className="col-7 col-md-9 col-lg-8">
            //                     <b>Order: </b> {option.order_number}
            //                 </div>
            //                 {option.order_status === 'For Payment' ?
            //                     <div className="col-5 col-md-3 col-lg-4">
            //                         <PaymentModal
            //                             id={option.order_number}
            //                             payment={option.order_paymentMethod}
            //                             orderTotal={option.order_totalAmount}
            //                             orderID={option.order_id}
            //                         />
            //                     </div>
            //                     :
            //                     null
            //                 }
            //             </div>
            //             <br />
            //             <b>Status: </b> {option.order_status}
            //             <br />
            //             <b>Date: </b><i>{option.order_createdDate}</i>
            //             <br />
            //             <br />
            //             <div>
            //                 <div className="row">
            //                     <div className="col">
            //                         <b>Products</b>
            //                     </div>
            //                     <div className="col" style={styles.txtCenter} >
            //                         <b>Quantity</b>
            //                     </div>
            //                     <div className="col" style={styles.txtCenter} >
            //                         <b>Price</b>
            //                     </div>
            //                 </div>
            //                 <ProductHistory order_id={option.order_id} />
            //                 <div style={{ 'float': 'right' }}>
            //                     <b>Discount: </b> {option.order_discountAmount}
            //                     <br />
            //                     <b>Shipping: </b> {option.order_shippingFee}
            //                     <br />
            //                     <b>Total: </b> {option.order_totalAmount}
            //                 </div>
            //             </div>
            //         </div>
            //     </div >

            // </div >

        )
    });
    return <React.Fragment>
        {isMobile ?
            // added conbdition if in on mobile
            <Header title="Order History" />
            :
            <div style={{ position: 'fixed', zIndex: '999' }} className="p-0" >
                <DesktopHeader
                    visibleLogin={visibleLogin}
                    setVisibleLogin={setVisibleLogin} />
            </div>}



        {

            getUser ?
                // added conbdition if user is logged in
                <div className={isMobile ? null : "mt-5 pt-5"}>
                    <div className="row">
                        {!isMobile ?
                            <div className="col-3 sideNav_OrderAdd col-sm-12 col-lg-3">
                                <SideNav />
                            </div>
                            :
                            null
                        }
                        <div className={isMobile ? 'col-12 pb-3' : 'col-9 col-sm-9 pb-3'}>
                            <h3>Order history</h3>
                            <div className="row">
                                {renderedOrders.length > 0 ?
                                    <>
                                        {renderedOrders}
                                        <ReorderModal
                                            reorderModalStatus={reorderModalStatus}
                                            setReorderModalStatus={setReorderModalStatus}
                                            reorderDetail={reorderDetail}
                                            setReorderDetail={setReorderDetail}
                                            outOfStockItems={outOfStockItems}
                                            setOutOfStockItems={setOutOfStockItems}
                                        />
                                    </>
                                    :
                                    //added else if no order history
                                    <center>
                                        <i> No order history </i>

                                    </center>
                                }
                                { }
                            </div>
                            <Modal show={showModal} onHide={handleModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Track Shipment</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {modalContent}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleModal}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={handleModal}>
                                        Save Changes
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                </div>
                : ''
        }
        <LoadingModal
            modalStatus={loading}
        />
    </React.Fragment>
}
const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal
    }
}

export default UserProfile;
