import React, { useState, useEffect } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import Carousel from "../Shared/Carousel"
import './Category.scss';
import LinkIcons from '../Shared/Links/LinkIcons'
import Home_api from '../APIs/Home';
import ItemList from "./ItemList";
import Header from "../Shared/Header";
import CartDetails from "../Shared/CartDetails";
import Login from '../Login/Login';
import Register from '../Login/Register';
import Cart from './Cart';
import loginLogo from "../../assets/logo/zagana-logo.png";
import { useHistory } from 'react-router-dom';
import { connect, useDispatch } from "react-redux";
import { forgotModal } from "../../actions";
import ForgotPass from "../Login/ForgotPass";
import SortBy from './FilterList';
import { v4 as uuidv4 } from 'uuid';
const style = {
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'center'
    },
    btnClear: {
        position: 'absolute',
        top: '8px',
        right: '35px',
    }
}

let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const Home = ({ showModal }) => {
    document.title = "Zagana | Farm to Kitchen";
    const [scrollPosition, setScrollPosition] = useState(0);
    const [viewing, setViewing] = useState('grid')
    const [items, setItems] = useState([]);
    const [cartVDetails, setCartVDetails] = useState(defaultItem);
    const [cartVChange, setCartVChange] = useState(0);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [selected, setSelected] = useState('');
    const [branch, setBranch] = useState(null);
    const [label, setLabel] = useState('Category');
    const [active, setActive] = useState('');
    const [slide, setSlide] = useState('firstSlide');
    const [term, setTerm] = useState('');// search for desktop
    const [selectedCity, setSelectedCity] = useState({});// cart for desktop
    const [cart, setCart] = useState([]);// cart for desktop
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [visibleCart, setVisibleCart] = useState(true); // show modal cart
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal
    let cartModalShow = `modal cart ${visibleCart === true ? 'showModal' : ''}`; // class for cart show modal
    let body = document.body; // for setting body height (modal)
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    const isMobile = localStorage.getItem("isMobile") === "true";
    const [imgSlide, setImgSlide] = useState([]); // for mapping banner
    const history = useHistory();
    const dispatch = useDispatch()
    let heightPadding;
    if (isMobile) {
        heightPadding = 0;
    }
    else {
        heightPadding = 160;
    }


    useEffect(() => {
        ReactPixel.pageView();


        const banner = async () => {
            const response = await Home_api.get('/banner')
            const bannerResult = response.data.banner || []
            setImgSlide(bannerResult)

        }
        banner()


        const category = async () => {
            const response = await Home_api.get('/premium/categoryList')
            // const item
            const itemResult = response.data.category_list || []
            setItems(itemResult)
        }

        category()
        // let city = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 3 }
        // setBranch(city)
        setTerm("");

        //dbStorage
        // function uuidv4() {
        //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //         return v.toString(16);
        //     });
        // }
        let link = uuidv4();
        let accessKey = localStorage.getItem('accessKey')
        const accessStorage = async (status) => {
            await Home_api.post('/accessStorage', { accessToken: link })
            localStorage.setItem('accessKey', link)
            if (status === 'tokenNotFound') {
                window.location.reload()
            }
        }
        if (!accessKey) {

            if (localStorage.getItem('branchSelected') === true) {
                accessStorage('tokenNotFound')
            } else {
                accessStorage()
            }
        } else {
            const getCart = async () => {
                let response = await Home_api.post('/cartChecker', { accessToken: accessKey })
                if (response.data.result === "AccessTokenNotFound") {
                    localStorage.setItem('branchSelected', false)
                    accessStorage('tokenNotFound')
                }
                else {
                    let itemsOnCart = response.data.cart || []
                    setCart(response.data.cart || [])
                    setOnCartLoaded(true)
                    if (response.data.selectedCity === null) {
                        localStorage.setItem('branchSelected', false)
                        window.location.reload()
                    } else {
                        localStorage.setItem('branchSelected', true)
                        setBranch(response.data.selectedCity || [])
                    }
                }
            }
            getCart()
        }

        // setCart(JSON.parse(localStorage.getItem('cart')));
    }, [])

    const handleScroll = () => {

        //scroll effects / getting Y axis 
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    const clearSearch = () => {
        setTerm('')
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        //setting elements by id
        let sale_element = document.getElementById('h_sale') || '',
            best_element = document.getElementById('h_recommended') || '',
            v_element = document.getElementById('h_vegetables') || '',
            f_element = document.getElementById('h_fruits') || '',
            m_element = document.getElementById('h_meats') || '',
            sea_element = document.getElementById('h_seafoods') || '',
            poul_element = document.getElementById('h_poultry') || '',
            rice_element = document.getElementById('h_rice') || '',
            egg_element = document.getElementById('h_dairy&eggs') || '',
            herbs_element = document.getElementById('h_herbs&spices') || '',
            bundles_element = document.getElementById('h_bundles') || '',
            frozen_element = document.getElementById('h_frozenfood') || '',
            desserts_element = document.getElementById('h_desserts') || '',
            bev_element = document.getElementById('h_beverages') || '',
            nextday_element = document.getElementById('h_nextdaydelivery') || '',
            more_element = document.getElementById('h_more') || ''




        //added height upon scroll
        let sales_height = sale_element.offsetHeight + heightPadding;
        let best_height = sales_height + best_element.offsetHeight;
        let fruits_height = (best_height + f_element.offsetHeight);
        let veg_height = fruits_height + v_element.offsetHeight;
        let meat_height = veg_height + m_element.offsetHeight;
        let sea_height = meat_height + sea_element.offsetHeight;
        let poul_height = sea_height + poul_element.offsetHeight;
        let rice_height = poul_height + rice_element.offsetHeight;
        let eggs_height = rice_height + egg_element.offsetHeight;
        let herbs_height = eggs_height + herbs_element.offsetHeight;
        let bundles_height = herbs_height + bundles_element.offsetHeight;
        let frozen_height = bundles_height + frozen_element.offsetHeight;
        let desserts_height = frozen_height + desserts_element.offsetHeight;
        let bev_height = desserts_height + bev_element.offsetHeight;
        let nextday_height = bev_height + nextday_element.offsetHeight;
        let more_height = nextday_height + more_element.offsetHeight;


        // Condition to show selected categorya
        if (scrollPosition <= sale_element.offsetHeight) {

            setLabel('Sale')
            setActive('Sale')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= best_height) {

            setLabel('Recommended')
            setActive('Recommended')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= fruits_height) {

            setLabel('Fruits')
            setActive('Fruits')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= veg_height) {
            setLabel('Vegetables')
            setActive('Vegetables')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= meat_height) {
            setLabel('Meats')
            setActive('Meat')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= sea_height) {
            setLabel('Seafoods')
            setActive('Seafoods')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= poul_height) {
            setLabel('Poultry')
            setActive('Poultry')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= rice_height) {
            setLabel('Rice')
            setActive('Rice')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= eggs_height) {
            setLabel('Dairy & Eggs')
            setActive('Dairy & Eggs')
            setSlide('secondSlide')
        }
        else if (scrollPosition <= herbs_height) {
            setLabel('Herbs & Spices')
            setActive('Herbs & Spices')
            setSlide('secondSlide')
        }
        else if (scrollPosition <= bundles_height) {
            setLabel('Bundles')
            setActive('Bundles')
            setSlide('secondSlide')
        }
        else if (scrollPosition <= frozen_height) {
            setLabel('Frozen Food')
            setActive('Frozen Food')
            setSlide('secondSlide_last')
        }
        else if (scrollPosition <= desserts_height) {
            setLabel('Desserts')
            setActive('Desserts')
            setSlide('secondSlide_last')
        }
        else if (scrollPosition <= bev_height) {
            setLabel('Beverages')
            setActive('Beverages')
            setSlide('secondSlide_last')
        }
        else if (scrollPosition <= nextday_height) {
            setLabel('Next day delivery')
            setActive('Next day delivery')
            setSlide('secondSlide_last')
        }
        else if (scrollPosition <= more_height) {
            setLabel('More')
            setActive('More')
            setSlide('secondSlide_last')
        }




        return () => {
            window.removeEventListener('scroll', handleScroll);

        };
    }, [scrollPosition]);

    //toggle modal and body scroll
    const closeLoginModal = () => {
        setVisibleLogin("true");
        body.classList.remove("body_class");
        body.classList.remove("body_class_");
    }

    useEffect(() => {
        if (showModal) {
            dispatch(forgotModal(true))
        }
    }, [showModal])
    useEffect(() => {
    }, [cart])

    return (
        <React.Fragment>
            <div className="w-100 m-0 p-0">
                <div className="w-100 m-0 p-0 bg-light wholeHeader" style={{ 'position': "fixed", 'zIndex': '2' }}>
                    <Header
                        branch={branch}
                        setBranch={setBranch}
                        term={term}
                        total={total}
                        setTerm={setTerm}
                        visibleLogin={visibleLogin}
                        setVisibleLogin={setVisibleLogin}
                        visibleCart={visibleCart}
                        setVisibleCart={setVisibleCart}
                        body={body}
                    />

                    {isMobile === 'true' || isMobile ?
                        <div className="input-group p-4 pt-1 pb-1 searchForm">
                            <div className="input-group-prepend ">
                                <span className="input-group-text" id="basic-addon1" style={{ borderRadius: "50px 0px 0px 50px", padding: '0.29rem 0.85rem' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Search product by name" style={{ borderRadius: "0px 50px 50px 0px", height: '30px', fontSize: "13px" }} value={term} onChange={(e) => {
                                setTerm(e.target.value);
                                ReactPixel.track('Search', { search_string: e.target.value })
                            }} />
                            {term !== '' ? <button className="btn btn-primary-outline p-0" id="btn_clear" style={style.btnClear} onClick={clearSearch}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                </svg>
                            </button> : ''}
                        </div> : ''
                    }

                    {term === "" ? scrollPosition < 80 ?
                        <div id="firstContent">
                            <Carousel
                                imgSlide={imgSlide}
                            />

                            {/* <LinkIcons /> */}
                            <SortBy options={items}
                                selected={selected}
                                onSelectedChange={setSelected}
                                label={label}
                                active={active}
                                slide={slide}
                                viewing={viewing}
                                setViewing={setViewing}
                            />

                        </div>
                        : '' : ''
                    }
                    {term === "" ? scrollPosition > 80 ?
                        <SortBy options={items}
                            selected={selected}
                            onSelectedChange={setSelected}
                            label={label}
                            active={active}
                            slide={slide}
                            viewing={viewing}
                            setViewing={setViewing}
                        />
                        : ''
                        : ''
                    }
                </div>

                <CartDetails
                    total={total}
                    visibleLogin={visibleLogin}
                    setVisibleLogin={setVisibleLogin}
                    visibleCart={visibleCart} 
                    setVisibleCart={setVisibleCart} 
                />
                {!isLogged ?
                    <div>
                        <div id="LoginModal">
                            <div className={modalShow} tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body p-0 pt-2 mb-3">
                                            <button className="btn text-left" style={style.closeBtn} onClick={closeLoginModal}>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                                </svg>
                                            </button>
                                            <img src={loginLogo} alt="zagana.com" width="250px" className="mx-auto mt-3 mb-3 d-block" />
                                            {!showModal ?
                                                <Tabs
                                                    defaultActiveKey="login"
                                                    transition={false}
                                                    id="noanim-tab-example"
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="login" title="Login">
                                                        <Login />
                                                    </Tab>
                                                    <Tab eventKey="register" title="Register">
                                                        <Register />
                                                    </Tab>
                                                </Tabs>
                                                :
                                                <ForgotPass />
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}

                <div className={`premiumPage w-100 ${term === '' ? 'mt10' : 'mt5'}`}>
                    <ItemList options={items} search={term} total={total} selectedCity={selectedCity} setTotal={setTotal} branch={branch} onCartLoaded={onCartLoaded} cart={cart} setCart={setCart} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing} setViewing={setViewing} />
                    {/* <Search options={items} total={total} setTotal={setTotal} branch={branch}/> */}
                </div>
                <Cart cartModalShow={cartModalShow} setVisibleLogin={setVisibleLogin} cart={cart} setCart={setCart} visibleCart={visibleCart} setVisibleCart={setVisibleCart} total={total} setTotal={setTotal} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} />
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal
    }
}

export default connect(mapStateToProps)(Home);