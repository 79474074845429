import React, { useState } from 'react';
import { useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const AddedModal = ({ show }) => {
    const [modalShow, setModalShow] = useState(show);

    useEffect(() => {
        setModalShow(show)
    }, [show])

    const handleClose = () => setModalShow(false);

    return (
        <>
            <Modal size="sm" animation={false} show={modalShow} centered onHide={handleClose}>
                <Modal.Body className='w-25'>
                    Added to cart
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddedModal;