/*******************************************************************************************
* File Name                : Header
* File Directory           : src/components/Shared/Header
* Module                   : Header
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Header.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   Navbar        -   component for user navigation
 *
 * #How to call:
 *    <Header/>
 *
 * Note: Still working on the ff:
 * - 

 *******************************************************************************************/

 
 import debounce from 'lodash.debounce';
import React, { useCallback, useEffect, useState } from "react";
import { Dropdown } from 'react-bootstrap';
import ReactGA from 'react-ga4';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { forgotModal } from "../../actions";
import searchSvg from "../../assets/site_icons/Search.svg";
import Home from '../APIs/Home';
import SelectCity from "../Home/SelectCity";
import { searchToDatabase } from "./SaveToDatabase";
import ZaganaLogo from "./ZaganaLogo";
const style = {
    loginBtn: {
        color: '#222',
        display: 'inline-block!important',
        paddingLeft: '5%',
        cursor: 'pointer',
        position: 'absolute',
        right: '15px',
        top: '5px'
    },
    backBtn: {
        color: '#222',
        display: 'inline-block!important',
        paddingLeft: '5%',
        cursor: 'pointer'
    },
    headerBG: {
        'backgroundColor': '#facc02'
    },
    searchIcon: {
        cursor: 'pointer',
        borderRadius: '50px 0px 0px 50px',
        padding: '7px 10px',
        height: '32px'
    },
    inputRadius: {
        borderRadius: '0 50px 50px 0px',
        height: '32px'
    },
    rightIcons: {
        marginBottom: '0 !important',
        padding: '10px 0',
        display: 'inline-block!important'
    },
    btnClear: {
        position: 'absolute',
        right: '15px'
    },
    dropdownUser: {
        width: '30%',
        display: 'inline-block'
    },
    displayInline: {
        display: 'inline-block!important'
    },
    link: {
        textDecoration: 'none',
        color: '#222'
    }
}


const Header = ({ title, branch, setBranch, term, setTerm, setVisibleLogin, visibleLogin, visibleCart, setVisibleCart, total }) => {
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let source = sessionStorage.getItem('source')||''
    let body = document.body; // for setting body height (modal)
    const [totals, setTotals] = useState({ itemCount: 0, itemTotal: 0 })
    const isMobile = localStorage.getItem("isMobile") === "true";
    const history = useHistory()
    let hashChecker = window.location.pathname;
    const [selected, setSelected] = useState('')
    sessionStorage.setItem('reviewPolicy', getUser.review_policy || "No")
    const searchHandle = event => {
        setTerm(event.target.value);
        searchToDatabase(event.target.value)
    };
    const debouncedChangeHandler = useCallback(
        debounce(searchHandle, 250)
        , []);
    const dispatch = useDispatch()
    const historyBack = () => {
        window.history.back();
    }
    const clearSearch = () => {
        setTerm('');
        document.getElementById("searchInputs").value = "";
    }
    useEffect(() => {
        setTotals(total || { itemCount: 0, itemTotal: 0 })
    }, [total])
    const signOut = async () => {
        
        ReactGA.event({
            category: 'Logout',
            action: 'Logout User',
        });
        sessionStorage.removeItem('Login');
        localStorage.removeItem('cart');
        localStorage.removeItem('SelectedCity');
        localStorage.removeItem('deliveryDate');
        localStorage.removeItem('deliveryTime');
        localStorage.removeItem('Address');
        localStorage.removeItem('skip');
        localStorage.setItem('branchSelected',false)
        //dbStorage
        // function uuidv4() {
        //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //         return v.toString(16);
        //     });
        // }
        let link = uuidv4();
        let accessKey = localStorage.getItem('accessKey')
        let Login = sessionStorage.getItem('Login');
        await Home.post('/accessStorage', { accessToken: link })
        localStorage.setItem('accessKey', link)
        window.location.reload();
    };

    //toggle modal 
    const openLoginModal = () => {
        setVisibleLogin("false");
        let element = document.getElementById("modalLoginID");
        element?.classList?.remove("showModal");
        body.classList.add("body_class_");
        dispatch(forgotModal(false))
    }
    const openCartModal = () => {
        setVisibleCart(!visibleCart);
        if (source === "Tiktok") {
            window.ttq.track('ViewContent',{
                content_type: "product",
                content_name: "View Cart",
                description: "View Cart"
            })
        }
    }
    const onClickMyOrders = () => {
        setSelected('orders')
        history.push('/user-profile')
    }
    const onClickMyAccount = () => {
        setSelected('account')
        history.push('/my-account')
    }
    const onClickMyAddress = () => {
        setSelected('address')
        history.push('/my-directions')
    }
    return (
        <React.Fragment>
            <div className="row justify-content-md-center m-0 pc_nav sss" style={style.headerBG} >

                <div className="col-3 p-0 d-block d-sm-none">
                    {/* condition for back button, if true backbutton will show else hamburger */}
                    {title === "Address" || title === "Delivery Schedule" || title === "Payment Method" || title === "Address Book" || title === "Voucher" || title === "My Account" || title === "Order History" || title === "About us" || title === "FAQs"
                        || title === "Blogs" || title === "Privacy Policy" || title === "Terms &amp; Condition" || title === "Referral" || title === "Recipe"
                        
                        ?
                        <span id="arrowBack" onClick={historyBack} style={style.backBtn}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                            </svg>
                        </span>
                        :
                        <div>

                        </div>
                    }

                </div>
                <div className="col-2 d-none d-sm-block text-center">
                    <a href="/">
                        <ZaganaLogo />
                    </a>
                </div>
                {/* Search input */}
                <div className={`col-4 d-sm-block my-auto search_pc pt-1 ${isMobile ? 'd-none' : ''}`}>

                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1" style={style.searchIcon}>
                                <img src={searchSvg} width="12" alt="Search" />
                            </span>
                        </div>
                        <input type="text" id="searchInputs" className="searchInput_ form-control" placeholder="Search" style={style.inputRadius}
                            // value={term} 
                            onChange={debouncedChangeHandler}

                        />
                        {term !== '' ? <button className="btn btn-primary-outline p-0" id="btn_clear" style={style.btnClear} onClick={clearSearch}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                            </svg>
                        </button> : ''}
                    </div>

                </div>

                {/* Select City option */}
                {isMobile ?
                
                    <div className="col-6 m-l pt-2 pb-2 m-logo"  >
                        <a href="/">
                            <img id="mobileLogo" src="/static/media/zagana-logo.d9913959.png" alt="zagana.com" />
                        </a>
                    </div>
                    : ""
                }
                {!(hashChecker).includes("premium") ?
                    <div className="col-6 col-md-4 pt-1 pb-1 btn_cityy my-auto"  >
                        {title ? <h1>{title !== "Blogs" ? title : ""}</h1> :
                            <SelectCity branch={branch} setBranch={setBranch} />

                        }
                    </div>
                    :
                    <div className="col-6 col-md-4 pt-1 pb-1 btn_cityy my-auto"></div>
                }

                {/* Condition for user to show login link or customer profile */}
                <button id="cartModalMobile" onClick={openCartModal} style={style.backBtn} className="d-none btn btn-transparent p-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                        <g id="Layer_2" data-name="Layer 2"><g id="Layer_9" data-name="Layer 9"><path className="cls-1" d="M143.28,118.5,36.53,119.56a10,10,0,0,1-9.61-6.78l-21-61.57A16.25,16.25,0,0,1,21.25,29.72H168.38Z" /><line className="cls-1" x1="143.28" y1="116.28" x2="175.22" y2="6.14" /><circle className="cls-1" cx="49.36" cy="158.79" r="12.08" /><circle className="cls-1" cx="114.34" cy="158.79" r="12.08" /><path className="cls-1" d="M38,119.56l92.13-1.06c7.44,0,13.16,7.1,13.16,14.55h0a13.61,13.61,0,0,1-13.45,13.66H49.36" /><line className="cls-1" x1="175.6" y1="5" x2="192.64" y2="5" /></g></g></svg>

                    {
                        totals.itemCount > 0 ?
                            <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{totals.itemCount}</div> : ''
                    }
                </button>
                {!getUser ?
                    <div className="col-1 text-center my-auto cart_logg" >
                        {isMobile ?
                            "" :
                            <button onClick={openCartModal} style={style.backBtn} className="d-none d-sm-inline-block btn btn-transparent p-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_9" data-name="Layer 9"><path className="cls-1" d="M143.28,118.5,36.53,119.56a10,10,0,0,1-9.61-6.78l-21-61.57A16.25,16.25,0,0,1,21.25,29.72H168.38Z" /><line className="cls-1" x1="143.28" y1="116.28" x2="175.22" y2="6.14" /><circle className="cls-1" cx="49.36" cy="158.79" r="12.08" /><circle className="cls-1" cx="114.34" cy="158.79" r="12.08" /><path className="cls-1" d="M38,119.56l92.13-1.06c7.44,0,13.16,7.1,13.16,14.55h0a13.61,13.61,0,0,1-13.45,13.66H49.36" /><line className="cls-1" x1="175.6" y1="5" x2="192.64" y2="5" /></g></g></svg>

                                {
                                    totals.itemCount > 0 ?
                                        <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{totals.itemCount}</div> : ''
                                }
                            </button>
                        }
                        <a onClick={openLoginModal} style={style.backBtn} className="d-none d-sm-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                            </svg>
                        </a>

                        {/* {isMobile ?
                                <a href="/login" style={style.loginBtn}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#222" viewBox="0 0 197.64 175.87" fill="none" stroke="#222" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                        <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                                    </svg>
                                </a> :
                                ''
                            } */}

                    </div>
                    :
                    <div className="col-1 text-center my-auto cart_logg" style={style.rightIcons} >
                        {isMobile ?
                            <button onClick={openCartModal} style={style.backBtn} className="openCart d-none d-sm-inline-block btn btn-transparent p-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f4cc0e" className="bi bi-cart2" viewBox="0 0 16 16">
                                    <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                </svg>
                                {
                                    totals.itemCount > 0 ?
                                        <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{totals.itemCount}</div> : ''
                                }
                            </button> :
                            <button onClick={openCartModal} style={style.backBtn} className="openCart d-none d-sm-inline-block btn btn-transparent p-0">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f4cc0e" className="bi bi-cart2" viewBox="0 0 16 16">
                                    <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                </svg>
                                {
                                    totals.itemCount > 0 ?
                                        <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{totals.itemCount}</div> : ''
                                }
                            </button>
                        }
                        {isMobile ?
                            ""
                            // <a href="/user-profile" style={style.loginBtn}>
                            //     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#222" viewBox="0 0 197.64 175.87" fill="none" stroke="#222" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                            //         <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                            //     </svg>
                            // </a>
                            :
                            <div className="userDropdown d-none d-sm-inline-block p-0" style={style.dropdownUser}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="default" id="dropdown-basic" className="p-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                            <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item style={{ 'display': 'block !important' }} onClick={onClickMyAccount} >{getUser.customer_name}</Dropdown.Item>
                                        <Dropdown.Item style={{ 'display': 'block !important' }} onClick={onClickMyAccount} > My Account</Dropdown.Item>
                                        <Dropdown.Item style={{ 'display': 'block !important' }} onClick={onClickMyOrders}> My Orders</Dropdown.Item>
                                        <Dropdown.Item style={{ 'display': 'block !important' }} onClick={onClickMyAddress}> My Address</Dropdown.Item>
                                        <Dropdown.Item style={{ 'display': 'block !important' }} onClick={signOut} >Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        }
                    </div>
                }
            </div>

        </React.Fragment>
    )

};


export default Header;
