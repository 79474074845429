/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : Account
 * File Directory           : src/components/Customer/Account
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : update customer info
 * Used In					        : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Address        -   component for updating customer info
 *
 * #How to call:
 *    <Account/>
 *
 *******************************************************************************************/

import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { initialCustInfo } from "../../actions"
import Header from "../Shared/Header";
import ZaganaLogo from '../Shared/ZaganaLogo';
import Home from "../APIs/Home";
import SuccessModal from "../Modal/SuccessfulModal";
import CookieModal from "../Modal/CookieModal";
import DisableAccountModal from "./DisableAccountModal";
import SideNav from "./SideNav";

const styles = {
    height: {
        minHeight: '60vh'
    },
    userDetails: {
        width: '80%',
        display: 'inline-table',
        marginLeft: '5rem'
    },
    inputIcon: {
        borderRadius: '5px 0px 0px 5px',
        height: '100%'
    },
    error: {
        color: '#ff1e02'
    },
    valid: {
        color: '#24b14b'
    },
    formGroup: {
        marginBottom: '0px',

    },
    paddingRight: {
        paddingRight: 'unset'
    },
    headerBG: {
        'backgroundColor': '#facc02'
    },
    width30: {
        width: '35%',
        fontSize: '14px'
    }
}

const renderInput = ({ input, type, meta, class_name, value_check, maxLength, id, pattern, label, defaultValue }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
        <div className={className}>
            <label
                htmlFor="staticEmail"
                className="col"
            >
                {label}  <div className="d-inline-block">{renderIcon(meta)}</div>
            </label>
            {id !== "phone_fieldd" ?
                <input {...input}
                    type={type}
                    id={id}
                    pattern={pattern}
                    autoComplete="off"
                    className={class_name}
                    checked={value_check}
                    maxLength={maxLength}
                    defaultValue={defaultValue}
                />
                :
                <div className="input-group">
                    <div className="input-group-prepend" style={styles.inputIcon}>
                        <span className="input-group-text" id="basic-addon1">(+63)</span>
                    </div>
                    <input
                        {...input}
                        type={type}
                        id={id}
                        pattern={pattern}
                        autoComplete="off"
                        className={class_name}
                        checked={value_check}
                        maxLength={maxLength}
                        defaultValue={defaultValue}
                    />

                </div>
            }

            <div className="form-control-feedback">{renderMsg(meta)}</div>
        </div>
    );
};

const renderMsg = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span className="error" >{error}</span>
            </div>
        )
    }
}


const renderIcon = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span style={styles.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
}


const validate = values => {
    const errors = {};
    var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    if (!values.fullName) {
        errors.fullName = 'You must enter your First Name'
    }
    if (!values.Birthdate) {
        errors.Birthdate = 'You must enter a valid date';

    }
    if (!values.Email) {
        errors.Email = 'You must enter your Email';
    }
    if (values.Email) {
        if (emailCheck.test(values.Email) === false) {
            errors.Email = 'Invalid Email Format';
        }
    }
    if (!values.Phone) {
        errors.Phone = 'You must enter your number';
    }
    if (values.Phone) {
        if (values.Phone.length > 10 || values.Phone.length < 10) {
            errors.Phone = 'Format should be: (+63) 9xxxxxxxxx';
        }
    }
    if (!values.Password) {
        errors.Password = 'You must enter your Password';
    }
    if (!values.CPassword) {
        errors.CPassword = 'You must enter your Password';
    }
    if (values.Password !== values.CPassword) {
        errors.CPassword = 'Password do not match';
    }
    return errors;
};

const Account = ({ valid, info }) => {
    // console.log(valid)
    document.title = "Zagana | Customer Profile"
    const isMobile = localStorage.getItem("isMobile") === "true"
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let Fblogin = sessionStorage.getItem('FBLogin') === 'true' || '';
    const [showModal, setShowModal] = useState(false)
    const [msgModal, setModalMsg] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()
    if (getUser === "") {
        history.push("/");
    }

    const updateInfo = (e) => {
        e.preventDefault()
        const values = info.values
        const details = {
            user_id: getUser.customer_id,
            user_name: values.fullName,
            user_phone: values.Phone,
            user_bday: values.Birthdate
        };
        const updateCustInfo = async () => {
            try {
                const response = await Home.post(`/updateCustomerInfo`, details)
                if (response.status === 200) {
                    setShowModal(true)
                    setModalMsg('information saved')
                    setTimeout(() => {
                        setShowModal(false)
                    }, 700)
                }
            } catch (err) {
                console.log(err)
            }

        }
        updateCustInfo()
    }
    const updateEmail = (e) => {
        e.preventDefault()
        const values = info.values
        const details = {
            user_id: getUser.customer_id,
            user_email: values.Email,
        };
        const updateCustEmail = async () => {
            try {
                const response = await Home.post(`/updateCustomerEmail`, details)
                if (response.status === 200) {
                    setShowModal(true)
                    setModalMsg('email updated')
                    setTimeout(() => {
                        setShowModal(false)
                    }, 700)
                }
            } catch (err) {
                console.log(err)
            }

        }
        updateCustEmail()
    }
    const updatePassword = (e) => {
        e.preventDefault()
        const values = info.values
        const details = {
            user_id: getUser.customer_id,
            user_pw: values.Password,
        };
        const updateCustPassword = async () => {
            try {
                const response = await Home.post(`/updateCustomerPassword`, details)
                if (response.status === 200) {
                    setShowModal(true)
                    setModalMsg('password changed')
                    setTimeout(() => {
                        setShowModal(false)
                    }, 700)
                }
            } catch (err) {
                console.log(err)
            }
        }
        updateCustPassword()

    }

    useEffect(() => {

        const getCustomerInfo = async () => {
            try {
                const response = await Home.post(`/customerInfo`, { user_id: getUser.customer_id })
                dispatch(initialCustInfo(response.data[0]))
            } catch (err) {
                console.log(err)
            }
        }
        getCustomerInfo()

    }, [])

    return (
        <React.Fragment>
            {isMobile ?
                <Header title="My Account" />
                :
                <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                    <div className="row">
                        <div className="col-2 d-none d-sm-block text-center">
                            <a href="/">
                                <ZaganaLogo />
                            </a>
                        </div>
                    </div>
                </div>
            }
            {!isMobile ?
                <div className="col-3 sideNav_Act">
                    <SideNav />
                </div>
                :
                null
            }
            <div className={isMobile ? "col-12" : "col-9"}>
                {
                    getUser ?
                        <React.Fragment>
                            <CookieModal />
                            <div className="p-4 pb-0" style={styles.height}>
                                
                                <div className="row-12 d-flex justify-content-center">

                                    <div className={isMobile ? "row p-4 sideNav_Act" : "row w-75"}>
                                        <div className={Fblogin ? "col-12" : "col-12  col-lg-6"}>
                                            <h5>Information</h5>
                                            <form onSubmit={updateInfo} className="p-2">
                                                <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                    <div className="col-12">
                                                        <Field
                                                            label="Username"
                                                            type="text"
                                                            name="fullName"
                                                            component={renderInput}
                                                            class_name="form-control"
                                                        // defaultValue={user.user_name}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                    <div className="col-12 ">
                                                        <Field
                                                            label="Phone"
                                                            type="number"
                                                            name="Phone"
                                                            maxLength="11"
                                                            component={renderInput}
                                                            class_name="form-control"
                                                            id="phone_fieldd"
                                                            pattern="[0-9]"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                    <div className="col-12">
                                                        <Field
                                                            type="date"
                                                            label="Birthday"
                                                            name="Birthdate"
                                                            component={renderInput}
                                                            class_name="form-control"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                    <div className="mt-3 col-12">
                                                        <button
                                                            type="submit"
                                                            disabled={!valid}
                                                            className={isMobile ? "btn btn-success w-100" : "btn btn-success"}
                                                            style={isMobile ? null : styles.width30}
                                                        >
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        {!Fblogin ?
                                            <div className="col-12 col-sm-12 col-lg-6">
                                                <h5>Email</h5>
                                                <form onSubmit={updateEmail} className="p-2">
                                                    <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                        <div className="col-12">
                                                            <Field
                                                                type="email"
                                                                label="Email"
                                                                name="Email"
                                                                component={renderInput}
                                                                class_name="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                        <div className="mt-3 col-12">
                                                            <button
                                                                type="submit"
                                                                disabled={!valid}
                                                                className={isMobile ? "btn btn-success w-100" : "btn btn-success"}
                                                                style={isMobile ? null : styles.width30}
                                                            >
                                                                Update
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <h5>Password</h5>
                                                <form onSubmit={updatePassword} className="p-2">
                                                    <div className="form-group row" style={styles.formGroup} id="Password_field">
                                                        <div className="col-12">
                                                            <Field
                                                                type="Password"
                                                                name="Password"
                                                                label="New Password"
                                                                component={renderInput}
                                                                class_name="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row" style={styles.formGroup} id="CPassword_field">
                                                        <div className="col-12">
                                                            <Field
                                                                type="Password"
                                                                name="CPassword"
                                                                label="Confirm Password"
                                                                component={renderInput}
                                                                class_name="form-control"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row" style={styles.formGroup} id="phone_field">
                                                        <div className="mt-3 col-12">
                                                            <button
                                                                type="submit"
                                                                disabled={!valid}
                                                                className={isMobile ? "btn btn-success w-100" : "btn btn-success"}
                                                                style={isMobile ? null : styles.width30}
                                                            >
                                                                Change
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                        
                                <SuccessModal btn={showModal} body={msgModal} />
                            </div>
                            {/* <hr />
                            <div className="p-4">
                                <div className="row-12 d-flex justify-content-center">
                                    <div className={isMobile ? "row p-4" : "row w-75"}>
                                        <div className={Fblogin ? "col-12" : "col-12 col-sm-12 col-lg-6"}>
                                            <h5>Delete Account</h5>
                                            <div className="p-2" style={isMobile?  null : styles.width30}>
                                            <DisableAccountModal/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </React.Fragment>
                        : null
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        initialValues: {
            fullName: state.CustInfo.fullName,
            Email: state.CustInfo.Email,
            Phone: state.CustInfo.Phone,
            Birthdate: state.CustInfo.Birthdate,
            Password: state.CustInfo.Password,
            CPassword: state.CustInfo.Password
        },
        info: state.form.Account
    }
}

export default connect(mapStateToProps)(reduxForm({
    form: "Account",
    enableReinitialize: true, // this is needed!!
    validate
})(Account));
