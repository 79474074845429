import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import SuccessModal from "../Modal/SuccessfulModal";
import { useHistory } from "react-router-dom";
import Home from "../APIs/Home";

const style = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: '44.5vh'
    },
    expired: {
        color: '#ff1e02'
    }
}
const renderInput = ({ input, type, meta, class_name, value_check, maxLength, id, pattern, label, defaultValue }) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
    return (
        <div className={className}>
            <label
                htmlFor="staticEmail"
                className="col"
            >
                {label}  <div className="d-inline-block">{renderIcon(meta)}</div>
            </label>
            <input {...input}
                type={type}
                id={id}
                pattern={pattern}
                autoComplete="off"
                className={class_name}
                checked={value_check}
                maxLength={maxLength}
                defaultValue={defaultValue}
            />


            <div className="form-control-feedback">{renderMsg(meta)}</div>
        </div>
    );
};

const renderMsg = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span className="error" >{error}</span>
            </div>
        )
    }
}


const renderIcon = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.Password) {
        errors.Password = 'You must enter your Password';
    }
    if (!values.CPassword) {
        errors.CPassword = 'You must enter your Password';
    }
    if (values.Password !== values.CPassword) {
        errors.CPassword = 'Password do not match';
    }
    return errors;
};
const ChangePass = ({ valid, password }) => {
    const isMobile = localStorage.getItem("isMobile") === "true"
    const user = window.location.pathname
    const [validFP, setValidFP] = useState(false)
    const [userID, setUserID] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [msgModal, setModalMsg] = useState(false)
    const removed = user.replace("/change-password/", "")
    const history = useHistory()
    useEffect(() => {
        const getForgotPW = async () => {
            try {
                const response = await Home.get(`/forgotpassword/${removed}`)
                if (response.data.length > 0) {
                    setValidFP(true)
                    setUserID(response.data[0].user_id)
                }
            } catch (err) {
                console.log(err)
            }
        }
        getForgotPW()
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault()
        const details = {
            user_pw: password.values.Password,
            user_id: userID
        }
        try {
            const response = await Home.post(`/updateCustomerPassword`, details)
            console.log(response)
            if (response.status === 200) {
                setShowModal(true)
                setModalMsg('password changed')
                setTimeout(() => {
                    setShowModal(false)
                }, 800)
                setTimeout(() => {
                    if (isMobile) {
                        history.push('/login')
                    } else {
                        history.push('/')
                    }
                }, 2000)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <React.Fragment>
            <div className="container mt-5 mb-3" style={style.container}>
                <h5>Change Password</h5>
                {validFP ?
                    <form className={isMobile ? "p-5 pt-2 w-100" : "p-2 w-25"} onSubmit={handleSubmit}>
                        <div className="form-group row" id="Password_field">
                            <div className="col-12">
                                <Field
                                    type="Password"
                                    name="Password"
                                    label="New Password"
                                    component={renderInput}
                                    class_name="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group row" id="CPassword_field">
                            <div className="col-12">
                                <Field
                                    type="Password"
                                    name="CPassword"
                                    label="Confirm Password"
                                    component={renderInput}
                                    class_name="form-control"
                                />
                            </div>
                        </div>
                        <div className="form-group row" id="phone_field">
                            <div className="mt-3 col-12">
                                <button
                                    type="submit"
                                    disabled={!valid}
                                    className={isMobile ? "btn btn-success w-100" : "btn btn-success w-50"}
                                >
                                    Change
                                </button>
                            </div>
                        </div>
                    </form>
                    :
                    <div style={style.expired}>Expired</div>
                }
                <SuccessModal btn={showModal} body={msgModal} />
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        password: state.form.Password
    }
}

export default connect(mapStateToProps)(reduxForm({
    form: 'Password',
    validate
})(ChangePass));