/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : DirectDebit
 * File Directory           : src/components/APIs/FailXendit
 * Module                   : API
 * Author                   : Joseph Rivera
 * Version                  : 1
 * Purpose                  : payment method for xendit
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   FailXendit        -   component for Failure upon authorization of bank acct 
 *
 * #How to call:
 *    /xendit/FailXendit
 *
 *******************************************************************************************/import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';
import ZaganaLogo from '../../Shared/ZaganaLogo';
import Header from "../../Shared/Header";
import UB from "../../../assets/site_icons/unionbank-icon.svg";
import BPI from "../../../assets/site_icons/bpi-icon.svg";

const styles = {
    container: {
        height: "100vh",
        padding: "3rem"
    },
    headerBG: {
        'backgroundColor': '#facc02'
    }
}

const Fail = ({ paymentSelected }) => {
    const isMobile = localStorage.getItem("isMobile") === "true"
    const selectedPayment = paymentSelected.paymentSelected; // declaring payment selected
    const imgSrc = selectedPayment === "UB" ? UB : BPI; // condition for image source depending on payment selected
    return (
        <React.Fragment>
            <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                <div className="row">
                    <div className="col-2 d-none d-sm-block text-center">
                        <a href="/">
                            <ZaganaLogo />
                        </a>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row text-center mt-5 mb-5">
                    <div className="card mt-2 mb-5 w-25" style={{ "margin": "0 auto", "display": "block"}}>
                        <div className="card-body mt-5 mb-5" >
                            <h2>Authorization Failed</h2>
                            <i>
                                User didn't authorize bank account
                            </i>
                            <br/>
                            <a href="/checkout-details">Click here to try again</a>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )

}
const mapStateToProps = (state) => {
    console.log(state)
    return ({
        // id: state.OrderID.order_id
        paymentSelected: state.payment
    })
}
export default connect(mapStateToProps)(Fail);