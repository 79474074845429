/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : DirectDebit
 * File Directory           : src/components/APIs/DirectDebit
 * Module                   : API
 * Author                   : Joseph Rivera
 * Version                  : 1
 * Purpose                  : payment method for xendit
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DirectDebit        -   component for Direct Debit input details
 *
 * #How to call:
 *    /xendit/DirectDebit
 *
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";
import Xendit from "../Xendit";
import Home from "../Home";
import UB from "../../../assets/site_icons/unionbank-icon.svg"
import BPI from "../../../assets/site_icons/bpi-icon.svg"

const style = {
    error: {
        color: 'red'
    },
    valid: {
        color: '#24b14b'
    },
    width: {
        width: '100%'
    },
    img: {
        width: '250px',
        margin: '0 auto',
        display: 'block'
    }
}

//render component for redux-form
const renderInput = ({
    input,
    type,
    meta,
    value_name,
    class_name,
    placeholder,
    label,
    pattern,
    style
}) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''} ${!meta.error ? 'valid' : ''}`;
    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    {label === undefined ?
                        ''
                        :

                        <label
                            htmlFor={label}
                            className="col-form-label"
                        >
                            {label} &nbsp;
                            <div className="d-inline-block"> {errorIcon(meta)}</div>
                        </label>

                    }

                </div>
            </div>

            <div className={className}>
                {type === "number" ?
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">(+63)</span>
                        </div>
                        <input
                            {...input}
                            type={type}
                            pattern={pattern}
                            autoComplete="off"
                            className={class_name}
                            placeholder={placeholder}
                            style={style}
                        />
                    </div>
                    :
                    <input
                        {...input}
                        type={type}
                        pattern={pattern}
                        autoComplete="off"
                        className={class_name}
                        placeholder={placeholder}
                        style={style}
                    />
                }
                {label === undefined ? <div className="d-inline-block"> {errorIcon(meta)}</div> : ''}
                <div className="form-control-feedback">{errorMsg(meta)}</div>
            </div>

        </React.Fragment>
    );
};

//rendering error message
const errorMsg = ({ error, touched }) => {
    if (touched && error) {
        return (
            <span className="error" >{error}</span>
        )
    }
}

//rendering error/valid icon
const errorIcon = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span style={style.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
    else if (!error) {
        return (
            <div>
                <span style={style.valid}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </span>
            </div>
        );
    }
}

//declaring input errors
const validate = values => {
    const errors = {};
    var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (!values.customerEmail) {
        errors.customerEmail = 'Please enter your email';
    }
    if (!values.contactNumber) {
        errors.contactNumber = 'You must enter your Contact Number';
    }
    if (values.contactNumber) {
        if (values.contactNumber.length > 10 || values.contactNumber.length < 10) {
            errors.contactNumber = 'Format should be: (+63) 9xxxxxxxxx';
        }
    }
    if (values.customerEmail) {
        if (emailCheck.test(values.customerEmail) === false) {
            errors.customerEmail = 'Invalid Email Format';
        }
    }
    if (!values.cardName) {
        errors.cardName = 'Please enter your card name';
    }
    if (values.cardNumber) {
        if (values.cardNumber.length > 4 || values.cardNumber.length < 4) {
            errors.cardNumber = 'Please enter the last 4 digits';
        }
    }
    if (!values.cardNumber) {
        errors.cardNumber = 'Please enter your card number';
    }
    if (!values.Month) {
        errors.Month = true;
    }
    if (values.Month) {
        if (values.Month.length > 2) {
            errors.Month = true
        }
        if (values.Month > 12 || values.Month < 1) {
            errors.Month = true
        }
    }
    if (!values.Year) {
        errors.Year = true;
    }
    if (values.Year) {
        if (values.Year.length > 2) {
            errors.Year = true
        }
    }
    return errors;
};

//main component
const DirectDebit = ({ valid, details, paymentSelected, order_id, customer }) => {
    const isMobile = localStorage.getItem("isMobile") === "true"; // is mobile
    const [errorDetails, setErrorDetails] = useState(false) // error details
    const [errorMsg, setErrorMsg] = useState('') // error msg
    const [showForm, setShowForm] = useState(false); // shows form for contact details
    const history = useHistory()
    const dispatch = useDispatch()
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const orderDetails = JSON.parse(sessionStorage.getItem('order')) //order details
    const selectedPayment = paymentSelected.paymentSelected; // selected payment
    const imgSrc = selectedPayment === "UB" ? UB : BPI; // condition for select payment method
    useEffect(() => {
        const value = customer; //data from form
        const contact = customer.Phone;
        const orderNumber = order_id; // order number
        const paymentOrder = paymentSelected; // order number
        
        let originURL = {
            originURL: window.location.origin,
            customer: 'url'
        };
       
        const mergedObj = Object.assign(value, orderNumber, customer, paymentOrder,originURL); // merging all objects
        console.log(originURL);
        console.log(mergedObj);
        //Create Customer API
        const createPayment = async () => {
            await Xendit.post(`/CreateCustomer`, mergedObj)
                .then(response => {
                    // console.log(response)
                    //response from create api
                    if (response.data.message === 'Success') {
                        dispatch({ // dispatching setting up to redux
                            type: "XenditData",
                            url: response.data.initialized.authorizer_url,
                            customer_id: response.data.initialized.customer_id,
                            reference_id: response.data.refId
                        })
                        window.location.href = response.data.initialized.authorizer_url;
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }

        if (customer.Email && customer.Phone && contact.length === 10) {
            createPayment()
            setShowForm(!showForm)
        }
        // 
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault()
        const value = details.values; //data from form
        const orderNumber = order_id; // order number
        const paymentOrder = paymentSelected; // order number
        const originURL = {url:window.location.origin}; // order number
        

        const updateDetails = {
            user_id: getUser.customer_id,
            user_email: value.customerEmail,
            Phone: value.contactNumber,
        };
        const mergedObj = Object.assign(value, orderNumber, customer, paymentOrder,updateDetails,originURL); // merging all objects
        document.getElementById("btnRegister").disabled = true; // changing enable to disable
        document.getElementById("btnRegister").innerText = 'Loading ...'; // button title proceed to Loading..
        
        updateCustEmail(updateDetails)
        console.log(mergedObj)
        //Create Customer API
        Xendit.post(`/CreateCustomer`, mergedObj)
            .then(response => {
                //response from create api
                if (response.data.message === 'Success') {
                    dispatch({ // dispatching setting up to redux
                        type: "XenditData",
                        url: response.data.initialized.authorizer_url,
                        customer_id: response.data.initialized.customer_id,
                        reference_id: response.data.refId
                    })
                    window.location.href = response.data.initialized.authorizer_url;
                }
            })
            .catch(error => {
                console.log(error)
            })
    }
    const updateCustEmail = async (details) => {
        try {
            const response = await Home.post(`/updateCustomerEmail`, details)
        } catch (err) {
            console.log(err)
        }

    }
    return (
        <React.Fragment>
            <div className="container">
                <div className={`justify-content-center m-auto ${isMobile === true ? 'w-100' : 'w-25'}`}
                >
                    <div className="row">
                        <div className="col mt-5">

                            <img src={imgSrc} style={style.img} />
                        </div>
                    </div>
                    {errorDetails ?
                        <div className="alert alert-danger p-2" role="alert">
                            <span>{errorMsg}</span>
                        </div>
                        :
                        ''
                    }
                    <form onSubmit={handleSubmit} className={`p-2  ${showForm === true ? 'd-none' : ' '}`}>
                        <div className="row">
                            <div className="col">
                                <Field
                                    label="Email"
                                    type="email"
                                    name="customerEmail"
                                    class_name="form-control"
                                    component={renderInput}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Field
                                    label="Contact Number"
                                    type="number"
                                    name="contactNumber"
                                    class_name="form-control"
                                    component={renderInput}
                                />
                            </div>
                        </div>
                        {/* <div className="row d-none">
                                 <div className="col">
                                     <Field
                                         label="Card Name"
                                         type="cardName"
                                         name="cardName"
                                         class_name="form-control"
                                         component={renderInput}
                                     />
                                 </div>
                             </div>
                             <div className="row d-none">
                                 <div className="col">
                                     <Field
                                         label="Card Number (last 4 digits)"
                                         type="number"
                                         name="cardNumber"
                                         class_name="form-control"
                                         component={renderInput}
                                     />
                                 </div>
                             </div>
                             <div className="row  d-none">
                                 <div className="col-12">
                                     <div className="row p-0">
                                         <label
                                             htmlFor="Expiry"
                                             className="col-form-label"
                                         >
                                             Expiry
                                         </label>
                                         <div className="col-5" >
                                             <Field
                                                 type="number"
                                                 name="Month"
                                                 class_name="form-control d-inline-block"
                                                 component={renderInput}
                                                 placeholder="MM"
                                                 maxLength="2"
                                                 pattern="[0-9]"
                                                 style={style.width}
                                             />
                                         </div>
                                         /
                                         <div className="col-5" >
                                             <Field
                                                 type="number"
                                                 name="Year"
                                                 class_name="form-control d-inline-block"
                                                 component={renderInput}
                                                 placeholder="YY"
                                                 pattern="[0-9]"
                                                 style={style.width}
                                             />
                                         </div>
                                     </div>
                                 </div>
 
 
                             </div> */}
                        <div className="row pt-4">
                            <div className="col">
                                <button type="submit" className={`btn w-100  ${!valid ? 'disabled' : 'btn-primary'} `} disabled={!valid} id="btnRegister">Submit</button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        details: state.form.Xendit, // state from redux state
        paymentSelected: state.payment, // redux state from paymentSelected
        order_id: state.OrderID, // redux state from order id
        customer: state.CustInfo, // redux state from customer info
        initialValues: {
            customerEmail: state.CustInfo.Email, // setting initials value for email
            contactNumber: state.CustInfo.Phone // setting initials value for phone
        },
    }
}
export default connect(mapStateToProps)
    (reduxForm({
        form: 'Xendit',
        validate
    })(DirectDebit));
