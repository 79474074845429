
//  * File Name                : DeliveryFields
//  * File Directory           : src/components/Shared/DeliveryFields
//  * Module                   : DeliveryFields
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/App.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DeliveryFields        -   component for setting delivery schedule
 *
 * #How to call:
 *    <DeliveryFields/>
 *
 * Note: Still working on the ff:
 * Date: set only from today to tom and next day
 * Upon clicking on confirm or delivery now the data is saved on localstorage
 * 

 *******************************************************************************************/
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Alert } from 'react-bootstrap';
import '../Shared/Shared.scss';
import { useHistory } from "react-router-dom";
import Header from '../Shared/Header'
import { useDispatch } from "react-redux";
import { Popover, OverlayTrigger } from 'react-bootstrap';
const style = {
    formLabel: {
        display: 'inline-block',
        width: '25%'
    },
    formInput: {
        display: 'inline-block',
        width: '75%'
    },
    formDate: {
        textAlign: 'center',
    }

}




const DeliveryFields = ({disableDel}) => {
    const history = useHistory();
    const today = new Date();
    let tomorrow = new Date();
    let nextday = new Date();
    tomorrow.setDate(today.getDate() + 1);
    nextday.setDate(today.getDate() + 2);

    const dateStore = sessionStorage.getItem('deliveryDate') || '';
    const [startDate, setStartDate] = useState(formatDate(today));
    const [time, setTime] = useState(normalTime(today));
    const [showTime, setShowTime] = useState('');
    const [validation, setValidation] = useState('');
    const [nowValidation, setNowValidation] = useState('');
    const [validType, setValidType] = useState('');
    const [dispayTime, setDisplayTime] = useState('');
    const [timeValidation, setTimeValidation] = useState(normalTime(today));
    const isMobile = localStorage.getItem("isMobile");
    const [validTitle, setValidTitle] = useState('');
    const [toggMethod, setToggMethod] = useState('');
    let getBranch = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_opening: '08:00', branch_closing: '16:00' };
    getBranch.branch_opening = getBranch.branch_opening !== undefined ? getBranch.branch_opening : '08:00'
    getBranch.branch_closing = getBranch.branch_closing !== undefined ? getBranch.branch_closing : '16:00'
    let opening = (getBranch.branch_opening !== undefined ? getBranch.branch_opening : '08:00').split(':'), closing = (getBranch.branch_closing !== undefined ? getBranch.branch_closing : '16:00').split(':')
    opening = parseInt(opening[0])
    closing = parseInt(closing[0])
    const dispatch = useDispatch()
    // sessionStorage.setItem('deliveryDate', formatDate(startDate));

    //checker if user is logged in
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    if (!getUser) {
        window.location.replace("/login#checkout")
    }


    //getting format today date

    function formatDate(today) {
        let d = new Date(today),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
    // split time + 12 hour format no additional 
    function normalTime(today) {
        let d = new Date(today);
        let H = d.getHours(), m = d.getMinutes(), l = "";
        let time = "am"

        if (H == 12) {
            time = "pm"
            H = H;
        }
        if (H > 12) {
            time = "pm"
            H = H - 12;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (m < 10) {
            m = '0' + m;
        }

        return H + ':' + m + ' ' + l + time;
    }
    // split time + 12 hour format no additional +1
    function delNowTime(today) {
        let d = new Date(today);
        let H = d.getHours() + 1, m = d.getMinutes(), l = "";
        let time = "am"

        if (H == 12) {
            time = "pm"
            H = H;
        }
        if (H > 12) {
            time = "pm"
            H = H - 12;
        }
        if (H < 10) {
            H = '0' + H;
        }
        if (m < 10) {
            m = '0' + m;
        }

        return H + ':' + m + ' ' + l + time;
    }

    // function timeFormatter(timessx) {
    //     let H = timessx, l = "", nM = 0, nH = 0;
    //     let time = "am"
    //     H = timessx.split(':');
    //     if (parseInt(H[0], 10) == 12) {
    //         time = "pm"
    //         nH = parseInt(H[0], 10);
    //     }
    //     if (parseInt(H[0], 10) > 12) {
    //         time = "pm"
    //         nH = parseInt(H[0], 10) - 12;
    //     }
    //     if (parseInt(H[0], 10) < 10) {
    //         nH = parseInt(H[0], 10);
    //     }
    //     if (parseInt(H[1], 10) < 10) {
    //         nM = '0' + parseInt(H[1], 10);
    //     }
    //     if (parseInt(H[1], 10) > 10) {
    //         nM = parseInt(H[1], 10);
    //     }
    //     return nH + ":" + nM + ' ' + l + time;
    // }
    //trigger to open time keeper + adding new format for the time (12hour) for mbi
    const timeBlur = () => {
        timeDisplayFormat();
        
    }
    
    const timeBlurDesk = () => {
        sessionStorage.setItem('deliveryTime', (time));
        
    }
    const timeFormatter = (time) => {
        time = time.split(':')
        let newTime = (time[0] < 12 ? time[0] : (parseInt(time[0]) - 12 < 10 ? '0' + (parseInt(time[0]) - 12).toString() : parseInt(time[0]) - 12)) + ':' + time[1]
        newTime += (parseInt(time[0]) >= 12 ? ' PM' : ' AM')
        return newTime
    }
    const timeChecker = () => {
        //sessionStorage.setItem('deliveryTime', (time));
        //   added error redux error message
        if (startDate) {
            let timeNow = today.getHours() + ":" + today.getMinutes(),
                inputTime = "",
                convertedTime = "",
                timePlus = today.getHours() + 1 + ":" + today.getMinutes();
            time.length === 4 ? inputTime = "0" + time : inputTime = time;
            timeNow.length === 4 ? convertedTime = "0" + today.getHours() + ":" + today.getMinutes() : convertedTime = "0" + today.getHours() + ":" + today.getMinutes();
            let total = (parseInt(inputTime) - parseInt(timePlus))
            getBranch.branch_opening.length === 4 ? getBranch.branch_opening = "0" + getBranch.branch_opening : getBranch.branch_opening = getBranch.branch_opening
            if (formatDate(startDate) === formatDate(today)) {
                if (convertedTime > inputTime) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)} - ${timeFormatter(getBranch.branch_closing)} PM . See you then!`
                        })
                    }
                }

                else if (inputTime < getBranch.branch_opening) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (inputTime > getBranch.branch_closing) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (inputTime == convertedTime) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (total < 0) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else {
                    
                    //sessionStorage.setItem('deliveryTime', (time));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: false,
                            errorMsg: ''
                        })
                    }
                }
            }
            else {

                if (inputTime > getBranch.branch_closing) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (inputTime < getBranch.branch_opening) {
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else {
 
                    if (isMobile !== 'true') {
                        
                        dispatch({
                            type: 'TIME_ERROR',
                            error: false,
                            errorMsg: ''
                        })
                    }
                }
            }

        }
        else {
            setValidType("Error")
            setValidTitle('Error!');
            setValidation('Please enter required fields')
            sessionStorage.setItem('deliveryTime', (''));
            sessionStorage.setItem('deliveryDate', (''));
        }
        // if (isMobile !== 'true') {
        //     confirmSched()
        // }

    }
    const toggleMethod = () => {
        setToggMethod("Later")
        sessionStorage.setItem('deliveryTime', '');
        sessionStorage.setItem('deliveryDate', formatDate(today));
    }

    const timeDisplayFormat = () => {
        if (time > '12:00') {
            setTime(time)
            let e = time.split(':');

            let end = new Date(0, 0, parseInt(e[1], 10), parseInt(e[0], 10), parseInt(e[1], 10), 0);
            setDisplayTime(normalTime(end))
        }
        else if (time === '12:00') {
            setTime(time)
            let e = time.split(':');
            let end = new Date(0, 0, parseInt(e[1], 10), parseInt(e[0], 10), 0, 0);
            setDisplayTime(normalTime(end))
        }
        else {
            setTime(time)
            let e = time.split(':');
            let end = new Date(0, 0, parseInt(e[1], 10), parseInt(e[0], 10), 0, 0);
            setDisplayTime((time + " am"))

        }
    }
    // customer input submit (advance booking + conditions)
    const confirmSched = () => {
        timeDisplayFormat();
        if (startDate) {
            let timeNow = today.getHours() + ":" + today.getMinutes(),
                inputTime = "",
                convertedTime = "",
                timePlus = today.getHours() + 1 + ":" + today.getMinutes();
            time.length === 4 ? inputTime = "0" + time : inputTime = time;
            timeNow.length === 4 ? convertedTime = "0" + today.getHours() + ":" + today.getMinutes() : convertedTime = "0" + today.getHours() + ":" + today.getMinutes();
            let total = (parseInt(inputTime) - parseInt(timePlus))
            getBranch.branch_opening.length === 4 ? getBranch.branch_opening = "0" + getBranch.branch_opening : getBranch.branch_opening = getBranch.branch_opening
            if (formatDate(startDate) === formatDate(today)) {

                if (convertedTime > inputTime) {
                    setValidType("Error")
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`)
                    setValidTitle('Uh-oh!');
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }

                else if (inputTime < getBranch.branch_opening) {
                    setValidType("Error")
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`)
                    setValidTitle('Uh-oh!');
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (inputTime > getBranch.branch_closing) {
                    setValidType("Error")
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)} - ${timeFormatter(getBranch.branch_closing)} PM . See you then!`)
                    setValidTitle('Uh-oh!');
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (inputTime == convertedTime) {
                    setValidType("Error")
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`)
                    setValidTitle('Uh-oh!');
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (total < 0) {
                    setValidType("Error")
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`)
                    setValidTitle('Uh-oh!');
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else {
                    setValidType("Success")
                    setValidation('Please expect your rider to arrived at ' + dispayTime + ' Date: (' + formatDate(startDate) + ')')
                    timeDisplayFormat();
                    sessionStorage.setItem('deliveryTime', (dispayTime));
                    sessionStorage.setItem('deliveryDate', formatDate(startDate));
                    if (isMobile === 'true') {
                        history.push('/checkout-details');
                    }
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: false,
                            errorMsg: ''
                        })
                    }
                }
            }
            else {

                if (inputTime > getBranch.branch_closing) {
                    setValidType("Error")
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`)
                    setValidTitle('Uh-oh!');
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else if (inputTime < getBranch.branch_opening) {
                    setValidType("Error")
                    setValidTitle('Uh-oh!');
                    setValidation(`Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`)
                    sessionStorage.setItem('deliveryTime', (''));
                    sessionStorage.setItem('deliveryDate', (''));
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: true,
                            errorMsg: `Sorry, suki. Please choose time between ${timeFormatter(getBranch.branch_opening)}  - ${timeFormatter(getBranch.branch_closing)} . See you then!`
                        })
                    }
                }
                else {
                    timeDisplayFormat();
                    setValidType("Success")
                    setValidTitle('Success!');
                    setValidation('Please expect your rider to arrived at ' + dispayTime + ' Date: (' + formatDate(startDate) + ')')
                    sessionStorage.setItem('deliveryTime', (dispayTime));
                    sessionStorage.setItem('deliveryDate', formatDate(startDate));
                    if (isMobile === 'true') {
                        history.push('/checkout-details');
                    }
                    if (isMobile !== 'true') {
                        dispatch({
                            type: 'TIME_ERROR',
                            error: false,
                            errorMsg: ''
                        })
                    }
                }
            }

        }
        else {
            setValidType("Error")
            setValidTitle('Error!');
            setValidation('Please enter required fields')
            sessionStorage.setItem('deliveryTime', (''));
            sessionStorage.setItem('deliveryDate', (''));
        }

    };

    // submitting del schedule (book)now
    const delNow = () => {
        setValidType("Success")
        setValidation('Please expect your order after 45mins')
        sessionStorage.setItem('deliveryTime', delNowTime(today));
        sessionStorage.setItem('deliveryDate', formatDate(today));
        setTime(delNowTime(today).toString())

        setStartDate(today)
        setToggMethod("Now")
        // if (isMobile === 'true') {
        //     history.push('/checkout-details');
        // }
    };
    const confirmPush = () => {
        history.push('/checkout-details');
    };
    const popover = (
        <Popover id="popover-basic">
            <Popover.Body>
                Fruit baskets are made to order and may not be delivered on your chosen schedule. Our Customer Success Rep will reach out to you for any delivery concerns.
            </Popover.Body>
        </Popover>
    );

    return (

        <React.Fragment>
            {isMobile === "true" ?
                <>
                    <Header title="Delivery Schedule" />

                    <br />

                    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                        <button className="btn btn-link  mt-3" style={{ color: '#24b14b', fontSize: '14px', fontStyle: 'normal', 'width': 'auto' }}>Disclaimer</button>
                    </OverlayTrigger>
                    <div className="row mt-3" style={{ 'display': 'inlineFlex', 'textAlign': 'center', 'margin': '0 auto' }}>
                        <div className="col-6 d-flex justify-content-center">

                            {today.getHours() > closing || today.getHours() < opening || formatDate(today) === "2022-01-01" ?
                                <input type="button" className="btn-md btn-block w-100 p-3 btn btn-secondary" onClick={delNow} disabled value="Deliver Now" />
                                :
                                <input type="button" className="btn btn-outline-success btn-md btn-block w-100" onClick={delNow} value="Deliver Now"

                                />
                            }

                        </div>


                        <div className="col-6 d-flex justify-content-center">
                            <button type="button" className={`btn-md btn-block w-100 p-3 btn 
                              ${toggMethod === "Later" ? 'btn-primary'
                                    : 'btn-outline-success btn-md btn-block'}`
                            } onClick={toggleMethod}

                            >Deliver Later</button>
                        </div>
                    </div>

                    {toggMethod === "Now" ?
                        <div className="mt-3">
                            {today.getHours() > closing || today.getHours() < opening || formatDate(today) === "2022-01-01" ?
                                <div>
                                    <div role="alert" className={`fade alert alert-danger show delNowAlert ${toggMethod === "Now" ? '' : 'd-none'} `}>
                                        <b>Uh-oh!</b>
                                        <br />
                                        <span style={{ 'fontSize': '14px' }} >
                                            Sorry, suki. Please choose time between {getBranch.branch_opening} AM - {getBranch.branch_closing} PM. See you then!
                                        </span>
                                    </div>
                                </div>
                                :
                                <div role="alert" className={`fade alert alert-success show delNowAlert ${toggMethod === "Now" ? '' : 'd-none'} `}> {validation}</div>
                            }

                            <div className="container">

                                <div className="col-12">
                                    <button type="button" className="btn btn-outline-success btn-md btn-block w-100" onClick={confirmPush} >Confirm Schedule</button>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                    }
                    {toggMethod === "Later" ?
                        <form className="mt-3 mb-3" >
                            <div className="mt-3 mb-3">
                                {validType === 'Error' ? <Alert variant="danger"><b>{validTitle}</b>
                                    <br />  <span style={{ 'fontSize': '14px' }}>{validation}</span>
                                </Alert> : ''}
                                {validType === 'Success' ? <Alert variant="success"> {validation}</Alert> : ''}

                                <hr />
                                <div className="form-group row mb-2" id="DeliveryDate_field ">
                                    <label htmlFor="staticEmail" className="col-xs-4 col-sm-5 col-lg-4 col-form-label" style={style.formLabel}>Date</label>


                                    <div className={`card col dateCard ${startDate === formatDate(today) && startDate !== "2022-01-01" && closing > today.getHours() ? 'activeDate' : ''} ${closing > today.getHours() ? '' : 'disabledCOD'} 
                                    ${formatDate(today) === "2022-01-01" ? 'disabledCOD' : ''}
                                    ${formatDate(today) === "2022-10-31" ? 'disabledCOD' : ''}
                                    `} 
                                    onClick={date => setStartDate(formatDate(today))} >
                                        {today.toLocaleString('default', { month: 'short' })}. {today.getDate()}
                                        <br />
                                        {today.toLocaleDateString('default', { weekday: 'short' })}
                                    </div>

                                    <div className={`card col dateCard ${startDate === formatDate(tomorrow) ? 'activeDate' : ''} ${formatDate(tomorrow) === "2022-01-01" ? 'disabledCOD' : ''} `} onClick={date => setStartDate(formatDate(tomorrow))}

                                    >
                                        {tomorrow.toLocaleString('default', { month: 'short' })}. {tomorrow.getDate()}
                                        <br />
                                        {tomorrow.toLocaleDateString('default', { weekday: 'short' })}
                                    </div>
                                    <div className={`card col dateCard ${startDate === formatDate(nextday) ? 'activeDate' : ''} ${formatDate(nextday) === "2022-01-01" ? 'disabledCOD' : ''} `} onClick=
                                        {date => setStartDate(formatDate(nextday))} >
                                        {nextday.toLocaleString('default', { month: 'short' })}. {nextday.getDate()}
                                        <br />
                                        {nextday.toLocaleDateString('default', { weekday: 'short' })}
                                    </div>
                                </div>

                                <div className="form-group row" id="DeliveryTime_field">
                                    <label htmlFor="staticEmail" className="col-xs-6 col-sm-5 col-lg-4 col-form-label" style={style.formLabel}>Time</label>
                                    <div className="col-xs-6 col-sm-7 col-lg-8" style={style.formInput}>
                                        {isMobile === "true" ?
                                            <div>
                                                {/* <br />
                                      {showTime &&
                                          <TimeKeeper
                                              time={time}
                                              onChange={(newTime) => setTime(newTime.formatted24)}
                                              onDoneClick={toggleTime}
                                              switchToMinuteOnHourSelect
                                          />
                                      }
                                      {!showTime &&
                                          <input type="button" className="form-control" onClick={toggleTime} value={time} name="delTime" />
                                      } */}
                                            </div>

                                            : <div>
                                            </div>}


                                        <input type="time" className="form-control" value={time} min="08:00" max="16:00" required onChange={(e) => setTime(e.target.value)} onBlur={timeBlur}
                                        ></input>
                                    </div>
                                </div>
                            </div>


                            <div className="container">
                                <div className="col-12">
                                    <button type="button" className="btn btn-outline-success btn-md btn-block w-100" onClick={confirmSched} >Confirm Schedule</button>
                                </div>
                            </div>
                        </form> : ""}
                </>
                : <div>
                    <div className="row">
                        <div className="col-6 d-flex justify-content-center">

                            {today.getHours() > closing || today.getHours() < opening || formatDate(today) === "2022-01-01" ?

                                <input type="button" className="btn-md btn-block w-75 p-3 btn btn-secondary" onClick={delNow} disabled value="Deliver Now" />

                                :
                                <input type="button" className={`btn-md btn-block w-75 p-3 btn 
                                  ${toggMethod === "Now" ? 'btn-primary'
                                        : 'btn-outline-success btn-md btn-block'}`
                                } onClick={delNow} value="Deliver Now" />
                            }

                        </div>


                        <div className="col-6 d-flex justify-content-center">
                            <button type="button" className={`btn-md btn-block w-75 p-3 btn 
                              ${toggMethod === "Later" ? 'btn-primary'
                                    : 'btn-outline-success btn-md btn-block'}`
                            } onClick={toggleMethod}

                            >Deliver Later</button>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <h5>Delivery Time</h5>
                        {/* condition for opening and closing of store */}
                        {today.getHours() > closing || today.getHours() < opening || formatDate(today) === "2022-01-01" ?
                            <div>
                                <div role="alert" className="fade alert alert-danger show">
                                    <b>Uh-oh!</b>
                                    <br />
                                    <span style={{ 'fontSize': '14px' }}>
                                        Sorry, suki. Please choose time between {getBranch.branch_opening} AM - {getBranch.branch_closing} PM. See you then!
                                    </span>
                                </div>
                            </div>
                            :
                            ""
                        }
                        {/* Toggle method for deliver now and deliver later form */}
                        {toggMethod === "Now" ?
                            <div>
                                {today.getHours() > closing || today.getHours() < opening || formatDate(today) === "2022-01-01" ?
                                    <div>
                                        <div role="alert" className="fade alert alert-success show">
                                            <b>Uh-oh!</b>
                                            <br />
                                            <span style={{ 'fontSize': '14px' }}>
                                                Sorry, suki. Please choose time between {getBranch.branch_opening} AM - {getBranch.branch_closing} PM. See you then!
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    <div role="alert" className="fade alert alert-success show 11"> {validation}</div>
                                }
                            </div>
                            : <div></div>}
                        {/* Toggle method for deliver now and deliver later form */}
                        {toggMethod === "Later" ?
                            <div>
                                <div className="form-group row mb-2" id="DeliveryDate_field ">
                                    <label htmlFor="staticEmail" className="col-xs-4 col-sm-5 col-lg-4 col-form-label" style={style.formLabel}>Date</label>
                                    {/* Card delivery date: Today, tommorrow and next day */}
                                    <div className={`card col dateCard ${startDate === formatDate(today) && startDate !== "2022-01-01" && closing > today.getHours() ? 'activeDate' : ''} ${closing > today.getHours() ? '' : 'disabledCOD'} 
                                    ${formatDate(today) === "2022-01-01" ? 'disabledCOD' : ''} 
                                    ${formatDate(today) === "2022-10-31" ? 'disabledCOD' : ''}
                                    `}
                                     onClick={date => {
                                        // document.getElementById("timeInput").focus();
                                        setStartDate(formatDate(today))
                                        sessionStorage.setItem('deliveryDate', formatDate(today));
                                    }
                                    }
                                    >
                                        {today.toLocaleString('default', { month: 'short' })}. {today.getDate()}
                                        <br />
                                        {today.toLocaleDateString('default', { weekday: 'short' })}
                                    </div>

                                    <div className={`card col dateCard ${startDate === formatDate(tomorrow) ? 'activeDate' : ''} ${formatDate(tomorrow) === "2022-01-01" ? 'disabledCOD' : ''} `} onClick={date => {
                                        // document.getElementById("timeInput").focus();
                                        setStartDate(formatDate(tomorrow))
                                        sessionStorage.setItem('deliveryDate', formatDate(tomorrow));
                                    }}
                                    >
                                        {tomorrow.toLocaleString('default', { month: 'short' })}. {tomorrow.getDate()}
                                        <br />
                                        {tomorrow.toLocaleDateString('default', { weekday: 'short' })}
                                    </div>
                                    <div className={`card col dateCard ${startDate === formatDate(nextday) ? 'activeDate' : ''} ${formatDate(nextday) === "2022-01-01" ? 'disabledCOD' : ''} `} onClick={date => {
                                        // document.getElementById("timeInput").focus();
                                        setStartDate(formatDate(nextday))
                                        sessionStorage.setItem('deliveryDate', formatDate(nextday));
                                    }
                                    }>
                                        {nextday.toLocaleString('default', { month: 'short' })}. {nextday.getDate()}
                                        <br />
                                        {nextday.toLocaleDateString('default', { weekday: 'short' })}
                                    </div>
                                </div>

                                <div className="form-group row" id="DeliveryTime_field">
                                    <label htmlFor="staticEmail" className="col-xs-6 col-sm-5 col-lg-4 col-form-label" style={style.formLabel}>Time</label>
                                    <div className="col-xs-6 col-sm-7 col-lg-8" style={style.formInput}>

                                        {/* Time for deliver later form */}
                                        <input type="time" className="form-control" id="timeInput" value={time} min="09:00" max="16:00" required onChange={(e) => {
                                            setTime(e.target.value)
                                        }
                                        }
                                            onBlur={timeBlurDesk}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                        }


                    </div>
                </div>
            }
        </React.Fragment>
    );
}


export default (DeliveryFields);


