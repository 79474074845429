/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : CheckoutDetails
 * File Directory           : src/components/Checkout/
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : view checkoit details 
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CheckoutDetails        -   component for viewing checkout page for mobile
 *
 * #How to call:
 *    /checkout-page
 *
 *******************************************************************************************/


import React, { useEffect, useState } from "react";

import CircularProgress from '@mui/material/CircularProgress';
import { connect, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { initialCustInfo } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";

import Spinner from 'react-bootstrap/Spinner';

import ReactPixel from 'react-facebook-pixel'; // facebook pixel module
import ErrorModal from "../Modal/ErrorModal"; // component for showing errors
import AddressView from "../Shared/Address/AddressView"; // component for address 
import ConfirmContactModal from '../Shared/ConfirmContactModal'; // component for verifying contact
import DeliveryFields from "../Shared/DeliveryTime"; //component for delivery schedule
import Header from "../Shared/Header"; // component for header
import Payments from "./Payments";
import Voucher from "./Voucher"; // component for voucher input

import Paymongo from 'paymongo'; // module for paymongo payment

import Backend from "../APIs/Backend"; // backend api call
import Home from "../APIs/Home"; // backend api call 

import { getOrderID } from "../../actions"; //function for logs

import Cart from "../Products/CartModal"; // component for cart
import Details from "./Details"; // component for payment breakdown
import StoreCredit from "./StoreCredit";

import axios from 'axios'; // module for axios

import ReactGA from 'react-ga4';
import CourierJson from '../Shared/courier';

import moment from 'moment';
import { quoteShipment, bookShipment } from "../APIs/Shipmates";
import { SaveToDatabase } from "../Shared/SaveToDatabase";
import { SaveToSession } from "../Shared/SaveToSession";
import grabLogo from "../../assets/logo/grab.png";
import "./Checkout.css";
const style = {
    fontSize: {
        fontSize: '1.2rem'
    },
    terms: {
        textDecoration: 'none',
        color: '#24b14b',
        cursor: 'pointer'
    },
    bgColor: {
        backgroundColor: '#fff',
        zIndex: 0
    },

}

let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}

const CheckoutPage = ({ paymentSelected, total, discount, secretDiscount, CartRedux }) => {
    let source = sessionStorage.getItem('source') || ''
    let date = localStorage.getItem('date') || ''
    const isMobile = localStorage.getItem("isMobile") === "true"; // checker if mobileview
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [visibleCart, setVisibleCart] = useState(true); // show modal cart
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || ''; // if user is log in
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal
    let cartModalShow = `modal cart ${visibleCart === true ? 'showModal' : ''}`; // class for cart show modal

    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY); // paymongo secret key

    const [totalAmount, setTotal] = useState(JSON.parse(sessionStorage.getItem('total'))) // subtotal 
    const [cartVDetails, setCartVDetails] = useState(defaultItem);
    const [cartVChange, setCartVChange] = useState(0);

    const [shippingFee, setShippingFee] = useState(0) // shipping fee
    const [orderTotal, setOrderTotal] = useState(0)  // total amount
    let specialIns = sessionStorage.getItem('notes') || ''; // notes
    const [branch, setBranch] = useState(null); // branch
    const [details, setDetails] = useState([]) // payment breakdown
    const [cart, setCart] = useState(CartRedux);// cart
    let accessKey = localStorage.getItem('accessKey') // access key
    let gen_id = localStorage.getItem('gen_id') // generated id
    const [error, setError] = useState(false) // error 
    const [refresh, setRefresh] = useState(true) // refreshing page
    const [errorMsg, setErrorMsg] = useState('') // error message 
    const [freeDelivery, setFreeDelivery] = useState([]) // free delivery for voucher product
    const dispatch = useDispatch() // used for updating redux
    const history = useHistory() //  used for re routing
    const [categDel, setCategDel] = useState([]);
    let disableDel;
    const [itemNotify, setItemNotify] = useState([])
    const [confirmContactmodalStatus, setConfirmContactModalStatus] = useState(false)
    const [enableCheckoutButton, setEnableCheckoutButton] = useState(true)
    const [hasCredit, setHasCredit] = useState(false)//credit store
    const [credit, setCredit] = useState(0)
    const [disabledCOD, setDisabledCOD] = useState(false)
    const merchant_code = process.env.REACT_APP_BILLEASE_MERCHANT_CODE
    const shop_code = process.env.REACT_APP_BILLEASE_SHOP_CODE
    const token = process.env.REACT_APP_BILLEASE_TOKEN
    const billeaseUrl = process.env.REACT_APP_BILLEASE_URL
    const [address, setAddress] = useState({})
    const [shippingDate, setShippingDate] = useState("")
    const [renderShippingMethod, setRenderShippingMethod] = useState([])
    const [selectedShippingMethod, setSelectedShippingMethod] = useState({})
    const [shipmentData, setShipmentData] = useState()
    const [shippingLoading, setShippingLoading] = useState(false)

    let user;
    let userLogin;

    //if not on mobile view rederiect to checkout page for desktop view
    if (!isMobile) {
        if (window.location.pathname === '/checkout-page') {
            history.push('/checkout-details')
        }
    }

    //checker if user is logged in
    if (!getUser) {
        window.location.replace("/login#checkout")
    }
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    //getting cart from db
    const getAccount = async () => {
        let response = await Home.post('/accountChecker', { accessToken: accessKey })
        setBranch(response.data.selectedCity || [])
    }
    const getCart = async () => {
        let response = await Home.post('/cartChecker', { accessToken: accessKey })
        let itemsOnCart = response.data.cart || []
        if (itemsOnCart.length > 0) {

            let itemTotal = 0
            let itemCount = 0
            itemsOnCart.forEach(item => {
                if (item.cart_quantity) {
                    sessionStorage.removeItem("total")
                    localStorage.removeItem("cart")
                }
                if (item.item_status === "enabled") {
                    itemTotal += parseFloat(item.cart_qty) * parseFloat(item.cart_price)
                    itemCount += parseFloat(item.cart_qty)
                }
            });
            setTotal({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) })
            dispatch({
                type: "TOTAL_AMOUNT",
                total: parseFloat(itemTotal)
            })
            sessionStorage.setItem("total", JSON.stringify({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) }));
            // console.log(itemsOnCart)
            // console.log(cart)
            let checker;
            const found = [];
            itemsOnCart.forEach((cart, index) => {
                checker = cart.category;
                // found.concat(checker)
                if (checker.includes("2d")) {
                    found.push("2d")
                }
                else if (checker.includes("1d")) {
                    found.push("1d")
                }
            });
            setCategDel(found)
        }
        setCart(response.data.cart || [])
        setBranch(response.data.selectedCity || [])
    }

    useEffect(() => {

        //computing total cart price and qty
        let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
        let itemCount = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_qty : 0) // returning qty per item
        totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
        itemCount = itemCount.reduce((a, b) => a + b, 0) // total of all items
        setTotal({ itemTotal: totalPrice, itemCount: itemCount })
        sessionStorage.setItem('total', JSON.stringify({ itemTotal: totalPrice, itemCount: itemCount }))
        setCart(CartRedux)
    }, [CartRedux])

    //getting customer info
    const getCustomerInfo = async () => {
        try {
            const response = await Home.post(`/customerInfo`, { user_id: getUser.customer_id })
            dispatch(initialCustInfo(response.data[0]))
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        SaveToSession({
            page: 'Checkout Page',
            path: window.location.search
        })
        //facebook pixel
        if (source === 'FB') {
            ReactPixel.pageView();
            SaveToDatabase({
                action: {
                    type: 'pageView',
                    value: {}
                }
            })
        }
        //payload for logs
        const payload = {
            user: user,
            changes: `Zagana || Checkout`,
            type: `view_checkout_page`,
            date: `NOW()`
        }

        //checking if user has accesskey
        if (!accessKey) {
        } else {
            getCart()
            getAccount()
        }

        getCustomerInfo()
        storeFrontLogs(payload)
        setRefresh(!refresh)
    }, [])

    // getting shipping fee from db
    useEffect(() => {
        let city = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 1 }
        setBranch(city)

        //fetch shipping fee amount
        const getShippingFee = async () => {
            try {
                const response = await Home.post(`/get-shippingfee`)
                setShippingFee(response.data.sfee_price || 0)

            } catch (err) {
                console.log(err)
            }
        }
        // getShippingFee()
        sessionStorage.setItem('payment', paymentSelected.paymentSelected)
    }, [paymentSelected.paymentSelected])

    //computation for total amount if theres a discount or none
    useEffect(() => {
        //saving subtotal to redux
        dispatch({
            type: "TOTAL_AMOUNT",
            total: totalAmount.itemTotal
        })
        const today = new Date();
        function formatDate(today) {
            let d = new Date(today),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        }
        let element;
        let voucherFreedel = false
        sessionStorage.setItem("disabledCOD", "false")
        setDisabledCOD(false)
        if (cart.some(item => !["Zagana 1000 Gift Voucher", "Zagana 2000 Gift Voucher", "Zagana 300 Gift Voucher", "Zagana 500 Gift Voucher", "Zagana 800 Gift Voucher"].includes(item.item_name))) {
            voucherFreedel = false
            sessionStorage.setItem("disabledCOD", "false")
            setDisabledCOD(false)
        } else {
            voucherFreedel = true
            sessionStorage.setItem("disabledCOD", "false")
        }
        if (discount.discount_code.toLowerCase() === "freedel") {
            let totalPayment = totalAmount.itemTotal + shippingFee - 99 || 0
            setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            setFreeDelivery({
                discount: false,
                discount_amount: 0,
                discount_code: "FREEDEL"
            })
            voucherFreedel = false
            setRefresh(!refresh)
        }
        else if (voucherFreedel === true && discount.discount_code.toLowerCase() !== "freedel") {
            let totalPayment = totalAmount.itemTotal + shippingFee - 99 || 0
            setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            setFreeDelivery({
                discount: true,
                discount_amount: 99,
                discount_code: "GIFTVOUCHER"
            })
            //For gift voucher payment method - cannot use COD
            if (!hasCredit) {
                sessionStorage.setItem("disabledCOD", "true")
                setDisabledCOD(true)
            }
        }
        else if (totalAmount.itemTotal < 1000 || formatDate(today) >= "2022-01-01") {
            setFreeDelivery({
                discount: false,
                discount_amount: 0,
                discount_code: "Select code"
            })
        } else {
            let totalPayment = totalAmount.itemTotal + shippingFee - 99 || 0
            setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            setFreeDelivery({
                discount: true,
                discount_amount: 99,
                discount_code: "MAZAGANA"
            })
            // setModalPromoStatus(true)

        }
        if (discount.discount || secretDiscount.Secret_discount || hasCredit) {
            let totalPayment
            if (freeDelivery.discount) {
                totalPayment = totalAmount.itemTotal + shippingFee - (discount.discount_amount || 0) - (secretDiscount.Secret_discount_amount || 0) - (freeDelivery.discount_amount || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)

            } else {
                totalPayment = totalAmount.itemTotal + shippingFee - (discount.discount_amount || 0) - (secretDiscount.Secret_discount_amount || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)

            }
        }
        else {
            let totalPayment
            if (freeDelivery.discount_amount) {
                totalPayment = totalAmount.itemTotal + shippingFee - (freeDelivery.discount_amount || 0) - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)

            }
            else {
                totalPayment = totalAmount.itemTotal + shippingFee || 0 - credit
                setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
            }
        }
        // if (totalAmount.itemTotal >= 1000 && discount.discount_code.toLowerCase() !== "freedel" && discount.discount_code.toLowerCase() !== "walkin") {
        //     // alert(totalAmount.itemTotal)  
        //     let total = totalAmount.itemTotal + shippingFee;
        //     let finalTotal;

        //     setFreeDelivery({
        //         discount: true,
        //         discount_amount: 99,
        //         discount_code: 'FREEDEL1000'
        //     })
        //     if (discount.discount) {
        //         finalTotal = total - discount.discount_amount - 99 - credit;
        //     }
        //     else {
        //         finalTotal = total - 99 - credit;
        //     }
        //     let totalPayment = finalTotal || 0
        //     setOrderTotal(totalPayment < 0 ? 0 : totalPayment)
        // }
        let checker;
        const found = [];
        cart.forEach((cart) => {
            checker = cart.category;
            // found.push(checker)
            if (checker.includes("2d")) {
                found.push("2d")
            }
            else if (checker.includes("1d")) {
                found.push("1d")
            }
        });
        setCategDel(found)
    }, [
        total,
        cart,
        orderTotal,
        discount.discount,
        discount.discount_amount,
        dispatch,
        shippingFee,
        secretDiscount,
        discount,
        totalAmount,
        totalAmount.itemTotal,
        totalAmount.itemCount,
        freeDelivery.discount_amount,
        disableDel,
        freeDelivery.discount,
        hasCredit,
        credit,
        disabledCOD,
        CartRedux
    ]
    )
    //payment breakdown 
    useEffect(() => {
        let CheckoutDetails = [
            {
                display: true,
                title: "Subtotal " + "(" + totalAmount.itemCount + " item/s)",
                value: totalAmount.itemTotal.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
            },
            //details for shiping fee
            {
                display: true,
                title: "Shipping fee ",
                value: shippingFee.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
            },
            //details for discount
            {
                display: discount.discount,
                title: "Voucher code " + "(" + discount.discount_code + ")",
                value: Number(-discount.discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
            },
            //details for referral
            {
                display: secretDiscount.Secret_discount,
                title: "Referral code " + "(" + secretDiscount.Secret_discount_code + ")",
                value: Number(-secretDiscount.Secret_discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
            },
            {
                display: freeDelivery.discount,
                title: "Free Shipping " + "(" + freeDelivery.discount_code + ")",
                value: Number(-freeDelivery.discount_amount).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
            },
            {
                display: hasCredit,
                title: "Store Credit " + "(" + credit + ")",
                value: Number(-credit).toLocaleString('en-US', { style: 'currency', currency: 'PHP' })
            },

        ]
        setDetails(CheckoutDetails)
    }, [
        discount.discount_code,
        discount.discount_amount,
        totalAmount.itemTotal, shippingFee,
        secretDiscount.Secret_discount_code,
        secretDiscount.Secret_discount_amount,
        freeDelivery.discount,
        totalAmount,
        freeDelivery.discount_amount,
        hasCredit,
        CartRedux
    ])

    useEffect(() => {
        getShippingQuote()
    }, [address, paymentSelected.paymentSelected, shippingDate])
    const getShippingQuote = async () => {
        setShippingFee(0)
        setSelectedShippingMethod({})
        setShippingLoading(true)
        let store = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 1 }
        let date = shippingDate ?
            shippingDate
            :
            moment().isBetween(moment().format("YYYY-MM-DD 08:00"), moment().format("YYYY-MM-DD 17:00")) ? moment().format("YYYY-MM-DD") : moment().add("days", 1).format("YYYY-MM-DD")
        let rateType = date === moment().format("YYYY-MM-DD") ? "ondemand" : "ondemand"
        console.log(address)
        let data = {
            from_address: {
                contact_name: store?.admin_name,
                contact_number: store?.contact_number,
                contact_email: null,
                address1: `${store.branch_address}`,
                address2: store?.branch_brgy,
                city: store?.branch_city,
                province: "Metro Manila",
                country: "Philippines",
                zip: store?.branch_zip,
                landmark: "",
                remarks: "",
                latitude: "",
                longitude: ""
            },
            to_address: {
                contact_name: address?.add_fn || address?.receiver_name,
                contact_number: address?.add_contact || address?.receiver_contactNo,
                contact_email: null,
                address1: `${address?.address_apartment} ${address?.address_street}`,
                address2: address?.brgy_name,
                city: address?.city_name,
                province: "Metro Manila",
                country: "Philippines",
                zip: address?.address_zip,
                landmark: address?.add_landmark ?? "",
                remarks: "",
                latitude: "",
                longitude: ""
            },
            items: {},
            amount_total: orderTotal,
            amount_cod: paymentSelected.paymentSelected === "COD" ? orderTotal : 0
        };
        setShipmentData(data)
        let result = await quoteShipment(data)
        let renderShippingMethod = result.data.rates[rateType].filter((data) => { return data.courier_service_name.match(/20kg/gi) || data.courier_service_name.match(/pouch/gi) })
        setRenderShippingMethod(renderShippingMethod)
        setShippingLoading(false)

    }
    const handleShippingClick = async (data) => {
        setShippingFee(data.fees.shipping_fee + data.fees.shipmates_fee + (data.fees?.cod_fee ?? 0) + (data.fees?.valuation_fee ?? 0))
        setSelectedShippingMethod(data)
    }
    // checkout button on click
    const onClickCheckout = async () => {
        //facebook pixel
        if (source === 'FB') {
            ReactPixel.track('InitiateCheckout');
            SaveToDatabase({
                action: {
                    type: 'InitiateCheckout',
                    value: {}
                }
            })
            console.log('react pixel'.ReactPixel);
        }
        setEnableCheckoutButton(false)
        const address = sessionStorage.getItem('Address') === '{}' || sessionStorage.getItem('Address') === null;
        const time = sessionStorage.getItem('deliveryTime') || null
        const date = sessionStorage.getItem('deliveryDate') || null
        const payment = paymentSelected.paymentSelected === 'Select payment'
        specialIns = sessionStorage.getItem('notes') || ''; // notes
        let delivery;

        let pixelItems = []
        let pixelContent = []
        const GACart = CartRedux.map((item, index) => {
            pixelItems.push(item.item_sku)
            pixelContent.push({
                id: item.item_sku,
                quantity: item.cart_qty
            })
            return {
                item_id: item.item_sku,
                item_name: item.item_name,
                currency: "PHP",
                index: index,
                item_brand: "Zagana",
                item_category: item.category,
                item_variant: item.item_var,
                price: item.item_price,
                quantity: item.cart_qty
            }
        })

        localStorage.setItem('fbPixelItems', JSON.stringify(pixelItems))
        localStorage.setItem('fbPixelContent', JSON.stringify(pixelContent))
        window.gtag("event", "begin_checkout", {
            currency: "PHP",
            value: totalAmount,
            items: GACart
        });

        if (time !== null && date !== null) {
            delivery = date + " " + time
        }
        const dateTime = (delivery === '' || delivery === undefined)
        if (address) {
            setError(true)
            setEnableCheckoutButton(true)
            setErrorMsg('Please select address')
            setTimeout(() => {
                setError(false)
                setErrorMsg('')
            }, 3000)
        } else if (dateTime) {
            setError(true)
            setEnableCheckoutButton(true)
            setErrorMsg('Please select time and date')
            setTimeout(() => {
                setError(false)
                setErrorMsg('')
            }, 3000)
        } else if (payment) {
            setError(true)
            setEnableCheckoutButton(true)
            setErrorMsg('Please select payment method')
            setTimeout(() => {
                setError(false)
                setErrorMsg('')
            }, 3000)
        } else if (totalAmount.itemCount === 0 || cart === null || cart === undefined || cart === "[]" || cart.length === 0) {
            setError(true)
            setEnableCheckoutButton(true)
            setErrorMsg('No items remaining')
            setTimeout(() => {
                setError(false)
                setErrorMsg('')
            }, 3000)
        } else if (selectedShippingMethod === null || selectedShippingMethod === {} || Object.keys(selectedShippingMethod).length === 0) {
            setError(true)
            setEnableCheckoutButton(true)
            setErrorMsg('Please select shipping method')
            setTimeout(() => {
                setError(false)
            }, 2000)
        } else {
            setError(false)
            sessionStorage.setItem('orderTotal', orderTotal)
            let discountAmount = 0
            let discountName = ''
            // if (discount.discount_code !== 'Select code' || secretDiscount.Secret_discount_code !== 'Select code') {
            //     discountAmount = parseFloat(discount.discount_amount) + parseFloat(secretDiscount.Secret_discount_amount)
            //     discountName = discount.discount_code + "(" + discount.discount_amount + ")" + secretDiscount.Secret_discount_code + "(" + secretDiscount.Secret_discount_amount + ")"
            // } 
            if (discount.discount_code !== 'Select code') {
                discountAmount = parseFloat(discount.discount_amount)
                discountName = discount.discount_code + "(" + discount.discount_amount + ")"
            }
            if (freeDelivery.discount) {
                discountAmount += freeDelivery.discount_amount
                if (discount.discount_code !== 'Select code') {
                    discountName += ' '
                }
                discountName += (freeDelivery.discount_code + `(${freeDelivery.discount_amount})`)
                // alert(discountName)
            }
            if (hasCredit) {
                discountName += `StoreCredit(${credit.toFixed(2)})`
            }
            const custInfo = sessionStorage.getItem('Login')
            const parsedCustInfo = JSON.parse(custInfo)
            const custAddress = sessionStorage.getItem('Address')
            const parsedCustAddress = JSON.parse(custAddress)
            // const itemCart = localStorage.getItem('cart')
            // const parsedItemCart = JSON.parse(itemCart)
            const selectedCity = localStorage.getItem('SelectedCity')
            const parsedSelectedCity = JSON.parse(selectedCity)
            const paymentMethod = sessionStorage.getItem('payment')
            const customerAddress = [parsedCustAddress]
            // const cart = parsedItemCart
            const branchId = parsedSelectedCity.branch_id
            const payment = paymentMethod
            const totalAmount = parseFloat(orderTotal).toFixed(2)
            const sfee = shippingFee
            const deliveryDate = delivery
            const origin = window.location.origin
            let type = ''
            const customerInfo = [{
                customer_id: parsedCustInfo.customer_id,
                customer_name: parsedCustInfo.customer_name,
                customer_email: parsedCustInfo.customer_email,
                customer_contactNo: parsedCustInfo.customer_phone
            }]
            const body = {
                customerAddress,
                cart,
                branchId,
                payment,
                totalAmount,
                discountAmount,
                discountName,
                sfee,
                credit,
                customerInfo,
                deliveryDate,
                specialIns: specialIns,
                accessKey,
                selectedShippingMethod,
                origin
            }
            if (payment === 'GCash') {
                type = 'gcash'
            } else {
                type = 'grab_pay'
            }
            //data for paymongo payment (Gcash/Grabpay)
            const doubleTotalAmount = totalAmount * 100
            const data = {
                data: {
                    attributes: {
                        type: type,
                        amount: parseInt(doubleTotalAmount),
                        currency: 'PHP',
                        redirect: {
                            success: window.location.origin + '/success',
                            failed: window.location.origin + '/failed'
                        }
                    }
                }
            }
            try {

                // insert checkout data to database
                const response = await Home.post(`/checkout`, body)
                //if successfully inserted to database
                if (response.data.status === "OK") {
                    ReactGA.event({
                        'category': 'Checkout',
                        'action': 'Place Order',
                    });

                    if (source === "Tiktok") {
                        SaveToDatabase({
                            action: {
                                type: 'PlaceAnOrder',
                                value: {}
                            }
                        })
                        window.ttq.track('PlaceAnOrder', {
                            content_type: "product",
                            quantity: GACart.map(p => p.quantity).reduce((a, b) => a + b, 0),
                            description: discountName + discountAmount + hasCredit ? "STORECREDIT" + credit : "",
                            content_id: response.data.job_order,
                            currency: "PHP",
                            value: totalAmount
                        })
                    }
                    dispatch(getOrderID(response.data.job_order))
                    let shipData = shipmentData
                    shipData.courier_service = selectedShippingMethod.courier_service
                    //saved data to session storage
                    sessionStorage.setItem('order', JSON.stringify(response.data))
                    sessionStorage.setItem('selectedPaymentMethod', JSON.stringify(shipData))

                    //if debit/credit is selected as payment method
                    if (payment === 'Debit/Credit') {
                        dispatch({
                            type: 'SET_LOCATION',
                            location: '/paymongo/debit-credit'
                        })
                        history.push('/paymongo/debit-credit')
                        //if cod is selected as payment method
                    } else if (payment === 'COD') {
                        let result = await bookShipment(shipData)
                        const response_shipment = await Home.post(`/save_courier_info`, { order_id: response.data.check_id, courier: result.data })
                        dispatch({
                            type: 'SET_LOCATION',
                            location: '/thank-you'
                        })
                        history.push('/thank-you')

                    } else if (payment === 'Atome') {
                        if (response.status === 200) {
                            let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                            let url = response.data.atomeData.redirectUrl
                            const details = {
                                paymentId: response.data.job_order,
                                checkID: orderDetails.check_id,
                                paymentMethod: "Atome"
                            }
                            const savePaymentID = async () => {
                                try {
                                    const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                    if (res.status === 200) {
                                        window.location.href = url
                                    }
                                } catch (err) {
                                    console.log(err)
                                }
                            }
                            savePaymentID()
                        }
                    } else if (payment === 'LandBank') {
                        dispatch({
                            type: 'SET_LOCATION',
                            location: '/landbank-form'
                        })
                        history.push('/landbank-form')
                    }
                    //if ub is selected as payment method
                    else if (payment === 'UB') {
                        dispatch({
                            type: 'SET_LOCATION',
                            location: '/xendit/direct-debit'
                        })
                        history.push('/xendit/direct-debit')
                    }
                    //if BPI is selected as payment method
                    else if (payment === 'BPI') {
                        dispatch({
                            type: 'SET_LOCATION',
                            location: '/xendit/direct-debit'
                        })
                        history.push('/xendit/direct-debit')
                    }
                    //if GCASH is selected as payment method
                    else if (payment === 'GCash') {
                        const result = await paymongo.sources.create(data);
                        const details = {
                            id: result.data.id,
                            redirect: result.data.attributes.redirect.checkout_url
                        }
                        let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                        const body = {
                            payID: result.data.id,
                            totalAmount: totalAmount,
                            checkID: orderDetails.check_id,
                            paymentMethod: payment,
                            accessKey: accessKey
                        }
                        await Home.post(`/paymongo/payment/payID`, body)
                        const stringifyData = JSON.stringify(details)
                        sessionStorage.setItem('paymongo', stringifyData)
                        // history.push({pathname : `${details.redirect}`})
                        window.location.href = details.redirect
                        //if Grabpay is selected as payment method
                    } else if (payment === 'Grabpay') {
                        const result = await paymongo.sources.create(data);
                        const details = {
                            id: result.data.id,
                            redirect: result.data.attributes.redirect.checkout_url
                        }
                        let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                        const body = {
                            payID: result.data.id,
                            totalAmount: totalAmount,
                            checkID: orderDetails.check_id,
                            paymentMethod: payment,
                            accessKey: accessKey
                        }
                        await Home.post(`/paymongo/payment/payID`, body)
                        const stringifyData = JSON.stringify(details)
                        sessionStorage.setItem('paymongo', stringifyData)
                        // history.push('/paymongo/source')
                        window.location.href = details.redirect
                        //if paymaya is selected as payment method
                    } else if (payment === 'Paymaya') {
                        let orderDetails = JSON.parse(sessionStorage.getItem('order'))
                        let auth = process.env.REACT_APP_PAYMAYA
                        const options = {
                            method: 'POST',
                            url: 'https://pg.paymaya.com/payby/v2/paymaya/payments',
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                                Authorization: auth
                            },
                            data: {
                                totalAmount: { value: totalAmount, currency: 'PHP' },
                                redirectUrl: {
                                    success: window.location.origin + '/thank-you',
                                    failure: window.location.origin + '/user-profile',
                                    cancel: window.location.origin + '/user-profile'
                                },
                                metadata: {
                                    pf: {
                                        smi: '101065221',
                                        smn: 'Zagana Inc.',
                                        mci: 'Quezon City',
                                        mpc: '608',
                                        mco: 'PHL'
                                    }
                                },
                                requestReferenceNumber: orderDetails.job_order
                            }
                        };

                        axios.request(options).then(function (response) {
                            const url = response.data.redirectUrl
                            if (response.status === 200) {
                                const details = {
                                    paymentId: response.data.paymentId,
                                    checkID: orderDetails.check_id,
                                    paymentMethod: payment
                                }
                                const savePaymentID = async () => {
                                    try {
                                        const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                        if (res.status === 200) {
                                            window.location.href = url
                                        }
                                    } catch (err) {
                                        console.log(err)
                                    }
                                }
                                savePaymentID()
                            }
                        }).catch(function (error) {
                            console.error(error);
                        });
                    } else if (payment === "Billease") { // Billease Payment method
                        let orderDetails = JSON.parse(sessionStorage.getItem('order')) // order details 
                        let voucherAmount = discount.discount_amount // voucher amount
                        let voucherCode = discount.discount_code // voucher code
                        let customerName = getUser.customer_name // customer name
                        let customerEmail = getUser.customer_email // customer email
                        let customerPhone = getUser.customer_phone // customer phone number
                        let orderID = response.data.job_order // order ID
                        let freeDel = freeDelivery.discount_code // free del code

                        let filteredcart = cart.filter((item) => {
                            return item.item_status === "enabled" ? item : null
                        })
                        const renderCart = filteredcart.map(item => { // render cart items
                            // item varialbles
                            let itemSku = item.item_sku
                            let itemName = item.item_name
                            let itemPrice = item.cart_price
                            let itemQty = item.cart_qty


                            const item_details = {  // Cart item details
                                code: itemSku,
                                item: itemName,
                                price: parseFloat(itemPrice),
                                quantity: itemQty,
                                currency: "PHP",
                                seller_code: "ZGN"
                            }
                            return item_details
                        })
                        const deliveryFee = { // add Delivery fee in item (defautlt)
                            code: "Shipping Fee",
                            item: "Delivery Fee",
                            price: 99,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }
                        const customerFreeDel = { // add free delivery deduction in item
                            code: freeDel,
                            item: `Free delivery: ${freeDel}`,
                            price: -99,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }
                        const customerDiscount = { // add Discount/Voucher deduction in item
                            code: voucherCode,
                            item: `Voucher: ${voucherCode}`,
                            price: -voucherAmount,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }
                        const customerStoreCred = { // add StoreCredit in deduction item
                            code: 'zgnstorecredit',
                            item: `Store Credit: ${credit}`,
                            price: -credit,
                            quantity: 1,
                            currency: "PHP",
                            seller_code: "ZGN",
                            item_type: "fee"
                        }

                        // push delivery fee to item
                        renderCart.push(deliveryFee)
                        if (totalAmount > 500) { // push delivery fee to item if subtotal greater or equal to 500
                            console.log("Pasok")
                            renderCart.push(customerFreeDel)
                        }
                        if (discount.discount_amount) { // push voucher to item if voucher true
                            renderCart.push(customerDiscount)
                        }
                        if (hasCredit) { // push store credit to item if voucher true
                            renderCart.push(customerStoreCred)
                        }

                        const body = { // Payloads
                            "merchant_code": merchant_code, // merchant code
                            "shop_code": shop_code, // shop code
                            "amount": parseFloat(totalAmount), // total amount === items total amount
                            "currency": "PHP",
                            "checkout_type": "standard",
                            "seller_code": "ZGN",
                            "items": renderCart, // items list
                            "sellers": [ // seller details
                                {
                                    "code": "ZGN",
                                    "seller_name": "Zagana inc.",
                                    "url": "http://zagana.com"
                                }
                            ],
                            "customer": { // customer details
                                "full_name": customerName,
                                "email": customerEmail,
                                "phone": customerPhone,
                                "internal_user_id": customerEmail,
                            },
                            // "callbackapi_url": `${process.env.REACT_APP_ZAGANA_URL}api/billease/trx/webhook`,
                            "callbackapi_url": `https://api.zagana.com/api/billease/trx/webhook`,
                            "url_redirect": window.location.origin + "/thank-you",
                            "failed_url_redirect": window.location.origin + "/checkout-page",
                            "order_id": orderID // order id
                        }
                        const options = { // axios options
                            "method": 'POST',
                            "url": billeaseUrl + '/be-transactions-api/trx/checkout',
                            "headers": {
                                "Content-Type": 'application/json',
                                "Authorization": `bearer ${token}`
                            },
                            "data": body
                        };
                        // if (totalAmount > 150 && parseFloat(total.itemTotal) > 150) { // minimum of 150php to make transaction condition
                        axios.request(options).then(function (response) { // connection to Billease
                            const url = response.data.redirect_url // url 
                            if (response.status === 200) {
                                // variables
                                let paymentID = response.data.trxid
                                let checkID = orderDetails.check_id
                                const details = { // payment details
                                    paymentId: paymentID,
                                    checkID: checkID,
                                    paymentMethod: payment
                                }
                                const savePaymentID = async () => { // save payment ID 
                                    try {
                                        const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                        if (res.status === 200) { // redirect to billease payment
                                            window.location.href = url
                                        }
                                    } catch (err) {
                                        console.log(err)  // log error
                                    }
                                }
                                savePaymentID() // run save payment
                            }
                        }).catch(function (error) {
                            setErrorMsg(error.message) // error message
                            setError(true)
                            setEnableCheckoutButton(true) //show error message
                            setTimeout(() => {
                                setError(false) // hide error message
                            }, 1200)
                        });
                        // } else { // minimum value error 
                        //     setErrorMsg(`Sorry, A minimum value of 150php in Subtotal and Total Payment to proceed Transaction`) // error message
                        //     setError(true)
                        // setEnableCheckoutButton(true) //show error message
                        //     setTimeout(() => {
                        //         setError(false) // hide error message
                        //     }, 2000)
                        // }
                    }
                }
            } catch (err) {
                if (err.detail === "The value for amount cannot be less than 10000." && err.code === "parameter_below_minimum") {
                    setErrorMsg('The value for amount cannot be less than 100.')
                    setError(true)
                    setEnableCheckoutButton(true)
                    setTimeout(() => {
                        setError(false)
                    }, 2000)
                }
            }
        }
    };

    const onBlurNotes = (e) => {
        //saving special instruction to sessionStorage
        sessionStorage.setItem('notes', e.target.value || '')
    }
    return (
        <div id="CO_Page" className="p-0">

            <div style={{ position: 'relative', zIndex: '2' }} className="p-0" >
                <div style={{ display: '' }}>
                    <Header
                        branch={branch}
                        setBranch={setBranch}
                        total={total}
                        visibleLogin={visibleLogin}
                        setVisibleLogin={setVisibleLogin}
                        visibleCart={visibleCart}
                        setVisibleCart={setVisibleCart}
                    />
                </div>
            </div>
            <div className="p-4" id="checkOutModal">
                <div className="row">
                    <h5 className="w-75" >
                        <b>Set Address</b>
                    </h5>

                    <Link
                        to="/address/add"
                        className="w-25 btn activeDate  "
                        style={{ "textAlign": "center" }}
                    >
                        <b>ADD</b>
                    </Link>
                    <AddressView setCheckoutAddress={setAddress} />
                </div>
                <div className="row mt-3">
                    <h5><strong>Time and Date</strong></h5>
                    <DeliveryFields disableDel={categDel} setShippingDate={setShippingDate} />
                </div>
                <div className="row">
                    <h5><strong>Payment method</strong></h5>
                    <Payments orderTotal={orderTotal} disabledCOD={disabledCOD} />
                </div>
                <div className="row">
                    <h5><strong>Shipping Method</strong></h5>
                    <div className="row ">
                        {
                            shippingLoading ?
                                <div style={{ textAlign: "center" }}>
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                                :
                                renderShippingMethod.map((data) =>
                                    <div className="col-sm-3 p-1">
                                        <div className="container mt-2">
                                            <div className="card card-payment m-1 " onClick={() => handleShippingClick(data)}
                                                style={data.courier_service === selectedShippingMethod.courier_service ? {
                                                    outlineColor: "rgb(36, 177, 75)",
                                                    outlineStyle: "solid",
                                                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem"
                                                } : {}}
                                            >
                                                <div className="card-body p-1">
                                                    <div className="row text-center">
                                                        <div className="row-6">
                                                            <div style={{ minHeight: '49px' }}>
                                                                <img src={data.courier === "Grab" ? grabLogo : CourierJson[data.courier].img} width="50%" alt="paymaya" style={style.paymayaimg} />
                                                            </div><p style={{ minHeight: '50px', maxHeight: '50px' }}>{data.courier_service_name}</p>
                                                            Php {((data.fees.shipping_fee + data.fees.shipmates_fee + (data.fees?.cod_fee ?? 0) + (data.fees?.valuation_fee ?? 0)) || 0).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)
                        }
                    </div>
                </div>

                <div className="row">
                    <h5><strong>Voucher / Referral</strong></h5>
                    <Voucher totalOrder={orderTotal} shippingFee={shippingFee} />
                </div>
                <div className="row">
                    <h5><strong>Store Credit</strong></h5>
                    <StoreCredit totalOrder={orderTotal} subTotal={totalAmount.itemTotal} setHasCredit={setHasCredit} setCredit={setCredit} disabledCOD={disabledCOD} />
                </div>
                {/* <div className="row">
                    <h5><strong>Referral</strong></h5>
                    <Referral />
                </div> */}

                <div className="row p-2 mb-2 text-center">
                    <div className="col-12 mt-3 w-100">
                        <span>Special Instructions</span>
                    </div>
                    <div className="col-12 w-100">
                        <textarea
                            className="form-control w-100"
                            onBlur={onBlurNotes}
                            defaultValue={specialIns}

                        />
                    </div>
                </div>
                <ErrorModal btn={error} body={errorMsg} />

                <div
                    className="fixed-bottom mb-5 p-2"
                    style={style.bgColor}
                >
                    <div className="row">
                        <div
                            className="col text-center my-auto"
                        >
                            <strong style={style.fontSize}>Total Payment: {orderTotal.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</strong>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col m-0 text-center ">
                            <button id="place_order" className="btn btn-success btn-primary" onClick={enableCheckoutButton ? onClickCheckout : null} disabled={!enableCheckoutButton}>
                                {
                                    enableCheckoutButton ? 'Place Order'
                                        :
                                        <CircularProgress
                                            size={16} style={{ color: 'white' }} />
                                }
                            </button>
                            <span style={{ fontSize: '.65rem' }}>
                                By confirming your order, you agree to the <Link to='/ContentPage/terms' style={style.terms}> Terms & Conditions</Link>.
                            </span>
                        </div>
                    </div>
                </div>

                <Cart cartModalShow={cartModalShow} cart={cart} setCart={setCart} visibleCart={visibleCart} setVisibleCart={setVisibleCart} total={totalAmount} setTotal={setTotal} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange}

                    confirmContactmodalStatus={confirmContactmodalStatus}
                    setConfirmContactModalStatus={setConfirmContactModalStatus}
                    setItemNotify={setItemNotify}
                    setVisibleLogin={setVisibleLogin}
                />

                <ConfirmContactModal
                    confirmContactModalStatus={confirmContactmodalStatus}
                    setConfirmContactModalStatus={setConfirmContactModalStatus}
                    itemNotify={itemNotify}
                    setItemNotify={setItemNotify}
                    setVisibleLogin={setVisibleLogin}
                />
            </div>
            <div className="table-details">
                <Details details={details} />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        paymentSelected: state.payment,
        total: state.total.total,
        discount: state.discount,
        secretDiscount: state.secretDiscount,
        CartRedux: state.cart.CartList,
    }
}

export default connect(mapStateToProps)(CheckoutPage);