
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ThankYou
 * File Directory           : src/components/Checkout/ThankYou
 * Module                   : Checkout Page
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To know that the order is complete
 * Used In				    : 
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ThankYou        -   component for Thank you Page 
 *
 * #How to call:
 *    <ThankYou />
 *
 *******************************************************************************************/

 import React, { useEffect ,useState } from 'react';
 import ReactPixel from 'react-facebook-pixel';
 import Header from "../../Shared/Header"
 import ZaganaLogo from '../../Shared/ZaganaLogo';
 import OrderSuccess from '../../../assets/site_icons/OrderSuccess.svg'
 import Backend from '../Backend';
 import { storeFrontLogs } from '../Logs';
 import Home from "../Home";
 import { connect, useDispatch } from "react-redux";
 import { useParams,useHistory,Link } from "react-router-dom";
 import axios from 'axios';
 
 const style={
     center:{
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
         height:'100vh',
         width: '100vw'
     },
     link:{
         textDecoration:'none'
     }
 }
 
 const styles = {
     container: {
         height: "100vh",
         padding: "3rem"
     },
     headerBG: {
         'backgroundColor': '#facc02'
     }
 }
 function LandBankCallback({ id, paymongo ,landbankId,token}) {
     ReactPixel.pageView();
     document.title = "Zagana | Thank You"
     const [generatedToken, setGeneratedToken] = useState('') // error msg
     const [statusFailed, setStatusFailed] = useState(false) // error msg
     const dispatch = useDispatch()
     const history = useHistory()
     const param = useParams();
     landbankId = param.orderID
     const isMobile = localStorage.getItem("isMobile") === "true"
     const payment = sessionStorage.getItem('payment') === 'COD'
     const paymaya = sessionStorage.getItem('payment') === 'Paymaya'
     const landBank = sessionStorage.getItem('payment') === 'LandBank'

     const totalAmount = JSON.parse(sessionStorage.getItem('orderTotal'))

     const accessKey = localStorage.getItem('accessKey')
     const orderDetails = JSON.parse(sessionStorage.getItem('order'))
     let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
     let gen_id = localStorage.getItem('gen_id')
     let user;
     let userLogin;
   
     if (getUser) {
         userLogin = JSON.parse(sessionStorage.getItem('Login'))
         gen_id = localStorage.getItem('gen_id')
         user = userLogin.customer_name + ` (${gen_id})`
     }else if(gen_id){
         user = gen_id
     }
     const onClickHome = () => {
         window.location = "www.zagana.com"
     }
     
     console.log(token)
 
     useEffect(() => {
        console.log("eyy")
        let data = JSON.stringify({
            "clientId": process.env.REACT_APP_LANDBANK_ID,
            "secret": process.env.REACT_APP_LANDBANK_SECRET
        });

        let config = {
            method: 'post',
            url: 'https://landbankpay.bank-genie.co:7004/api/Ecommerce/Token',
            headers: {
                'Identity': '6d5a74c940694d668aaaae6b402b4ee71cca906bc6ce48c39002fae9d72ae384-IDTK45E291-112701',
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                let token = response.data.body.token;

                dispatch({
                    type: "accessToken",
                    token: token
                })
                setGeneratedToken(token);
                console.log("landbankId")
                // if(landBank){
                    console.log(landbankId)
                    
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", `Bearer ${token}`);
                    myHeaders.append("Content-Type", "application/json");
            
                    var raw = JSON.stringify({
                        "clientId": process.env.REACT_APP_LANDBANK_ID,
                        "secret": process.env.REACT_APP_LANDBANK_SECRET,
                        "orderId": landbankId
                    });
            
                    var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                    };
            
                    fetch("https://landbankpay.bank-genie.co:7004/api/PaymentGateway/OrderStatus", requestOptions)
                    .then(response => response.text())
                    .then(async function (response) { 
                        let body=JSON.parse(response)
                        body = body.body
                        console.log(body)
                        if(body.code==="00"){
                            // history.push("/failed")
                            const body = {
                                payID: landbankId,
                                totalAmount: totalAmount,
                                checkID: orderDetails.check_id,
                                paymentMethod: payment,
                                accessKey: accessKey
                            }
                            const response = await Home.post(`/paymongo/payment/succeeded`, body)
                            history.push("/thank-you")
                        }else{
                            setStatusFailed(true)
                        }
                    })
                // }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
     return (
         <React.Fragment>
             {statusFailed ?
                <React.Fragment>
                     <Header title="" />
                    <div className="container" style={style.center}>
                        <span 
                            className=" border rounded p-5 alert alert-danger"               
                        >
                            <div className="row text-center">
                                <h1>Payment Failed</h1>
                            </div>
                            <div className="row text-center">
                                <span>
                                    Selected Payment method <br/> is not available at this moment.
                                </span>
                            </div>
                            <div className="row text-center">
                                <Link
                                    to="/user-profile"
                                    style={style.link}    
                                >
                                    Please try again.
                                </Link>
                            </div>
                        </span>
                    </div>
                </React.Fragment>
                 :
                 null
             }
         </React.Fragment>
     )
 }
 
 const mapStateToProps = (state) => {
     return ({
         id: state.OrderID.order_id,
         token: state.LandBank.token,
         landbankId: state.LandBank.generatedOrderID
     })
 }
 
 export default connect(mapStateToProps)(LandBankCallback);