/*******************************************************************************************
//  * File Name                : ReorderModal
//  * File Directory           : src/components/Home/ReorderModal
//  * Module                   : ReorderModal
//  * Author                   : Jake
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : ItemList
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ReorderModal        -   component for pop up item details
 *
 * #How to call:
 *    <ReorderModal/>
 *
 * Note:
 *******************************************************************************************/
 import React, { useState, useRef, useEffect } from "react";
 import UseOutsideClick from "../Shared/UseOutsideClick"
 import ConfirmContactModal from '../Shared/ConfirmContactModal';
 import ReactPixel from 'react-facebook-pixel';
 import Home from '../APIs/Home';
 import { useHistory } from 'react-router-dom';
 import _ from 'lodash'
 const style = {
   addButtonColor: {
     border: "2px solid #24b14b",
     borderRadius: "25px",
     background: "#24b14b",
     color: "white",
     fontWeight: "bold",
     fontSize: "14px",
     padding: "0",
     width: "170px",
     height: "39.5px",
     float: "right"
   },
   itemModal: {
     'transition': 'all 0.8s ease',
   },
   showModal: {
     'display': 'block',
     'background': 'rgba(0, 0, 0, 0.5)',
     'transition': 'all 0.8s ease',
     'top': '0px',
   }, modalDialog: {
     'margin': '1.5rem'
   },
   modalContent: {
     'height': 'auto'
   },
   textField: {
     'textAlign': "center",
     'float': "right",
     'background': "transparent",
     'margin': "3px"
   },
   textItemTitle: {
     fontSize: "1.1rem"
   },
   textItemVariant: {
     fontSize: "0.8rem"
   },
   textItemPrice: {
     fontSize: "0.9rem"
   },
   textDescription: {
     width:"100%",
     border:'none',
     background:'transparent',
     whiteSpace:'pre-wrap',
     overflowWrap:'break-word',
     lineHeight:'1.5'
   },
   incrementButtonColor: {
     border: "2px solid #24b14b",
     borderRadius: "25px",
     background: "#24b14b",
     color: "white",
     fontWeight: "bold",
     float: "right",
     fontSize: "14px",
     padding: "0",
     width: "30px",
     height: "30px"
   },
   decrementButtonColor: {
     border: "2px solid #24b14b",
     borderRadius: "25px",
     background: "#white",
     color: "#24b14b",
     fontWeight: "bold",
     float: "right",
     fontSize: "14px",
     padding: "0",
     width: "30px",
     height: "30px"
   }, closeButton: {
     float: 'right'
   },
   headerItemTitle :{
     color: "#222",
     textDecoration: "none",
     fontSize: "20px"
   }
 
 }
 let defaultItem = {
   item_id: 0,
   item_name: "Product Name",
   item_variant: "500g",
   item_price: 300,
   item_status:"enabled",
   item_description: "Description here - 500g",
   item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
 }
 const ReorderModal = ({ setReorderDetail, reorderDetail,outOfStockItems, setReorderModalStatus, reorderModalStatus}) => {
    const ref = useRef();
    const [delayStatus, setDelayStatus] = useState(false); // show modal and delay on closing it.
    const [userEmail, setUserEmail] = useState("Customer Email"); // show modal and delay on closing it.
    const [userNumber, setUserNumber] = useState("Customer Number"); // show modal and delay on closing it.
    const [confirmContactmodalStatus, setConfirmContactModalStatus] = useState(false)
    const [itemDetailModal, setItemDetailModal] = useState(defaultItem)
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    const [itemNotify, setItemNotify] = useState([])
    const [items, setItems] = useState([])
    let oos=0;
    const [errors, setErrors] = useState({
        email:"",
        phoneNumber:""
    }); 
    const history = useHistory()
    let accessKey = localStorage.getItem('accessKey')
    const isMobile = localStorage.getItem("isMobile") || "true"
    let body = document.body; // for setting body height (modal)
   //for clicking outside close modal
   UseOutsideClick(ref, () => {
     if (delayStatus) {
        setReorderDetail([])
       setReorderModalStatus(false)
       if (!window.location.href.includes('checkout-details'))
         window.history.pushState({}, document.title, "/");   // window.location.hash=item.item_name.replace(/ /g,'');
       else
         window.history.pushState({}, document.title, "/checkout-details");
     }
   });
   //timing out of opening and closing of modal
   setTimeout(() => {
     setDelayStatus(reorderModalStatus)
   }, 100);

   //trigger close modal
   const closeModal = () => {
    setReorderDetail([])
     setReorderModalStatus(false)
     if (!window.location.href.includes('checkout-details'))
       window.history.pushState({}, document.title, "/");   //removeItem Name on URL
     else
       window.history.pushState({}, document.title, "/checkout-details");
   }
   
    const submitNotify = async (e) => {
        e.preventDefault()
    }
    
    //map from database ordered products
    const renderedProducts = outOfStockItems.map((prod, index) => {
      if(prod.item_status==="enabled")
        return (
              <li>
                  {prod.item_name}
              </li>
            


        )
    });
    const oosRenderedProducts = outOfStockItems.map((prod, index) => {
      if(prod.item_status!=="enabled"){
        oos++
        return (
              <li style={{color:"gray"}}>(Out of stock) {prod.item_name}
              </li>
        )
      }
    });
    //on click Notify me/update me
    const btnNotifyMe = async () => {
      if(isLogged){
        setConfirmContactModalStatus(true)
        setItemNotify(outOfStockItems)
        // if(userEmail!==null&&userEmail!==""){
        //   console.log(item.item_sku)
        //   await Home.post('/notifyItem/add', {
        //     userId: userId,
        //     item_sku: item.item_sku,
        //     item_branch: item.item_branch
        //   })
        // }else{
        //   alert("kindly input Email")
        // }
      }
      
    }
   return (
     <React.Fragment>
 
        <div id="itemModal">
            <div className='modal'
           style={reorderModalStatus ? style.showModal : {}}
           tabIndex="-1"
           role="dialog">
                <div className="modal-dialog modal-md" style={style.modalDialog} role="document" ref={ref}>
                    <div id="modal-content" className="modal-content" style={style.modalContent}>
                        <div className="modal-header">
                            <h5>Re-Order {reorderDetail.order_number}</h5>
                            <div className="float-right">
                              <button className='btn btn-transparent'
                                  style={style.closeButton}
                                  onClick={closeModal}
                              >x</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div>
                                <form onSubmit={submitNotify} name="ProductForm">
                                    <div>
                                      {oos>0 ?
                                      <>
                                          <div className="row">
                                            <div className="col-md-8">
                                              <p>Please take note that some of the product{oos>1 ?'s are':' is'} unavailable:
                                              </p>
                                            </div>
                                            <div className="col-md-4">
                                            </div>
                                          </div>
                                        {oosRenderedProducts}
                                        <div className="row justify-content-end mt-3">
                                        {/* <button 
                                          style={{borderRadius: "25px",cursor:"not-allowed",fontSize:"12px",width:"95px",marginRight:"2rem"}} 
                                          className={`btn btn-secondary mt-3 mb-1 p-0 pt-2 pb-2`}
                                        >Notify Added
                                        </button> */}
                                          <div className={`${isMobile?'col':`col-3`}`}>
                                            <button 
                                              style={{
                                                background:"#facc09",
                                                width:"95px",
                                                fontSize:"14px",
                                                float:isMobile?"right":"unset"
                                                
                                              }} 
                                              className={`btn p-0 pt-2 pb-2`}
                                              onClick={btnNotifyMe}
                                            >Notify Me
                                            </button>
                                          </div>
                                        </div>
                                        <hr/>
                                      </>
                                      :
                                      ''}
                                      <h5>Do you want to Re-order {reorderDetail.order_number}</h5>
                                      {renderedProducts}
                                    </div>
                                    <br/>
                                        <button 
                                            className="btn"
                                            style={{
                                              background:"#24b14b",
                                              color:"white",
                                              float:"right",
                                              fontSize:"14px"
                                            }}
                                            onClick={(()=>{
                                                
                                                Home.post('/reorder', { 
                                                    access_key: accessKey,
                                                    order_id:reorderDetail.order_id
                                                })
                                                history.push("/checkout-details");
                                            })}
                                        >
                                            Re-Order
                                        </button>
                                        <button 
                                            className="btn btn-transparent"
                                            style={{
                                              float:"left",
                                              float:"right",
                                              fontSize:"14px"
                                            }}
                                            onClick={closeModal}
                                        >
                                            Maybe Later
                                        </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          <ConfirmContactModal
              confirmContactModalStatus={confirmContactmodalStatus}
              setConfirmContactModalStatus={setConfirmContactModalStatus}
              itemDetailModal={itemDetailModal}
              itemNotify={itemNotify}
              setItemNotify={setItemNotify}
              mode={"Reorder"}
          />
     </React.Fragment>
   );
 };
 
 export default ReorderModal;