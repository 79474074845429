const INTIAL_STATE = {
    CartList: []
}

const CartReducer = (state = INTIAL_STATE, action) => {
    let cartLimit={
        basic:50,
        flash:2
    }
    let limit=action.flash?cartLimit.flash:cartLimit.basic
    switch (action.type) {
        case 'SET_CART':
            return {
                ...state,
                CartList: action.selected
            };
        case 'ADD_ITEM' :
            return { 
                ...state,
                CartList: [...state.CartList, action.newItem]
            }
        case 'UPDATE_QUANTITY': {
            let list =state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.filter((item) => {
                    if (item.item_id === action.id) {
                        return action.value > 0 ? true : false
                    } else   
                        return true
                }).map((el) => {
                    if(limit>el.item_limit)
                        limit=el.item_limit
                    if (action.value > limit)
                        action.value=limit
                    return el.item_id === action.id ? 
                            { ...el, cart_qty: action.value > 0 ? action.value: 0 } 
                        : 
                            el
                })
            }
        }
        case 'ADD_QUANTITY': {
            let list =state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.map((el) => {
                    if(el.item_id === action.id && limit>el.item_limit)
                        limit=el.item_limit
                    return el.item_id === action.id ?
                    { ...el, cart_qty: ++el.cart_qty > limit ? limit : el.cart_qty }
                    :
                    el
                })
            }
        }
        case 'MINUS_QUANTITY': {
            let list =state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.filter((item) => {
                    if (item.item_id === action.id) {
                        return --item.cart_qty > 0 ? true : false
                    } else   
                        return true
                }).map((el) => {
                    return el.item_id === action.id ? 
                            { ...el, cart_qty: el.cart_qty > 0 ? el.cart_qty: 0 } 
                        : 
                            el
                })
            }
        }
        case 'REMOVE_ITEM': {
            let list =state.CartList
            const cloneList = [...list];
            return {
                ...state,
                CartList: cloneList.filter((item) => {
                    if (item.item_id === action.id) {
                        return false
                    } else   
                        return true
                })
            }
        }
        default:
            return state;
    }

};

export default CartReducer;