const AmountReducer = (state = {}, action) => {
    switch (action.type) {
        case 'TOTAL_AMOUNT':
            return {
                ...state, 
                total: action.total,
                orderTotal: action.order
            };
        default:
            return state;
    }

};

export default AmountReducer;