import { combineReducers } from "redux";
import AuthReducer from './AuthReducer'
import DiscountReducer from './DiscountReducer'
import CartReducer from './CartReducer'
import ProductModalReducer from './ProductModalReducer'
import NotifyReducer from './NotifyReducer'
import AmountReducer from './AmountReducer'
import PaymentReducer from "./PaymentReducer";
import { reducer as Register } from 'redux-form';
import AddAddressReducer from "./AddAddressReducer";
import TimeReducer from "./TimeReducer";
import CustomerInfoReducer from "./CustomerInfoReducer"
import LoginModalReducer from "./LoginModalReducer";
import AddressInfoReducer from "./AddressInfoReducer";
import OrderIDReducer from "./OrderIDReducer";
import SecretDiscountReducer from "./SecretDiscountReducer";
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import XenditData from "./XenditData";
import XenditRetrieve from "./XenditRetrieve";
import XenditCreatePayment from "./XenditCreatePayment";
import XenditValidateOTP from "./XenditValidateOTP";
import CheckoutModalReducer from "./CheckoutModalReducer"
import CategoryReducer from "./CategoryReducer"
import LocationReducer from "./LocationReducer";
import LandBank from "./LandBankReducer";
import StoreCreditReducer from "./StoreCreditReducer"

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
      'OrderID',
      'CustInfo',
      'AddressInfo', 
      'payment',
      'total', 
      'XenditData', 
      'XenditRetrieve', 
      'XenditCreatePayment', 
      'XenditValidateOTP',
      'LandBank',
      'StoreCredit',
      'cart'
    ]
  }

const reducers = combineReducers({
    form: Register,
    auth: AuthReducer,
    cart: CartReducer,
    ProductModal: ProductModalReducer,
    notify: NotifyReducer,
    total: AmountReducer,
    discount: DiscountReducer,
    payment: PaymentReducer,
    address: AddAddressReducer,
    time: TimeReducer,
    CustInfo: CustomerInfoReducer,
    AddressInfo: AddressInfoReducer,
    ModalLogin: LoginModalReducer,
    OrderID: OrderIDReducer,
    secretDiscount: SecretDiscountReducer,
    XenditData: XenditData,
    XenditRetrieve:XenditRetrieve,
    XenditCreatePayment:XenditCreatePayment,
    XenditValidateOTP:XenditValidateOTP,
    CheckoutModal : CheckoutModalReducer,
    Category: CategoryReducer,
    Location : LocationReducer,
    LandBank:LandBank,
    StoreCredit : StoreCreditReducer
});

export default persistReducer(persistConfig, reducers );
