import axios from 'axios';


export const signUp = userObj => {
    axios.post('https://staging.zagana.com/api/apiV2/authenticate/sign_up', userObj)
        .then(response => {
            const loginDetails = JSON.stringify(response.data.loginDetails[0])
            sessionStorage.setItem('Login', loginDetails)
        })
        .catch(error => {
            console.log(error)
        })
}

export const signIn = () => {
    return {
        type: 'SIGN_IN'
    };
};
export const getOrderID = (data) => {
    return {
        type: 'ORDER_ID',
        id: data
    };
};

export const initialCustInfo = (data) => {
    return {
        type: 'INITIAL_VALUE',
        fullName: data.user_name,
        Email: data.user_email,
        Phone: data.user_phone,
        Birthdate: data.user_bday,
        Password: data.user_pw
    };
};

export const initialAddressInfo = (data) => {
    return {
        type: 'INITIAL_ADDRESS',
        fullName: data.add_fn,
        houseNumber: data.add_apartment,
        street: data.add_street,
        postalCode: data.address_zip,
        brgy_id: data.brgy_id,
        brgy_name: data.brgy_name,
        city_id: data.citybrgy_id,
        city_name: data.city_name,
        Phone: data.add_contact,
        add_id: data.add_id
    };
};
export const forgotModal = (payload) => {
    return {
        type: 'LOGIN_MODAL',
        show: payload
    };
};

export const signOut = () => {
    return {
        type: 'SIGN_OUT'
    };
};
export const increment = () => {
    return {
        type: 'INCREMENT'
    }
}
export const decrement = () => {
    return {
        type: 'DECREMENT'
    }
}
export const fetchCart = () => {
    const cartItems = JSON.parse(localStorage.getItem('cart')) || []
    return {
        type: 'FETCH_CART',
        payload: cartItems
    }
}
export const saveDel = userObj => {

    alert("save del")
}

