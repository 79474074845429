/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : Blog
 * File Directory           : src/components/Misc/Blog
 * Module                   : Show Selected Blog from Zagana
 * Author                   : Bryan
 * Version                  : 2
 * Purpose                  : To view the blog
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Blog        -   component for Blogs
 *
 * #How to call:
 *    <Blog />
 *
 *******************************************************************************************/


import React from "react";
import { useParams } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// header
import MiscHeader from "./MiscHeader";

// json file
import blogJson from "./blogJson";
const Blog = () => {
    const { blog } = useParams()
    const urlOrigin = window.location.origin
    const filterBlog = blogJson.map( b => {
        if ( b.topic === blog ){
            const subTopic = b.mainTopic.subTopic.map( subT => {
                const subContent = subT.subContent.map( subC => {
                    // Contains subcontent
                    return (
                        <li>
                            <div className="">
                                <h4 className="subContentHeader">{subC.title}</h4>
                                <p className="topicText">{subC.content}</p>
                                { subC.image === "" ? "" : <LazyLoadImage className="subContentImg" src={subC.image} alt={subC.title} /> }  
                                
                            </div>
                        </li>
                    )
                })
                //Contains subtopic, subcontent
                return(
                    <div className="subContentContainer">
                        <div className="subContentBody">
                            <h5 className="subTopicHeader">{subT.title}</h5>
                            <p className="topicText">{subT.content}</p>
                            { subT.image === "" ? "" : <LazyLoadImage className="subTopicImg" effect="blur" src={subT.image} alt={subT.title} />}  
                            
                            <ol className="subContent">
                                {subContent}
                            </ol>
                            
                        </div>
                    </div>
                )
            }) 
            // Contains all topic, subtopic, subcontent
            return (
                <div className="topicContainer">
                    <div className="mainTopic">
                        <p className="mainTopicTitle">{blog}</p>
                        { b.image === "" ? "" : <LazyLoadImage className="topicImg" effect="blur" src={b.image} alt={b.title} />}
                        <div className="mainTopicDiv">
                            <h2 className="mainTopicHeader">{b.mainTopic.title}</h2>
                            <div className="headerLine"></div>
                            <p className="topicText">{b.mainTopic.desctription}</p>
                        </div>
                    </div>
                    <div className="subTopic">
                        {subTopic}
                    </div>
                    {
                        b.video !== "" ?
                        <div className="yt">
                            <div className="row justify-content-center p-2">
                                <iframe width="560" height="315" src={b.video} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className="row p-4 pt-4">
                                <h3>{b.videoTitle}</h3>
                                <p>{b.videoDesc}</p>
                            </div>
                        </div> : ""
                    }
                </div>
            )
        }
        return ""
    })

    // filter other blog
    const fitlerOtherBlog = blogJson.map( fob => {
        if (fob.topic !== blog) {
            // Contains Slider type Blogs
            return (
                <div className="slideCard">
                    <div className="card" style={{width: '18rem'}}>
                        <div className="imgContainer">
                            <a href={`/ContentPage/blogs/${fob.topic}`}>
                                <LazyLoadImage className="card-img-top" src={fob.image} alt={fob.topic} />
                            </a>
                        </div>
                        <div className="card-body">
                            <h5 className="card-title"><a href={`/ContentPage/blogs/${fob.topic}`} >{fob.topic}</a></h5>
                        </div>
                    </div>
                </div>
            )
        }
    })

    if (filterBlog.length === 0) {
        window.location.href = "/ContentPage/blogs"
    }
    return(
        <div className="">
            <MetaTags>
                <title>Zagana | Introducing Zagana</title>
                <meta name="description" content="The Online Grocery Every Filipino Needs"/>
                <meta property="og:title" content="Zagana | Introducing Zagana"/>
                <meta
                property="og:image"
                content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"/>
                <meta
                property="og:url"
                content="https://www.zagana.com/ContentPage/blogs/introducing-zagana"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Zagana Inc."/>
                <meta
                name="keywords"
                content="farm-fresh, fresh, produce, local, frozen, farmers, quality, introduction, online grocery, online palengke, blogs"/>
                <meta name="author" content="Zagana Inc."/>
            </MetaTags>

            {/* 
            //
                PAGE CONTENT
            //
            */}
                <MiscHeader />
        
            <div className="container p-0">
                <div className="misc-bm">
                    {/* <div className="misc-blog-front sticky">
                        <div className="blog-blg col">
                            <p className="blogTitle">
                                {blog}
                            </p>
                        </div>
                        <div className="blogQuotes col d-flex justify-content-end">
                            <a className="blogQuotesBtn btn btn-outline-warning" href="/ContentPage/blogs/introducing-zagana">Back to Blogs</a>
                            <a className="blogQuotesBtn btn btn-outline-success" href={urlOrigin}>Shop Now</a>
                        </div>
                    </div> */}
                    <div className="blogContent">
                        {/* 
                        //
                            BLOGS|ZAGANA
                        //
                        */}

                        <div className="blogContainer">
                            {filterBlog}
                        </div>
                    </div>
                    <div className="slideBlogContainer">
                        <div className="slideBlogHead">
                            <div className="col">
                                <h4>Other Blogs</h4>
                            </div>
                            <div className="col">
                                <a href="/ContentPage/blogs">See all</a>
                            </div>
                        </div>
                        <div className="slideBlog">
                            {fitlerOtherBlog}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog