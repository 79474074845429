const blogJson = [
    {
        featured: false,
        image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1661150544/blogs/5reasons/Online_Shopping_k10two.jpg",
        video: "",
        videoTitle: "",
        videoDesc: "",
        topic: "Why Online Grocery Shopping Is Necessary for Today's Lifestyle",
        campaign: "",
        client: "Zagana",
        keywords: "Online Delivery Grocery, Shop Online Grocery, Zagana",
        audience: [
            "25-35 single male, female or young family",
            "Condo living in Metro Manila",
            "Millennials and Gen Z who have a busy lifestyle and want to do their grocery shopping conveniently and safely"
        ],
        datePublished: "March 15, 2021",
        reference: "https://www.myvalue365.com/blog/13/5-reasons-why-online-grocery-shopping-is-necessary-for-today's-lifestyle",
        mainTopic: {
            title: "5 Reasons Why You Should Try Online Grocery Shopping Today",
            desctription: `\nAre you a fan of online shopping?\n\nThe existence of online shopping or e-commerce platforms makes it easier for us to buy the things we want without moving an inch. Among these is grocery shopping. Buying groceries is essential for every household. However, it is also quite time-consuming considering that you would have to travel to a grocery store and then spend nearly an hour roaming around the place looking for the things that you and your family need. Not to mention, the long lines at the cashier!\n\nThis is exactly why online delivery grocery has been made available. With this, you can do your own grocery shopping just by clicking on a website and then your purchased products will be delivered to you. Easy, right?`,
            subTopic: [
                {
                    title: "It’s a time-saver",
                    content: "As mentioned above, going to a physical store takes more time out of you. Since a lot of us are busy with our jobs, families, and personal stuff, we want to make things a lot more convenient for us as we do our errands. The website already has every category of needs and essentials organized and laid out simply for the consumers to see, unlike when you’re in a physical grocery store where you have to roam around from aisle to aisle looking for what you need. Simple to say, it’s very efficient!",
                    image:"",
                    subContent: []
                },
                {
                    title: "Variety of choices",
                    content: "Some are keen on buying their groceries at an actual store because they can be more sure of the things that they are buying. However, online grocery shopping actually gives you a variety of high-quality products that you can also purchase from a normal physical store, if not more. Anything you need whether it’s meat, poultry, vegetables, fruits, dairy, and other daily items are made available for you to purchase online. You can even discover products that are imported or aren’t easy to find that are available in online grocery stores.",
                    image:"https://res.cloudinary.com/dw5axbz64/image/upload/v1661150541/blogs/5reasons/Variety_of_choices_yvmmuc.jpg",
                    subContent: []
                },
                {
                    title: "Delivery option",
                    content: "One more advantage about doing online grocery shopping is that your purchased items can be dropped literally at your front door. Whether you’re busy at work or stuck at home, you can have your grocery items delivered to your residence. Online grocery stores partner up with reliable couriers or have one of their own that will ensure the safety of your products. They also deliver right away (like same-day deliveries, but it will still depend on the given conditions), even just after a few hours that you made your purchase.",
                    image:"",
                    subContent: []
                },
                {
                    title: "Hassle-free returns",
                    content: "When going online grocery shopping, you have the inability to check the quality of your products. Fortunately, there is such a thing as a return/refund policy. If ever you encounter that any of your purchased products are flawed, most online grocery stores offer an easy return method. This way, you didn’t put your good money to waste and it also shows accountability on the side of the seller.",
                    image:"",
                    subContent: []
                },
                {
                    title: "Promos, deals, and offers",
                    content: "Online grocery stores and other e-commerce businesses offer various promos and discounts to capture the interest of more buyers. Shopping online can get a lot more exciting if you have vouchers and offers that lead to cheaper costs and the inevitable buy one take one’s and of course, the free shipping. You have the option to save more of your money and make more wise choices as a buyer.\n\nIf you are looking for a trusted online grocery store in the Philippines that delivers high-quality products, you can try Zagana! We offer farm-fresh products with the help of our hard-working local farmers.",
                    image:"https://res.cloudinary.com/dw5axbz64/image/upload/v1661150543/blogs/5reasons/Online_grocery_store_cmp5kb.jpg",
                    subContent: []
                }
            ]
        }
    },
    {
        featured: false,
        image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1661150547/blogs/stepbystep/Online_grocery_pbbbq5.jpg",
        video: "",
        videoTitle: "",
        videoDesc: "",
        topic: "How Online Grocery Shopping Works",
        campaign: "Highlight brand and services",
        client: "Zagana",
        keywords: "Online Delivery Grocery, Shop Online Grocery, Zagana",
        audience: [
            "25-35 single male, female or young family",
            "Condo living in Metro Manila",
            "Millennials and Gen Z who have a busy lifestyle and want to do their grocery shopping conveniently and safely"
        ],
        datePublished: "March 22, 2021",
        reference: "https://www.myvalue365.com/blog/37/wondering-how-online-grocery-shopping-works?-here-is-how",
        mainTopic: {
            title: "A Step-By-Step Guide On How Online Grocery Shopping Works",
            desctription: `Grocery shopping is a major part of our household necessities. We buy food, drinks, and other everyday items that will be consumed for a month or two weeks at the least. From time to time, we find ourselves going to a grocery store, roaming around from aisle to aisle, looking for what we want to buy. Some find this activity fun and satisfying, but let’s face it, it’s also very time-consuming.\n\nHere is where online grocery comes in. Many countries all over the world are becoming highly active in online shopping—the Philippines being among them. Online shopping has made our lives much easier and convenient. It allows us to purchase the things we need and want even if we’re busy with other things. The process is easy and you still get the same things you can buy from physical stores. This is the same thing with online grocery shopping.\n\nBut the main question is, how does online grocery shopping work?`,
            subTopic: [
                {
                    title: `Prepare your shopping list`,
                    content: `It always starts with you making a list of the things you need to buy. You need to plan what you are going to purchase so that when you’re already at the online grocery website, you won’t have to scour your brain trying to recall what’s missing from your kitchen.`,
                    image:``,
                    subContent: []
                },
                {
                    title: `Giving out your basic information`,
                    content: `When you open the online grocery website or app, the process will start with them asking for your basic information. You need to input important things such as your name, address, and contact details which they will be needing when they deliver your purchased items to you.`,
                    image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150538/blogs/stepbystep/Basic_information_jab4i5.jpg`,
                    subContent: []
                },
                {
                    title: `Browsing for your essentials`,
                    content: `This is the time when you’ll be doing the shopping itself. You can choose from browsing the available items in the selection provided by the online grocery store or you can just simply use their search bar to find the specific items that you need. If you’re worried that online grocery stores don’t have the same quality and quantity of items as a physical store does, don’t be! Most online grocery stores provide high-quality products, sometimes even the ones that are hard to find.\n\nTake a look at Zagana, an online delivery grocery store in the Philippines that brings farm-fresh products to the kitchen of their consumers.`,
                    image:``,
                    subContent: []
                },
                {
                    title: `Using discounts or vouchers`,
                    content: `Before you proceed to checkout, you will be offered discounts and promos if there are any available. You can take advantage of this to save more money or gain freebies, especially if you are a first-time buyer of the store or it’s a special holiday.`,
                    image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150548/blogs/stepbystep/Discounts_and_voucher_pidbjt.jpg`,
                    subContent: []
                },
                {
                    title: `Proceed to checkout`,
                    content: `You can now proceed to checkout then select your desired address to which they will be delivering your purchased items to. It can either be your home or office address, whichever is best for you. You will also be choosing your mode of payment and most online and e-commerce stores offer online payment through bank transfer or other payment channels, and of course there’s the option of cash on delivery.`,
                    image:``,
                    subContent: []
                },
                {
                    title: `Wait for your delivery`,
                    content: `Once you have finished with your online transaction, you can just sit back, and relax as you wait for them to deliver your groceries. Most online grocery stores offer same-day or one-day deliveries but it will still depend on the volume of their customers for that particular day.\n\nNow that you know how online grocery shopping works, you can try doing it with Zagana! Here, we offer farm to kitchen products at reasonable prices, and we deliver right to your door. Whatever you need, we got it! You can contact us to know more details.`,
                    image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150548/blogs/stepbystep/Delivery_mtl5cf.jpg`,
                    subContent: []
                },
            ]
        }


    },
    {
        featured: false,
        image: "https://res.cloudinary.com/dw5axbz64/image/upload/v1661150540/blogs/benefit/Grocery_shopping_ujb47s.jpg",
        video: "",
        videoTitle: "",
        videoDesc: "",
        topic: "Save Time & Money with the Advantages of Online Grocery Shopping",
        campaign: "Brand establishment",
        client: "Zagana",
        keywords: "Benefits Online Grocery",
        audience: [
            "25-35 single male, female or young family",
            "Condo living in Metro Manila",
            "Millennials and Gen Z who have a busy lifestyle and want to do their grocery shopping conveniently and safely"
        ],
        datePublished: "April 12, 2021",
        reference: "https://www.foodtown.com/articles/advantages-online-grocery-shopping",
        mainTopic: {
            title: "Benefits Online Grocery Can Give: Save Time And Money!",
            desctription: `Grocery shopping is fun but it can also be burdensome. Usually, when it comes to buying our daily essentials, we go to the grocery store. We go and grab that pushcart and navigate our way through the different aisles. But sometimes we encounter getting lost finding our desired goods or having to wait in a long line to the cashier. Those factors make grocery shopping a little less appealing, especially if we are busy people or if we have somewhere else to be.\n\nGrocery shopping costs us not just money, but also a lot of our time. Fortunately, this doesn’t always have to be the case. Enter, Zagana online delivery grocery shopping. A lot of people have been shopping online grocery these days due to the convenience that it brings. It’s amazing that after a few taps on our phones, we can easily buy the things that we want. Just take a look at Zagana—an online grocery store that lets customers shop for farm-to-table fresh products with ease. \n\nIn this article, we want to show all the benefits online grocery shopping can give you.`,
            subTopic: [
                {
                    title: `Online Delivery Grocery Shopping Saves Our Money`,
                    content: `In efforts to budget our funds, we always choose items that could save us the most money. Given that culture, it’s only practical to do online grocery shopping.\n\nListed below are the reasons why:`,
                    image:``,
                    subContent: [
                        {
                            title: `Reduced expenses`,
                            content: `It is expected that grocery shopping will cost us some bills. Buying multiple products at a time will do that to you. But beyond that, we’ll also have to consider the expenses you incur from car gas and parking tickets. Doing online grocery shopping with Zagana will help us save our income one bit at a time. We no longer have to worry about spending on fuel or paying for parking! We’ll simply be paying for groceries.
                            `,
                            image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150537/blogs/benefit/Reduces_expenses_dblpzm.jpg`
                        },
                        {
                            title: `Same prices`,
                            content: `Another great thing about our online grocery store is that we offer the same product prices to our online shoppers as to our in-store ones. You can do everything you want for the same price, which is more financially practical because you will be able to do it from the comfort of your own home. Plus, we also offer promos and discounts just like in physical stores.
                            `,
                            image:``
                        },
                        {
                            title: `Buying in bulk`,
                            content: `One of the things that in-store buyers avoid is buying too many items because it makes the pushcart heavier and harder to navigate around. Fortunately, with online grocery shopping, this wouldn’t be an issue! We can purchase as manyproducts as we want in one go and they’ll be delivered straight to our door. Easy, breezy.
                            `,
                            image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150536/blogs/benefit/Buying_in_bulk_v2lyof.jpg`
                        }
                    ]
                },
                {
                    title: `Online delivery grocery shopping saves our time`,
                    content: `With us having jobs, familial responsibilities, and social commitments, it becomes difficult to squeeze in our errands sometimes. Grocery shopping takes up a lot of time, which is why we must manage and save that time well for what matters more in our life.\n\nHere’s how online grocery shopping can help:`,
                    image:``,
                    subContent: [
                        {
                            title: `Shop anywhere, anytime`,
                            content: `The best thing about grocery shopping with an online store like Zagana is that we’re able to do it anywhere and at any time of the day. Whether we’re in the office, at home, or out with our friends, we can conveniently purchase our essentials. This is especially beneficial for people who are very busy and have plenty of commitments to tend to.`,
                            image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150549/blogs/benefit/Shop_anywhere_anytime_pexllh.jpg`
                        },
                        {
                            title: `No more long lines`,
                            content: `It can be quite annoying to wait in long lines. We have to wait while standing up and more often than not, it affects our other plans for the day. With online grocery shopping, we can avoid this. We can simply shop and proceed to checkout in no time, without ending up stressed or irritated.`,
                            image:``
                        },
                        {
                            title: `Fewer road trips`,
                            content: `With online grocery shopping, we don’t have to travel to a physical store. We don’t have to worry about getting into a car and driving through traffic because we can easily buy your essentials from the screen in front of us. This is not only time and cost-efficient but it’s also doing our environment a favor. Also, if we ever forget to purchase an item, we don’t have to drive all the way back to the grocery store. Here at Zagana, we can just add more items to your cart even if we’ve already proceeded to checkout as long as our order is not yet on its way.`,
                            image:`https://res.cloudinary.com/dw5axbz64/image/upload/v1661150551/blogs/benefit/Fewer_road_trips_o6xscg.jpg`
                        }
                    ]
                },
                {
                    title: `Experience convenience with Zagana Online Grocery Store`,
                    content: `There are so many benefits online grocery shopping can give us. It’s very convenient, easy to do, and it saves us lots of time and money. Shopping online has its reservations but its benefits to shoppers weigh so much more. What are you waiting for? Go shop online grocery, and we’ll drop your ordered items right to your door!`,
                    image:``,
                    subContent: []
                }
            ]
        }
    },
    {
        featured: true,
        image: "https://api.zagana.com/img/shutterstock_1724502742.jpg",
        video: "https://www.youtube.com/embed/PiBvjAQR8RA",
        videoTitle: "",
        videoDesc: "If you are ever in the mood for some online grocery shopping, you can count on Zagana online delivery grocery. Our user-friendly website has got everything covered for your daily household essentials, including our farm-fresh products! With us, you can experience high-quality and hassle-free online grocery shopping whenever you need it. We cater to various areas of Metro Manila, Cavite, and Rizal. Shop with us now!",
        topic: "Introducing Zagana",
        campaign: "",
        client: "",
        keywords: "",
        audience: [],
        datePublished: "",
        reference: "https://www.foodtown.com/articles/advantages-online-grocery-shopping",
        mainTopic: {
            title: "Online Grocery Every Filipino Needs",
            desctription: 'It’s kind of hard to imagine the world now without the online platform. Many people shop and order online these days due to the convenience it brings. Whether you’re busy at work, stuck in traffic, or within the four walls of your home, you can easily just press “add to cart”. Ordering online seems to make more sense especially for those who have a lot on their plate and don’t have the luxury to go outside and shop for their needs.\n\nGrocery shopping is not an exception to this. It’s true that when it comes to buying food and other necessary stuff for our household, visiting a physical store gives us more peace of mind. This is mainly due to being able to check the quality of the products that we are purchasing. However, the downside of this is having to drive or commute to grocery stores which will add to your burden of chores and take some time. Fortunately, certain measures have been made for you to shop for your grocery needs without even moving an inch from where you’re sitting at.\n\nMeet Zagana—a newly opened online delivery grocery in the Philippines! It is without a doubt a fact that a lot of Filipinos love to do online shopping. Furthermore, a lot of them take pleasure and find the need to do grocery shopping. If you combine the two, you’ll be getting Zagana. With us, you can easily purchase food and everyday items simply by visiting our shopping website. All of our products are displayed there along with their respective prices.\n\nBut the question is, why choose us? Surely there are lots of other online groceries out there.\n\nRead on to find out why Zagana is the great (and only) choice for your go-to online grocery shopping.',
            subTopic: [
                {
                    title: `The products are fresh and came straight from the farm`,
                    content: `When it comes to food, we all want it to be fresh. Here at Zagana, we ensure that our products, particularly our meat, poultry, fruits, and vegetables, are freshly produced by the hands of our trusted local farmers. It is our commitment to deliver high-quality food and products that will give our customers a farm-fresh experience. Zagana is not just your typical online grocery; Zagana is your online fresh produce delivery.`,
                    image:`https://api.zagana.com/img/shutterstock_1506253877.jpg`,
                    subContent: []
                },
                {
                    title: `We deliver right to your door`,
                    content: `No need to worry about pumping up your gas tank or driving through heavy traffic, because we’ll take your “farm to kitchen” groceries to you. We want to make our customers feel every ounce of convenience, thus, once you have finished clicking on the products you wanted to buy from us, we will be delivering them to your address. Our delivery process guarantees speed as fast as 30 to 45 seconds, especially when you order via Grab. Fast delivery without compromising the quality of your ordered items.`,
                    image:`https://api.zagana.com/img/shutterstock_1890611368.jpg`,
                    subContent: []
                },
                {
                    title: `Getting to help our Filipino farmers`,
                    content: `We at Zagana online grocery shop don’t just aim to provide you with high-quality and fresh products, we also have an advocacy of helping our local farming industry. Many farmers are working hard in order to bring us fresh meat, poultry, and fruits and vegetables. We believe that their hard work and dedication deserve to be highlighted and supported. That’s why we encourage you to shop with us, you don’t just get to conveniently shop for your needs, you also get to extend your support to our beloved farmers too. But don't take our word for it, hear and listen how Zagana has helped farmers from Alcala Pangasinan in the video at the bottom of this article.`,
                    image:`https://api.zagana.com/img/Zagana%20x%20Pangasinan-34.jpg`,
                    subContent: []
                },
            ]
        }


    },
]
export default blogJson