
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : PrivacyPolicy
 * File Directory           : src/components/Misc/PrivacyPolicy
 * Module                   : Show All Privacy Policy from Zagana
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To view the Privacy Policy 
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   PrivacyPolicy        -   component for Privacy Policy Page
 *
 * #How to call:
 *    <PrivacyPolicy />
 *
 *******************************************************************************************/


import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import Header from "../Shared/Header";
import DesktopHeader from '../Shared/DesktopHeader';
import LoginModal from '../Shared/LoginModal';
import { storeFrontLogs } from "../APIs/Logs";
const styles = {
    indentedText: {
        paddingLeft: "9%"
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed',
        'zIndex': '2'
    },
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'left'
    }
}
function PrivacyPolicy() {
    document.title = "Zagana | Privacy Policy"
    const isMobile = localStorage.getItem("isMobile") || "true"
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal
    let gen_id = localStorage.getItem('gen_id')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || Privacy Policy`,
            type: 'view_privacy_policy',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }, [])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Zagana | Privacy Policy</title>
                <meta name="description" content="Zagana is committed to protecting our visitors' and members' privacy. The following Privacy Policy outlines the information Zagana, (the 'Company', 'we', or 'us') may collect and how we may use that information to better serve visitors and members while using our Web site www.zagana.com and Mobile Application." />
                <meta property="og:title" content="Zagana | FAQs" />
                <meta property="og:image" content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" />
                <meta property="og:url" content="https://www.zagana.com/ContentPage/privacy-policy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Zagana Inc." />
                <meta name="keywords" content="farm-fresh, fresh, produce, local, frozen, farmers, quality, introduction, online grocery, online palengke, privacy, policy, privacy policy" />
                <meta name="author" content="Zagana Inc." />
            </MetaTags>

            {isMobile === "true" ?
                <div style={{ position: 'fixed', zIndex: '2' }} className="p-0" >
                    <Header title="Privacy Policy" />
                </div>
                : <div style={{ position: 'fixed', zIndex: '2' }} className="p-0" >
                    <DesktopHeader
                    />
                </div>}
            <div className="container mt-5">
                <div className="row pt-3">
                    <h1><center>PRIVACY POLICY</center></h1>
                </div>
                <div className="row p-4 pt-0 pb-3">
                    <p>Zagana is committed to protecting our visitors' and members' privacy. The following Privacy Policy outlines the information Zagana, (the "Company", "we", or "us") may collect and how we may use that information to better serve visitors and members while using our Web site www.zagana.com.ph and Mobile Application. BY USING OUR WEBSITE AND MOBILE APPLICATION (COLLECTIVELY, THE "SERVICE"), YOU AGREE TO THE TERMS OF THIS PRIVACY POLICY. Please review the following carefully so that you understand our privacy practices. If you have questions about this Privacy Policy, please contact us at zaganacs@gmail.com.</p>
                    <h2>I. THE COMPANY</h2>
                    <p>a. We are Zagana, Inc., a corporation duly organized under the laws of the Philippines, trading as www.zagana.com.ph (“ZAGANA”), with office address at 3rd Floor Ablaza Building, E. Rodriguez SR. Avenue, Tatalon, 1113 Quezon City, Metro Manila (the “Office”).</p>
                    <p>Zagana is an online source of fresh produce and frozen food that can deliver in as fast as 30 mins.</p>
                    <h2>II. CHILDREN UNDER THE AGE OF 13</h2>
                    <p>Company cares about the safety of children. Because the Service is not directed toward kids, children under 13 are not allowed to register with or use the Service. We do not knowingly collect personal information from anyone under the age of 13. If we discover that we have collected personal information from a child under 13, we will delete that information immediately</p>
                    <h2>III. INFORMATION WE COLLECT </h2>
                    <p>3.1 Upon registration with the Service (either as a consumer, partner or driver), a user profile may be developed to further customize the user's experience. The current required data fields are:</p>
                    <ul className="list-unstyled">
                        <li>● Name</li>
                        <li>● Email</li>
                        <li>● Password</li>
                        <li>● Mobile number</li>
                        <li>● Address (for delivery)</li>
                        <li>● Barangay</li>
                        <li>● Zip Code</li>
                        <li>● Credit Card Number</li>
                        <li>● Expiration date {"&"} Security Code</li>
                    </ul>
                    <p>In addition, tracking information may be collected as you navigate through our website (the "Website") or use the Service, including, but not limited to geographic areas.</p>
                    <p>3.2 We may also collect information on the groceries you are ordering from us, including type, service requested, brand, variants, and size. In addition, we may take photos of the groceries that were delivered to your doorstep. Our primary goal in collecting information is to provide you with an enhanced experience when using the Service.</p>
                    <p></p>
                    <p>3.4 If you wish to apply for a job on our site we may collect personal information such as your name, email address, phone number and additional information such as resume, gender, and your ethnicity. We use the information collected within this area of the site to determine your qualifications for the position in which you have applied and to contact you to set up an interview</p>
                    <p>3.5 To help us serve your needs better, we may use "cookies" to store and sometimes track user information. A cookie is a small amount of data that is sent to your browser from a web server and stored on your computer's hard drive. Cookies can be disabled or controlled by setting a preference within your web browser.</p>
                    <p>3.6 Users of the Website should be aware that non-personal information and data may be automatically collected by virtue of the standard operation of the Company's computer servers or through the use of "cookies". Cookies are files a website can use to recognize repeat users, and allow a website to track web usage behavior. Cookies take up minimal room on your computer and cannot damage your computer's files. Cookies work by assigning a number to the user that has no meaning outside of the assigning website. Users should be aware that the Company cannot control the use of cookies (or the resulting information) by third-parties. If you do not want information to be collected through the use of cookies, your browser allows you to deny or accept the use of cookies. There may, however, be some features of the Service which require the use of cookies in order to customize the delivery of information to you.</p>
                    <p>3.7 The use of third party cookies is not covered by our privacy policy. We do not have access or control over these cookies.</p>
                    <p>3.8 We will be collecting your personal delivery address, to be able to properly fulfill your order. We will not share this information with third parties for any purpose and will only use this information for the sole purpose of fulfilling your request. You may at any time no longer allow our application to use your location by turning this off at the account level. Rest assured usage of your delivery address is user-initiated, and we will not be sending you marketing or sales related materials without your consent.</p>
                    <p>3.9 The use of third party cookies is not covered by our privacy policy. We do not have access or control over these cookies.</p>
                    <h2>IV. HOW WE USE YOUR INFORMATION</h2>
                    <p>Your personal data may be collected, used, disclosed, and/or processed for one or more of the following purposes:</p>
                    <p>4.1 As a buyer/customer,</p>
                    <ul className="list-unstyled">
                        <li>● Our primary goal in collecting information is to provide you with an enhanced experience when using the Service. We use this information to closely monitor which features of the Service are used most, to allow you to view your order history, store your credit card information on a secure page, view any promotions we may currently be running, rate delivery personnel, and to determine which features we need to focus on improving, including usage patterns and geographic locations to determine where we should offer or focus services, features and/or resources, we use the mobile information collected so that we are able to serve you the correct app version depending on your device type, for troubleshooting and in some cases marketing purposes. We use the credit card information you provide us to bill you for services. </li>
                        <li>● To fulfill orders and send you the fresh products you ordered on the website. In order to deliver the product to you, we may share your personal information with a third party (e.g., our logistics partner).</li>
                        <li>● To keep you informed about your delivery and assist customers with their orders.</li>
                    </ul>
                    <p>4.2 As an applicant/employee,</p>
                    <ul className="list-unstyled">
                        <li>● To take into account and/or process your application;</li>
                        <li>● For the purposes of identification and/or verification;</li>
                        <li>● To enforce our Terms of Service or other end-user license agreements that may be applicable;</li>
                        <li>● To safeguard our own personal safety, as well as the rights, property, and safety of our employees and others;</li>
                        <li>● To respond to legal processes or complying with or as required by any applicable law, governmental or regulatory requirements of any relevant jurisdiction.</li>
                        <li>● To conduct due diligence or other screening operations in line with legal or regulatory responsibilities or our risk management policies, as required by law or as implemented by us;</li>
                    </ul>
                    <p>4.3 For us providing services,</p>
                    <ul className="list-unstyled">
                        <li>● The Company may use your Internet Protocol (IP) address to help diagnose problems with our computer server, and to administer the Website. Your IP address is used to help identify you, and to gather broad demographic data. Your IP address contains no personal information about you.</li>
                        <li>● To have your name, username, or profile shown on the Platform (including in any reviews you may write);</li>
                    </ul>
                    <p>4.4 For us to do marketing and research</p>
                    <ul className="list-unstyled">
                        <li>● To conduct research, analysis, and development activities (including, but not limited to, data analytics, surveys, technology development, and/or profiling), to analyze how you use the Site, and to improve and/or enhance the Site; to conduct research, analysis, and development activities (including, but not limited to, data analytics, surveys,</li>
                        <li>● To generate data and research for internal and statutory reporting and/or record-keeping purposes;</li>
                    </ul>
                    <p>4.5 Others </p>
                    <ul className="list-unstyled">
                        <li>● Any other uses that we inform you about when we acquire your consent. (the "Purposes" altogether).</li>
                    </ul>
                    <p>Because the purposes for which we will/may collect, use, disclose, or process your personal data are contingent on the circumstances, they may not be listed here. Unless the processing of the applicable data without your consent is permitted by the Privacy Laws, we will advise you of such other purpose upon gaining your consent.</p>
                    <h2>V. HOW CAN YOU WITHDRAW CONSENT/ACCOUNT, YOU HAVE PROVIDED US. </h2>
                    <p>You can withdraw your consent/account for the collection, use, and/or disclosure of your personal data in our possession or under our control by sending an email to our Admin Officer at dev@zagana.com. We may be unable to continue considering your application and any information you provide if you withdraw your consent.</p>
                    <h2>VI. SERVICE AND PROMOTION-RELATED ANNOUNCEMENTS </h2>
                    <p>6.1 We will send you strictly service-related announcements on rare occasions when it is necessary to do so. For instance, if our service is temporarily suspended for maintenance, we might send you an email.</p>
                    <p>6.2 Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account.</p>
                    <p>6.3 For promotion-related materials, rest assured we will only send messages when we believe an offer or announcement is important for you. You may opt-out of this service, by sending a message to zaganacs@gmail.com</p>

                    <h2>VII. CUSTOMER SERVICE </h2>
                    <p>upon the personally identifiable information you provide us, we will send you a welcoming email and SMS message which we may use to verify your username, password, and mobile phone number. We will also communicate with you in response to your inquiries, to provide the services you request, and to manage your account. We will communicate with you by email, telephone, or SMS, in accordance with your wishes.</p>
                    <h2>VIII. OUR DISCLOSURE OF YOUR INFORMATION</h2>
                    <p>8.1 We do not sell, share, rent or trade your personal information or geo-location information other than as disclosed within this privacy policy</p>
                    <p>8.2 The Company may share aggregated information that includes non-identifying information and log data with third parties for industry analysis, demographic profiling and to deliver targeted advertising about other products and services.</p>
                    <p>8.3 We may employ third party companies and individuals to facilitate our Service, to provide the Service on our behalf, to process payment, provide customer support, provide geo-location information to our drivers, to host our job application form, to perform Website-related services (e.g., without limitation, maintenance services, database management, web analytics and improvement of the Website's features) or to assist us in analyzing how our Website and Service are used. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose. We may also provide personal information to our business partners or other trusted entities for the purpose of providing you with information on goods or services we believe will be of interest to you. You can, at any time, opt out of receiving such communications by contacting those third parties directly.</p>
                    <p>8.4 The Company cooperates with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), to protect the property and rights of the Company or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, an illegal, unethical or legally actionable activity.</p>
                    <p>8.5 If we are involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.</p>
                    <p>8.6 We may use a third party hosting provider who may host our support section of the site. Information collected within this section of the site is governed by our privacy policy. Our third party service provider does not have access to this information.</p>
                    <h2>IX. ACCESS TO PERSONAL INFORMATION</h2>
                    <p>your personal information changes, or if you no longer desire our service, you may correct, delete inaccuracies, or amend it by emailing us at zaganacs@gmail.com. We will respond to your access request within 30 days.</p>
                    <p>your personal information changes, or if you no longer desire our service, you may correct, delete inaccuracies, or amend it by emailing us at zaganacs@gmail.com. We will respond to your access request within 30 days.</p>
                    <h2>X. SECURITY</h2>
                    <p>personally identifiable and geo-location information we collect is securely stored within our database, and we use standard, industry-wide, commercially reasonable security practices such as encryption, firewalls and SSL (Secure Socket Layers) for protecting your information (credit card number and geo-location information). However, as effective as encryption technology is, no security system is impenetrable. We cannot guarantee the security of our database, nor can we guarantee that information you supply won't be intercepted while being transmitted to us over the Internet, and any information you transmit to the Company you do at your own risk. We recommend that you not disclose your password to anyone.</p>
                    <h2>XI. REFERRAL SERVICE</h2>
                    <p>you choose to use our referral service to tell a friend about our site or a job position, we will ask you for your friend's name and email address. We will send your friend a one-time email inviting him or her to sign-up for Zagana’s service or visit the site. We store this information for the sole purpose of sending this one-time email and tracking the success of our referral program</p>
                    <p>Your friend may contact us at zaganacs@gmail.com to request that we remove this informationfrom our database.</p>
                    <h2>XII. SOCIAL MEDIA (FEATURES) AND WIDGETS</h2>
                    <p>Our Web site may include Social Media Features, such as the Facebook Like button and Widgets, such as the Share this button or interactive mini-programs that run on our site. These Features may collect your IP address, which page you are visiting on our site, and may set a cookie to enable the Feature to function properly. Social Media Features and Widgets are either hosted by a third party or hosted directly on our Site. Your interactions with these Features are governed by the privacy policy of the company providing it.</p>
                    <h2>XIII. PUBLIC FORUMS</h2>
                    <p>Our Web site may offer publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, contact us at zaganacs@gmail.com. In some cases, we may not be able to remove your personal information, in which case we will let you know if we are unable to do so and why.</p>
                    <h2>XIV. CHANGES IN THIS PRIVACY POLICY</h2>
                    <p>We may update this privacy statement to reflect changes to our information practices. If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this Site prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices.</p>
                </div>
                <div className="p-4 pt-5 mt-3">
                    <label>Please read these Data Processing Terms carefully before ordering our Services as your purchase of any Services offered on our Website is subject to these Data Processing  and Cross selling , Marketing selling Terms. The Client agrees that clicking the acceptance box to these Data Processing Terms constitutes acceptance of these Data Processing Terms. By ordering Services via the Website(whether now or in the future), you agree to be bound by these Data Processing Terms.These Data Processing Terms constitute the mandatory data processing contract between you as data controller and us as data processor. If you do not accept these Data Processing Terms, you should not proceed to order Services from us.</label>
                    <label className="pt-4">We recommend that you print a copy of these Data Processing Terms for future reference. These Data Processing Terms are only in the English language.</label>
                </div>
            </div>

            <LoginModal
                visible={visibleLogin}
                modalToggle={modalShow}
            />
        </React.Fragment >
    )
}


export default PrivacyPolicy;