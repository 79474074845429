/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : FeedBack
 * File Directory           : src/components/Shared/FeedBack
 * Module                   : FeedBack
 * Author                   : Joseph
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/ItemList.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   FeedBack        -   component for FeedBack
 *
 * #How to call:
 *    <FeedBack/>
 *
 *******************************************************************************************/

import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

import Home_api from '../APIs/Home';
import createPalette from "@mui/material/styles/createPalette";

import likeIconGray from "../../assets/site_icons/likeIconGray.png";
import likeIconGreen from "../../assets/site_icons/likeIconGreen.png";

import dislikeIconGray from "../../assets/site_icons/dislikeIconGray.png";
import dislikeIconGreen from "../../assets/site_icons/dislikeIconGreen.png";
const style = {
    toast: {
        'position': 'fixed',
        'margin': '0 auto',
        'zIndex': '999',
        'right': '30px',
        'bottom': '2%',
        'textAlign': 'left'
    },
    buttonLike: {
        'border': '0px solid',
        'padding': '0',
        'borderRadius': '50%',
        'background': '#fff'
    }
}


const FeedBack = ({ order_id, forForm }) => {
    const [orderNo, setOrderNo] = useState([]); // display for ordernumber
    const [feedlist, setFeedlist] = useState([]); // display for ffeedbacklist
    const [productlist, setProductList] = useState([]); // display for ffeedbacklist
    const [show, setShow] = useState(false); // showing of modal
    const [showAlert, setShowAlert] = useState(false); // declare for toggle the alert box
    const [variant, setVariant] = useState(""); // warning / success alert color
    const [msg, setMsg] = useState(""); // warning / success messages
    const [formID, setFormID] = useState(""); // sets form by order id
    const [seeMore, setSeeMore] = useState(0); // warning / success messages
    const [productFeedback, setProductFeedback] = useState([]); // product like / dislike state
    const [refresh, setRefresh] = useState(true); // set like / dislike state

    const isLogged = sessionStorage.getItem("Login") || ''; // login checker

    let skip = localStorage.getItem('skip') || null;
    let urlChecker = window.location.pathname;
    const orderID = order_id || null;
    const formShow = forForm || null;
    useEffect(() => {

        let accessKey = localStorage.getItem('accessKey')
        if (urlChecker.includes("user-profile")) {
            setShow(false);
        }
        if (refresh === true) {
            // setRefresh(!refresh);
        }
        const getFeedBack = async () => {
            const id = sessionStorage.getItem('Login') || null
            const parseCustomerID = JSON.parse(id) || []
            const customerID = parseCustomerID.customer_id || accessKey
            // let productListFeedback = productFeedback || [];
            let response;
            if (formID === "") {
                response = await Home_api.post('/fetchFeedBack', { accessToken: customerID })
            }
            else {
                response = await Home_api.post('/fetchFeedBackID/' + formID, { accessToken: customerID })
            }
            if (response.data.result === "Success") {
                if (response.data.feedbacks.length > 0) {
                    let newData = response.data.feedbacks;
                    let products = JSON.parse(response.data.feedbacks[0].items);
                    setSeeMore(products.length);
                    if (skip !== "true") {
                        setShow(true);
                    }
                    else if (urlChecker.includes("user-profile")) {
                        setShow(false);
                    }
                    const renderProds = products.map((prod, index) => {
                        productFeedback.push({ itemSku: prod.item_sku, itemName: prod.item_name, feedback: "" })
                        return (
                            <tr className="list">
                                <td className="p-0 w-50">&#8226; {prod.item_name}</td>
                                <td className="p-0 w-25 text-center">{prod.cart_quantity}</td>
                                <td className="p-0 w-25 text-center">
                                    <button className="p-2" id={`like_${prod.item_sku}`} style={style.buttonLike} onClick={clickLike} >
                                        <img className="" src={likeIconGray} data-id="like" data-sku={prod.item_sku} alt="Like!" width="16" />
                                    </button>
                                    <button className="p-2 d-none" id={`liked_${prod.item_sku}`} style={style.buttonLike} onClick={clickLike} >
                                        <img className="" src={likeIconGreen} data-id="liked" data-sku={prod.item_sku} alt="Like!" width="16" />
                                    </button>

                                    <button className="p-2" id={`dislike_${prod.item_sku}`} style={style.buttonLike} onClick={clickLike} >
                                        <img className="" src={dislikeIconGray} data-id="dislike" data-sku={prod.item_sku} alt="Dislike!" width="16" />
                                    </button>

                                    <button className="p-2 d-none" id={`disliked_${prod.item_sku}`} style={style.buttonLike} onClick={clickLike} >
                                        <img className="" src={dislikeIconGreen} data-id="disliked" data-sku={prod.item_sku} alt="Disliked!" width="16" />
                                    </button>
                                </td>
                            </tr>
                        )
                    });
                    const renderToFeedBack = newData.map((optionFeed, index) => {
                        setOrderNo(optionFeed.order_number)
                        setProductList(renderProds)
                        return (
                            <div className="row " key={index}>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <label id="ratingLabel">Ratings</label>
                                        </div>
                                        <input type="hidden" name="orderid" id="orderid" value={optionFeed.order_id} />

                                        <div className="rating">
                                            <input type="radio" id="star5" name="rating" value="5"
                                                onClick={
                                                    (e) => {
                                                        document.getElementById("rateText").innerText = "Perfect!";
                                                        document.getElementById("rateText").setAttribute("class", "text-success");
                                                    }
                                                }
                                            />
                                            <label htmlFor="star5"></label>
                                            <input type="radio" id="star4" name="rating" value="4"
                                                onClick={
                                                    (e) => {
                                                        document.getElementById("rateText").innerText = "Near Perfect!";
                                                        document.getElementById("rateText").setAttribute("class", "text-greenlight");
                                                    }
                                                } />
                                            <label htmlFor="star4"></label>
                                            <input type="radio" id="star3" name="rating" value="3"
                                                onClick={
                                                    (e) => {
                                                        document.getElementById("rateText").innerText = "OK";
                                                        document.getElementById("rateText").setAttribute("class", "text-yellow");
                                                    }
                                                } />
                                            <label htmlFor="star3"></label>
                                            <input type="radio" id="star2" name="rating" value="2"
                                                onClick={
                                                    (e) => {
                                                        document.getElementById("rateText").innerText = "Poor";
                                                        document.getElementById("rateText").setAttribute("class", "text-orange");
                                                    }
                                                }
                                            />
                                            <label htmlFor="star2"></label>
                                            <input type="radio" id="star1" name="rating" value="1"
                                                onClick={
                                                    (e) => {
                                                        document.getElementById("rateText").innerText = "Terrible";
                                                        document.getElementById("rateText").setAttribute("class", "text-danger");
                                                    }
                                                }
                                            />
                                            <label htmlFor="star1"></label>
                                        </div>
                                        <br />
                                        <b id="rateText"></b>

                                        <br />
                                        <div className="w-50 p-1"></div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col p-3">
                                            <label>Comments</label>
                                            <textarea name="comments" className="form-control" id={`customerFeed_${optionFeed.order_id}`} rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        );
                    });
                    setProductFeedback(productFeedback);
                    setFeedlist(renderToFeedBack)

                }
            }
        }
        if (isLogged) {
            getFeedBack()
        }

        const clickLike = (e) => {
            // setRefresh(!refresh)
            e.preventDefault();
            let sku = e.target.getAttribute("data-sku");
            let type = e.target.getAttribute("data-id");
            let likeStatus;
            if (type === "liked") {

                likeStatus = productFeedback.filter(element => element.itemSku === sku)
                likeStatus[0].feedback = "";

                document.getElementById("liked_" + sku)?.classList.add('d-none');
                document.getElementById("disliked_" + sku)?.classList.add('d-none');
                document.getElementById("liked_" + sku)?.classList.remove("d-inline-block");
                document.getElementById("like_" + sku)?.classList.add("d-inline-block");
                document.getElementById("like_" + sku)?.classList.remove('d-none');
                document.getElementById("dislike_" + sku)?.classList.remove('d-none');
                document.getElementById("dislike_" + sku)?.classList.add('d-inline-block');

            }
            else if (type === "like") {

                likeStatus = productFeedback.filter(element => element.itemSku === sku)
                likeStatus[0].feedback = "1";
                document.getElementById("liked_" + sku)?.classList.remove('d-none');
                document.getElementById("dislike_" + sku)?.classList.remove('d-none');
                document.getElementById("disliked_" + sku)?.classList.add('d-none');
                document.getElementById("like_" + sku)?.classList.add("d-inline-block");
                document.getElementById("like_" + sku)?.classList.add("d-none");
            }
            else if (type === "dislike") {

                likeStatus = productFeedback.filter(element => element.itemSku === sku)
                likeStatus[0].feedback = "2";
                document.getElementById("disliked_" + sku)?.classList.remove('d-none');
                document.getElementById("disliked_" + sku)?.classList.add('d-inline-block');
                document.getElementById("dislike_" + sku)?.classList.add('d-none');
                document.getElementById("liked_" + sku)?.classList.add("d-none");
                document.getElementById("like_" + sku)?.classList.remove('d-none');
                document.getElementById("like_" + sku)?.classList.add("d-inline-block");

            }
            else if (type === "disliked") {

                likeStatus = productFeedback.filter(element => element.itemSku === sku)
                likeStatus[0].feedback = "2";
                document.getElementById("dislike_" + sku)?.classList.remove('d-none');
                document.getElementById("dislike_" + sku)?.classList.add('d-inline-block');
                document.getElementById("disliked_" + sku)?.classList.add('d-none');
                document.getElementById("liked_" + sku)?.classList.add("d-none");
                document.getElementById("like_" + sku)?.classList.remove('d-none');
                document.getElementById("like_" + sku)?.classList.add("d-inline-block");

            }
            console.log(likeStatus)
            console.log(productFeedback)
        }
    }, [formID, refresh])

    const handleClose = (e) => {
        setShow(false);
        const orderid = document.getElementById("orderid").value;
        const rate = "-";
        const comments = "-";
        const details = {
            orderid: orderid,
            rate: rate,
            comments: comments
        }
        // console.log(details)
        localStorage.setItem("skip", true);
        insertFeedBack(details);
    }

    const handleShow = (event) => {
        setShow(true)
        let el = event.currentTarget.dataset.id;
        setFormID(el)
        localStorage.setItem("skip", false);
    }
    const submitFeedback = (e) => {
        e.preventDefault();
        const orderid = e.target.orderid.value;
        const rate = e.target.rating.value;
        const comments = e.target.comments.value;
        const listofProds = productFeedback;
        const details = {
            orderid: orderid,
            rate: rate,
            comments: comments,
            listofProds: listofProds
        }
        if (rate !== "") {
            localStorage.setItem("skip", true);
            insertFeedBack(details);
        }
        else {
            setShowAlert(true) // alert will show
            setVariant("danger") // for success alert, green colored box
            setMsg("Ratings should not be blank!") // success msg
        }
    };

    const showProds = () => {
        const nodeList = document.querySelectorAll(".list");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.add("productListFB");
        };

        document.getElementById("showProds").style.display = 'none';
        document.getElementById("showLess").style.display = 'block';
    }
    const showLess = () => {
        const nodeList = document.querySelectorAll(".list");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.remove("productListFB");
        };
        document.getElementById("showProds").style.display = 'block';
        document.getElementById("showLess").style.display = 'none';
    }
    const insertFeedBack = async (details) => {
        //  alert("test")
        await Home_api.post(`/insertFeedBack`, details)
            .then(response => {
                // once the result is success

                setShowAlert(true) // alert will show
                setVariant("success") // for success alert, green colored box
                setMsg("Thank you for your feedback!") // success msg
                setTimeout((function () { // added timeout before closing modal, to show success alert

                    if (urlChecker.includes("user-profile")) {
                        window.location.reload();
                    }
                    else {
                        setShow(false)
                    }
                }), 1000);

            })
            .catch(error => {
                console.log(error)

                // // once the result is error
                setShowAlert(true) // alert will show
                setVariant("danger") // for danger alert, red colored box
                setMsg("Something went wrong! Please try again later") // error msg
            })
    }
    return <React.Fragment>
        {formShow === "show" ?
            <>
                <Alert variant={variant} show={showAlert}>
                    {msg}
                </Alert>
                <form onSubmit={submitFeedback} >
                    <div id="feedbackHistory">
                        {feedlist}

                        <div className="row">
                            <div className="form-group col-12 mt-2">
                                {/* <button className="btn btn-primary">Submit</button> */}
                            </div>
                        </div>
                    </div>
                </form>
            </>

            :
            <>

                {urlChecker.includes("user-profile")
                    ?
                    <Button variant="primary" onClick={handleShow} data-id={orderID}>
                        Add Feedback
                    </Button>
                    : ""}
                <Modal show={show} onHide={handleClose} backdrop="static">
                    <Modal.Header className="p-0">
                        <Modal.Title id="feedBackHeader">
                            <img src="https://res.cloudinary.com/dw5axbz64/image/upload/v1648609575/upload/b6coohojb7zme9afhpmq.png" style={{ "width": "100%" }} />
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body id="feedBackBody">
                        <Alert variant={variant} show={showAlert}>
                            {msg}
                        </Alert>
                        <form onSubmit={submitFeedback} >
                            {feedlist}

                            <div className="row">
                                <div className="col-12">
                                    <h5 className="mt-2 mb-0 ordernumTitle">Order Number: {orderNo}</h5>
                                    <table className="table ordernumTitle productList" id="listofProds">
                                        <thead>
                                            <tr>
                                                <th scope="col p-0">Product Name</th>
                                                <th scope="col p-0" style={{"textAlign":"center"}}>Quantity</th>
                                                <th scope="col p-0" style={{"textAlign":"center"}}>Rate</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {productlist}
                                        </tbody>
                                    </table>


                                    {seeMore > 3 ?
                                        <>
                                            <a className="link-warning" id="showLess" onClick={showLess}>Show Less</a>
                                            <a className="link-warning" id="showProds" onClick={showProds}>See More</a>
                                        </>
                                        : ""
                                    }
                                </div>
                            </div>
                            <hr />

                            <div className="row float-end">
                                <div className="form-group col-12 mt-2">
                                    <label style={{ "cursor": "pointer", "marginRight": "6px" }} onClick={handleClose}>
                                        Maybe Later
                                    </label>
                                    <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </>
        }
    </React.Fragment>
}

export default FeedBack;