import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Home_api from '../APIs/Home';

const style = {
    colorRed: {
        color: 'red'
    },
    checkGreen: {
        verticalAlign: "text-bottom",
        color: "#24b14b",
        marginLeft: "4px"
    }
}

const ModalUpdateInfo = () => {
    // select city branch setter
    const isLogged = sessionStorage.getItem("Login") || null; // login checker
    let user = JSON.parse(isLogged);
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [isSaving, setIsSaving] = useState("Save");
    const [emailMsg, setEmailMsg] = useState("");
    const [phoneMsg, setPhoneMsg] = useState("");


    useEffect(() => {
        setEmailMsg("check");
        setShow(false);
        if (user !== null) {
            setEmail(user.customer_email || "");
            setPhone(user.customer_phone || "");
            setTimeout(() => {
                if (user.customer_email !== "") {
                    setEmailMsg("check");
                    setShow(false);
                }
                else {
                    setEmailMsg("Format should be : sample_user@gmail.com");
                    setTimeout(() => {
                        setShow(true);
                    }, 1000);
                }
                if (user.customer_phone !== "") {
                    setPhoneMsg("check");
                    setShow(false);
                }
                else {
                    setPhoneMsg('Format should be: (+63) 9xxxxxxxxx');
                    setTimeout(() => {
                        setShow(true);
                    }, 1000);
                }
            }, 200);

        }
    }, [isLogged])

    const submitUpdate = () => {
        setIsSaving("Saving...");
        let body = {
            token: user.token,
            customer_name: user.customer_name,
            userVerified: user.userVerified,
            customer_id: user.customer_id,
            customer_email: email,
            customer_phone: phone,
            newsletter: user.newsletter,
            preferredNotification: user.preferredNotification,
            OTP: user.OTP,
            review_policy: user.review_policy
        }

        setTimeout(() => {
            updateContactInfo(body)
        }, 500);
    }

    const emailFormat = (e) => {
        let inputEmail = e.target.value;
        setEmail(e.target.value)
        if (!inputEmail.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailMsg("Format should be : sample_user@gmail.com");
        }
        else {
            setEmailMsg("check");
        }
    }

    const phoneFormat = (e) => {
        let inputPhone = e.target.value;
        setPhone(e.target.value)

        if (inputPhone.length > 10 || inputPhone.length < 10) {
            setPhoneMsg('Format should be: (+63) 9xxxxxxxxx');
        }
        else {
            setPhoneMsg("check");
        }
    }

    const updateContactInfo = async (body) => {
        // alert("accept")
        await Home_api.post(`/update_contactInfo/`, body)
            .then(response => {
                sessionStorage.setItem("Login", JSON.stringify(body));
                setIsSaving(response.data.status);
                setTimeout(() => {
                    setShow(false);
                    // window.location.reload()
                    console.log(user)
                }, 800);
            })
            .catch(error => {
                console.log(error)
            })
    }


    return (
        <>
            {isLogged ?
                <Modal
                    show={show}
                    backdrop="static"
                    keyboard={false}>
                    <Modal.Header>
                        <Modal.Title>
                            <center>
                                <h5 className='mb-0'>Kindly update your contact info. Thanks!</h5>
                            </center>
                        </Modal.Title>
                    </Modal.Header>
                    <form>
                        <Modal.Body>
                            <div className='row'>
                                <label>Email Address
                                </label>
                                <div className='col-12 mt-1'>
                                    <input type="email" required
                                        placeholder='email@gmail.com'
                                        className={`form form-control ${emailMsg === "check" ? "is-valid" : ""} ${emailMsg === "Format should be : sample_user@gmail.com" ? "is-invalid" : ""}`}
                                        value={email}
                                        onChange={emailFormat}
                                    />
                                    {emailMsg === "Format should be : sample_user@gmail.com" ? <span style={style.colorRed}>{emailMsg}</span> : ''}
                                </div>
                            </div>
                            <div className='row'>
                                <label>Phone Number
                                </label>
                                <div className='col-12 mt-1'>

                                    <div className="input-group">
                                        <div
                                            className={`input-group-prepend ${phoneMsg === "check" ? "border border-success" : ""} ${phoneMsg === "Format should be: (+63) 9xxxxxxxxx" ? "border border-danger" : ""}`}>
                                            <span className="input-group-text" id="basic-addon1">(+63)</span>
                                        </div>
                                        <input type="number" required
                                            placeholder='9XXXXXXXX'
                                            className={`form form-control ${phoneMsg === "check" ? "is-valid" : ""} ${phoneMsg === "Format should be: (+63) 9xxxxxxxxx" ? "is-invalid" : ""}`}
                                            value={phone}
                                            onChange={phoneFormat}
                                        />
                                    </div>
                                    {phoneMsg === "Format should be: (+63) 9xxxxxxxxx" ? <span style={style.colorRed}>{phoneMsg}</span> : ''}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={submitUpdate} disabled={phoneMsg === "check" && emailMsg === "check" ? false : true} >
                                {isSaving}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
                :
                ""}
        </>
    );
};

export default ModalUpdateInfo;

