//  * File Name                : SelectedRecipe
//  * File Directory           : src/components/Misc/RecipePage/SelectedRecipe
//  * Module                   : SelectedRecipe
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/App.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <SelectedRecipe/>
 *
 *******************************************************************************************/


// import react library
import React, { useRef } from 'react'
// import useParams 
import { useParams } from 'react-router-dom'
// import header
import RecipeHeader from './Components/Header';
// import recipe list
import { recipeList } from './recipeList';
// import selected recipe slideshow
import SelectedRecipeSlideShow from './Components/SelectedRecipeSlideShow';
// import suggested recipe
import SuggestedRecipe from './Components/SuggestedRecipe';

// export selected recipe
const SelectedRecipe = () => {
  // set parameters as params
  const params = useParams()
  // filter recipe list equal to params data
  const filterRecipe = recipeList.filter( el => el.recipeName === params.recipe)
  // declare image variable
  let selected_image = filterRecipe[0].recipeImages.image[0]
  // declare video variable
  let selected_video = filterRecipe[0].recipeVideo
  // declare name variable
  let selected_name = filterRecipe[0].recipeName
  // useRef for printing
  const componentRef = useRef();
  if(filterRecipe.length === 0){
    // redirect to main page if 0 result upon filter
    window.location.href = "/ContentPage/recipe"
  }
  // to filter recipe ingredients
  const toFilterIngredient = filterRecipe[0].recipeDetails.ingredients
  // to filter recipe procedures
  const toFilterProcedure = filterRecipe[0].recipeDetails.procedures
  // render ingredients 
  const renderIngredients = toFilterIngredient.map(ingredients => {
    return (
      <li>
        <p>
          {ingredients}
        </p>
      </li>
    )
  })
  // render procedure
  const renderProcedures = toFilterProcedure.map(procedures => {
    return (
      <li>
        <p>
          {procedures}
        </p>
      </li>
    )
  })

  return (
    <div>
      <div className='row'>
        <RecipeHeader/>
      </div>
      <div className='row'>
        <div id="recipeCarousel">
          <SelectedRecipeSlideShow componentRef={componentRef} recipe={filterRecipe[0]}/>
        </div>
      </div>
      <div className='row recipe-details-margin marginLR mt-50'>
          <div ref={componentRef} className='row p-0 recipe-details'>
            <div className='centeredText'>
              <h1>
                Let's make {selected_name}!
              </h1>
            </div>
              <div className='ingredients'>
                <div className='row'>
                  <h2>Ingredients:</h2>
                </div>
                <div className='row mt-20'>
                  <ul>
                    {renderIngredients}
                  </ul>
                </div>
              </div>
              <div className='procedure'>
                <div className='row'>
                  <h2>Procedures:</h2>
                </div>
                <div className='row mt-20'>
                  <ol>
                    {renderProcedures}
                  </ol>
                </div>
              </div> 
              <div className='tutorial'>
                <video className='video' width="100%" height="100%" poster={selected_image} controls >
                  <source src={selected_video} type="video/mp4"/>
                </video>
              </div>
          </div>
      </div>
      <div className='row suggested-recipe-margin marginLR mt-20'>
        <div className='row collection'>
          <div className="row">
            <h2>Other Recipes</h2>
          </div>
          <div className="row mt-20">
            <SuggestedRecipe />
          </div>
        </div>
      </div>    
    </div>
  )
}
export default SelectedRecipe
