const INTIAL_STATE = {
    location : window.location.pathname
}


const LocationReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_LOCATION':
            return {
                ...state,
                location: action.location
            };
        default:
            return state;
    }

};

export default LocationReducer;