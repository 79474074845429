import React, { useEffect, useState } from "react";
import Home from '../APIs/Home';
import LazyLoad from "react-lazyload";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { forgotModal } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";
import { connect, useDispatch } from "react-redux";
import { v4 as uuid } from 'uuid';
import _ from 'lodash'

const CartRemoveButton = ({
    CartRedux,
    item
}) => {
    
    const dispatch = useDispatch()
    const [cartQty, setCartQty]=useState(0)
    const [onCart, setOnCart]=useState(false)
    useEffect(()=>{
        let cartList=CartRedux
        let itemDetails = cartList.filter((obj) => {
            return obj.item_id === item?.item_id;
        });
        let qty = cartQty
        if (itemDetails.length > 0) {
            setCartQty(itemDetails[0].cart_qty)
            setOnCart(true)
        }else{
            setOnCart(false)
            setCartQty(0)
        }
    },[CartRedux])
    const btnRemoveOnClick = () => {
        dispatch({
            type: "REMOVE_ITEM",
            id: item?.item_id
        });
    }
    return(
    <div className="product-buttons">
        {
            <button
                id={`btnRemoveCart${item?.item_id}`}
                onClick={btnRemoveOnClick}
                className="product-button-oval remove-button"
                >
                Remove
            </button >
        }
    </div>
    )
};

const mapStateToProps = (state) => {
    
    return {
    CartRedux: state.cart.CartList,
    };
};

export default connect(mapStateToProps)(CartRemoveButton);