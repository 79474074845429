/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : SpecificItemList
 * File Directory           : src/components/Shared/SpecificItemList
 * Module                   : Items
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/Search.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <SpecificItemList/>
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import LoadingModal from "../Modal/LoadingModal";
import { useParams } from "react-router-dom";
import Home from "../APIs/Home";
import ItemCardv2 from "./ItemCardv2";
import ItemViewModal from "./ItemViewModal";
import { set } from "react-ga";
import { connect } from "react-redux";
import { useTimer } from 'react-timer-hook';
import ProductPage from "./productPage";
import { height } from "@mui/system";
const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'zoom-in',
        position: 'relative',
        height: '45vw'
    }
}


const SpecificItemList = ({
    search,
    branch,
    options,
    setTotal,
    CartRedux,
    specificList
}) => {
    const sku = useParams();
    let accessKey = localStorage.getItem('accessKey')
    const [isLoading, setIsLoading] = useState(false) //showing loading modal
    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload
    const selectedBranch = branch !== null ? branch.branch_id : 1 //setting default value for selected branch
    const [items, setItems] = useState([]) //list of products
    const [filtered, setFiltered] = useState([]) //list of products
    const userLogin = JSON.parse(sessionStorage.getItem('Login')) || null;
    const userId = userLogin ? userLogin.customer_id : null
    const [onLoad, setOnLoad] = useState(true)
    const [isNoItems, setIsNoItems] = useState(false)
    const [isRendered, setIsRendered] = useState(true)
    const expiryTimestamp = (new Date()).setSeconds((new Date()).getSeconds())
    let autoStart = false
    const {
        seconds,
        minutes,
        hours,
        restart,
    } = useTimer({
        // expiryTimestamp, autoStart, onExpire: () =>
        //     // window.location.reload()
    });

    // useEffect(() => {
    //     if (onLoad && items.length > 0) {
    //         items.some(element => {
    //             if (element.category.includes("flash")) {
    //                 restart((new Date(element.flash_end_date)).setSeconds((new Date(element.flash_end_date)).getSeconds()))
    //                 return true;
    //             }
    //         });
    //         setOnLoad(false)
    //     }
    // }, [items])

    //useEffect for getting products in DB 
    // console.log(isRendered);
    useEffect(async () => {
        setIsNoItems(false)
        // console.log("TRIGGERS")
        // if (isRendered)
            try {
                setIsRendered(false)
                // setIsLoading(true)
                const response = await Home.get(`/itemList2/ALL/${selectedBranch}/ALL${userLogin ? "?userId=" + userId : ""}`)
                if (response.status === 200) {
                    let result = response.data.data
                    let itemChecker = result !== "No Remaining Products"
                    if (itemChecker) {
                        result = result.map((item) => {
                            let defaultImg = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
                            item.item_image = item.item_image && item.item_image !== "NULL" ? item.item_image : defaultImg
                            let category = item.category
                            item.category = category.split(",")
                            return item
                        })
                    }
                    //product page removing item itself and only enabled items are shown
                    if ((window.location.pathname).includes("productPage")) {
                        result = result.filter((item) => { return item.item_sku !== sku.sku && item.item_status === "enabled" })
                    }
                    // setItems(itemChecker ? result : [])
                    // setFiltered(itemChecker ? result : [])
                    if (specificList.length>0) {
                        //filter items base on search
                        let inSearch = result.filter((val, index, arr) => {
                            return specificList.includes(val.item_sku) ? val : null
                        })
                        if ((window.location.pathname).includes("productPage")) {
                            inSearch = inSearch.map((el) => {
                                return { ...el, category: ["relatedingredients"] } 
                            })
                        }
                        // console.log("-----------inSearch");
                        // console.log(inSearch);
                        setFiltered(inSearch)
                        setItems(inSearch ? result : [])
                        if (inSearch.length === 0) {
                            setIsNoItems(true)
                        }
                    }
                    setIsLoading(false)
                }
            } catch (err) {
                console.log(err.message)
            }
    }, [branch, specificList])

    // //useEffect for every cart changes save in DB 
    // useEffect(() => {
    //     if (!isFirstRender)
    //         try {
    //             Home.post('/accessStorage/changeCart', {
    //                 itemOnCart: CartRedux,
    //                 accessToken: accessKey
    //             })
    //         } catch (err) {
    //             console.log(err.message)
    //         }
    //     else
    //         setIsFirstRender(false)
    // }, [CartRedux])
    // useEffect(() => {
    // }, [specificList])
    return (
        <React.Fragment>
            <div className="container p-0">
                {filtered.length > 0 ?
                    <React.Fragment>
                        {/* {console.log("test1")} */}
                        <div className="item-list">
                            {filtered.map((item, i) => (
                                <React.Fragment key={i}>
                                    <ItemCardv2 items={item} />
                                </React.Fragment>
                            ))
                            }
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                    {/* {console.log("test12")} */}
                        {isNoItems ?
                            <div className="container" style={style.center}>
                                <h5>
                                    No product/s match your search.
                                </h5>
                            </div>
                            :
                            <div className="container" style={style.center} />
                            
                        }
                    </React.Fragment>
                }
            </div>
            <LoadingModal
                modalStatus={isLoading && selectedBranch !== 0 ? true : false}
            />
            <ItemViewModal />
        </React.Fragment>

    )

}


SpecificItemList.propTypes = {
  specificList: PropTypes.array,
  options: PropTypes.array
};
SpecificItemList.defaultProps = {
    specificList: [],
    options:[]
};
const mapStateToProps = (state) => {
    return {
        CartRedux: state.cart.CartList,
    };
};

export default connect(mapStateToProps)(SpecificItemList);