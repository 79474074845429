/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ItemList
 * File Directory           : src/components/Shared/ItemList
 * Module                   : Items
 * Author                   : Jenhel
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/Search.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <ItemList/>
 *******************************************************************************************/
import React, { useEffect, useState, useRef } from 'react';
import Home from '../APIs/Home';
import ItemModal from './ItemModal';
import ConfirmContactModal from './ConfirmContactModal';
import LoadingModal from '../Modal/LoadingModal';
import { useParams } from "react-router-dom";
import ItemCard from './ItemCard';
import { getElementError } from '@testing-library/dom';
import { useDispatch } from "react-redux";
import { useTime, useTimer } from 'react-timer-hook';
const style = {
    categP: {
        position: 'absolute',
        top: '-100px',
        opacity: '0',
        color: 'transparent'
    },
    categDiv: {
        position: 'relative'
    },
    timeBorder: {
        border: "solid 1px #dc3545",
        padding: "1px",
        background: "#dc3545",
        color: "#fff",
        borderRadius: "5px",
        fontWeight: "400",
        fontSize: "15px"
    },
    timeTitle: {
        verticalAlign: "super",
        fontSize: "12px",
        marginLeft: "12px"
    }
}
let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    category: "all",
    item_status: "enabled",
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const ItemList = ({ search, options = [{ category: "ALL" }], selectedCity, total, setTotal, branch, cartVChange, setVisibleLogin, setCartVChange, cartVDetails, setCartVDetails, viewing, setCart, cart, onCartLoaded, sku }) => {
    // let cart = []
    let accessKey = localStorage.getItem('accessKey')
    const [itemDetailModal, setItemDetailModal] = useState(defaultItem)
    const [cartChange, setCartChange] = useState(0)
    const [modalStatus, setModalStatus] = useState(false)
    const [loading, setLoading] = useState(true)
    const sku_params = useParams();
    const sku_tags = useParams();
    const [itemNotify, setItemNotify] = useState([])
    const [confirmContactmodalStatus, setConfirmContactModalStatus] = useState(false)
    const [onCart, setOnCart] = useState(cart || [])
    const [items, setItems] = useState([])
    const [onLoad, setOnLoad] = useState(true)
    const dispatch = useDispatch()
    const userLogin = JSON.parse(sessionStorage.getItem('Login')) || null;
    const userId = userLogin?.customer_id
    const isMobile = localStorage.getItem("isMobile") || "true"
    const sr_checker = search || ''
    // console.log(branch)
    const selectedBranch = branch !== null ? branch.branch_id : 0
    let viewType = window.innerWidth <= 640 ? viewing === "grid" ? 'col-6 p-1' : 'col-sm-4' : 'col-sm-4'


    // const time = (item.category).toLowerCase().includes("flash")?new Date(item.flash_end_date):new Date()
    const expiryTimestamp = (new Date()).setSeconds((new Date()).getSeconds())
    // let expiryTimestamp = time.setSeconds(time.getSeconds())
    let autoStart = false
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp, autoStart, onExpire: () =>
            window.location.reload()
    });

    useEffect(() => {
        setLoading(true)
        // console.log(selectedCity)
        const fetchItems = async () => {
            if (selectedBranch != 0) {
                //check if for order Details
                if (window.location.pathname === '/checkout-details') {
                    //get items from cart storage
                    let inSearch = cart
                    //check if response is no items 
                    if (inSearch === "No Remaining Products") {
                        setItems([])
                    }
                    else if (search !== "" && search !== "onCart") {
                        //filter items base on search
                        inSearch = inSearch.filter((val, index, arr) => {
                            return val.item_name.toUpperCase().match(sr_checker.toUpperCase()) ? val : null
                        })
                        setItems(inSearch)
                    } else if (search === "" || search === "onCart") {
                        setItems(inSearch)
                    }
                    //if user already choose a branch set loading to false
                    if (branch !== 0)
                        setLoading(false)
                    if (search === "" || search === "onCart" || search === undefined) {
                        cartChecker(inSearch)
                    }
                } else {
                    //get items from itemList API
                    // console.log(sku_tags.categoryTag);
                    let tags = sku_tags.categoryTag || "ALL";
                    const response = await Home.get(`/itemList2/${tags}/${selectedBranch}/ALL${userLogin ? "?userId=" + userLogin.customer_id : ""}`)
                    let inSearch = response.data.data || []
                    //check if response is no items
                    let filteredList = []
                    if (inSearch === "No Remaining Products") {
                        setItems([])
                    }
                    else if (search !== "" && search !== "onCart") {
                        let searchWords = (sr_checker.toUpperCase()).split(" ")
                        //filter items base on search
                        inSearch = inSearch.filter((val, index, arr) => {
                            return searchWords.every((currentValue) => val.item_name.toUpperCase().match(currentValue)) ? val : null
                        })
                        setItems(inSearch)
                    } else if (search === "" || search === "onCart") {
                        let startItems = []
                        let lastItems = inSearch.filter((val, index, arr) => {
                            if (val.item_status.toLowerCase() === "enabled") {
                                if (!val.item_name.toUpperCase().match('Rosario'.toUpperCase()) && !val.item_name.toUpperCase().match('mekeni'.toUpperCase())) {
                                    startItems.push(val)

                                }
                                return val.item_name.toUpperCase().match('Rosario'.toUpperCase()) ? val : val.item_name.toUpperCase().match('mekeni'.toUpperCase()) ? val : null

                            }
                        })
                        filteredList = startItems.concat(lastItems)
                        startItems = []
                        let lastItemsDisabled = inSearch.filter((val, index, arr) => {
                            if (val.item_status.toLowerCase() === "disabled") {
                                if (!val.item_name.toUpperCase().match('Rosario'.toUpperCase()) && !val.item_name.toUpperCase().match('mekeni'.toUpperCase())) {
                                    startItems.push(val)
                                }
                                return val.item_name.toUpperCase().match('Rosario'.toUpperCase()) ? val : val.item_name.toUpperCase().match('mekeni'.toUpperCase()) ? val : null

                            }
                        })
                        filteredList = filteredList.concat(startItems.concat(lastItemsDisabled))
                        setItems(filteredList)
                    }
                    //if user already choose a branch set loading to false
                    // if (branch !== 0)
                    //     setLoading(false)
                    if (search === "" || search === "onCart" || search === undefined) {
                        cartChecker(filteredList)
                    }

                }
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0;
            }

        }
        fetchItems()
    }, [sr_checker, selectedBranch, onCartLoaded, selectedCity])

    useEffect(() => {
        setOnCart(cart)
        if (cart.length != 0) {
            let itemsOnCart = cart || []
            itemsOnCart = itemsOnCart.filter(({ item_id: id2 }) => cart.some(({ item_id: id1 }) => id2 === id1));
            setOnCart(itemsOnCart)
            TotalComputation(itemsOnCart)
        }
    }, [cart])
    //validate current cart on items available
    const cartChecker = (inSearch) => {
        setOnCart(cart || [])
        if (inSearch !== "No Remaining Products") {
            let itemsOnCart = cart || []
            itemsOnCart = itemsOnCart.filter(({ item_id: id2 }) => inSearch.some(({ item_id: id1 }) => id2 === id1));
            setOnCart(itemsOnCart)
            TotalComputation(itemsOnCart)
            localStorage.setItem('cart', JSON.stringify(itemsOnCart))
        }
    }
    useEffect(() => {
        if (onLoad && items.length > 0) {
            items.some(element => {
                if (((element.category).toLowerCase()).includes("flash")) {
                    // setExpiryTimestamp((new Date(element.flash_end_date)).setSeconds((new Date(element.flash_end_date)).getSeconds()))
                    // start()
                    restart((new Date(element.flash_end_date)).setSeconds((new Date(element.flash_end_date)).getSeconds()))
                    return true;
                }
            });
            setOnLoad(false)
        }
    }, [items])
    //compute Total Items
    const TotalComputation = (itemsOnCart) => {
        let itemTotal = 0
        let itemCount = 0
        itemsOnCart.forEach(item => {
            if (item.cart_quantity) {
                sessionStorage.removeItem("total")
                localStorage.removeItem("cart")
            }
            if (item.item_status === "enabled") {
                itemTotal += parseFloat(item.cart_qty) * parseFloat(item.cart_price)
                itemCount += parseFloat(item.cart_qty)
            }
        });
        dispatch({
            type: "TOTAL_AMOUNT",
            total: parseFloat(itemTotal)
        })
        setTotal({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) })
        sessionStorage.setItem("total", JSON.stringify({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) }));
    }

    //================================================================
    //Both onCart and deBounce useEffect is used for adding Item To DB
    // useEffect(() => {
    //     alert("CART CHANGED")
    //         const timerId = setTimeout(() => {
    //             setDebouncedCart(onCart);
    //         }, 2000);
    //         return () => {
    //             clearTimeout(timerId);
    //         };
    // }, [onCart])

    // useEffect(() => {
    //     console.log(didMount.current)
    //     if (didMount.current) {
    //         console.log(onCartLoaded)
    //         if(onCartLoaded && debouncedCart===cart){
    //             const fetchItems = async () => {
    //                 console.log(debouncedCart.length)
    //                 if (userId !== null) {

    //                     await Home.post('/accessStorage/changeCart', {
    //                         itemOnCart: debouncedCart,
    //                         accessToken: accessKey
    //                     })
    //                 }
    //             }
    //             fetchItems()
    //         }
    //     }
    //     else didMount.current = true;
    // }, [debouncedCart]);

    //filter items to show cart items only
    let results = items
    if (search === "onCart") {
        results = items.filter(({ item_id: id1 }) => onCart.some(({ item_id: id2 }) => id2 === id1));
    }

    // console.log(options)    
    //render category with items
    const renderedCateg = options.map((category, index) => {
        let renderedItems, counter = 0
        if (results !== "No Remaining Products") {

            var points = results;
            // points.sort(function(a, b){return 0.5 - Math.random()});
            renderedItems = points.map((item, index) => {
                let inCart = onCart.filter((val, index, arr) => {
                    return val.item_id === item.item_id ? val.cart_qty : null
                })
                inCart = inCart[0] || { id: item.item_id, cart_qty: 0 }

                if ((window.location.pathname).includes("search")) {

                    return <div className={`${isMobile ? (search === "onCart" && window.location.pathname !== '/') || ((window.location.pathname).includes("productPage") && sku_params.sku !== item.item_sku) ? '' : viewType + ' mb-2' : ''}`} key={item.item_id + "_" + category.category_id}>

                        <ItemCard
                            search={search}
                            key={item.item_id}
                            item={item}
                            incart={inCart}
                            setOnCart={setOnCart}
                            userId={userId}
                            total={total}
                            itemDetailModal={itemDetailModal}
                            setTotal={setTotal}
                            cartChange={cartChange}
                            setCartChange={setCartChange}
                            modalStatus={modalStatus}
                            cart={cart}
                            setCart={setCart}
                            setVisibleLogin={setVisibleLogin}
                            itemNotify={itemNotify}
                            setItemNotify={setItemNotify}
                            setConfirmContactModalStatus={setConfirmContactModalStatus}
                            setModalStatus={setModalStatus}
                            setItemDetailModal={setItemDetailModal}
                            cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing}
                        />
                    </div>
                }
                else if ((window.location.pathname).includes("productPage") && category.category) {

                    let re = new RegExp(category.category.replace(" ", '').replace(" ", '').toLowerCase(), 'g');
                    let match = item.category.replace(" ", '').replace(" ", '').toLowerCase().match(re)
                    // console.log(match + " " + counter + " " + item.category)
                    if (match !== null && counter < 20) {
                        // console.log("pasok")
                        if (item.item_sku !== sku_params.sku && item.item_status !== "disabled") {
                            counter++;
                            // console.log("pasok")
                            return <div id="prodCard" className={`cardNo ${isMobile ? search === "onCart" && window.location.pathname !== '/' ? '' : viewType + ' mb-2' : ''} `} key={item.item_id}>

                                <ItemCard
                                    search={search}
                                    key={item.item_id}
                                    item={item}
                                    incart={inCart}
                                    setOnCart={setOnCart}
                                    userId={userId}
                                    total={total}
                                    setTotal={setTotal}
                                    itemDetailModal={itemDetailModal}
                                    cartChange={cartChange}
                                    setCartChange={setCartChange}
                                    modalStatus={modalStatus}
                                    cart={cart}
                                    setVisibleLogin={setVisibleLogin}
                                    itemNotify={itemNotify}
                                    setItemNotify={setItemNotify}
                                    setConfirmContactModalStatus={setConfirmContactModalStatus}
                                    setCart={setCart}
                                    setModalStatus={setModalStatus}
                                    setItemDetailModal={setItemDetailModal}
                                    cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing}
                                />
                            </div>
                        }
                    }
                    else {
                        if (item.item_sku !== sku_params.sku) {
                            return <div className={`related-d-None`} key={item.item_id}>

                                <ItemCard
                                    search={search}
                                    key={item.item_id}
                                    item={item}
                                    incart={inCart}
                                    setOnCart={setOnCart}
                                    userId={userId}
                                    total={total}
                                    setTotal={setTotal}
                                    itemDetailModal={itemDetailModal}
                                    cartChange={cartChange}
                                    setCartChange={setCartChange}
                                    modalStatus={modalStatus}
                                    setVisibleLogin={setVisibleLogin}
                                    itemNotify={itemNotify}
                                    setItemNotify={setItemNotify}
                                    setConfirmContactModalStatus={setConfirmContactModalStatus}
                                    cart={cart}
                                    setCart={setCart}
                                    setModalStatus={setModalStatus}
                                    setItemDetailModal={setItemDetailModal}
                                    cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing}
                                />
                            </div>
                        }
                    }
                }
                else if (category.category) {

                    return <div className={`${isMobile ? (search === "onCart" && window.location.pathname !== '/') || (window.location.pathname).includes("productPage") ? '' : viewType + ' mb-2' : ''}`} key={item.item_id + "_" + category.category_id}>

                        <ItemCard
                            search={search}
                            key={item.item_id}
                            item={item}
                            incart={inCart}
                            setOnCart={setOnCart}
                            userId={userId}
                            total={total}
                            itemDetailModal={itemDetailModal}
                            setTotal={setTotal}
                            cartChange={cartChange}
                            setCartChange={setCartChange}
                            modalStatus={modalStatus}
                            cart={cart}
                            setCart={setCart}
                            setModalStatus={setModalStatus}
                            setVisibleLogin={setVisibleLogin}
                            itemNotify={itemNotify}
                            setItemNotify={setItemNotify}
                            setConfirmContactModalStatus={setConfirmContactModalStatus}
                            setItemDetailModal={setItemDetailModal}
                            cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing}
                        />
                    </div>
                }
                else if (!category.category) {
                    let ifSale = (category.category_tag.replace(" ", '').replace(" ", '').toLowerCase()).match("sale")
                    let ifRecommended = (category.category_tag.replace(" ", '').replace(" ", '').toLowerCase()).match("recommended")
                    let re = new RegExp(category.category_tag.replace(" ", '').replace(" ", '').toLowerCase(), 'g');
                    let match = item.category.replace(" ", '').replace(" ", '').toLowerCase().match(re)

                    if (match !== null) {
                        if (ifSale !== null || ifRecommended !== null) {
                            if (item.item_status === "enabled") {
                                counter++;
                                return <div className={`${isMobile ? search === "onCart" && window.location.pathname !== '/' ? '' : viewType + ' mb-2' : ''}`} key={item.item_id}>
                                    <ItemCard
                                        search={search}
                                        key={item.item_id}
                                        item={item}
                                        incart={inCart}
                                        setOnCart={setOnCart}
                                        userId={userId}
                                        total={total}
                                        setTotal={setTotal}
                                        itemDetailModal={itemDetailModal}
                                        cartChange={cartChange}
                                        setCartChange={setCartChange}
                                        modalStatus={modalStatus}
                                        cart={cart}
                                        setCart={setCart}
                                        setModalStatus={setModalStatus}
                                        setItemDetailModal={setItemDetailModal}

                                        setVisibleLogin={setVisibleLogin}
                                        itemNotify={itemNotify}
                                        setItemNotify={setItemNotify}
                                        setConfirmContactModalStatus={setConfirmContactModalStatus}
                                        cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing}
                                    />
                                </div>
                            }
                        } else {
                            counter++;
                            return <div className={`${isMobile ? search === "onCart" && window.location.pathname !== '/' ? '' : viewType + ' mb-2' : ''}`} key={item.item_id}>
                                <ItemCard
                                    search={search}
                                    key={item.item_id}
                                    item={item}
                                    incart={inCart}
                                    setOnCart={setOnCart}
                                    userId={userId}
                                    total={total}
                                    setTotal={setTotal}
                                    itemDetailModal={itemDetailModal}
                                    cartChange={cartChange}
                                    setCartChange={setCartChange}
                                    modalStatus={modalStatus}
                                    cart={cart}
                                    setCart={setCart}
                                    setVisibleLogin={setVisibleLogin}
                                    itemNotify={itemNotify}
                                    setItemNotify={setItemNotify}
                                    setConfirmContactModalStatus={setConfirmContactModalStatus}
                                    setModalStatus={setModalStatus}
                                    setItemDetailModal={setItemDetailModal}
                                    cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} viewing={viewing}
                                />
                            </div>
                        }
                    }
                }
            })
        }
        if (counter > 0 || window.location.pathname === '/checkout-details' && selectedBranch !== 0 && window.location.pathname.includes(sku_tags?.categoryTag) === false) {
            let showVisible = window.location.pathname.includes(sku_tags?.categoryTag)
            if (!category.category && selectedBranch !== 0) {
                let item = document.getElementById(`li_${category.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                if (item)
                    item.style.display = 'block'
                item = document.getElementById(`form-${category.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                if (item)
                    item.classList.remove('categ-d-none')
            }
            return <div
                className={`prod_ROW row ${results === "No Remaining Products" ? 'd-none' : ''} ${showVisible ? sku_tags?.categoryTag === category.category_tag ? "d-flex" : "d-none" : "d-flex"} `}
                id={!category.category ? 'h_' + category.category_tag.replace(" ", '').replace(" ", '').toLowerCase() : ''} key={`categoryItems${!category.category ? category.category_tag.replace(" ", '').toLowerCase() : 'all'}`}>
                {search !== "onCart" ?
                    <div className="categoryTag" style={style.categDiv} >

                        <p id={!category.category ? category.category_tag.replace(" ", '').toLowerCase() : 'all'} style={style.categP} >&nbsp;</p>
                        <h2 className="mb-3 mt-3 categTitle_ d-inline-block"> {!category.category ? category.category_name.toUpperCase() : ''}
                        </h2>
                        &nbsp;
                        <h5 className="mb-3 mt-3 categTitle_ d-inline-block" style={style.timeTitle}>
                            {category.category_tag?.replace(" ", '').toLowerCase().includes("flash") &&
                                <>
                                    <span style={style.timeBorder}>&nbsp;{("0" + hours).slice(-2)} : {("0" + minutes).slice(-2)} : {("0" + seconds).slice(-2)}&nbsp;</span>
                                </>
                            }
                        </h5>
                    </div> : ''
                }

                {isMobile === "true" ?
                    <div className={`${!category.category ? (category.category_tag.toLowerCase() !== "sale" && category.category_tag.toLowerCase() !== "flash") ? "row" : "" : ""}`}
                        id={`${!category.category ? (category.category_tag.toLowerCase() === "sale" || category.category_tag.toLowerCase() === "flash" || category.category_tag.toLowerCase() === "recommended") ? "slideProd" : "" : ""}`}>

                        {showVisible}
                        {renderedItems}

                    </div>
                    :
                    <div className={`${!category.category ? (category.category_tag.toLowerCase() !== "sale" && category.category_tag.toLowerCase() !== "flash") ? "row" : "row" : ""}`} id={`${!category.category ? category.category_tag.toLowerCase() === "sale" || category.category_tag.toLowerCase() === "flash" || category.category_tag.toLowerCase() === "recommended" ? "slideProd" : "" : "productzgnRow"}`}>
                        {renderedItems}
                    </div>
                }
            </div>
        }
        else {
            if (!category.category && selectedBranch !== 0) {
                let item = document.getElementById(`li_${category.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                if (item)
                    item.style.display = 'none'
                item = document.getElementById(`form-${category.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                if (item)
                    item.classList.add('categ-d-none')
                // ${sku_tags?.categoryTag === category.category_tag ? "d-block":"d-none"} 
            }
            if ((window.location.pathname).includes("productPage") && selectedBranch !== 0)

                return <div className={`row ${results === "No Remaining Products" ? 'd-none' : ''} 
                ${(sku_tags?.categoryTag !== undefined || sku_tags?.categoryTag !== "" ? sku_tags?.categoryTag : "") === category.category_tag ? "" : "d-none"}`} id={!category.category ? 'h_' + category.category_tag.replace(" ", '').replace(" ", '').toLowerCase() : ''} key={`categoryItems${!category.category ? category.category_tag.replace(" ", '').toLowerCase() : 'all'}`}>
                    {search !== "onCart" ?
                        <div className="categoryTag" style={style.categDiv} >
                            <p id={!category.category ? category.category_name.replace(" ", '').toLowerCase() : 'all'} style={style.categP} >&nbsp;</p>
                            <h2 className="mb-3 mt-3"> {!category.category ? category.category_name.toUpperCase() : ''}</h2>
                        </div> : ''
                    }
                    {renderedItems}
                </div>
            if ((window.location.pathname).includes(category.category_name) && selectedBranch !== 0)

                return <div className={`row ${results === "No Remaining Products" ? 'd-none' : ''} 
                ${(sku_tags?.categoryTag !== undefined || sku_tags?.categoryTag !== "" ? sku_tags?.categoryTag : "") === category.category_tag ? "" : "d-none"}`} id={!category.category ? 'h_' + category.category_tag.replace(" ", '').replace(" ", '').toLowerCase() : ''} key={`categoryItems${!category.category ? category.category_tag.replace(" ", '').toLowerCase() : 'all'}`}>
                    {search !== "onCart" ?
                        <div className="categoryTag" style={style.categDiv} >
                            <p id={!category.category ? category.category_name.replace(" ", '').toLowerCase() : 'all'} style={style.categP} >&nbsp;</p>
                            <h2 className="mb-3 mt-3"> {!category.category ? category.category_name.toUpperCase() : ''}</h2>
                        </div> : ''
                    }
                    {category.category_name}
                    {/* {renderedItems} */}
                </div>
            else
                return ''
        }
    })
    // console.log(renderedCateg)
    return (
        <div className={`${search === "onCart" ? '' : 'container product_list'}`} >
            {search === "onCart" ?
                !loading ?
                    results.length > 0 ?
                        renderedCateg
                        :
                        <div className="d-flex justify-content-center mb-5 no_prods">
                            <p className="text-center">There are no more items in your shopping cart. To add to your basket, please click <a href="/" className="btn btn-transparent p-0" style={{ color: '#24b14b' }}>here</a>.</p>
                        </div>
                    :
                    <div className="d-flex justify-content-center mb-5">
                        {/* <div className="spinner-border text-success" role="status">
                            <span className="sr-only"></span>
                        </div> */}
                    </div>
                :
                window.location.pathname === '/search' ?
                    !loading ?
                        items.length > 0 && search !== '' ?
                            renderedCateg
                            :
                            <div className="d-flex justify-content-center mb-5 no_prods">

                            </div>
                        :
                        <div className="d-flex justify-content-center mb-5">
                            {/* <div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div> */}
                        </div>
                    :
                    !loading ?
                        items.length > 0 ?
                            renderedCateg
                            :
                            <div className="d-flex justify-content-center mb-5 no_prods">
                                No Remaining Products
                            </div>
                        :
                        <div className="d-flex justify-content-center mb-5">
                            {/* <div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div> */}
                        </div>
            }
            {/* <button onClick={()=>setModalStatus(true)}>TEST TRIGGER</button> */}
            <ItemModal
                modalStatus={modalStatus}
                itemDetailModal={itemDetailModal}
                setModalStatus={setModalStatus}
                setOnCart={setOnCart}
                setTotal={setTotal}
                cartChange={cartChange}
                setItemDetailModal={setItemDetailModal}
                setCartChange={setCartChange}
            />
            <ConfirmContactModal
                confirmContactModalStatus={confirmContactmodalStatus}
                setConfirmContactModalStatus={setConfirmContactModalStatus}
                itemNotify={itemNotify}
                setItemNotify={setItemNotify}
                setVisibleLogin={setVisibleLogin}
            />
            {/* <LoadingModal
                modalStatus={loading && selectedBranch !== 0 ? true : false}
            /> */}
        </div>
    )
}

export default ItemList;