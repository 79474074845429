/*******************************************************************************************
* File Name                : LinkIcons
* File Directory           : src/components/Shared/LinkIcons
* Module                   : LinkIcons
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/LinkIcons.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   LinkIcons        -   component for used on homepage
 *
 * #How to call:
 *    <LinkIcons/>
 *
 * Note: Still working on the ff:
 * - anchor elements with svg
 * 
 *******************************************************************************************/

import React from "react";
// import About from "../../../assets/site_icons/About.svg";
// import FAQ from "../../../assets/site_icons/FAQ-2.svg";
// import Blog from "../../../assets/site_icons/Blog.svg";

const isMobile = localStorage.getItem("isMobile") === "true"
const style = {
    newFont: {
        fontSize: `${isMobile? "13px" : '16px'}`,
        color: '#222',
        padding: '0 3%',
        cursor: 'pointer'
    }
    // },
    // imgRadius: {
    //     padding: '3%',
    //     borderRadius: '50%',
    //     background: '#f4cc0f',
    //     display: 'inline-block'
    // }


}
const details = [
    {
        count: 1,
        image: <img src="https://res.cloudinary.com/dw5axbz64/image/upload/v1661933509/upload/About_hcglma.png" alt="About Us" height={`${isMobile? "40px" : '80px'}`} width={`${isMobile? "40px" : '80px'}`} />,
        title: "About Us",
        link: "/ContentPage/about",
        description: "Zagana provides delivery of farm-fresh produce and frozen food sourced directly from local farmers at a fair market price, in as fast as 30 minutes."
    },
    {
        count: 2,
        image: <img src="https://res.cloudinary.com/dw5axbz64/image/upload/v1661933508/upload/FAQ_hklggq.png" alt="FAQ" height={`${isMobile? "40px" : '80px'}`} width={`${isMobile? "40px" : '80px'}`} />,
        title: "FAQs",
        link: "/ContentPage/FAQs",
        description: "Get answers on frequently asked questions about order, delivery, and other concerns here."
    },
    {
        count: 3,
        image: <img src="https://res.cloudinary.com/dw5axbz64/image/upload/v1661933509/upload/Blogs_dgerym.png" alt="Blog" height={`${isMobile? "40px" : '80px'}`} width={`${isMobile? "40px" : '80px'}`} />,
        title: "Blogs",
        link: "/ContentPage/blogs",
        description: "More about your favorite online fresh grocery here."
    }
]

function redirectLink(link) {
    window.location.href = link;
}


const LinkIcons = () => {
    return (
        <React.Fragment>

            {/* {isMobile === "false" ? */}
                <div className={`row ${isMobile ? 'p-0 pb-2 m-0' : 'p-1 '} pt-1 m-0 w-100 bg-white justify-content-sm-center others`}>
                    {isMobile ? null : <div className="col-sm-1 p-1 pt-1" >&nbsp;</div> }
                    {details.map(item => {
                        return (
                            <div className="col p-1 pt-1 text-justify my-auto" key={item.count} >
                                <div className="row">
                                    <div className="col" >
                                        <div className="row othersRow" style={{ 'height': `${ isMobile ? "50px" : '80px'}` }}>
                                            <div className="col-6 iconLink m-auto">
                                                <a style={style.imgRadius} onClick={() => redirectLink(item.link)} id={item.title} role="button">
                                                    {item.image}
                                                </a>
                                            </div>
                                            <div className="col-6 my-auto" style={style.newFont} onClick={() => redirectLink(item.link)}>
                                                <strong>{item.title}</strong>
                                                <br />
                                                <span className={`description_Icons ${isMobile ? "d-none" : ""}`}>
                                                    {item.description}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {isMobile ? null : <div className="col-sm-2 p-1 pt-1" >&nbsp;</div> }

                    
                </div>
                {/* :
                <div></div>
            } */}
        </React.Fragment >
    )

};


export default LinkIcons;