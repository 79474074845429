/*******************************************************************************************
* File Name                : Register
* File Directory           : src/components/Login/Register
* Module                   : Login
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Login.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   Register        -   component for Registration for new customers
 *
 * #How to call:
 *    <Register/>
 *
 * Note: Still working on the ff:
 * - When success should be auto login (Login is still ongoing)
 * 
 *******************************************************************************************/
import React from "react";
import { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "../Shared/Header";
import Home from "../APIs/Home";
import ReactGA from 'react-ga4';
import { forgotModal } from "../../actions";
import { Alert } from 'react-bootstrap';

const style = {
    formGroup: {
        marginBottom: '6%',
        position: 'relative'
    },
    errorStyle: {
        position: 'absolute',
        color: 'red'
    },
    formInput: {
        width: '100%'
    },
    backBtn: {
        color: "#6c757d",
        TextDecoration: "none"
    },
    labelWidth: {
        width: "100%"
    }
}

//to show error

const renderError = ({ error, touched }) => {
    if (error && touched) {
        return (
            <div>
                <span className="error" style={style.errorStyle} >{error}</span>
                <span className="invalid " style={style.errorStyle}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
    else if (!error) {
        return (
            <div>
                <span className="valid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </span>
            </div>
        );
    }
}

//to show input via redux

const renderInput = ({ input, type, meta, value_name, class_name, value_check, maxLength, id, pattern, readOnly }) => {

    let className = `field ${meta.error && meta.touched ? 'error' : ''} ${!meta.error ? 'valid' : ''}`;
    let label_radio = value_name, checker = true;
    if (value_name === "Radio") {
        return <label> {value_name}</label>;
    }
    // if(type="checkbox"){
    //     checker=<input {...input} type={type} value={value_name} id={id} pattern={pattern} autoComplete="off" className={class_name} readOnly={readOnly} defaultChecked={checker} maxLength={maxLength} /> {label_radio}</label>
    // }
    return (
        <div className={className} style={style.formInput}>
            {id !== "phone_fieldd" ? <label style={style.labelWidth}>
                {value_check}
                <input {...input} type={type} value={value_name} id={id} pattern={pattern} autoComplete="off" className={class_name} readOnly={readOnly} maxLength={maxLength} /> {label_radio}</label>
                :
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">(+63)</span>
                    </div>
                    <input {...input} type={type} value={value_name} id={id} pattern={pattern} autoComplete="off" className={class_name} checked={value_check} maxLength={maxLength} />

                </div>
            }
            {/* {label_radio} */}
            <div className="form-control-feedback">{renderError(meta)}</div>
        </div>
    );
};

//to validate field values
const validate = values => {
    const errors = {};
    // console.log(errors)
    var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

    if (!values.FirstName) {
        errors.FirstName = 'You must enter your First Name'
    }

    if (!values.LastName) {
        errors.LastName = 'You must enter your Last Name';
    }

    if (!values.Birthdate) {
        errors.Birthdate = 'You must enter a valid date';

    }

    if (!values.Email) {
        errors.Email = 'You must enter your Email';

    }

    if (values.Email) {
        if (emailCheck.test(values.Email) === false) {
            errors.Email = 'Invalid Email Format';
        }
    }

    if (!values.Phone) {
        errors.Phone = 'You must enter your Phone Number';
    }
    if (values.Phone) {
        if (values.Phone.length > 10 || values.Phone.length < 10) {
            errors.Phone = 'Format should be: (+63) 9xxxxxxxxx';
        }
    }
    if (!values.Password) {
        errors.Password = 'You must enter your Password';
    }
    if (!values.CPassword) {
        errors.CPassword = 'You must enter your Password';
    }
    if (values.Password !== values.CPassword) {
        errors.CPassword = 'Password do not match';
    }

    if (!values.RadioGrp) {
        errors.RadioGrp = 'Password do not match';
    }
    return errors;
};


const Register = ({ valid, register,
    confirmContactmodalStatus,
    setConfirmContactModalStatus,
    userLoginNotify, setUserLoginNotify,
    itemNotify, setItemNotify }) => {
    document.title = "Zagana | Register";
    const history = useHistory()
    const isMobile = localStorage.getItem("isMobile") === "true";
    let body = document.body; // for setting body height (modal)
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';

    if (getUser != "") {
        history.push("/");
    }
    const [checked, setChecked] = useState(true);
    const [emailTaken, setEmailTaken] = useState("");

    const dispatch = useDispatch()

    const handleChange = () => {
        setChecked(current => !current);
    };

    const terms = (
        <React.Fragment>
            <span> Be the first to know about exclusive deals and discounts! Tick the box to subscribe to our email newsletter.</span>
            <br />
            <span>By continuing, you agree to Zagana's Terms of Service, Privacy Policy" when a customer register through their website</span>
        </React.Fragment>
    )


    const onClickForgot = () => {
        if (isMobile === "true") {
            history.push("/forgot-password")
        } else {
            dispatch(forgotModal(true))
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        body.classList.remove("body_class_");
        body.classList.remove("body_class");
        const values = register.values
        const details = {
            FirstName: values.FirstName,
            LastName: values.LastName,
            Email: values.Email,
            Password: values.Password,
            Birthdate: values.Birthdate,
            Phone: values.Phone,
            RadioGrp: values.RadioGrp,
            CheckBox: checked
        }
        // console.log(details);
        Home.post(`/authenticate/sign_up`, details)
            .then(response => {
                if (response.data.message === 'Registration Success') {
                    ReactGA.event({
                        category: "Register",
                        action: 'Register User',
                    });
                    setEmailTaken("");
                    const loginDetails = JSON.stringify(response.data.loginDetails[0])
                    let login = response.data.loginDetails[0]
                    sessionStorage.setItem('Login', loginDetails)
                    sessionStorage.setItem('FBLogin', false)
                    sessionStorage.setItem('LBLogin', false)
                    ReactPixel.track('CompleteRegistration', {
                        content_name: 'Registration Page'
                    });
                    // console.log(localStorage.getItem("notifyItem"))
                    // if(localStorage.getItem("notifyItem")!==null){
                    //     if(!login.preferredNotification){
                    //         setUserLoginNotify(login)
                    //         setItemNotify(JSON.parse(localStorage.getItem("notifyItem")))
                    //         setConfirmContactModalStatus(true)
                    //     }else{
                    //         let item=JSON.parse(localStorage.getItem("notifyItem"))
                    //         Home.post('/notifyItem/add', {
                    //         userId: login.customer_id,
                    //         item_sku: item.item_sku,
                    //         item_branch: item.item_branch,
                    //         customerEmail: login.customer_email,
                    //         phoneNumber:login.customer_phone,
                    //         mode:null
                    //         })
                    //         setItemNotify({
                    //         item_id:item.item_id,
                    //         item_sku:item.item_sku,
                    //         item_name:item.item_name,
                    //         notify_id:"success"
                    //         })
                    //         history.push("/");
                    //         window.location.href = "/";
                    //     }
                    // } else {
                    history.push("/");
                    window.location.href = "/";
                    // }
                    // history.push('/')
                    //  history.push('/verifyOTP')
                }
            })
            .catch(error => {
                setEmailTaken(error.response?.data.error + " : " + values.Email)
            })
    }

    return (
        <React.Fragment>
            {isMobile ?
                <Header title="Sign up" />
                :
                <div></div>
            }
            <div className="container">

                <form onSubmit={handleSubmit} className="p-2" id="regForm">
                    <div className="form-group row" style={style.formGroup} id="FirstName_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">First Name</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="text"
                                name="FirstName"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="LastName_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Last Name</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="text"
                                name="LastName"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="Birthdate_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Birthdate</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="date"
                                name="Birthdate"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="Email_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Email</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="email"
                                name="Email"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="Phone_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Phone</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="number"
                                name="Phone"
                                maxLength="15"
                                component={renderInput}
                                class_name="form-control"
                                id="phone_fieldd"
                                pattern="[0-9]"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="Password_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Password</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="Password"
                                name="Password"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="CPassword_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Confirm Password</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="Password"
                                name="CPassword"
                                component={renderInput}
                                class_name="form-control"
                            />
                        </div>
                    </div>
                    <div className="form-group row" style={style.formGroup} id="Gender_field">
                        <label htmlFor="staticEmail" className="col-xs-12 col-sm-5 col-lg-4 col-form-label">Gender</label>
                        <div className="col-xs-12 col-sm-7 col-lg-8">
                            <Field
                                type="Radio"
                                name="RadioGrp"
                                component={renderInput}
                                value_name="Male"

                            />
                            <Field
                                type="Radio"
                                name="RadioGrp"
                                component={renderInput}
                                value_name="Female"
                            />
                            <Field
                                type="Radio"
                                name="RadioGrp"
                                component={renderInput}
                                value_name="Rather Not to say"
                            />
                        </div>
                    </div>

                    <div className="form-group row" style={style.formGroup} id="Newsletter_Field">

                        <small style={{ "fontSize": "13px" }}><input type="checkbox" readOnly="true" defaultChecked={checked} onChange={handleChange} /> Be the first to know about exclusive deals and discounts! Tick the box to subscribe to our email newsletter.</small>

                    </div>

                    <div className="form-group row" style={style.formGroup} id="Newsletter_Field">


                        <small style={{ "fontSize": "13px" }}><input type="checkbox" readOnly="true" checked /> By continuing, you agree to Zagana's <a href='/ContentPage/terms'>Terms of Service</a>, <a href='ContentPage/privacy-policy'>Privacy Policy</a> when a customer register through their website</small>
                    </div>


                    <div className="row">

                        {isMobile === "true" ?
                            <div className="col">
                                <a href="/login" style={style.backBtn}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fill="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>
                                    Login
                                </a>
                            </div>
                            : ""}

                        {emailTaken === "" ? "" :
                            <div role="alert" className='alert alert-danger'> {emailTaken}
                                <br />
                                If you don't know your password click here to: <a onClick={onClickForgot} className="p-0 btn btn-link">Forgot Password</a>
                            </div>
                        }
                        <div className="col d-flex align-items-center justify-content-center">
                            <button type="submit" className={`btn  ${!valid ? 'disabled' : 'btn-primary'} `} disabled={!valid} id="btnRegister">Submit</button>

                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return ({
        register: state.form.Register
    })
};
export default connect(mapStateToProps)
    (reduxForm({
        form: 'Register',
        validate,
    })(Register));
