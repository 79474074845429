import React from "react";
import Error from "./../../assets/logo/error-404.png"
import { Link } from "react-router-dom";
import Header from "./Header"
import ZaganaLogo from './ZaganaLogo';
const style = {
    img: {
        width: '18rem',
        height: '15rem',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    row: {
        height: '95vh',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    links: {
        textDecoration: 'none'
    },
    headerBG: {
        'backgroundColor': '#facc02'
    }
}

const Page404 = () => {
    const isMobile = localStorage.getItem("isMobile") === "true"

    return (
        <React.Fragment>
            {isMobile ?
                <Header title="About us" />
                : <div className="row justify-content-md-center m-0 pc_nav" style={style.headerBG} >
                    <div className="row ">

                        <div className="col-1 d-none d-sm-block text-center">
                            <a href="/">
                                <ZaganaLogo />
                            </a>
                        </div>
                    </div>
                </div>
            }
            <div className="container" style={style.row}>
                <div className="row text-center justify-content-center">
                    <img className="img img-fluid" style={style.img} alt="error-404" src={Error} />
                    <strong>PAGE NOT FOUND</strong>
                    <Link to="/" style={style.links}>Home</Link>
                </div>
            </div>
        </React.Fragment>
    )


}


export default Page404;