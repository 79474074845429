const INTIAL_STATE = {
    url: '',
    customer_id  : '',
    reference_id   : ''
};

const XenditData = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'XenditData':
            return {
                ...state, 
                url : action.url,
                customer_id  : action.customer_id,
                reference_id  : action.reference_id
            };
        default:
            return state;
    }

};

export default XenditData;
