/*******************************************************************************************
* File Name                : productPage
* File Directory           : src/components/Product/productPage
* Module                   : productPage
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/productPage.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   productPage        -   component for product per page
 *
 * #How to call:
 *    <productPage/>
 *
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useTimer } from 'react-timer-hook';
import ReactPixel from 'react-facebook-pixel';


import CartButtons from "./CartButtons";
import NotifyMeButtons from "./NotifyMeButtons";
import ItemListv2 from "./ItemListv2";
import ProductCarousel from "./ProductCarousel";

import Cart from '../Home/Cart';

import DesktopHeader from '../Shared/DesktopHeader';
import ItemList from "../Shared/ItemList";
import Header from "../Shared/Header";
import LoginModal from '../Shared/LoginModal';
import SearchFloat from '../Shared/SearchFloat'
import SnackBar from "../Shared/SnackBar";
import LinkIcons from '../Shared/Links/LinkIcons';

import cartIcon from '../../assets/site_icons/shoppingCart.svg';
import copyIcon from "../../assets/site_icons/copyLink.svg";

import { v4 as uuid } from 'uuid'; // modeule for generating id
import { v4 as uuidv4 } from 'uuid';

import Home_api from '../APIs/Home';
import { storeFrontLogs } from "../APIs/Logs";
import { SaveToSession } from "../Shared/SaveToSession";
import { SaveToDatabase } from "../Shared/SaveToDatabase";

const style = {
    btnClear: {
        position: 'absolute',
        top: '8px',
        right: '50px'
    },
    minHeight: {
        height: "400px",
        padding: "0px"
    },
    prodTitle: {
        fontSize: "24px"
    },
    prodPrice: {
        fontSize: "30px",
        color: "#000"
    },
    incrementButtonColor: {
        border: "none",
        borderRadius: "25px",
        background: "#24b14b",
        color: "#24b14b",
        fontWeight: "bold",
        fontSize: "14px",
        padding: "0",
        width: "20px",
        height: "20px",
        float: "revert"
    },
    incrementAddButtonColor: {
        border: "none",
        borderRadius: "25px",
        background: "#24b14b",
        color: "white",
        fontWeight: "bold",
        fontSize: "14px",
        padding: "0",
        width: "100px",
        height: "40px",
        float: "revert"
    },
    incrementCheckoutButtonColor: {
        border: "none",
        borderRadius: "25px",
        color: "#000",
        background: "#facc02",
        fontWeight: "bold",
        fontSize: "14px",
        padding: "0",
        width: "100px",
        height: "40px",
        float: "revert"
    },
    textField: {
        textAlign: "center",
        background: "transparent",
        margin: "3px",
        display: 'inline',
        padding: '0',
        width: '10%'
    },
    decrementButtonColor: {
        border: "none",
        borderRadius: "25px",
        background: "#24b14b",
        color: "#24b14b",
        fontWeight: "bold",
        fontSize: "14px",
        padding: "0",
        width: "20px",
        height: "20px",
        float: "revert"
    },
    anchorText: {
        textDecoration: 'none'
    },
    cartCardMob: {
        background: 'transparent',
        border: '0px solid',
        position: 'fixed',
        bottom: '5%',
        paddingTop: "0px",
        width: '92%',
        color: '#222',
        borderRadius: '8px 8px',
        zIndex: 2
    },
    fontSize12: {
        fontSize: "12px",
        padding: "0 3px"
    }
}

let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const ProductPagev1 = ({ CartRedux }) => {
    const param = useParams();
    const sku = useParams();


    let source = sessionStorage.getItem('source')||''
    let date = localStorage.getItem('date')||''
    const history = useHistory()
    const dispatch = useDispatch()

    const isMobile = localStorage.getItem("isMobile") === "true";

    const [totalCount, setTotalCount] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [cartVChange, setCartVChange] = useState(0);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [inCartQty, setinCartQty] = useState(0);
    const [selectedBranch, setSelectedBranch] = useState(3);

    const [visibleLinks, setVisibleLinks] = useState(false)
    const [MinusToCartVisible, setMinusToCartVisible] = useState(inCartQty > 0 ? true : false);
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [productStatus, setProductStatus] = useState(true); //
    const [firstLoad, setFirstLoad] = useState(true);
    const [visibleCart, setVisibleCart] = useState(true); // show modal cart

    const [product, setProduct] = useState([]); //
    const [items, setItems] = useState([]);
    const [cart, setCart] = useState([]);// cart for desktop
    const [cartProduct, setCartProduct] = useState([]); // show modal cart

    const [selectedCity, setSelectedCity] = useState({});// cart for desktop
    const [cartVDetails, setCartVDetails] = useState(defaultItem);

    const [productName, setProductName] = useState(); //
    const [productImg, setProductImg] = useState(); // 
    const [productPrice, setProductPrice] = useState(); // 
    const [salePrice, setSalePrice] = useState(); // 

    const [viewing, setViewing] = useState('grid')
    const [term, setTerm] = useState('');// search for desktop
    const [branch, setBranch] = useState(null);

    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload
    let cartModalShow = `modal cart ${visibleCart === true ? 'showModal' : ''}`; // class for cart show modal

    let user;
    let userLogin;

    let accessKey = localStorage.getItem('accessKey')
    let citySelected = localStorage.getItem('SelectedCity')

    let body = document.body; // for setting body height (modal)
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal    
    document.title = "Zagana | " + productName;

    let getSelected = branch !== null ? branch : 'Please Select City'
    if (getSelected.branch_id === undefined) {
        getSelected = 'Please Select City'
    }

    const time = new Date()
    let expiryTimestamp = time.setSeconds(time.getSeconds())
    let autoStart = false
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp, autoStart, onExpire: () =>
            (window.location.origin === "https://zagana.com" || window.location.origin === "https://www.zagana.com") && window.location.reload()
    });

    const isLogged = sessionStorage.getItem("Login") || '';

    const openLoginModal = () => {
        if (isLogged === '') {
            setVisibleLogin("false");
            let element = document.getElementById("modalLoginID");
            element.classList.remove("showModal");
            body.classList.add("body_class");
        }
    }

    const triggerCart = () => {
        dispatch({
            type: "SET_LOCATION",
            location: window.location.pathname
        })
        document.getElementById("cartModalMobile").click();
    }

    useEffect(() => {
        if (!isFirstRender) {
            try {
                Home_api.post('/accessStorage/changeCart', {
                    itemOnCart: CartRedux,
                    accessToken: accessKey
                })
            } catch (err) {
                console.log(err.message)
            }
        }
        else
            setIsFirstRender(false)
        //computing total cart price and qty
        let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
        let itemCount = CartRedux.length
        totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
        setTotal({ itemTotal: totalPrice, itemCount: itemCount })
        setTotalCount(itemCount)
        setTotalPrice(totalPrice)
        sessionStorage.setItem('total', JSON.stringify({ itemTotal: totalPrice, itemCount: itemCount }))
    }, [CartRedux])

    useEffect(() => {
        const category = async () => {

            const response = await Home_api.get('/categoryList')
            // const item
            const itemResult = response.data.category_list || []
            setItems(itemResult)
        }
        if (branch !== null)
            category()
    }, [branch])

    useEffect(() => {
        // console.log(cartVChange)
        // console.log(cartVDetails)
        // console.log(cart)
        if (firstLoad == false) {
            if (cartVDetails.item_sku === sku.sku) {
                let checker = parseInt(cartVChange)
                if (parseInt(cartVChange) < 0)
                    checker = 1
                //maximum of 50 Item Qty
                if ((product.category).toLowerCase().includes("flash")) {
                    if (parseInt(checker) > 2)
                        checker = 2
                }
                else if (parseInt(checker) > 50)
                    checker = 50
                setinCartQty(parseInt(checker))
                var cartIndex = cart.findIndex(
                    (cartItem) => cartVDetails.item_sku === cartItem.item_sku
                );
                // console.log(cartIndex)
                if (cartIndex >= 0) {
                    // console.log("EXISTING")
                    cart[cartIndex].cart_qty = checker;
                    if (checker <= 0) {

                        setMinusToCartVisible(false)
                        cart.splice(cartIndex, 1);
                    }
                }
                else {
                    // console.log("NEW")
                    cart.push({
                        item_id: product.item_id,
                        cart_qty: parseInt(checker),
                        cart_price: product.item_salePrice,
                        item_price: product.item_price,
                        item_sku: product.item_sku,
                        item_var: product.item_variant,
                        item_name: product.item_name,
                        item_image: productImg
                    });
                    setMinusToCartVisible(true)
                }
                fetchItems(cart)
                TotalComputation(cart)
            }
        } else {
            setFirstLoad(false)
        }
    }, [cartVChange, cartVDetails])

    useEffect(() => {
        SaveToSession({
            page: 'Product Page',
            path: window.location.search
        })
        //dbStorage
        // function uuidv4() {
        //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //         return v.toString(16);
        //     });
        // }
        let link = uuidv4();
        const accessStorage = async (status) => {
            await Home_api.post('/accessStorage', { accessToken: link })
            localStorage.setItem('accessKey', link)
            const city_details = {
                city_id: getSelected.city_id || 11,
                city_name: getSelected.city_name || 'Quezon City (QC)',
                branch_id: getSelected.branch_id || 1,
                branch_opening: '08:00',
                branch_closing: '17:00'
            }
            localStorage.setItem('SelectedCity', JSON.stringify(city_details))
            let accessToken = localStorage.getItem('accessKey')
            let result = Home_api.post(`/accessStorage/changeCity`, {
                accessToken: accessToken,
                city_details: city_details
            })
            if (status === 'tokenNotFound') {
                window.location.reload()
            }
        }
        if (!accessKey) {

            if (localStorage.getItem('branchSelected') === true) {
                accessStorage('tokenNotFound')
            } else {
                setBranch([{ city_id: getSelected.city_id || 11, branch_id: getSelected.branch_id || 1, city_name: getSelected.city_name || 'Quezon City (QC)', branch_closing: '17:00', branch_opening: '08:00' }])
                accessStorage()
            }
        } else {
            const getCart = async () => {
                let response = await Home_api.post('/cartChecker', { accessToken: accessKey })
                if (response.data.result === "AccessTokenNotFound") {
                    // console.log("IF1")
                    localStorage.setItem('branchSelected', false)
                    accessStorage('tokenNotFound')
                }
                else {
                    // console.log("ELSE1")
                    setCartProduct(response.data.cart || [])
                    // console.log(response.data.cart)
                    setCart(response.data.cart || [])
                    setOnCartLoaded(true)
                    if (response.data.selectedCity === null || response.data.selectedCity === []) {
                        // console.log("IF2")
                        localStorage.setItem('branchSelected', false)
                        setSelectedBranch(3)
                        setBranch([{ city_id: getSelected.city_id || 11, branch_id: getSelected.branch_id || 1, city_name: getSelected.city_name || 'Quezon City (QC)', branch_closing: '17:00', branch_opening: '08:00' }])
                        // window.location.reload()
                    } else {
                        // console.log("ELSE2")
                        // console.log(response.data.selectedCity)
                        setSelectedBranch(response.data.selectedCity.branch_id)
                        localStorage.setItem('branchSelected', true)
                        setBranch(response.data.selectedCity || [])
                        const city_details = {
                            city_id: response.data.selectedCity.city_id || 11,
                            city_name: response.data.selectedCity.city_name || 'Quezon City (QC)',
                            branch_id: response.data.selectedCity.branch_id || 1,
                            branch_opening: response.data.selectedCity.branch_opening || '08:00',
                            branch_closing: response.data.selectedCity.branch_closing || '17:00'
                        }
                        localStorage.setItem('SelectedCity', JSON.stringify(city_details))
                        let accessToken = localStorage.getItem('accessKey')
                        let result = Home_api.post(`/accessStorage/changeCity`, {
                            accessToken: accessToken,
                            city_details: {
                                city_id: response.data.selectedCity.city_id || 11,
                                city_name: response.data.selectedCity.city_name || 'Quezon City (QC)',
                                branch_id: response.data.selectedCity.branch_id || 1,
                                branch_opening: response.data.selectedCity.branch_opening || '08:00',
                                branch_closing: response.data.selectedCity.branch_closing || '17:00'
                            }
                        })
                    }
                }
            }
            getCart()
        }


    }, [])

    useEffect(() => {
        // console.log(cart)
        var cartIndex = cart.findIndex(
            (cartItem) => sku.sku === cartItem.item_sku
        );
        if (cartIndex >= 0) {
            setinCartQty(cart[cartIndex].cart_qty)
            setMinusToCartVisible(true)
        }
        TotalComputation(cart)
    }, [onCartLoaded])

    useEffect(() => {
        const productSelected = async () => {
            const response = await Home_api.post('/itemList/details/', { sku: sku.sku, branch_id: selectedBranch })
            const prodResult = response.data.data[0] || []
            if (prodResult !== "N") {
                if (prodResult.sub_listing === '') {
                    prodResult.sub_listing = []
                } else {
                    prodResult.sub_listing = prodResult.sub_listing.split(", ")
                }
                prodResult.category_list = []
                // console.log(prodResult.category)
                setProductStatus(prodResult.item_status === "disabled" ? false : true)
                if (prodResult.item_images.length > 0) {
                    setProductImg(prodResult.item_images[0].image_url || "")
                }
                if (prodResult.item_price < prodResult.item_salePrice) {
                    setProductPrice((prodResult.item_price).toFixed(2))
                    setSalePrice("")
                }
                else {
                    setProductPrice((prodResult.item_price).toFixed(2))
                    setSalePrice((parseFloat(prodResult.item_salePrice)).toFixed(2))

                }
                setProductName(prodResult.item_name || " ")
                prodResult.category_list.push({
                    category_tag: (prodResult.category || "fruits").split(/,(.+)/)[0],
                    category_name: (prodResult.category || "fruits").split(/,(.+)/)[0]
                })
                // console.log(prodResult.category_list)
                restart((new Date(prodResult.flash_end_date)).setSeconds((new Date(prodResult.flash_end_date)).getSeconds()))
            }

            // console.log((prodResult))
            setProduct(prodResult)
        }

        productSelected()
    }, [selectedBranch])

    const btnAddOnClick = (movement) => {
        //newFunction
        if (movement === "checkout") {
            addToCart()
            if (!isLogged)
                openLoginModal()
            history.push(isLogged ? "/checkout-details" : '#checkout')
        } else {
            addToCart()
        }

    }

    const btnCopyLink = () => {
        //copy current link
        // alert("Copied")

        let element = document.getElementById("alertCopied");
        element.classList.add("d-block");
        element.classList.remove("d-none");

        setTimeout(() => {
            element.classList.add("d-none");
            element.classList.remove("d-block");
        }, 1000);
        const urlLoc = window.location.href;
        navigator.clipboard.writeText(urlLoc);
    }

    const addToCart = () => {
        let fIndex = CartRedux.findIndex((item) => item.item_id === product.item_id)
        if (fIndex >= 0) {
            dispatch({
                type: "ADD_QUANTITY",
                id: product.item_id,
                flash: product.category.includes('flash') ? true : false
            });
        } else {
            let insertCart = {
                item_id: product.item_id,
                cart_qty: 1,
                cart_price: parseFloat(product.item_salePrice),
                item_price: parseFloat(product.item_price),
                item_branch: product.item_branch,
                item_status: product.item_status,
                item_sku: product.item_sku,
                item_var: product.item_variant,
                item_name: product.item_name,
                item_image: productImg,
                category: product.category
            }
            dispatch({
                type: "ADD_ITEM",
                newItem: insertCart,
            });
        }
    }

    const btnMinusOnClick = () => {
        //Get item index on cart
        var cartIndex = cart.findIndex(
            (cartItem) => sku.sku === cartItem.item_sku
        );
        //Remove to Array if 0 else just deduct
        if (cartIndex >= 0 && cart[cartIndex].cart_qty - 1 <= 0) {
            cart[cartIndex].cart_qty--;
            // if (search === "onCart")
            setMinusToCartVisible(false)
            cart.splice(cartIndex, 1);
        } else if (cartIndex >= 0)
            cart[cartIndex].cart_qty--;
        //Just to make sure not negative :)
        if (inCartQty - 1 <= 0) {
            setinCartQty(0);
        }
        if (inCartQty - 1 <= 0) {
            setinCartQty(0);
        } else
            setinCartQty(inCartQty - 1);
        fetchItems(cart)
        TotalComputation(cart)
    }

    const inputQtyChange = (action, count, e) => {

        //Get item index on cart
        var cartIndex = cart.findIndex(
            (cartItem) => sku.sku === cartItem.item_sku
        );
        //check action type
        if (action === "blur") {
            //on blur remove if 0 else just update qty
            if (count === "" || count <= 0 || count === "0") {
                setMinusToCartVisible(false)
                setinCartQty(0);
                count = 0
                if (cartIndex >= 0) cart[cartIndex].cart_qty = count;
                if (cartIndex >= 0 && (count === "" || count === 0 || count === "0"))
                    // if (search === "onCart")
                    cart.splice(cartIndex, 1);
                let itemAdded = JSON.stringify(cart);

                // localStorage.setItem("cart", itemAdded);
            }
            else {
                //set max to 50
                if ((product.category).toLowerCase().includes("flash")) {
                    if (count > 2)
                        count = 2
                }
                else if (count > 50)
                    count = 50
                count = parseInt(count)
            }
        } else {
            //set max to 50
            if ((product.category).toLowerCase().includes("flash")) {
                if (parseInt(count) > 2)
                    count = 2
            }
            else if (parseInt(count) > 50)
                count = 50
            if (cartIndex >= 0)
                cart[cartIndex].cart_qty = count; //set item qty
            setinCartQty(count);

            // localStorage.setItem("cart", itemAdded);
        }
        fetchItems(cart)
        TotalComputation(cart)
    }

    const TotalComputation = (cart) => {
        let itemTotal = 0, itemCount = 0
        cart.forEach(items => {
            let cartQty = items.cart_qty
            //check if there are old errors (string on cartQty)
            if (cartQty === '' || cartQty === '0')
                cartQty = 0
            //add to total and cart count
            if (items.item_status === "enabled") {
                itemTotal += cartQty * parseFloat(items.cart_price)
                itemCount += parseInt(cartQty)
            }
        });
        //set to sessionStorage
        setTotal({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) })
        sessionStorage.setItem("total", JSON.stringify({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) }));
    }

    const fetchItems = async (items) => {
        // console.log(accessKey)
        // console.log(items)
        await Home_api.post('/accessStorage/changeCart', {
            itemOnCart: items,
            accessToken: accessKey
        })
    }

    const historyBack = () => {
        window.history.back();
    }

    const onBlurSearch = (e) => {
        const payload = {
            user: user,
            changes: `Zagana || HomePage`,
            type: `seacrh_${e.target.value}`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }

    const clearSearch = () => {
        setTerm('')
    }
    const onClickTags = (e) => {
        const tags = e.target.id
        history.push(`/sub-list/${tags}`)
    }

    return (
        <div id="productPage" className="p-0">

            <Helmet>

                <meta property="og:image" content={productImg} />
                <meta property="fb:image" content={productImg} />
                <script>
                    { }</script>
            </Helmet>
            <div className="w-100 m-0 p-0 bg-light wholeHeader" style={{ 'position': "fixed", 'zIndex': '2' }}>
                {param?.categoryTag === undefined ?
                    <Header
                        branch={branch}
                        setBranch={setBranch}
                        term={term}
                        total={total}
                        setTerm={setTerm}
                        visibleLogin={visibleLogin}
                        setVisibleLogin={setVisibleLogin}
                        visibleCart={visibleCart}
                        setVisibleCart={setVisibleCart}
                        body={body}
                    />
                    :
                    <div className="container p-1 bgColorYellow">
                        <div className="row p-1">
                            <div className="col-2 text-center my-auto">
                                <span id="arrowBack" onClick={historyBack} style={style.backBtn}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                    </svg>
                                </span>
                            </div>
                            <div className="col-10 p-0">
                                <span className="display-6">
                                    {param.categoryTag}
                                </span>
                            </div>

                        </div>
                    </div>
                }

                {isMobile === 'true' || isMobile ?
                    <div className="input-group p-4 pt-1 pb-1 searchForm">
                        <div className="input-group-prepend ">
                            <span className="input-group-text" id="basic-addon1" style={{ borderRadius: "50px 0px 0px 50px", padding: '0.29rem 0.85rem' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                            </span>
                        </div>
                        <input type="text" className="form-control" placeholder="Search product by name" style={{ borderRadius: "0px 50px 50px 0px", height: '30px', fontSize: "13px" }} value={term} onChange={(e) => {
                            setTerm(e.target.value);
                            if (source === 'FB') {
                                SaveToDatabase({
                                    action: {
                                        type: 'Search',
                                        value: e.target.value
                                    },
                                    sku: sku.sku
                                })
                                ReactPixel.track('Search', { search_string: e.target.value })
                            }else if (source === 'Tiktok') {
                                SaveToDatabase({
                                    action: {
                                        type: 'Search',
                                        value: e.target.value
                                    },
                                    sku: sku.sku
                                })
                                window.ttq.track('Search', { search_string: e.target.value })
                            }
                        }}
                            onBlur={onBlurSearch}
                        />
                        {term !== '' ? <button className="btn btn-primary-outline p-0" id="btn_clear" style={style.btnClear} onClick={clearSearch}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                            </svg>
                        </button> : ''}
                        <div className="menuBtn"><img onClick={(e) => { setVisibleLinks(!visibleLinks) }} src="https://res.cloudinary.com/dw5axbz64/image/upload/v1663832692/icons/menu_lvwn2i.png" alt="menu" /> </div>
                    </div> : ''
                }
                <div>
                    {isMobile ? <div className={visibleLinks ? '' : 'd-none'}><LinkIcons /></div> : null}
                </div>
            </div>

            {
                term === "" ?
                    <div>
                        {
                            product !== "N" ?
                                <div className={`container row m-0 p-2 mt-5 pt-5`}>
                                    <div className={`card mt-3`} style={{ "borderRadius": "0.5rem" }}>
                                        <div className="row productSelected">
                                            {(((product?.item_price - product?.item_salePrice) / product?.item_price) * 100).toFixed(2) <= 0 ? null :
                                                <div className="ribbon-wrapper-modal">
                                                    <div className="ribbon">
                                                        {Math.round(((product?.item_price - product?.item_salePrice) / product?.item_price) * 100)}%
                                                    </div>
                                                </div>
                                            }
                                            {((product.category || "all")).includes("new") ?
                                                <div className="ribbon-wrapper-modal" >
                                                    {
                                                        window.location.pathname === '/checkout-details' ?
                                                            <div className="ribbon ribbonNew">
                                                                &nbsp;&nbsp;&nbsp;new
                                                            </div>
                                                            :
                                                            <div className="ribbon ribbonNew">
                                                                &nbsp;&nbsp;&nbsp;new
                                                            </div>

                                                    }
                                                </div>
                                                : null
                                            }
                                            {((product.category || "all")).includes("seasonal") ?
                                                <div className="ribbon-wrapper-modal" >
                                                    {
                                                        window.location.pathname === '/checkout-details' ?
                                                            <div className="ribbon ribbonSeasonal">
                                                                &nbsp;&nbsp;Seasonal
                                                            </div>
                                                            :
                                                            <div className="ribbon ribbonSeasonal">
                                                                &nbsp;&nbsp;Seasonal
                                                            </div>

                                                    }
                                                </div>
                                                : null
                                            }
                                            <div className="col-sm-12 col-md-5">
                                                <ProductCarousel images={product.item_images} />
                                            </div>
                                            <div className="col-sm-12 col-md-6 ">
                                                <div className="mt-5 p-3 mb-3">
                                                    <h1 style={style.prodTitle}>{product.item_name}</h1>
                                                    {
                                                        product?.sub_listing?.map((tags, index) => (
                                                            <button
                                                                key={index}
                                                                className="btn btn-light p-1 m-1"
                                                                id={tags}
                                                                style={style.fontSize12}
                                                                onClick={onClickTags}>
                                                                {tags}
                                                            </button>
                                                        ))
                                                    }
                                                    <>
                                                        {(product.category)?.toLowerCase().includes("flash") &&
                                                            <>
                                                                <div className="alert alert-secondary p-2" style={{ "background": "#dc3545", "color": "#fff" }} >
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <span className="text-light">Flash Sale! </span>
                                                                        </div>
                                                                        <div className="col-6 my-auto flashTimer">
                                                                            <span className="float-end" style={style.timeBorder}>Ends in: &nbsp;{("0" + hours).slice(-2)} : {("0" + minutes).slice(-2)} : {("0" + seconds).slice(-2)}&nbsp;</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                    <div id="price">
                                                        {parseFloat(productPrice) > parseFloat(salePrice) ?
                                                            <span style={style.prodPrice}>
                                                                <span style={{ "textDecoration": "line-through", "color": "#000" }}>
                                                                    &#8369; {productPrice}
                                                                </span>
                                                                <span style={{ "color": "red" }}>
                                                                    &nbsp; &#8369; {salePrice}
                                                                </span>
                                                            </span>
                                                            :
                                                            <span style={style.prodPrice}>
                                                                &#8369; {productPrice}

                                                            </span>
                                                        }
                                                    </div>
                                                    {
                                                        product.item_status === "enabled" ?
                                                            <CartButtons item={product} />
                                                            :
                                                            null
                                                    }
                                                    <div className={`actions ${productStatus ? 'd-none' : ''}`}>
                                                        <p className="text-danger">Sold out</p>
                                                    </div>

                                                    <br />
                                                    {!isMobile ?
                                                        <button
                                                            id={`btnAddCart${product.item_id}`}
                                                            className="btn"
                                                            style={style.incrementCheckoutButtonColor}
                                                            onClick={(e) => btnAddOnClick("checkout")}
                                                        >
                                                            CHECKOUT
                                                        </button >
                                                        :
                                                        null
                                                    }
                                                    <br />
                                                    <hr />
                                                    <span className="mb-2 text-secondary" style={{ "whiteSpace": "pre-wrap", "width": "100%" }}>{product.item_description}</span>
                                                    <hr />
                                                    {/* <span style={{ "textTransform": "uppercase", "display": "block" }}>
                                                 Category: {product.category}
                                             </span> */}


                                                    <div className="shareable">
                                                        <div class="fb-share-button" data-href={window.location.href} data-layout="button" data-size="large">
                                                            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fapi.zagana.com%2Fadmin8888zgn%2Fmaintenance%2Fdiscount&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a>
                                                        </div>
                                                        <div class="CopyLink">
                                                            <button type="button" class="copylinkbtn btn btn-light pt-1 pb-0" style={{ "background": "#facc02", }}
                                                                onClick={() => btnCopyLink()}>
                                                                <span style={{ "marginBottom": "2px" }}>
                                                                    <img src={copyIcon} />
                                                                    copy</span></button>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 p-2 productRelated">
                                        <SnackBar alertMsg="Link copied to clipboard" status="bg-success" />

                                        <h2>Sale</h2>

                                        <div id="saleProds">
                                            <ItemListv2
                                                options={[{
                                                    category_tag: "sale",
                                                    category_name: "sale"
                                                }]}
                                                search={term}
                                                total={total}
                                                selectedCity={selectedCity}
                                                branch={branch}

                                            />

                                        </div>

                                        <h2>Related Products</h2>
                                        <div id="relatedProd1s">
                                            <ItemListv2
                                                options={product.category_list || []}
                                                search={term}
                                                total={total}
                                                selectedCity={selectedCity}
                                                branch={branch}

                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="container mt-5" style={style.minHeight}>
                                    <div className="row">
                                        <div className="col text-center mt-5">
                                            NO PRODUCTS FOUND
                                            <br />
                                            <a href="/"> Click here for more products</a>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    :

                    <div className={'mt-5 pt-5'}>
                        <ItemListv2
                            options={term === '' ? [] : items}
                            search={term}
                            total={total}
                            selectedCity={selectedCity}
                            branch={branch}
                        />
                    </div>
            }

            {isMobile ?
                <div className={`cartdetails_ ${total.itemCount > 0 ? '' : 'd-none'}`} >
                    <a onClick={triggerCart} style={style.anchorText} >
                        <div className="card-body checkOutButton" style={style.cartCardMob}>
                            <div className="alert alert-secondary p-2 cartDetails shadow-lg" >
                                <div className="row">
                                    <div className="col-6 col-md-6 text-center">
                                        <span className=""><img src={cartIcon} width="16" />: {total.itemCount || ''} Item/s</span>
                                    </div>
                                    <div className="col-6 col-md-6 text-center">
                                        <span className="">&#8369; {total.itemTotal.toLocaleString(2)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                : null
            }
            <LoginModal
                visible={visibleLogin}
                modalToggle={modalShow}
            />
            <Cart cartModalShow={cartModalShow} setVisibleLogin={setVisibleLogin} cart={cart} setCart={setCart} visibleCart={visibleCart} setVisibleCart={setVisibleCart} total={total} setTotal={setTotal} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} />

        </div>
    );
}

const mapStateToProps = (state) => {

    return {
        CartRedux: state.cart.CartList,
    };
};

export default connect(mapStateToProps)(ProductPagev1);