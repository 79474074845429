import React, { useState, useEffect } from 'react';
import Home_api from '../APIs/Home';

const style = {
    modalBody: {
        position: 'fixed',
        bottom: '0',
        background: '#353535',
        zIndex: '999',
        width: '100%'
    },
    reviewButton:{
        background: "rgb(255, 212, 45)",
        borderRadius: "3px",
        boxShadow: "none",
        color: "black",
        cursor: "pointer",
        flex: "0 0 auto",
        padding: "5px 10px",
        margin: "15px"
    }
}

const ModalPolicy = () => {
    // select city branch setter
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';

    let reviewModal = sessionStorage.getItem('reviewPolicy');

    const acceptPolicy  = async () => {
        // alert("accept")
        await Home_api.post(`/acceptPolicy/`+getUser.customer_id)
            .then(response => {
                sessionStorage.setItem('reviewPolicy', 'Yes');
                window.location.reload();
            })
            .catch(error => {
                console.log(error)

            })
    }
    

    return (
        <>
        { getUser && reviewModal === "No" ?
            <div className="text-white" id="ReviewPolicy" style={style.modalBody} >
                <p className='p-4 pb-0 mb-0 pt-3'>Zagana updated its terms & conditions and privacy policy on October 19, 2022. By continuing to use Zagana, you're accepting the updated <a href="/ContentPage/terms" target="_blank">terms & conditions</a> and <a href="/ContentPage/privacy-policy" target="_blank">privacy policy</a>
                </p>
                <div className="row">
                    <div className='col-12 p-4 pb-0 pt-0'>
                        <a href='/ContentPage/privacy-policy' className='btn' style={style.reviewButton} target="_blank">Review</a>

                        <button href='/ContentPage/privacy-policy' onClick={acceptPolicy} className='btn btn-primary text-white' style={style.reviewButton} target="_blank">I Accept</button>
                    </div>
                </div>
                {/* {getUser.customer_id} */}
            </div>
            :
            ""
        }
        </>
    );
};

export default ModalPolicy;

