/*******************************************************************************************
* File Name                : Header
* File Directory           : src/components/Shared/Header
* Module                   : Header
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Header.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   Navbar        -   component for user navigation
 *
 * #How to call:
 *    <Header/>
 *
 * Note: Still working on the ff:
 * - 

 *******************************************************************************************/

 import React, { useEffect, useState } from "react";
 import SideBar from "./SideBar";
 import SelectCity from "../Home/SelectCity";
 import { Dropdown } from 'react-bootstrap';
 import ReactPixel from 'react-facebook-pixel';
import ZaganaLogo from "./ZaganaLogo";
 import searchSvg from "../../assets/site_icons/Search.svg";
 import { useDispatch } from "react-redux";
 import { forgotModal } from "../../actions";
 import ReactGA from 'react-ga4'
 
 const style = {
     loginBtn: {
         color: '#222',
         display: 'inline-block!important',
         paddingLeft: '5%',
         cursor: 'pointer',
         position: 'absolute',
         right: '15px',
         top: '5px',
         marginRight: '8% !important'
     },
     backBtn: {
         color: '#222',
         display: 'inline-block!important',
         paddingLeft: '5%',
         cursor: 'pointer'
     },
     headerBG: {
         'backgroundColor': '#facc02'
     },
     searchIcon: {
         cursor: 'pointer',
         borderRadius: '50px 0px 0px 50px',
         padding: '7px 10px',
         height: '30x'
     },
     inputRadius: {
         borderRadius: '0 50px 50px 0px',
         height: '28px'
     },
     rightIcons: {
         marginBottom: '0 !important',
         padding: '10px 0',
         display: 'inline-block!important',
         textAlign: 'right'
     },
     btnClear: {
         position: 'absolute',
         right: '15px'
     },
     dropdownUser: {
         width: '30%',
         display: 'inline-block'
     },
     displayInline: {
         display: 'inline-block!important'
     }
 }
 
 
 const DesktopHeader = ({ title, branch, setBranch, term, setTerm, setVisibleLogin, visibleLogin, visibleCart, setVisibleCart, total }) => {
     let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
     let body = document.body; // for setting body height (modal)
     const [totals, setTotals] = useState({ itemCount: 0, itemTotal: 0 })
     const isMobile = localStorage.getItem("isMobile") === "true";
     const dispatch = useDispatch()
     const historyBack = () => {
         window.history.back();
     }
     const clearSearch = () => {
         setTerm('')
     }
     useEffect(() => {
         setTotals(total||{ itemCount: 0, itemTotal: 0 })
     }, [total])
     const signOut = () => {
        
        ReactGA.event({
            category: 'Logout',
            action: 'Logout User',
        });
         sessionStorage.removeItem('Login');
         localStorage.removeItem('cart');
         localStorage.removeItem('deliveryDate');
         localStorage.removeItem('deliveryTime');
         localStorage.removeItem('Address');
         window.location.reload();
     };
 
     //toggle modal 
     const openLoginModal = () => {
        
        setVisibleLogin("false");
        // alert(visibleLogin)
        let element = document.getElementById("modalLoginID");
        element.classList.remove("showModal");
        body.classList.add("body_class_");
        dispatch(forgotModal(false))
    }

     const openCartModal = () => {
         setVisibleCart(!visibleCart);
     }
     return (
         <React.Fragment>
             <div className="row justify-content-md-center m-0 pc_nav" style={style.headerBG} >
                 <div className="row">
                     <div className="col-2  d-none d-sm-inline-block text-center">
                         <a href="/">
                             <ZaganaLogo />
                         </a>
 
                     </div>
 
                  
 
                     {/* Condition for user to show login link or customer profile */}
                     {!getUser ?
                         <div className="col-10 col-sm-8 pull-right my-auto cart_logg" style={style.rightIcons}>
                             {isMobile ? '' :
                                 <button onClick={openCartModal} style={style.backBtn} className="d-none d-sm-inline-block btn btn-transparent" style={{'visibility':'hidden'}} >
                                     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                         <g id="Layer_2" data-name="Layer 2"><g id="Layer_9" data-name="Layer 9"><path className="cls-1" d="M143.28,118.5,36.53,119.56a10,10,0,0,1-9.61-6.78l-21-61.57A16.25,16.25,0,0,1,21.25,29.72H168.38Z" /><line className="cls-1" x1="143.28" y1="116.28" x2="175.22" y2="6.14" /><circle className="cls-1" cx="49.36" cy="158.79" r="12.08" /><circle className="cls-1" cx="114.34" cy="158.79" r="12.08" /><path className="cls-1" d="M38,119.56l92.13-1.06c7.44,0,13.16,7.1,13.16,14.55h0a13.61,13.61,0,0,1-13.45,13.66H49.36" /><line className="cls-1" x1="175.6" y1="5" x2="192.64" y2="5" /></g></g></svg>
 
                                     {
                                         totals.itemCount > 0 ?
                                             <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{totals.itemCount}</div> : ''
                                     }
                                 </button>
                             }
                             <a onClick={openLoginModal} style={style.backBtn} className="d-none d-sm-inline-block">
                                 <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                     <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                                 </svg>
                             </a>
 
                             {isMobile ?
                                 <a href="/login" className="userIcon" style={style.loginBtn}>
                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#222" viewBox="0 0 197.64 175.87" fill="none" stroke="#222" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                         <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                                     </svg>
                                 </a> :
                                 ''
                             }
 
                         </div>
                         :
                         <div className="col-10 col-sm-8 pull-right my-auto cart_logg" style={style.rightIcons} >
                             {isMobile ? '' :
                                 <button onClick={openCartModal} style={style.backBtn} className="userDropdown d-none d-sm-inline-block btn btn-transparent" style={{'visibility':'hidden'}}>
                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f4cc0e" className="bi bi-cart2" viewBox="0 0 16 16">
                                         <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l1.25 5h8.22l1.25-5H3.14zM5 13a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                     </svg>
                                     {
                                         totals.itemCount > 0 ?
                                             <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{totals.itemCount}</div> : ''
                                     }
                                 </button>
                             }
                             {isMobile ?
                                 <a href="/user-profile" className="userDropdown" style={style.loginBtn}>
                                     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#222" viewBox="0 0 197.64 175.87" fill="none" stroke="#222" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                         <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                                     </svg>
                                 </a>
                                 :
                                 <div className="userDropdown d-none d-sm-inline-block" style={style.dropdownUser}>
                                     <Dropdown>
                                         <Dropdown.Toggle variant="default" id="dropdown-basic">
                                             <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.77 177.24" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" fill="none" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                                 <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7"><circle className="cls-1" cx="53.39" cy="40.23" r="35.23" /><path className="cls-1" d="M52.72,75.46h0a49.06,49.06,0,0,1,49.06,49.06v27.77a19.95,19.95,0,0,1-19.95,19.95H24.95A19.95,19.95,0,0,1,5,152.29V123.18A47.72,47.72,0,0,1,52.72,75.46Z" /></g></g>
                                             </svg>
                                         </Dropdown.Toggle>
                                         <Dropdown.Menu>
                                             <Dropdown.Item href="/user-profile" style={{ 'display': 'block !important' }} >{getUser.customer_name}</Dropdown.Item>
                                             <Dropdown.Item href="/my-account" style={{ 'display': 'block !important' }} >My Account</Dropdown.Item>
                                             <Dropdown.Item href="/user-profile" style={{ 'display': 'block !important' }} >My Orders</Dropdown.Item>
                                             <Dropdown.Item style={{ 'display': 'block !important' }} onClick={signOut} >Logout</Dropdown.Item>
                                         </Dropdown.Menu>
                                     </Dropdown>
                                 </div>
                             }
                         </div>
                     }
                 </div>
             </div>
 
         </React.Fragment>
     )
 
 };
 
 
 export default DesktopHeader;