/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : Blogs
 * File Directory           : src/components/Misc/Blogs
 * Module                   : Show All blogs from Zagana
 * Author                   : Bryan
 * Version                  : 2
 * Purpose                  : To view the blogs 
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Blogs        -   component for Blogs
 *
 * #How to call:
 *    <Blogs />
 *
 *******************************************************************************************/

import React, {useState, useEffect} from "react";
import MetaTags from 'react-meta-tags';
import LoginModal from '../Shared/LoginModal';
import {storeFrontLogs} from "../APIs/Logs";
import blogJson from "./blogJson";
import MiscHeader from "./MiscHeader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import _ from "lodash"

const styles = {
  headerBG: {
    'backgroundColor': '#facc02'
  },
  closeBtn: {
    width: '8%',
    float: 'right',
    textAlign: 'left'
  }
}

function Blogs({showModal}) {
    document.title = "Zagana | Blogs"
    const isMobile = localStorage.getItem("isMobile") === "true"
    const [visibleLogin,setVisibleLogin] = useState('true'); // show modal
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    const urlOrigin = window.location.origin
    let modalShow = `modal login ${visibleLogin === 'true'
        ? 'showModal'
        : ''}`; // class for show modal
    let gen_id = localStorage.getItem('gen_id')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        const payload = {
        user: user,
        changes: `Zagana || Blogs`,
        type: 'view_blogs',
        date: `NOW()`
        }
        storeFrontLogs(payload)
    }, [])


    // filter featured select only featured = true

    const filterFeatured = blogJson.filter( f => f.featured === true )

    // filter blogs where not featured

    const filterBlogs = blogJson.map( blog => {
        if (blog.featured === false) {
            return (
                <div className="card Allblogs" style={{width: '18rem'}}>
                    <a href={`/ContentPage/blogs/${blog.topic}`} >
                        <LazyLoadImage effect="blur" className="card-img-top" src={blog.image} alt={blog.topic} />
                    </a>
                    <div className="card-body">
                        <h5 class="card-title">
                            <a href={`/ContentPage/blogs/${blog.topic}`} >
                                {blog.topic}
                            </a>
                        </h5>
                    </div>
                </div>
            )
        }
        return ""
    })
    return (
        <React.Fragment>
            <MetaTags>
                <title>Zagana | Introducing Zagana</title>
                <meta name="description" content="The Online Grocery Every Filipino Needs"/>
                <meta property="og:title" content="Zagana | Introducing Zagana"/>
                <meta
                property="og:image"
                content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"/>
                <meta
                property="og:url"
                content="https://www.zagana.com/ContentPage/blogs"/>
                <meta property="og:type" content="website"/>
                <meta property="og:site_name" content="Zagana Inc."/>
                <meta
                name="keywords"
                content="farm-fresh, fresh, produce, local, frozen, farmers, quality, introduction, online grocery, online palengke, blogs"/>
                <meta name="author" content="Zagana Inc."/>
            </MetaTags> 

            {/*\\ PAGE CONTENT//*/}
                <div>
                    <MiscHeader />
                    <div className="misc-bm"> 

                        {/*\\ BLOGS|ZAGANA //*/}
                            <div className="misc-blog-front">
                                <div className="blog-blg col-9 p-0">
                                    <p>BLOGS</p>
                                    <span>Useful information for online grocery shopping of fresh and frozen.</span>
                                </div>
                                <div className="blogQuotes col-3 d-flex justify-content-end p-0">
                                    <a className="blogQuotesBtn btn btn-success" href={urlOrigin}>Back to Shop</a>
                                </div>
                            </div>

                        {/*\\ CARDS //*/}
                            <div className="blogCard">

                                {/*\\ FEATURED CARD //*/}
                                    <div className="blog-card row">
                                        <div className="meta">
                                            <a href={`/ContentPage/blogs/${filterFeatured[0].topic}`}>
                                                <div
                                                    className="photo"
                                                    style={{ 
                                                        backgroundImage: `url(${filterFeatured[0].image})`
                                                    }}
                                                />
                                            </a>
                                        </div>
                                        <div className="description">
                                            <h1>
                                                <a href={`/ContentPage/blogs/${filterFeatured[0].topic}`}>  
                                                    {filterFeatured[0].topic}
                                                </a> 
                                            </h1>
                                            <div className="description-content">
                                                <p className="featuredBlogP text-justify">
                                                     {_.get(filterFeatured[0].mainTopic, ['desctription'], '').length >= 250 ? `${filterFeatured[0].mainTopic.desctription.substring(0, 250)}...` : filterFeatured[0].mainTopic.desctription}
                                                     
                                                </p>
                                            </div>
                                            <a href={`/ContentPage/blogs/${filterFeatured[0].topic}`} className="card-btn">
                                                See more
                                            </a>
                                        </div>
                                    </div>

                                {/*\\ NORMAL CARDS //*/}
                                    <div className="blogs-card">
                                        {filterBlogs}
                                    </div>
                            </div> 

                        {/*\\ YOUTUBE SECTION //*/}
                        <div className="yt yt-blogs">
                            <div className="row justify-content-center p-2">
                                <iframe width="560" height="375" src="https://www.youtube.com/embed/HoB6qJmpUIw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            </div>
                            <div className="row ytDesc p-4 pt-4">
                                <h2>This is Zagana</h2>
                                <p>
                                    Two years ago, Zagana was delivering to restaurants but when the pandemic hit, we didn't have much choice but to adapt because we have a mission to fulfill. And that is to bridge the gap between food producers and consumers through technology.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            
            {/*\\ LOGIN MODAL //*/}
            <LoginModal visible={visibleLogin} modalToggle={modalShow}/>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
  return {showModal: state.ModalLogin.showModal}
}

export default Blogs;