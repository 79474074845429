const INTIAL_STATE = {
    createPayment_id   : ''
};

const XenditCreatePayment = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'XenditCreatePayment':
            return {
                ...state,
                createPayment_id   : action.createPayment_id
            };
        default:
            return state;
    }

};

export default XenditCreatePayment;
