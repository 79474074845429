import React, { useState, useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
import CookieConsent from "react-cookie-consent";
import { PageView, initGA } from '../Tracking';
import { forgotModal } from "../../actions"
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

const style = {
    style: {
        position: 'relative',
        backgroundColor: 'unset',

    },
    button: {
        backgroundColor: 'unset',
        border: '2px solid #0D6EFD',
        borderRadius: '15px',
        position: 'absolute',
        right: '0px',
        bottom: '-3.3rem',
        color: 'black'
    },
    back: {
        position: 'absolute',
        border: '2px solid #0d6efd',
        padding: '4px 10px',
        borderRadius: '15px',
        marginLeft: '1rem',
    },
    policy: {
        bottom: '-3rem',
        position: 'relative',
    }
}

const CookieSettings = () => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const [selectGA, setSelectedGA] = useState(true)
    const [selectPixel, setSelectPixel] = useState(true)
    const [selectAll, setSelectAll] = useState(true)
    const dispatch = useDispatch()
    const history = useHistory()
    const [windowDimension, setWindowDimension] = useState(null);
    let cookieSettings = {};
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };
    ReactPixel.init('871545380653151', options);
    useEffect(() => {
        initGA('UA-191468992-1');
        PageView();
        setWindowDimension(window.innerWidth);
        console.log("getUser")
        console.log(getUser)
        let defaultChecked = JSON.parse(localStorage.getItem("cookieSettings"))
        if(getUser === ''){
            cookieSettings = {
                Pixel:true,
                GA: true,
                ALL:true
            }
        }
        else{
            setSelectedGA(defaultChecked.GA)
            setSelectPixel(defaultChecked.Pixel)
            setSelectAll(defaultChecked.ALL)
        }
    }, []);
    useEffect(() => {
        // if(getCookieConsentValue('zaganaCookie')==='true'){
        window.gtag("config", "G-01DEJ7ZZWE", {
            page_title: window.location.href,
            page_path: window.location.pathname.replace("/", '') || 'home',
        })
        // }
    });
    useEffect(() => {
        function handleResize() {
            setWindowDimension(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    useEffect(() => {
        if (selectPixel && selectGA) {
            setSelectAll(true)
            cookieSettings = {
                Pixel:true,
                GA: true,
                ALL:true
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        } else {
            setSelectAll(false)
            cookieSettings = {
                Pixel:selectPixel,
                GA: selectGA,
                ALL:false
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        }
    }, [selectPixel, selectGA])
    const handleChangeAll = (e) => {
        const { name, checked } = e.target
        if (checked) {
            setSelectPixel(true)
            setSelectedGA(true)
            setSelectAll(true)
            cookieSettings = {
                Pixel:true,
                GA: true,
                ALL:true
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        } else {
            setSelectPixel(false)
            setSelectedGA(false)
            setSelectAll(false)
            cookieSettings = {
                Pixel:false,
                GA: false,
                ALL:false
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        }
    }
    const handleChangePixel = (e) => {
        const { checked } = e.target
        if (checked) {
            setSelectPixel(true)
            cookieSettings = {
                Pixel:true,
                GA: selectGA,
                ALL:selectAll
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        } else {
            setSelectPixel(false)
            cookieSettings = {
                Pixel:false,
                GA: selectGA,
                ALL:selectAll
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        }
    }
    const handleChangeGA = (e) => {
        const { checked } = e.target
        if (checked) {
            setSelectedGA(true)
            cookieSettings = {
                Pixel:selectPixel,
                GA: true,
                ALL:selectAll
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        } else {
            setSelectedGA(false)
            cookieSettings = {
                Pixel:selectPixel,
                GA: false,
                ALL:false
            }
            localStorage.setItem("cookieSettings",JSON.stringify(cookieSettings));
        }
    }
    const onAcceptCookie = () => {
        console.log(selectPixel)
        if (selectPixel) {
            ReactPixel.grantConsent()
            window['ga-disable-G-01DEJ7ZZWE'] = false;
        } else {
            window['ga-disable-G-01DEJ7ZZWE'] = true;
            ReactPixel.revokeConsent();
        }

        if (selectGA) {
            window.gtag("config", "G-01DEJ7ZZWE", {
                page_title: window.location.href,
                page_path: window.location.pathname.replace("/", '') || 'home',
            })
        }
        dispatch(forgotModal(false))
        window.location.href = "/"

    }
    const onClickBack = () => {
        history.push('/')
    }
    return (
        <React.Fragment>
            <h4>Changing cookie settings on this site</h4>
            <div>
                Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.
            </div>
            <br />
            <b>Please check your cookie settings below and turn on the cookies you are happy with.</b>
            <div className="p-4">
                <div className="form-check">
                    <input
                        type="checkbox"
                        name="selectAll"
                        className="form-check-input"
                        onChange={handleChangeAll}
                        checked={selectAll}
                    />
                    <label className="form-check-label"><b>Accept all cookies</b></label>
                </div>
                <div className="form-check">
                    <input type="checkbox" name="strictly" className="form-check-input" checked={true} disabled={true} />
                    <label className="form-label"><b>Strictly necessary cookies.</b>  These are cookies that are essential for our website to function properly. Cookies, for example, allow our website to remember information you enter into forms during a session or to remember your preferences.
                    </label>
                </div>
                <div className="form-check">
                    <input
                        type="checkbox"
                        name="GA"
                        className="form-check-input"
                        checked={selectGA}
                        onChange={handleChangeGA}
                    />
                    <label className="form-check-label"><b>Analytical or performance cookies.</b> These let us recognize and count the number of visitors as well as see how they navigate our website while on it. This allows us to enhance the way our website functions, such as ensuring that people can simply locate what they're looking for.
                    </label>
                </div>
                <div className="form-check">
                    <input
                        type="checkbox"
                        name="FBPixel"
                        className="form-check-input"
                        checked={selectPixel}
                        onChange={handleChangePixel}
                    />
                    <label className="form-check-label"><b>Marketing and advertising cookies.</b> These cookies, which Zagana Inc. or its media agencies have put on this site, track your visit to this website, the pages you have visited, and the internet links you have followed. Zagana Inc., uses this information to tailor their online or social media advertisements or offers on third-party websites, or their own websites, to those that are most likely to be of interest to you, as well as to evaluate the effectiveness of their online marketing and advertising campaigns.
                    </label>
                </div>
            </div>
            <div className="col-12">
                {isMobile ?
                    <div style={style.back} onClick={onClickBack}>
                        Back
                    </div>
                    :
                    null
                }
                <CookieConsent
                    debug={true}
                    enableDeclineButton
                    cookieName="zaganaCookie"
                    style={style.style}
                    buttonStyle={{ display: 'none' }}
                    buttonClasses="float-right"
                    declineButtonStyle={style.button}
                    declineButtonText="Save settings"
                    contentStyle={{ display: 'none' }}
                    onDecline={onAcceptCookie}
                />
            </div>
            <div className="col-12 mb-5" style={style.policy}>
                You can learn more about our cookies in our <a href='/ContentPage/cookie-policy'> cookie policy</a>.
            </div>
        </React.Fragment>
    )
}

export default CookieSettings;