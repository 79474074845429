/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ItemListv2
 * File Directory           : src/components/Shared/ItemListv2
 * Module                   : Items
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/Search.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <ItemListv2/>
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useTimer } from 'react-timer-hook';
import { set } from "react-ga";

import LoadingModal from "../Modal/LoadingModal";

import ItemCardv2 from "./ItemCardv2";
import SublistCard from "./SublistCard";
import ItemViewModal from "./ItemViewModal";
import ProductPage from "./productPage";

import { height } from "@mui/system";

import Home from "../APIs/Home";

import moment from 'moment';

const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'zoom-in',
        position: 'relative',
        height: '45vw'
    }
}


const ItemListv2 = ({
    search,
    branch,
    options,
    setTotal,
    CartRedux
}) => {
    const sku = useParams();

    const [defaultView, setDefaultView] = useState(4)

    const [seeMore, setSeeMore] = useState(false)
    const [isLoading, setIsLoading] = useState(true) //showing loading modal
    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload

    const [items, setItems] = useState([]) //list of products
    const [subList, setSubList] = useState([]) //list of products
    const [combine, setCombine] = useState([]) //list of products
    const [filtered, setFiltered] = useState([]) //list of products

    const [onLoad, setOnLoad] = useState(true)
    const [isNoItems, setIsNoItems] = useState(false)

    const selectedBranch = branch !== null ? branch.branch_id : 1 //setting default value for selected branch

    const expiryTimestamp = (new Date()).setSeconds((new Date()).getSeconds())

    const userLogin = JSON.parse(sessionStorage.getItem('Login')) || null;
    const userId = userLogin ? userLogin.customer_id : null

    let accessKey = localStorage.getItem('accessKey')

    let autoStart = false

    const {
        seconds,
        minutes,
        hours,
        restart,
    } = useTimer({
        expiryTimestamp, autoStart, onExpire: () =>
            (window.location.origin === "https://zagana.com" || window.location.origin === "https://www.zagana.com") && window.location.reload()
    });
    useEffect(() => {

        if (onLoad && items.length > 0) {
            items.some(element => {
                if (element.category.includes("flash")) {
                    restart((new Date(moment(element.flash_end_date).subtract('hours', 8))).setSeconds((new Date(moment(element.flash_end_date).subtract('hours', 8))).getSeconds()))
                    return true;
                }
            });
            setOnLoad(false)
        }

    }, [items])

    useEffect(() => {
        const combineOrders = subList.concat(items)
        // console.log(items);
        // console.log(combineOrders);
        setItems(combineOrders)
    }, [subList])
    //useEffect for getting products in DB 
    useEffect(async () => {
        setIsNoItems(false)
        if ((window.location.pathname).includes("productPage")) {
            try {
                setIsLoading(true)
                const response = await Home.get(`/itemList2/ALL/${selectedBranch}/ALL${userLogin ? "?userId=" + userId : ""}`)
                if (response.status === 200) {
                    let result = response.data.data
                    let itemChecker = result !== "No Remaining Products"
                    if (itemChecker) {
                        result = result.map((item) => {
                            let defaultImg = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
                            item.item_image = item.item_image && item.item_image !== "NULL" ? item.item_image : defaultImg
                            let category = item.category
                            item.category = category.split(",")
                            return item
                        })
                    }
                    //product page removing item itself and only enabled items are shown
                    if ((window.location.pathname).includes("productPage") && search === "") {
                        result = result.filter((item) => { return item.item_sku !== sku.sku && item.item_status === "enabled" })
                    }

                    setItems(itemChecker ? result : [])
                    setFiltered(itemChecker ? result : [])
                    setIsLoading(false)
                    sublistFetch();
                }
            } catch (err) {
                console.log(err.message)
            }
        } else {
            if (search === "" && branch !== null)

                try {
                    setIsLoading(true)
                    const response = await Home.get(`/itemList2/ALL/${selectedBranch}/ALL${userLogin ? "?userId=" + userId : ""}`)
                    if (response.status === 200) {
                        let result = response.data.data
                        let itemChecker = result !== "No Remaining Products"
                        if (itemChecker) {
                            result = result.map((item) => {
                                let defaultImg = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
                                item.item_image = item.item_image && item.item_image !== "NULL" ? item.item_image : defaultImg
                                let category = item.category
                                let sub_listing = item.sub_listing.toLowerCase().replace(/\s/g, "")
                                item.category = category?.split(",")
                                item.sub_listing = item.sub_listing === '' ? [] : sub_listing?.split(",")
                                return item
                            })
                        }
                        //product page removing item itself and only enabled items are shown
                        if ((window.location.pathname).includes("sub-list")) {
                            result = result.filter((item) => { return !item.sub_listing.includes(sku.subCategory.toLowerCase().replace(/\s/g, "")) && item.item_status === "enabled" })
                        }


                        setItems(itemChecker ? result : [])
                        setFiltered(itemChecker ? result : [])
                        setIsLoading(false)
                        sublistFetch();
                    }
                } catch (err) {
                    console.log(err.message)
                }
            else
                setIsLoading(false)
        }

    }, [branch])

    const sublistFetch = async () => {
        try {
            // setIsLoading(true)
            const response = await Home.get(`/sub_listing`)
            if (response.status === 200) {
                let result = response.data.data

                result = result.map((item) => {
                    let defaultImg = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
                    item.item_image = item.item_image && item.item_image !== "NULL" ? item.item_image : defaultImg
                    let category = item.category
                    item.category = category.split(",")
                    // let item_branch = 1;
                    // item.item_branch = item_branch
                    item.item_status = item.sub_category_status === "1" ? "enabled" : "disabled"
                    item.item_price = 0
                    item.item_salePrice = 0
                    return item
                })
                setSubList(result);
            }
        } catch (err) {
            console.log(err.message)
        }
    }
    //useEffect for every cart changes save in DB 
    // useEffect(() => {
    //     if (!isFirstRender)
    //         try {
    //             Home.post('/accessStorage/changeCart', {
    //                 itemOnCart: CartRedux,
    //                 accessToken: accessKey
    //             })
    //         } catch (err) {
    //             console.log(err.message)
    //         }
    //     else
    //         setIsFirstRender(false)
    // }, [CartRedux])

    useEffect(() => {
        if (search !== "") {
            let searchWords = (search.toUpperCase()).split(" ")
            //filter items base on search
            let inSearch = items.filter((val, index, arr) => {
                return searchWords.every((currentValue) => val.item_name?.toUpperCase().match(currentValue)) ? val : null
            })
            setFiltered(inSearch)
            if (inSearch.length === 0) {
                setIsNoItems(true)
            }
        } else {
            setFiltered(items)
        }

    }, [search, items])
    return (
        <React.Fragment>
            <div className="container itemlist-container">
                {filtered.length > 0 ?
                    options.map((data, index) => {
                        // console.log(">>>>>>>>>>>");
                        // console.log(index);
                        if (filtered.some((currentValue) => {
                            return data.category_tag?.toLowerCase().includes((currentValue?.category).toString())
                        })) {
                            let item = document.getElementById(`form-${data.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                            if (item)
                                item.classList.remove('categ-d-none')
                            let categDiv = document.getElementById(`categoryDiv${data.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                            if (categDiv !== null)
                                categDiv?.classList?.remove('d-none')
                            // items filtered by categ
                            const itemFilterTotal = filtered.filter((item) => {
                                if (data.category_tag === 'Fruits, FreshFruits') {
                                    console.log(data.category_tag?.toLowerCase());
                                    console.log((item?.category).toString());
                                }
                                return (item?.category).toString().includes(data.category_tag?.toLowerCase())
                            })
                            let itemFilter = filtered.filter((item) => { return data.category_tag?.toLowerCase().includes((item?.category).toString()) })
                            // if on product page,limit view to 4
                            if ((window.location.pathname).includes("productPage") && search === "" || (window.location.pathname).includes("sub-list")) {
                                itemFilter = itemFilter.splice(0, defaultView)
                            }

                            const seeMoreLess = () => {
                                if (itemFilterTotal.length > defaultView)
                                    return "See More"
                                if (itemFilterTotal.length < defaultView)
                                    return "See Less"
                            }
                            // {defaultView > itemFilter.length ? itemFilter.length < 4 ? null : itemFilter.length < defaultView ? null : "See less" : "See More"}
                            return (
                                <React.Fragment key={index}>
                                    {
                                        (!(window.location.pathname).includes("productPage") && !(window.location.pathname).includes("home") || (window.location.pathname).includes("productPage") && search !== "") &&
                                        <h5 className="mt-5 w-100 category-title" id={`${data.category_tag}`} >{data.category_name}
                                            {data.category_name.toLowerCase() === "flash sale" ?
                                                <d>
                                                    <span> {("0" + hours).slice(-2)}</span> : <span>{("0" + minutes).slice(-2)}</span> : <span>{("0" + seconds).slice(-2)}</span>
                                                </d>
                                                :
                                                null
                                            }
                                        </h5>
                                    }
                                    <div className={`${search !== "" ? "search-item-list" : "item-list"} ${data.category_name.toLowerCase() === "sale" || data.category_name.toLowerCase() === "recommended" ? "prodSlider" : ""}`}>

                                        {itemFilter.map((item, i) => (

                                            <React.Fragment key={i}>
                                                {data.category_tag?.toLowerCase().includes((item?.category).toString()) ?
                                                    <ItemCardv2 items={item} />
                                                    :
                                                    null
                                                }
                                            </React.Fragment>
                                        ))
                                        }

                                    </div>
                                    {(window.location.pathname).includes("productPage") && search === "" || (window.location.pathname).includes("sub-list") && search === "" ?
                                        <button className="item-list-see-more" onClick={(e) => { setDefaultView(defaultView > itemFilter.length ? 4 : defaultView + 4) }}>
                                            {seeMoreLess()}
                                        </button> : null}

                                </React.Fragment>
                            )
                        } else {
                            let item = document.getElementById(`form-${data.category_tag?.replace(" ", '')?.replace(" ", '').toLowerCase()}`)
                            if (item)
                                item.classList.add('categ-d-none')
                            let categDiv = document.getElementById(`categoryDiv${data.category_tag.replace(" ", '').replace(" ", '').toLowerCase()}`)
                            if (categDiv)
                                categDiv.classList.add('d-none')
                        }
                    })
                    :
                    <React.Fragment>
                        {isNoItems ?
                            <div className="container" style={style.center}>
                                <h5>
                                    No product/s match your search.
                                </h5>
                            </div>
                            :
                            <div className="container" style={style.center} />

                        }
                    </React.Fragment>
                }
            </div>
            <LoadingModal
                modalStatus={isLoading}
            />
            <ItemViewModal />
        </React.Fragment >

    )

}


const mapStateToProps = (state) => {
    return {
        CartRedux: state.cart.CartList,
    };
};

export default connect(mapStateToProps)(ItemListv2);