import axios from 'axios';
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { v4 as uuid, v4 as uuidv4 } from 'uuid'; // modeule for generating id
import { forgotModal } from "../../actions";
import loginLogo from "../../assets/logo/zagana-logo.png";
import Home_api from '../APIs/Home';
import { storeFrontLogs } from "../APIs/Logs";
import SortBy from '../Home/SortBy';
import ForgotPass from "../Login/ForgotPass";
import Login from '../Login/Login';
import Register from '../Login/Register';
import Cart from '../Products/CartModal';
import ItemListv2 from "../Products/ItemListv2";
import Carousel from "../Shared/Carousel";
import CartDetails from "../Shared/CartDetails";
import FeedBack from '../Shared/FeedBack';
import Header from "../Shared/Header";
import LinkIcons from '../Shared/Links/LinkIcons';
import ModalPolicy from "../Shared/ModalPolicy";
import ModalUpdateInfo from "../Shared/ModalUpdateInfo";
import { SaveToDatabase, searchToDatabase } from "../Shared/SaveToDatabase";
import { SaveToSession } from "../Shared/SaveToSession";
const style = {
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'center'
    },
    btnClear: {
        position: 'absolute',
        top: '8px',
        right: '50px'
    },
    top35: {
        top: '35vh',
        position: 'relative'
    },
    top80: {
        top: '80vh',
    },
    top20: {
        top: '20vh',
    },
    seeAll: {
        marginTop: "7rem",
        width: "100%",
        textAlign: "center",
        cursor: 'pointer',
        color: '#24b14b'
    }
}

let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const Home = ({ showModal, category, CartRedux, notifyItem, notifyLogin }) => {
    document.title = "Zagana | Farm to Kitchen";
    const param = useParams();
    const phoneNumber = param.userPhoneNumber ? param.userPhoneNumber : ""
    const [scrollPosition, setScrollPosition] = useState(0);
    const [viewing, setViewing] = useState('grid')
    const [items, setItems] = useState([]);
    const [cartVDetails, setCartVDetails] = useState(defaultItem);
    const [cartVChange, setCartVChange] = useState(0);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [selected, setSelected] = useState('');
    const [branch, setBranch] = useState(null);
    const [label, setLabel] = useState('Category');
    const [active, setActive] = useState('');
    const [slide, setSlide] = useState('firstSlide');
    const [term, setTerm] = useState('');// search for desktop
    const [selectedCity, setSelectedCity] = useState({});// cart for desktop
    const [cart, setCart] = useState([]);// cart for desktop
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [count, setCount] = useState(9)

    const [confirmContactmodalStatus, setConfirmContactModalStatus] = useState(false)
    const [userLoginNotify, setUserLoginNotify] = useState(null)
    const [itemNotify, setItemNotify] = useState([])

    let hashCheckout = window.location.hash;
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [visibleCart, setVisibleCart] = useState(hashCheckout === "#cart" ? false : true); // show modal cart
    const [visibleLinks, setVisibleLinks] = useState(false)

    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal
    let cartModalShow = `modal cart ${visibleCart === true ? 'showModal' : ''}`; // class for cart show modal
    let body = document.body; // for setting body height (modal)
    const isLogged = sessionStorage.getItem("Login") || null; // login checker
    const isMobile = localStorage.getItem("isMobile") === "true";
    const [imgSlide, setImgSlide] = useState([]); // for mapping banner
    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload
    const history = useHistory();
    const dispatch = useDispatch()
    const isFiltered = category.length > 0
    const uniqueID = uuid(); // generating id
    let accessKey = localStorage.getItem('accessKey')
    let source = sessionStorage.getItem('source') || ''
    let date = localStorage.getItem('date') || ''
    const [isRefresh, setIsRefresh] = useState({})
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;


    useEffect(() => {
        //computing total cart price and qty
        let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
        let itemCount = 0
        CartRedux.forEach((cart) => { itemCount += cart.item_status === "enabled" ? cart.cart_qty : 0 }) // returning qty per item
        totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
        // itemCount = itemCount.reduce((a, b) => a + b, 0) // total of all items
        setTotal({ itemTotal: totalPrice, itemCount: itemCount })
        sessionStorage.setItem('total', JSON.stringify({ itemTotal: totalPrice, itemCount: itemCount }))
    }, [CartRedux])
    useEffect(() => {
        if (notifyLogin) {
            setVisibleLogin("false");
            // let element = document.getElementById("modalLoginID");
            // element.classList.remove("showModal");
            let body = document.body; // for setting body height (modal)
            body.classList.add("body_class_");
            dispatch(forgotModal(false))
        }
    }, [notifyLogin])
    const openCartModal = () => {
        setVisibleCart(!visibleCart);
    }
    const identity = "6d5a74c940694d668aaaae6b402b4ee71cca906bc6ce48c39002fae9d72ae384-IDTK45E291-112701"
    const [generatedToken, setGeneratedToken] = useState('') // error msg
    let heightPadding;

    //checking if the generated id is stored on local
    if (gen_id === null) {
        gen_id = localStorage.setItem('gen_id', uniqueID)
        gen_id = localStorage.getItem('gen_id')
        user = gen_id
    } else if (gen_id) {
        user = gen_id
    } else if (isLogged) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    }

    if (isMobile) {
        heightPadding = 0;
    }
    else {
        heightPadding = 160;
    }
    useEffect(() => {
        SaveToSession({
            page: 'Home',
            path: window.location.search
        })
        if (source === 'FB') {
            SaveToDatabase({
                action: {
                    type: 'pageView',
                    value: {}
                }
            })
            ReactPixel.pageView();
        }
        const payload = {
            user: user,
            changes: `Zagana || HomePage`,
            type: 'view_home_page',
            date: `NOW()`
        }
        storeFrontLogs(payload)
        dispatch({
            type: "SELECTED_PAYMENT",
            selected: 'Select payment',
            status: true
        })
        if (phoneNumber.length > 10 || phoneNumber.length < 10) {
        } else {
            landBankLogin()
        }
        sessionStorage.removeItem('deliveryTime')
        sessionStorage.removeItem('deliveryDate')
        sessionStorage.removeItem('orderTotal')
        sessionStorage.removeItem('paymongo')
        sessionStorage.removeItem('order')
        sessionStorage.removeItem('stops')
        sessionStorage.removeItem('NumStops')

        const banner = async () => {
            const response = await Home_api.get(isMobile ? '/banner/mobile' : '/banner/desktop')
            const bannerResult = response.data.banner || []
            setImgSlide(bannerResult)

        }
        banner()

        // let city = JSON.parse(localStorage.getItem('SelectedCity')) || { branch_id: 3 }
        // setBranch(city)
        setTerm("");

        //dbStorage
        // function uuidv4() {
        //     uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
        // }
        let link = uuidv4();
        let Login = sessionStorage.getItem('Login');
        const accessStorage = async (status) => {
            await Home_api.post('/accessStorage', { accessToken: link })
            localStorage.setItem('accessKey', link)
            if (status === 'tokenNotFound') {
                window.location.reload()
            }
        }
        if (!accessKey) {
            // ("PASOK1")
            if (localStorage.getItem('branchSelected') === true) {
                // ("PASOK3")
                accessStorage('tokenNotFound')
            } else {
                // ("PASOK4")
                accessStorage()
            }
        } else {
            // ("PASOK2")
            const getAccount = async () => {
                let response = await Home_api.post('/accountChecker', { accessToken: accessKey })
                // console.log(response.data)
                if (response.data.result === "AccessTokenNotFound") {
                    // ("PASOK5")
                    localStorage.setItem('branchSelected', false)
                    accessStorage('tokenNotFound')
                }
                else {
                    if (response.data.loginDetails.length > 0) {
                        const loginDetails = JSON.stringify(response.data.loginDetails[0]);
                        sessionStorage.setItem("Login", loginDetails);
                        sessionStorage.setItem('FBLogin', false)
                    } else {
                        sessionStorage.removeItem("Login")
                    }
                    setOnCartLoaded(true)
                    if (response.data.selectedCity === null || response.data.selectedCity.length == 0) {
                        // ("PASOK6")
                        setBranch(null)
                        localStorage.setItem('SelectedCity', JSON.stringify({}))
                        setTimeout(() => {
                            localStorage.setItem('branchSelected', 'false')
                        }, 500);
                    } else {
                        // (response.data.selectedCity)
                        localStorage.setItem('branchSelected', 'true')
                        localStorage.setItem('SelectedCity', JSON.stringify(response.data.selectedCity || {}))
                        setBranch(response.data.selectedCity || [])
                    }
                }
            }
            getAccount()
            const getCart = async () => {
                let response = await Home_api.post('/cartChecker', { accessToken: accessKey })
                // console.log(response.data)
                if (response.data.result === "AccessTokenNotFound") {
                    // ("PASOK5")
                    localStorage.setItem('branchSelected', false)
                    accessStorage('tokenNotFound')
                }
                else {
                    // ("PASOK8")
                    let itemsOnCart = response.data.cart || []
                    // (response.data)
                    setCart(response.data.cart || [])
                    // console.log(itemsOnCart)
                    dispatch({
                        type: "SET_CART",
                        selected: itemsOnCart,
                    });
                }


            }
            getCart()
        }
        // setCart(JSON.parse(localStorage.getItem('cart')));
    }, [])
    useEffect(() => {
        const category = async () => {
            const response = await Home_api.get(`/categoryList/${branch.branch_id}`)
            // const item
            const itemResult = response.data.category_list || []
            setItems(itemResult)
        }
        if (branch !== null)
            category()
    }, [branch])
    const landBankLogin = () => {
        let data = JSON.stringify({
            "clientId": process.env.REACT_APP_LANDBANK_ID,
            "secret": process.env.REACT_APP_LANDBANK_SECRET
        });

        let config = {
            method: 'post',
            url: 'https://landbankpay.bank-genie.co:7004/api/Ecommerce/Token',
            headers: {
                'Identity': '6d5a74c940694d668aaaae6b402b4ee71cca906bc6ce48c39002fae9d72ae384-IDTK45E291-112701',
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                let token = response.data.body.token;

                dispatch({
                    type: "accessToken",
                    token: token
                })
                setGeneratedToken(token);
                var myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${token}`);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "clientId": process.env.REACT_APP_LANDBANK_ID,
                    "secret": process.env.REACT_APP_LANDBANK_SECRET,
                    "mobileNumber": phoneNumber
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch("https://landbankpay.bank-genie.co:7004/api/Customer/Get", requestOptions)
                    .then(response => response.text())
                    .then(function (response) {
                        let body = JSON.parse(response)
                        body = body.body
                        const details = {
                            FirstName: body.firstName,
                            LastName: body.lastName,
                            Email: body.email,
                            Password: body.Password,
                            Birthdate: body.dateOfBirth,
                            Phone: body.mobileNumber,
                            RadioGrp: "---",
                            CheckBox: "---"
                        }

                        if (body.isValid) {
                            Home_api.post(`/authenticate/landbank/sign_up`, details)
                                .then(response => {
                                    if (response.data.message === 'Registration Success') {
                                        const loginDetails = JSON.stringify(response.data.loginDetails[0])
                                        let login = response.data.loginDetails[0]
                                        sessionStorage.setItem('Login', loginDetails)
                                        sessionStorage.setItem('FBLogin', false)
                                        sessionStorage.setItem('LBLogin', true)
                                        if (source === 'FB') {
                                            SaveToDatabase({
                                                action: {
                                                    type: 'CompleteRegistration',
                                                    value: {
                                                        content_name: 'Registration Page'
                                                    }
                                                }
                                            })
                                            ReactPixel.track('CompleteRegistration', {
                                                content_name: 'Registration Page'
                                            });
                                        }
                                        if (localStorage.getItem("notifyItem") !== null) {
                                            if (!login.preferredNotification) {
                                                setUserLoginNotify(login)
                                                setItemNotify(JSON.parse(localStorage.getItem("notifyItem")))
                                                setConfirmContactModalStatus(true)
                                            } else {
                                                let item = JSON.parse(localStorage.getItem("notifyItem"))
                                                Home.post('/notifyItem/add', {
                                                    userId: login.customer_id,
                                                    item_sku: item.item_sku,
                                                    item_branch: item.item_branch,
                                                    customerEmail: login.customer_email,
                                                    phoneNumber: login.customer_phone,
                                                    mode: null
                                                })
                                                setItemNotify({
                                                    item_id: item.item_id,
                                                    item_sku: item.item_sku,
                                                    item_name: item.item_name,
                                                    notify_id: "success"
                                                })
                                                history.push("/");
                                                window.location.href = "/";
                                            }
                                        } else {
                                            history.push("/");
                                            window.location.href = "/";
                                        }
                                        // history.push('/')
                                        //  history.push('/verifyOTP')
                                    }
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        } else {
                            history.push("/");
                        }
                    })
                    .catch(error => console.log('error', error));
            })
            .catch(function (error) {
                console.log(error);
            });

    };

    useEffect(() => {
        if (term !== '') {
            setTimeout(() => {
                window.scrollTo(0, 0)
            }, 2000)

        }
    }, [term])

    // const toggleFeedBack = (event) => {
    //     let el = event.currentTarget.dataset.id;
    //     setFormID(el);
    // };

    const handleScroll = () => {

        //scroll effects / getting Y axis 
        const position = window.pageYOffset;
        setScrollPosition(position);
        // alert(position)
        if (position > 80) {
            setCount(4)
        }
        else {
            setCount(9)

        }
        // 
    };


    const clearSearch = () => {
        setTerm('')
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        //setting elements by id
        let sale_element = document.getElementById('h_sale') || '',
            best_element = document.getElementById('h_recommended') || '',
            v_element = document.getElementById('h_vegetables') || '',
            f_element = document.getElementById('h_fruits') || '',
            m_element = document.getElementById('h_meats') || '',
            sea_element = document.getElementById('h_seafoods') || '',
            poul_element = document.getElementById('h_poultry') || '',
            rice_element = document.getElementById('h_rice') || '',
            egg_element = document.getElementById('h_dairy&eggs') || '',
            herbs_element = document.getElementById('h_herbs&spices') || '',
            bundles_element = document.getElementById('h_bundles&vouchers') || '',
            frozen_element = document.getElementById('h_frozenfood') || '',
            desserts_element = document.getElementById('h_desserts') || '',
            bev_element = document.getElementById('h_beverages') || '',
            voucher_element = document.getElementById('h_voucher') || '',
            more_element = document.getElementById('h_more') || ''




        //added height upon scroll


        let sales_height = sale_element.offsetHeight + heightPadding;
        let best_height = sales_height + best_element.offsetHeight;
        let veg_height = best_height + v_element.offsetHeight;
        let fruits_height = (veg_height + f_element.offsetHeight);
        let meat_height = fruits_height + m_element.offsetHeight;
        let poul_height = meat_height + poul_element.offsetHeight;
        let sea_height = poul_height + sea_element.offsetHeight;
        let eggs_height = sea_height + egg_element.offsetHeight;
        let rice_height = eggs_height + rice_element.offsetHeight;
        let frozen_height = rice_height + frozen_element.offsetHeight;
        let desserts_height = frozen_height + desserts_element.offsetHeight;
        let bev_height = desserts_height + bev_element.offsetHeight;
        let bundles_height = bev_height + bundles_element.offsetHeight;
        let herbs_height = bundles_height + herbs_element.offsetHeight;
        let voucher_height = bev_height + voucher_element.offsetHeight;
        let more_height = voucher_height + more_element.offsetHeight;

        // let sales_height = sale_element.offsetHeight + heightPadding;
        // let best_height = sales_height + best_element.offsetHeight;
        // let fruits_height = (best_height + f_element.offsetHeight);
        // let veg_height = fruits_height + v_element.offsetHeight;
        // let meat_height = veg_height + m_element.offsetHeight;
        // let sea_height = meat_height + sea_element.offsetHeight;
        // let poul_height = sea_height + poul_element.offsetHeight;
        // let rice_height = poul_height + rice_element.offsetHeight;
        // let eggs_height = rice_height + egg_element.offsetHeight;
        // let herbs_height = eggs_height + herbs_element.offsetHeight;
        // let bundles_height = herbs_height + bundles_element.offsetHeight;
        // let frozen_height = bundles_height + frozen_element.offsetHeight;
        // let desserts_height = frozen_height + desserts_element.offsetHeight;
        // let bev_height = desserts_height + bev_element.offsetHeight;
        // let voucher_height = bev_height + voucher_element.offsetHeight;
        // let more_height = voucher_height + more_element.offsetHeight;


        // Condition to show selected categorya
        if (scrollPosition <= sale_element.offsetHeight) {

            setLabel('Sale')
            setActive('Sale')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= best_height) {

            setLabel('Recommended')
            setActive('Recommended')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= veg_height) {

            setLabel('Vegetables')
            setActive('Vegetables')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= fruits_height) {
            setLabel('Fruits')
            setActive('Fruits')
            setSlide('firstSlide')
        }
        else if (scrollPosition <= meat_height) {
            setLabel('Meats')
            setActive('Meat')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= poul_height) {
            setLabel('Poultry')
            setActive('Poultry')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= sea_height) {
            setLabel('Seafoods')
            setActive('Seafoods')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= eggs_height) {
            setLabel('Dairy & Eggs')
            setActive('Dairy & Eggs')
            setSlide('secondSlide')

        }
        else if (scrollPosition <= rice_height) {
            setLabel('Rice')
            setActive('Rice')
            setSlide('firstSlide')

        }
        else if (scrollPosition <= frozen_height) {
            setLabel('Frozen Food')
            setActive('Frozen Food')
            setSlide('secondSlide_last')

        }
        else if (scrollPosition <= desserts_height) {
            setLabel('Desserts')
            setActive('Desserts')
            setSlide('secondSlide_last')

        }

        else if (scrollPosition <= bev_height) {
            setLabel('Beverages')
            setActive('Beverages')
            setSlide('secondSlide_last')

        }
        else if (scrollPosition <= bundles_height) {
            setLabel('Bundles & Vouchers')
            setActive('Bundles & Vouchers')
            setSlide('secondSlide')

        }
        else if (scrollPosition <= herbs_height) {
            setLabel('Herbs & Spices')
            setActive('Herbs & Spices')
            setSlide('secondSlide')

        }
        else if (scrollPosition <= voucher_height) {
            setLabel('Voucher')
            setActive('Voucher')
            setSlide('secondSlide_last')

        }
        else if (scrollPosition <= more_height) {
            setLabel('More')
            setActive('More')
            setSlide('secondSlide_last')

        }



        return () => {
            window.removeEventListener('scroll', handleScroll);

        };
    }, [scrollPosition]);

    const historyBack = () => {
        window.history.back();
    }
    //toggle modal and body scroll
    const closeLoginModal = () => {
        setVisibleLogin("true");
        body.classList.remove("body_class");
        body.classList.remove("body_class_");
        localStorage.removeItem('notifyItem')
        dispatch({
            type: "SET_ITEM",
            value: {},
            login: true
        });
    }

    const showProds = () => {
        const nodeList = document.querySelectorAll(".list");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.remove("productList");
        };

        document.getElementById("showProds").style.display = 'none';
        document.getElementById("showLess").style.display = 'block';
    }
    const showLess = () => {
        const nodeList = document.querySelectorAll(".list");
        for (let i = 0; i < nodeList.length; i++) {
            nodeList[i].classList.add("productList");
        };
        document.getElementById("showProds").style.display = 'block';
        document.getElementById("showLess").style.display = 'none';
    }
    useEffect(() => {
        if (showModal) {
            dispatch(forgotModal(true))
        }
    }, [showModal])

    const onBlurSearch = (e) => {
        const payload = {
            user: user,
            changes: `Zagana || HomePage`,
            type: `seacrh_${e.target.value}`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
        searchToDatabase(e.target.value)
    }
    const onClickSeeAll = () => {
        dispatch({
            type: "SELECTED_CATEGORY",
            selected: []
        })
        window.scrollTo(0, 200)
        if (isMobile) {
            window.location.href = "/"
        }
    }

    return (
        <React.Fragment>
            <div className="w-100 m-0 p-0">
                <div className="w-100 m-0 p-0 bg-light wholeHeader" style={{ 'position': "fixed", 'zIndex': '2' }}>
                    {param?.categoryTag === undefined ?
                        <Header
                            branch={branch}
                            setBranch={setBranch}
                            term={term}
                            total={total}
                            setTerm={setTerm}
                            visibleLogin={visibleLogin}
                            setVisibleLogin={setVisibleLogin}
                            visibleCart={visibleCart}
                            setVisibleCart={setVisibleCart}
                            body={body}
                        />
                        :
                        <div className="container p-1 bgColorYellow">
                            <div className="row p-1">
                                <div className="col-2 text-center my-auto">
                                    <span id="arrowBack" onClick={historyBack} style={style.backBtn}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
                                        </svg>
                                    </span>
                                </div>
                                <div className="col-10 p-0">
                                    <span className="display-6">
                                        {param.categoryTag}
                                    </span>
                                </div>

                            </div>
                        </div>
                    }


                    {isMobile === 'true' || isMobile ?
                        <div className="input-group p-4 pt-1 pb-1 searchForm">
                            <div className="input-group-prepend ">
                                <span className="input-group-text" id="basic-addon1" style={{ borderRadius: "50px 0px 0px 50px", padding: '0.29rem 0.85rem' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Search product by name" style={{ borderRadius: "0px 50px 50px 0px", height: '30px', fontSize: "13px" }} value={term} onChange={(e) => {

                                setTerm(e.target.value);
                                if (source === 'FB') {
                                    SaveToDatabase({
                                        action: {
                                            type: 'Search',
                                            value: e.target.value
                                        }
                                    })
                                    ReactPixel.track('Search', { search_string: e.target.value })

                                } else if (source === 'Tiktok') {
                                    SaveToDatabase({
                                        action: {
                                            type: 'Search',
                                            value: e.target.value
                                        }
                                    })
                                    window.ttq.track('Search', { query: e.target.value })
                                }
                            }}
                                onBlur={onBlurSearch}
                            />
                            {term !== '' ? <button className="btn btn-primary-outline p-0" id="btn_clear" style={style.btnClear} onClick={clearSearch}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                </svg>
                            </button> : ''}

                            <div className="menuBtn"><img onClick={(e) => { setVisibleLinks(!visibleLinks) }} src="https://res.cloudinary.com/dw5axbz64/image/upload/v1663832692/icons/menu_lvwn2i.png" alt="menu" /> </div>
                        </div> : ''
                    }
                    {
                        ((window.location.pathname).includes("home")) &&
                        <button id="cartModalMobile" onClick={openCartModal} style={style.backBtn} className="d-none btn btn-transparent p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#f4cc0e" viewBox="0 0 197.64 175.87" stroke="#f4cc0e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px">
                                <g id="Layer_2" data-name="Layer 2"><g id="Layer_9" data-name="Layer 9"><path className="cls-1" d="M143.28,118.5,36.53,119.56a10,10,0,0,1-9.61-6.78l-21-61.57A16.25,16.25,0,0,1,21.25,29.72H168.38Z" /><line className="cls-1" x1="143.28" y1="116.28" x2="175.22" y2="6.14" /><circle className="cls-1" cx="49.36" cy="158.79" r="12.08" /><circle className="cls-1" cx="114.34" cy="158.79" r="12.08" /><path className="cls-1" d="M38,119.56l92.13-1.06c7.44,0,13.16,7.1,13.16,14.55h0a13.61,13.61,0,0,1-13.45,13.66H49.36" /><line className="cls-1" x1="175.6" y1="5" x2="192.64" y2="5" /></g></g></svg>

                            {
                                total.itemCount > 0 ?
                                    <div id="cartCount" style={{ display: "inline-block", borderRadius: "50%", background: "#24b14b", color: "white", width: "27px", position: "relative", top: "15px", right: "10px" }}>{total.itemCount}</div> : ''
                            }
                        </button>

                    }
                    {term === "" ? scrollPosition < 120 ? param?.categoryTag === undefined ?
                        <div id="firstContent">
                            <Carousel
                                imgSlide={imgSlide}
                            />
                            {isMobile === false ?
                                <LinkIcons />
                                :
                                null
                            }
                            {/* <SortBy options={items}
                                selected={selected}
                                onSelectedChange={setSelected}
                                label={label}
                                active={active}
                                slide={slide}
                                viewing={viewing}
                                setViewing={setViewing}
                            /> */}

                        </div>
                        : '' : '' : ''
                    }
                    <div>
                        {isMobile ? <div className={visibleLinks ? '' : 'd-none'}><LinkIcons /></div> : null}
                    </div>
                    {term === '' ?
                        <div>
                            <SortBy options={items}
                                selected={selected}
                                onSelectedChange={setSelected}
                                label={label}
                                active={active}
                                slide={slide}
                                viewing={viewing}
                                setViewing={setViewing}
                                refresh={setIsRefresh}
                                count={count}
                                setCount={setCount}
                                styles={!isMobile ? scrollPosition < 80 ? style.top80 : style.top20 : null}
                            />
                        </div>
                        :
                        null
                    }
                </div>
                <FeedBack />
                <ModalPolicy />
                <ModalUpdateInfo isLogged={isLogged} />

                <CartDetails
                    total={total}
                    visibleLogin={visibleLogin}
                    setVisibleLogin={setVisibleLogin}
                    setVisibleCart={setVisibleCart}
                />
                {/* <ConfirmContactModal
                    confirmContactModalStatus={confirmContactmodalStatus}
                    setConfirmContactModalStatus={setConfirmContactModalStatus}
                    itemNotify={itemNotify}
                    setItemNotify={setItemNotify}
                    userLoginNotify={userLoginNotify}
                    title={"login"}
                /> */}
                {!isLogged ?
                    <div>
                        <div id="LoginModal">
                            <div className={modalShow} tabIndex="-1" role="dialog">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-body p-0 pt-2 mb-3">
                                            <button className="btn text-left" style={style.closeBtn} onClick={closeLoginModal}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                                </svg>
                                            </button>
                                            <img src={loginLogo} alt="zagana.com" width="250px" className="mx-auto mt-3 mb-3 d-block" />
                                            {!showModal ?
                                                <Tabs
                                                    defaultActiveKey="login"
                                                    transition={false}
                                                    id="noanim-tab-example"
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="login" title="Login">
                                                        <Login

                                                            confirmContactmodalStatus={confirmContactmodalStatus}
                                                            // setConfirmContactModalStatus={setConfirmContactModalStatus}
                                                            itemNotify={itemNotify}
                                                            userLoginNotify={userLoginNotify}
                                                            setUserLoginNotify={setUserLoginNotify}
                                                            setItemNotify={setItemNotify}

                                                        />
                                                    </Tab>
                                                    <Tab eventKey="register" title="Register">
                                                        <Register />
                                                    </Tab>
                                                </Tabs>
                                                :
                                                <ForgotPass />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
                }
                <div className={`w-100 ${term === '' ? 'mt10' : 'mt5 p-2'} ${param?.categoryTag !== undefined ? "categBodyPage" : ""}`} >
                    <ItemListv2
                        options={isFiltered && term === '' ? category : items}
                        search={term}
                        total={total}
                        selectedCity={selectedCity}
                        branch={branch}
                    />
                    {/* <Search options={items} total={total} setTotal={setTotal} branch={branch}/> */}
                    {isFiltered ?
                        <div style={style.seeAll} onClick={onClickSeeAll}>See all products</div>
                        :
                        null
                    }
                </div>
                <Cart cartModalShow={cartModalShow} setCart={setCart} visibleCart={visibleCart} setVisibleLogin={setVisibleLogin} setVisibleCart={setVisibleCart} total={total} setTotal={setTotal} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} />
            </div>

        </React.Fragment >
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal,
        CartRedux: state.cart.CartList,
        category: state.Category.selectedCategory,
        notifyItem: state.notify.NotifyItem,
        notifyLogin: state.notify.VisibleLogin,
    }
}

export default connect(mapStateToProps)(Home);