
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ThankYou
 * File Directory           : src/components/Checkout/ThankYou
 * Module                   : Checkout Page
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To know that the order is complete
 * Used In				    : 
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ThankYou        -   component for Thank you Page 
 *
 * #How to call:
 *    <ThankYou />
 *
 *******************************************************************************************/

import React, { useState, useRef, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import Header from "../../Shared/Header";
import ZaganaLogo from '../../Shared/ZaganaLogo';
import OrderSuccess from '../../../assets/site_icons/OrderSuccess.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Xendit from "../Xendit";
import { storeFrontLogs } from '../Logs';
import Home from '../Home';
import ReactGA from 'react-ga4'
import { SaveToDatabase } from "../../Shared/SaveToDatabase";

const styles = {
    container: {
        height: "100vh",
        padding: "3rem"
    },
    headerBG: {
        'backgroundColor': '#facc02'
    }
}
function ThankYou({ id, paymongo, paymentDD, credit }) {
    let source = sessionStorage.getItem('source')||''
    let date = localStorage.getItem('date')||''
    if (source === 'FB') {
        SaveToDatabase({
            action: {
                type: 'pageView',
                value: {}
            }
        })
        ReactPixel.pageView();
    }
    document.title = "Zagana | Thank You"
    const custInfo = sessionStorage.getItem('Login')
    const parsedCustInfo = JSON.parse(custInfo)
    const pixelItems = localStorage.getItem('fbPixelItems')
    const parsedPixelItems = JSON.parse(pixelItems)
    const pixelContent = localStorage.getItem('fbPixelContent')
    const parsedPixelContent = JSON.parse(pixelContent)
    const totalAmount = sessionStorage.getItem("orderTotal") || 0
    const isMobile = localStorage.getItem("isMobile") === "true"
    const payment = sessionStorage.getItem('payment') === 'COD'
    const orderDetails = JSON.parse(sessionStorage.getItem('order')) || "";
    const orderID = orderDetails.check_id || "";
    const paymentMethod = orderDetails.paymentType;
    const transactionID = paymentDD.paymentDD;
    let accessKey = localStorage.getItem('accessKey')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        // alert(transactionID)
        const payload = {
            user: user,
            changes: `Zagana || Thank you page`,
            type: `view_thank_you_page`,
            date: `NOW()`
        }
        ReactGA.event({
            'category': 'Checkout',
            'action': 'Order Completed',
        });
        if (source === 'FB') {
            parsedPixelItems.push(id)
            let renderedItems = parsedPixelItems.slice(0).reverse().map((item, index) => {
                return item
            })
            SaveToDatabase({
                action: {
                    type: 'Purchase',
                    value: {
                        currency: "PHP",
                        content_ids: renderedItems,
                        content_type: 'product',
                        content: parsedPixelContent,
                        num_items: parsedPixelContent.length,
                        value: parseFloat(totalAmount), // your total transaction value goes here
                    }
                }
            })
            ReactPixel.track('Purchase', {
                currency: "PHP",
                content_ids: renderedItems,
                content_type: 'product',
                content: parsedPixelContent,
                num_items: parsedPixelContent.length,
                value: parseFloat(totalAmount), // your total transaction value goes here
            });
        }else if (source === 'Tiktok') {
            parsedPixelItems.push(id)
            let renderedItems = parsedPixelItems.slice(0).reverse().map((item, index) => {
                return item
            })
            SaveToDatabase({
                action: {
                    type: 'Purchase',
                    value: {
                        currency: "PHP",
                        content_ids: renderedItems,
                        content_type: 'product',
                        content: parsedPixelContent,
                        num_items: parsedPixelContent.length,
                        value: parseFloat(totalAmount), // your total transaction value goes here
                    }
                }
            })
            window.ttq.track('CompletePayment', {
                content_type: "product", 
                quantity: parsedPixelContent.length, 
                description: "Complete Transaction", 
                content_id: id, 
                currency: "PHP", 
                value: parseFloat(totalAmount) 
            }) 

        }
        console.log({
            currency: "PHP",
            content_ids: parsedPixelItems,
            content_type: 'product',
            content:parsedPixelContent,
            num_items: parsedPixelContent.length,
            value: parseFloat(totalAmount), // your total transaction value goes here
        })
        storeFrontLogs(payload)

        const orderSucceeded = async () => {
            await Xendit.post(`/succeeded`, { checkID: orderID, paymentDD: transactionID, paymentMethod: paymentMethod, accessKey: accessKey })
                .then(response => {
                    if (response.data.status === "COMPLETED") {

                        localStorage.removeItem('skip')
                    }

                })
                .catch(error => {
                    console.log(error)
                })
        }
        orderSucceeded()
        const deductCredit = async () => {
            const details = { id: getUser.customer_id, credit: credit.credit }
            try {
                await Home.post(`/storeCredit/deduct`, details)

            } catch (err) {
                console.log(err)
            }
        }
        if (credit.isApplied) {
            deductCredit()
        }
    }, []);
    return (
        <React.Fragment>
            {!paymongo ?
                isMobile ?
                    <Header title="Thank You" />
                    : <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                        <div className="row">

                            <div className="col-2 d-none d-sm-block text-center">
                                <a href="/">
                                    <ZaganaLogo />
                                </a>
                            </div>
                        </div>
                    </div>

                :
                null
            }
            <div className="container mt-5" id="thankYou_con" style={styles.container}>
                <div className="row align-items-center" >
                    <center>
                        <img src={OrderSuccess} alt="Thank you Icon" width="100%" />
                        <br />
                        <p><strong>Yay! It's Done</strong> </p>
                        <p>Transaction Successful</p>
                        <p>Your Order is <b># {id}</b></p>
                        <p>We'll process your goods immediately.</p>
                    </center>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return ({
        id: state.OrderID.order_id,
        paymentDD: state.XenditValidateOTP,
        credit: state.StoreCredit
    })
}

export default connect(mapStateToProps)(ThankYou);