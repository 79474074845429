
import React, { useState, useEffect } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import Login from '../Login/Login';
import Register from '../Login/Register';
import loginLogo from "../../assets/logo/zagana-logo.png";
import { connect, useDispatch } from "react-redux";
import { forgotModal } from "../../actions";
import ForgotPass from "../Login/ForgotPass";
const styles = {
    indentedText: {
        paddingLeft: "9%"
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed',
        'zIndex': '2'
    },
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'left'
    }
}

const LoginModal = ({ showModal, modalToggle, notifyItem, notifyLogin }) => {
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    const dispatch = useDispatch();
    const modalShowed = modalToggle
    const [confirmContactmodalStatus, setConfirmContactModalStatus] = useState(false)
    const [itemNotify, setItemNotify] = useState([])
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal


    let body = document.body; // for setting body height (modal)
    //toggle modal and body scroll
    const closeLoginModal = () => {
        let element = document.getElementById("modalLoginID");
        element.classList.add("showModal");
        body.classList.remove("body_class");
        body.classList.remove("body_class_");
        localStorage.setItem('notifyItem', JSON.stringify({
        }))
        setVisibleLogin("true");
        localStorage.removeItem('notifyItem')
        dispatch({
            type: "SET_ITEM",
            value: {},
            login: true
        });
    }



    return (
        <React.Fragment>

            {!isLogged ?
                <div>
                    <div id="LoginModal">
                        <div className={modalShowed} tabIndex="-1" role="dialog" id="modalLoginID" >
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-body p-0 pt-2 mb-3">

                                        <button className="btn text-left" style={styles.closeBtn} onClick={closeLoginModal} >

                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                            </svg>
                                        </button>
                                        <img src={loginLogo} alt="zagana.com" width="250px" className="mx-auto mt-3 mb-3 d-block" />
                                        {!showModal ?
                                            <Tabs
                                                defaultActiveKey="login"
                                                transition={false}
                                                id="noanim-tab-example"
                                                className="mb-3"
                                            >
                                                <Tab eventKey="login" title="Login">
                                                    <Login
                                                        setConfirmContactModalStatus={setConfirmContactModalStatus}
                                                        setItemNotify={setItemNotify} />
                                                </Tab>
                                                <Tab eventKey="register" title="Register">
                                                    <Register />
                                                </Tab>
                                            </Tabs>
                                            :
                                            <ForgotPass />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal,
        notifyItem: state.notify.NotifyItem,
        notifyLogin: state.notify.VisibleLogin
    }
}

export default connect(mapStateToProps)(LoginModal);