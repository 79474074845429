import React from "react";
import { connect, useDispatch } from "react-redux";
import { forgotModal } from "../../actions";
import { reduxForm, Field } from "redux-form";
import { useHistory } from "react-router-dom";
import Header from "../Shared/Header";
import Home from "../APIs/Home";

const style = {
    button: {
        fontSize: ".8rem",
    },
    container: {
        padding: '12vh 0'
    },
    submit: {
        borderRadius: '20px'
    },
    center:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    }
}

const renderInput = ({
    input,
    type,
    meta,
    value_name,
    class_name,
    value_check,
}) => {
    const className = `field ${meta.error && meta.touched ? "error" : ""} ${!meta.error ? "valid" : ""
        }`;
    return (
        <React.Fragment>
            <div 
                className={className}
                style={style.center}
            >
                <input
                    {...input}
                    type={type}
                    value={value_name}
                    autoComplete="off"
                    className={class_name}
                    checked={value_check}
                    placeholder="Email"
                />
            </div>
            <div className="form-control-feedback text-center">{renderError(meta)}</div>
        </React.Fragment>
    );
};
const renderError = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span className="error" >
                    {error}
                </span>
            </div>
        );
    }
};

const validate = (values) => {
    const errors = {};
    var emailCheck =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (!values.customerEmail) {
        errors.customerEmail = "You must enter your Email";
    }
    if (values.customerEmail) {
        if (emailCheck.test(values.customerEmail) === false) {
            errors.customerEmail = "Invalid Email Format";
        }
    }

    return errors;
};



const ForgotPass = ({ email, display }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const dispatch = useDispatch()
    const history = useHistory()

    const onClickBack = () => {
        if (isMobile) {
            history.push('/login')
        } else {
            dispatch(forgotModal(false))
        }
    }
    const onSubmitEmail = async () => {
        const userEmail = email.values.customerEmail
        try {
            const response = await Home.post(`/forgotpassword`, { email: userEmail })
            console.log(response.data)
            if (response.data.cust_names) {
                dispatch({
                    type: 'TIME_ERROR',
                    error: false,
                    errorMsg: 'Email sent, Please check your email'
                })
                setTimeout(() => {
                    // dispatch({
                    //     type: 'TIME_ERROR',
                    //     error: false,
                    //     errorMsg: ""
                    // })
                }, 5000)
            } else if (response.data.Error) {
                dispatch({
                    type: 'TIME_ERROR',
                    error: true,
                    errorMsg: "Email doesn't exist"
                })
                // setTimeout(() => {
                //     dispatch({
                //         type: 'TIME_ERROR',
                //         error: false,
                //         errorMsg: ""
                //     })
                // }, 1000)
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <React.Fragment>
            <div className="p-0">
                {isMobile ?
                    <Header title="Forgot Password" />
                    :
                    null
                }

                <div className="container mt-3" style={isMobile ? style.container : null}>
                    <div className="col-12 text-center">
                        <h5 className="text-center">RESET YOUR PASSWORD</h5>
                    </div>
                    <div className="col-12">
                        <Field
                            type="email"
                            name="customerEmail"
                            component={renderInput}
                            class_name="form-control w-75"
                        />
                    </div>
                    {display.error ?
                        <div className="col-12 text-center">
                            <span className="text-center error">{display.errorMsg}</span>
                        </div>
                        :
                        <div className="col-12 text-center">
                            <span className="text-center text-success">{display.errorMsg}</span>
                        </div>
                    }
                    <div className="col-12">
                        <div className="col mt-2" style={style.center}>
                            <button className="btn w-25 btn-primary"
                                disabled={display.errorMsg === 'Email sent, Please check your email'}
                                onClick={onSubmitEmail}
                                style={style.submit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                    <div className="col-12 text-center">
                        <div className="col">
                        <span className="btn" onClick={onClickBack}>Back to login</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )

}


const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal,
        email: state.form.Forgot,
        display: state.time
    }
}
export default connect(mapStateToProps)(reduxForm({
    form: "Forgot",
    validate,
})(ForgotPass));