/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : Voucher
 * File Directory           : src/components/Checkout/
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : select or apply voucher 
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Address        -   component for applying referral code
 *
 * #How to call:
 *    /add-referral
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../Shared/Header";
import Home from "../APIs/Home";
import { storeFrontLogs } from "../APIs/Logs";

const style = {
    fontSize: {
        fontSize: "14px",
        backgroundColor: '#24b14b',
        borderColor: '#24b14b'
    },
    valid:{
        color : "#24b14b"
    }
}

const Referral = ({ total }) => {
    const customerID = sessionStorage.getItem('Login') || null
    const parseCustomerID = JSON.parse(customerID) || []
    const user_id = parseCustomerID.customer_id
    const [error, setError] = useState(true)
    const [referralApplied, setReferralApplied] = useState({})
    const [errorMsg, setErrorMsg] = useState('')
    const [referral, setReferral] = useState('')
    const [isApplied, setIsApplied] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const isMobile = localStorage.getItem("isMobile") === "true"
        let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
  
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    }else if(gen_id){
        user = gen_id
    }

    useEffect(() => {
        const body = {
            amount: total,
            voucher_code: referral,
            user_id: user_id
        }

        const getAppliedReferral = async () => {
            try {
                const response = await Home.post(`/add-voucher-secret`, body)
                setReferralApplied(response.data.result)
                setError(response.data.result.Error)
                setErrorMsg(response.data.result.Error)

            } catch (err) {
                console.log(err)
            }
        }

        const timeOutId = setTimeout(() => {
            if (referral) {
                getAppliedReferral()
            }
        }, 550)
        return () => {
            clearTimeout(timeOutId)
        };
    }, [referral, total, user_id])

    const onClickApply = () => {
        dispatch({
            type: "ADD_SECRET_DISCOUNT",
            code: referralApplied.disc_code,
            amount: referralApplied.disc_amount,
            discount: true
        })
        const payload = {
            user: user,
            changes: `Zagana || Checkout`,
            type: 'click_apply_referral',
            date: `NOW()`
        }
        storeFrontLogs(payload)
        setIsApplied(true)
        setTimeout(()=>{
            setError(true)
            setErrorMsg('')
        },2500)
        // if (isMobile) {
        //     history.push('/checkout-details')
        // }
    }
    const onKeyUp = (e) => {
        return (
            setReferral(e.target.value)
        )
    }
    return (
        <React.Fragment>
            {/* {isMobile ?
                <Header title="Referral" />
                : null
            } */}
            <div className="container mt-2 mb-3">
                <div className="row">
                    <div className="col-9">
                        <input
                            type="text"
                            className="w-100 form-control"
                            placeholder="Enter Referral Code.."
                            onKeyUp={onKeyUp}

                        />
                    </div>
                    <div className="col-3 ">
                        <button
                            id="apply_referral"
                            className="btn btn-success p-1"
                            style={style.fontSize}
                            onClick={onClickApply}
                            disabled={error}
                        >
                            apply
                        </button>
                    </div>
                </div>
                <div className="row mb-3">
                    {error ?
                        <span className="error">
                            {errorMsg}
                        </span>
                        :
                        null
                    }
                </div>
                {isApplied ?
                    <span style={style.valid}>
                        Referral applied
                    </span>
                    :
                    null
                }

            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        total: state.total.total
    }
};
export default connect(mapStateToProps)(Referral);

