import React, { useState, useEffect } from "react"
import { connect, useDispatch } from "react-redux";
import { Modal, Button } from "react-bootstrap";

import ErrorModal from "./ErrorModal";

import PaymentMethod from "../Checkout/PaymentMethod";
import { useHistory } from "react-router-dom";

import Paymongo from 'paymongo';

import Backend from "../APIs/Backend";

import axios from 'axios';

import { getOrderID } from "../../actions"
import Home from "../APIs/Home";
import { initialCustInfo } from "../../actions";
import ReactGA from "react-ga4"

const MyVerticallyCenteredModal = (props) => {
    const { show } = props
    return (
        <Modal
            className="payment-modal"
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Payment Method
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PaymentMethod modalClick={show} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
}




const PaymentModal = ({ payment, id, selectedPayment, orderTotal, orderID, orderNumber, orderDiscountName, orderItem }) => {
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [cart, setCart] = useState([]);// cart for desktop
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const merchant_code = process.env.REACT_APP_BILLEASE_MERCHANT_CODE // Billease Merchant Code
    const shop_code = process.env.REACT_APP_BILLEASE_SHOP_CODE // Billease Shop Code
    const token = process.env.REACT_APP_BILLEASE_TOKEN // Billease Bearer Token
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    // const orderCart = JSON.parse(orderItem)
    const history = useHistory()
    const dispatch = useDispatch()

    // console.log(orderCart);
    // console.log(orderDiscountName);
    // console.log(payment);

    useEffect(() => {
        const getCustomerInfo = async () => {
            try {
                const response = await Home.post(`/customerInfo`, { user_id: getUser.customer_id })
                dispatch(initialCustInfo(response.data[0]))
            } catch (err) {
                console.log(err)
            }
        }
        getCustomerInfo()
    }, [])

    const onHide = () => {
        setModalShow(false)
    }

    const onClickModal = (e) => {
        //show modal
        setModalShow(true)
        //update payment method on redux based on database
        dispatch({
            type: "SELECTED_PAYMENT",
            selected: payment,
            status: true
        })
        //update session storage payment
        sessionStorage.setItem('payment', payment)
        sessionStorage.setItem('orderTotal', e.target.dataset.ordertotal)
        sessionStorage.setItem('order', JSON.stringify({ check_id: e.target.dataset.order_id, job_order: e.target.id }))
    }

    const onConfirm = async() => {
        sessionStorage.setItem('orderTotal', orderTotal)
        let type = ''
        if (selectedPayment === 'GCash') {
            type = 'gcash'
        } else {
            type = 'grab_pay'
        }
        const doubleTotalAmount = orderTotal * 100
        //data for paymongo payment (Gcash/Grabpay)
        const data = {
            data: {
                attributes: {
                    type: type,
                    amount: parseInt(doubleTotalAmount),
                    currency: 'PHP',
                    redirect: {
                        success: window.location.origin + '/success',
                        failed: window.location.origin + '/failed'
                    }
                }
            }
        }

        if (payment !== selectedPayment) {
            const payload = { checkID: orderID, payment : selectedPayment }
            const response = await Home.post("/changePayment", payload)
            console.log(response)
        }

        const getPaymentIntentID = async () => {
            ReactGA.event({
                category: "Payment Payment Modal",
                action: "Place order",
                label: orderNumber
            })
            if (selectedPayment === 'Debit/Credit') {
                history.push('/paymongo/debit-credit')
            } else if (selectedPayment === 'COD') {
                const payload = { id: orderID }
                const response = await Home.post("/updateStatus", payload)
                if (response.status === 200) {
                    history.push('/thank-you')
                }
            } else if (selectedPayment === 'GCash') {
                const result = await paymongo.sources.create(data);
                const details = {
                    id: result.data.id,
                    redirect: result.data.attributes.redirect.checkout_url
                }
                const stringifyData = JSON.stringify(details)
                sessionStorage.setItem('paymongo', stringifyData)
                window.location.href = details.redirect

            } else if (selectedPayment === 'Grabpay') {
                // console.log("Pasok")
                const result = await paymongo.sources.create(data);
                // console.log(result)
                const details = {
                    id: result.data.id,
                    redirect: result.data.attributes.redirect.checkout_url
                }
                const stringifyData = JSON.stringify(details)
                sessionStorage.setItem('paymongo', stringifyData)
                window.location.href = details.redirect
            } else if (selectedPayment === 'LandBank') {
                history.push('/landbank-form')
            } else if (selectedPayment === 'UB') {
                dispatch(getOrderID(id))
                history.push('/xendit/direct-debit')
            } else if (selectedPayment === 'BPI') {
                dispatch(getOrderID(id))
                history.push('/xendit/direct-debit')
            } else if (selectedPayment === 'Paymaya') {
                let auth = process.env.REACT_APP_PAYMAYA
                const options = {
                    method: 'POST',
                    url: 'https://pg.paymaya.com/payby/v2/paymaya/payments',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: auth
                    },
                    data: {
                        totalAmount: { value: orderTotal, currency: 'PHP' },
                        redirectUrl: {
                            success: window.location.origin + '/thank-you',
                            failure: window.location.origin + '/user-profile',
                            cancel: window.location.origin + '/user-profile'
                        },
                        metadata: {
                            pf: {
                                smi: '101065221',
                                smn: 'Zagana Inc.',
                                mci: 'Quezon City',
                                mpc: '608',
                                mco: 'PHL'
                            }
                        },
                        requestReferenceNumber: id
                    }
                };

                axios.request(options).then(function (response) {
                    const url = response.data.redirectUrl
                    if (response.status === 200) {
                        const details = {
                            paymentId: response.data.paymentId,
                            checkID: orderNumber,
                            paymentMethod: 'Paymaya'
                        }
                        const savePaymentID = async () => {
                            try {
                                const res = await Backend.post(`/Paymayav2/paymentID`, details)
                                if (res.status === 200) {
                                    window.location.href = url
                                }
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        savePaymentID()
                    }
                }).catch(function (error) {
                    console.error(error);
                });
            }
            // } else if (payment === "Billease") { // Billease Payment method 
            //     let customerName = getUser.customer_name // customer name
            //     let customerEmail = getUser.customer_email // customer email
            //     let customerPhone = getUser.customer_phone // customer phone number

            //     const renderCart = orderCart.map( item => { // render cart items
            //         // item varialbles
            //         let itemSku = item.item_sku
            //         let itemName = item.item_name
            //         let itemPrice = item.cart_price
            //         let itemQty = item.cart_quantity

            //         // Cart item details
            //         const item_details = { 
            //             code: itemSku,
            //             item: itemName,
            //             price: parseFloat(itemPrice),
            //             quantity: itemQty,
            //             currency: "PHP",
            //             seller_code: "ZGN"
            //         }
            //         return item_details
            //     })

            //     const deliveryFee = { // add Delivery fee in item (defautlt)
            //         code: "Shipping Fee",
            //         item: "Delivery Fee",
            //         price: 79,
            //         quantity: 1,
            //         currency: "PHP",
            //         seller_code: "ZGN",
            //         item_type: "fee"
            //     }
            //     renderCart.push(deliveryFee) // push delivery fee to item

            //     // loop
            //     const discountArray = orderDiscountName.split("+");
            //     console.log(orderDiscountName);
            //     discountArray.map( discount => {
            //         if(orderDiscountName){
            //             let discountSplitter = discount.split("(")
            //             let discountAmountCleaner = discountSplitter[1].replace(")","")
            //             let discountAmount = parseFloat(discountAmountCleaner)
            //             let discountClean = [discountSplitter[0], discountAmount]
            //             let feeTitle = "Voucher"
            //             let discountName=discountClean[0].toLowerCase()
            //             setTotalDiscount(totalDiscount+discountAmount)

            //             if(discountName.includes("freedel"))
            //                 feeTitle=`FREE DEL`
            //             else if(discountName.includes("storecred"))
            //                 feeTitle=`STORECREDIT`
            //             else 
            //                 feeTitle=`Voucher`
            //             return renderCart.push( { // add free delivery deduction in item
            //                 code: discountClean[0],
            //                 item: `${feeTitle}: ${discountClean[0]}`,
            //                 price: parseFloat(-discountClean[1]),
            //                 quantity: 1,
            //                 currency: "PHP",
            //                 seller_code: "ZGN",
            //                 item_type: "fee"
            //             })
            //         }else{
            //             return null
            //         }
            //     })


            //     const body = { // Payloads
            //         "merchant_code": merchant_code, // merchant code
            //         "shop_code": shop_code, // shop code
            //         "amount": parseFloat(orderTotal), // total amount === items total amount
            //         "currency": "PHP", 
            //         "checkout_type": "standard",
            //         "seller_code": "ZGN",
            //         "items": renderCart, // items list
            //         "sellers": [ // seller details
            //             {
            //                 "code": "ZGN",
            //                 "seller_name": "Zagana inc.",
            //                 "url": "http://zagana.com"
            //             }
            //         ],
            //         "customer": { // customer details
            //             "full_name": customerName,
            //             "email": customerEmail,
            //             "phone": customerPhone,
            //             "internal_user_id": customerEmail,
            //         },
            //         "callbackapi_url": `https://staging-api.zagana.com/api/billease/trx/webhook`,
            //         // "callbackapi_url": `${process.env.REACT_APP_ZAGANA_URL}api/billease/trx/webhook`,
            //         "url_redirect": "https://staging.zagana.com/thank-you",
            //         "failed_url_redirect": "https://staging.zagana.com/user-profile",
            //         "order_id": orderID
            //     }
            //     const options = { // axios options
            //         "method": 'POST',
            //         "url": 'https://trx-test.billease.ph/be-transactions-api/trx/checkout',
            //         "headers": {
            //             "Content-Type": 'application/json',
            //             "Authorization": `bearer ${token}`
            //         },
            //         "data": body
            //     };
            //     if (orderTotal > 150 && parseFloat(orderTotal+totalDiscount) > 150 ) { // minimum of 150php to make transaction condition
            //         axios.request(options).then(function (response) { // connection to Billease
            //             const url = response.data.redirect_url // url 
            //             if (response.status === 200) { 
            //                 // variables
            //                 let paymentID = response.data.trxid
            //                 let checkID = orderID
            //                 const details = { // payment details
            //                     paymentId: paymentID,
            //                     checkID: checkID,
            //                     paymentMethod: "Billease"
            //                 }
            //                 const savePaymentID = async () => { // save payment ID 
            //                     try {
            //                         const res = await Backend.post(`/Paymaya/paymentID`, details)
            //                         if (res.status === 200) { // redirect to billease payment
            //                                 window.location.href = url     
            //                         }
            //                     } catch (err) {
            //                         console.log(err)  // log error
            //                     }
            //                 }
            //                 savePaymentID() // run save payment
            //             }
            //         }).catch(function (error) {
            //                 setErrorMsg(error.message) // error message
            //                 setError(true) //show error message
            //                 setTimeout(() => {
            //                     setError(false) // hide error message
            //                 }, 1200)
            //         });
            //     } else{ // minimum value error 
            //         setErrorMsg(`Sorry, A minimum value of 150php in Subtotal and Total Payment to proceed Transaction`) // error message
            //         setError(true) //show error message
            //         setTimeout(() => {
            //             setError(false) // hide error message
            //         }, 2000)
            //     }
            // }
        }
        getPaymentIntentID()
        setModalShow(false)
    }



    return (
        <React.Fragment>
            <Button
                variant="primary"
                id={id}
                data-ordertotal={orderTotal}
                data-payment={payment}
                data-order_id={orderID}
                onClick={onClickModal}
            >
                Pay Order
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={onHide}
                onConfirm={onConfirm}
            />
            <ErrorModal btn={error} body={errorMsg} />
        </React.Fragment>
    );

}

const mapStateToProps = (state) => {
    return {
        selectedPayment: state.payment.paymentSelected
    }
}

export default connect(mapStateToProps)(PaymentModal);