/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : CheckoutDetails
 * File Directory           : src/components/Checkout/
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : view checkoit details 
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DebitCredit        -   component for Debit/Credit input details
 *
 * #How to call:
 *    /paymongo/debit-credit
 *
 *******************************************************************************************/
import React, { useState } from "react";
import { connect } from "react-redux";

import { reduxForm, Field } from 'redux-form';
import { useHistory } from "react-router-dom";

import Paymongo from 'paymongo';
import paymongLogo from "../../../assets/logo/paymongo.png"

import Backend from "../../APIs/Backend";

const style = {
    error: {
        color: 'red'
    },
    valid: {
        color: '#24b14b'
    },
    width: {
        width: '60%'
    }
}

//render component for redux-form
const renderInput = ({
    input,
    type,
    meta,
    value_name,
    class_name,
    placeholder,
    label,
    pattern,
    style
}) => {
    const className = `field ${meta.error && meta.touched ? 'error' : ''} ${!meta.error ? 'valid' : ''}`;
    return (
        <React.Fragment>
            <div className="row">
                <div className="col">
                    {label === undefined ?
                        ''
                        :

                        <label
                            htmlFor={label}
                            className="col-form-label"
                        >
                            {label} &nbsp;
                            <div className="d-inline-block"> {errorIcon(meta)}</div>
                        </label>

                    }

                </div>
            </div>

            <div className={className}>
                <input
                    {...input}

                    type={type}
                    value={value_name}
                    pattern={pattern}
                    autoComplete="off"
                    className={class_name}
                    placeholder={placeholder}
                    style={style}
                />
                {label === undefined ?  <div className="d-inline-block"> {errorIcon(meta)}</div> : ''}
                <div className="form-control-feedback">{errorMsg(meta)}</div>
            </div>

        </React.Fragment>
    );
};

//rendering error message
const errorMsg = ({ error, touched }) => {
    if (touched && error) {
        return (
            <span className="error" >{error}</span>
        )
    }
}

//rendering error/valid icon
const errorIcon = ({ error, touched }) => {
    if (touched && error) {
        return (
            <div>
                <span style={style.error}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                    </svg>
                </span>
            </div>
        );
    }
    else if (!error) {
        return (
            <div>
                <span style={style.valid}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check-circle" viewBox="0 0 16 16">
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                    </svg>
                </span>
            </div>
        );
    }
}

//declaring input errors
const validate = values => {
    const errors = {};
    var emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    if (!values.customerEmail) {
        errors.customerEmail = 'Please enter your email';
    }
    if (values.customerEmail) {
        if (emailCheck.test(values.customerEmail) === false) {
            errors.customerEmail = 'Invalid Email Format';
        }
    }
    if (!values.cardName) {
        errors.cardName = 'Please enter your card name';
    }
    if (!values.cardNumber) {
        errors.cardNumber = 'Please enter your card number';
    }
    if (!values.Month) {
        errors.Month = true;
    }
    if (values.Month) {
        if (values.Month.length > 2) {
            errors.Month = true
        }
        if (values.Month > 12 || values.Month < 1) {
            errors.Month = true
        }
    }
    if (!values.Year) {
        errors.Year = true;
    }
    if (values.Year) {
        if (values.Year.length > 2) {
            errors.Year = true
        }
    }
    if (!values.CVCInput) {
        errors.CVCInput = true;
    }
    return errors;
};

//main component
const DebitCredit = ({ valid, details }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const paymongo = new Paymongo(process.env.REACT_APP_PAYMONGO_SECRET_KEY);
    const orderTotal = sessionStorage.getItem('orderTotal')
    const [errorDetails, setErrorDetails] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const history = useHistory()
    const orderDetails = JSON.parse(sessionStorage.getItem('order'))
    const handleSubmit = async (e) => {
        e.preventDefault()
        const value = details.values
        const payload = {
            data: {
                attributes: {
                    type: 'card',
                    details: {
                        card_number: `${value.cardNumber}`,
                        exp_month: parseInt(value.Month),
                        exp_year: parseInt(value.Year),
                        cvc: `${value.CVCInput}`
                    }
                }
            }
        }
        const doubleTotalAmount = orderTotal * 100
        const data = {
            data: {
                attributes: {
                    amount: parseInt(doubleTotalAmount),
                    currency: 'PHP',
                    payment_method_allowed: ['card'],
                    description:'ZAGANA ORDER ' + orderDetails.job_order 
                }
            }
        }

        try {
            //creating payment method for Debit/Credit
            const response = await paymongo.paymentMethods.create(payload);
            //payment method id
            const id = response.data.id
            //creating payment intents
            const result = await paymongo.paymentIntents.create(data);
            //data for attaching payment intents id to payment method
            const details = {
                data: {
                    attributes: {
                        payment_method: id
                    }
                }
            }
            const results = await paymongo.paymentIntents.attach(result.data.id, details);

            //conditions depending to payment status
            const paymentStatus = results.data.attributes.status
            if (paymentStatus === "awaiting_next_action") {
                const details = {
                    paymentId: result.data.id,
                    checkID: orderDetails.check_id,
                    paymentMethod: "Debit/Credit"
                }
                const savePaymentID = async () => {
                    try {
                        const res = await Backend.post(`/Paymayav2/paymentID`, details)
                        if (res.status === 200) {
                            const data = {
                                id: result.data.id,
                                redirect: results.data.attributes.next_action.redirect.url
                            }
                            const stringifyData = JSON.stringify(data)
                            sessionStorage.setItem('paymongo', stringifyData)
                            history.push('/paymongo/debit-credit/next-action')
                        }else{
                            savePaymentID()
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
                savePaymentID()
            } else if (paymentStatus === "succeeded") {
                
                const details = {
                    paymentId: result.data.id,
                    checkID: orderDetails.check_id,
                    paymentMethod: "Debit/Credit"
                }
                const savePaymentID = async () => {
                    try {
                        const res = await Backend.post(`/Paymayav2/paymentID`, details)
                        if (res.status === 200) {
                            const data = {
                                id: result.data.id,
                            }
                            const stringifyData = JSON.stringify(data)
                            sessionStorage.setItem('paymongo', stringifyData)
                            history.push('/success')
                        }else{
                            savePaymentID()
                        }
                    } catch (err) {
                        console.log(err)
                    }
                }
                savePaymentID()
            }

        } catch (err) {
            console.log(err)
            if (err.detail === "details.exp_year must be at least this year or no later than 50 years from now." && err.code === "parameter_invalid") {
                setErrorMsg('Year should be atleast this year or no later than 50 years from now')
                setErrorDetails(true)
            } else if (err.detail === "details.card_number format is invalid." && err.code === "parameter_format_invalid") {
                setErrorMsg('Card Number Format is invalid')
                setErrorDetails(true)
            } else if (err.detail === "details.cvc should be numeric." && err.code === "parameter_format_invalid") {
                setErrorMsg('CVC should be numeric')
                setErrorDetails(true)
            } else if (err.detail === "The value for details.cvc cannot be more than 3 characters." && err.code === "parameter_above_maximum") {
                setErrorMsg('CVC cannot be more than 3 characters.')
                setErrorDetails(true)
            }
            if (err.sub_code === "card_expired") {
                setErrorMsg("Please enter a different card or choose another payment method")
                setErrorDetails(true)
            }
            else if (err.sub_code === "cvc_invalid") {
                setErrorMsg("Correct the CVC/CVN before re-submitting")
                setErrorDetails(true)
            }
            else if (err.sub_code === "generic_decline") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "fraudalent") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "insufficient_funds") {
                setErrorMsg("Please enter a different card or choose another payment method")
                setErrorDetails(true)
            }
            else if (err.sub_code === "processor_blocked") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "lost_card") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "stolen_card") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
            }
            else if (err.sub_code === "processor_unavailable") {
                setErrorMsg("Kindly wait for a few minutes before re-trying or you may enter a different card or choose another payment method")
                setErrorDetails(true)
            }
            else if (err.sub_code === "blocked") {
                setErrorMsg("Please contact your card issuing bank for further details")
                setErrorDetails(true)
                
            }else if (err.sub_code === "acquirer_declined" ){
                setErrorMsg("The card type used is not allowed. Please use another card or try a different payment method.")
                setErrorDetails(true)
            }
        }

    }

    return (
        <React.Fragment>
            {isMobile ?
                <React.Fragment>
                    <div className="container mt-2 mb-5">
                        <div className="row">
                            <div className="col">
                                <img
                                    className="img img-fluid p-5 pb-2 pt-2"
                                    src={paymongLogo}
                                    alt="paymongo_logo"
                                />
                            </div>
                        </div>
                        {errorDetails ?
                            <div className="alert alert-danger p-2" role="alert">
                                <span>{errorMsg}</span>
                            </div>
                            :
                            ''
                        }
                        <form onSubmit={handleSubmit} className="p-2">
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Email"
                                        type="email"
                                        name="customerEmail"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Card Name"
                                        type="cardName"
                                        name="cardName"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Card Number"
                                        type="number"
                                        name="cardNumber"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row p-0">
                                        <label
                                            htmlFor="Expiry"
                                            className="col-form-label"
                                        >
                                            Expiry
                                        </label>
                                        <div className="col-6">
                                            <Field
                                                type="number"
                                                name="Month"
                                                class_name="form-control d-inline-block"
                                                component={renderInput}
                                                placeholder="MM"
                                                maxLength="2"
                                                pattern="[0-9]"
                                                style={style.width}
                                            />
                                        </div>
                                        /
                                        <div className="col-5 p-0">
                                            <Field
                                                type="number"
                                                name="Year"
                                                class_name="form-control d-inline-block"
                                                component={renderInput}
                                                placeholder="YY"
                                                pattern="[0-9]"
                                                style={style.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <Field
                                        label="CVC"
                                        name="CVCInput"
                                        class_name="form-control"
                                        component={renderInput}
                                        placeholder="CVC"
                                    />
                                </div>

                            </div>
                            <div className="row pb-5 pt-5">
                                <div className="col">
                                    <button type="submit" className={`btn w-100  ${!valid ? 'disabled' : 'btn-primary'} `} disabled={!valid} id="btnRegister">Submit</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </React.Fragment>
                :
                <div className="container p-5">
                    <div className="justify-content-center w-25 m-auto">
                        <div className="row">
                            <div className="col">
                                <img
                                    className="img img-fluid p-5 pb-2 pt-2"
                                    src={paymongLogo}
                                    alt="paymongo_logo"
                                />
                            </div>
                        </div>
                        {errorDetails ?
                            <div className="alert alert-danger p-2" role="alert">
                                <span>{errorMsg}</span>
                            </div>
                            :
                            ''
                        }
                        <form onSubmit={handleSubmit} className="p-2">
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Email"
                                        type="email"
                                        name="customerEmail"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Card Name"
                                        type="cardName"
                                        name="cardName"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Field
                                        label="Card Number"
                                        type="number"
                                        name="cardNumber"
                                        class_name="form-control"
                                        component={renderInput}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="row p-0">
                                        <label
                                            htmlFor="Expiry"
                                            className="col-form-label"
                                        >
                                            Expiry
                                        </label>
                                        <div className="col-6" >
                                            <Field
                                                type="number"
                                                name="Month"
                                                class_name="form-control d-inline-block"
                                                component={renderInput}
                                                placeholder="MM"
                                                maxLength="2"
                                                pattern="[0-9]"
                                                style={style.width}
                                            />
                                        </div>
                                        /
                                        <div className="col-5 p-0" >
                                            <Field
                                                type="number"
                                                name="Year"
                                                class_name="form-control d-inline-block"
                                                component={renderInput}
                                                placeholder="YY"
                                                pattern="[0-9]"
                                                style={style.width}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <Field
                                        label="CVC"
                                        name="CVCInput"
                                        class_name="form-control"
                                        component={renderInput}
                                        placeholder="CVC"
                                    />
                                </div>

                            </div>
                            <div className="row pt-4">
                                <div className="col">
                                    <button type="submit" className={`btn w-100  ${!valid ? 'disabled' : 'btn-primary'} `} disabled={!valid} id="btnRegister">Submit</button>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        details: state.form.Paymongo,
    }
}
export default connect(mapStateToProps)
    (reduxForm({
        form: 'Paymongo',
        validate
    })(DebitCredit));