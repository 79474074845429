const INTIAL_STATE = {
    error: false,
    errorMsg: ''
};

const TimeReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'TIME_ERROR':
            return {
                ...state, 
                error: action.error,
                errorMsg:action.errorMsg
            };
        default:
            return state;
    }

};

export default TimeReducer;
