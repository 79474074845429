/*******************************************************************************************
//  * File Name                : ModalPromo
//  * File Directory           : src/components/Home/ModalPromo
//  * Module                   : ModalPromo
//  * Author                   : Jake
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : ItemList
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ModalPromo        -   component for pop up item details
 *
 * #How to call:
 *    <ModalPromo/>
 *
 * Note:
 *******************************************************************************************/
 import React, { useState, useRef, useEffect } from "react";
 import UseOutsideClick from "../Shared/UseOutsideClick";
 import ReactPixel from 'react-facebook-pixel';
 
 const style = {
   ModalPromo: {
     'transition': 'all 0.8s ease',
   },
   showModal: {
     'display': 'block',
     'background': 'rgba(0, 0, 0, 0.5)',
     'transition': 'all 0.8s ease',
     'top': '0px',
     'zIndex':'1057'
   }, modalDialog: {
     'margin': '1.5rem',
     'zIndex':'1058'
   },
   modalContent: {
     'height': 'auto',
     'borderRadius': '10px'
   }, closeButton: {
     float: 'right'
    }, closeButton: { 
    position: 'absolute',
    right: '5px',
    top: '5px',
    fontWeight: 'bold'
    }
 
 }
 let defaultItem = {
   item_id: 0,
   item_name: "Product Name",
   item_variant: "500g",
   item_price: 300,
   item_description: "Description here - 500g",
   item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
 }
const ModalPromo = ({modalPromoStatus, discount,imageUrl,multiStop, setModalPromoStatus}) => {
    const ref = useRef();
    const [countDown, setCountDown] = useState(3)
    const [seconds, setSeconds ] =  useState(3);
    const [delayStatus, setDelayStatus] = useState(false); // show modal and delay on closing it.
    UseOutsideClick(ref, () => {
      if (delayStatus) {
        setModalPromoStatus(false)
      }
    });
    //timing out of opening and closing of modal
    setTimeout(() => {
      setDelayStatus(modalPromoStatus)
    }, 100);
    // useEffect(()=>{
    //   let counter=countDown
    //   console.log(discount)
    //   if(discount.discount_code=='BIGSALESURPRISE'){
    //     let myInterval = setInterval(() => {
    //             if (seconds > 0) {
    //                 setSeconds(counter - 1);
    //                 counter--
    //                 if(counter === 0){
    //                   setModalPromoStatus(false)
    //                   clearInterval(myInterval);
    //                 }
    //             }
    //         }, 1000)
    //         return ()=> {
    //             clearInterval(myInterval);
    //           };
    //   }
    // }, [modalPromoStatus]);

  //trigger close modal
  const closeModal = () => {
    setModalPromoStatus(false)
  }
  return (
    <React.Fragment>
      {/* <div id="close" onClick={closeModal}>
        <p style={modalPromoStatus ? {position: "absolute",zIndex: "1054",height: "100%", color: "transparent", width: "99%",display:'block' } : {display:'none'}}>.</p>
      </div> */}
      <div id="itemModal">
        <div id="close" onClick={closeModal}>
          <p style={modalPromoStatus ? {position: "absolute",zIndex: "1056",height: "100%", color: "transparent", width: "99%",display:'block' } : {display:'none'}}>.</p>
        </div>
        <div className='modal'
          style={modalPromoStatus ? style.showModal : {'zIndex':'1057'}}
          tabIndex="-1"
          role="dialog">
          <div className="modal-dialog" style={style.modalDialog} role="document" ref={ref}>
            <div id="modal-content" className="modal-content" style={style.modalContent}>
              <div className="modal-body p-0">
                    <div className="closeButtonField" style={style.closeButtonField}>
                    <button className='btn btn-transparent'
                        style={style.closeButton}
                        onClick={closeModal}
                    >x</button>
                    </div>
                    { multiStop ? 
                      <div style={{padding:"35px"}}>
                        <h5 style={{color:"#24b14b",textAlign:"center",fontWeight:"bold",marginBottom:"25px"}}>Thank you for choosing Zagana for your gift giving! </h5>
                        <p>Please follow the format below for the recipients of your gifts and put it on the <span style={{color:"#24b14b",fontWeight:"bold"}}>"Special Instructions"</span> part of this page.</p>
                        <ol>
                          <li>
                              <p style={{margin:"0px"}}>
                                Name of recipient: 
                                <br/>
                                Address of recipient:
                                <br/>
                                Item/s for recipient: 
                                <br/>
                                Note: (optional)
                              </p>
                          </li>
                        </ol>
                        <p style={{fontSize:"11px",fontStyle:"italic"}}>Disclaimer: We can only deliver in Metro Manila and some selected areas in Rizal and Cavite. Please get in touch with our Customer Success Representative on Zagana - Farm to Kitchen on Facebook Messenger for other concerns. </p>
                      </div>
                    :
                      <img width='100%' src={imageUrl} style={{borderRadius:'10px'}}/>
                    }
                    {/* <p style={{textAlign:'center',margin:'8px auto'}}>Auto close in {seconds} second{seconds>1?'s':''}</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    )
};

export default ModalPromo;