/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ItemViewModal
 * File Directory           : src/components/Shared/ItemViewModal
 * Author                   : Jake
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/ItemViewModal.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <ItemViewModal/>
 *******************************************************************************************/


import React, { useState, useRef, useEffect } from "react";
import UseOutsideClick from "../Shared/UseOutsideClick";
import CartButtons from "./CartButtons";
import moment from 'moment';
import { connect, useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ProductCarousel from "./ProductCarousel";
import { useTimer } from 'react-timer-hook';
import ReactGA from "react-ga4"
const style = {
    itemModal: {
        'transition': 'all 0.8s ease',
    },
    showModal: {
        'display': 'block',
        'background': 'rgba(0, 0, 0, 0.5)',
        'transition': 'all 0.8s ease',
        'top': '0px',
    }, modalDialog: {
        'margin': '1.5rem'
    },
    modalContent: {
        'height': 'auto'
    },
    borderDark :{
        border: "3px solid #222",
        borderBottom:"0px"
    },
    borderLight: {
        border: "1px solid #CACFD2"
    }, borderBlack2px: {
      border: "2px solid #facc02",
      borderRadius: "0",
      borderTop: "0px"
    }
}


const ItemViewModal = ({ modalStatus, item }) => {
    const ref = useRef();
    const dispatch = useDispatch()
    const [delayStatus, setDelayStatus] = useState(false); // show modal and delay on closing it.
    const time = new Date()
    let expiryTimestamp = time.setSeconds(time.getSeconds())
    let autoStart = false
    const {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({
      expiryTimestamp, autoStart, onExpire: () =>
        window.location.reload()
    });
    useEffect(() => {
      if (modalStatus === true) {
        if (item.category.includes("flash"))
        restart((new Date(moment(item.flash_end_date).subtract('hours',8))).setSeconds((new Date(moment(item.flash_end_date).subtract('hours',8))).getSeconds()))
      } else {
        pause()
      }
    }, [modalStatus])
    UseOutsideClick(ref, () => {
        if (delayStatus) {
            dispatch({
                type: "SET_VIEW_MODAL",
                selected: {},
                view: false
            });
            if (!window.location.href.includes('checkout-details'))
                window.history.pushState({}, document.title, "/");   // window.location.hash=item?.item_name.replace(/ /g,'');
            else
                window.history.pushState({}, document.title, "/checkout-details");
        }
    });
    setTimeout(() => {
        setDelayStatus(modalStatus)
    }, 100);

    //trigger close modal
    const closeModal = () => {
        dispatch({
            type: "SET_VIEW_MODAL",
            selected: {},
            view: false
        });
        if (!window.location.href.includes('checkout-details')) {
            window.history.pushState({}, document.title, window.location.pathname);   //removeItem Name on URL
        }
        else {
            window.history.pushState({}, document.title, "/checkout-details");
        }
    }

    return (
        <React.Fragment>

            <div id="itemModal" className="itemModalV2">
                <div className='modal'
                    style={modalStatus ? style.showModal : {}}
                    tabIndex="-1"
                    role="dialog">
                    <div className="modal-dialog" style={style.modalDialog} role="document" ref={ref}>
                        <div id="modal-content" className="modal-content" style={style.modalContent}>


                            <div className="modal-body">
                                <div className="row-12 p-1 m-0 text-end">
                                    <button className=' text-end w-25 p-0 btn btn-transparent'
                                        onClick={closeModal}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="#000" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" fill="#000" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="row p-2 pt-0 pb-0">
                                    {
                                        (((item?.item_price - item?.item_salePrice) / item?.item_price) * 100).toFixed(2) <= 0 ? null :
                                            <div className="ribbon-wrapper-modal">
                                                <div className="ribbon">
                                                    {Math.round(((item?.item_price - item?.item_salePrice) / item?.item_price) * 100)}%
                                                </div>
                                            </div>
                                    }


                                    {
                                        ((item.category || "all")).includes("new") ?
                                            <div className="ribbon-wrapper-modal" >
                                                {
                                                    window.location.pathname === '/checkout-details' ?
                                                        <div className="ribbon ribbonNew">
                                                            &nbsp;&nbsp;&nbsp;new
                                                        </div>
                                                        :
                                                        <div className="ribbon ribbonNew">
                                                            &nbsp;&nbsp;&nbsp;new
                                                        </div>

                                                }
                                            </div>
                                            : null
                                    }
                                    {
                                        ((item.category || "all")).includes("seasonal") ?
                                            <div className="ribbon-wrapper-modal" >
                                                {
                                                    window.location.pathname === '/checkout-details' ?
                                                        <div className="ribbon ribbonSeasonal">
                                                            &nbsp;&nbsp;Seasonal
                                                        </div>
                                                        :
                                                        <div className="ribbon ribbonSeasonal">
                                                            &nbsp;&nbsp;Seasonal
                                                        </div>

                                                }
                                            </div>
                                            : null
                                    }
                                    <div className="col-lg-12 col-sm-12 p-0" style={((item.category || "all")).includes("flash") ? style.borderDark : style.borderLight}>
                                        <ProductCarousel images={item.image_list} />
                                    </div>
                                    <>
                                        {
                                            ((item.category || "all")).includes("flash") &&
                                            <>
                                                <div className="alert alert-secondary p-2" style={style.borderBlack2px} >
                                                    <div className="row">
                                                    <div className="col-6" style={{ "fontWeight": "600" }}>
                                                        <span className="text-dark">Flash Sale! </span>
                                                        <br />
                                                        <strong style={style.textItemPrice} ><span style={item.item_salePrice < item.item_price ? { textDecoration: "line-through", fontWeight: 'normal' } : {}}>₱{item.item_price}</span> {item.item_salePrice < item.item_price ? <span style={{ color: "red" }}> ₱{item.item_salePrice}</span> : ''} </strong>

                                                    </div>
                                                    <div className="col-6 my-auto flashTimer">
                                                        <span className="float-end" style={style.timeBorder}>Ends in: &nbsp;{("0" + hours).slice(-2)} : {("0" + minutes).slice(-2)} : {("0" + seconds).slice(-2)}&nbsp;</span>
                                                        <small style={{fontSize:".6em"}} className="flashInfo float-end text-secondary p-2 pt-0 pb-0"><i>(Max of 2 items or until stocks last)</i></small>
                                                    </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                    <div style={style.lineHeight12} className="col-lg-12 col-sm-12 pt-2 pb-2" >
                                        <h2 style={style.fontSize12}>
                                            {item?.item_name}
                                        </h2>
                                        <label
                                            style={style.fontSize10}
                                            className="w-100"
                                        >
                                            {item?.item_variant}
                                        </label>
                                        <pre className="mb-1 text-secondary w-100 item-description">{item.item_description}</pre>
                                        <a href={`/productPage/${item.item_sku}`} onClick={() => {
                                            ReactGA.event({
                                                category: item.category,
                                                action: "View Item",
                                                label: item.item_name
                                            });
                                            window.gtag("event", "view_item", {
                                                currency: "PHP",
                                                value: item.item_price,
                                                items: [
                                                    {
                                                        item_id: item.item_sku,
                                                        item_name: item.item_name,
                                                        currency: "PHP",
                                                        index: 0,
                                                        item_brand: "Zagana",
                                                        item_category: item.category,
                                                        price: item.item_price,
                                                    }
                                                ]
                                              });
                                        }}>See more</a>

                                        {item.item_salePrice < item.item_price ?
                                            <React.Fragment>
                                                <label
                                                    style={style.fontSize12}
                                                    className="text-danger d-block"
                                                >
                                                    ₱{item.item_salePrice?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2
                                                    })}
                                                </label>
                                                <label
                                                    style={style.fontSize10}
                                                    className="w-100"
                                                >
                                                    <del>
                                                        ₱{item.item_price?.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                    </del>
                                                </label>
                                            </React.Fragment>
                                            :
                                            <label
                                                className="w-100"
                                                style={style.fontSize12}
                                            >
                                                ₱{item.item_price?.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2
                                                })}
                                            </label>
                                        }
                                    </div>
                                    <div className="float-right">
                                        {
                                            item?.item_status === "enabled" ?
                                                <CartButtons item={item} />
                                                :
                                                <p></p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

const mapStateToProps = (state) => {

    return {
        item: state.ProductModal.Item,
        modalStatus: state.ProductModal.View,
    };
};

export default connect(mapStateToProps)(ItemViewModal);