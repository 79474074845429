/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ItemCard
 * File Directory           : src/components/Shared/ItemCard
 * Module                   : Items
 * Author                   : Jenhel
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemCard        -   component for ItemCard
 *
 * #How to call:
 *    <ItemCard/>
 *
 *******************************************************************************************/

import React, { useEffect, useState } from "react";
import Home from '../APIs/Home';
import LazyLoad from "react-lazyload";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { forgotModal } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";
import { useDispatch } from "react-redux";
import { v4 as uuid } from 'uuid';
import { useTime, useTimer } from 'react-timer-hook';
//moment
import moment from "moment";
import _ from 'lodash'
const style = {
  addButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "0",
    width: "95px",
    height: "33.5px"
  },
  addButtonColorMobile: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "0",
    width: " 100%",
    height: "33.5px"
  },
  incrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#24b14b",
    color: "white",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  decrementButtonColor: {
    border: "2px solid #24b14b",
    borderRadius: "25px",
    background: "#white",
    color: "#24b14b",
    fontWeight: "bold",
    fontSize: "14px",
    padding: "0",
    width: "30px",
    height: "30px"
  },
  incrementButtonColorDesktop: {
    border: "none",
    borderRadius: "25px",
    background: "transparent",
    color: "#24b14b",
    fontWeight: "bold",
    float: "left",
    fontSize: "14px",
    padding: "0",
    width: "20px",
    height: "20px"
  },
  decrementButtonColorDesktop: {
    border: "none",
    borderRadius: "25px",
    background: "transparent",
    color: "#24b14b",
    fontWeight: "bold",
    float: "left",
    fontSize: "14px",
    padding: "0",
    width: "20px",
    height: "20px"
  },
  textField: {
    textAlign: "center",
    background: "transparent",
    margin: "3px",
    display: 'inline'
  },
  textFieldDesktop: {
    textAlign: "center",
    float: "left",
    background: "transparent",
    margin: "3px"
  },
  imgColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    cursor: 'zoom-in',
    position: 'relative'
  },
  textItemTitle: {
    fontSize: "0.8rem",
    cursor: 'zoom-in'
  },
  textItemTitleGrid: {
    fontSize: ".75rem",
    cursor: 'zoom-in',
    height: "30px",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box"
  },
  textItemTitleDesktop: {
    fontSize: "1rem",
    height: "26px",
    margin: "0",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    cursor: 'zoom-in'
  },
  textItemVariant: {
    fontSize: "0.7rem"
  },
  textItemPrice: {
    fontSize: "0.7rem"
  },
  textItemPriceDesktop: {
    fontSize: "0.7rem",
    float: 'right'
  },
  btnDiv: {
    float: 'right',
    width: '125px',
    paddingRight: '1rem'
  },
  btnDivGrid: {
    width: '125px',
    padding: "0 10px",
    marginTop: "45px"
  },
  container: {
    position: "relative",
    textAlign: "center",
    color: "white"
  },
  centered: {
    width: "max-content",
    position: "absolute",
    top: "50%",
    left: "50%",
    color: "#ff7f7f",
    transform: "translate(-45%, -50%)",
    background: "whitesmoke",
    opacity: "80%"
  },
  outOfStock: {
    background: "#d3d3d3",
    position: "relative"
  },
  saleRibbon: {
    position: "relative"
  },
  displayNone: {
    display: "none"
  },
  oosItem: {
    backgroundColor: "rgba(0, 0, 0, 0.1) !important"
  },
  timeBorder: {
    border: "solid 1px gray",
    padding: "1px",
    fontSize: "13px"
  }
};

let defaultItem = {
  item_id: 0,
  item_name: "Product Name",
  item_variant: "500g",
  item_price: 300,
  category: "all",
  item_status: "enabled",
  item_description: "Description here - 500g",
  item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const ItemCard = ({ itemNotify, item, incart, setOnCart, cart, setCart, setTotal, cartChange, setCartChange, itemDetailModal, modalStatus, setModalStatus, setItemDetailModal, search, cartVChange, setCartVChange, cartVDetails, setCartVDetails, viewing, setVisibleLogin, setConfirmContactModalStatus, setItemNotify }) => {
  let accessKey = localStorage.getItem('accessKey')
  const [inCartQty, setinCartQty] = useState(itemDetailModal.item_id === item.item_id ? cartChange : incart.cart_qty || 0);
  let body = document.body; // for setting body height (modal)

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(inCartQty > 0 ? true : false);
  const isMobile = localStorage.getItem("isMobile") === "true"
  const [itemOnCart, setItemOnCart] = useState([]);
  const [notifId, setNotifId] = useState(item.notify_id);
  const [percentage, setPercentage] = useState(0)
  // let itemOnCart =[]
  // let filename = item.item_image !== null ? item.item_image.split("/fixed/")[1] : null
  //  let mobilePhoto = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
  let photo = item.item_image
  photo = (photo === null||photo.replace(/ /g, '')==="") ? "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" : photo
  const isLogged = sessionStorage.getItem("Login") || ''; // login checker
  const uniqueID = uuid();
  let branch_id = JSON.parse(localStorage.getItem('SelectedCity'))
  branch_id = branch_id.branch_id
  let gen_id = localStorage.getItem('gen_id')
  let user;
  let userLogin = JSON.parse(sessionStorage.getItem('Login'));
  let saleTagCO = Math.round(((item.item_price - item.cart_price) / item.item_price) * 100); // for sale tag percent, for checkout page
  let saleTag = Math.round(((item.item_price - item.item_salePrice) / item.item_price) * 100); // for sale tag percent, for home page and modal
  const time = (item.category).toLowerCase().includes("flash")?new Date(item.flash_end_date):new Date()
  let expiryTimestamp = time.setSeconds(time.getSeconds())
  let autoStart = (item.category).toLowerCase().includes("flash")
  const {
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp,autoStart, onExpire: () => 
                window.location.reload() });

  const ConditionalEffect2 = ({depA, depB}) => {
    useEffect(() => {}, [depA, depB])
    return null
  }
  let categTag = (item.category).toLowerCase();
  useEffect(() => {
    setItemOnCart(cart)
    if (incart.cart_qty > 0) {
      setinCartQty(incart.cart_qty)
      setVisible("true")
    }
    var cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    if (cartIndex >= 0) {

    } else {
      setinCartQty(0)
      setVisible(false)
    }
  }, [cart])
  //getCartQty for cart that takes time to get
  useEffect(() => {
    if (incart.cart_qty > 0) {
      setinCartQty(incart.cart_qty)
      setVisible("true")
    }
  }, [incart])
  useEffect(() => {
    if (itemNotify) {
      if (itemNotify.item_id === item.item_id)
        if (itemNotify.notify_id === "success"){
          setNotifId(1)
          item.notify_id=1
        }
    }
  }, [itemNotify])

  //SetChangeOnDetails to Trigger cartChange Use Effect Only on homepage
  useEffect(() => {
    if (window.location.pathname === '/' || window.location.pathname === '/premium') {
      if (cartVDetails.item_id === item.item_id) {
        setCartChange(cartVChange)
        setItemDetailModal(cartVDetails)
      }
    } else if ((window.location.pathname).includes("productPage")) {
      if (cartVDetails.item_id === item.item_id) {
        setCartChange(cartVChange)
        setItemDetailModal(cartVDetails)
      }
    }
  }, [cartVDetails, cartVChange])

  //Set Cart Changes to localstorage and update views on different Item view
  useEffect(() => {
    // //check if latest change is for the current item
    var cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    if (itemDetailModal.item_id === item.item_id) {
      if(cartIndex>=0||cartChange===1){
        
        var checker = cartChange
        //set Maximum of 50 only
        if ((item.category).toLowerCase().includes("flash")) {
          if(checker > 2)
          checker = 2
        }
        else if (checker > 50)
          checker = 50
        //Check if already in Cart
        cartIndex = cart.findIndex(
          (cartItem) => item.item_id === cartItem.item_id
        );
        // console.log(cartIndex)
        if (cartIndex < 0) {
          cartIndex = cart.findIndex(
            (cartItem) => item.item_sku === cartItem.item_sku
          );
        }
        // console.log(cartIndex)
        if (checker === 0) {

          setVisible(false)
        }
        if (cartIndex >= 0 && checker === 0) {
          //remove item on cart
          cart.splice(cartIndex, 1);
          setVisible(false)
        } else if (cartIndex >= 0) {
          //update item qty
          cart[cartIndex].cart_qty = checker;
        }
        else if (cartIndex < 0 && cartChange !== 0) {
          //add item to cart
          setVisible(true)
          cart.push({
            item_id: item.item_id,
            cart_qty: parseInt(checker),
            cart_price: item.item_salePrice,
            item_price: item.item_price,
            item_branch: item.item_branch,
            item_sku: item.item_sku,
            item_var: item.item_variant,
            item_name: item.item_name,
            item_image: item.item_image,
            item_status: item.item_status,
            category: item.category
          });
        }
        //update items
        setinCartQty(cartChange)
        // console.log(cart)
        let itemAdded = JSON.stringify(cart);
        setCart(cart)
        // localStorage.setItem("cart", itemAdded);
        TotalComputation(cart)
        fetchItems(cart)
        // setCartVChange(defaultItem)
        if(modalStatus===false)
          setItemDetailModal(defaultItem)
      }else{
        setinCartQty(0)
        setVisible(false)
      }
    }
  }, [cartChange, itemDetailModal])

  const btnAddOnClick = () => {
    //newFunction
    let checker = parseInt(inCartQty) + 1
    if (parseInt(inCartQty) < 0)
      checker = 1
    //maximum of 50 Item Qty
      if ((item.category).toLowerCase().includes("flash")) {
        if(checker > 2)
        checker = 2
      }
      else if (checker > 50)
      checker = 50
    setinCartQty(parseInt(checker))
    var cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    if (cartIndex < 0) {
      cartIndex = cart.findIndex(
        (cartItem) => item.item_sku === cartItem.item_sku
      );
    }
    // console.log(cartIndex)
    let pushNewToCart = 0
    if (cartIndex >= 0)
      cart[cartIndex].cart_qty = checker;
    else
      cart.push({
        item_id: item.item_id,
        cart_qty: parseInt(checker),
        cart_price: item.item_salePrice,
        item_price: item.item_price,
        item_branch: item.item_branch,
        item_status: item.item_status,
        item_sku: item.item_sku,
        item_var: item.item_variant,
        item_name: item.item_name,
        item_image: item.item_image,
        category: item.category
      });
    setCart(cart)
    setOnCart(cart)
    setVisible(true)
    // fetchItems(cart)
    // TotalComputation(pushNewToCart)
    //oldFunction
    // let checker = parseInt(inCartQty) + 1
    // if (parseInt(inCartQty) < 0)
    //   checker = 1
    // //maximum of 50 Item Qty
    // if (checker > 50)
    //   checker = 50
    // setinCartQty(parseInt(checker));
    // //Fetch item Index on Cart
    // var cartIndex = itemOnCart.findIndex(
    //   (cartItem) => item.item_id === cartItem.item_id
    // );

    // //Set New Cart Quantity or Add to cart Array
    // let pushNewToCart = 0
    // if (cartIndex >= 0)
    //   itemOnCart[cartIndex].cart_qty = checker;
    // else
    //   pushNewToCart = 1
    // setOnCart(itemOnCart)
    // setVisible(true)

    // //call Total Computation
    TotalComputation(cart)
    //logs cart for adding
    setLogs(`Item Variant: ${item.item_variant}, Item Cost: ${item.item_salePrice}`, "add_to_cart", item)

    /** Google Analytics */
    let value = parseInt(checker) * item.item_price
    // if(getCookieConsentValue('zaganaCookie')==='true'){
    window.gtag("event", "add_to_cart", {
      currency: "PHP",
      value: value,
      items: [{
        item_id: item.item_sku,
        item_name: item.item_name,
        location_id: item.item_branch,
        item_variant: item.item_description,
        item_category: item.category,
        price: item.item_price,
        discount: item.item_salePrice,
      }]
    });
    // }
    /** Google Analytics */
    /** FB PIXEL */
    ReactPixel.track('AddToCart');
    /**FB PIXEL */

    // tiktol pixel
    window.ttq.track('AddToCart',{  
      content_id: item.item_sku,
      quantity: 1,
      price: parseFloat(item.item_price),
      value: parseFloat(item.item_price),
      currency: 'PHP',
  })
  };

  const fetchItems = async (items) => {
    await Home.post('/accessStorage/changeCart', {
      itemOnCart: items,
      accessToken: accessKey
    })
  }
  const btnMinusOnClick = () => {
    //Get item index on cart
    var cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    //Remove to Array if 0 else just deduct
    if (cartIndex >= 0 && cart[cartIndex].cart_qty - 1 <= 0) {
      // console.log(cart[cartIndex].cart_qty - 1)
      cart[cartIndex].cart_qty--;
      setVisible(false)
      // if (search === "onCart")
      // cart.splice(cartIndex, 1);
    } else if (cartIndex >= 0)
      cart[cartIndex].cart_qty--;
    //Just to make sure not negative :)
    if (inCartQty - 1 <= 0) {
      setinCartQty(0);
    }
    setinCartQty(inCartQty - 1);
    setCart(cart)
    setOnCart(itemOnCart)
    let itemAdded = JSON.stringify(itemOnCart);
    // localStorage.setItem("cart", itemAdded);

    fetchItems(itemOnCart)
    //call Total Computation
    TotalComputation(itemOnCart)
    /** Google Analytics */
    let value = parseInt(inCartQty - 1) * item.item_price
    setLogs(`Item Variant: ${item.item_variant}, Item Cost: ${item.item_salePrice}`, "remove_from_cart", item)
    // if(getCookieConsentValue('zaganaCookie')==='true'){
    window.gtag("event", "remove_from_cart", {
      currency: "PHP",
      value: value,
      items: [{
        item_id: item.item_sku,
        item_name: item.item_name,
        location_id: item.item_branch,
        item_variant: item.item_description,
        item_category: item.category,
        price: item.item_price,
        discount: item.item_salePrice,
      }]
    });
    // }
    /** Google Analytics */
  };

  const inputQtyChange = (action, count, e) => {
    //Get item index on cart
    var cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    //check action type
    if (action === "blur") {
      //on blur remove if 0 else just update qty
      if (count === "" || count <= 0 || count === "0") {
        setVisible(false)
        setinCartQty(0);
        count = 0
        if (cartIndex >= 0) cart[cartIndex].cart_qty = count;
        setOnCart(cart)
        if (cartIndex >= 0 && (count === "" || count === 0 || count === "0"))
          // if (search === "onCart")
          cart.splice(cartIndex, 1);
        let itemAdded = JSON.stringify(cart);

        // localStorage.setItem("cart", itemAdded);
      }
      else {
        //set max to 50
        if ((item.category).toLowerCase().includes("flash")) {
          if(count > 2)
          count = 2
        }
        else if (count > 50)
          count = 50
        count = parseInt(count)
      }
    } else {
      //set max to 50
      if ((item.category).toLowerCase().includes("flash")) {
        if(count > 2)
        count = 2
      }
      else if (parseInt(count) > 50)
        count = 50
      if (cartIndex >= 0)
        cart[cartIndex].cart_qty = count; //set item qty
      setinCartQty(count);
      setCart(cart)
      setOnCart(cart)
      let itemAdded = JSON.stringify(cart);

      // localStorage.setItem("cart", itemAdded);
    }
    fetchItems(cart)
    //call Total Computation
    TotalComputation(cart)
  };

  const setLogs = (changes, movement, cartItem) => {
    if (gen_id === null) {
      gen_id = localStorage.setItem('gen_id', uniqueID)
      gen_id = uniqueID
      user = gen_id
    } else if (gen_id) {
      gen_id = localStorage.getItem('gen_id')
      user = gen_id
    } else if (isLogged) {
      userLogin = JSON.parse(sessionStorage.getItem('Login'))
      gen_id = localStorage.getItem('gen_id')
      user = userLogin.customer_name + ` (${gen_id})`
    }

    const payload = {
      user: user,
      changes: changes,
      type: movement,
      date: `NOW()`,
      sku: cartItem.item_sku
    }
    storeFrontLogs(payload)
  }
  const TotalComputation = (items) => {
    // //get Item Index
    // var cartIndex = itemOnCart.findIndex(
    //   (cartItem) => item.item_id === cartItem.item_id
    // );
    // //use to trigger CartChange useEffect (trigger/update the change to other item viewing)
    // if (pushNewToCart === 1) {
    //   setCartChange(1)
    // }
    // else if (cartIndex >= 0)
    //   setCartChange(itemOnCart[cartIndex].cart_qty)
    // setItemDetailModal(item)

    let pushNewToCart = 0
    let cartIndex = cart.findIndex(
      (cartItem) => item.item_id === cartItem.item_id
    );
    //use to trigger CartChange useEffect (trigger/update the change to other item viewing)
    // if (pushNewToCart === 1) {
    //   setCartChange(1)
    // }
    // else 
    if (cartIndex >= 0)
      setCartChange(cart[cartIndex].cart_qty)
    setItemDetailModal(item)
    //getCurrentCart
    let itemsOnCart = cart || []
    let itemTotal = 0 //default total
    let itemCount = 0 //default cart count
    //loop all items
    itemsOnCart.forEach(items => {
      let cartQty = items.cart_qty
      //check if there are old errors (string on cartQty)
      if (cartQty === '' || cartQty === '0')
        cartQty = 0
      //add to total and cart count
      if (items.item_status === "enabled") {
        itemTotal += cartQty * parseFloat(items.cart_price)
        itemCount += parseInt(cartQty)
      }
    });
    //set to sessionStorage
    // console.log(itemCount+" "+parseFloat(itemTotal))
    setTotal({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) })
    sessionStorage.setItem("total", JSON.stringify({ itemCount: itemCount, itemTotal: parseFloat(itemTotal) }));
  }

  //Open item Modal
  const btnViewDetails = () => {
    // if((item.item_status).toLowerCase()==="enabled"){
      setLogs(`View Product Details(Modal)`,"view_product_details",item)
      if (window.location.pathname === '/checkout-page' || window.location.pathname === '/checkout-details' ||window.location.pathname === '/search') {
  
      }
      else if ((window.location.pathname).includes("productPage")) {
        window.location.replace('/productPage/' + item.item_sku);
      }
      else {
        window.history.pushState('page2', 'Zagana | Home', '?' + item.item_name.replace(/ /g, ''));    // update Url
        //get item On Cart
        var cartIndex = cart.findIndex(
          (cartItem) => item.item_id === cartItem.item_id
        );
        //set item Modal visible
        setModalStatus(true)
        //set details
        if (cartIndex >= 0){
          setCartChange(cart[cartIndex].cart_qty)
        }else{
          setCartChange(0)
        }
        setItemDetailModal(item)

        //FBPixel
        ReactPixel.track('ViewContent', {
          content_type: "product",
          content_ids: [item.item_sku],
        });
        //FBPixel
      }
    // }
  }
  //on click Notify me/update me
  const btnNotifyMe = async () => {
    if (isLogged) {
      if (!userLogin.preferredNotification) {
        setConfirmContactModalStatus(true)
        setItemNotify(item)
      } else {
        await Home.post('/notifyItem/add', {
          userId: userLogin.customer_id,
          item_sku: item.item_sku,
          item_branch: item.item_branch||branch_id,
          customerEmail: userLogin.customer_email,
          phoneNumber: userLogin.customer_phone,
          mode: null
        })
        setItemNotify({
          item_id: item.item_id,
          item_sku: item.item_sku,
          item_name: item.item_name,
          item_branch: item.item_branch||branch_id,
          notify_id: "success"
        })
      }
      // if(userEmail!==null&&userEmail!==""){
      //   console.log(item.item_sku)
      //   await Home.post('/notifyItem/add', {
      //     userId: userId,
      //     item_sku: item.item_sku,
      //     item_branch: item.item_branch
      //   })
      // }else{
      //   alert("kindly input Email")
      // }
    }else{
      localStorage.setItem('notifyItem',JSON.stringify({
        item_sku: item.item_sku,
        item_branch: item.item_branch||branch_id
      }))
      setVisibleLogin("false");
      let element = document.getElementById("modalLoginID");
      element.classList.remove("showModal");
      body.classList.add("body_class_");
      dispatch(forgotModal(false))

    }

  }

  return (
    <div
      style={(item.item_status).toLowerCase() === "disabled" ? window.location.pathname !== '/checkout-page' && !isMobile ? style.outOfStock : style.outOfStock : {}}
      // style={(item.item_status).toLowerCase() === "disabled" ? style.outOfStock : style.saleRibbon}
      className={`
      ${(item.item_status).toLowerCase() === "enabled" ? 'bg-white' : ''} 
      ${viewing === "grid" && window.location.pathname !== '/checkout-details' && isMobile ? 'borderRadiusGrid' : ''} 
      ${search === "onCart" && !isMobile ? (item.item_status).toLowerCase() === "enabled" ? 'border-bottom bg-transparent' : "border-bottom " : 'card rounded border'} 
      ${window.location.pathname === '/' && search === "onCart" ? 'p-2' : isMobile ? 'p-1' : 'p-2'} 
      ${window.location.pathname !== '/checkout-page' && !isMobile && (item.item_status).toLowerCase() === "disabled" ? "outOfStock" : ""} 
      ${(item.category).includes("premium") ? 'cardPrem' : ''}   pb-1`} id={item.item_name}>

      <div className="card-body p-1">
        <div className="row">

          {search === "onCart" && !isMobile && window.location.pathname !== '/' ?

            (item.item_status).toLowerCase() === "enabled" ?
              <div className='col p-0 my-auto'>
                <button
                  id={`btnMinusCart${item.item_id}`}
                  className={`btn col-3 btnMinus ${visible ? "" : "invisible"}`}
                  style={style.decrementButtonColorDesktop}
                  onClick={btnMinusOnClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 25" fill="none" stroke="#55b24d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px" ><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="83.34" y1="12.5" x2="12.5" y2="12.5" /></g></g></svg>
                </button>
                <input
                  id={`inputCartQty${item.item_id}`}
                  className={`form-control w30 col-3 float-right border-0 border-bottom ${visible ? "" : "invisible"
                    }`}
                  type="number"
                  value={inCartQty}
                  style={style.textFieldDesktop}
                  onChange={(e) => inputQtyChange("change", e.target.value)}
                  onBlur={(e) => inputQtyChange("blur", e.target.value, e)}
                />
                <button
                  id={`btnAddCart${item.item_id}`}
                  className="btn col-3 btnAdd"
                  style={style.incrementButtonColorDesktop}
                  onClick={btnAddOnClick}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 95.84" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px"><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="47.92" y1="12.5" x2="47.92" y2="83.34" /><line className="cls-1" x1="83.34" y1="48.96" x2="12.5" y2="48.96" /></g></g></svg>
                </button >
              </div>
              :
              ""
            :
            <div></div>
          }
          <div className={`col${viewing === "grid" && window.location.pathname !== '/checkout-details' && isMobile ? '-12' : ''} ${search === "onCart" && !isMobile && window.location.pathname !== '/' ? '' : 'p-1'}`} style={style.imgColumn}>
            {/* <LazyLoad height={90} once>
              <img
                onClick={btnViewDetails}
                size="100%"
                className={`img-fluid ${search === "onCart" && !isMobile && window.location.pathname !== '/' ? '' : 'p-1'}`}
                src={photo}
                width={viewing === "grid" && window.location.pathname !== '/checkout-details' && isMobile ? '100px' : `70px`}
                alt={item.item_name}
              />

            </LazyLoad> */}

            {
              saleTag.toFixed(2) <= 0 ? null :
                <div className="ribbon-wrapper" style={(item.item_price - item.cart_price) === 0 ? style.displayNone : null} >
                  {
                    window.location.pathname === '/checkout-details' ?
                      <div className="ribbon">
                        {Math.round(saleTagCO)}%
                      </div>
                      :
                      <div className="ribbon">
                        {Math.round(saleTag)}%
                      </div>

                  }
                </div>
            }
            
            {
              categTag.includes("new")  ?
                <div className="ribbon-wrapper" style={(item.item_price - item.cart_price) === 0 ? style.displayNone : null} >
                  {
                    window.location.pathname === '/checkout-details' ?
                      <div className="ribbon ribbonNew">
                        &nbsp;&nbsp;new
                      </div>
                      :
                      <div className="ribbon ribbonNew">
                       &nbsp;&nbsp;new
                      </div>

                  }
                </div>
                : null
            }
            {
              categTag.includes("seasonal")  ?
                <div className="ribbon-wrapper" style={(item.item_price - item.cart_price) === 0 ? style.displayNone : null} >
                  {
                    window.location.pathname === '/checkout-details' ?
                      <div className="ribbon ribbonSeasonal">
                        &nbsp;Seasonal
                      </div>
                      :
                      <div className="ribbon ribbonSeasonal">
                        &nbsp;Seasonal
                      </div>

                  }
                </div>
                : null
            }
            
            <LazyLoadImage
              onClick={btnViewDetails}
              alt={item.item_name}
              effect="blur"
              height={90}
              width="100%"
              src={photo} />
            {/* out of stock label over item image */}
            {/* {
              (item.item_status).toLowerCase()==="disabled"?
                <div style={style.centered}>Out of stock</div>:""
            } */}
            {!isMobile ? ''
              :
              window.location.pathname === '/checkout-details' || window.location.pathname === '/search' ? '' :
                <div>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className={`bi bi-eye eye ${modalStatus && itemDetailModal.item_id === item.item_id ? 'active' : ''}`} viewBox="0 0 16 16" style={{ position: "absolute", bottom: "17px", right: "15px" }}>
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg> */}

                  <svg xmlns="http://www.w3.org/2000/svg" className={`bi bi-eye eye ${modalStatus && itemDetailModal.item_id === item.item_id ? 'active' : ''}`} viewBox="0 0 67.78 70.74" fill="none" stroke="#222" strokeLinecap="round" strokeWidth="10" strokelinejoin="round" style={{ position: "absolute", bottom: "17px", right: "15px", width: "16" }} ><g id="Layer_2" data-name="Layer 2"><g id="Layer_11" data-name="Layer 11"><circle className="cls-1" cx="29.71" cy="29.71" r="24.71" /><line className="cls-1" x1="46.07" y1="49.04" x2="62.78" y2="65.74" /><line className="cls-2" x1="29.71" y1="21.02" x2="29.71" y2="39.13" /><line className="cls-2" x1="38.77" y1="30.34" x2="20.66" y2="30.34" /></g></g></svg>
                </div>

            }
          </div>
          <div className={` ${search === "onCart" && !isMobile && window.location.pathname !== '/' ? 'col-sm-6 p-0' : viewing === "grid" && window.location.pathname !== '/checkout-details' && isMobile ? 'col-12 p-2' : 'col-9 p-0'}  itemDetails`} style={viewing === "grid" && window.location.pathname !== '/checkout-details' && isMobile ? { textAlign: 'center' } : {}} >
            <h5 className={`prodItem_ mb-1 ${search === "onCart" && !isMobile && window.location.pathname === '/' ? 'item_titleCart' : ''}`} style={search !== "onCart" && isMobile ? viewing === "grid" && window.location.pathname !== '/checkout-details' && isMobile ? style.textItemTitleGrid : style.textItemTitle : style.textItemTitleDesktop} onClick={btnViewDetails}>
              {_.get(item, ['item_name'], '').length >= 45 ? `${item.item_name.substring(0, 45)}...` : item.item_name}

            </h5>
            {/* {
              (item.category).toLowerCase().includes("flash") &&
                <>
                <div style={{ "padding":"2px"}}>
                    ENDS IN <span style={style.timeBorder}>{("0" + hours).slice(-2)}</span> : <span style={style.timeBorder}>{("0" + minutes).slice(-2)}</span> : <span style={style.timeBorder}>{("0" + seconds).slice(-2)}</span>
                  </div>
                </>
            } */}

            <div className="priceDetails">
              {
                window.location.pathname === '/checkout-details' ?
                  <strong style={search === "onCart" && !isMobile && window.location.pathname !== '/' ? style.textItemPriceDesktop : style.textItemPrice} ><><span style={item.cart_price < item.item_price ? { textDecoration: "line-through", fontWeight: 'normal' } : {}}>₱{item.item_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}</span>
                    <p className="mb-2 text-secondary" style={style.textItemVariant}>{item.item_variant}</p> </>{item.cart_price < item.item_price ? <><span style={{ color: "red" }}> ₱{item.cart_price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}</span>
                      <p className="mb-2 text-secondary" style={style.textItemVariant}>{item.item_variant}</p>
                    </> : ''}
                  </strong>

                  :

                  <strong className="itempriceSale" style={search === "onCart" && !isMobile && window.location.pathname !== '/' ? style.textItemPriceDesktop : style.textItemPrice} >
                    <>
                      <span
                        id={`${item.item_salePrice < item.item_price ? 'strikePrice' : ''}`}
                        style={item.item_salePrice < item.item_price ? { textDecoration: "line-through", fontWeight: 'normal' } : {}}>₱{item.item_price}</span>
                      {item.item_salePrice < item.item_price ? "" : <p className="mb-2 text-secondary" style={style.textItemVariant}>{item.item_variant}</p>}
                    </>
                    {item.item_salePrice < item.item_price ?
                      <>
                        <span id="salePriceSpan" style={{ color: "red", fontSize: "13px" }}>₱{item.item_salePrice.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}</span>
                        <p className="mb-2 text-secondary" style={style.textItemVariant}>{item.item_variant}</p>
                      </> : ''}

                  </strong>
                  

              }

            </div>

             {search !== "onCart" && (window.location.pathname === '/' || window.location.pathname.includes('/home/') ) ?

              <div style={(item.item_status).toLowerCase() === "disabled" && !isMobile ? { float: "right" } : {}}>
                {(item.item_status).toLowerCase() === "enabled" ?
                  <div id="addMinus" style={viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? style.btnDivGrid : style.btnDiv} className={viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? `w-100 buttonsField` : ``}>
                    <button
                      id={`btnMinusCart${item.item_id}`}
                      className={`btn btnMinus ${visible ? "" : viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none"}`}
                      style={style.decrementButtonColor}
                      onClick={btnMinusOnClick}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 25" fill="none" stroke="#55b24d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px" ><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="83.34" y1="12.5" x2="12.5" y2="12.5" /></g></g></svg>
                    </button>
                    <input
                      id={`inputCartQty${item.item_id}`}
                      className={`form-control w-25 float-right border-0 border-bottom ${visible ? "" : viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none"
                        }`}
                      type="number"
                      value={inCartQty}
                      style={style.textField}
                      onChange={(e) => inputQtyChange("change", e.target.value)}
                      onBlur={(e) => inputQtyChange("blur", e.target.value, e)}
                    />
                    <button
                      id={`btnAddCart${item.item_id}`}
                      className={`btn btnAdd ${visible ? "" : viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none"}`}
                      style={style.incrementButtonColor}
                      onClick={btnAddOnClick}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 95.84" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px"><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="47.92" y1="12.5" x2="47.92" y2="83.34" /><line className="cls-1" x1="83.34" y1="48.96" x2="12.5" y2="48.96" /></g></g></svg>
                    </button >
                    <button
                      id={`btnAddCart${item.item_id}`}
                      className={`addSale btn btnAdd ${visible ? viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none" : ""}`}
                      style={isMobile ? style.addButtonColorMobile : style.addButtonColor}
                      onClick={btnAddOnClick}
                    >
                      ADD
                    </button >
                  </div>
                  :
                  <div style={{ padding: isMobile ? "0px 10px" : "", marginRight: isMobile ? "" : "", float: isMobile ? "" : "unset", marginTop: isMobile?"35px":""}}>
                    {/* <button
                        id={`btnMinusCart${item.item_id}`}
                        style={{borderRadius: "4px",fontSize:"10px",width:"95px",marginRight:isMobile? "":"2rem"}} 
                        className={`btn btn-danger oosItem`}
                        onClick={btnMinusOnClick}
                      >
                        Remove
                      </button> */}
                    {notifId ?
                      <button
                        style={{ borderRadius: "25px", cursor: "not-allowed", fontSize: "10px", width: isMobile ? "100%" : "95px", marginRight: isMobile ? "" : "2rem", float: isMobile ? "right" : "unset" }}
                        className={`btn btn-secondary ${isMobile ? "mt-3 " : ""}  mb-1 p-0 pt-2 pb-2`}
                      >Updating soon
                      </button>
                      :
                      <button
                        style={{ background: "#facc09", borderRadius: "25px", fontSize: "12px", width: isMobile ? "100%" : "95px", margin:isMobile?"0":"",marginRight: isMobile ? "" : "2rem", float: isMobile ? "right" : "unset", padding:isMobile?"0px":"" , marginBottom:isMobile?"13px":"" }}
                        className={`notifyBtn btn ${isMobile ? "" : ""}  p-0 pt-1 pb-2`}
                        onClick={btnNotifyMe}
                      >Notify Me
                      </button>
                    }
                  </div>

                }
              </div>
              :
              <div>
                {
                  (item.item_status).toLowerCase() === "enabled" ?
                    isMobile ?
                      <div id="addMinus" style={viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? style.btnDivGrid : style.btnDiv} className={viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? `w-100 buttonsField` : ``}>
                        <button
                          id={`btnMinusCart${item.item_id}`}
                          className={`btn btnMinus ${visible ? "" : viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none"}`}
                          style={style.decrementButtonColor}
                          onClick={btnMinusOnClick}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 25" fill="none" stroke="#55b24d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px" ><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="83.34" y1="12.5" x2="12.5" y2="12.5" /></g></g></svg>
                        </button>
                        <input
                          id={`inputCartQty${item.item_id}`}
                          className={`form-control w-25 float-right border-0 border-bottom ${visible ? "" : viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none"
                            }`}
                          type="number"
                          value={inCartQty}
                          style={style.textField}
                          onChange={(e) => inputQtyChange("change", e.target.value)}
                          onBlur={(e) => inputQtyChange("blur", e.target.value, e)}
                        />
                        <button
                          id={`btnAddCart${item.item_id}`}
                          className={`btn btnAdd ${visible ? "" : viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none"}`}
                          style={style.incrementButtonColor}
                          onClick={btnAddOnClick}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 95.84" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px"><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="47.92" y1="12.5" x2="47.92" y2="83.34" /><line className="cls-1" x1="83.34" y1="48.96" x2="12.5" y2="48.96" /></g></g></svg>
                        </button >
                        <button
                          id={`btnAddCart${item.item_id}`}
                          className={`ADDLABEL btn btnAdd ${visible ? viewing === "grid" && window.location.pathname !== '/checkout-page' && isMobile ? "d-none" : "d-none" : ""}`}
                          style={style.addButtonColor}
                          onClick={btnAddOnClick}
                        >
                          ADD
                        </button >
                      </div>
                      : ""
                    :
                    // OOS CART
                    isMobile ?
                      <div style={{ marginRight: isMobile ? "2rem" : "", float: isMobile ? "right" : "" }} className={`${isMobile ? '' : 'col'}  my-auto`} role="group" aria-label="Basic mixed styles example">
                        <div className="btn-group-vertical gap-1 w-75 my-auto">
                          {
                            window.location.pathname === '/checkout-page' &&
                            <button
                              id={`btnMinusCart${item.item_id}`}
                              style={{ borderRadius: "4px", fontSize: "10px", width: "95px", marginRight: isMobile ? "" : "2rem" }}
                              className={`btn btn-danger oosItem`}
                              onClick={btnMinusOnClick}
                            >
                              Remove
                            </button>
                          }
                          {

                            notifId ?
                              <button
                                style={{ borderRadius: "4px", cursor: "not-allowed", fontSize: "10px", width: "95px", marginRight: isMobile ? "" : "2rem" }}
                                className={`btn btn-secondary p-0 pt-2 pb-2`}
                              >Updating soon
                              </button> :
                              <button
                                id={`btnMinusCart${item.item_id}`}
                                style={{ borderRadius: "4px", fontSize: "10px", width: "95px", marginRight: isMobile ? "" : "2rem" }}
                                className={`btn btn-warning oosItem`}
                                onClick={btnNotifyMe}
                              >
                                Notify
                              </button>
                          }
                        </div>
                      </div>
                      : ""
                }
              </div>
            }
          </div >

          {search === "onCart" && !isMobile && window.location.pathname !== '/' ?

            (item.item_status).toLowerCase() === "disabled" ?
              <div style={{ marginRight: isMobile ? "2rem" : "", float: isMobile ? "right" : "" }} className={`${isMobile ? '' : 'col'}  my-auto`} role="group" aria-label="Basic mixed styles example">
                <div className="btn-group-vertical gap-1 w-75 my-auto">
                  <button
                    id={`btnMinusCart${item.item_id}`}
                    style={{ borderRadius: "4px", fontSize: "10px", width: "95px", marginRight: isMobile ? "" : "2rem" }}
                    className={`btn btn-danger oosItem`}
                    onClick={btnMinusOnClick}
                  >
                    Remove
                  </button>
                  {

                    notifId ?
                      <button
                        style={{ borderRadius: "4px", cursor: "not-allowed", fontSize: "10px", width: "95px", marginRight: isMobile ? "" : "2rem" }}
                        className={`btn btn-secondary p-0 pt-2 pb-2`}
                      >Updating soon
                      </button> :
                      <button
                        id={`btnMinusCart${item.item_id}`}
                        style={{ borderRadius: "4px", fontSize: "10px", width: "95px", marginRight: isMobile ? "" : "2rem" }}
                        className={`btn btn-warning oosItem`}
                        onClick={btnNotifyMe}
                      >
                        Notify
                      </button>
                  }
                </div>
              </div>
              : ""
            : ""
          }
        </div >
      </div >
    </div >
  );
};

export default ItemCard;