/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : FbLogin
 * File Directory           : src/components/APIs/LoginAPI
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In					        : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   FBLogin        -   component for showing FBLogin button
 *
 * #How to call:
 *    <FBLogin/>
 *
 *******************************************************************************************/
/*global FB */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { signIn, signOut } from '../../../actions'
import { useDispatch, connect } from 'react-redux';
import Home from '../Home';


const colorBtn = {
    backgroundColor: ' #4769B0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontSize: '16px',
    margin: '15px',
    borderRadius: '8px',
    color: '#fff'
}


const Fblogin = (props) => {
    let body = document.body; // for setting body height (modal)
    const isMobile = localStorage.getItem("isMobile") === "true";
    //getting auth key
    const _extends = Object.assign || function (target) {
        for (let i = 1; i < arguments.length; i++) {
            const source = arguments[i]
            for (const key in source) {
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key]
                }
            }
        }
        return target
    }

    const useFacebook = function () {
        // const appId = '558243781933754'
        const appId = '772647424099163'
        const [isReady, setIsReady] = useState(false)

        const facebook = {
            //facebook login function
            login: async () => {
                const { authResponse, status } = await new Promise(resolve => window.FB.login(resolve, { scope: 'public_profile,email,user_birthday,user_gender' }))
                if (!authResponse) return { status }

                return new Promise(resolve => window.FB.api('/me', { locale: 'en_US', fields: 'first_name,last_name,name,birthday,email,gender,picture' }, me => {
                    _extends(me, { authResponse, status })
                    resolve(me)
                }))
            },
            //facebook logout function
            logout: async () => {
                const { authResponse, status } = await new Promise(resolve => window.FB.logout(resolve))
                if (!authResponse) return { status }

                return new Promise(resolve => window.FB.api('/me', { locale: 'en_US', fields: 'first_name,last_name,name,birthday,email,gender,picture' }, me => {
                    _extends(me, authResponse)
                    resolve(me)
                }))
            }
        }

        useEffect(() => {
            //connecting fb using SDK
            window.fbAsyncInit = () => {
                FB.init({
                    appId,
                    autoLogAppEvents: true,
                    cookie: true,
                    xfbml: true,
                    status: true,
                    version: 'v12.0'
                })
                //getting fb login status
                FB.getLoginStatus(function (response) {
                    if (response.status === 'connected') {
                        dispatch(signIn())
                    }
                    setIsReady(true)
                }, true);

            };

            (function (d, s, id) {
                if (d.getElementById(id)) {
                    setIsReady(true)
                    return
                }
                const fjs = d.getElementsByTagName(s)[0]
                const js = d.createElement(s)
                js.id = id
                js.src = `https://connect.facebook.net/en-US/sdk.js`
                js.async = true
                js.defer = true
                fjs.parentNode.insertBefore(js, fjs)
            })(document, 'script', 'facebook-jssdk')
        }, [isReady])

        return [facebook, isReady]
    }
    const [facebook, isFacebookReady] = useFacebook()
    // const login = useSelector((state) => {
    //     return (
    //         state.auth.isSignedIn
    //     )
    // })
    const dispatch = useDispatch()
    const history = useHistory()

    //getting logged in response
    const handleFacebookLogin = async () => {
        let hashCheckout = window.location.hash;
        body.classList.remove("body_class");
        body.classList.remove("body_class_");
        const response = await facebook.login()
        if (response.status === 'connected') {
            //saving user info to database
            const loginFB = async () => {
                const result = await Home.post(`/authenticate/FbLogin`,
                    {
                        'headers': {
                            "content-type": "application/json"
                        },
                        params:
                        {
                            customerEmail: response.email,
                            customerName: response.name,
                            customerBirthday: response.birthday,
                            customerGender: response.gender,
                            genID: response.id
                        }
                    })
                const loginDetails = JSON.stringify(result.data.loginDetails[0])
                let accessToken = localStorage.getItem('accessKey')
                Home.post(`/accessStorage/Login`, {
                    accessToken: accessToken,
                    loginDetails: loginDetails
                  })
                sessionStorage.setItem('Login', loginDetails)
                sessionStorage.setItem('FBLogin', true)
                if (hashCheckout === "#checkout") {
                    if (isMobile) {
                        history.push("/checkout-page");
                    } else {
                        history.push("/checkout-details");
                    }
                } else {
                    history.push("/");
                }
            }
            loginFB()
            return dispatch(props.signIn())
        }
    }

    //getiing logged out response
    // const handleFacebookLogout = async () => {
    //     const response = await facebook.logout()
    //     if (response) {
    //         return dispatch(props.signOut())
    //     }
    // }
    //rendering fb button
    const Buttons = () => {
        return (
            isFacebookReady ?
                <button
                    type='button'
                    className='btn btn-info m-0 mt-2 mb-2'
                    onClick={handleFacebookLogin}
                    disabled={!isFacebookReady}
                    style={colorBtn}
                >
                    Login with Facebook
                </button>
                :
                null
        )

    }

    return (
        <>
            {Buttons()}
        </>
    );
};
const mapStateToProps = (state) => {
    return { type: state.auth.isSignedIn }
}
export default connect(
    mapStateToProps,
    { signIn, signOut }
)(Fblogin);