/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : OrderDetails
 * File Directory           : src/components/Checkout/OrderDetails
 * Module                   : Items
 * Author                   : Jenhel
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <OrderDetails/>
 *
 * Note: 
 *      Done:
 *      - Viewing inCart only
 *      Still working on the ff:
 *      - Upon 0 Qty on item, remove cart item on the item list
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import "./Checkout.css"
import { connect } from "react-redux";
import CheckoutCardV2 from '../Products/CheckoutCardV2';
import Home from "../APIs/Home";
import NoProducts from "../Products/NoProducts";
const OrderDetails = ({ CartRedux, setTotal }) => {
    let accessKey = localStorage.getItem('accessKey')
    const isMobile = localStorage.getItem("isMobile") || "true"
    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload

    useEffect(() => {
        saveLocalStorage()
        let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
        let itemCount = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_qty : 0) // returning qty per item

        totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
        itemCount = itemCount.reduce((a, b) => a + b, 0) // total of all items
        setTotal({ itemTotal: totalPrice, itemCount: itemCount })
    }, [CartRedux])

    const saveLocalStorage = () => {

        if (!isFirstRender)
            try {
                Home.post('/accessStorage/changeCart', {
                    itemOnCart: CartRedux,
                    accessToken: accessKey
                })
            } catch (err) {
                console.log(err.message)
            }
        else
            setIsFirstRender(false)
    }

    //change item image
    const renderedItems = CartRedux.slice(0).reverse().map((item, index) => {
        let photo = item.item_image
        photo = photo === null ? "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" : photo
        return (<CheckoutCardV2 item={item} setTotal={setTotal} />)
    })
    return (
        <div id="cartdetCheckout" className={`${!isMobile ? '' : 'col'}`}>
            <div className="row bg-light rounded-top">
                <h5 className="my-auto pt-2">Cart Details</h5>
            </div>

            <div className='row cart bg-light rounded-bottom row productsCheck p-0 checkout-cart' style={{ height: "25rem" }}>
                <div className="col border-bottom p-2">
                    {CartRedux.length > 0 ?
                        renderedItems
                        :
                        <NoProducts />
                    }
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        CartRedux: state.cart.CartList
    }
}
export default connect(mapStateToProps)(OrderDetails);