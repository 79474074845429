const INTIAL_STATE = {
    link_id   : ''
};

const XenditRetrieve = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'XenditRetrieve':
            return {
                ...state,
                link_id   : action.link_id
            };
        default:
            return state;
    }

};

export default XenditRetrieve;
