/*******************************************************************************************
//  * File Name                : ConfirmContactModal
//  * File Directory           : src/components/Home/ConfirmContactModal
//  * Module                   : ConfirmContactModal
//  * Author                   : Jake
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : ItemList
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ConfirmContactModal        -   component for pop up item details
 *
 * #How to call:
 *    <ConfirmContactModal/>
 *
 * Note:
 *******************************************************************************************/
 import React, { useState, useRef, useEffect } from "react";
 import UseOutsideClick from "./UseOutsideClick"
 import ReactPixel from 'react-facebook-pixel';
 import { Link, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
 import Home from '../APIs/Home';
 import _ from 'lodash'
 const style = {
   addButtonColor: {
     border: "2px solid #24b14b",
     borderRadius: "25px",
     background: "#24b14b",
     color: "white",
     fontWeight: "bold",
     fontSize: "14px",
     padding: "0",
     width: "170px",
     height: "39.5px",
     float: "right"
   },
   itemModal: {
     'transition': 'all 0.8s ease',
   },
   showModal: {
     'display': 'block',
     'background': 'rgba(0, 0, 0, 0.5)',
     'transition': 'all 0.8s ease',
     'top': '0px',
     zIndex:'1115'
   }, modalDialog: {
     'margin': '1.5rem'
   },
   modalContent: {
     'height': 'auto'
   },
   textField: {
     'textAlign': "center",
     'float': "right",
     'background': "transparent",
     'margin': "3px"
   },
   textItemTitle: {
     fontSize: "1.1rem"
   },
   textItemVariant: {
     fontSize: "0.8rem"
   },
   textItemPrice: {
     fontSize: "0.9rem"
   },
   textDescription: {
     width:"100%",
     border:'none',
     background:'transparent',
     whiteSpace:'pre-wrap',
     overflowWrap:'break-word',
     lineHeight:'1.5'
   },
   incrementButtonColor: {
     border: "2px solid #24b14b",
     borderRadius: "25px",
     background: "#24b14b",
     color: "white",
     fontWeight: "bold",
     float: "right",
     fontSize: "14px",
     padding: "0",
     width: "30px",
     height: "30px"
   },
   decrementButtonColor: {
     border: "2px solid #24b14b",
     borderRadius: "25px",
     background: "#white",
     color: "#24b14b",
     fontWeight: "bold",
     float: "right",
     fontSize: "14px",
     padding: "0",
     width: "30px",
     height: "30px"
   }, closeButton: {
     float: 'right'
   },
   headerItemTitle :{
     color: "#222",
     textDecoration: "none",
     fontSize: "20px"
   },url:{
    textDecoration:"unset",
    color:"rgb(36, 177, 75)"
  }
 
 }
 let defaultItem = {
   item_id: 0,
   item_name: "Product Name",
   item_variant: "500g",
   item_price: 300,
   item_status:"enabled",
   item_description: "Description here - 500g",
   item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
 }
const ConfirmContactModal = ({ title = "", userLoginNotify = null, setItemNotify, itemNotify, setItemDetailModal, mode = null,
      notifyItem,
      notifyLogin,
      notifyContact}) => {
    const ref = useRef();
    const dispatch = useDispatch()
    const [delayStatus, setDelayStatus] = useState(false); // show modal and delay on closing it.
    const [userPreferContact, setUserPreferContact] = useState(""); // show modal and delay on closing it.
    const [userEmail, setUserEmail] = useState("Customer Email"); // show modal and delay on closing it.
    const [userNumber, setUserNumber] = useState("Customer Number"); // show modal 
    const history = useHistory();
    let branch_id = JSON.parse(localStorage.getItem('SelectedCity')) || {}
    branch_id = branch_id.branch_id
    const [errors, setErrors] = useState({
        email:"",
        phoneNumber:""
    }); // show modal and delay on closing it.
    useEffect(() => {
    if (notifyContact) {
        title='login'
      }
    },[notifyContact])
    // let errors={
    //     email:"",
    //     phoneNumber:""
    // }
    const isMobile = localStorage.getItem("isMobile") || "true"
    let body = document.body; // for setting body height (modal)

   //for clicking outside close modal
  //  UseOutsideClick(ref, () => {
  //    if (delayStatus) {
  //      setConfirmContactModalStatus(false)
  //     //  if(!mode && !window.location.href.includes('checkout-page') && !window.location.href.includes('checkout-details'))
  //     //  setItemDetailModal(defaultItem)
  //      if (!window.location.href.includes('checkout-details'))
  //        window.history.pushState({}, document.title, "/");   // window.location.hash=item.item_name.replace(/ /g,'');
  //      else
  //        window.history.pushState({}, document.title, "/checkout-details");
  //    }
  //  });
  //  //timing out of opening and closing of modal
  //  setTimeout(() => {
  //    setDelayStatus(notifyContact)
  //  }, 100);

   //trigger close modal
   const closeModal = () => {
    dispatch({
      type: "SET_CONFIRM_CONTACT",
      value: false
    });
    //  setConfirmContactModalStatus(false)
    //  if(!mode && !window.location.href.includes('checkout-page') && !window.location.href.includes('checkout-details'))
    //   setItemDetailModal(defaultItem)
     if (!window.location.href.includes('checkout-details'))
       window.history.pushState({}, document.title, "/");   //removeItem Name on URL
     else
       window.history.pushState({}, document.title, "/checkout-details");
   }
   useEffect(() => {
       if(true){
        // alert("LOGIN")
        if(notifyLogin){
          // console.log(userLoginNotify)
          let userDetails=JSON.parse(sessionStorage.getItem("Login"))
          setUserEmail(userDetails.customer_email)
          setUserNumber(userDetails.customer_phone)
          const loginDetails = JSON.stringify(userLoginNotify);
        }else{
          let userDetails=JSON.parse(sessionStorage.getItem("Login"))
          setUserEmail(userDetails?userDetails.customer_email:"")
          setUserNumber(userDetails?userDetails.customer_phone:"")
        }
       }
   }, [notifyContact])
   
    const submitNotify = async (e) => {
        e.preventDefault()
        let phoneNumber=e.target.customerNumber.value
        let customerEmail=e.target.customerEmail.value
        let emailCheck = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
        let err=0
        let checkboxes = document.querySelectorAll(`input[name="customerPrefer${notifyLogin?'login':''}"]:checked`);
        let customerPrefer = [];
        customerPrefer=[]
        checkboxes.forEach((checkbox) => {
          customerPrefer.push(checkbox.value);
        });
        if(customerPrefer.length>0){
          if (customerPrefer.includes("email")) {
              if (emailCheck.test(customerEmail) === false) {
                  err+=1
                  var element = document.getElementById(`emailError${notifyLogin?'login':''}`)
                  element.classList.remove("d-none");
              }
          }
          if (customerPrefer.includes("phone")) {
              if (phoneNumber.length > 10 || phoneNumber.length < 10) {
                  err+=1
                  var element = document.getElementById(`phoneNumberError${notifyLogin?'login':''}`)
                  element.classList.remove("d-none");
              }
          }
          if(phoneNumber===""&&customerEmail===""){
            var element = document.getElementById(`emptyNotif${notifyLogin?'login':''}`)
            element.classList.remove("d-none");
          }else
            if(err===0){
              // console.log(userLoginNotify)
              var element = document.getElementById(`emptyNotif${notifyLogin?'login':''}`)
              element.classList.add("d-none");
              let user=JSON.parse(sessionStorage.getItem('Login'))
              let userEmail=user.customer_email
              let userId=user.customer_id
              let items=notifyItem
              setItemNotify({
                item_id:notifyItem.item_id,
                item_sku:notifyItem.item_sku,
                item_name:notifyItem.item_name,
                notify_id:"success"
              })
              let branch=JSON.parse(localStorage.getItem('SelectedCity'))
              // if(notifyLogin){
              //   user=userLoginNotify
              //   items=JSON.parse(localStorage.getItem('notifyItem'))
              //   notifyItem=items
              //   mode=null
              // }
              // console.log(notifyItem)
              let result = await Home.post('/notifyItem/add', {
                userId: userId,
                item_sku: mode?items:notifyItem.item_sku,
                item_branch: (mode?branch.branch_id:notifyItem.item_branch)||branch_id,
                customerEmail: customerEmail,
                phoneNumber:phoneNumber,
                mode:mode?mode:null,
                customerPrefer:customerPrefer.toString()
              })
              dispatch({
                type: "SET_NOTIFY",
                value:  {
                  item_sku: notifyItem.item_sku,
                  item_branch: notifyItem.item_branch,
                  notified: true
                },
                login: false
              });
              user.customer_email=customerEmail
              user.customer_phone=phoneNumber
              user.preferredNotification=customerPrefer.toString()
              sessionStorage.setItem('Login', JSON.stringify(user))
              var element = document.getElementById(`emptyNotif${notifyLogin?'login':''}`)
              element.classList.add("d-none");
              var element = document.getElementById(`successNotif${notifyLogin?'login':''}`)
              element.classList.remove("d-none");
              var element = document.getElementById(`emailError${notifyLogin?'login':''}`)
              element.classList.add("d-none");
              var element = document.getElementById(`phoneNumberError${notifyLogin?'login':''}`)
              element.classList.add("d-none");
              if(notifyLogin){
                history.push("/");
                window.location.href = "/";
              }else{
                setTimeout(() => {
                  closeModal()
                  element.classList.add("d-none");
                }, 500);
              }
              
            }else{
              var element = document.getElementById(`emptyNotif${notifyLogin?'login':''}`)
              element.classList.remove("d-none");
            }
        }else{
          var element = document.getElementById(`emptyNotif${notifyLogin?'login':''}`)
          element.classList.remove("d-none");
        }
    }
   return (
     <React.Fragment>
 
        <div id="itemModal">
            <div className='modal'
           style={notifyContact ? style.showModal : {}}
           tabIndex="-1"
           role="dialog">
                <div className="modal-dialog modal-md" style={style.modalDialog} role="document" ref={ref}>
                    <div id="modal-content" className="modal-content" style={style.modalContent}>
                        <div className="modal-header">
                            <h5>Preferred Contact Detail</h5>
                            <div className="float-right">
                              <button className='btn btn-transparent'
                                  style={style.closeButton}
                                  onClick={closeModal}
                              >x</button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div>
                                <form onSubmit={submitNotify} name="ProductForm">
                                    <div className="alert alert-danger d-none" id={`emptyNotif${notifyLogin?'login':''}`}>
                                      <span className="">Please choose atleast one contact detail</span>
                                    </div>
                                    <div className="alert alert-success d-none successNotif" id={`successNotif${notifyLogin?'login':''}`}>
                                      <span className="" >Notify Added</span>
                                    </div>
                                    <div className="mb-3 form-check">
                                      <input type="checkbox" className="form-check-input" 
                                      id={`customerPreferEmail${notifyLogin?'login':''}`}
                                      name={`customerPrefer${notifyLogin?'login':''}`}
                                      value="email"
                                      defaultChecked="checked" 
                                      />
                                    <label>
                                        Email:
                                    </label>
                                    <input
                                        type="text"
                                        value={userEmail}
                                        name="customerEmail"
                                        className="form-control"
                                        id="customerEmail"
                                        onChange={ (e) =>
                                          setUserEmail(e.target.value)
                                        }
                                    />
                                    <p className="error text-danger d-none emailError" id={`emailError${notifyLogin?'login':''}`}>Invalid Email Format</p>
                                    </div>
                                    <div className="mb-3 form-check">
                                      <input type="checkbox" className="form-check-input" 
                                      id={`customerPreferPhone${notifyLogin?'login':''}`}
                                      name={`customerPrefer${notifyLogin?'login':''}`}
                                      value="phone"
                                      defaultChecked="checked" 
                                      />
                                      <label>
                                          Phone Number:
                                      </label>
                                      <div className="input-group">
                                          <div className="input-group-prepend">
                                              <span className="input-group-text" id="basic-addon1">(+63)</span>
                                          </div>
                                          <input 
                                              type={"number"} 
                                              value={userNumber} 
                                              style={{padding:".375rem .75rem !important"}}
                                              pattern="[0-9]" 
                                              autoComplete="off" 
                                              className="form-control" 
                                              name="customerNumber" 
                                              id="customerNumber"
                                              maxLength={10}
                                              onChange={ (e) =>
                                                setUserNumber(e.target.value)
                                              }/>
                                      </div>
                                      <p className="error text-danger d-none phoneNumberError" id={`phoneNumberError${notifyLogin?'login':''}`}>Format should be: (+63) 9xxxxxxxxx</p>
                                    </div>
                                    <div className="mb-3 ">
                                      <small className="form-check-label" htmlFor="confirmingAgreement">By confirming to be notified, you agree to our <Link style={style.url} to="/ContentPage/privacy-policy">Privacy Policy</Link> and <Link style={style.url} to="/ContentPage/terms">Terms & Condition</Link>.</small>
                                    </div>
                                    <br></br>
                                    <button 
                                        className="btn"
                                        style={{
                                          background:"#24b14b",
                                          color:"white",
                                          width:"100%"
                                        }}
                                        type="submit"
                                    >
                                        Confirm Contact Details
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     </React.Fragment>
   );
 };
 
const mapStateToProps = (state) => {
    
    return {
      notifyItem:state.notify.NotifyItem,
      notifyLogin:state.notify.VisibleLogin,
      notifyContact:state.notify.ConfirmContact
    };
};

export default connect(mapStateToProps)(ConfirmContactModal);