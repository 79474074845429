import React, { useEffect, useMemo } from "react";
import { connect } from 'react-redux';
import ThankYou from "../../Checkout/ThankYou";
import Home from "../Home";
import ZaganaLogo from '../../Shared/ZaganaLogo';
import Paymongo from 'paymongo';
import Header from "../../Shared/Header"
import { storeFrontLogs } from "../Logs";
import { SaveToSession } from "../../Shared/SaveToSession";
import { quoteShipment, bookShipment } from "../../APIs/Shipmates";
const styles = {
    container: {
        height: "100vh",
        padding: "3rem"
    },
    headerBG: {
        'backgroundColor': '#facc02'
    }
}

const Success = () => {
    const isMobile = localStorage.getItem("isMobile") === "true"
    const orderDetails = JSON.parse(sessionStorage.getItem('order'))
    const paymongo = JSON.parse(sessionStorage.getItem('paymongo'))
    const totalAmount = JSON.parse(sessionStorage.getItem('orderTotal'))
    const payment = sessionStorage.getItem('payment')
    const SECRET_KEY = process.env.REACT_APP_PAYMONGO_SECRET_KEY
    const paymongoAPI = new Paymongo(SECRET_KEY);
    const doubleTotalAmount = totalAmount * 100
    const shipData = JSON.parse(sessionStorage.getItem('selectedPaymentMethod'))
    const order_info = JSON.parse(sessionStorage.getItem('order'))
    let accessKey = localStorage.getItem('accessKey')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        SaveToSession({
            page: 'Thank You Page',
            path: window.location.search
        })
    }, [])
    const data = useMemo(() => {
        const data = {
            data: {
                attributes: {
                    amount: parseInt(doubleTotalAmount),
                    currency: 'PHP',
                    description: 'ZAGANA ORDER ' + orderDetails.job_order,
                    source: {
                        id: paymongo.id, // Id of the Source resource.
                        type: 'source', // 
                    }
                }
            }
        }
        return data
    }, [doubleTotalAmount, orderDetails.job_order, paymongo.id])

    const body = useMemo(() => {
        const body = {
            payID: paymongo.id,
            totalAmount: totalAmount,
            checkID: orderDetails.check_id,
            paymentMethod: payment,
            accessKey: accessKey
        }
        return body
    }, [orderDetails.check_id, payment, paymongo.id, totalAmount, accessKey])

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || Thank you page`,
            type: `view_sucess_page`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
        const updatePaymongo = async () => {
            try {
                await paymongoAPI.payments.create(data);
            } catch (err) {
                console.log(err)
            }
        }
        updatePaymongo()
        const book = async () => {
            try {
                let result = await bookShipment(shipData)
                const response_shipment = await Home.post(`/save_courier_info`, { order_id: order_info.check_id, courier: result.data })
            } catch (err) {
                console.log(err)
            }
        }
        book()
    }, [data, paymongoAPI.payments])


    useEffect(() => {
        //update order status to 'To Fulfill'
        const updateOrderStatus = async () => {
            try {
                const response = await Home.post(`/paymongo/payment/succeeded`, body)
                if (response.data.result === 'Pay ID recorded') {
                    localStorage.removeItem('cart')
                    sessionStorage.removeItem('total')
                    sessionStorage.removeItem('payment')
                    sessionStorage.removeItem('deliveryTime')
                    sessionStorage.removeItem('deliveryDate')
                    sessionStorage.removeItem('orderTotal')
                    sessionStorage.removeItem('paymongo')
                    sessionStorage.removeItem('notes')
                    sessionStorage.removeItem('stops')
                    sessionStorage.removeItem('NumStops')
                }
            } catch (err) {
                console.log(err)
            }
        }
        updateOrderStatus()
    }, [body])

    return (
        <React.Fragment>
            {isMobile ?
                <Header title="Thank You" />
                : <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                    <div className="row">

                        <div className="col-1 d-none d-sm-block text-center">
                            <a href="/">
                                <ZaganaLogo />
                            </a>
                        </div>
                    </div>
                </div>
            }
            <ThankYou paymongo={true} />
        </React.Fragment>
    )

}
const mapStateToProps = (state) => {
    return ({
        id: state.OrderID.order_id
    })
}
export default connect(mapStateToProps)(Success);