/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : Payments
 * File Directory           : src/components/Checkout/Payments
 * Module                   : Items
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for Payment method list
 *
 * #How to call:
 *    <Payments/>
 *
 * Note:
 *     
 *     
 *    
 *     
 *******************************************************************************************/
import React, { useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Gcash from "../../assets/site_icons/GCash.svg"
import Grabpay from "../../assets/site_icons/GrabPay.svg"
import Debit from "../../assets/site_icons/Credit-Debit Card.svg"
import COD from "../../assets/site_icons/COD.svg"
import UB from "../../assets/site_icons/unionbank-icon-black.svg"
import BPI from "../../assets/site_icons/bpi-icon-black.svg"
import PAYMAYA from "../../assets/site_icons/paymaya.svg"
import landbank from "../../assets/site_icons/landbank.svg"
import atome from "../../assets/site_icons/atome.svg"
import billease from "../../assets/site_icons/billease.svg"
import { storeFrontLogs } from "../APIs/Logs";
import "./Checkout.css"

const style = {
    img: {
        width: '6rem',
        height: '67px',
        padding: '0.5rem'
    },
    selected: {
        outlineColor: "#24b14b",
        outlineStyle: "solid",
        boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
        width: "100%",
        height: "102px"
    },
    paymentBtn: {
        width: "100%",
        height: "102px"
    },
    paymayaimg: {
        width: "90%",
        height: "90%",
        marginTop: "1rem"
    },
    img2: {
        width: '90%',
        padding: '0px',
        marginTop: '9%'
    },
    landbankimg: {
        width: "90%",
        height: "90%",
        display: "flex",
        margin: "0 auto",
        paddingTop: "15%"
    }
}

const Payments = ({ payment, orderTotal, disabledCOD }) => {
    const dispatch = useDispatch()
    const isMobile = localStorage.getItem("isMobile") === "true" //for checking mobile view
    const LBLogin = sessionStorage.getItem('LBLogin') === "true" || false // for checking Lanbank login
    const LBLoginRef = useRef();
    const nonCOD = sessionStorage.getItem('disabledCOD') === "true" // for disabling COD
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || ''; // getting user info
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
    let paymentList, userId = getUser.customer_id || 0;
    //payloads for storeront logs 
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }
    //if Landbank login auto selected
    useEffect(() => {
        if (LBLogin === "true") {
            LBLoginRef.current.click()
        }
    }, [])
    //if  COD is disabled
    useEffect(() => {
        if (disabledCOD && payment === 'COD') {
            dispatch({
                type: "SELECTED_PAYMENT",
                selected: "Select payment",
                status: true
            })
        }
    }, [disabledCOD])

    const onClickPayment = (e) => {
        sessionStorage.setItem('payment', e.currentTarget.id.replace("-", "/"))
        let payment = e.currentTarget.id.replace("-", "/").toLowerCase() // for saving redux 
        dispatch({
            type: "SELECTED_PAYMENT",
            selected: e.currentTarget.id.replace("-", "/"),
            status: true
        })
        //saving to db for storefrontlogs
        const payload = {
            user: user,
            changes: `Zagana || Checkout`,
            type: `selected_${payment}_payment`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }

    if (LBLogin) {
        paymentList = [
            {
                id: "LandBank",
                paymentName: "LANDBANK",
                image: landbank,
                display: "block"
            }
        ]
    } else {
        paymentList = [
            {
                id: "COD",
                paymentName: "CASH ON DELIVERY",
                image: COD,
                display: "block"

            },
            {
                id: "Debit-Credit",
                paymentName: "DEBIT/CREDIT",
                image: Debit,
                minimum: 100,
                display: "block"
            },
            {
                id: "GCash",
                paymentName: "GCASH",
                image: Gcash,
                minimum: 100,
                display: "block"
            },
            {
                id: "Grabpay",
                paymentName: "GRABPAY",
                image: Grabpay,
                minimum: 100,
                display: "block"
            },
            {
                id: "UB",
                paymentName: "Union Bank",
                image: UB,
                minimum: 0,
                display: "block"
            },
            {
                id: "BPI",
                paymentName: "BPI",
                image: BPI,
                minimum: 100,
                display: "block"
            },
            {
                id: "Paymaya",
                paymentName: "Paymaya",
                image: PAYMAYA,
                minimum: 100,
                display: "block"
            },
            {
                id: "LandBank",
                paymentName: "LANDBANK",
                image: landbank,
                minimum: 100,
                display: "block"
            },
            {
                id: "Atome",
                paymentName: "Atome",
                image: atome,
                minimum: 200,
                display: "block"
            },
            {
                id: "Billease",
                paymentName: "Billease",
                image: billease,
                minimum: 150,
                display: "block"
            },
        ]
    }
    const renderPaymentList = paymentList.map((data, index) => {
        // console.log(orderTotal + "||" + data.minimum)
        const renderPaymentName = data.id === "COD" || data.id === "Debit-Credit"
        return (
            <React.Fragment>
                {isMobile ?

                    <div key={index} ref={LBLoginRef} className={
                        `paymentMethod p-2 m-2
                        ${data.display}
                             ${data.id === "COD" ?
                            nonCOD || disabledCOD ? 'disabledCOD' : ''
                            : orderTotal === 0 || orderTotal <= data.minimum ? 'disabledCOD' : ''}`
                    }
                        id={data.id}
                        onClick={onClickPayment}
                        style={payment.replace("/", "-") === data.id ? style.selected : null}
                    >
                        <div className="card-body" >
                            <div className="row text-center " >
                                <div className="col-6">
                                    <img
                                        src={data.image}
                                        alt={data.id + "_logo"}
                                        // style={data.id === "LandBank" ? null :
                                        //     style.img}
                                        // className={data.id === "LandBank" ? "filterDark" : null}
                                        style={style.img}
                                    />
                                </div>
                                <div className="col-6 my-auto">
                                    {
                                        orderTotal <= data.minimum ?
                                            <div>
                                                <small style={{ color: "#ffc459" }} ><svg xmlns="http://www.w3.org/2000/svg" fill="#ffc459" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" /></svg> Minimum: {data.minimum}</small>
                                            </div> :
                                            <strong>
                                                {data.paymentName}
                                            </strong>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    < div key={index} className="paymentDesktop m-1" >

                        <div ref={LBLoginRef} className={
                            `card p-2
                        ${data.display}
                        ${data.id === "COD" ?
                                nonCOD || disabledCOD ? 'disabledCOD' : ''
                                : orderTotal === 0 || orderTotal <= data.minimum ? 'disabledCOD' : ''}`
                        }
                            id={data.id}
                            onClick={onClickPayment}
                            style={payment.replace("/", "-") === data.id ? style.selected : style.paymentBtn}
                        >
                            <div className={`card-body ${isMobile ? "p-3 pb-2" : "p-0"} `} >
                                <div className="row text-center " >
                                    <div className="row-6 p-0 m-0">
                                        <img src={data.image} alt={data.id + "_logo"}
                                            className="img img-fluid"
                                            style={
                                                data.id === "UB" ? style.img2 :
                                                    data.id === "Paymaya" ? style.paymayaimg :
                                                        style.img
                                            }
                                        />
                                    </div>
                                    <div className={isMobile ? "col-6 my-auto" : "row-6 my-auto p-0"}>
                                        {
                                            orderTotal <= data.minimum ?
                                                <div>
                                                    <small style={{ color: "#ffc459" }} ><svg xmlns="http://www.w3.org/2000/svg" fill="#ffc459" width="16" height="16" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 6h2v8h-2v-8zm1 12.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" /></svg> Minimum: {data.minimum}</small>
                                                </div> :
                                                <strong>
                                                    {renderPaymentName ? data.paymentName : null}
                                                </strong>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                }
            </React.Fragment>
        )
    })

    return (

        < React.Fragment >
            {isMobile ?
                <div className="container mt-2 mb-5 pb-2">
                    <div className="row">
                        <div className="col p-0">
                            {renderPaymentList}
                        </div>
                    </div>
                </div>
                :
                <div className="container mt-2 pb-2">
                    <div className="row m-1">
                        <div className="paymentContainer">
                            {renderPaymentList}
                        </div>
                    </div>
                </div>
            }
        </React.Fragment >
    )
}

const mapStateToProps = (state) => {
    return {
        payment: state.payment.paymentSelected
    }
}
export default connect(mapStateToProps)(Payments);