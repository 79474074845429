
//  * File Name                : DeliveryFields
//  * File Directory           : src/components/Home/SortBy
//  * Module                   : SortBy
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DeliveryFields        -   component for setting delivery schedule
 *
 * #How to call:
 *    <SortBy/>
 *
 * Note: Still working on the ff:
 * UI and Link (Not yet tested)
 * 
 * 

 *******************************************************************************************/
import React, { useState, useRef, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import arrowDown from '../../assets/site_icons/arrowdown.svg';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-scroll';
import { storeFrontLogs } from "../APIs/Logs";
import { Form } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { useParams } from "react-router-dom";
import ReactGA from 'react-ga4'
import Home from '../APIs/Home';

const style = {
    sortByForm: {
        width: ' 80%',
        backgroundColor: '#fff',
        color: '#000',
        textAlign: 'left'
    },
    bgColorNav: {
        background: '#f4cc0e'
    },
    linkColorW: {
        color: '#222',
        fontWeight: '500'
    },
    labelFont: {
        fontSize: '13px'
    },
    width80: {
        width: "13vw"
    },
    category: {
        cursor: 'pointer',
        width: '80%'
    }

}

const SortBy = ({ options, selected, onSelectedChange, label, active, slide, viewing, setViewing, styles, refresh, category, count, setCount }) => {
    const [visible, setVisible] = useState(true);
    const [filteredOptions, setFilteredOptions] = useState([])
    const ref = useRef();
    const isMobile = localStorage.getItem("isMobile");
    const [slidePos, setSlidePos] = useState("");
    const [scrollPosition, setScrollPosition] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    const [filteredItems, setFilteredItems] = useState([]);
    const [checkerCateg, setCheckerCateg] = useState(true);
    const [categLength, setCategLength] = useState(0);
    const sku_tags = useParams();
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;


    if (gen_id) {
        gen_id = localStorage.getItem('gen_id')
        user = gen_id
    }
    else if (isLogged) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    }

    useEffect(() => {
        if (isMobile === "true") {
            if (sku_tags.categoryTag) {
                let tags = filteredItems
                tags = [{
                    category_tag: sku_tags.categoryTag,
                    category_name: sku_tags.categoryName,
                    category_id: sku_tags.id
                }]
                setFilteredItems(tags)
                dispatch({
                    type: "SELECTED_CATEGORY",
                    selected: tags
                })
            } else {
                dispatch({
                    type: "SELECTED_CATEGORY",
                    selected: []
                })
            }
        }
    }, [])
    
    const renderedOptions = options.map((option, index) => {
        if (option.category_name === label) {
            return null;
        }
        return (
            <Link id={`li_${option.category_name.replace(" ", '').replace(" ", '').toLowerCase()}`} to={`${option.category_tag.replace(" ", '').toLowerCase()}`} spy={true} smooth={true}
                onClick={() => {

                    window.history.pushState('page2', 'Zagana | Home', '?' + option.category_name.replace(/ /g, ''));

                    const payload = {
                        user: user,
                        changes: `Zagana || HomePage`,
                        type: `click_${option.category_tag}_category`,
                        date: `NOW()`
                    }
                    storeFrontLogs(payload)
                    window.gtag("event", "select_content", {
                        content_type: "categories",
                        item_id: option
                    });
                }}
            >
                <Dropdown.Item onClick={() => {
                    window.history.pushState('page2', 'Zagana | Home', '?' + option.category_name.replace(/ /g, ''));

                    onSelectedChange(option)
                    /** Google Analytics */
                    window.gtag("event", "select_content", {
                        content_type: "categories",
                        item_id: option
                    });
                    /** Google Analytics */
                }}
                    key={index}
                >
                    {option.category_name}
                </Dropdown.Item>
            </Link>
        );
    });

    // rendering category list for desktop view
    // const renderedCateg = options.map((option_pc, index) => {
    //     return (
    //         // this conditon is for adding activated styles
    //         <li id={`li_${option_pc.category_name.replace(" ", '').replace(" ", '').toLowerCase()}`} className={`nav-item ${option_pc.category_name.replace(" ", '').toLowerCase() === active.replace(" ", '').toLowerCase() ? 'actived' : ''}`} key={option_pc.category_id}>
    //             <Link to={`${option_pc.category_tag.replace(" ", '').toLowerCase()}`} spy={true} smooth={true} style={style.linkColorW} href={`#${option_pc.category_tag.replace(" ", '').toLowerCase()}`} onClick={() => {

    //                 window.history.pushState('page2', 'Zagana | Home', '?' + option_pc.category_name.replace(/ /g, ''));
    //                 const payload = {
    //                     user: user,
    //                     changes: `HomePage`,
    //                     type: 'Category: '+option_pc.category_tag,
    //                     date: `NOW()`
    //                 }
    //                 storeFrontLogs(payload)
    //                 onSelectedChange(option_pc)
    //                 /** Google Analytics */
    //                 // if(getCookieConsentValue('zaganaCookie')==='true'){
    //                 window.gtag("event", "select_content", {
    //                     content_type: "categories",
    //                     item_id: option_pc
    //                 });
    //                 // }
    //                 /** Google Analytics */
    //             }}>
    //                 &nbsp;<input type="checkbox"/> &nbsp;{option_pc.category_name}
    //             </Link>
    //         </li>
    //     );
    // });
    const toggleCategories = (e) => {
        let collapseCateg = scrollPosition > 80 ? 4 : 9;
        setCheckerCateg(!checkerCateg)
        setCount(!checkerCateg ? collapseCateg : categLength);
    }

    const renderedCateg = options.map((categ, index) => {
        const image = {
            style: {
                backgroundImage: `URL(${categ.file_path})`,
                border: 'unset',
                boxShadow: 'unset',
                backgroundColor: 'unset',
                width: '20%',
                padding: '0.6rem'
            }
        }
        const onChangeCheckbox = (e) => {
            let tags = filteredItems
            let isChecked = e.target.checked
            let name = e.target.id
            let tag = e.target.dataset.tag
            if (isChecked) {
                tags = [{
                    category_tag: tag,
                    category_name: name,
                    category_id: index
                }]
                // tags.sort((a,b)=>{
                //     return a.category_id - b.category_id
                // })
                setFilteredItems(tags)
                dispatch({
                    type: "SELECTED_CATEGORY",
                    selected: tags
                })
                const payload = {
                    user: user,
                    changes: `Zagana || HomePage`,
                    type: `click_${categ.category_tag}_category`,
                    date: `NOW()`
                }
                storeFrontLogs(payload)
                window.scrollTo(0, 250)
                refresh({})
            } else {
                const newArr = tags.filter(object => {
                    return object.category_tag !== tag;
                });
                setFilteredItems(newArr)
                dispatch({
                    type: "SELECTED_CATEGORY",
                    selected: newArr
                })
                setTimeout(() => {
                    window.scrollTo(0, 250)
                }, 150);

            }
        }
        return (
            <Form key={index} id={`form-${(categ.category_tag.toLowerCase())}`}>
                {
                    <div className={categ.category_name === `${category.length > 0 ? category[0].category_name : null}` ? 'actived' : null} >
                        <Form.Check type={'checkbox'} id={categ.category_name} >
                            <Form.Check.Input key={index + `${categ.category_id}`} type={'checkbox'}
                                data-index={index}
                                data-tag={categ.category_tag}
                                style={image.style}
                                checked={categ.category_name === `${category.length > 0 ? category[0].category_name : null}` ? true : false}
                                onChange={onChangeCheckbox}
                                onClick={(e) => {
                                    const category = e.target.dataset.tag
                                    ReactGA.event({
                                               category: category,
                                               action: 'Select Category',
                                           });
                                }}
                            />
                            <Form.Check.Label key={categ.category_id} style={style.category}>{categ.category_name}</Form.Check.Label>
                        </Form.Check>
                    </div>
                }
            </Form>
        )
    })

    const renderedCategMobile = options.map((categ, index) => {

        return (
            <>
                {
                    index < count ?
                        <div style={{ "width": "20%", "padding": "0" }} key={index}  id={`categoryDiv${(categ.category_tag.toLowerCase()).replace(/ /gi,'')}`}>
                            {
                                <a  href={`/home/${categ.category_tag}/${categ.category_name}`} 
                                    onClick={() => {
                                        ReactGA.event({
                                                category: categ.category_name,
                                                action: 'Select Category',
                                            });
                                            }
                                        } 
                                    className="btn btn-light pb-0 pt-0" >
                                    <div className='row'>
                                        <div className={`col-12` }>
                                            {/* <img src={categ.file_path} className="w-100" /> */}
                                            <img src={categ.file_path} width="32" height="32" className={`${(categ.category_tag).toLowerCase() === (sku_tags.categoryTag || "").toLowerCase() ? 'circleIconAct' : 'circleIcon'}`} />
                                        </div>
                                        <div className={`col-12 p-0  ${(categ.category_tag).toLowerCase() === (sku_tags.categoryTag || "").toLowerCase() ? 'categActive' : ''}`} style={{ "lineHeight": "1" }}>
                                            <small style={{ "fontSize": "9px" }}>
                                                {categ.category_name}
                                            </small>
                                        </div>
                                    </div>
                                </a>
                            }
                        </div> : ""
                }
            </>
        )
    })

    useEffect(() => {
        setCategLength(renderedCategMobile.length)
    }, [renderedCategMobile]);

    //toggle to left slide
    const leftSlide = () => {
        setSlidePos("firstSlide")
    }
    //toggle to right slide
    const rightSlide = () => {
        setSlidePos("secondSlide")
    }
    //on scroll toggle the category slider
    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
        setVisible(position < scrollPosition);
        if (checkerCateg === false) {
            setCheckerCateg(!checkerCateg)
        }
    };
    //setting the category upon scrolling
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        setSlidePos(slide)
        return () => {
            window.removeEventListener('scroll', handleScroll);

        };
    }, [scrollPosition]);

    return (
        <React.Fragment>
            {/* Mobile category (go to) */}
            {isMobile === "true" ?
                <div className={`container p-2 ${visible ? "d-block" : "d-none"} `} >
                    <div className='row'>
                        {/* <div>&nbsp;&nbsp;Filter by: </div> */}
                        {renderedCategMobile}
                        <div style={{ "width": "20%" }} >
                            {
                                <a onClick={toggleCategories} className="btn btn-light">
                                    <div className='row'>
                                        <div className='col-12'>
                                            <img src="https://res.cloudinary.com/dw5axbz64/image/upload/v1660634364/upload/option_h0c4w5.png" width="32" height="32" />
                                        </div>
                                        <div className='col-12'>
                                            <small style={{ "fontSize": "10px" }}>
                                                {checkerCateg ? "More" : "Less"}
                                            </small>
                                        </div>
                                    </div>
                                </a>
                            }
                        </div>
                    </div>
                </div>
                // <div className='row'>
                //     <div className="pt-1 pb-1 text-center dropCategory">
                //         <Dropdown
                //             id="dropdown-basic-button "
                //             onClick={() => {
                //                 setVisible(!visible)
                //             }
                //             }
                //             ref={ref} >
                //             <label className="label" style={style.labelFont}>Go to: </label>
                //             <Dropdown.Toggle id="sortby_field" style={style.sortByForm}>
                //                 {label}
                //                 <img src={arrowDown} width="10" alt="v" style={{ 'float': 'right' }} className="mt-1 m-2" />
                //                 <Dropdown.Menu >
                //                     {renderedOptions}
                //                 </Dropdown.Menu>
                //             </Dropdown.Toggle>
                //         </Dropdown>
                //     </div>
                //     <div className="col-2" role="group" style={{ width: "100px", position: 'absolute', right: '-20px', paddingTop: '5px' }}>
                //         <button type="button" style={{ width: '45%', height: '29.1px', padding: 0, border: '0px solid', opacity: "0.5" }} onClick={() => { setViewing('grid'); localStorage.setItem("viewItem", 'grid') }} className={`btn ${viewing === "list" ? 'btn-outline-secondary' : 'btn'}`} >

                //             <svg height="15px" width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.08 154.66"><g id="Layer_2" data-name="Layer 2"><g id="Layer_34" data-name="Layer 34"><rect width="70.95" height="70.95" rx="14.85" /><rect x="84.13" width="70.95" height="70.95" rx="14.85" /><rect y="83.72" width="70.95" height="70.95" rx="14.85" /><rect x="84.13" y="83.72" width="70.95" height="70.95" rx="14.85" /></g></g></svg>

                //         </button>
                //         <button type="button" style={{ width: '45%', height: '29.1px', padding: 0, border: '0px solid', opacity: "0.5" }} onClick={() => {
                //             setViewing('list'); localStorage.setItem("viewItem", 'list')
                //         }
                //         } className={`btn ${viewing === "list" ? 'btn svgStroke' : 'btn-outline-secondary'}`}>

                //             <svg height="15px" width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.22 146.21"><g id="Layer_2" data-name="Layer 2"><g id="Layer_33" data-name="Layer 33"><circle cx="16.43" cy="16.43" r="16.43" /><rect x="46.16" width="123.6" height="32.86" rx="16.43" /><circle cx="16.89" cy="73.1" r="16.43" /><rect x="46.62" y="56.67" width="123.6" height="32.86" rx="16.43" /><circle cx="16.43" cy="129.78" r="16.43" /><rect x="46.16" y="113.35" width="123.6" height="32.86" rx="16.43" /></g></g></svg>
                //         </button>
                //     </div>
                // </div>
                :
                <div>
                    {/* Desktop category (go to) */}
                    <div className="container p-0" style={style.bgColorNav}>
                        {slidePos === "secondSlide" ?
                            <div className="scroller scroller-left" onClick={leftSlide}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                </svg>
                            </div>
                            :
                            <div></div>
                        }
                        {slidePos === "firstSlide" ?
                            <div className="scroller scroller-right" onClick={rightSlide}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                </svg>
                            </div>
                            :
                            <div></div>
                        }

                        <div className="wrapper" id="categoryNav" >

                            <ul className={`nav nav-pills nav-fill list ${slidePos} `} style={styles}>
                                <div>&nbsp;&nbsp;Filter by: </div>
                                {renderedCateg}
                            </ul>

                        </div>
                    </div>
                </div>
            }

        </React.Fragment >
    );
};

const mapStateToProps = (state) => {
    return {
        category: state.Category.selectedCategory
    }
}

export default connect(mapStateToProps)(SortBy);


