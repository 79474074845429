/*******************************************************************************************
* File Name                : Sidebar
* File Directory           : src/components/Shared/Sidebar
* Module                   : Sidebar
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Sidebar.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * @function   Sidebar        -   component for user navigation
 *
 * #How to call:
 *    <Sidebar/>
 *
 * Note: Still working on the ff:
 * - Ability to show user details
 * - about , faqs and blog links is here 
 *******************************************************************************************/

import React, { useState } from "react";
import { Link } from "react-router-dom";
import userIcon from "../../assets/site_icons/Login.svg";
import Home from '../APIs/Home';
import { storeFrontLogs } from "../APIs/Logs";
import { v4 as uuidv4 } from 'uuid';

const style = {
    userDetails: {
        width: '80%',
        display: 'inline-table'
    },
    userImage: {
        verticalAlign: 'text-top'
    },
    links:{
        textDecoration: 'none',
        color: '#24b14b',
    }
}

const details = [
    {
        title: "Home",
        link: "/"
    },
    {
        title: "About Us",
        link: "/ContentPage/about"
    },
    {
        title: "FAQs",
        link: "/ContentPage/FAQs"
    },
    {
        title: "Blogs",
        link: "/ContentPage/blogs"
    },
    {
      title: "Privacy Policy",
      link: "/ContentPage/privacy-policy"
    },
    {
      title: "Terms & Conditions",
      link: "/ContentPage/terms"
    },
    {
      title: "Contact Us",
      link: "/contact-us"
    }
]

function redirectLink(link) {
    window.location.href = link;
}


const SideBar = () => {
    const [sideBar, setSideBar] = useState(true);
    const [clickOut, setClickOut] = useState(true);
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let footer = document.footer; // for setting body height (modal)
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    }else if(gen_id){
        user = gen_id
    }

    const sideBarOpen = () => {
        setSideBar(!sideBar);
        setClickOut(!clickOut);
        document.querySelector('footer').style = 'z-index: 0'
    };
    const sideBarClose = () => {
        setSideBar(!sideBar);
        setClickOut(!clickOut);
        document.querySelector('footer').style = 'z-index: 2'
    };
    const signOut = async () => {
        sessionStorage.removeItem('Login');
        localStorage.removeItem('cart');
        sessionStorage.removeItem('deliveryDate');
        sessionStorage.removeItem('deliveryTime');
        sessionStorage.removeItem('Address');
        localStorage.removeItem('SelectedCity');
        localStorage.setItem('branchSelected',false)
        //dbStorage
        // function uuidv4() {
        //     return `xxxxxxxxxxxxxxx`.replace(/[xy]/g, function (c) {
        //         let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        //         return v.toString(16);
        //     });
        // }
        let link = uuidv4();
        let accessKey = localStorage.getItem('accessKey')
        let Login = sessionStorage.getItem('Login');
        await Home.post('/accessStorage', { accessToken: link })
        localStorage.setItem('accessKey', link)
        window.location.reload();
    };

    const onClickProfile = () => {
        const payload = {
            user: user,
            changes: `Zagana || customer profile`,
            type: 'view_customer_profile',
            date: `NOW()`
        }
        storeFrontLogs(payload)
        redirectLink('/my-account')
    }

    const onClickOrders = () => {
        const payload = {
            user: user,
            changes: `Zagana || order history`,
            type: 'view_my_orders',
            date: `NOW()`
        }
        storeFrontLogs(payload)
        redirectLink('/user-profile')
    }
    const onClickAddress = () => {
        const payload = {
            user: user,
            changes: `Zagana || customer address `,
            type: 'view_my_address',
            date: `NOW()`
        }
        storeFrontLogs(payload)
        redirectLink('/my-directions')
    }
    
    const toggleSide = `bg-light ${sideBar === true ? 'sidenav_hide' : 'sidenav_show'}`;
    const toggleBtn = `${clickOut === true ? 'btnAuto' : 'btnSet'}`;

    const isMobile = localStorage.getItem("isMobile");
    return (
        <React.Fragment>
            {isMobile === "true" ?
                <>

                    <div onClick={sideBarClose} className={toggleBtn}></div>
                        
                        <img id="sideBar" onClick={sideBarOpen} src={userIcon} width="10" />
                    
                    <div className={toggleSide} >
                        <br />
                        {
                            getUser ?
                                <div>
                                    <img src="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" alt="avatar" width="50" style={style.userImage} />
                                    <ul className="list-unstyled p-2" style={style.userDetails}>
                                        <li><h3>{getUser.customer_name}</h3></li>
                                        <li>{getUser.customer_email}</li>
                                        <li><a onClick={onClickProfile} style={style.links}>My Account</a></li>
                                        <li><a onClick={onClickOrders} style={style.links}>My Orders</a></li>
                                        <li><a onClick={onClickAddress} style={style.links}>My Address</a></li>
                                        <li><span onClick={signOut}>Logout</span></li>
                                    </ul>
                                </div>
                                : <ul className="list-unstyled p-2" style={style.userDetails}>
                                    <li><h3 onClick={() => redirectLink('/login')}>Login</h3></li>
                                </ul>
                        }

                        <ul className="list-unstyled p-2">
                            {details.map(item => {
                                return (
                                    <li className="pb-3" onClick={() => redirectLink(item.link)} key={item.title} id={item.title} role="button">
                                        <h3>{item.title}</h3>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </>
                :
                <div></div>
            }

        </React.Fragment>
    )

};


export default SideBar;