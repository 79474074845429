const INTIAL_STATE = {
    Item: {
    },
    View: false
}

const ProductModalReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_VIEW_MODAL':
            return {
                ...state,
                Item: action.selected,
                View: action.view
            };
        default:
            return state;
    }

};

export default ProductModalReducer;