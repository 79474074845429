//  * File Name                : Login
//  * File Directory           : src/components/Login/Login
//  * Module                   : Login
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In					        : src/Login.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Login        -   component for signing in for new customers
 *
 * #How to call:
 *    <Login/>
 *
 * Note: Still working on the ff:
 * - When success should be auto login (Login is still ongoing)
 * - Add gender to the db and api (Done adding for just need to update the created API and database for another column)
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { reduxForm, Field } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import FbLogin from "../APIs/LoginAPI/FbLogin";
import Header from "../Shared/Header";
import Home from "../APIs/Home";
import { forgotModal } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";
import ReactGA from 'react-ga4'

const style = {
  formGroup: {
    marginBottom: "6%",
    position: "relative",
  },
  errorStyle: {
    position: "absolute",
    color: "red",
  },
  formInput: {
    width: "100%",
  },
  backBtn: {
    color: "#6c757d",
    textDecoration: "none",
  },
  oneMore: {
    color: '#f87f00',
    backgroundColor: '#f87f0047',
    borderColor: '#f5c2c7'
  },
  login: {
    borderRadius: '20px'
  }

};
//to show error
const renderError = ({ error, touched }) => {
  if (touched && error) {
    return (
      <div>
        <span className="error" style={style.errorStyle}>
          {error}
        </span>
        <span className="invalid " style={style.errorStyle}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </span>
      </div>
    );
  } else if (!error) {
    return (
      <div>
        <span className="valid">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-check-circle"
            viewBox="0 0 16 16"
          >
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
          </svg>
        </span>
      </div>
    );
  }
};
//to show input via redux
const renderInput = ({
  input,
  type,
  meta,
  value_name,
  class_name,
  value_check,
}) => {
  const className = `field ${meta.error && meta.touched ? "error" : ""} ${!meta.error ? "valid" : ""
    }`;
  let label_radio = value_name;
  if (value_name === "Radio") {
    return <label> {value_name}</label>;
  }
  return (
    <div className={className}>
      <input
        {...input}
        type={type}
        value={value_name}
        autoComplete="off"
        className={class_name}
        checked={value_check}
        style={style.formInput}
      />
      {label_radio}
      <div className="form-control-feedback">{renderError(meta)}</div>
    </div>
  );
};

//to validate field values
const validate = (values) => {
  const errors = {};
  var emailCheck =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  if (!values.customerEmail) {
    errors.customerEmail = "You must enter your Email";
  }
  if (values.customerEmail) {
    if (emailCheck.test(values.customerEmail) === false) {
      errors.customerEmail = "Invalid Email Format";
    }
  }
  if (!values.customerPassword) {
    errors.customerPassword = "You must enter your Password";
  }

  return errors;
};

const Login = ({ valid, login,
  confirmContactmodalStatus,
  setConfirmContactModalStatus,
  userLoginNotify,setUserLoginNotify,
  itemNotify, setItemNotify,
    notifyItem,
    notifyLogin
}) => {
  document.title = "Zagana | Login";
  const history = useHistory();
  const [invalid, setInvalid] = useState("");
  let hashCheckout = window.location.hash;
  const [alertCartStatus, setAlertCartStatus] = useState(false)
  const isMobile = localStorage.getItem("isMobile");
  let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
  let body = document.body; // for setting body height (modal)
  let gen_id = localStorage.getItem('gen_id')
  let user;
  let userLogin;
  if (getUser) {
    userLogin = JSON.parse(sessionStorage.getItem('Login'))
    gen_id = localStorage.getItem('gen_id')
    user = userLogin.customer_name + ` (${gen_id})`
  } else if (gen_id) {
    user = gen_id
  }
  const dispatch = useDispatch()

  if (getUser != "") {
    history.push("/");
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      user: user,
      changes: `Zagana || Login`,
      type: 'click_login',
      date: `NOW()`
    }
    storeFrontLogs(payload)

    body.classList.remove("body_class");
    body.classList.remove("body_class_");
    const values = login.values;
    const details = {
      customerEmail: values.customerEmail,
      customerPassword: values.customerPassword
    };
    const Login = async () => {
      try {
        const response = await Home.post(`/authenticate/login`, details);
        if (response.data.message === "Login Success") {
          ReactGA.event({
                     category: "Login",
                     action: 'User Login',
                 });
          let login=response.data.loginDetails[0]
          const loginDetails = JSON.stringify(response.data.loginDetails[0]);
          let accessToken = localStorage.getItem('accessKey')
          let result = await Home.post(`/accessStorage/Login`, {
            accessToken: accessToken,
            loginDetails: loginDetails
          })
          sessionStorage.setItem("Login", loginDetails);
          sessionStorage.setItem('FBLogin', false)
          sessionStorage.setItem('LBLogin', false)
          // if(response.data.loginDetails[0].userVerified!=='verified'){
          //     let hash=''
          //     if(hashCheckout === "#checkout")
          //         hash='#checkout'
          //     history.push(`/verifyOTP${hash}`)
          // }else{
          setAlertCartStatus(true)
          if (hashCheckout === "#checkout") {
            if (isMobile === "true") {
              history.push("/checkout-page");
            } else {
              history.push("/checkout-details");
              window.location.href = "/checkout-details";
            }
          } else {
            if(localStorage.getItem("notifyItem")!==null){
              if(!login.preferredNotification){
                  dispatch({
                    type: "SET_CONFIRM_CONTACT",
                    value: true
                  });
                // setUserLoginNotify(login)
                // setItemNotify(JSON.parse(localStorage.getItem("notifyItem")))
                // setConfirmContactModalStatus(true)
              }else{
                let item=notifyItem
                await Home.post('/notifyItem/add', {
                  userId: login.customer_id,
                  item_sku: item.item_sku,
                  item_branch: item.item_branch,
                  customerEmail: login.customer_email,
                  phoneNumber:login.customer_phone,
                  mode:null
                })
                dispatch({
                  type: "SET_ITEM",
                  value:  {
                    item_sku: item.item_sku,
                    item_branch: item.item_branch,
                    notified: true
                  },
                  login: false
                });
                // history.push("/");
                // window.location.href = "/";
              }
            }else{
              
              history.push("/");
              window.location.href = "/";
            }
          }
          // } 
        }
        setInvalid(response.data.error);
      } catch (err) {
        console.log(err);
      }
    };
    Login();
  };

  const onClickForgot = () => {
    if (isMobile === "true") {
      history.push("/forgot-password")
    } else {
      dispatch(forgotModal(true))
    }
  }

  return (
    <React.Fragment>
      {isMobile === "true" ? <Header title="Login" /> : ''}
      <div className="container">
        {hashCheckout ? (
          <div className="alert alert-danger p-2 m-2" style={style.oneMore}>
            <b >One more step!</b>
            <br />
            <span style={{ 'fontSize': '14px' }}>Please sign up or log in to place your order, ka-Zagana. Thanks!</span>
          </div>
        ) : (
          ""
        )}
        {invalid ? (
          <div className="alert alert-danger p-2 m-2">{invalid}</div>
        ) : (
          ""
        )}
        <form onSubmit={handleSubmit} className="p-3 mt-2">
          <div
            className="form-group row"
            id="Email_field"
            style={style.formGroup}
          >
            <label
              htmlFor="staticEmail"
              className="col-xs-12 col-sm-5 col-lg-4 col-form-label"
            >
              Email
            </label>
            <div className="col-xs-12 col-sm-7 col-lg-8">
              <Field
                type="email"
                name="customerEmail"
                component={renderInput}
                class_name="form-control"
              />
            </div>
          </div>
          <div
            className="form-group row"
            id="Password_field"
            style={style.formGroup}
          >
            <label
              htmlFor="staticEmail"
              className="col-xs-12 col-sm-5 col-lg-4 col-form-label"
            >
              Password
            </label>
            <div className="col-xs-12 col-sm-7 col-lg-8">
              <Field
                type="password"
                name="customerPassword"
                component={renderInput}
                class_name="form-control"
              />
            </div>
          </div>
          <br />
          <div className="row">

            <div className="col my-auto d-flex align-items-center justify-content-center ">
              <button
                type="submit"
                className={`btn  ${!valid ? "disabled" : "btn-primary"} w-100`}
                disabled={!valid}
                style={style.login}
              >
                Login
              </button>
            </div>
            <div className="mt-4 text-center">
              <small>or login with:</small>
              <FbLogin />
            </div>
          </div>
          {isMobile === "true" ?
            <div className="col my-auto text-center mt-2">
              <Link to="/register" style={style.backBtn}>
                Sign Up
              </Link>
            </div>
            : ""
          }
        </form>
        <div className="text-center mb-3">
          <span className="btn text-success" onClick={onClickForgot}>Forgot password?</span>
        </div>
      </div>

      {/* <AlertModal alertCartStatus={alertCartStatus} setAlertCartStatus={setAlertCartStatus} setAlertCartDoneStatus={setAlertCartDoneStatus} error={{ title: 'Cart Select', message: 'Please Select Cart' }} /> */}
    </React.Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    login: state.form.Login,
    notifyItem:state.notify.NotifyItem,
    notifyLogin:state.notify.VisibleLogin
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: "Login",
    validate,
  })(Login)
);
