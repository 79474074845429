import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import Home from "../Home";
import { storeFrontLogs } from "../Logs";

const style = {
    center: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: '100vh',
        width: '100vw'
    },
    link: {
        textDecoration: 'none'
    }
}


const Failed = () => {
    const orderDetails = JSON.parse(sessionStorage.getItem('order'))
    const paymongo = JSON.parse(sessionStorage.getItem('paymongo'))
    const totalAmount = JSON.parse(sessionStorage.getItem('orderTotal'))
    const payment = sessionStorage.getItem('payment')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    const body = useMemo(() => {
        const body = {
            payID: paymongo.id,
            totalAmount: totalAmount,
            checkID: orderDetails.check_id,
            paymentMethod: payment
        }
        return body
    }, [orderDetails.check_id, payment, paymongo.id, totalAmount])

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || Thank you page`,
            type: `view_failed_page`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
        //record payment intent id to database
        const updateOrderStatus = async () => {
            try {
                const response = await Home.post(`/paymongo/payment/failed`, body)
                if (response.data.result === 'Pay ID recorded') {
                    localStorage.removeItem('cart')
                    sessionStorage.removeItem('total')
                    sessionStorage.removeItem('payment')
                    sessionStorage.removeItem('deliveryTime')
                    sessionStorage.removeItem('deliveryDate')
                    sessionStorage.removeItem('orderTotal')
                    sessionStorage.removeItem('paymongo')
                    sessionStorage.removeItem('order')
                }
            } catch (err) {
                console.log(err)
            }
        }
        updateOrderStatus()
    }, [body])

    return (
        <React.Fragment>
            <div className="container" style={style.center}>
                <span
                    className=" border rounded p-5 alert alert-danger"
                >
                    <div className="row text-center">
                        <h1>Payment Failed</h1>
                    </div>
                    <div className="row text-center">
                        <span>
                            Selected Payment method <br /> is not available at this moment.
                        </span>
                    </div>
                    <div className="row text-center">
                        <Link
                            to="/user-profile"
                            style={style.link}
                        >
                            Please try again.
                        </Link>
                    </div>
                </span>
            </div>
        </React.Fragment>
    )
}



export default Failed;