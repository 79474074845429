const INTIAL_STATE = {
    discount_code: "Select code",
    discount_amount: 0,
    discount: false
}

const DiscountReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'ADD_DISCOUNT':
            return {
                ...state,
                discount_code: action.code,
                discount_amount: action.amount,
                discount: action.discount
            };
        default:
            return state;
    }

};

export default DiscountReducer;