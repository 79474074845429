//  * File Name                : SuggestedRecipe
//  * File Directory           : src/components/Misc/RecipePage/Components/SuggestedRecipe
//  * Module                   : SuggestedRecipe
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/SelectedRecipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <SuggestedRecipe/>
 *
 *******************************************************************************************/


// import react libraries
import React from 'react'
// import useParams
import { useParams } from 'react-router-dom';
// import recipe list
import { recipeList } from "../recipeList";

// export suggested recipe component
const SuggestedRecipe = () => {
    // isMobile 
    const isMobile = localStorage.getItem("isMobile") === "true"
    // set useParams as params
    const params = useParams()
    // filter recipe list not equal to params recipe
    const filterRecipe = recipeList.filter(el => el.recipeName !== params.recipe)
    // render suggested recipe 
    const renderSuggestedRecipe = filterRecipe.map(suggested => {
        // declare image variable 
        let suggestedImage = suggested.recipeImages.image[0]
        // declare name variable
        let suggestedName = suggested.recipeName
        // declare description variable
        let suggestedDescription = suggested.recipeDescription
        const onSuggestedClick = () => {
            // redirect
            window.location.href =`/ContentPage/recipe/${suggestedName}`
        }
        if (isMobile) {
            return(
                <div key={suggested.categoryName} onClick={onSuggestedClick} className="suggested-flex-content">
                    <div className="collection-overlay-wrapper">
                        <div className="collection-overlay"></div>
                        <img className="collection-image suggested-collection-image" src={suggestedImage} alt={suggestedName} />
                        
                            <div className="collection-sliding-desc">
                                <div className="row">
                                    <p className="collection-text">{suggestedName}</p>
                                </div>
                            </div>
                    </div>
                </div>
            )
        }else{
            return(
                <div key={suggested.categoryName} className="collection-content suggested-flex-content">
                    <div className="collection-overlay-wrapper">
                        <div className="collection-overlay"></div>
                        <img className="collection-image suggested-collection-image" src={suggestedImage} alt={suggestedName} />
                        
                            <div className="collection-sliding-desc">
                                <div className="row">
                                    <p className="collection-text">{suggestedName}</p>
                                </div>
                                <div className="row centeredItem collection-desc">
                                    <p className="centeredText">{suggestedDescription}</p>
                                </div>
                                <div className="row">
                                    <a href={`/ContentPage/recipe/${suggestedName}`} className="centeredItem btn btn-warning collection-text-btn" >View</a>
                                </div>
                            </div>
                    </div>
                </div>
            )
        }
    })
    return (
    <div className='d-flex flex-row suggested-flex '>
        {renderSuggestedRecipe}
    </div>
  )
}

export default SuggestedRecipe