/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : StoreCredit
 * File Directory           : src/components/Checkout/
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : store credit
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CheckoutDetails        -   component for viewing checkout page for mobile
 *
 * #How to call:
 *    <StoreCredit />
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
//modal
import ErrorModal from "../Modal/ErrorModal";
//backend API
import Home from "../APIs/Home";
//react redux
import { connect, useDispatch } from "react-redux";
import ReactGA from 'react-ga4'

const style = {
    fontSize: {
        fontSize: "14px",
        backgroundColor: '#24b14b',
        borderColor: '#24b14b'
    }
}


const StoreCredit = ({ totalOrder, setHasCredit, setCredit, subTotal, discount }) => {
    const [error, setError] = useState(false) // for showing error modal
    const [errorMsg, setErrorMsg] = useState('') // for error message
    const [wallet, setWallet] = useState(0) // credit from db
    const [amount, setAmount] = useState(0) // wallet amount used/going to use
    const [isApplied, setIsApplied] = useState(false) // for checking if apply button is clicked
    const [hasWallet, sethasWallet] = useState(true) // for checking if credit is more than 0
    const [disableApply, setDisableApply] = useState(false) // for disabling apply button   
    const userInfo = JSON.parse(sessionStorage.getItem('Login')) //for getting user info
    const dispatch = useDispatch()

    useEffect(() => {
        getStoreCredit()
        //saving store credit in redux
        dispatch({
            type: "STORE_CREDIT",
            credit: amount,
            isApplied: isApplied
        })
    }, [disableApply])

    useEffect(() => {
        if (!isApplied) {
            //if total amount is less than 0
            if (totalOrder < 0) {
                setAmount(0)
                //if credit is less than total Order
            } else if (wallet < totalOrder) {
                setAmount(wallet)
            } else {
                setAmount(totalOrder)
            }

        } else {
            //when applied if totalOrder is 0 COD is selected
            if (totalOrder === 0) {
                dispatch({
                    type: "SELECTED_PAYMENT",
                    selected: "COD",
                    status: true
                })
            } else {
                dispatch({
                    type: "SELECTED_PAYMENT",
                    selected: 'Select payment',
                    status: true
                })
            }
        }

    }, [wallet, totalOrder])

    useEffect(() => {
        setIsApplied(false)
        setDisableApply(false)
        setHasCredit(false)
        setCredit(0)
    }, [subTotal, discount.discount_amount])

    const getStoreCredit = async () => {
        try {
            const response = await Home.post('/storeCredit', { id: userInfo.customer_id })
            if (response.status === 200) {
                setWallet(response.data.result)
                if (response.data.result === 0) {
                    sethasWallet(false)
                    setDisableApply(true)
                }
            }
        } catch (err) {
            setError(true)
            setErrorMsg(err.message)
            setTimeout(() => {
                setError(false)
                setErrorMsg('')
            }, 1500)
        }

    }

    const onClickApplied = () => {
        ReactGA.event({
            category: "Store Credit",
            action: 'Apply Discounts'
        });
        setIsApplied(true)
        setDisableApply(true)
        setHasCredit(true)
        setCredit(amount)
    }

    return (
        <React.Fragment>
            <div className="container ">
                <div className="row">
                    <label>
                        Available Credit : {(wallet || 0).toFixed(2)}
                    </label>
                </div>
                <div className="row mt-2 mb-4">
                    <div className="col-9">
                        <input
                            type="text"
                            className="w-100 form-control"
                            value={hasWallet ? `You can use your ${(amount || 0).toFixed(2)} credit` : `No credit available`}
                            disabled
                        />
                    </div>
                    <div className="col-3 ">
                        <button
                            id="apply_voucher"
                            className="btn btn-success p-1"
                            style={style.fontSize}
                            disabled={disableApply}
                            onClick={onClickApplied}
                        >
                            apply
                        </button>
                    </div>
                </div>
                <ErrorModal btn={error} body={errorMsg} />
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        discount: state.discount,
    }
}

export default connect(mapStateToProps)(StoreCredit);
