/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : Voucher
 * File Directory           : src/components/Checkout/
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : select or apply voucher 
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Address        -   component for applying voucher
 *
 * #How to call:
 *    /add-voucher
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../Shared/Header";
import Home from "../APIs/Home";
import { storeFrontLogs } from "../APIs/Logs";
import ReactGA from 'react-ga4'

const style = {
    fontSize: {
        fontSize: "14px",
        backgroundColor: '#24b14b',
        borderColor: '#24b14b'
    },
    valid: {
        color: "#24b14b"
    }
}


const Voucher = ({ total, totalOrder, shippingFee }) => {
    const customerID = sessionStorage.getItem('Login') || null
    const parseCustomerID = JSON.parse(customerID) || []
    const user_id = parseCustomerID.customer_id
    const [error, setError] = useState(false)
    // const [s_Error, setS_Error] = useState(true)
    const [voucherSelected, setVoucherSelected] = useState({})
    const [voucherApplied, setVoucherApplied] = useState({})
    // const [s_VoucherApplied, setS_voucherApplied] = useState({})
    const [minimum, setMinimum] = useState(null)
    const [errorMsg, setErrorMsg] = useState('')
    const [couponValid, setCouponValid] = useState('')
    const [orderCount, setOrderCount] = useState(0)
    const [newOrderCount, setNewOrderCount] = useState(0)
    const [v2OrderCount, setV2OrderCount] = useState(0)
    const [errorMinimun, setErrorMinimum] = useState(false)
    const [checker, setChecker] = useState(false)
    const [code, setCode] = useState('')
    const [isApplied, setIsApplied] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [secretCode, setSecretCode] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()
    const isMobile = localStorage.getItem("isMobile") === "true"
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }



    useEffect(() => {
        const body = {
            user_id: user_id
        }
        const getVoucher = async () => {
            try {
                const response = await Home.post(`/get-voucher`, body)
                if (response.status === 200) {
                    // console.log(response.data)
                    setMinimum(response.data.Error)
                    setOrderCount(response.data.result.order_count || 0)
                    setNewOrderCount(response.data.result.new_order_count || 0)
                    setV2OrderCount(response.data.result.v2_order_count || 0)
                    setVoucherSelected(response.data.result || {})
                    setChecker(true)
                }
            } catch (err) {
                console.log(err)
            }

        }
        getVoucher()
    }, [user_id])

    // useEffect(() => {
    //     const body = {
    //         amount: total,
    //         voucher_code: code,
    //         user_id: user_id
    //     }
    //     const getAppliedVoucher = async () => {
    //         try {
    //             const response = await Home.post(`/add-voucher`, body)
    //             setVoucherApplied(response.data.result)
    //             setError(response.data.result.Error)
    //             setErrorMsg(response.data.result.Error)
    //         } catch (err) {
    //             console.log(err)
    //         }
    //     }
    //     const timeOutId = setTimeout(() => {
    //         if (code) {
    //             getAppliedVoucher()
    //         }
    //     }, 550)
    //     return () => {
    //         clearTimeout(timeOutId)
    //     };

    // }, [code, total, user_id])

    // const onClickDiscount = () => {
    //     if (!minimum) {
    //         dispatch({
    //             type: "ADD_DISCOUNT",
    //             code: voucherSelected.disc_code,
    //             amount: voucherSelected.disc_amount,
    //             discount: true
    //         })
    //         setIsApplied(true)
    //     } else {
    //         setErrorMinimum(true)
    //     }
    // }
    useEffect(() => {
        // console.log(totalOrder)
        if (isApplied && code !== '' && total > 0) {
            // console.log(voucherApplied.disc_type === "Percentage")
            if (voucherApplied.disc_type === "Percentage") {
                let discount_amount = voucherApplied.disc_amount
                const percentage = discount_amount / 100
                let discount
                if (voucherApplied.include_sfee == 'On' && total < 500) {
                    discount = parseFloat(total) * parseFloat(percentage) + shippingFee
                } else {
                    discount = parseFloat(total) * parseFloat(percentage)
                }
                // console.log(discount)
                if (voucherApplied.promo_type === "Employee150") {
                    dispatch({
                        type: "ADD_DISCOUNT",
                        code: voucherApplied.disc_code,
                        amount: discount - 79,
                        discount: true
                    })
                } else {
                    // console.log("discount percentage")
                    // console.log("discount")
                    dispatch({
                        type: "ADD_DISCOUNT",
                        code: voucherApplied.disc_code,
                        amount: discount,
                        discount: true
                    })
                }
            } else {
                let discount
                if (voucherApplied.include_sfee == 'On' && total < 500) {
                    discount = voucherApplied.disc_amount + shippingFee
                } else {
                    discount = voucherApplied.disc_amount
                }
                dispatch({
                    type: "ADD_DISCOUNT",
                    code: voucherApplied.disc_code,
                    amount: discount,
                    discount: true
                })
            }

            if (totalOrder <= 100) {
                // console.log("PAsOK COD")
                dispatch({
                    type: "SELECTED_PAYMENT",
                    selected: "COD",
                    status: true
                })
            }

        } else {

            if (totalOrder <= 100) {
                // console.log("PAsOK COD")
                dispatch({
                    type: "SELECTED_PAYMENT",
                    selected: "COD",
                    status: true
                })
            }
            // else {
            //     dispatch({
            //         type: "SELECTED_PAYMENT",
            //         selected: 'Select payment',
            //         status: true
            //     })
            // }

            dispatch({
                type: "ADD_DISCOUNT",
                code: "Select Code",
                amount: 0,
                discount: false
            })
        }
    }, [total, isApplied, voucherApplied, voucherApplied.disc_code, voucherApplied.disc_amount, totalOrder])

    // useEffect(() => {
    //     if (isApplied) {
    //         setCouponValid("")
    //         dispatch({
    //             type: "ADD_DISCOUNT",
    //             code: voucherApplied.disc_code,
    //             amount: voucherApplied.disc_amount,
    //             discount: true
    //         })
    //         const payload = {
    //             user: user,
    //             changes: `Zagana || Checkout`,
    //             type: 'click_apply_voucher',
    //             date: `NOW()`
    //         }
    //         storeFrontLogs(payload)
    //         setTimeout(() => {
    //             setError(true)
    //             setErrorMsg('')
    //         }, 2500)
    //     } else {
    //         dispatch({
    //             type: "ADD_DISCOUNT",
    //             code: "Select Code",
    //             amount: 0,
    //             discount: false
    //         })
    //     }
    // }, [isApplied, voucherApplied, voucherApplied.disc_code, voucherApplied.disc_amount])

    const onClickApply = () => {
        // if (code !== secretCode) {
        getAppliedVoucher()
    }

    const onKeyUp = (e) => {
        setIsApplied(false)
        setError(false)
        return (
            setCode(e.target.value)
        )
    }

    const getAppliedVoucher = async () => {
        setLoading(true)
        const body = {
            amount: total,
            voucher_code: code,
            user_id: user_id
        }
        try {
            const response = await Home.post(`/add-voucher`, body)
            if (response.status === 200) {
                ReactGA.event({
                    category: "Voucher",
                    action: 'Apply Discounts',
                    label: code
                });
                setVoucherApplied(response.data.result)
                // console.log(response.data.result)
                setError(response.data.result.Error)
                setErrorMsg(response.data.result.Error)
                if (!response.data.result.Error) {
                    setIsApplied(true)

                } else {
                    setIsApplied(false)
                }
                setLoading(false)
            }
        } catch (err) {
            setError(true)
            setErrorMsg(err.message)
            setLoading(false)
        }
    }

    return (
        <React.Fragment>

            {/* {isMobile ?
                <Header title="Voucher" />
                : ""} */}

            <div className="container mt-2">
                <div className="row">
                    <div className="col-9">
                        <input
                            type="text"
                            className="w-100 form-control"
                            placeholder="Enter voucher/ referral code.."
                            onKeyUp={onKeyUp}

                        />
                    </div>
                    <div className="col-3 ">
                        <button
                            id="apply_voucher"
                            className="btn btn-success p-1"
                            style={style.fontSize}
                            onClick={onClickApply}
                            disabled={isApplied}
                        >
                            {loading ?
                                <React.Fragment>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                </React.Fragment>
                                : "apply"}
                        </button>
                    </div>
                </div>
                <div className="row mb-3">

                    <span className="error">
                        {couponValid}
                    </span>
                    {error ?
                        <span className="error">
                            {errorMsg}
                        </span>
                        :
                        null
                    }
                    {isApplied ?
                        <span style={style.valid}>
                            Voucher applied
                        </span>
                        :
                        null
                    }
                </div>

                {checker && total < voucherSelected.disc_minimum ?

                    <div className={isMobile ? "row mt-3 mb-3" : "row"}>

                        {orderCount === 0 && newOrderCount === 0 && v2OrderCount === 0 && isMobile ?
                            <React.Fragment>
                                <div className="col">
                                    <div
                                        className="card shadow p-2"
                                    // onClick={onClickDiscount}
                                    >
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-12">
                                                    <label>Code: &nbsp; </label>
                                                    <span>{voucherSelected.discount_code}</span>
                                                </div>
                                                <div className="col-12">
                                                    <label>Amount: &nbsp; </label>
                                                    <span>{voucherSelected.discount_amount}</span>
                                                </div>
                                                <div className="col-12">
                                                    <center>
                                                        <small>
                                                            minimum spend of {voucherSelected.discount_minimum} PHP
                                                        </small>
                                                    </center>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    {errorMinimun ?
                                        <span className="error ">
                                            Doesn't meet a minimum spend of {voucherSelected.discount_minimum}
                                        </span>
                                        :
                                        null
                                    }
                                </div>
                            </React.Fragment>
                            :

                            null
                        }
                    </div>
                    :
                    null
                }

            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        total: state.total.total
    }
};
export default connect(mapStateToProps)(Voucher);