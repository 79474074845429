/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : Footer
 * File Directory           : src/components/Shared/Footer
 * Module                   : All
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : Footer for the website
 * Used In				          : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   Footer        -   component for Footer for the whole website
 *
 * #How to call:
 *    <Footer />
 *
 *******************************************************************************************/
import React, { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import FB from "../../assets/socmed_icons/fb.svg"
import IG from "../../assets/socmed_icons/ig.svg"
import LinkedIn from "../../assets/socmed_icons/linkedin.svg"
import Logo from "../../assets/logo/zagana-logo.png"
import { Popover, OverlayTrigger } from 'react-bootstrap';
import LoginModal from '../Shared/LoginModal';
import { storeFrontLogs } from "../APIs/Logs";

const styles = {
  backgroundColor: {
    backgroundColor: "#FACC09",
    fontSize: '14px',
    marginBottom: '12%',
    marginTop: '5%'
  },
  buttonDesign: {
    background: "none",
    color: "inherit",
    border: "none",
    padding: "0",
    font: "inherit",
    cursor: "pointer",
    outline: "inherit"
  },
  greenText: {
    color: "rgb(36, 177, 75)",
    cursor: "pointer",
  }
};

function Footer() {
  const location = useLocation();
  const [showFooter, setShowFooter] = useState(true);

  const isLogged = sessionStorage.getItem("Login") || ''; // login checker

  let gen_id = localStorage.getItem('gen_id')
  let user;
  let userLogin;
  if (gen_id) {
    gen_id = localStorage.getItem('gen_id')
    user = gen_id
  }
  else if (isLogged) {
    userLogin = JSON.parse(sessionStorage.getItem('Login'))
    gen_id = localStorage.getItem('gen_id')
    user = userLogin.customer_name + ` (${gen_id})`
  }

  const details = [
    {
      title: "Recipe Page",
      link: "/ContentPage/recipe"
    },
    {
      title: "About Us",
      link: "/ContentPage/about"
    },
    {
      title: "FAQs",
      link: "/ContentPage/FAQs"
    },
    {
      title: "Blogs",
      link: "/ContentPage/blogs"
    },
    {
      title: "Privacy Policy",
      link: "/ContentPage/privacy-policy"
    },
    {
      title: "Terms & Conditions",
      link: "/ContentPage/terms"
    },
    {
      title: "Contact Us",
      link: "/contact-us"
    }
  ]

  const connectLinks = [
    {
      img: <img src={FB} alt="Facebook Link" width="100%" />,
      link: "https://facebook.com/zagana.com.ph/",
      title: "facebook"
    },
    {
      img: <img src={IG} alt="Instagram Link" width="100%" />,
      link: "https://www.instagram.com/zagana.com.ph/",
      title: "insta"
    },
    {
      img: <img src={LinkedIn} alt="Linkedin Link" width="100%" />,
      link: "https://www.linkedin.com/company/zagana-inc/",
      title: "linkedin"
    },
  ]
  const download = [
    {
      img: <img src={`https://res.cloudinary.com/dw5axbz64/image/upload/v1663835827/icons/playstore_v5jnrq.png`} alt="Download App" width="100%" />,
      link: "https://play.google.com/store/search?q=zagana&c=apps",
      title: "dowload app"
    }
  ]

  function redirectLink(link) {
    window.location.href = link;
  }

  useEffect(() => {
    let url = location.pathname
    if (url.includes("checkout") || url.includes("address")
      || url.includes("delivery-fields") || url.includes("add-voucher")
      || url.includes("select-payment-method") || url.includes("paymongo") || url.includes("add-referral"))
      setShowFooter(false);
    else setShowFooter(true);
  }, [location.pathname] || [])

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Let us assist you. <br />
        Contact us at <a href="tel:09176294376"> <strong style={styles.greenText}>09176294376</strong> </a><br />
        or at <strong style={styles.greenText} onClick={() => {
          window.open(
            "mailto:hello@zagana.com?subject=Query on my orders"
          );
        }}>hello@zagana.com</strong>
      </Popover.Body>
    </Popover>
  );

  return (
    <footer className="container" id="footer">

      {showFooter ?
        <div className="row footer_" style={styles.backgroundColor}>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <ul className="list-unstyled">
              <li key={1} className="pb-1 pt-4">
                <h4>
                  <img src={Logo} alt="Footer Logo" width="35%" id="zagana-logo-footer" />
                </h4>
              </li>
              <li key={2} className="pb-2">
                Zagana provides 30 minutes or same day delivery of fresh produce and frozen food sourced directly from local farmers at a fair market price.
              </li>
              <li key={3} className="pb-1">
                © 2021 Zagana. All rights reserved
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-4">
            <ul className="list-unstyled">
              <li key={4} className="pb-1 pt-4">
                <h4>
                  <strong> Site </strong>
                </h4>
              </li>
              {details.map(item => {
                if (item.title !== "Contact Us")
                  return (
                    <a href={item.link} key={item.title} id={item.title} style={{ color: "#222222", textDecoration: "none" }}>
                      <li key={5} className="pb-1" role="button">
                        {item.title}
                      </li>
                    </a>
                  )
                else return (
                  <OverlayTrigger trigger="click" placement="right" overlay={popover}>
                    <button style={styles.buttonDesign}>Contact Us</button>
                  </OverlayTrigger>
                )
              })}
            </ul>
          </div>

          <div className="col-6 col-sm-4">
            <ul className="list-unstyled">
              <li key={6} className="pb-1 pt-4">
                <h4>
                  <strong> Follow Us </strong>
                </h4>
              </li>
              <li key={7}>
                <div className="row pt-2">
                  {connectLinks.map(key => {
                    return (
                      <div className="col-4 col-sm-2" 
                      onClick={() => {
                         redirectLink(key.link)
                            const payload = {
                              user: user,
                              changes: key['title'],
                              type: 'Click / Visit: '+key['title'],
                              date: `NOW()`
                          }
                        storeFrontLogs(payload)
                        }} key={key['title']}
                        id={key['title']} role="button">
                        {key['img']}
                      </div>
                    )
                  })}
                </div>
              </li>
            </ul>
            <ul className="list-unstyled">
              <li key={6} className="pb-1">
                <h4>
                  <strong> Download our app! </strong>
                </h4>
              </li>
              <li key={7}>
                <div className="row pt-2">
                  {download.map(key => {
                    return (
                      <div className="col-4 col-sm-2" 
                      onClick={() => {
                         redirectLink(key.link)
                            const payload = {
                              user: user,
                              changes: key['title'],
                              type: 'Click / Visit: '+key['title'],
                              date: `NOW()`
                          }
                        storeFrontLogs(payload)
                        }} key={key['title']}
                        id={key['title']} role="button">
                        {key['img']}
                      </div>
                    )
                  })}
                </div>
              </li>
            </ul>
          </div>
        </div>
        : null
      }
    </footer>
  );
}

export default Footer;
