/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : ItemCardv2
 * File Directory           : src/components/Shared/ItemCardv2
 * Author                   : Ryan
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/components/home.js & src/Home/ItemCardv2.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   ItemList        -   component for ItemList for listing items
 *
 * #How to call:
 *    <ItemCardv2/>
 *******************************************************************************************/

import { lineHeight } from "@mui/system"
import React, { useEffect, useState } from "react"
import CartButtons from "./CartButtons";
import NotifyMeButtons from "./NotifyMeButtons";
import { useDispatch } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component"
import { SaveToDatabase } from "../Shared/SaveToDatabase";
const style = {
    fontSize12: {
        fontSize: "12px"
    },
    fontSize10: {
        fontSize: "10px"
    },
    lineHeight12: {
        lineHeight: "12px"
    },
    imgColumn: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: 'zoom-in',
    }
}


const ItemCardv2 = ({ items }) => {
    let saleTag = Math.round(((items.item_price - items.item_salePrice) / items.item_price) * 100); // for sale tag percent, for home page and modal
    let source = sessionStorage.getItem('source')||''
    const dispatch = useDispatch()

    const btnViewDetails = (item) => {
        if (source === "Tiktok") {
            SaveToDatabase({
                action: {
                    type: 'ViewProductModal',
                    value: {
                        content_ids: [item.item_sku],
                        content_type: 'product',
                        content_name: item.item_name,
                        currency: "PHP",
                        value: parseFloat(item.item_price), // your total transaction value goes here
                    }
                },
                sku: item.item_sku
            })
            window.ttq.track('ViewContent', {
                content_type: "product", 
                content_category: item.category[0], 
                content_name: item.item_name,
                content_id: item.item_sku, 
                description: "View Product(Modal)",
                currency: "PHP", 
                value: item.item_price
            })
        }
        window.gtag("event", "select_item", {
            items: [
                {
                    item_id: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: 0,
                    item_brand: "Zagana",
                    item_category: item.category,
                    price: item.item_price,
                }
            ]
        });
        if (!(window.location.pathname).includes("productPage"))
            dispatch({
                type: "SET_VIEW_MODAL",
                selected: items,
                view: true
            });
    }
    const seeProduct = (item) => {
        if (item.sub_category_status) {
            window.location.pathname = `sub-list/${item.item_name}`
        } else {
            window.location.pathname = `productPage/${item.item_sku}`
        }


        if (source === "Tiktok") {
            SaveToDatabase({
                action: {
                    type: 'ViewProduct',
                    value: {
                        content_ids: [item.item_sku],
                        content_type: 'product',
                        content_name: item.item_name,
                        currency: "PHP",
                        value: parseFloat(item.item_price), // your total transaction value goes here
                    }
                },
                sku: item.item_sku
            })
            window.ttq.track('ViewContent', {
                content_type: "product", 
                content_category: item.category[0], 
                content_name: item.item_name,
                content_id: item.item_sku, 
                description: "View Product",
                currency: "PHP", 
                value: item.item_price
            })
        }
        window.gtag("event", "select_item", {
            items: [
                {
                    item_id: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: 0,
                    item_brand: "Zagana",
                    item_category: item.category,
                    price: item.item_price,
                }
            ]
        });

        window.gtag("event", "view_item", {
            currency: "PHP",
            value: item.item_price,
            items: [
                {
                    item_id: item.item_sku,
                    item_name: item.item_name,
                    currency: "PHP",
                    index: 0,
                    item_brand: "Zagana",
                    item_category: item.category,
                    price: item.item_price,
                }
            ]
          });
        //   console.log(item);
    }

    return (
        <React.Fragment>
            {
                [items].map((item, index) => (
                    <div key={index} className={item.item_status === "enabled" ? "card item-card" : "card item-card muted"}>
                        <div className={`ribbon-wrapper ${item.item_salePrice < item.item_price ? '' : 'd-none'}`}>
                            <div className="ribbon">
                                {Math.round(saleTag)}%
                            </div>
                        </div>
                        {
                            (item.category).includes("new") ?
                                <div className="ribbon-wrapper" style={(item.item_price - item.cart_price) === 0 ? style.displayNone : null} >
                                    {
                                        window.location.pathname === '/checkout-details' ?
                                            <div className="ribbon ribbonNew">
                                                &nbsp;&nbsp;new
                                            </div>
                                            :
                                            <div className="ribbon ribbonNew">
                                                &nbsp;&nbsp;new
                                            </div>

                                    }
                                </div>
                                : null
                        }
                        {
                            (item.category).includes("seasonal") ?
                                <div className="ribbon-wrapper" style={(item.item_price - item.cart_price) === 0 ? style.displayNone : null} >
                                    {
                                        window.location.pathname === '/checkout-details' ?
                                            <div className="ribbon ribbonSeasonal">
                                                &nbsp;Seasonal
                                            </div>
                                            :
                                            <div className="ribbon ribbonSeasonal">
                                                &nbsp;Seasonal
                                            </div>

                                    }
                                </div>
                                : null
                        }
                        <div className="row item-img-row">
                            <div style={style.imgColumn} className="item-img-col col-lg-5 col-sm-12">
                                <LazyLoadImage
                                    wrapperClassName={`item-list-image text-center img-fluid w-100`}
                                    onClick={() => (window.location.pathname).includes("productPage") || item.sub_category_status || (window.location.pathname).includes("sub-list")  ?
                                        seeProduct(item)
                                        :
                                        btnViewDetails(item)}
                                    alt={item.item_name}
                                    width="100%"
                                    effect="blur"
                                    src={item.item_image}
                                />
                            </div>
                            <div style={style.lineHeight12} className=" col-lg-7 col-sm-12 p-4 pt-3 pb-3">
                                <div className="row p-0">
                                    <div className={`item-details-col col-12`}
                                        onClick={() => ((window.location.pathname).includes("productPage") || item.sub_category_status) ?
                                            seeProduct(item)
                                            :
                                            btnViewDetails(item)}>
                                        <h5
                                            className="item-name"
                                        >
                                            {item.item_name}
                                        </h5>
                                        <label
                                            style={style.fontSize10}
                                            className="w-100"
                                        >
                                            {item.item_variant}
                                        </label>
                                        {item.item_salePrice < item.item_price ?
                                            <React.Fragment>
                                                <b>
                                                    <span
                                                        style={style.fontSize12}
                                                        className="text-danger"
                                                    >
                                                        ₱{item.item_salePrice.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                    </span>
                                                </b>
                                                <label
                                                    style={style.fontSize10}
                                                    className="w-100 text-muted"
                                                >
                                                    <del>
                                                        ₱{item.item_price.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                    </del>
                                                </label>
                                            </React.Fragment>
                                            :
                                            <b>
                                                {item.sub_category_status ? null :
                                                    <span
                                                        className="w-100"
                                                        style={style.fontSize12}
                                                    >
                                                        ₱{item.item_price.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                        })}
                                                    </span>
                                                }
                                            </b>
                                        }
                                    </div>
                                    {item.sub_category_status ?
                                        <div className="col-lg-12 col-sm-12 text-center">
                                            <div className="product-buttons ">
                                                <button
                                                    className="product-button-oval add-button text-center"
                                                    onClick={() =>
                                                        seeProduct(item)
                                                    }
                                                >
                                                    See More
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-lg-12 col-sm-12 text-center">
                                            {
                                                item.item_status === "enabled" ?
                                                    <CartButtons item={item} />
                                                    :
                                                    <NotifyMeButtons item={item} />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                ))
            }
        </React.Fragment >
    )
}

export default ItemCardv2