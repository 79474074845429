//  * File Name                : FloatingRecipe
//  * File Directory           : src/components/Misc/RecipePage/Components/FloatingRecipe
//  * Module                   : FloatingRecipe
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In					        : src/Recipe.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <FloatingRecipe/>
 *
 *******************************************************************************************/


// import react library
import React, { useEffect, useState } from "react";
// import recipe list
import { recipeList } from "../recipeList";

// Floating recipe means recipes with no category
// export Floating Recipe component
const FLoatingRecipe = () => {
  // isMobile
  const isMobile = localStorage.getItem("isMobile") === "true"
  // set max item to display 
  const [maxItem, setMaxItem] = useState(5)
  // declare maxed state
  const [maxed, setMaxed] = useState(false)
  // set number of items to display upon click More/Less
  const [numberOfItems, setNumberOfItems] = useState()
  // set index of how many times you click
  const [index, setIndex] = useState(0)
  // set visible data to display
  const [visibleData , setVisibleData] = useState([])
  // declare action state for more/less function
  const [action,setAction] = useState("")

  // filter recipes with no categories
  const filterRecipe = recipeList.filter(el => el.categoryName === "" || el.categoryName === null || el.categoryName === undefined)
  // declare filter recipe length
  const filterRecipeLength = filterRecipe.length


  // useEffect for Show More/Less function
  useEffect(() => {
    // for mobile show no limit
    if (isMobile) {
      setMaxItem(filterRecipeLength)
    }else{
      setMaxItem(5)
    }
    setNumberOfItems(maxItem * ( index + 1 )) ;
    // data container to update visible data state
    const newArray = []; 
    let i= 0 
    for(i;i< filterRecipeLength ; i++ ){
      if(i < numberOfItems) 
        // add data and set it to visible data
        newArray.push(filterRecipe[i])
    }
    // set new data to visible data state
    setVisibleData(newArray);
    // set max if number of items is greater or equal to main list
    if (numberOfItems >= filterRecipeLength) {
      // setNumberOfItems(10)
      setMaxed(true)
    }else{
      setMaxed(false)
    }
  }, [index, numberOfItems, filterRecipeLength])

  useEffect(()=>{
    if(maxed){
      if(filterRecipeLength <= maxItem){
        setAction("")
      }else{
        setAction("Less")
      }
      
    }else{
      setAction("More")
    }
  },[maxed,filterRecipeLength,maxItem])

  // onClick function for Show More/Less
  const onClickLoad = () => {
      if (numberOfItems > filterRecipeLength) {
        // set to default value 0
        setNumberOfItems(0)
        setIndex (0)
      }else{
        // add 1 on index
        setIndex ( index + 1 )
      }
    }

  // render Floating recipe 
  const renderFloatingRecipe = visibleData.map((col) => {
    // declare name variable
    let floating_name = col.recipeName
    // declare description variable
    let floating_description = col.recipeDescription
    // declare image variable
    let floating_image = col.recipeImages.image[0]
    const onCollectionClick = () => {
      // redirect to Recipe Description Page
      window.location.href = `/ContentPage/recipe/${floating_name}`
    }
    if(isMobile){
      return(
        <div key={col.categoryName}  onClick={onCollectionClick} className={`collection-mobile centeredItem`}>
          
          <div className="collection-overlay-wrapper ">
            <div className="collection-overlay"></div>
            <img className="suggested-collection-image br-20" src={floating_image} alt={floating_name} />
            <div className="collection-sliding-desc">
                <div className="row">
                    <p className="collection-text">{floating_name}</p>
                </div>
            </div>
          </div>
        </div>
      )
    }else{
      return(
        <div key={col.recipeName} className="collection-content br-20 centeredItem">
          <div className="collection-overlay-wrapper">
            <div className="collection-overlay br-20"></div>
            <img className="collection-image br-20" src={floating_image} alt={floating_name} />
            
            <div className="collection-sliding-desc">
                      <div className="row">
                          <p className="collection-text">{floating_name}</p>
                      </div>
                      <div className="row centeredItem collection-desc">
                          <p className="centeredText">{floating_description}</p>
                      </div>
                      <div className="row">
                          <button className="centeredItem btn btn-warning collection-text-btn" onClick={onCollectionClick}>View</button>
                      </div>
                  </div>
          </div>
        </div>
      )
    }
  })
  return(
    <div className="container">
        <div className="row floating-recipe-margin marginLR">
          <div className="collection collection-m">
              <h2>Other Recipe</h2>
              {
                filterRecipeLength < 1 
                ?
                  <p className="centeredText ">No Available Recipe</p>
                :
                  null
              }
              <div className={`${isMobile ? "collection-mobile-flex" : "collection-image-grid"}`}>
                  {filterRecipeLength > 0 ? renderFloatingRecipe : null}
              </div>
              {
                isMobile ?
                "" :
                <div className="mt-20 centeredItem show-more-btn">
                  <p className="show-more" onClick={onClickLoad}> {action} </p>
                </div>
              }
          </div>
        </div>
    </div>
  )
}

export default FLoatingRecipe