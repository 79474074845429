import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Checkout.css";

const style = {
    value: {
        textAlign: "end"
    },
    links: {
        textDecoration: 'none',
        color: "#24b14b",
    }
}

const Details = ({ details }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";

    const renderedDetails = details.map((data, index) => {
        return (
            <React.Fragment>
                {data.display ?
                    <React.Fragment>
                        <tr>
                            <td>
                                {
                                    data.title
                                }
                            </td>
                            <td style={style.value}>
                                {data.title === "Payment method" || data.title === "Voucher" || data.title === "Referral" ?
                                    <Link
                                        to={data.link}
                                        id={data.title}
                                        style={style.links}
                                    >
                                        {data.value}
                                    </Link>
                                    :
                                    data.value
                                }
                            </td>
                        </tr>
                    </React.Fragment>
                    : null
                    // (data.title).match(/Free Shipping/gi)?
                    //         <tr>
                    //             <td colSpan={2}>
                    //                 <span style={{fontStyle:"italic",color:"rgb(255, 196, 89)", fontSize:'10px'}} className="font-italic">You can get free delivery for purchases Greater than or equal to 1000</span>
                    //             </td>
                    //         </tr>
                    //     :
                    //     null

                }
            </React.Fragment>
        )
    })

    return (
        <React.Fragment>
            <Table className="mb-5" id="table">
                <tbody className="mb-5">
                    {renderedDetails}
                </tbody>
            </Table>
        </React.Fragment>
    )

}

export default Details