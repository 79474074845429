/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : AlertModal
 * File Directory           : src/components/Shared/SnackBar
 * Module                   : Alert
 * Author                   : Joseph
 * Version                  : 1
 * Purpose                  : Reusable
 * Used In				   : src/ItemList.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   AlertModal        -   component for AlertModal
 *
 * #How to call:
 *    <AlertModal/>
 *
 *******************************************************************************************/

import React, { useState, useRef } from "react";
const style = {
    toast: {
        'position': 'fixed',
        'margin': '0 auto',
        'zIndex': '999',
        'right': '30px',
        'bottom': '2%',
        'textAlign': 'left'
    }
}


const SnackBar = ({ alertMsg, status }) => {
    return <React.Fragment>
        <div className={`toast text-white ${status}`} id="alertCopied" role="alert" aria-live="assertive" aria-atomic="true" style={style.toast}>
            <div className="toast-body">
                {alertMsg} 
            </div>
        </div>
    </React.Fragment>
}

export default SnackBar;