const INTIAL_STATE = {
    selectedCategory   : []
};

const CategoryReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SELECTED_CATEGORY':
            return {
                ...state,
                selectedCategory   : action.selected
            };
        default:
            return state;
    }

};

export default CategoryReducer;
