
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : AboutUs
 * File Directory           : src/components/Misc/AboutUs
 * Module                   : About Us
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : More info about Zagana
 * Used In				   : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   AboutUs        -   component for About Us Page
 *
 * #How to call:
 *    <AboutUs />
 *
 *******************************************************************************************/

import React, { useState, useEffect } from "react";
import MetaTags from 'react-meta-tags';
import DesktopHeader from '../Shared/DesktopHeader';
import Header from '../Shared/Header';
import LoginModal from '../Shared/LoginModal';
import { storeFrontLogs } from "../APIs/Logs";

const styles = {
    desktopWidth: {
        width: "50%"
    },
    mobileWidth: {
        width: "100%"
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed'
    },
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'left'
    },
    btnClear: {
        position: 'absolute',
        top: '8px',
        right: '35px',
    }
}


function AboutUs({ showModal }) {
    document.title = "Zagana | About Us"
    const isMobile = localStorage.getItem("isMobile") === "true"
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal
    let gen_id = localStorage.getItem('gen_id')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let user;
    let userLogin;
    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }

    useEffect(() => {
        const payload = {
            user: user,
            changes: `Zagana || About Us`,
            type: 'view_about_us',
            date: `NOW()`
        }
        storeFrontLogs(payload)
    }, [])

    const details = {
        missionPurpose: [
            {
                src: "https://res.cloudinary.com/dw5axbz64/image/upload/v1635311471/fixed/PURPOSE_vjcckh.png",
                text: "Uplifting lives through technology",
                title: "Purpose",
                alt: 'Purpose Picture'
            },
            {
                src: "https://res.cloudinary.com/dw5axbz64/image/upload/v1635311471/fixed/MISSION_iurw04.png",
                text: <> We Bridge the gap between Food Producers and <br /> Consumers through technology; <br /> uplifting the lives of our stakeholders.</>,
                title: "Mission",
                alt: 'Mission Picture'
            },
        ],
        vision: [
            {
                src: "https://res.cloudinary.com/dw5axbz64/image/upload/v1635311470/fixed/EMPLOYEES_kkwztr.png",
                text: "To be a place where great talents can grow and achieve their full potential",
                alt: 'Vision #1 Picture',
                title: "Employees"
            },
            {
                src: "https://res.cloudinary.com/dw5axbz64/image/upload/v1635311471/fixed/FOOD_PRODUCERS_trvacw.png",
                text: "To make food production a preferred livelihood",
                alt: 'Vision #2 Picture',
                title: "Food producers"
            },
            {
                src: "https://res.cloudinary.com/dw5axbz64/image/upload/v1635311471/fixed/CONSUMERS_tpxu5f.png",
                text: "To allow every consumer to have access to affordable food",
                alt: 'Vision #3 Picture',
                title: "Consumers"
            },
            {
                src: "https://res.cloudinary.com/dw5axbz64/image/upload/v1635311471/fixed/STAKEHOLDERS_srwaof.png",
                text: "To grow each individual and entity engaged in Zagana that ultimately creates a healthy economy where we operate in",
                alt: 'Vision #4 Picture',
                title: "Stakeholders"
            },

        ]
    }

    function showHeader() {
        return (
            <React.Fragment>
                <MetaTags>
                    <title>Zagana | About Us</title>
                    <meta name="description" content="Zagana provides 30 minutes or same day delivery of fresh produce and frozen food sourced directly from local farmers at a fair market price." />
                    <meta property="og:title" content="Zagana | About Us" />
                    <meta property="og:image" content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" />
                    <meta property="og:url" content="https://www.zagana.com/ContentPage/about" />
                    <meta property="og:type" content="website" />
                    <meta property="og:site_name" content="Zagana Inc." />
                    <meta name="keywords" content="farm-fresh, fresh, produce, local, frozen, farmers, quality, online grocery, online palengke" />
                    <meta name="author" content="Zagana Inc." />
                </MetaTags>
                <div className="row p-0">
                <div className="row misc-h">
                    {isMobile ?
                        <div style={{ position: 'fixed', zIndex: '2' }} className="p-0 hdr m-h" >
                            <Header title="Blogs" />
                        </div>
                        :
                        <div style={{ position: 'fixed' }} className="p-0 hdr d-h" >
                            <DesktopHeader
                                visibleLogin={visibleLogin}
                                setVisibleLogin={setVisibleLogin} />
                        </div>
                    }
                </div>
                    <div className="row justify-content-center p-3 mt-5">
                        <iframe width={"300"} height={"400"} src="https://www.youtube.com/embed/HoB6qJmpUIw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className="row text-center p-5 pt-2 pb-2">
                        <p>When Zagana says it bridges the gap between food producers and consumers, we do just that.</p>

                        <p>We source our fresh produce directly from the farm and buy them at a fair market price. This ensure freshness, quality availability and sustainability all at the same time.</p>

                        <p>For the busy shoppers of families in the Philippines who are looking for safe and time-efficient ways to restock food staples and fresh grocery, Zagana provides delivery of fresh produce and frozen food sourced directly from local farmers at a fair market price, in as fast as 30 minutes.</p>

                        <p>Different from other online sources of fresh grocery and frozen food, we guarantee same day delivery of thousands of orders by being available in major online sales channels and key cities nationwide.</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    /**
     * 
     * @returns JSX for the Purpose and Mission Part
     */
    function showPurposeMission() {
        let data = details['missionPurpose'] || []
        return (
            <React.Fragment>

                <div className="row">
                    {data.map(key => {
                        return (
                            <React.Fragment key={key.title}>
                                {isMobile ?
                                    <React.Fragment>
                                        <div className="row">
                                            <center>
                                                <img src={key['src']} id="zagana-video" className="img-fluid rounded mx-auto d-block" alt="Purpose Picture" />
                                            </center>
                                        </div>
                                        <br />
                                        <div className="row">
                                            <center>
                                                <h1>
                                                    <strong> {key['title']} </strong>
                                                </h1>
                                            </center>
                                        </div>
                                        <div className="row">
                                            <center>
                                                {key['text']}
                                            </center>
                                        </div>
                                        <br />
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <div className="col-2">
                                            <img src={key['src']} className="img-fluid rounded mx-auto d-block" alt={key['alt']} width="100%" />
                                        </div>
                                        <div className="col-4 align-self-center">
                                            <div className="row">
                                                <div className="col mini-box">
                                                    <h1> <strong> {key['title']} </strong> </h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col mini-box">
                                                    {key['text']}
                                                </div>
                                            </div>
                                        </div>

                                    </React.Fragment>
                                }
                            </React.Fragment>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    function showVision() {
        let data = details['vision'] || []
        return (
            <React.Fragment>
                <div className="row pt-5">
                    <center>
                        <h1>
                            <strong> Vision </strong>
                        </h1>
                    </center>
                </div>
                <React.Fragment>
                    <div className="row">
                        {data.map(key => {
                            return (
                                <React.Fragment key={key.title}>
                                    {isMobile ?
                                        <React.Fragment>
                                            <div className="row">
                                                <center>
                                                    <img src={key['src']} id="zagana-video" className="img-fluid rounded mx-auto d-block" alt="Purpose Picture" />
                                                </center>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <center>
                                                    <h2>
                                                        <strong> {key['title']} </strong>
                                                    </h2>
                                                </center>
                                            </div>
                                            <div className="row">
                                                <center>
                                                    {key['text']}
                                                </center>
                                            </div>
                                            <br />
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <div className="col-2">
                                                <img src={key['src']} className="img-fluid rounded mx-auto d-block" alt={key['alt']} width="100%" />
                                            </div>
                                            <div className="col-4 align-self-center">
                                                <div className="row">
                                                    <div className="col mini-box">
                                                        <h4> <strong> {key['title']} </strong> </h4>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mini-box">
                                                        {key['text']}
                                                    </div>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            )
                        })}
                    </div>
                </React.Fragment>
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="container" style={{ 'paddingBottom': '40% 0%' }}>
                {showHeader()}
                {showPurposeMission()}
                {showVision()}
            </div>
            <LoginModal
                visible={visibleLogin}
                modalToggle={modalShow}
            />
        </React.Fragment>
    )
}
const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal
    }
}

export default AboutUs;