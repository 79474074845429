/*******************************************************************************************
* File Name                : productPage
* File Directory           : src/components/Product/ProductPageNew
* Module                   : ProductPageNew
* Author                   : Ryan
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/ProductPageNew.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   productPage        -   component for product per page
 *
 * #How to call:
 *    <ProductPageNew/>
 *
 *******************************************************************************************/
import React from "react";
import { useParams } from "react-router-dom";
import ProductPage from "./productPage";
import ProductPagev1 from "./ProductPagev1";
import { specialProduct } from "./specialProdJSON";


const ProductPageNew = () => {
    const params = useParams()
    const sku = params.sku
    // const specialSKU = ['ZGN00001815', 'ZGN00001826'] 
    const filterSku = specialProduct.filter(sp => sp.sku === sku)
    return (
        <React.Fragment>
                {
                    filterSku.length > 0 ?
                        <ProductPage prod={filterSku} />
                        :
                        <ProductPagev1 />
                }
        </React.Fragment>
    )
}
export default ProductPageNew;