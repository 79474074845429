//  * File Name                : SubCollection
//  * File Directory           : src/components/Misc/RecipePage/SubCollection
//  * Module                   : SubCollection
//  * Author                   : Bryan
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : src/App.js
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * #How to call:
 *    <SubCollection/>
 *
 *******************************************************************************************/


import React, { useEffect, useState } from "react";
// import header
import RecipeHeader from "./Components/Header";
// import recipeList
import { recipeList } from "./recipeList"
// impoirt css
import "./RecipePage.css"
// import Slide Show
import SlideShow from "./Components/SlideShow"
// import suggested collection
import SuggestedCollections from "./Components/SuggestedCollections";
// import recipe list

const SubCollection = () => {
    // isMobile
    const isMobile = localStorage.getItem("isMobile") === "true"
    // set max item to display 
    const [maxItem, setMaxItem] = useState(5)
    // declare maxed state to toggle if to display is more or less
    const [maxed, setMaxed] = useState(false)
    // set number of items to display upon click More/Less
    const [numberOfItems, setNumberOfItems] = useState()
    // set index of how many times you click
    const [index, setIndex] = useState(0)
    // set visible data to display
    const [visibleData , setVisibleData] = useState([])
    // declare action state for more/less function
    const [action,setAction] = useState("")
    // get category from local storage
    const category = localStorage.getItem("category")
    // filter collection equal to category name from local storage
    const filterCollection = recipeList.filter(el => el.categoryName === category)
    // declare collection length
    const filterCollection_length = filterCollection.length
    // visible data length
    const visibleDataLength = visibleData.length

    // useEffect for Show More/Less function
    useEffect(() => {
        // for mobile show no limit
        if (isMobile) {
          setMaxItem(filterCollection_length)
        }else{
          setMaxItem(5)
        }
        setNumberOfItems(maxItem * ( index + 1 )) ;
        // data container to update visible data state
        const newArray = []; 
        let i = 0 
        for( i ; i < filterCollection_length ; i++ ){
        if(i < numberOfItems) 
            // add data and set it to visible data
            newArray.push(filterCollection[i])
        }
        // setting visible datas
        setVisibleData(newArray);
        if (numberOfItems >= filterCollection_length) {
        // set max if number of items is greater or equal to main list
            setMaxed(true)
        }else{
            setMaxed(false)
        }
    }, [index, numberOfItems, filterCollection_length])



    //onClick function for Show More/Less
    const onClickLoad = () => {
        if (numberOfItems > filterCollection_length) {
        // set to default value 0
        setNumberOfItems(0)
        setIndex (0)
        }else{
        // add 1 on index
        setIndex ( index + 1 )
        }
    }

    console.log(filterCollection_length);
    console.log(maxItem);
    useEffect(()=>{
        if(filterCollection_length > 0){
            if(maxed){
                if(filterCollection_length <= maxItem){
                    setAction("")
                }else{
                    setAction("Less")
                }
            }else{
              setAction("More")
            }
        }
      },[maxed,filterCollection_length,maxItem])
    // render recipe list under "category" 
    const renderSubCollectionList = visibleData.map(col => {
    // declare image variable
    let suggested_image = col.recipeImages.image[0]
    // declare name variable
    let suggested_recipe_name = col.recipeName
    // declare description variable
    let suggested_recipe_description = col.recipeDescription

    

        if(isMobile){
            const onRecipeClick = () => {
                // redirect
                window.location.href = `/ContentPage/recipe/${suggested_recipe_name}`
            }
            return (
                <div key={col.categoryName}  onClick={onRecipeClick} className={`collection-mobile`}>
                    <div>
                        <div className="collection-overlay-wrapper">
                            <div className="collection-overlay"></div>
                            <img className="suggested-collection-image" src={suggested_image} alt={suggested_recipe_name} />
                            <div className="collection-sliding-desc">
                                <div className="row">
                                    <p className="collection-text sub-collection-text">{suggested_recipe_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) 
        }else{
            return (
                <div key={col.categoryName} className="collection-content centeredItem">
                    <div className="collection-overlay-wrapper">
                        <div className="collection-overlay"></div>
                        <img className="collection-image" src={suggested_image} alt={suggested_recipe_name} />
                        <div className="collection-sliding-desc">
                            <div className="row">
                                <p className="collection-text sub-collection-text">{suggested_recipe_name}</p>
                            </div>
                            <div className="row centeredItem collection-desc">
                                <p className="centeredText sub-collection-desc">{suggested_recipe_description}</p>
                            </div>
                            <div className="row">
                                <a href={`/ContentPage/recipe/${suggested_recipe_name}`} className="centeredItem btn btn-warning collection-text-btn">View</a>
                            </div>
                        </div>
                    </div>
                </div>
            ) 
        }
    
    
    })
    return (
        <div>
            <RecipeHeader /> 
            <div className="container p-0">
                <div className="row">
                    <div id="recipeCarousel">
                        <SlideShow recipeList={filterCollection} />
                    </div>
                </div>
                <div className="marginLR sub-collection-margin">
                    <div className="row collection collection-m">
                        <div className="row">
                            <h2>Related Recipes</h2>
                        </div>
                        <div className="row mt-20 centeredItem">
                            <div className={isMobile ? "collection-mobile-flex": "sub-collection-image-grid"}>
                                {renderSubCollectionList}
                            </div>
                            {    
                                isMobile ?
                                "" :
                                <div className="mt-20 centeredItem show-more-btn">
                                    <p className="show-more" onClick={onClickLoad}> {action} </p>
                                </div>
                            }
                            {visibleDataLength < 1 ? <p className="centeredText">No available Recipe</p> : null}
                        </div>
                    </div>
                    <div className="row collection">
                        <div className="row">
                            <h2>Other Collections</h2>
                        </div>
                        <div className="row mt-20">
                            <SuggestedCollections exception={category} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubCollection