
import axios from 'axios';

export async function quoteShipment(data) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://staging-api.shipmates.app/v1/quote',
        headers: {
            'X-Shipmates-Token': process.env.REACT_APP_SHIPMATES_KEY,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return axios(config);
}
export async function bookShipment(data) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://staging-api.shipmates.app/v1/book',
        headers: {
            'X-Shipmates-Token': process.env.REACT_APP_SHIPMATES_KEY,
            'Content-Type': 'application/json'
        },
        data: data
    };
    return axios(config);
}
export async function trackShipment(trackNumber) {
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://staging-api.shipmates.app/v1/track',
        headers: {
            'X-Shipmates-Token': process.env.REACT_APP_SHIPMATES_KEY,
            'Content-Type': 'application/json'
        },
        data: {
            "tracking_number": trackNumber
        }
    };
    return axios(config);
}