const INTIAL_STATE = {
    paymentSelected : 'Select payment',
    payment: false
}


const PaymentReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'SELECTED_PAYMENT':
            return {
                ...state, 
                paymentSelected: action.selected,
                payment: action.status

            };
        default:
            return state;
    }

};

export default PaymentReducer;