/*******************************************************************************************
* File Name                : productPage
* File Directory           : src/components/Product/SubCategoryPage
* Module                   : SubCategoryPage
* Author                   : Ryan
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/SubCategoryPage.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   SubCategoryPage        -   component for SubCategoryPage per page
 *
 * #How to call:
 *    <SubCategoryPage/>
 *
 *******************************************************************************************/
import React, { useState, useEffect } from 'react';

import Header from '../Shared/Header';

import Home from '../APIs/Home';
import { v4 as uuidv4 } from 'uuid';
import { connect, useDispatch } from 'react-redux';
import Cart from './CartModal';
import ProductCarousel from './ProductCarousel';
import { useParams } from 'react-router-dom';
import SubListingModal from "./SubListingModal";
import CartDetails from '../Shared/CartDetails';
import ItemListv2 from './ItemListv2';
import LoginModal from '../Shared/LoginModal';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import SpecificItemList from './SpecificItemList';
import { specialProduct } from './specialProdJSON';
import { sublisting } from './SublistingJSON';
import Backend from '../APIs/Backend';

const style = {
    minHeisght65vh: {
        minHeight: "65vh"
    },
    display: {
        display: "grid",
        gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
        gridGap: "10px",
        gap: "10px"
    },
    displayMobile: {
        display: "grid",
        gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
        gridGap: "10px",
        gap: "10px",
    },
    btn: {
        fontSize: "14px",
        padding: "5px 3px",
        lineHeight: '17px',
        height: "45px",
        border: "1px solid #dadada"
    },
    text: {
        color: "#222",
        width: "100%",
        textAlign: "center",
        WebkitLineClamp: "2",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        display: "-webkit-box",
    }
}

let defaultItem = {
    item_id: 0,
    item_name: "Product Name",
    item_variant: "500g",
    item_price: 300,
    item_description: "Description here - 500g",
    item_image: 'https://res.cloudinary.com/dw5axbz64/image/upload/v1619502504/upload/ugawjssee82witbmxvo9.jpg'
}
const SubListingPage = ({ CartRedux }) => {
    const params = useParams()
    const [branch, setBranch] = useState(null);
    let accessKey = localStorage.getItem('accessKey')
    const [show, setShow] = useState(false)
    const [term, setTerm] = useState('');// search for desktop
    const [cart, setCart] = useState(CartRedux);// cart for desktop
    const [onCartLoaded, setOnCartLoaded] = useState(false)
    const [selectedBranch, setSelectedBranch] = useState(1);
    const [total, setTotal] = useState({ itemCount: 0, itemTotal: 0 });
    const [isFirstRender, setIsFirstRender] = useState(true) //useeffect should not be triggered onload
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const [category, setCategory] = useState([]);
    const [items, setItems] = useState([]) //list of products
    const [filteredItems, setFilteredItems] = useState([])
    const [images, setImages] = useState([])
    const userLogin = JSON.parse(sessionStorage.getItem('Login')) || null;
    const userId = userLogin ? userLogin.customer_id : null
    const [productData, setProductData] = useState({})
    const [visibleCart, setVisibleCart] = useState(true); // show modal cart
    const [subList, setSubList] = useState([]) //sub list data
    const [subListImages, setSubListImages] = useState({
        gif: [],
        mobile: [],
        desktop: []
    }) //sub list data
    const [update, setUpdate] = useState(0)
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal  
    let cartModalShow = `modal cart ${visibleCart === true ? 'showModal' : ''}`; // class for cart show modal
    const [cartVDetails, setCartVDetails] = useState(defaultItem);
    const [selectedImage, setSelectImage] = useState('')
    const [cartVChange, setCartVChange] = useState(0);
    const [filteredCategory, setFilteredCategory] = useState([]);
    const isMobile = localStorage.getItem("isMobile") === "true";
    const isLogged = sessionStorage.getItem("Login") || '';
    const dispatch = useDispatch()
    const sublist = sublisting.filter(f => f.subList === params.subCategory)
    let selectedCity = localStorage.getItem('SelectedCity')
    let body = document.body; // for setting body height (modal)
    let getSelected = branch !== null ? branch : 'Please Select City'
    if (getSelected.branch_id === undefined) {
        getSelected = 'Please Select City'
    }

    useEffect(() => {
        let link = uuidv4();
        const accessStorage = async (status) => {
            await Home.post('/accessStorage', { accessToken: link })
            localStorage.setItem('accessKey', link)
            const city_details = {
                city_id: getSelected.city_id || 11,
                city_name: getSelected.city_name || 'Quezon City (QC)',
                branch_id: getSelected.branch_id || 1,
                branch_opening: '08:00',
                branch_closing: '17:00'
            }
            localStorage.setItem('SelectedCity', JSON.stringify(city_details))
            let accessToken = localStorage.getItem('accessKey')
            let result = Home.post(`/accessStorage/changeCity`, {
                accessToken: accessToken,
                city_details: city_details
            })
            if (status === 'tokenNotFound') {
                window.location.reload()
            }
        }
        if (!accessKey) {
            if (localStorage.getItem('branchSelected') === true) {
                accessStorage('tokenNotFound')
            } else {
                setBranch([{ city_id: getSelected.city_id || 11, branch_id: getSelected.branch_id || 1, city_name: getSelected.city_name || 'Quezon City (QC)', branch_closing: '17:00', branch_opening: '08:00' }])
                accessStorage()
            }
        } else {
            const getCart = async () => {
                let response = await Home.post('/cartChecker', { accessToken: accessKey })
                if (response.data.result === "AccessTokenNotFound") {
                    // console.log("IF1")
                    localStorage.setItem('branchSelected', false)
                    accessStorage('tokenNotFound')
                }
                else {
                    // console.log(response.data.cart)
                    setCart(response.data.cart || [])
                    setOnCartLoaded(true)
                    if (response.data.selectedCity === null || response.data.selectedCity === []) {
                        // console.log("IF2")
                        localStorage.setItem('branchSelected', false)
                        setSelectedBranch(1)
                        setBranch([{ city_id: getSelected.city_id || 11, branch_id: getSelected.branch_id || 1, city_name: getSelected.city_name || 'Quezon City (QC)', branch_closing: '17:00', branch_opening: '08:00' }])
                        // window.location.reload()
                    } else {
                        // console.log("ELSE2")
                        // console.log(response.data.selectedCity)
                        setSelectedBranch(response.data.selectedCity.branch_id)
                        localStorage.setItem('branchSelected', true)
                        setBranch(response.data.selectedCity || [])
                        const city_details = {
                            city_id: response.data.selectedCity.city_id || 11,
                            city_name: response.data.selectedCity.city_name || 'Quezon City (QC)',
                            branch_id: response.data.selectedCity.branch_id || 1,
                            branch_opening: response.data.selectedCity.branch_opening || '08:00',
                            branch_closing: response.data.selectedCity.branch_closing || '17:00'
                        }
                        localStorage.setItem('SelectedCity', JSON.stringify(city_details))
                        let accessToken = localStorage.getItem('accessKey')
                        Home.post(`/accessStorage/changeCity`, {
                            accessToken: accessToken,
                            city_details: {
                                city_id: response.data.selectedCity.city_id || 11,
                                city_name: response.data.selectedCity.city_name || 'Quezon City (QC)',
                                branch_id: response.data.selectedCity.branch_id || 1,
                                branch_opening: response.data.selectedCity.branch_opening || '08:00',
                                branch_closing: response.data.selectedCity.branch_closing || '17:00'
                            }
                        })
                    }
                }
            }
            getCart()
            getSubListingList()
        }
        dispatch({
            type: "SET_LOCATION",
            location: "/sub-list"
        })
    }, [])

    useEffect(() => {
        getSublistImages()
    }, [])

    //useEffect for getting products in DB 
    useEffect(async () => {
        if (branch !== null)
            try {
                const response = await Home.get(`/itemList2/ALL/${selectedBranch}/ALL${userLogin ? "?userId=" + userId : ""}`)
                if (response.status === 200) {
                    let result = response.data.data
                    let itemChecker = result !== "No Remaining Products"
                    if (itemChecker) {
                        result = result.map((item) => {
                            let defaultImg = "https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png"
                            item.item_image = item.item_image && item.item_image !== "NULL" ? item.item_image : defaultImg
                            let category = item.category
                            let sub_listing = item.sub_listing.toLowerCase().replace(/\s/g, "")
                            item.category = category?.split(",")
                            item.sub_listing = item.sub_listing === '' ? [] : sub_listing?.split(",")
                            return item
                        })
                    }
                    //product page removing item itself and only enabled items are shown
                    setItems(itemChecker ? result : [])
                }
                const category = async () => {
                    const response = await Home.get('/categoryList')
                    // const item
                    const category = response.data.category_list || []
                    setCategory(category)
                }
                if (branch !== null)
                    category()
            } catch (err) {
                console.log(err.message)
            }
    }, [branch])

    useEffect(() => {
        const tempItems = items.filter((data) => data.sub_listing.includes(params.subCategory.toLowerCase().replace(/\s/g, "")))
        const images = tempItems.map((data) => data.item_image)
        setImages(images)
        setFilteredItems(tempItems)
    }, [items])

    useEffect(() => {
        let relatedCategory = []
        filteredItems.forEach((el) => {
            relatedCategory.push(...el.category)
        })
        let uniqueCateg = [...new Set(relatedCategory)]
        const tempCategory = category?.filter((el) => uniqueCateg?.includes(el.category_tag?.toLowerCase().replace(/\s/g, "")))
        setFilteredCategory(tempCategory)
    }, [category])

    useEffect(() => {
        if (selectedImage) {
            document.getElementById(selectedImage)?.click()
        }
    }, [selectedImage, update])

    useEffect(() => {
        if (!isFirstRender) {
            try {
                Home.post('/accessStorage/changeCart', {
                    itemOnCart: CartRedux,
                    accessToken: accessKey
                })
            } catch (err) {
                console.log(err.message)
            }
        }
        else
            setIsFirstRender(false)
        let totalPrice = CartRedux.map((cart) => cart.item_status === "enabled" ? cart.cart_price * cart.cart_qty : 0) // returning price * qty
        let itemCount = 0
        CartRedux.forEach((cart) => { itemCount += cart.item_status === "enabled" ? cart.cart_qty : 0 }) // returning qty per item
        totalPrice = totalPrice.reduce((a, b) => a + b, 0) // sum of price * qty
        // itemCount = itemCount.reduce((a, b) => a + b, 0) // total of all items
        setTotal({ itemTotal: totalPrice, itemCount: itemCount })
        sessionStorage.setItem('total', JSON.stringify({ itemTotal: totalPrice, itemCount: itemCount }))
    }, [CartRedux])

    const getSubListingList = async () => {
        try {
            const response = await Home.get(`/sub-listing/${params.subCategory}`)
            // console.log("response.data.result");
            // console.log(response.data.result);
            if (response.status === 200) {
                setSubList(response.data.result || [])
            }
        } catch (err) {
            console.log(err)
            console.log(err.message)
        }
    }

    const getSublistImages = async () => {
        try {
            // console.log("sublist");
            // console.log(subList);
            const response = await Home.get(`/sublist-image/${params.subCategory}`)
            // console.log(response);
            if (response.status === 200) {
                const result = response.data.result
                const gif = result.filter(f => f.image_type === "Gif").map(m => m.image_source)
                const mobile = result.filter(f => f.image_type === "Mobile Reviews").map(m => m.image_source)
                const desktop = result.filter(f => f.image_type === "Desktop Reviews").map( m => m.image_source)
                setSubListImages({
                    gif: gif,
                    mobile: mobile,
                    desktop: desktop
                })

            }
            
        } catch (err) {
            console.log(err)
            console.log(err.message)
        }
    }

    // console.log("+++++++++++++++++++    subListImages");
    // console.log(subListImages);
    const onClickProduct = (e) => {
        e.cancelBubble = true;
        e.stopPropagation()
        const id = parseInt(e.target.dataset.id)
        const tempProductData = items.filter((data) => data.item_id === id)
        setProductData(tempProductData[0])
        setShow(true)
    }

    const onMouseEnter = (e) => {
        const time = Date.now()
        const name = e.target.dataset.image
        setSelectImage(name)
        setTimeout(() => {
            setUpdate(time)
        }, 550)
    }

    const onMouseLeave = () => {
        setSelectImage('')
    }
    const prices = () => {
        const getLowest = Math.min.apply( Math, filteredItems.map( f => f.flash_salePrice === null ? f.item_salePrice : f.flash_salePrice ) );
        const getHighest = Math.max.apply( Math, filteredItems.map( f => f.flash_salePrice === null ? f.item_salePrice : f.flash_salePrice ) );
        return `₱${getLowest} - ₱${getHighest}`
    }
    const renderProdBullets = specialProduct.filter(f => f.sku === "ZGN00001815").map(blts => {
        const bullets1 = blts.bullets[0]
        const bullets2 = blts.bullets[1]
        return (
            <>
            <div className="col-lg-3 col-sm-12 p-0 m-0 prodBenefits">
                <div className="w-100 r5-mv">
                    <img className="br-5 auto-mh w-100" src={bullets1.image} alt="benefits img" />   
                </div>
            </div>
            <div className="col-lg-3 col-sm-12  p-2 m-0">
                <h1 className="pv-h1 mt2r listT"><b>{bullets1.title}:</b></h1>
                <ul className="lh-30">
                    {bullets1.items.map(item => <li>{item}</li>)}
                </ul>
            </div>
            
            <div className="col-lg-3 col-sm-12  p-0 m-0 prodSpecs">
                        <div className="w-100 r5-mv">
                            <img className="br-5 auto-mh w-100" src={bullets2.image} alt="spec & packaging img" />   
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-12 p-2 m-0">
                        <h1 className="pv-h1 mt2r listT"><b>{bullets2.title}:</b></h1>
                        <ul className="lh-30">
                            {bullets2.items.map(item => <li>{item}</li>)}
                        </ul>
                    </div>
            </>
        )
    })
    return (
        <React.Fragment>
            <div className='sub-category p-0'>
                <Header
                    branch={branch}
                    setBranch={setBranch}
                    term={term}
                    total={total}
                    setTerm={setTerm}
                    visibleLogin={visibleLogin}
                    setVisibleLogin={setVisibleLogin}
                    visibleCart={visibleCart}
                    setVisibleCart={setVisibleCart}
                    body={body}
                />
                <div className='container p-0 mt-4 '>
                    <div className={`card sublisting-page productView ${isMobile ? null : 'm-3' }`} style={style.minHeisght65vh}>
                        <div className='row '>
                            <div className='col-lg-5 col-md-12 col-sm-12'>
                                <ProductCarousel images={images} />
                            </div>
                            <div className='col-lg-7 col-md-12 com-sm-12'>
                                <div className='row' style={{height: "100%"}}>
                                    <div className={`col-12   ${isMobile ? '' : 'mt-5 pt-3'}`} style={ isMobile ? null : {paddingLeft: "4rem"}}>
                                    <div className='row'>
                                    </div>
                                        <div className='row mt-2 mb-2 d-flex' style={{alignItems: "center"}}>
                                            <div>
                                                <h3>Zagana {params.subCategory}</h3>
                                            </div>
                                            <div className='d-flex' style={{alignItems: "center"}}>
                                                <Rating sx={{fontSize: 30}} name="size-large" defaultValue={5} precision={1} size="large" readOnly={true} />
                                                <div className="auto-mh" ><p style={{ color: "gray", marginBottom: "0" }}>5.0 Ratings</p></div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <h4>{prices()}</h4>
                                        </div>                                            
                                        <div className="row payments">
                                            <h4>Payment Options:</h4>
                                            <div className="paymentIconsDiv mb-3">
                                                <img className="paymentIcons" style={{ 
                                                        width: isMobile ? "100%" : "60%",
                                                        padding: "10px",
                                                        backgroundColor: "lightgray",
                                                        borderRadius: "10px"
                                                    }} src="https://res.cloudinary.com/dw5axbz64/image/upload/v1664162323/icons/payment-method_2_gzpzcn.png" alt="paymentmethodiconsdesk" />
                                            </div>
                                        </div>
                                        {isMobile ? null :
                                            <div className='row' 
                                            style={{
                                            // marginTop:"20px",
                                            display: "flex",
                                            alignContent: "center",
                                            alignItems: "center",}}>

                                                <h4>{subList.sub_category_hookline}</h4> 
                                                <p style={{fontSize: 15}}><i>{subList.sub_category_hookline_desc}</i></p>
                                                
                                            </div>
                                        }
                                        
                                        {/* <div>
                                            <h4>Variant :</h4>
                                            <div style={isMobile ? style.displayMobile : style.display} className={isMobile ? `mb-4` : ''} >
                                                {filteredItems?.map((data, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <button
                                                                className='btn sublisting-name-btn'
                                                                onMouseEnter={onMouseEnter}
                                                                onMouseLeave={onMouseLeave}
                                                                data-image={data.item_image}
                                                                onClick={onClickProduct}
                                                                data-id={data.item_id}
                                                                data-name={data.sub_listing_name}
                                                                style={style.btn}
                                                            >
                                                                <div
                                                                    className='sublisting-name'
                                                                    onClick={onClickProduct}
                                                                    onMouseEnter={onMouseEnter}
                                                                    onMouseLeave={onMouseLeave}
                                                                    data-image={data.item_image}
                                                                    data-id={data.item_id}
                                                                    data-name={data.sub_listing_name}
                                                                    style={style.text}
                                                                >
                                                                    {data.sub_listing_name}
                                                                </div>
                                                            </button>
                                                        </React.Fragment>
                                                    )
                                                })}
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="productRelated">
                                <div id="relatedProdIngr" className="relatedProdIngr pt-4 pb-4">
                                    <h4>Variants:</h4>
                                    <SpecificItemList
                                        options={[{
                                            category_tag: "relatedingredients",
                                            category_name: "Related Ingredients"
                                        }]}
                                        specificList={filteredItems.map(i => i.item_sku)}
                                        search={term}
                                        total={total}
                                        selectedCity={selectedCity}
                                        branch={branch}
                                    />
                                </div>
                                <div className='row pt-4 pb-4'>
                                    {
                                        subListImages.gif.length === 0 ? null :
                                        <div className='col-lg-4 col-sm-12 mt-2 mb-2'>
                                            <div className=''>
                                                <img   className='' 
                                                    style={{
                                                        borderRadius: "10px", 
                                                        width: isMobile? "100%" : "75%",
                                                        margin: "auto",
                                                        display: "block"}} 
                                                    src={subListImages.gif.length > 0 ? subListImages.gif[0] : subListImages.gif.map( m => m)}
                                                    alt="Description" />
                                            </div>
                                        </div>
                                    }
                                    <div className='col-lg-8 col-sm-12 mt-2 mb-2'>
                                        <div className=''>
                                            <h4 className='m-0'>Description:</h4>
                                            <span className='item-description col-12 r5-mv' style={{fontSize: "15px"}}>{subList.sub_category_description}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                <div className={`prodReviews ${ 
                                        isMobile ? 
                                            subListImages.mobile.length === 0 ? 'd-none' : null 
                                        : subListImages.desktop.length === 0 ? 'd-none' : null }
                                        `}>
                
                    <div>
                        <h4>Reviews:</h4>
                    </div> 
                    <>
                        <div className="w-100 r5-mv">
                            {   isMobile ? 
                                subListImages.mobile.length !== 0 ?
                                    subListImages.mobile?.map((img, index) => <img className="auto-mh br-5 w-100 mb-2" src={img} alt={`mobile banner ` + index} /> )
                                : null
                            :
                                subListImages.desktop.length !== 0 ?
                                     <img className="auto-mh br-5 w-100" src={subListImages.desktop.length > 0 ?  subListImages.desktop[0] : subListImages.desktop.map( d => d) } alt="desktop banner" /> 
                                : null
                            } 
                        </div>
                    </>
                </div>
                    </div>
                    <div className='row-12 m-3 mt-3'>
                        <div className='row productRelated' >
                            <div id="relatedProd-sublisting">
                                <ItemListv2
                                    options={filteredCategory || []}
                                    search={term}
                                    total={total}
                                    branch={branch}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Cart cartModalShow={cartModalShow} setCart={setCart} visibleCart={visibleCart} setVisibleCart={setVisibleCart} total={total} setTotal={setTotal} cartVDetails={cartVDetails} setCartVDetails={setCartVDetails} cartVChange={cartVChange} setCartVChange={setCartVChange} />

                < SubListingModal show={show} setShow={setShow} data={productData} />
                {isMobile ?
                    null
                    :
                    <CartDetails
                        total={total}
                        visibleLogin={visibleLogin}
                        setVisibleLogin={setVisibleLogin}
                    />
                }
                <LoginModal
                    visible={visibleLogin}
                    modalToggle={modalShow}
                />
            </div>

        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        CartRedux: state.cart.CartList,
    };
};



export default connect(mapStateToProps)(SubListingPage);