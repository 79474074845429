
/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * File Name                : TermsAndCondition
 * File Directory           : src/components/Misc/TermsAndCondition
 * Module                   : Show All Terms And Condition from Zagana
 * Author                   : Ava
 * Version                  : 1
 * Purpose                  : To view the Terms And Condition 
 * Used In				    : src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   TermsAndCondition        -   component for Terms And Condition Page
 *
 * #How to call:
 *    <TermsAndCondition />
 *
 *******************************************************************************************/

 import React, { useState, useEffect } from "react";
 import MetaTags from 'react-meta-tags';
 import DesktopHeader from '../Shared/DesktopHeader';
 import Header from '../Shared/Header';
 import LoginModal from '../Shared/LoginModal';

const styles = {
    indentedText: {
        paddingLeft: "9%"
    },
    headerBG: {
        'backgroundColor': '#facc02',
        'position': 'fixed',
        'zIndex': '2'
    },
    closeBtn: {
        width: '8%',
        float: 'right',
        textAlign: 'left'
    }
}
function TermsAndCondition({ showModal }) {
    const isMobile = localStorage.getItem("isMobile") === "true"
    const [visibleLogin, setVisibleLogin] = useState('true'); // show modal
    const isLogged = sessionStorage.getItem("Login") || ''; // login checker
    let modalShow = `modal login ${visibleLogin === 'true' ? 'showModal' : ''}`; // class for show modal



   
    document.title = "Zagana | Terms & Condition"
    return (
        <React.Fragment>
            <MetaTags>
                <title>Zagana | Terms and Condition</title>
                <meta name="description" content="These terms and conditions ('User Terms') apply to your visit to and your use of our website at www.zagana.com.ph (the 'Website'), the Service and the Application (as defined below), as well as to all information, recommendations and/or services provided to you on or through the Website, the Service and the Application." />
                <meta property="og:title" content="Zagana | Terms and Condition" />
                <meta property="og:image" content="https://res.cloudinary.com/dw5axbz64/image/upload/v1635938677/upload/placeholder_dyn8yx.png" />
                <meta property="og:url" content="https://www.zagana.com/ContentPage/terms" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Zagana Inc." />
                <meta name="keywords" content="farm-fresh, fresh, produce, local, frozen, farmers, quality, introduction, online grocery, online palengke, terms, condition, terms and condition" />
                <meta name="author" content="Zagana Inc." />
            </MetaTags>

            {isMobile ?

                <div style={{ position: 'fixed', zIndex: '2' }} className="p-0" >
                    <Header title="Terms &amp; Condition" />
                </div>
                : <div style={{ position: 'fixed', zIndex: '2' }} className="p-0" >
                    <DesktopHeader
                        visibleLogin={visibleLogin}
                        setVisibleLogin={setVisibleLogin} />
                </div>}
            <div className="container mt-5">
                <div className="row pt-5">
                    <h1>
                        <center>TERMS {"&"} CONDITIONS</center>
                    </h1>
                </div>
                <div className="row p-4 pt-0 pb-3">
                    <p>
                        These terms and conditions ("User Terms") apply to your visit to and your use of our website at www.zagana.com.ph (the "Website"), the Service and the Application (as defined below), as well as to all information, recommendations and/or services provided to you on or through the Website, the Service and the Application.
                    </p>
                    <p>
                        PLEASE READ THESE TERMS OF SERVICE CAREFULLY. BY ACCESSING OR USING ZAGANA, INC. APPLICATIONS OR ANY SERVICES PROVIDED BY US, YOU AGREE TO BE BOUND BY THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO ALL OF THESE TERMS AND CONDITIONS, DO NOT ACCESS OR USE ZAGANA, INC. APPLICATIONS OR ANY SERVICES PROVIDED BY US
                    </p>
                    <h2>
                        I. THE COMPANY
                    </h2>
                    <p>We are Zagana, Inc., a corporation duly organized under the laws of the Philippines, trading as ww.zagana.com.ph (“ZAGANA”), with office address at 2nd Floor Bookman Building, Quezon Ave, Quezon City, 1114 Metro Manila (the “Office”).</p>
                    <h2>II. DEFINITION OF TERMS</h2>
                    <p>a. <strong> “Account”</strong> means the ZAGANA Account that you will need to register for on the Site in the event that you would place an Order on the Site.</p>
                    <p>b. <strong>“Business Day”</strong> means any day on which banks in the Philippines are open for business, which is neither (i) a Saturday or Sunday, nor (ii) a public holiday anywhere in the Philippines.</p>
                    <p>c. <strong>“Clauses”</strong> refer to clauses of these Terms and Conditions.</p>
                    <p>d. <strong>“Contract”</strong> means your Order of a Product or Products in accordance with these Terms and Conditions which you accept in accordance with clause 6.2 below.</p>
                    <p>e. <strong>“Customer”</strong> means any individual who places an Order on the Site.</p>
                    <p>f. <strong>“Includes”</strong> or <strong>“including”</strong>  or like words or expressions shall mean without limitation.</p>
                    <p>g. <strong>“Order”</strong> means the Order submitted by you to the Site to purchase a Product from us.</p>
                    <p>h. <strong>“Order Confirmation”</strong> means our email to you, in which we acknowledge your Order in accordance with clause 6.2 below.</p>
                    <p>i. <strong>“Products”</strong> refer to any of the goods being sold on the Site.</p>
                    <p>j. <strong>“Site”</strong> means www.pushkart.ph.</p>
                    <p>k. <strong>“We”</strong> or <strong>“us”</strong> refers to ZAGANA.</p>
                    <p>l. <strong>“You”</strong> means the Customer who places an Order.</p>
                    <h2>III. LEGAL REQUIREMENT </h2>
                    <p>To place an Order with ZAGANA, you must be over eighteen (18) years of age. If you are under eighteen (18), you may place an Order with ZAGANA only with the involvement of a parent or guardian.</p>
                    <h2>IV. PROHIBITIONS </h2>
                    <p>You must not misuse this Site by committing or encouraging a criminal offense, by transmitting or distributing a virus including but not limited to Trojan horse, worm, and similar viruses or by posting any other material on the Site which is malicious, technologically harmful, in breach of confidence or in any way offensive or obscene; or by hacking into any aspect of the service; by corrupting data; by causing annoyance to other users; by infringing upon the rights of any other person’s propriety rights; by sending any unsolicited advertising or promotional material; or by attempting to affect the performance or functionality of any computer facilities of or accessed throughout the Site. In the event such breach occurs, ZAGANA will block your access to the Site and report the breach to the relevant law enforcement authorities and appropriate legal action will be taken.</p>
                    <h2>V. ENTIRE AGREEMENT </h2>
                    <p>5.1 These Terms and Conditions shall apply to all Orders and Contracts made or to be made by us for the sale and supply of Products. These Terms and Conditions constitute the entire agreement between you and ZAGANA and supersede any and all preceding and contemporaneous agreements between you and ZAGANA. Any waiver of any provision of the Terms and Conditions will be effective only if in writing and signed by an authorized signatory of ZAGANA.</p>
                    <p>5.2 You acknowledge that, in entering into this Contract, you did not rely on any representation, undertaking or promise given by the ZAGANA or implied from anything said or written between you and ZAGANA prior to such Terms and Conditions, except as expressly stated in these Terms and Conditions</p>
                    <h2>VI. TERMS OF THE SALE </h2>
                    <p>6.1 Registration</p>
                    <p style={styles.indentedText}>a. To place an Order, you must register with us by creating an Account on the Site. You must only submit to us information which is accurate and true. You must also keep your information up to date by informing us of any changes,by using the relevant sections of the Site.</p>
                    <p style={styles.indentedText}>b. You shall not misuse the Site by creating multiple user accounts.</p>
                    <p style={styles.indentedText}>c. A username and password may be selected for the privacy and security of your account. We recommend you not to share your user credentials with anyone.</p>
                    <p style={styles.indentedText}>d. An order can only be executed using your user credentials, after confirmation of your registration using your indicated e-mail address.</p>
                    <br />
                    <p>6.2 Formation of a Contract</p>
                    <p style={styles.indentedText}>a.The information set out in the Terms and Conditions and the details contained on this Site do not constitute an offer for sale but rather an invitation to make an offer. No Contract in respect of any Products shall exist between you and us until we have shipped the Products to your address.</p>
                    <p style={styles.indentedText}>b.To submit an Order, you will be required to follow the online shopping process on the Site. After this you will receive an Order Confirmation which will act as an acknowledgement of your Order.</p>
                    <p style={styles.indentedText}>c.A Contract will relate only to those Products which we have shipped or delivered to you. If your Order consists of more than one Product, the Products may be delivered to you in separate packages at separate times.</p>
                    <p>6.3 Price and Payment</p>
                    <p>While we make every effort to ensure that all details, descriptions and prices which appear on this Site are accurate, there may be instances where errors may occur. If we discover an error in the price of any Products which you have ordered, we will inform you of this as soon as possible and give you the option of reconfirming your Order at the correct price or cancelling it. If we are unable to contact you, we will treat the Order as cancelled. If you cancel your Order before we have shipped it to you, and you have already paid for your Order, you will receive a full refund through store credit.</p>
                    <p style={styles.indentedText}>a. Prices are inclusive of VAT and are in Philippine Peso. Shipping Fee will be charged in addition where applicable; such additional charges are clearly displayed and included in the ‘Final Total’.</p>
                    <p style={styles.indentedText}>b. The applicable Shipping Fee will apply for the ff. order subtotals:</p>
                    <p style={styles.indentedText}>○ Any Amount = PHP 79 Grocer’s fee</p>
                    <p style={styles.indentedText}>c. We are under no obligation to fulfil your Order if the price listed on the website is incorrect, even after you receive an Order Confirmation from us.</p>
                    <p style={styles.indentedText}>d. Currently, you can pay cash upon delivery. Similarly, you can pay all or part of the price of your Order using your store credit or a promotional voucher.</p>
                    <p style={styles.indentedText}>e. Upon authorization of the payment, by clicking the “Order now” button you are confirming that your orders are final or that you are the legitimate holder of the promotional voucher, and have sufficient funds or credit facilities to cover the cost of the Product and delivery</p>
                    <p>6.4 Product Returns</p>
                    <p>ZAGANA properly trains grocers to select the best possible goods, for customers. At this time item returns will not be processed after the delivery has been completed. If it occurs that ZAGANA made a mistake in any item delivered to you, please notify the grocer so the support team will coordinate with you for delivery of the proper items.</p>
                    <p>6.5 Refusal of Order</p>
                    <p>We reserve the right to withdraw any Products from this Site at any time and/or remove or edit any materials or content on this Site. We will make our best efforts to always process all Orders but there may be exceptional circumstances wherein we may need to refuse to process an Order after we have sent you an Order Confirmation, which we reserve the right to do at any time, at our sole discretion. If we cancel your Order and you have already made payment for your Order, your payment will be fully refunded to you through Bank Transfer. We will not be liable to you or any other third party by reason of our withdrawal of any Product from this Site, whether it has been sold or not, removal or revision of any of the materials or contents on this Site or refusal to process or accept an Order.</p>
                    <h2>VII. DELIVERY </h2>
                    <p>7.1 We aim to deliver the Product to you at the place of delivery requested by you in your Order and within the delivery period indicated by us in the Order Confirmation.</p>
                    <p>7.2 We will do our best to ensure we provide you all your ordered goods, within the stock inventory of our store partners. If the occasion arrives that one of your paid/ordered products is not available, we will automatically provide you a refund via Bank Transfer,.</p>
                    <p>7.3 Upon delivery of the Product, you may be required to sign for delivery. You may contact zaganacs@gmail.com in case of any faults, defects or damage to the Product upon delivery and you shall be required to provide the documents delivered with the Product as well as other details of the Order.</p>
                    <p>7.4 Please note that it might not be possible for us to deliver to some locations in which event, we will inform you using the contact details that you have provided us and arrange for either the cancellation of your Order or its delivery to an alternative delivery address which you will provide.</p>
                    <p>7.5 We shall deliver your Order in our standard packaging.</p>
                    <p>7.6 All risk in the Product shall pass to you upon delivery, except that, where delivery is delayed due to any breach of your obligations under Contract (see 7.8 below), risk shall pass at the date when delivery would have occurred if not for your breach. From the time the risk passes to you, we will not be liable for loss or destruction of the Product. Any breach of obligations by you, might impact your ability to shop on the Site in the future.</p>
                    <p>7.7 If you are not available to receive the delivery, we may leave a card or send you an email with instructions on the re-delivery of your Order.</p>
                    <p>7.8 If delivery is delayed through your unreasonable refusal to accept delivery or if you do not (within two weeks of our first attempt to deliver the Product to you) accept delivery, we may, without having to effect any other right or remedy available to us, do either or both of the following:</p>
                    <p style={styles.indentedText}>a.charge you for any fee and other costs reasonably incurred by us; or</p>
                    <p style={styles.indentedText}>b.no longer make the Product available for delivery or collection and notify you that we are immediately cancelling the applicable Contract, in which case we will refund to you through Bank Transfer, as may be applicable, any money already paid to us under the Contract, less our reasonable administration charges which includes the attempt to deliver and the return of the Product, and any other fees and costs we may incur.</p>
                    <p>7.9 We shall notify you if we expect that we will be unable to meet our estimated delivery period, but, except to the extent mandated by law, we shall not be liable to you for any losses, liabilities, costs, damages, charges or expenses arising out of late delivery.</p>
                    <p>You acknowledge that the Products are standard and not made to fit any particular requirements that you may have.</p>
                    <h2>VIII. CANCELLATION BY THE CUSTOMER BEFORE DELIVERY </h2>
                    <p>If you wish to cancel your Order before we have sent you the Order Confirmation or before the Order has been dispatched, please contact our Customer Service Team on zaganacs@gmail.com. No cancellation fees shall be applicable 12 hours before the confirmed delivery time. However, once the Order has been dispatched it may not be cancelled. If a rescheduling is needed, you may message us at zaganacs@gmail.com 12 hours before the confirmed delivery time and we will assist you as best as we can. No penalties, just appreciation for giving us the heads up!</p>
                    <h2>IX. PERSONAL DATA PROTECTION </h2>
                    <p>Please see our Privacy Policy.</p>
                    <h2>X. VOUCHERS </h2>
                    <p>10.1 You may use promotional vouchers as payment for Products on the Site. Specific details for promotional vouchers are listed below.</p>
                    <p>10.2 To redeem vouchers in your Account or check an existing credit, please visit "My Account" on the Site. Subject to clause 14.1, we accept no Liability for errors in the email address of the voucher recipient.</p>
                    <p>10.3 Vouchers assigned to you are non-transferrable.</p>
                    <p>10.4 In the event of fraud, an attempt at deception or in the event of suspicion of other illegal activities in connection with the redemption of your voucher on the Site, we are entitled to close your Account and/or require a different means of payment.</p>
                    <p>10.5 We assume no Liability for the loss, theft or illegibility of promotional vouchers.</p>
                    <p>10.6 In rare cases, ZAGANA may at its sole discretion discontinue or cancel any voucher previously issued and will not be responsible for any losses arising therefrom.</p>
                    <p>10.7 Conditions for the redemption of promotional vouchers:</p>
                    <p>From time to time we may distribute promotional vouchers that may be used only on the Site, which we will send to you either by email or post:</p>
                    <p style={styles.indentedText}>a. Promotional vouchers are valid for the specified period stated on the voucher, can only be redeemed once and may not be used in conjunction with other promotional vouchers and discounted items. We may exclude individual brands from voucher promotions in accordance with our sales strategies.</p>
                    <p style={styles.indentedText}>b. If you place an Order for a Product less than the value of the promotional voucher, no refund or residual credit will be returned to you.</p>
                    <p style={styles.indentedText}>c. The credit of a promotional voucher does not accrue interest nor does it have a cash value.</p>
                    <p style={styles.indentedText}>d. If the credit of a promotional voucher is insufficient for the Order you wish to place, you may make up the difference using one of our accepted payment methods.</p>
                    <h2>XI. DISCLAIMER OF LIABILITY </h2>
                    <p>11.1 The content displayed on this Site is provided without any guarantees, conditions or warranties as to its accuracy. Unless expressly stated to the contrary and to the fullest extent permitted by law, ZAGANA and its suppliers, content providers and advertisers hereby expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity and shall not be liable for any damages whatsoever, including but without limitation to any direct, indirect, special, consequential, punitive or incidental damages, or damages for loss of use, profits, data or other intangibles, damage to goodwill or reputation, or the cost of procurement of substitute goods and services, arising out of or related to the use, inability to use, performance or failures of this Site and any materials posted thereon, irrespective of whether such damages were foreseeable or arise in contract, tort, equity, restitution, by statute, at common law or otherwise.</p>
                    <h2>XII. INDEMNITY</h2>
                    <p>You agree to indemnify, defend, and hold harmless ZAGANA, its directors, officers, employees, consultants, agents, and affiliates, from any and all third party claims, liability, damages and/or costs (including but not limited to, legal fees) arising from your use of this Site or your breach of the Terms and Conditions.</p>
                    <h2>XIII. COOPERATION</h2>
                    <p>You agree to indemnify, defend, and hold harmless ZAGANA, its directors, officers, employees, consultants, agents, and affiliates, from any and all third party claims, liability, damages and/or costs (including but not limited to, legal fees) arising from your use of this Site or your breach of the Terms and Conditions.</p>
                    <p>13.1 ZAGANA shall perform its obligations under these Terms and Conditions with reasonable skills and care.</p>
                    <p>13.2 We place great value on our Customer satisfaction. You may contact us at any time through zaganacs@gmail.com. We will attempt to address your concerns as soon as possible and will contact you on receipt of any relevant enquiry or complaint. In guarantee cases, the manufacturer frequently must be involved, and as such it may take longer to resolve such inquiries or complaints.</p>
                    <p>13.3 In the event of a complaint it will help us if you can describe the object of your complaint as accurately as possible and, where applicable, send us copies of the Order or at least the Order number that we assigned to you in the Order Confirmation. In rare cases your emails may be caught up in our spam filters or not reach us, or correspondence that we send to you may otherwise not have reached you. Should you not have received any response from us within five</p>
                    <p>(5) Business Days, please make further enquiries through <strong> zaganacs@gmail.com. </strong> </p>
                    <h2>XIV. CIRCUMSTANCES BEYOND OUR CONTROL (FORCE MAJEURE) </h2>
                    <p>14.1 We shall not be liable to you for any breach, hindrance or delay in the performance of a Contract attributable to any cause beyond our reasonable control, including without limitation:</p>
                    <p style={styles.indentedText}>a. Strikes, lock-outs or other industrial action;</p>
                    <p style={styles.indentedText}>b. Civil commotion, riot, invasion, terrorist attack or threat of terrorist attack, war (whether declared or not) or threat or preparation for war;</p>
                    <p style={styles.indentedText}>c. Fire, explosion, storm, flood, earthquake, subsidence, epidemic or other natural disaster;</p>
                    <p style={styles.indentedText}>d.Impossibility of the use of railways, shipping, aircraft, motor transport or other means of public or private transport; and</p>
                    <p style={styles.indentedText}>e.Impossibility of the use of public or private telecommunications networks.</p>
                    <p>14.2 In the circumstance that the Force Majeure event lasts for more than one week, you may terminate the Contract forthwith by written notice and without any Liability on our part other than a refund through store credit of the Order already paid for by you and not delivered. We also have the option of terminating the Contract, by sending a written notice to you, and by giving you a full refund through Bank Transfer of your Order which you have already paid for and which was not delivered to you.</p>
                    <p>14.3 If we have contracted to provide identical or similar Products to more than one Customer and are prevented from fully meeting our obligations to you by reason of a Force Majeure event, we may decide at our absolute discretion which Contracts we will perform and to what extent.</p>
                    <p>14.4 We reserve absolute discretion on the solution we adopt in fully meeting our obligations under the Contract despite the Force Majeure Event.</p>
                    <h2>XV. NOTICES </h2>
                    <p>We reserve the right to amend these Terms and Conditions at any time. All amendments to these Terms and Conditions will be posted online. However, continued use of the Site will be deemed to constitute acceptance of the new Terms and Conditions. Any notice under a Contract shall be given in writing through either letter or email to the relevant party at the address or email address last known to the other.</p>
                    <p style={styles.indentedText}>a.Notices given by post shall be deemed to have been served within two Business Days from the time the notice was sent out to the recipient’s address.</p>
                    <p style={styles.indentedText}>b.Any notice given by email shall be deemed to have been served within two (2) Business Days of the email being sent. In proving such service, it shall be sufficient to prove that the letter or email was properly addressed and, as the case may be, posted as a prepaid or recorded delivery letter or a dispatch or a delivery report was received.</p>
                    <p>15.1 All content included in or made available through our Site, such as text, graphics, logos, button icons, images, audio clips, digital downloads, campaign titles and data compilations is the property of ZAGANA or its content suppliers and is protected by copyright laws. All such rights are reserved by ZAGANA and its licensors. You may store, print and display the content supplied solely for your own personal use. You are not permitted to publish, manipulate, distribute or otherwise reproduce, in any format, any of the content or copies of the content supplied to you or which appears on this Website nor may you use any such content in connection with any business or commercial enterprise.</p>
                    <p>15.2 In addition, graphics, logos, page headers, button icons, scripts, and service names included in or made available through the Site are trademarks of ZAGANA and may not be used in connection with any product or service that is not provided by ZAGANA, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits ZAGANA. All other trademarks not owned by ZAGANA that appear on the Site are the property of their respective owners.</p>
                    <p>15.3 Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
                    <p>15.4 You must not use any part of the content on our site for commercial purposes without obtaining a license to do so from us or our licensors. In the event ZAGANA becomes aware of any infringement of its intellectual property rights, it shall not refrain from taking any relevant legal action.</p>
                    <p>15.5 If you print, copy or download any part of our Site in breach of these Terms and Conditions, your right to use our Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. You shall not modify, translate, reverse engineer, decompile, disassemble or create derivative works based on any software or accompanying documentation supplied by ZAGANA or its licensors.</p>
                    <h2>XVI. LINKS TO THE SITE</h2>
                    <p>You may link to our home page, provided you do so in a way that is legal and is not likely to damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
                    <h2>XVII. WAIVER</h2>
                    <p>failure or delay by us or you in exercising any right under these Terms and Conditions or a ontract shall operate as a waiver of such right or extend to or affect any other or subsequent event or impair any rights or remedies in respect of it or in any way modify or diminish our or your rights under these Terms and Conditions.</p>
                    <h2>XVIII. SEVERABILITY</h2>
                    <p>If any clause in these Terms and Conditions shall become or shall be declared by any court of competent jurisdiction to be invalid or unenforceable, such invalidity or unenforceability shall in no way affect any other clause or part of any clause, all of which shall remain in full force and effect, so long as these Terms and Conditions or a Contract shall be capable of continuing in effect without the unenforceable term.</p>
                    <h2>XIX. ASSIGNMENT</h2>
                    <p>You shall not assign, transfer, novate, charge, sub-Contract, create any trust over or deal in any other manner with these Terms and Conditions or all or any of your rights or obligations under these Terms and Conditions.</p>
                    <h2>XX. RELATIONSHIP</h2>
                    <p>Nothing in these Terms and Conditions shall create or be deemed to create a partnership, an agency or a relationship between you and ZAGANA unless otherwise provided herein.</p>
                    <h2>XXI. THIRD PARTY RIGHTS</h2>
                    <p>No person who is not a party to these Terms and Conditions or a Contract shall acquire any rights under it or be entitled to benefit from any of its terms even if that person has relied on any such term or has indicated to any party to these Terms and Conditions its assent to any such term.</p>
                    <h2>XXII. GOVERNING LAW</h2>
                    <p>These Terms and Conditions (and all non-Contractual obligations arising out of or connected to them) shall be governed and construed in accordance with the laws of the Philippines. You hereby submit to the exclusive jurisdiction of the Courts of Quezon City for any future litigation arising from these Terms and Conditions and any non-Contractual obligations arising or connected to them. All dealings, correspondence and contacts between us shall be made or conducted in the English language only</p>
                    <h2>XXIII. VARIATION</h2>
                    <p>We reserve the right to amend these Terms and Conditions at any time. All amendments to these Terms and Conditions will be posted online. However, continued use of the Site will be deemed to constitute acceptance of the new Terms and Conditions.</p>
                    <p>When you submit an Order to the Site, you agree that you do so subject to these Terms and Conditions current at the date you submit your Order. You are responsible for reviewing the latest Terms and Conditions each time you submit your Order</p>
                    <h2>XXIV. INTERPRETATION</h2>
                    <p>Headings are for ease of reference only and shall not affect the interpretation or construction of the Terms and Conditions;</p>
                    <p>Words imparting the singular shall include the plural and vice versa. Words imparting a gender shall include every gender and references to persons shall include an individual, company, corporation, firm or partnership.</p>
                    <h2>XXV. FINAL PROVISION</h2>
                    <p>The English text of these User Terms constitutes the sole authentic text. In the event of any discrepancy between the English text and a translation into a foreign language, the English text shall prevail.</p>
                </div>

                <LoginModal
                    visible={visibleLogin}
                    modalToggle={modalShow}
                />
            </div>
        </React.Fragment>
    )
}


const mapStateToProps = (state) => {
    return {
        showModal: state.ModalLogin.showModal
    }
}

export default TermsAndCondition;