import React from 'react';
import HeaderLogo from '../../assets/logo/zagana-logo.png';
import LogoPrem from '../../assets/logo/zagana-prem.PNG';

const style = {
    imgSize: {
        width: '80%',
        padding: '5% 0%'
    }
}

const ZaganaLogo = () => {

    let pathChecker = window.location.pathname;
    return (
        <React.Fragment>
            {!(pathChecker).includes("premium") ? 
                <img src={HeaderLogo} alt="zagana.com" style={style.imgSize} />
                :
                <img id="premiumLogo" src={LogoPrem} alt="zagana.com" style={style.imgSize} />
            }
        </React.Fragment>
    );
}

export default ZaganaLogo;