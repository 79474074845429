import moment from "moment";
import Home_api from '../APIs/Home';

export const SaveToDatabase = (params) => {
    const { action, sku } = params
    const dateNow = moment().format('YYYY-MM-DD')
    const dateFromSession = sessionStorage.getItem('date')
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let accessKey = localStorage.getItem('accessKey')
    let current_page = sessionStorage.getItem('current_page')
    let source = sessionStorage.getItem('source') || ''
    let date = localStorage.getItem('date') || ''
    try {
        Home_api.post('/source_analytics', {
            access: accessKey,
            user: getUser?.customer_email || '',
            source: source,
            current_page: current_page || '',
            sku: sku || '',
            action: action || ''
        })
    } catch (err) {
        console.log(err.message)
    }
};

export const searchToDatabase = (search) => {
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const gen_id = localStorage.getItem('gen_id')
    if (search !== '') {

        try {
            Home_api.post('search-database', {
                id: getUser?.customer_id || 0,
                search: search,
                gen_id: gen_id
            })
        } catch (err) {
            console.log(err.message)
        }
    }
}