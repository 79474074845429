import React, { useEffect, useState } from "react";
import Home from '../APIs/Home';
import LazyLoad from "react-lazyload";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { getCookieConsentValue } from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { forgotModal } from "../../actions";
import { storeFrontLogs } from "../APIs/Logs";
import { connect, useDispatch } from "react-redux";
import { v4 as uuid } from 'uuid';
import _ from 'lodash'
import AddedModal from "../Shared/AddedModal";
import AddEventGA from "../Shared/AddEventGA";
import ReactGA from 'react-ga4';
import { SaveToDatabase } from "../Shared/SaveToDatabase";

const CartButtons = ({
    CartRedux,
    item
}) => {

    let source = sessionStorage.getItem('source')||''
    let date = localStorage.getItem('date')||''
    const dispatch = useDispatch()
    const [cartQty, setCartQty] = useState(0)
    const [onCart, setOnCart] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    useEffect(() => {
        let cartList = CartRedux
        let itemDetails = cartList.filter((obj) => {
            return obj.item_id === item.item_id;
        });
        let qty = cartQty
        if (itemDetails.length > 0) {
            setCartQty(itemDetails[0].cart_qty)
            setOnCart(true)
        } else {
            setOnCart(false)
            setCartQty(0)
        }
    }, [CartRedux,item])
    const btnAddOnClick = () => {
        // let items = [...CartRedux];

        ReactGA.event({
            'category': item.category,
            'action': 'Add to cart',
            'label': item.item_name
        });
        
        let insertCart={
            item_id: item.item_id,
            cart_qty: 1,
            cart_price: parseFloat(item.item_salePrice),
            item_price: parseFloat(item.item_price),
            item_branch: item.item_branch,
            item_status: item.item_status,
            item_sku: item.item_sku,
            item_var: item.item_variant,
            item_name: item.item_name,
            item_image: Array.isArray(item.item_images) ? item.item_images[0].image_url : item.item_image,
            category: item.category,
            storehub_id: item.storehub_id || "",
            item_limit: item.item_limit,
            parent_id: item.parent_id
        }
        window.gtag("event", "add_to_cart", {
            currency: "PHP",
            value: parseFloat(item.item_price),
            items: [
              {
                item_id: item.item_sku,
                item_name: item.item_name,
                currency: "PHP",
                item_category:  item.category,
                item_variant: "green",
                price: parseFloat(item.item_price),
                quantity: 1
              }
            ]
          });
        dispatch({
            type: "ADD_ITEM",
            newItem: insertCart,
        });
        if (source === 'FB') {
            SaveToDatabase({
                action: {
                    type: 'AddToCart',
                    value: {
                        content_ids: [item.item_sku],
                        content_type: 'product',
                        content_name: item.item_name,
                        currency: "PHP",
                        value: parseFloat(item.item_price), // your total transaction value goes here
                    }
                },
                sku: item.item_sku
            })
            ReactPixel.track('AddToCart', {
                content_ids: [item.item_sku],
                content_type: 'product',
                content_name: item.item_name,
                currency: "PHP",
                value: parseFloat(item.item_price), // your total transaction value goes here
            });
        }else if (source === 'Tiktok'){
            SaveToDatabase({
                action: {
                    type: 'AddToCart',
                    value: {
                        content_ids: [item.item_sku],
                        content_type: 'product',
                        content_name: item.item_name,
                        currency: "PHP",
                        value: parseFloat(item.item_price), // your total transaction value goes here
                    }
                },
                sku: item.item_sku
            })
            window.ttq.track('AddToCart',{  
                content_type: "product",
                content_id: item.item_sku,
                content_name: item.item_name,
                quantity: 1,
                price: parseFloat(item.item_price),
                value: parseFloat(item.item_price),
                currency: 'PHP',
            })
        }
    }
    const btnIncrementOnClick = () => {
    if (source === "Tiktok") {
        SaveToDatabase({
            action: {
                type: 'IncreaseQuantity',
                value: {
                    content_ids: [item.item_sku],
                    content_type: 'product',
                    content_name: item.item_name,
                    currency: "PHP",
                    value: parseFloat(item.item_price), // your total transaction value goes here
                }
            },
            sku: item.item_sku
        })
        window.ttq.track('ClickButton', {
            content_type:'product', 
            content_id: item.item_sku, 
            content_name: item.item_name, 
            description: 'IncreaseQuantity'
        })
    }
        ReactGA.event({
            'category': item.category,
            'action': 'increase QTY',
            'label': item.item_name
        });
        dispatch({
            type: "ADD_QUANTITY",
            id: item.item_id,
            flash: ((item.category || "all")).includes('flash') ? true : false
        });
    }
    const btnDecrementOnClick = () => {
        if (source === "Tiktok") {
            SaveToDatabase({
                action: {
                    type: 'DecreaseQuantity',
                    value: {
                        content_ids: [item.item_sku],
                        content_type: 'product',
                        content_name: item.item_name,
                        currency: "PHP",
                        value: parseFloat(item.item_price), // your total transaction value goes here
                    }
                },
                sku: item.item_sku
            })
            window.ttq.track('ClickButton', {
                content_type:'product', 
                content_id: item.item_sku, 
                content_name: item.item_name, 
                description: 'DecreaseQuantity'
            })
        }
        ReactGA.event({
            'category': item.category,
            'action': 'decrease QTY',
            'label': item.item_name
        });
        dispatch({
            type: "MINUS_QUANTITY",
            id: item.item_id,
            flash: ((item.category || "all")).includes('flash') ? true : false
        });
    }
    const inputQtyChange = (type, value) => {
        if (type === "blur") {
            //on blur remove if 0 else just update qty
            if (value === 0 || value === '0' || value.replace(/ /gi) === "") {
                ReactGA.event({
                    'category': item.category,
                    'action': 'Remove product',
                    'label': item.item_name
                });
                if (source === "Tiktok") {
                    SaveToDatabase({
                        action: {
                            type: 'RemoveToCart',
                            value: {
                                content_ids: [item.item_sku],
                                content_type: 'product',
                                content_name: item.item_name,
                                currency: "PHP",
                                value: parseFloat(item.item_price), // your total transaction value goes here
                            }
                        },
                        sku: item.item_sku
                    })
                    window.ttq.track('ClickButton', {
                        content_type:'product', 
                        content_id: item.item_sku, 
                        content_name: item.item_name, 
                        description: 'RemoveToCart'
                    })
                }
                window.gtag("event", "remove_from_cart", {
                    currency: "PHP",
                    value: item.item_price,
                    items: [
                      {
                        item_id: item.item_sku,
                        item_name: item.item_name,
                        currency: "PHP",
                        index: 0,
                        item_category: item.category,
                        price: item.item_price,
                        quantity: 1
                      }
                    ]
                  });
                dispatch({
                    type: "REMOVE_ITEM",
                    id: item.item_id
                });
            }
            else
                dispatch({
                    type: "UPDATE_QUANTITY",
                    value: parseInt(value),
                    id: item.item_id,
                    flash: item.category.includes('flash') ? true : false
                });
        } else {
            setCartQty(value)
        }
    }
    return (
        <React.Fragment>
            <div className="product-buttons ">
                {
                    !onCart ?
                        <button
                            id={`btnAddCart${item.item_id}`}
                            onClick={btnAddOnClick}
                            className="product-button-oval add-button text-center"
                        >
                            ADD
                        </button >
                        :
                        <>
                            <button
                                id={`btnMinusCart${item.item_id}`}
                                onClick={btnDecrementOnClick}
                                className="product-button-round decrement-button text-center"
                            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 25" fill="none" stroke="#55b24d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px" ><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="83.34" y1="12.5" x2="12.5" y2="12.5" /></g></g></svg>
                            </button>
                            <input
                                id={`inputCartQty${item.item_id}`}
                                className="product-input"
                                type="number"
                                value={cartQty}
                                onChange={(e) => inputQtyChange("change", e.target.value)}
                                onBlur={(e) => inputQtyChange("blur", e.target.value, e)}
                            />
                            <button
                                id={`btnAddCart${item.item_id}`}
                                onClick={btnIncrementOnClick}
                                className="product-button-round increment-button text-center"
                            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.84 95.84" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="20px" width="15px"><g id="Layer_2" data-name="Layer 2"><g id="Layer_13" data-name="Layer 13"><line className="cls-1" x1="47.92" y1="12.5" x2="47.92" y2="83.34" /><line className="cls-1" x1="83.34" y1="48.96" x2="12.5" y2="48.96" /></g></g></svg>
                            </button >
                        </>
                }
            </div>
            <AddedModal show={modalShow} />
        </React.Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
        CartRedux: state.cart.CartList,
    };
};

export default connect(mapStateToProps)(CartButtons);