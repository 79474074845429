
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Header from "../Shared/Header";
import Gcash from "../../assets/site_icons/GCash.svg"
import Grabpay from "../../assets/site_icons/GrabPay.svg"
import Debit from "../../assets/site_icons/Credit-Debit Card.svg"
import COD from "../../assets/site_icons/COD.svg"
import UB from "../../assets/site_icons/unionbank-icon-black.svg"
import BPI from "../../assets/site_icons/bpi-icon-black.svg"
import PAYMAYA from "../../assets/site_icons/paymaya.svg"
import landbank from "../../assets/site_icons/landbank.png"
import { storeFrontLogs } from "../APIs/Logs";

const style = {
    img: {
        width: '6rem',
        height: '67px',
        padding: '0.5rem'
    },
    img2: {
        width: '100%',
        padding: '0px',
        marginTop: '12%'
    },
    selected: {
        outlineColor: "#24b14b",
        outlineStyle: "solid",
        boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)',
        width: "100%",
        height: "102px"
    },
    paymentBtn: {
        width: "100%",
        height: "102px"
    },
    paymayaimg: {
        width: "100%",
        height: "100%",
        display: "flex",
        marginTop: ".6rem"
    },
    landbankimg: {
        width: "100%",
        height: "100%",
        display: "flex",
        margin: "0 auto",
        marginTop: "0.1rem",
        paddingTop: "15%"
    }
}

const PaymentMethod = ({ payment, orderTotal , disabledCOD }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const isMobile = localStorage.getItem("isMobile") === "true"
    const NumStops = sessionStorage.getItem('NumStops') || null
    const LBLogin = sessionStorage.getItem('LBLogin') || false
    const LBLoginRef = useRef();
    const nonCOD = sessionStorage.getItem('disabledCOD') === "true"
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    let gen_id = localStorage.getItem('gen_id')
    let user;
    let userLogin;

    if (getUser) {
        userLogin = JSON.parse(sessionStorage.getItem('Login'))
        gen_id = localStorage.getItem('gen_id')
        user = userLogin.customer_name + ` (${gen_id})`
    } else if (gen_id) {
        user = gen_id
    }
    useEffect(() => {
        if (LBLogin === "true") {
            LBLoginRef.current.click()
        }
    }, [])
    useEffect(()=>{
        if(disabledCOD && payment === 'COD'){
            dispatch({
                type: "SELECTED_PAYMENT",
                selected: "Select payment",
                status: true
            })
        }
    },[disabledCOD])
    
    const onClickPayment = (e) => {
        e.preventDefault()
        sessionStorage.setItem('payment', e.currentTarget.id.replace("-", "/"))
        let payment = e.currentTarget.id.replace("-", "/").toLowerCase()
        dispatch({
            type: "SELECTED_PAYMENT",
            selected: e.currentTarget.id.replace("-", "/"),
            status: true
        })
        const payload = {
            user: user,
            changes: `Zagana || Checkout`,
            type: `selected_${payment}_payment`,
            date: `NOW()`
        }
        storeFrontLogs(payload)
        // if (isMobile) {
        //     history.push('/checkout-page')
        // }
    }


    return (
        <React.Fragment>

            {isMobile ?
                <React.Fragment>
                    {/* {!modalClick ?
                        <Header title="Payment Method" />
                        :
                        null
                    } */}
                    <div className="container mt-2 mb-5 pb-2">
                        <div className="row">
                            {
                                LBLogin === "true" ?
                                    <div className="col p-0 ">
                                        <div ref={LBLoginRef} className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="LandBank" onClick={onClickPayment} style={payment === 'LandBank' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        <img src={landbank} alt="landbank" className="filterDark" style={style.landbankimg} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>LANDBANK</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="col p-0 ">
                                        <div className={`paymentMethod p-2 m-2 ${NumStops === "Multiple" || nonCOD || disabledCOD ? 'disabledCOD' : ''}`} id="COD" onClick={onClickPayment} style={payment === 'COD' ? style.selected : {}} >
                                            <div className="card-body" >
                                                <div className="row text-center " >
                                                    <div className="col-6">
                                                        <img src={COD} alt="COD_logo" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>CASH ON DELIVERY</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="Debit-Credit" onClick={onClickPayment} style={payment === 'Debit/Credit' ? style.selected : {}} >
                                            <div className="card-body" >
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        <img src={Debit} alt="debit_logo" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>DEBIT/CREDIT</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="GCash" onClick={onClickPayment} style={payment === 'GCash' ? style.selected : {}} >
                                            <div className="card-body">
                                                <div className="row  text-center">
                                                    <div className="col-6">
                                                        <img src={Gcash} className="" alt="gcash_logo" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>GCASH</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="Grabpay" onClick={onClickPayment} style={payment === 'Grabpay' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        <img src={Grabpay} alt="grab_pay_logo" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>GRAB PAY</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="UB" onClick={onClickPayment} style={payment === 'UB' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        <img src={UB} alt="UnionBank" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>Union Bank</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="BPI" onClick={onClickPayment} style={payment === 'BPI' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        <img src={BPI} alt="BPI" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>BPI</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="Paymaya" onClick={onClickPayment} style={payment === 'Paymaya' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        <img src={PAYMAYA} alt="BPI" style={style.img} />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>Paymaya</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="LandBank" onClick={onClickPayment} style={payment === 'LandBank' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                                        {/* <img src={PAYMAYA} alt="BPI" style={style.img} /> */}

                                                        <img src={landbank} alt="landbank" className="filterDark" />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>LANDBANK</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={` ${isMobile ? "" : "card"} paymentMethod p-2 m-2 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="Billease" onClick={onClickPayment} style={payment === 'Billease' ? style.selected : {}}>
                                            <div className="card-body">
                                                <div className="row text-center">
                                                    <div className="col-6">
                                

                                                        <img src={Billease} alt="Billease" className="filterDark" />
                                                    </div>
                                                    <div className="col-6 my-auto">
                                                        <strong>Billease</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                            }
                        </div>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <div className="container mt-2">
                        {
                            LBLogin === "true" ?
                                <div className="col-sm-3 p-1">

                                    <div ref={LBLoginRef} className="card card-payment m-1 " id="LandBank" onClick={onClickPayment} style={payment === 'LandBank' ? style.selected : style.paymentBtn}>
                                        <div className="card-body">
                                            <div className="row text-center">
                                                <div className="row-6">
                                                    {/* <img src={PAYMAYA} alt="paymaya" style={style.paymayaimg} /> */}
                                                    LANDBANK
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <React.Fragment>
                                    <div className="row m-1">
                                        <div className="col-sm-3 p-1">
                                            <div className={`card card-payment  m-1
                                                ${NumStops === "Multiple" || nonCOD ? 'disabledCOD' : ''}
                                                `} id="COD" onClick={onClickPayment} style={payment === 'COD' ? style.selected : style.paymentBtn}>
                                                <div className="card-body p-0" >
                                                    <div className="row text-center " >
                                                        <div className="row-6 p-0 m-0">
                                                            <img src={COD} alt="COD_logo" className="img img-fluid" style={style.img} />
                                                        </div>
                                                        <div className="row-6 my-auto">
                                                            <strong>CASH ON DELIVERY</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  col-sm-3 p-1 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="Debit-Credit" onClick={onClickPayment} style={payment === 'Debit/Credit' ? style.selected : style.paymentBtn}>
                                                <div className="card-body  p-0 ">
                                                    <div className="row text-center">
                                                        <div className="row-6">
                                                            <img src={Debit} alt="debit_logo" className="img img-fluid" style={style.img} />
                                                        </div>
                                                        <div className="row-6 my-auto">
                                                            <strong>DEBIT/ CREDIT</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  col-sm-3 p-1 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="GCash" onClick={onClickPayment} style={payment === 'GCash' ? style.selected : style.paymentBtn}>
                                                <div className="card-body  p-3 pb-2 ">
                                                    <div className="row  text-center">
                                                        <div className="row-6 pb-2">
                                                            <img src={Gcash} className="img img-fluid " alt="gcash_img" style={style.img} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-sm-3 p-1">
                                            <div className={`card card-payment m-1  col-sm-3 p-1 ${orderTotal === 0 ? 'disabledCOD' : ''}`} id="Grabpay" onClick={onClickPayment} style={payment === 'Grabpay' ? style.selected : style.paymentBtn}

                                            >
                                                <div className="card-body  p-3 pb-2">
                                                    <div className="row text-center">
                                                        <div className="row-6 pb-2">
                                                            <img src={Grabpay} alt="grab_pay_logo " className="img img-fluid" style={style.img} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                    <div className="row m-1">

                                        <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  ${orderTotal === 0 ? 'disabledCOD' : ''} `} id="UB" onClick={onClickPayment} style={payment === 'UB' ? style.selected : style.paymentBtn}

                                            >
                                                <div className="card-body  p-3 pb-2">
                                                    <div className="row text-center">
                                                        <div className="row-6 pb-2">
                                                            <img src={UB} alt="UnionBank " className="p-0 img img-fluid" style={style.img2} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  ${orderTotal === 0 ? 'disabledCOD' : ''} `} id="BPI" onClick={onClickPayment} style={payment === 'BPI' ? style.selected : style.paymentBtn}

                                            >
                                                <div className="card-body  p-3 pb-2">
                                                    <div className="row text-center">
                                                        <div className="row-6 pb-2">
                                                            <img src={BPI} alt="BPI " className="img img-fluid" style={style.img} />
                                                        </div>
                                                        <div className="row-6 my-auto">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  ${orderTotal === 0 ? 'disabledCOD' : ''} `} id="Paymaya" onClick={onClickPayment} style={payment === 'Paymaya' ? style.selected : style.paymentBtn}>
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="row-6">
                                                            <img src={PAYMAYA} alt="paymaya" style={style.paymayaimg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  ${orderTotal === 0 ? 'disabledCOD' : ''} `} id="LandBank" onClick={onClickPayment} style={payment === 'LandBank' ? style.selected : style.paymentBtn}>
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="row-6">
                                                            <img src={landbank} alt="landbank" className="filterDark" style={style.landbankimg} />  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-sm-3 p-1">

                                            <div className={`card card-payment m-1  ${orderTotal === 0 ? 'disabledCOD' : ''} `} id="Billease" onClick={onClickPayment} style={payment === 'Billease' ? style.selected : style.paymentBtn}>
                                                <div className="card-body">
                                                    <div className="row text-center">
                                                        <div className="row-6">
                                                            <strong>Billease</strong>
                                                            <img src={landbank} alt="Billease" className="filterDark" style={style.Billeaseimg} />  
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </React.Fragment>
                        }

                    </div>

                </React.Fragment>
            }
        </React.Fragment >
    )
}
const mapStateToProps = (state) => {
    return {
        payment: state.payment.paymentSelected
    }
}
export default connect(mapStateToProps)(PaymentMethod);