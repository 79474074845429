
const CustomerInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case 'INITIAL_VALUE':
            return {
                ...state,
                fullName: action.fullName,
                Email: action.Email,
                Phone: action.Phone,
                Birthdate: action.Birthdate,
                Password: action.Password
            };
        default:
            return state;
    }

};

export default CustomerInfoReducer;