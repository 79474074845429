/*******************************************************************************************
 *
 * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 *
 * File Name                : CheckoutDetails
 * File Directory           : src/components/APIs/Paymongo
 * Module                   : Shared
 * Author                   : Ryan Rosario
 * Version                  : 1
 * Purpose                  : render Gcash/Grabpay payment method  
 * Used In					: src/App.js
 *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   NextAction        -   component for rendering Gcash/Grabpay payment method  
 *
 * #How to call:
 *    /paymongo/source
 *
 *******************************************************************************************/
import React from "react";

const style = {
    height: {
        height: '98vh',
        width: '100%'
    }
}

const Source = () => {
    const nextAction = sessionStorage.getItem('paymongo') || '{}'
    const parsedNextAction = JSON.parse(nextAction)

    return (
        <React.Fragment>
            <iframe title="paymongo" style={style.height} src={parsedNextAction.redirect} />
        </React.Fragment>
    )

}



export default Source;