
//  * File Name                : DeliveryFields
//  * File Directory           : src/components/Home/FilterList
//  * Module                   : FilterList
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : 
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   DeliveryFields        -   component for setting delivery schedule
 *
 * #How to call:
 *    <FilterList/>
 *
 * Note: Still working on the ff:
 * UI and Link (Not yet tested)
 * 
 * 

 *******************************************************************************************/
 import React, { useState, useRef, useEffect } from 'react';
 import { Dropdown } from 'react-bootstrap';
 import arrowDown from '../../assets/site_icons/arrowdown.svg';
 import { useHistory } from 'react-router-dom';
 import { Link } from 'react-scroll';
 
 
 const style = {
     sortByForm: {
         width: ' 80%',
         backgroundColor: '#fff',
         color: '#000',
         textAlign: 'left'
     },
     bgColorNav: {
         background: '#f4cc0e'
     },
     linkColorW: {
         color: '#222',
         fontWeight: '500'
     },
     labelFont: {
         fontSize: '13px'
     }
 
 }
 
 const FilterList = ({ options, selected, onSelectedChange, label, active, slide, viewing, setViewing }) => {
     const [visible, setVisible] = useState(false);
     const ref = useRef();
     const isMobile = localStorage.getItem("isMobile");
     const [slidePos, setSlidePos] = useState("");
     const [scrollPosition, setScrollPosition] = useState(0);
     const history = useHistory();
 
     const renderedOptions = options.map((option, index) => {
         if (option.category_name === label) {
             return null;
         }
         return (
             <Link id={`li_${option.category_name.replace(" ", '').replace(" ", '').toLowerCase()}`} to={`${option.category_tag.replace(" ", '').toLowerCase()}`} spy={true} smooth={true}
                 onClick={() => {
                     window.gtag("event", "select_content", {
                         content_type: "categories",
                         item_id: option
                     });
                 }}
             >
                 <Dropdown.Item onClick={() => {
                     
                     
                    window.history.pushState('page2', 'Zagana | Home', '?' + option.category_name.replace(/ /g, ''));  
                     onSelectedChange(option)
                     /** Google Analytics */
                     window.gtag("event", "select_content", {
                         content_type: "categories",
                         item_id: option
                     });
                     /** Google Analytics */
                 }}
                     key={index}
                 >
                     {option.category_name}
                 </Dropdown.Item>
             </Link>
         );
     });
 
     //rendering category list for desktop view
     const renderedCateg = options.map((option_pc, index) => {
         return (
             // this conditon is for adding activated styles
             <li id={`li_${option_pc.category_name.replace(" ", '').replace(" ", '').toLowerCase()}`} className={`nav-item ${option_pc.category_name.replace(" ", '').toLowerCase() === active.replace(" ", '').toLowerCase() ? 'actived' : ''}`} key={option_pc.category_id}>
                 <Link to={`${option_pc.category_tag.replace(" ", '').toLowerCase()}`} spy={true} smooth={true} style={style.linkColorW} href={`#${option_pc.category_tag.replace(" ", '').toLowerCase()}`} onClick={() => {
                     
                    window.history.pushState('page2', 'Zagana | Home', '?' + option_pc.category_tag.replace(/ /g, ''));  
                     onSelectedChange(option_pc)
                     /** Google Analytics */
                     // if(getCookieConsentValue('zaganaCookie')==='true'){
                     window.gtag("event", "select_content", {
                         content_type: "categories",
                         item_id: option_pc
                     });
                     // }
                     /** Google Analytics */
                 }}>
                &nbsp;{option_pc.category_name}
                 </Link>
             </li>
         );
     });
 
 
     //toggle to left slide
     const leftSlide = () => {
         setSlidePos("firstSlide")
     }
     //toggle to right slide
     const rightSlide = () => {
         setSlidePos("secondSlide")
     }
     //on scroll toggle the category slider
     const handleScroll = () => {
         const position = window.pageYOffset;
         setScrollPosition(position);
     };
     //setting the category upon scrolling
     useEffect(() => {
         window.addEventListener('scroll', handleScroll, { passive: true });
         setSlidePos(slide)
 
         return () => {
             window.removeEventListener('scroll', handleScroll);
 
         };
     }, [scrollPosition]);
     return (
         <React.Fragment>
             {/* Mobile category (go to) */}
             {isMobile === "true" ?
                 <div className='row'>
                     <div className="pt-1 pb-1 text-center dropCategory">
                         <Dropdown
                             id="dropdown-basic-button "
                             onClick={() => {
                                 setVisible(!visible)
                             }
                             }
                             ref={ref} >
                             <label className="label" style={style.labelFont}>Go to: </label>
                             <Dropdown.Toggle id="sortby_field" style={style.sortByForm}>
                                 {label}
                                 <img src={arrowDown} width="10" alt="v" style={{ 'float': 'right' }} className="mt-1 m-2" />
                                 <Dropdown.Menu >
                                     {renderedOptions}
                                 </Dropdown.Menu>
                             </Dropdown.Toggle>
                         </Dropdown>
                     </div>
                     <div className="col-2" role="group" style={{ width: "100px", position: 'absolute', right: '-20px', paddingTop: '5px' }}>
                         <button type="button" style={{ width: '45%', height: '29.1px', padding: 0, border: '0px solid', opacity: "0.5" }} onClick={() => { setViewing('grid'); localStorage.setItem("viewItem", 'grid') }} className={`btn ${viewing === "list" ? 'btn-outline-secondary' : 'btn'}`} >
 
                             <svg height="15px" width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.08 154.66"><g id="Layer_2" data-name="Layer 2"><g id="Layer_34" data-name="Layer 34"><rect width="70.95" height="70.95" rx="14.85" /><rect x="84.13" width="70.95" height="70.95" rx="14.85" /><rect y="83.72" width="70.95" height="70.95" rx="14.85" /><rect x="84.13" y="83.72" width="70.95" height="70.95" rx="14.85" /></g></g></svg>
 
                         </button>
                         <button type="button" style={{ width: '45%', height: '29.1px', padding: 0, border: '0px solid', opacity: "0.5" }} onClick={() => {
                             setViewing('list'); localStorage.setItem("viewItem", 'list')
                         }
                         } className={`btn ${viewing === "list" ? 'btn svgStroke' : 'btn-outline-secondary'}`}>
 
                             <svg height="15px" width="15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.22 146.21"><g id="Layer_2" data-name="Layer 2"><g id="Layer_33" data-name="Layer 33"><circle cx="16.43" cy="16.43" r="16.43" /><rect x="46.16" width="123.6" height="32.86" rx="16.43" /><circle cx="16.89" cy="73.1" r="16.43" /><rect x="46.62" y="56.67" width="123.6" height="32.86" rx="16.43" /><circle cx="16.43" cy="129.78" r="16.43" /><rect x="46.16" y="113.35" width="123.6" height="32.86" rx="16.43" /></g></g></svg>
                         </button>
                     </div>
                 </div>
                 :
                 <div>
                     {/* Desktop category (go to) */}
                     <div className="container p-0" style={style.bgColorNav}>
                         {slidePos === "secondSlide" ?
                             <div className="scroller scroller-left" onClick={leftSlide}>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                                     <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                 </svg>
                             </div>
                             :
                             <div></div>
                         }
                         {slidePos === "firstSlide" ?
                             <div className="scroller scroller-right" onClick={rightSlide}>
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                                     <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                 </svg>
                             </div>
                             :
                             <div></div>
                         }
                         <div className="wrapper premium" id="categoryNav">
                             <ul className={`nav nav-pills nav-fill list ${slidePos} `}>
                                 {renderedCateg}
                             </ul>
 
                         </div>
                     </div>
                 </div>
             }
 
         </React.Fragment >
     );
 };
 
 export default FilterList;
 
 
 