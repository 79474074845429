const INTIAL_STATE = {
    showModal : null
}


const LoginModalReducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case 'LOGIN_MODAL':
            return {
                ...state,
                showModal: action.show
            };
        default:
            return state;
    }

};

export default LoginModalReducer;