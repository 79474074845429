/*******************************************************************************************
//  * File Name                : CartDetails
//  * File Directory           : src/components/Home/CartDetails
//  * Module                   : CartDetails
//  * Author                   : Joseph
//  * Version                  : 1
//  * Purpose                  : Reusable
//  * Used In				   : Home and Search
//  *******************************************************************************************/

/*******************************************************************************************
 *
 * @function   CartDetails        -   component for setting delivery schedule
 *
 * #How to call:
 *    <SortBy/>
 *
 * Note: Still working on the ff:
 * Ability to show qty and total items 
 * Ability to redirect to checkout page / Login
 * 

 *******************************************************************************************/
import React from 'react';
import { connect, useDispatch } from "react-redux";
import cartIcon from '../../assets/site_icons/shoppingCart.svg';
import { SaveToDatabase } from './SaveToDatabase';

const style = {
    cartCard: {
        background: 'transparent',
        border: '0px solid',
        position: 'fixed',
        bottom: '5%',
        width: '100%',
        color: '#222',
        borderRadius: '8px 8px',
        zIndex: 2,
    },
    anchorText: {
        textDecoration: 'none'
    },
    cartBody: {
        margin:' 0 auto',
        display: 'block',
        width: '35%'
    },
    cartCardMob: {
        background: 'transparent',
        border: '0px solid',
        position: 'fixed',
        bottom: '5%',
        paddingTop: "0px",
        width: '92%',
        color: '#222',
        borderRadius: '8px 8px',
        zIndex: 2
    }

}

const CartDetails = ({ total, setVisibleLogin, visibleLogin ,visibleCart, setVisibleCart, CartRedux}) => {
    const isLogged = sessionStorage.getItem("Login") || '';
    const isMobile = localStorage.getItem("isMobile");
    const isCleared = sessionStorage.getItem("total") || '';
    const dispatch = useDispatch()
    let source = sessionStorage.getItem('source')||''
    let body = document.body; // for setting body height (modal)
    const GACart = CartRedux.map((item, index) => {
        return {
          item_id: item.item_sku,
          item_name: item.item_name,
          currency: "PHP",
          index: index,
          item_brand: "Zagana",
          item_category: item.category,
          price: item.item_price,
          quantity: item.cart_qty
        }
      })
    //toggle modal 
    const openLoginModal = () => {
        let element = document.getElementById("modalLoginID");
        element?.classList?.remove("showModal");
        setVisibleLogin("false");
        body.classList.add("body_class");
        

        window.gtag("event", "begin_checkout", {
            currency: "PHP",
            value: total.itemTotal,
            items: GACart
        });
    }
    const openCartModal = () => {
        setVisibleCart(!visibleCart);
    }
    
    const triggerCart = () => {
        if (source === "Tiktok") {
            SaveToDatabase({
                action: {
                    type: 'InitiateCheckout',
                    value: {}
                },
            })
            window.ttq.track('InitiateCheckout')
        }

        window.gtag("event", "begin_checkout", {
            currency: "PHP",
            value: total.itemTotal,
            items: GACart
        });

        dispatch({
            type: "SET_LOCATION",
            location : window.location.pathname
        })
        document.getElementById("cartModalMobile").click();
    }
    return (
        <React.Fragment>
            {
                total.itemCount === 0 || isCleared=='' ? ""

                    :
                    <div className="cartdetails_" >
                        {isMobile === "true" ?
                            <div>
                                {window.location.pathname === '/premium' ?
                                    <button onClick={openCartModal} style={style.anchorText} >
                                        <div className="card-body" style={style.cartCardMob}>
                                            <div className="alert alert-secondary p-2 cartDetails shadow-lg" >
                                                <div className="row">
                                                    <div className="col-6 col-md-6 text-center">
                                                        <span className=""><img src={cartIcon} width="16" />: {total.itemCount || ''} Item/s</span>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-center">
                                                        <span className="">&#8369; {total.itemTotal.toLocaleString(2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </button>:
                                    <a onClick={triggerCart} style={style.anchorText} >
                                        <div className="card-body checkOutButton" style={style.cartCardMob}>
                                            <div className="alert alert-secondary p-2 cartDetails shadow-lg" >
                                                <div className="row">
                                                    <div className="col-6 col-md-6 text-center">
                                                        <span className=""><img src={cartIcon} width="16" />: {total.itemCount || ''} Item/s</span>
                                                    </div>
                                                    <div className="col-6 col-md-6 text-center">
                                                        <span className="">&#8369; {total.itemTotal.toLocaleString(2)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                }
                            </div>
                            :
                            <div style={style.cartBody}>
                                <a href={`${isLogged !== '' ? '/checkout-details' : '#checkout'}`} onClick={openLoginModal} style={style.anchorText}>
                                    <div className="card-body checkOutButton" style={style.cartCard}>
                                        <div className="alert alert-secondary p-2 cartDetails" >
                                            <div className="row">
                                                <div className="col-6 col-md-6 text-center">
                                                    <span className=""><img src={cartIcon} width="16" />: {total.itemCount || ''} Item/s</span>
                                                </div>
                                                <div className="col-6 col-md-6 text-center">
                                                    <span className="">&#8369; {total.itemTotal.toLocaleString(2)} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        }
                    </div>
            }

        </React.Fragment>
    );
};



const mapStateToProps = (state) => {
  return {
    CartRedux: state.cart.CartList,
    pathname: state.Location.location
  }
}

export default connect(mapStateToProps)(CartDetails);

