import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/src/jquery';
import 'bootstrap/dist/js/bootstrap.min.js';

import ReactGA from "react-ga"
import TiktokPixel from 'tiktok-pixel';

import './App.scss';
import '../src/components/Home/Home.scss';
import '../src/components/Shared/Shared.scss';
import '../src/components/Products/Products.scss'

import Register from '../src/components/Login/Register';
import VerifyOTP from '../src/components/Login/VerifyOTP';
import Address from './components/Shared/Address/Address'
import AddressView from "./components/Shared/Address/AddressView";
import AddAddress from "./components/Shared/Address/AddAddress";
import Login from './components/Login/Login';
import ItemList from './components/Shared/ItemList';
import AboutUs from "./components/Misc/AboutUs"
import NavBar from './components/Shared/NavBar';
import SideBar from './components/Shared/SideBar';
import Home from './components/Home/Home';
import CategoryPage from './components/Premium/CategoryPage';
import CheckoutDetails from "./components/Checkout/CheckoutDetails";
import DeliveryFields from './components/Shared/DeliveryFields';
import Search from './components/Home/Search';
import OrderDetails from "./components/Checkout/OrderDetails";
import Voucher from "./components/Checkout/Voucher";
import PaymentMethod from "./components/Checkout/PaymentMethod";
import DebitCredit from "./components/APIs/Paymongo/DebitCredit";
import DirectDebit from "./components/APIs/Xendit/DirectDebit";
import SuccessXendit from "./components/APIs/Xendit/SuccessXendit";
import FailXendit from "./components/APIs/Xendit/FailXendit";
import OTPXendit from "./components/APIs/Xendit/OTPXendit";
import ThankYouXendit from "./components/APIs/Xendit/ThankYou";
import SortBy from './components/Home/SortBy';
import Footer from "./components/Shared/Footer";
import ThankYou from "./components/Checkout/ThankYou";
import FAQs from "./components/Misc/FAQs";
import Blogs from "./components/Misc/Blogs"
import TermsAndCondition from "./components/Misc/TermsAndCondition";
import PrivacyPolicy from "./components/Misc/PrivacyPolicy";
import SelectCity from "./components/Home/SelectCity";
import { PageView, initGA } from './components/Tracking';
import NextAction from "./components/APIs/Paymongo/NextAction";
import Source from "./components/APIs/Paymongo/Source"
import Success from "./components/APIs/Paymongo/Success";
import UserProfile from "./components/Customer/userProfile";
import ModalOTP from "./components/Modal/ModalOTP";
import ProductHistory from "./components/Customer/productHistory";
import Failed from "./components/APIs/Paymongo/Failed";
import ContactUs from "./components/Misc/ContactUs";
import Page404 from "./components/Shared/Page404";
import Account from "./components/Customer/Account";
import CookieConsent from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import ForgotPass from "./components/Login/ForgotPass";
import ChangePass from "./components/Customer/ChangePass";
import EditAddress from "./components/Shared/Address/EditAddress";
import MyAddress from "./components/Customer/Address";
import CookieModal from "./components/Modal/CookieModal";
import CookiePolicy from "./components/Misc/CookiePolicy";
import CookieSettings from "./components/Shared/CookieSettings";
import Referral from "./components/Checkout/Referral";
import ProductPageNew from "./components/Products/ProductPageNew";
import SearchFloat from "./components/Shared/SearchFloat";
import CheckoutPage from "./components/Checkout/CheckoutPage";
import { storeFrontLogs } from "./components/APIs/Logs";
//premium
// import Checkout from "./components/Premium/Checkout/Checkout";
// import VoucherPremium from "./components/Premium/Checkout/Voucher";
// import ReferralPremium from "./components/Premium/Checkout/Referral";
// import PaymentMethodPremium from "./components/Premium/Checkout/PaymentMethod";
import DeliveryTime from "./components/Shared/DeliveryTime";
import LandBankForm from "./components/APIs/Landbank/LandBankForm";
import LandBankCallback from "./components/APIs/Landbank/LandBankCallback";
import Payments from "./components/Checkout/Payments";
import AffiliateMarketing from "./components/Affiliate/AffiliateMarketing";
import SelectedRecipe from "./components/Misc/RecipePage/SelectedRecipe";
import KitchenTips from "./components/Misc/RecipePage/KitchenTips";
import Recipe from "./components/Misc/RecipePage/Recipe";
import SubCollection from "./components/Misc/RecipePage/SubCollection";
import Blog from "./components/Misc/Blog";
import SubListingPage from "./components/Products/SubListingPage";



const style = {
  modal: {
    position: 'absolute',
    left: '8.5rem',
    bottom: '0.9rem'
  }
}

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const App = () => {
  const [windowDimension, setWindowDimension] = useState(null);
  const isLogged = sessionStorage.getItem("Login") || ''; // login checker
  let source = sessionStorage.getItem('source')||''
  let gen_id = localStorage.getItem('gen_id')
  let user;
  let userLogin;
  let isMobile;
  if (gen_id) {
    user = gen_id
  } else if (isLogged) {
    userLogin = JSON.parse(sessionStorage.getItem('Login'))
    gen_id = localStorage.getItem('gen_id')
    user = userLogin.customer_name + ` (${gen_id})`
  }
  //FBPixel
  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init('871545380653151', options);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  })


  // console.log('REACT PIXEL', ReactPixel)
  // console.log(window.location.origin)
//  if (window.location.origin === "https://zagana.com" || window.location.origin === "https://www.zagana.com") {
  if(window.location.origin === "https://zagana.com" || window.location.origin === "https://www.zagana.com"){
    console.log('REACT PIXEL', ReactPixel)
    ReactPixel.grantConsent()
    window['ga-disable-G-01DEJ7ZZWE'] = false;
  

  } else {
    ReactPixel.revokeConsent()
    window['ga-disable-G-01DEJ7ZZWE'] = true;
  }
  // console.log(getCookieConsentValue('zaganaCookie'))
  // if (getCookieConsentValue('zaganaCookie') === 'true') {
  //   ReactPixel.grantConsent()
  //   window['ga-disable-G-01DEJ7ZZWE'] = false;
  // }

  //FBPixel
  useEffect(() => {
    if (window.location.origin === "https://zagana.com" || window.location.origin === "https://www.zagana.com") {
      initGA('UA-191468992-1');
      PageView();
    }
    setWindowDimension(window.innerWidth);
  }, [windowDimension]);

  useEffect(() => {
    // if (getCookieConsentValue('zaganaCookie') === 'true') {
    if (window.location.origin === "https://zagana.com" || window.location.origin === "https://www.zagana.com") {
      window.gtag("config", "G-6VB8EDVLW9", {
        page_title: window.location.href,
        page_path: window.location.pathname.replace("/", '') || 'home',
    })
      // }
    }
  });
  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  isMobile = window.innerWidth <= 640;
  localStorage.setItem('isMobile', isMobile);
  const onClickCookieSettings = () => {

    const payload = {
      user: user,
      changes: `View cookie settings`,
      type: 'storefront',
      date: `NOW()`
    }
    storeFrontLogs(payload)
    window.location.href = '/cookie-settings'
  }
  const onClickAccept = () => {
    const payload = {
      user: user,
      changes: `Accepted cookie`,
      type: 'storefront',
      date: `NOW()`
    }
    storeFrontLogs(payload)
  }
  return (
    <>
      <Helmet>
        <script>
          {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-6VB8EDVLW9', {
                page_path: "${window.location.href}",
                page_title: "${window.location.pathname.replace("/", '') || 'home'}"
              });`}</script>
      </Helmet>
      <BrowserRouter>
        <div className="container">
          <div className="row">
            {/* <ScrollToTOp /> */}
            <Switch>
              <Route path='/' exact component={Home} />
              <Route path='/home' exact component={Home} />
              <Route path='/home/:categoryTag/:categoryName/' exact component={Home} />
              {/* <Route path='/premium' exact component={CategoryPage} /> */}
              <Route path='/category/Sale' exact component={Home} />
              <Route path='/sidebar' exact component={SideBar} />
              <Route path='/login' exact component={Login} />
              <Route path='/register' exact component={Register} />
              <Route path='/itemlist' exact component={ItemList} />
              <Route path='/checkout-details' exact component={CheckoutDetails} />
              <Route path='/checkout-page' exact component={CheckoutPage} />
              <Route path='/ContentPage/about' exact component={AboutUs} ttile="About Us" />
              <Route path='/item-list' exact component={ItemList} />
              <Route path='/search' exact component={Search} />
              <Route path='/delivery-fields' exact component={DeliveryFields} />
              <Route path='/OrderDetails' exact component={OrderDetails} />
              <Route path='/address' exact component={Address} />
              <Route path='/address/change' exact component={AddressView} />
              <Route path='/address/add' exact component={AddAddress} />
              <Route path='/add-voucher' exact component={Voucher} />
              <Route path='/select-payment-method' exact component={PaymentMethod} />
              <Route path='/thank-you' exact component={ThankYou} />
              <Route path='/thank-you-xendit' exact component={ThankYouXendit} />

              <Route path='/ContentPage/FAQs' exact component={FAQs} />
              <Route path='/VerifyOTP' exact component={VerifyOTP} />
              <Route path='/ContentPage/blogs' exact component={Blogs} />
              <Route path='/ContentPage/blogs/:blog' exact component={Blog} />
              <Route path='/ContentPage/terms' exact component={TermsAndCondition} />
              <Route path='/ContentPage/privacy-policy' exact component={PrivacyPolicy} />
              <Route path='/ContentPage/cookie-policy' exact component={CookiePolicy} />
              <Route path='/select-city' exact component={SelectCity} />
              <Route path='/SortBy' exact component={SortBy} />
              <Route path='/paymongo/debit-credit' exact component={DebitCredit} />
              <Route path='/paymongo/debit-credit/next-action' exact component={NextAction} />
              <Route path='/paymongo/source' exact component={Source} />
              <Route path='/success' exact component={Success} />
              <Route path='/user-profile' exact component={UserProfile} />
              <Route path='/order-history' exact component={ProductHistory} />
              <Route path='/failed' exact component={Failed} />
              <Route path='/contact-us' exact component={ContactUs} />
              <Route path='/modal-otp' exact component={ModalOTP} />
              <Route path='/my-account' exact component={Account} />
              <Route path='/forgot-password' exact component={ForgotPass} />
              <Route path='/change-password/:userid' exact component={ChangePass} />
              <Route path='/address/edit' exact component={EditAddress} />
              <Route path='/my-directions' exact component={MyAddress} />
              <Route path='/my-address' exact component={MyAddress} />
              <Route path='/cookie-settings' exact component={CookieSettings} />
              <Route path='/add-referral' exact component={Referral} />
              <Route path='/productPage/:sku' exact component={ProductPageNew} />
              <Route path='/SearchFloat' exact component={SearchFloat} />

              {/* <Route path='/premium/checkout' exact component={Checkout} />
              <Route path='/premium/add-voucher' exact component={VoucherPremium} />
              <Route path='/premium/add-referral' exact component={ReferralPremium} />
              <Route path='/premium/select-payment-method' exact component={PaymentMethodPremium} /> */}


              <Route path='/xendit/direct-debit' exact component={DirectDebit} />
              <Route path='/xendit/SuccessXendit' exact component={SuccessXendit} />
              <Route path='/xendit/FailXendit' exact component={FailXendit} />
              <Route path='/xendit/OTPXendit' exact component={OTPXendit} />



              <Route path='/delivery-time' exact component={DeliveryTime} />
              <Route path='/landbank-form' exact component={LandBankForm} />
              {/** used for affiliate marketers */}
              {/* <Route path='/affiliate-marketer' exact component={AffiliateMarketing} /> */}
              <Route path='/LandBank-paystatus/:orderID' exact component={LandBankCallback} />
              <Route path='/home/:userPhoneNumber' exact component={Home} />
              <Route path='/ContentPage/recipe' exact component={Recipe} />
              <Route path='/ContentPage/recipe/collection/:category' exact component={SubCollection} />
              <Route path='/ContentPage/recipe/:recipe' exact component={SelectedRecipe} />
              <Route path='/ContentPage/recipe/KitchenTips/:tips' exact component={KitchenTips} />

              <Route path='/sub-list/:subCategory' exact component={SubListingPage} />
              
              <Route component={Page404} />
            </Switch>
            <Footer />
          </div>
          <NavBar />
        </div>
        <CookieConsent
          location="bottom"
          cookieName="zaganaCookie"
          contentClasses="col-12"
          buttonWrapperClasses="col-12"
          buttonText="Yes, I agree"
          onAccept={onClickAccept}
        >
          <div>
            We use cookies to personalize your experience. By continuing to visit our website you agree to our use of cookies. <br />
            Please let us know if you agree to the use of all cookies
          </div>
          <div style={style.modal}>
            {isMobile ?
              <button onClick={onClickCookieSettings} style={{ padding: '4px 10px' }}>
                No, show cookie settings
              </button>
              :
              <CookieModal />
            }
          </div>
        </CookieConsent>
      </BrowserRouter>
    </>
  );
}

export default App;