import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import ZaganaLogo from '../../Shared/ZaganaLogo';
import Header from "../../Shared/Header";
import Xendit from "../Xendit";
import { useHistory } from "react-router-dom";
import UB from "../../../assets/site_icons/unionbank-icon.svg"
import BPI from "../../../assets/site_icons/bpi-icon.svg"
const styles = {
    container: {
        height: "100vh",
        padding: "3rem"
    },
    headerBG: {
        'backgroundColor': '#facc02'
    },
    img: {
        width: '250px',
        margin: '0 auto',
        display: 'block'
    }
}

const Success = ({ createPayment_id, customer_id, paymentSelected, order_id }) => {
    const isMobile = localStorage.getItem("isMobile") === "true";
    const dispatch = useDispatch();
    let total = JSON.parse(sessionStorage.getItem('orderTotal'))
    const history = useHistory()
    let loc = window.location.origin;
    const today = new Date();
    const selectedPayment = paymentSelected.paymentSelected;
    const imgSrc = selectedPayment === "UB" ? UB : BPI;
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let retrieveID = (window.location.search).replace("?linked_account_token_id=", "") //
    console.log(retrieveID)
    useEffect(() => {
        // console.log(retrieveID)

        retrieveID = (window.location.search).replace("?linked_account_token_id=", "") //
        document.getElementById("btnRegister").disabled = true;
        document.getElementById("btnRegister").innerText = 'Loading ...';
        const createPayment = async () => {
            await Xendit.post(`/retrieveAccount`, { retrieveID: retrieveID })
                .then(response => {
                    if (response.data.message === 'Success') {
                        document.getElementById("btnRegister").disabled = false;
                        document.getElementById("btnRegister").innerText = 'Proceed Payment';
                        dispatch({
                            type: "XenditCreatePayment",
                            createPayment_id: response.data.resp[0].id
                        })

                        dispatch({
                            type: "XenditRetrieve",
                            link_id: retrieveID
                        })
                    }
                })
                .catch(error => {
                    console.log(error)
                    window.location.reload()
                })
        }
        createPayment();
    }, [retrieveID]);

    const onClickProceed = () => {

        document.getElementById("btnRegister").disabled = true;
        document.getElementById("btnRegister").innerText = 'Loading ...';
        Xendit.post(`/createPayment`, { createPayment_id: createPayment_id, customer_id: customer_id, total: total, paymentSelected: paymentSelected })
            .then(response => {
                if (response.data.status === 400 || response.data.status === 409 || response.data.status === 503) {
                    alert(response.data.message)
                    history.push('/checkout-details')
                }
                else if (response.data.status === "COMPLETE" || response.data.status === "COMPLETED") {

                    const paymentDD = response.data.id;
                    dispatch({
                        type: "XenditValidateOTP",
                        paymentDD: paymentDD
                    })
                    window.location.href = loc + "/thank-you-xendit"
                }
                else if (response.data.status === "PENDING") {
                    const paymentDD = response.data.id;
                    dispatch({
                        type: "XenditValidateOTP",
                        paymentDD: paymentDD
                    })
                    history.push('/xendit/OTPXendit')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <React.Fragment>
            <div className="row justify-content-md-center m-0 pc_nav" style={styles.headerBG} >
                <div className="row">
                    <div className="col-2 d-none d-sm-block text-center">
                        <a href="/">
                            <ZaganaLogo />
                        </a>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row'>
                    <h1></h1>
                    <div className={`card justify-content-center m-auto ${isMobile ? 'w-100 mb-5 mt-5' : 'w-25 '}`}
                        style={{ "minHeight": "250px" }}>
                        <div className="card-body mt-2 mb-5 p-0">
                            <img src={imgSrc} style={styles.img} />
                            <br />
                            <table className="table p-0">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: "left" }}>Date: {date}</td>
                                        <td scope="row"></td>
                                    </tr>
                                    <tr>
                                        <td scope="row">Order No:</td>
                                        <td>{order_id.order_id}</td>
                                    </tr>
                                    <tr>
                                        <td scope="row">Order Amount:</td>
                                        <td>{total.toLocaleString('en-US', { style: 'currency', currency: 'PHP' })}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button className='btn btn-success mt-5' id="btnRegister"
                                onClick={onClickProceed}
                            >
                                Proceed Payment
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

}
const mapStateToProps = (state) => {
    return ({
        createPayment_id: state.XenditCreatePayment,
        paymentSelected: state.payment,
        order_id: state.OrderID,
        customer_id: state.XenditData

    })
}
export default connect(mapStateToProps)(Success);