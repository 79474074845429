/*******************************************************************************************
* File Name                : productHistory
* File Directory           : src/components/Customer/userProfile
* Module                   : Customer and order history
* Author                   : Joseph
* Version                  : 1
* Purpose                  : Reusable
* Used In					        : src/Customer and product history.js
*******************************************************************************************/

/*******************************************************************************************
 *
 *  * Copyright (C) 2021 Zagana Inc. - All Rights Reserved.
 *
 * This file is part of BDE Platform, an intellectual property of Zagana.
 * Any individual or company using this code accepts that the use of this file
 * is provided as is and cannot be modified or used without written and approved
 * consent and permission of Zagana.
 * @function   productHistory        -   component for Registration for new customers
 *
 * #How to call:
 *    <productHistory/>
 *
 * Note: Still working on the ff:
 * - Ability to show customer ordered products
 * 
 *******************************************************************************************/
import React, { useState, useEffect } from "react";
import Home_api from '../APIs/Home';
import FeedBack from '../Shared/FeedBack';

const styles = {
    header: {
        backgroundColor: 'white',
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    card: {
        backgroundColor: '#F5F5F8',
        borderBottom: "none",
        transition: 'background 1000ms',
    },
    headerBG: {
        'backgroundColor': '#facc02'
    },
    userDetails: {
        width: '80%',
        display: 'inline-table'
    }
}
const ProductHistory = ({ order_id, order_status, Rate_date }) => {

    document.title = "Zagana | Customer Profile"
    const isMobile = localStorage.getItem("isMobile") === "true"
    let getUser = JSON.parse(sessionStorage.getItem('Login')) || '';
    const [products, setProducts] = useState([]); // for mapping myorders  product
    const orderId = order_id || 1;
    const orderStatus = order_status || "";
    const rateChecker = Rate_date || null;
    //fetching my orders from database
    useEffect(() => {
        const myProducts = async () => {
            const response = await Home_api.post('/vieworders/' + orderId, { id: orderId })
            const prodOrder = response.data.order[0].order_cartItems || []
            setProducts(prodOrder)
        }

        myProducts()
    }, [orderId])

    //map from database ordered products
    const renderedProducts = products.map((prod, index) => {
        return (

            <div className="row" key={index}>
                <div className="col-6">
                    {prod.item_name}
                </div>
                <div className="col" style={{ 'textAlign': 'center' }}>
                    {prod.cart_quantity}
                </div>
                <div className="col" style={{ 'textAlign': 'center' }}>
                    {prod.cart_price}
                </div>
                <hr />
            </div>


        )
    });

    return <React.Fragment>
        {
            <>
                {getUser ?
                    <div className="row">
                        {renderedProducts}
                    </div>
                    : ''}

                {/* {orderStatus === "Order fulfilled" && rateChecker === null ?
                    <FeedBack order_id={orderId} />

                    : ""} */}
            </>
        }
    </React.Fragment>
}

export default ProductHistory;
